// @flow
import React from 'react'
import styled from 'styled-components'
import { CloseButton } from './Buttons'
import { useOnClickOutside } from '../../utility/hooks'
import { MODAL_Z_INDEX, DARKEST_SHADE } from '../../constants/styles'

const Overlay: StyledType<> = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	${'' /* IPad "Fix"/hack - https://allthingssmitty.com/2020/05/11/css-fix-for-100vh-in-mobile-webkit/ */}
	max-height: -webkit-fill-available;
	z-index: ${MODAL_Z_INDEX};
	display: flex;
	flex-direction: column;
	justify-content: center;
	background-color: rgba(0, 0, 13, 0.7);
`

const ModalStyle = styled.div`
	padding: 12px;
	margin: 0 auto;
	border-radius: 16px;
	box-shadow: 0 0 1vh ${DARKEST_SHADE};
	position: relative;
`

export default function Modal({
	style,
	onClose,
	children,
	className,
	overlayStyle,
	iconClose = true,
}: {
	style?: {},
	children: React$Node,
	onClose?: ?() => void,
	className?: ?string,
	overlayStyle?: {},
	iconClose?: boolean,
}): React$Node {
	return (
		<Overlay style={overlayStyle} className={className}>
			{onClose ? (
				<ClosableModal style={style} iconClose={iconClose} onClose={onClose}>
					{children}
				</ClosableModal>
			) : (
				<ModalStyle className="md:w-1/2" style={style}>
					{children}
				</ModalStyle>
			)}
		</Overlay>
	)
}

// A modal that can be closed when a user clicks/touches outside of it, the modal also will have a close button displayed within it.
function ClosableModal({
	style,
	onClose,
	children,
	iconClose,
}: {
	style?: {},
	children: React$Node,
	onClose: () => void,
	iconClose: boolean,
}): React$Node {
	const ref = useOnClickOutside(onClose)
	return (
		<ModalStyle style={style} ref={ref}>
			{children}
			{iconClose && <CloseButton onClick={onClose} data-testid="close-modal" />}
		</ModalStyle>
	)
}
