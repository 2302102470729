import React, { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useDrop, useDrag } from 'react-dnd'
import classnames from 'classnames'
import { getEmptyImage } from 'react-dnd-html5-backend'
import { sendMessage } from '../../../../../../store/stores/webSocket'
import { getConnectionSocketMessage } from '../../../../../../store/selectors/jrPlusState/engineering'
import { useCallbackRef } from 'use-callback-ref'

import LineDragLayer from '../../../../../LineDragLayer'

import type { ConnectionData as ConnectionComponent } from '@mission.io/mission-toolkit'
import type { ElementRef } from 'react'

const ENDPOINT = 'CONNECTIONS_ENDPOINT'

export default function Connection({
	component,
	panelId,
}: {
	component: ConnectionComponent,
	panelId: string,
}): React$Node {
	const dispatch = useDispatch()

	const onAction = (node1: ?string, node2: ?string) => {
		if (!node1 || !node2) {
			return
		}
		dispatch(
			sendMessage(
				'ENGINEERING_PANEL_ACTION',
				getConnectionSocketMessage(component.id, [node1, node2])
			)
		)
	}

	return (
		<ConnectionArea
			nodes={component.nodes}
			connections={normalizeConnections(component.connections)}
			onConnect={onAction}
			onDisconnect={onAction}
			className="connection-wrapper"
			nodeClassName="node"
			lineClassName="line"
		/>
	)
}

const SEPERATOR = '-^>><<||'

/**
 * the connections coming from the redux store is not in an optimal format for this code,
 * converts the redux representation into an Array of connection [fromNode, toNode]
 *
 * @param { { [node: string]: string } } connections the connected nodes
 * @return {Array<[string, string]>} array of connections in form [fromNode, toNode]
 */
function normalizeConnections(connections: { [node: string]: string[] }): Array<[string, string]> {
	const connectionArray = []
	const alreadySeen: Set<string> = new Set()
	Object.keys(connections).forEach(node =>
		connections[node].forEach(connected => {
			if (!alreadySeen.has(connected + SEPERATOR + node)) {
				connectionArray.push([node, connected])
				alreadySeen.add(node + SEPERATOR + connected)
			}
		})
	)
	return connectionArray
}

type functionalProps = {
	nodes: string[],
	connections: [string, string][],
	onConnect: (node1: string, node2: string) => void,
	onDisconnect: (node1: ?string, node2: ?string) => void,
	className?: string,
	nodeClassName?: string,
	lineClassName?: string,
}

function ConnectionArea({
	nodes,
	connections,
	onConnect,
	onDisconnect,
	className,
	nodeClassName,
	lineClassName,
}: functionalProps) {
	const containerRef = useRef(null)
	const refs: {
		current: {
			[node: string]: ElementRef<'div'>,
		},
	} = useRef({})
	const [currentLineStart, setCurrentLineStart] = useState(null)
	const [currentNodePositions, setCurrentNodePositions] = useState({})

	let containerDimensions = { x: 0, y: 0 }

	if (containerRef.current) {
		let rect = containerRef.current.getBoundingClientRect()
		containerDimensions = {
			x: rect.left,
			y: rect.top,
		}
	}

	const beginDrag = (node: string) => {
		setCurrentLineStart(node)
	}
	const endDrag = () => {
		setCurrentLineStart(null)
	}

	const hasConnection = (node1: string, node2: string) => {
		return connections.some(connection => {
			return (
				(connection[0] === node1 && connection[1] === node2) ||
				(connection[0] === node2 && connection[1] === node1)
			)
		})
	}

	const mountRef = (node: string, ref: ElementRef<'div'>) => {
		if (!ref) {
			delete refs.current[node]
			return
		}
		refs.current[node] = ref
	}

	const updateDimensions = () => {
		const newDimensions = {}
		const currentRefs = refs.current
		Object.keys(currentRefs).map(
			node =>
				(newDimensions[node] = {
					x: currentRefs[node].clientWidth / 2 + currentRefs[node].offsetLeft,
					y: currentRefs[node].clientHeight / 2 + currentRefs[node].offsetTop,
				})
		)
		setCurrentNodePositions(newDimensions)
	}

	useEffect(() => {
		window.addEventListener('resize', updateDimensions)
		updateDimensions()
		return () => {
			window.removeEventListener('resize', updateDimensions)
		}
	}, [])

	return (
		<div className="ConnectionArea" ref={containerRef}>
			<svg className="myLines">
				<Lines
					connections={connections}
					onClick={onDisconnect}
					className={lineClassName}
					nodePositions={currentNodePositions}
				/>
			</svg>
			<div className={classnames(className)}>
				{nodes.map(node => (
					<ConnectionEndpoint
						node={node}
						addLine={onConnect}
						beginDrag={beginDrag}
						endDrag={endDrag}
						hasConnection={hasConnection}
						mountRef={mountRef}
						className={nodeClassName}
						key={node}
					/>
				))}
			</div>
			<LineDragLayer
				start={currentLineStart ? currentNodePositions[currentLineStart] : null}
				renderLine={([start, end]) => {
					return (
						<Line
							drawn={false}
							start={start}
							end={{ x: end[0] - containerDimensions.x, y: end[1] - containerDimensions.y }}
							className={lineClassName}
						/>
					)
				}}
			/>
		</div>
	)
}

type ConnectionEndpointProps = {
	node: string,
	className?: string,
	addLine: (node1: string, node2: string) => void,
	beginDrag: (node: string) => void,
	endDrag: () => void,
	hasConnection: (node1: string, node2: string) => boolean,
	mountRef: (node: string, ref: ElementRef<'div'>) => void,
}

export function ConnectionEndpoint({
	node,
	addLine,
	beginDrag,
	endDrag,
	hasConnection,
	mountRef,
	className,
}: ConnectionEndpointProps): React$Node {
	const ref = useCallbackRef(null, ref => mountRef(node, ref))
	const [, connectDrag, preview] = useDrag({
		item: { node, type: ENDPOINT },
		begin: () => {
			beginDrag(node)
			return { node }
		},
		end: (_, monitor) => {
			endDrag()
			if (!monitor.didDrop()) return
			if (!hasConnection(node, monitor.getDropResult().node)) {
				addLine(node, monitor.getDropResult().node)
			}
		},
		previewOptions: {
			disabled: true,
		},
	})
	const [, connectDrop] = useDrop({
		accept: ENDPOINT,
		drop: () => {
			return {
				node,
			}
		},
		canDrop: (item, monitor) => {
			return monitor.getItem().node !== node
		},
	})

	connectDrag(ref)
	connectDrop(ref)

	useEffect(() => {
		preview(getEmptyImage())
	}, [preview])

	return (
		<div ref={ref} className={classnames(className, 'notranslate')}>
			{node}
		</div>
	)
}

type LineProps = {
	startNode?: string,
	start: { x: number, y: number },
	endNode?: string,
	end: { x: number, y: number },
	className?: string,
	onClick?: (startNode: ?string, endNode: ?string) => void,
}

function Line({ startNode, endNode, start, end, className, onClick }: LineProps) {
	return (
		<line
			onClick={() => onClick && onClick(startNode, endNode)}
			className={classnames(className)}
			x1={start.x}
			y1={start.y}
			x2={end.x}
			y2={end.y}
		/>
	)
}

function Lines({
	connections,
	onClick,
	className,
	nodePositions,
}: {
	nodePositions: { [node: string]: { x: number, y: number } },
	connections: Array<[string, string]>,
	onClick: (startNode: ?string, endNode: ?string) => void,
	className?: string,
}) {
	return connections.map(([start, end]) => {
		const startPosition = nodePositions[start]
		const endPosition = nodePositions[end]
		if (!startPosition || !endPosition) return null

		return (
			<Line
				startNode={start}
				start={startPosition}
				endNode={end}
				end={endPosition}
				className={className}
				onClick={onClick}
				key={start + '->' + end}
			/>
		)
	})
}
