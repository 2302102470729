import React, { forwardRef, type AbstractComponent } from 'react'
import styled from 'styled-components/macro'
import type { BlasterProps as Props } from './upgradeLookup'
import { DefaultWrapper } from '../constants'

function Blaster(props: Props, pointOfRotationRef): React$Node {
	const { transformationWrapper: Wrapper = DefaultWrapper, children, ...otherProps } = props
	return (
		<Svg {...otherProps} viewBox="0 0 398.24 550.88">
			<defs>
				<radialGradient
					id="radial-gradient"
					cx="199.12"
					cy="351.77"
					r="199.12"
					gradientUnits="userSpaceOnUse">
					<stop offset="0.49" stopColor="#3b384c" />
					<stop offset="1" stopColor="#1a1a26" />
				</radialGradient>
				<linearGradient
					id="linear-gradient"
					x1="142"
					y1="154.77"
					x2="257.06"
					y2="154.77"
					gradientUnits="userSpaceOnUse">
					<stop offset="0" stopColor="#7d7d93" />
					<stop offset="0.04" stopColor="#72728a" />
					<stop offset="0.11" stopColor="#676780" />
					<stop offset="0.21" stopColor="#60607a" />
					<stop offset="0.49" stopColor="#5e5e78" />
					<stop offset="0.78" stopColor="#60607a" />
					<stop offset="0.88" stopColor="#676780" />
					<stop offset="0.96" stopColor="#72728a" />
					<stop offset="1" stopColor="#7d7d93" />
				</linearGradient>
				<linearGradient
					id="linear-gradient-2"
					y1="161.03"
					x2="257.06"
					y2="161.03"
					xlinkHref="#linear-gradient"
				/>
				<linearGradient
					id="linear-gradient-3"
					x1="174.74"
					y1="105.81"
					x2="223.84"
					y2="105.81"
					xlinkHref="#linear-gradient"
				/>
				<linearGradient
					id="linear-gradient-4"
					x1="174.74"
					y1="60.51"
					x2="223.84"
					y2="60.51"
					xlinkHref="#linear-gradient"
				/>
				<linearGradient
					id="linear-gradient-5"
					x1="141.76"
					y1="60.51"
					x2="262.94"
					y2="60.51"
					xlinkHref="#linear-gradient"
				/>
				<linearGradient
					id="linear-gradient-6"
					x1="185.76"
					y1="46.87"
					x2="212.13"
					y2="46.87"
					gradientUnits="userSpaceOnUse">
					<stop offset="0" stopColor="#1a1a26" />
					<stop offset="0.49" stopColor="#3b384c" />
					<stop offset="1" stopColor="#1a1a26" />
				</linearGradient>
				<linearGradient
					id="linear-gradient-7"
					x1="178.57"
					y1="34.85"
					x2="219.14"
					y2="34.85"
					xlinkHref="#linear-gradient"
				/>
				<linearGradient
					id="linear-gradient-8"
					x1="200.42"
					y1="-18.07"
					x2="200.07"
					y2="-11.01"
					xlinkHref="#linear-gradient"
				/>
				<linearGradient
					id="linear-gradient-10"
					x1="178.57"
					y1="19.43"
					x2="219.14"
					y2="19.43"
					xlinkHref="#linear-gradient"
				/>
				<linearGradient
					id="linear-gradient-11"
					x1="199.62"
					y1="123.36"
					x2="199.51"
					y2="133.2"
					xlinkHref="#linear-gradient"
				/>
				<linearGradient
					id="linear-gradient-12"
					x1="199.15"
					y1="162.92"
					x2="199.13"
					y2="165.14"
					xlinkHref="#linear-gradient"
				/>
			</defs>
			<g className="blaster-1">
				<Wrapper>
					{children}
					<g id="Layer_1-2" data-name="Layer 1">
						<g id="Default">
							<g id="Orbital_Thing" data-name="Orbital Thing">
								<circle
									className="blaster-2"
									cx="199.12"
									cy="351.77"
									r="199.12"
									ref={pointOfRotationRef}
								/>
								<path
									className="blaster-3"
									d="M245,163.81v90.35h0a107.79,107.79,0,0,1,62,97.6h0A105.22,105.22,0,0,1,201.72,457h-2"
								/>
							</g>

							<path
								className="blaster-4"
								d="M266.94,164.08H131.29V158l12.53-12.53H254.41L266.94,158Z"
							/>
							<rect className="blaster-5" x="131.29" y="157.99" width="135.65" height="6.1" />
							<rect className="blaster-6" x="170.18" y="66.16" width="57.88" height="79.29" />
							<polygon
								className="blaster-7"
								points="214.06 54.87 184.18 54.87 170.18 66.16 228.06 66.16 214.06 54.87"
							/>
							<polygon
								className="blaster-8"
								points="214.06 54.87 184.18 54.87 170.18 66.16 228.06 66.16 214.06 54.87"
							/>
							<rect className="blaster-9" x="184.18" y="38.87" width="29.88" height="16" />
							<polygon
								className="blaster-10"
								points="199.12 30.82 176.13 30.82 184.18 38.87 199.12 38.87 214.06 38.87 222.1 30.82 199.12 30.82"
							/>
							<polygon
								className="blaster-11"
								points="199.12 8.04 222.1 8.04 214.06 0 199.12 0 184.18 0 176.13 8.04 199.12 8.04"
							/>
							<polygon
								className="blaster-12"
								points="199.12 30.82 176.13 30.82 184.18 38.87 199.12 38.87 214.06 38.87 222.1 30.82 199.12 30.82"
							/>
							<rect
								className="blaster-13"
								id="laser-start"
								x="176.13"
								y="8.04"
								width="45.97"
								height="22.78"
							/>
							<polyline
								className="blaster-14"
								points="177.13 21.1 191.76 21.1 201.35 30.68 201.35 37.87"
							/>
							<g>
								<polygon
									className="blaster-15"
									points="206.93 135.25 191.31 135.25 184.18 165.76 189.09 174.29 209.15 174.29 214.06 165.76 206.93 135.25"
								/>
								<polygon
									className="blaster-16"
									points="184.18 165.76 214.06 165.76 209.15 174.29 189.09 174.29 184.18 165.76"
								/>
							</g>
						</g>
					</g>
				</Wrapper>
			</g>
		</Svg>
	)
}

export default (forwardRef(Blaster): AbstractComponent<Props, Element>)

const Svg = styled.svg`
	.blaster-1 {
		isolation: isolate;
	}

	.blaster-2 {
		fill: url(#radial-gradient);
	}

	.blaster-14,
	.blaster-3 {
		fill: none;
		stroke-linejoin: round;
	}

	.blaster-3 {
		stroke: #ffe19f;
		stroke-width: 10px;
	}

	.blaster-4 {
		opacity: 0.9;
		fill: url(#linear-gradient);
	}

	.blaster-12,
	.blaster-16,
	.blaster-5,
	.blaster-8 {
		mix-blend-mode: multiply;
	}

	.blaster-5 {
		opacity: 0.2;
		fill: url(#linear-gradient-2);
	}

	.blaster-6 {
		fill: url(#linear-gradient-3);
	}

	.blaster-7 {
		fill: url(#linear-gradient-4);
	}

	.blaster-12,
	.blaster-16,
	.blaster-8 {
		opacity: 0.3;
	}

	.blaster-8 {
		fill: url(#linear-gradient-5);
	}

	.blaster-9 {
		fill: url(#linear-gradient-6);
	}

	.blaster-10,
	.blaster-12 {
		fill: url(#linear-gradient-7);
	}

	.blaster-11 {
		fill: url(#linear-gradient-8);
	}

	.blaster-13 {
		fill: url(#linear-gradient-10);
	}

	.blaster-14 {
		stroke: #1a1a26;
		stroke-linecap: round;
		stroke-width: 2px;
	}

	.blaster-15 {
		fill: url(#linear-gradient-11);
	}

	.blaster-16 {
		fill: url(#linear-gradient-12);
	}
`
