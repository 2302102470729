import React from 'react'
import 'styled-components/macro'
export const SVG_HEIGHT = 50
export const SVG_DOM_HEIGHT = 84
export type SvgProps = {|
	css?: string,
	children?: React$Node,
	x?: string | number,
	y?: string | number,
	style?: {},
	id?: string,
	className?: string,
|}

// The Base SVG component that all other svg components should be wrapped in for the literacy event frame and options.
export const BaseSVG = ({ children, ...props }: SvgProps): React$Node => {
	return (
		<svg
			viewBox={`0 0 ${SVG_HEIGHT} ${SVG_HEIGHT}`}
			css={`
				height: ${SVG_DOM_HEIGHT}px;
				overflow: visible;
			`}
			{...props}>
			{children}
		</svg>
	)
}
