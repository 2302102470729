import React from 'react'
import { useSelector } from 'react-redux'
import type { StationEnum } from '../../../types'
import { ReactModal as Modal, CloseButton } from '../../basics'
import { getStationActivity } from '../../../utility/selectors'
import styled from 'styled-components'
import { useTimeout } from '../../../utility/hooks'
import { ONE_SECOND } from '../../../constants'
import { isTraining as isTraining_selector } from '../../../store/stores/general'

type Props = {
	station: StationEnum,
	message: ?string,
	style: {},
	onClose: () => void,
}

const customStyles = {
	overlay: {
		backgroundColor: 'rgba(0,0,13, .7)',
	},
	content: {
		width: '55%',
		height: '40%',
		backgroundColor: 'rgb(24, 70, 131)',
		boxShadow: '0 0 1vh #00000D',
		color: 'lightskyblue',
		border: 'none',
	},
}

const StationEndedDisplayStyle = styled.div.attrs({
	className: 'flex items-center justify-center text-center',
})`
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	h2 {
		font-size: 2.3rem;
	}

	p {
		font-size: 1.3rem;
	}
`
const AUTO_CLOSE_TIME = 15 * ONE_SECOND

export default function ShouldDisplayWrapper(props: Props): React$Node {
	const isStationActive = useSelector(state => getStationActivity(state)[props.station])
	const isTraining = useSelector(isTraining_selector)
	if (isStationActive || isTraining) return null
	return <StationEndedDisplay {...props} isStationActive={isStationActive} />
}

function StationEndedDisplay({
	isStationActive,
	message,
	style,
	station,
	onClose,
}: {
	...Props,
	isStationActive: boolean,
}) {
	useTimeout(onClose, AUTO_CLOSE_TIME)
	return (
		<Modal frameContent isOpen={true} onRequestClose={onClose} style={customStyles}>
			<CloseButton onClick={onClose} />
			<StationEndedDisplayStyle>
				<h2>Station Complete</h2>
				{message && <p className="py-4">{message}</p>}
			</StationEndedDisplayStyle>
		</Modal>
	)
}
