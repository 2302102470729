import React from 'react'
import type { TrainingId } from '@mission.io/mission-toolkit'
import { TRAINING } from '@mission.io/mission-toolkit/constants'
import { RATINGS } from '../components/basics'
import styled from 'styled-components'

const SurveySmiles = styled.div`
	display: flex;
	flex-direction: row;
	background-color: #1a3862;
	svg {
		height: 40px;
		width: 40px;
		margin: 0px 10px;
	}
	justify-content: center;
	padding: 5px;
`
const Li = styled.li`
	text-align: left;
`

type JoyrideStepConfig = {|
	target: string,
	content: string | React$Node,
	placement: string,
	locale?: {| next?: string, back?: string, last?: string |},
	disableBeacon?: boolean,
	hideBackButton?: boolean,
	spotlightClicks?: boolean,
	spotlightPadding?: number,
	styles?: {},
	async?: boolean,
|}
const stepLookup: {
	[TrainingId]: JoyrideStepConfig,
} = {
	[TRAINING.IDS.WELCOME]: {
		target: '.App',
		content: 'Welcome to training!',
		placement: 'center',
		locale: { next: 'Start' },
		disableBeacon: true,
		hideBackButton: true,
	},
	[TRAINING.IDS.QUICK_SUMMARY]: {
		target: '.App',
		content: (
			<>
				<p>There are four things that you should focus on during your mission:</p>
				<ol className="list-disc ml-8">
					<Li>Upgrades</Li>
					<Li>Health</Li>
					<Li>Repairs</Li>
					<Li>Sensors</Li>
				</ol>
			</>
		),
		placement: 'center',
		disableBeacon: true,
		hideBackButton: true,
	},
	[TRAINING.IDS.ACTIVATE_STATION_1]: {
		target: '.station-select',
		content: (
			<>
				<p>During the mission, you will need to activate different parts of your controls</p>
				<p>Start out by clicking on the blinking icon, which will lead you to thrusters control.</p>
			</>
		),
		spotlightClicks: true,
		placement: 'top',
		styles: { buttonNext: { display: 'none' } },
		hideBackButton: true,
	},
	[TRAINING.IDS.ACTIVATE_STATION_2]: {
		target: '.upgrades-wrapper',
		content: (
			<>
				Here is a list of the upgrades you can earn for thrusters. These are different types of
				engines.
				<br />
				Notice that <b>Turbo Engine X</b> is locked.
			</>
		),
		placement: 'right',
		disableBeacon: true,
		hideBackButton: true,
	},
	[TRAINING.IDS.ACTIVATE_STATION_3]: {
		target: '.initiate-upgrades',
		content: `Click here to start your engine upgrade in order to use thrusters!`,
		spotlightClicks: true,
		placement: 'top',
		styles: { buttonNext: { display: 'none' } },
		hideBackButton: true,
	},
	[TRAINING.IDS.ANSWER_QUESTION]: {
		target: '.question-modal',
		content: 'To complete the upgrade, solve the problem!',
		placement: 'top',
		hideBackButton: true,
		async: true,
		spotlightClicks: true,
		styles: { buttonNext: { display: 'none' } },
	},
	[TRAINING.IDS.REVIEW_QUESTION]: {
		target: '.question-modal',
		content: (
			<>
				This is where you will see if you answered your question correctly. <br /> If you answered
				wrong, <b>Beware</b> you will lose health, and miss out on the upgrade <br />
			</>
		),
		placement: 'right',
		hideBackButton: true,
	},
	[TRAINING.IDS.CLOSE_QUESTION]: {
		target: '.App',
		content:
			'Once all the students are done answering questions, you will be able to access your station.',
		placement: 'center',
	},
	[TRAINING.IDS.HEALTH_1]: {
		target: '#healthBar',
		content: 'This is your health. It can drop if you get a question wrong during an upgrade.',
		placement: 'top',
		disableBeacon: true,
		hideBackButton: true,
	},
	[TRAINING.IDS.HEALTH_2]: {
		target: '#healthBar',
		content: 'If your health reaches 0%, your computer will lockout until you restore your health.',
		placement: 'top',
		disableBeacon: true,
		hideBackButton: true,
	},
	[TRAINING.IDS.HEALTH_3]: {
		target: '.healthPlus',
		content: <span>To restore your health click this health button.</span>,
		placement: 'top',
		disableBeacon: true,
		hideBackButton: true,
		spotlightClicks: true,
		styles: { buttonNext: { display: 'none' } },
	},
	[TRAINING.IDS.HEALTH_TASK_1]: {
		target: '.chooseType',
		content:
			'Restoring health only requires you to complete one of the following tasks with someone else.',
		placement: 'center',
		disableBeacon: true,
		hideBackButton: true,
		styles: { tooltip: { fontSize: 20 } },
	},
	[TRAINING.IDS.HEALTH_TASK_2]: {
		target: '.chooseType',
		content: 'Choose any health task.',
		placement: 'top',
		disableBeacon: true,
		hideBackButton: true,
		spotlightClicks: true,
		styles: { tooltip: { fontSize: 20 }, buttonNext: { display: 'none' } },
	},
	[TRAINING.IDS.HEALTH_TASK_3]: {
		target: '.taskInfo',
		content: (
			<>
				<p>The task will tell you the name of the person you have to perform the task with.</p>
				<p>
					During your mission you will need to get out of your seat, find that person, and perform
					the task.
				</p>
			</>
		),
		placement: 'center',
		disableBeacon: true,
		hideBackButton: true,
		styles: { tooltip: { fontSize: 20 } },
	},
	[TRAINING.IDS.HEALTH_TASK_4]: {
		target: '.taskInfo',
		content: 'When the task is completed click DONE.',
		placement: 'bottom-end',
		disableBeacon: true,
		spotlightClicks: true,
		styles: { tooltip: { fontSize: 20 }, buttonNext: { display: 'none' } },
	},
	[TRAINING.IDS.HEALTH_4]: {
		target: '#healthBar',
		content: 'Your health went up!',
		placement: 'top',
		disableBeacon: true,
		hideBackButton: true,
	},
	[TRAINING.IDS.HEALTH_5]: {
		target: '.App',
		content: (
			<>
				<p>Warning! The person you do the task with will be asked to rate how well you did.</p>
				<SurveySmiles>
					{RATINGS.map(rating => (
						<rating.icon key={rating.value} />
					))}
				</SurveySmiles>
				<p>So do a good job.</p>
			</>
		),
		placement: 'center',
	},
	[TRAINING.IDS.REPAIRS_1]: {
		target: '.station-select',
		content: (
			<>
				<p>
					Lets check out how to make repairs! Click the blinking icon to switch to your repairs
					screen
				</p>
			</>
		),
		spotlightClicks: true,
		placement: 'top',
		styles: { buttonNext: { display: 'none' } },
		hideBackButton: true,
	},

	[TRAINING.IDS.REPAIRS_2]: {
		target: '.App',
		content: (
			<>
				<p>You will see alerts pop up when the repair station is activated.</p>
			</>
		),
		placement: 'center',
		disableBeacon: true,
		hideBackButton: true,
	},
	[TRAINING.IDS.REPAIRS_3]: {
		target: '.decksRobot',
		content:
			'To investigate an alert you must send your robot to the alert. Click the robot to begin.',
		placement: 'left',
		spotlightClicks: true,
		styles: { buttonNext: { display: 'none' } },
		hideBackButton: true,
		disableBeacon: true,
	},
	[TRAINING.IDS.REPAIRS_4]: {
		target: '.room-7',
		content: 'Click on this room to have the robot investigate this alert',
		placement: 'left',
		spotlightClicks: true,
		spotlightPadding: 0,
		hideBackButton: true,
		styles: { buttonNext: { display: 'none' } },
		disableBeacon: true,
	},
	[TRAINING.IDS.REPAIRS_5]: {
		target: '.room-7',
		content: 'Nice! Wait for the robot to arrive at the room.',
		placement: 'left',
		spotlightClicks: false,
		hideBackButton: true,
		styles: { buttonNext: { display: 'none' } },
		disableBeacon: true,
	},
	[TRAINING.IDS.REPAIRS_6]: {
		target: '.alert',
		content: 'The robot is now investigating the problem. Wait for the robot to finish!',
		placement: 'left',
		spotlightClicks: true,
		hideBackButton: true,
		styles: { buttonNext: { display: 'none' } },
		disableBeacon: true,
	},

	[TRAINING.IDS.ENGINEERING_PANEL_1]: {
		target: '.alert',
		content: (
			<>
				<p>
					Some tasks require certain systems to be on/off on your own engineering panel or on
					someone else’s.
				</p>
			</>
		),
		placement: 'right',
		spotlightClicks: true,
		hideBackButton: true,
		disableBeacon: true,
	},
	[TRAINING.IDS.ENGINEERING_PANEL_2]: {
		target: '.engPanels',
		content:
			'This is your engineering panel. It includes the systems that you are in charge of to help repair the ship',
		placement: 'right',
		spotlightClicks: true,
		disableBeacon: true,
	},
	[TRAINING.IDS.CREATIVE_CANVAS_ICON_CLICK]: {
		target: '.station-select',
		content: <p>On to the next tool! Click on the blinking icon to checkout the team canvas.</p>,
		placement: 'top',
		spotlightClicks: true,
		disableBeacon: true,
		styles: { tooltipContent: { padding: 0 }, buttonNext: { display: 'none' } },
	},
	[TRAINING.IDS.CREATIVE_CANVAS_EXPLAINED]: {
		target: `div[data-training-target="${TRAINING.IDS.CREATIVE_CANVAS_EXPLAINED}"]`,
		content:
			'Here is the team canvas where you can collaborate with your team and create solutions to succeed in your mission.',
		placement: 'bottom',
		disableBeacon: true,
		hideBackButton: true,
	},
	[TRAINING.IDS.CREATIVE_CANVAS_PROMPT_EXPLAINED]: {
		target: `div[data-training-target="${TRAINING.IDS.CREATIVE_CANVAS_PROMPT_EXPLAINED}"]`,
		content:
			'You will work with your team to draw something on the canvas that solves this prompt.',
		placement: 'left',
		disableBeacon: true,
		hideBackButton: true,
	},
	[TRAINING.IDS.CREATIVE_CANVAS_RUBRIC_AND_DATA_EXPLAINED]: {
		target: `div[data-training-target="${TRAINING.IDS.CREATIVE_CANVAS_RUBRIC_AND_DATA_EXPLAINED}"]`,
		content: (
			<div>
				<p>This section has two tabs:</p>
				<ul>
					<Li>
						<b>Rubric:</b> You will be graded by your flight director based on this list.
					</Li>
					<Li>
						<b>Data:</b> On the next tab, you will find helpful data for creating your solution.
					</Li>
				</ul>
			</div>
		),
		placement: 'left',
		spotlightClicks: true,
		disableBeacon: true,
		hideBackButton: true,
	},
	[TRAINING.IDS.SENSORS_1]: {
		target: '.station-select',
		content: <p>Moving on! Click on the blinking icon to check out how to use the map.</p>,
		placement: 'top',
		spotlightClicks: true,
		disableBeacon: true,
		styles: { tooltipContent: { padding: 0 }, buttonNext: { display: 'none' } },
	},
	[TRAINING.IDS.SENSORS_2]: {
		target: '.App',
		content: 'The sensors map is where you can discover important data for your mission.',
		placement: 'center',
		disableBeacon: true,
		hideBackButton: true,
	},
	[TRAINING.IDS.SENSORS_3]: {
		target: '.TRAINING_CONTACT',
		content: 'Start by clicking on this contact on the map!',
		placement: 'right',
		disableBeacon: true,
		spotlightClicks: true,
		hideBackButton: true,
		styles: { buttonNext: { display: 'none' } },
	},
	[TRAINING.IDS.MAP_OBJECT_ACTION_7]: {
		target: 'foreignObject[data-training="TRAINING_CONTACT-SCAN"]',
		content: 'Now scan the object.',
		placement: 'right',
		disableBeacon: true,
		spotlightClicks: true,
		hideBackButton: true,
		styles: { buttonNext: { display: 'none' } },
	},
	[TRAINING.IDS.MAP_OBJECT_ACTION_8]: {
		target: '.App',
		content:
			'You can only scan one object at a time. The more people that scan an object, the faster it will go. Work together to decide what to prioritize.',
		placement: 'center',
		disableBeacon: true,
		hideBackButton: true,
	},
	[TRAINING.IDS.COLLECTED_DATA_1]: {
		target: '.collectedData',
		content: (
			<>
				<p>When a scan completes, its data appears here.</p>
				<p>Some of the data you receive will be critical in making decisions. </p>
				<p>Some data is meant to distract you.</p>
			</>
		),
		placement: 'left',
		disableBeacon: true,
		spotlightClicks: true,
	},
	[TRAINING.IDS.COLLECTED_DATA_2]: {
		target: '.collectedData',
		content: (
			<>
				<p>Upvote data that you think is useful to the mission.</p>
				<p>Downvote data that you think is distracting.</p>
				<p>You can up/down vote 3 times for each data piece.</p>
			</>
		),
		placement: 'left',
		disableBeacon: true,
		spotlightClicks: true,
	},
	[TRAINING.IDS.PRO_TIP_1]: {
		target: '.App',
		content: (
			<>
				<p>Pro tip #1</p>
				<p>
					Scan everything you can and coordinate those scans with your class. The more data you have
					the easier the decisions will be.
				</p>
			</>
		),
		placement: 'center',
		disableBeacon: true,
	},
	[TRAINING.IDS.PRO_TIP_2]: {
		target: '.App',
		content: (
			<>
				<p>Pro tip #2</p>
				<p>
					If there are dangerous objects on the map, feel free to destroy them with your defensive
					systems or get them out of the way with your tractor beam.
				</p>
				<p>If there are characters that could be helpful, talk to them. </p>
			</>
		),
		placement: 'center',
		disableBeacon: true,
	},
	[TRAINING.IDS.PRO_TIP_3]: {
		target: '.App',
		content: (
			<>
				<p>Pro tip #3</p>
				<p>Nothing will happen on this screen without working together to make it happen.</p>
			</>
		),
		placement: 'center',
		disableBeacon: true,
	},
	[TRAINING.IDS.PRO_TIP_4]: {
		target: '.App',
		content: (
			<>
				<p>Final tips:</p>
				<ul>
					<Li>Do your best on answering questions, and you’ll get the best station upgrades</Li>
					<Li>Work with your class to scan as much as you can as quickly as you can</Li>
					<Li>Don’t die</Li>
				</ul>
			</>
		),
		placement: 'center',
		disableBeacon: true,
	},
	[TRAINING.IDS.END]: {
		target: '.App',
		content: 'You are done with training! Explore until your teacher starts the mission!',
		placement: 'center',
		disableBeacon: true,
		locale: { back: 'Restart', last: 'Close' },
	},
}

export const steps: JoyrideStepConfig[] = TRAINING.ORDER.map(
	trainingId => stepLookup[trainingId]
).filter(exists => exists)
