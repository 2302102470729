import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import { getHealthTaskInfo } from '../../../store/stores/staticData'
import { getStudentHealth } from '../../../store/stores/general'
import { sendMessage } from '../../../store/stores/webSocket'
import type { HealthTaskType, HealthTaskInfo, HealthTask } from '@mission.io/mission-toolkit'
import { ProgressBar, Button } from '../../../components/basics'
import { mapObject } from '../../../utility/functions'
import { PRIMARY_GREEN } from '../../../constants/styles'
import { useAdvanceTraining } from '../../../store/selectors/jrPlusState/training'

export default function ChooseType({ task }: { task: HealthTask }): React$Node {
	const dispatch = useDispatch()
	const advanceTraining = useAdvanceTraining()
	const health = useSelector(getStudentHealth)
	const selectType = (type: HealthTaskType) => {
		if (advanceTraining) {
			advanceTraining({ taskId: task.id, type })
		} else {
			dispatch(sendMessage('CHOOSE_HEALTH_TASK_TYPE', { taskId: task.id, type: type }))
		}
	}
	return (
		<>
			<div>
				<h2 style={{ margin: 0, padding: '4px' }}>Health: {health}%</h2>
				<ProgressBar percent={health > 0 ? health : 0.0001} />
			</div>
			<Types selectType={selectType} />
		</>
	)
}

const Type = styled(Button)`
	background-color: ${PRIMARY_GREEN};
	text-align: left;
	h3 {
		margin: 0;
		padding: 8px;
	}
`

const TypesStyled = styled.div`
	padding: 22px 0;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 10px;
`

function Types({ selectType }: { selectType: HealthTaskType => void }) {
	const taskInfo = useSelector(getHealthTaskInfo)
	return (
		<TypesStyled className="chooseType">
			{mapObject(taskInfo, (task: HealthTaskInfo) => {
				return (
					<Type key={task.name} onClick={() => selectType(task.type)}>
						{task.name}
					</Type>
				)
			})}
		</TypesStyled>
	)
}
