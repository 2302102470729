// @flow
import React, { useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components/macro'
import { Question } from '@mission.io/question-views'
import { getIsJuniorPlus, getStudentId, isTraining } from '../../store/stores/general'
import {
	isCurrentStationUpgradable,
	isStudentDoneWithQuestionPhase as isStudentDoneWithQuestionPhase_selector,
} from '../StationUpgradeOverlay/selectors'
import { ReactModal, Loading } from '../basics'
import { sendMessage } from '../../store/stores/webSocket'
import { useSelector, useDispatch } from 'react-redux'
import TextToSpeech from '../TextToSpeech'
import QuestionResponse from './QuestionResponse'
import { isQuestionResponseCorrect } from './QuestionResponse'

import {
	ALMOST_BLACK,
	DARKEST_SHADE,
	LIGHT_PURPLE,
	MODAL_Z_INDEX,
	PRIMARY_PURPLE,
} from '../../constants/styles'
import { getQuestionPhaseId } from '../StationUpgradeOverlay/selectors'
import { useAdvanceTraining } from '../../store/selectors/jrPlusState/training'
import type { Score, StudentAnswer } from '@mission.io/question-toolkit'
import type { QuestionPhase_Active } from '@mission.io/mission-toolkit'
import type { ReduxStore } from '../../store/rootReducer'
import {
	getQuestionPhase,
	getStudentQuestionData,
} from '../../store/selectors/jrPlusState/questionData'
import {
	getMostRecentStudentResponse,
	getQuestionPhaseQuestionIdsForStudent,
} from '@mission.io/mission-toolkit'
import { QUESTION_PHASE } from '@mission.io/mission-toolkit/constants'
import { getQuestionById } from '../../store/stores/staticData'
import { getIsQuestionSlot } from '../../store/selectors/sharedSelectors'
import { FRAME_WRAPPER_ID } from '../basics/ReactModal'

const customStyles = () => ({
	overlay: {
		backgroundColor: 'fade(#00000D, 70%)',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
})

/**
 * useCurrentQuestionForStudent - get the current question being asked or reviewed for the given studentId
 *
 * @param  {?string} studentId - the id of the student to get the question for
 *
 * @returns ?{                                                - falsy if the student should not be answering or reviewing a question
 *   questionData: { questionId: string, requestId: string }, - the id of the question along with the requestId
 *   response?: { score?: ?Score, answer: StudentAnswer },    - the response the student gave along with the score
 * }
 */
function useCurrentQuestionForStudent(
	studentId: ?string
): ?{
	questionData: { questionId: string, requestId: string },
	response?: { score?: ?Score, requestId?: ?string, answer: StudentAnswer },
} {
	return useSelector((store: ReduxStore) => {
		if (!studentId) {
			return null
		}
		if (getIsJuniorPlus(store)) {
			const _questionPhase = getQuestionPhase(store)
			if (!_questionPhase || _questionPhase.status !== QUESTION_PHASE.STATUS.ACTIVE) {
				return null
			}
			const questionPhase: QuestionPhase_Active = _questionPhase

			const studentQuestionData = getStudentQuestionData(store, studentId)

			if (!studentQuestionData) {
				return null
			}
			if (studentQuestionData.currentQuestion) {
				return { questionData: studentQuestionData.currentQuestion }
			}

			const questionIds = getQuestionPhaseQuestionIdsForStudent(questionPhase, studentId)
			if (!questionIds) {
				return null
			}

			let mostRecentAnswer = null
			let mostRecentAnswerQuestionId = null
			questionIds.forEach(questionId => {
				const answer = getMostRecentStudentResponse({
					questionId,
					state: store.jrPlusState,
					studentId,
				})
				if (!answer) {
					return
				}
				if (!mostRecentAnswer) {
					mostRecentAnswer = answer
					mostRecentAnswerQuestionId = questionId
					return
				}
				if (answer.response.responseTimestamp > mostRecentAnswer.response.responseTimestamp) {
					mostRecentAnswer = answer
					mostRecentAnswerQuestionId = questionId
				}
			})

			if (
				!mostRecentAnswer ||
				!mostRecentAnswerQuestionId ||
				mostRecentAnswer.response.responseTimestamp < questionPhase.startTimestamp
			) {
				return null
			}
			return {
				questionData: {
					questionId: mostRecentAnswerQuestionId,
					requestId: mostRecentAnswer.requestId,
				},
				response: mostRecentAnswer.response,
			}
		}

		if (!studentId || !getIsQuestionSlot(store)) {
			return null
		}

		const jrQuestion = store.jrState.questionData.currentQuestion
		const studentAnswers = jrQuestion
			? store.jrState.questionData.answers[studentId]?.[jrQuestion.questionId]
			: null
		return jrQuestion
			? {
					questionData: jrQuestion,
					response: studentAnswers?.length ? studentAnswers[studentAnswers.length - 1] : null,
			  }
			: null
	})
}

/**
 * The modal to display a question that a student must answer. This component is used for both types of missions.
 * @param {{questionState: CurrentQuestionState}} props
 * @returns {React$Node}
 */
export default function QuestionModal(): React$Node {
	const dispatch = useDispatch()
	const is4Plus = useSelector(getIsJuniorPlus)
	const trainingMode = useSelector(isTraining)
	const advanceTraining = useAdvanceTraining()
	const questionPhaseId = useSelector(getQuestionPhaseId)
	const currentStationIsUpgradable = useSelector(isCurrentStationUpgradable)
	const isStudentDoneWithQuestionPhase = useSelector(isStudentDoneWithQuestionPhase_selector)

	const waitingForRequest = useRef(false)
	const [waitingForNextQuestion, setWaitingForNextQuestion] = useState(false)
	const [awaitingAnimationFinish, setAwaitingAnimationFinish] = useState(false)
	const [awaitingReview, setAwaitingReview] = useState(false)

	const data = useCurrentQuestionForStudent(useSelector(store => getStudentId(store.general)))
	const { questionData, response } = data || {}
	const question = useSelector(store => getQuestionById(store, questionData?.questionId))
	const isCorrect = isQuestionResponseCorrect(response)

	const questionId = questionData?.questionId
	const requestId = questionData?.requestId

	useEffect(() => {
		waitingForRequest.current = false
		setAwaitingAnimationFinish(false)
		setAwaitingReview(false)
		setWaitingForNextQuestion(false)
	}, [questionId, requestId, response])

	useEffect(() => {
		if (response) {
			setAwaitingAnimationFinish(true)
			setAwaitingReview(true)
		}
	}, [response])

	const releaseQuestion = useCallback(() => {
		setAwaitingReview(false)
		if (is4Plus && questionPhaseId && !isStudentDoneWithQuestionPhase) {
			dispatch(sendMessage('ADVANCE_QUESTION_PHASE', { questionPhaseId }))
			setWaitingForNextQuestion(true)
		}
	}, [dispatch, questionPhaseId, isStudentDoneWithQuestionPhase, is4Plus])

	const onAnimationFinish = useCallback(() => {
		setAwaitingAnimationFinish(false)
	}, [])

	const onAdvance = () => {
		if (is4Plus) {
			if (trainingMode) {
				return
			}
			if (questionData && response) {
				releaseQuestion()
			}
			return
		}
	}
	// Show a message while the student is waiting for the question phase to complete.
	if (is4Plus && isStudentDoneWithQuestionPhase && !awaitingReview) {
		return (
			<StyledReactModal frameContent isOpen style={customStyles()} className="question-modal">
				<h1 className="my-auto text-center">Your station will begin shortly...</h1>
			</StyledReactModal>
		)
	}

	if (!questionData || !question) {
		return null
	}

	const _sendMessage = ({
		questionId,
		requestId,
		answer,
	}: {
		questionId: string,
		requestId: ?string,
		answer: mixed,
	}) => {
		if (waitingForRequest.current) {
			// Question has already been submitted and is currently pending. (This keeps students from spam clicking submit on a slow connection)
			return
		}
		if (advanceTraining) {
			advanceTraining({ answer })
		} else {
			const type = is4Plus ? 'QUESTION_ANSWERED_MESSAGE' : 'ANSWER_QUESTION'
			const pointEvent = { location: { x: '50%', y: '20%' } }
			dispatch(sendMessage(type, { questionId, requestId, answer }, pointEvent))
		}
		waitingForRequest.current = true
	}

	return (
		<StyledReactModal
			isOpen={true}
			frameContent
			onClose={onAdvance}
			onRequestClose={onAdvance}
			className="question-modal"
			style={customStyles()}>
			{waitingForNextQuestion ? (
				<Loading className="size-1/12" />
			) : response && awaitingAnimationFinish ? (
				<QuestionResponse
					questionResponse={response}
					is4Plus={is4Plus}
					releaseQuestion={releaseQuestion}
					finishedAnimation={onAnimationFinish}
				/>
			) : isCorrect === false || !response || !is4Plus ? (
				<>
					{is4Plus && !response && (
						<DeclarativePhrase>
							ANSWER QUESTION TO {currentStationIsUpgradable ? 'UPGRADE' : 'USE STATION'}
						</DeclarativePhrase>
					)}
					{response && <DeclarativePhrase>Please review your response</DeclarativePhrase>}
					<Question
						css="overflow: auto;"
						parentRef={{ current: document.getElementById(FRAME_WRAPPER_ID) }}
						question={question}
						studentResponse={response ?? undefined}
						review={response ? 'INSTANT' : undefined}
						requestId={questionData.requestId}
						sendMessage={_sendMessage}
						textToSpeech={TextToSpeech}
						trainingMode={trainingMode}
						isJrPlus={is4Plus}
					/>
					{response && is4Plus && <ContinueButton onClick={onAdvance}>Continue</ContinueButton>}
				</>
			) : null}
		</StyledReactModal>
	)
}

const ContinueButton = styled.button`
	padding: ${({ theme }) => `${theme.spacing} ${theme.spacing5x}`};
	background-color: ${({ theme }) => `${theme.secondaryColor}`};
	color: white;
	margin-top: ${({ theme }) => `-${theme.spacing2x}`};
	font-family: Orbitron;
	font-weight: ${({ theme }) => theme.fontBold};
	font-size: ${({ theme }) => theme.fontm};
	line-height: 23px;
	cursor: pointer;
	border: none;
	border-radius: ${({ theme }) => theme.spacingHalf};

	bottom: 32px;
	left: 50%;
	position: absolute;
	transform: translateX(-50%);
`

const DeclarativePhrase: StyledType<> = styled.div`
	margin: 0 3%;
	text-align: center;
	padding: 2%;
	border-radius: 25px;
	color: white;
`

const StyledReactModal = styled(ReactModal)`
	&::-webkit-scrollbar {
		width: 10px;
	}
	&::-webkit-scrollbar-track {
		box-shadow: inset 0 0 6px ${LIGHT_PURPLE};
		border-radius: 10px;
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 10px;
		box-shadow: inset 0 0 6px ${LIGHT_PURPLE};
	}
	position: absolute;
	border: none;
	background-color: ${PRIMARY_PURPLE};
	display: flex;
	flex-direction: column;
	border-radius: 4px;
	outline: none;
	padding: 24px 24px 16px;
	z-index: ${MODAL_Z_INDEX};
	min-width: max(300px, 60%);
	max-width: 80%;
	font-size: ${({ theme }) => theme.fontm};
	color: ${ALMOST_BLACK};
	min-height: 20%;
	max-height: 90%;
	box-shadow: 0 0 1vh ${DARKEST_SHADE};
`
