import SAT from 'sat'

const COLLISION_TYPES = {
	POLYGON: 'POLYGON',
	CIRCLE: 'CIRCLE',
}

export type Collision = {
	type: 'POLYGON' | 'CIRCLE',
	collidable: boolean,
	bounds: {},
}

/**
 * check if two bounds collide
 *
 * @param {Collision} collidable1 a collision bound to test against
 * @param {Collision} collidable2 a collision bound to test against
 * @return {boolean} true if the two collidable objects intersect
 **/
export function doesCollide(collidable1: Collision, collidable2: Collision): boolean {
	if (!collidable1.collidable || !collidable2.collidable) {
		return false
	}

	if (collidable1.type === COLLISION_TYPES.POLYGON) {
		if (collidable2.type === COLLISION_TYPES.POLYGON) {
			return SAT.testPolygonPolygon(collidable1.bounds, collidable2.bounds)
		}

		return SAT.testPolygonCircle(collidable1.bounds, collidable2.bounds)
	}

	if (collidable2.type === COLLISION_TYPES.POLYGON) {
		return SAT.testCirclePolygon(collidable1.bounds, collidable2.bounds)
	}

	return SAT.testCircleCircle(collidable1.bounds, collidable2.bounds)
}

export default COLLISION_TYPES
