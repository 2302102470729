import { keyframes } from 'styled-components'

export const growIn: any = keyframes`
    to {
        transform: scale(1);
    }
`

export const fadeIn: any = keyframes`
	to {
		opacity: 1;
	}
`

export const fadeOut: any = keyframes`
	to {
		opacity: 0;
	}
`

export const shrink: any = keyframes`
	to {
		transform: scale(0);
	}
`
