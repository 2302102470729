import { getThrusterClassDirection } from '../../store/selectors/sharedSelectors'

import React from 'react'
import { useSelector } from 'react-redux'

type Props = { children: React$Node }

/**
 * Animates the thruster directional arrow to display which way the class as a whole
 * is moving on the thrusters station.
 * @param {{ children: React$Node }} props any props that an svg element accepts
 */
export default function TeamDirection({ children, ...props }: Props): React$Node {
	const classDirection = useSelector(getThrusterClassDirection)

	if (!classDirection) {
		return null
	}

	return (
		<svg {...props}>
			{children}
			<g
				id="animated-team-direction"
				style={{
					transition: 'transform 200ms',
					transform: `rotate(${classDirection.angle + Math.PI / 2}rad)`,
					transformOrigin: '74.7332px 74.7332px',
				}}>
				{classDirection.magnitude && (
					<path
						d="M55.0149 66.054L76.2358 44.834L97.1669 65.765C97.7997 66.398 98.5511 66.9001 99.3781 67.2427C100.205 67.5853 101.091 67.7616 101.986 67.7616C102.881 67.7616 103.768 67.5853 104.595 67.2427C105.422 66.9001 106.173 66.398 106.806 65.765L114.045 58.526C114.678 57.8931 115.18 57.1418 115.523 56.3148C115.865 55.4879 116.042 54.6016 116.042 53.7065C116.042 52.8114 115.865 51.9251 115.523 51.0981C115.18 50.2712 114.678 49.5198 114.045 48.887L75.9448 10.787L37.8448 48.887C36.5669 50.1653 35.8491 51.8989 35.8491 53.7065C35.8491 55.514 36.5669 57.2476 37.8448 58.526L45.3739 66.054C46.0067 66.6874 46.7582 67.1899 47.5853 67.5327C48.4125 67.8756 49.299 68.052 50.1943 68.052C51.0897 68.052 51.9763 67.8756 52.8035 67.5327C53.6306 67.1899 54.3821 66.6874 55.0149 66.054Z"
						fill="#00FFFF"
						fillOpacity="0.5"
						stroke="#00FFFF"
						strokeWidth="3"
						strokeMiterlimit="10"
					/>
				)}
				<path
					d="M74.7332 85.11C80.6647 85.11 85.4732 80.3015 85.4732 74.37C85.4732 68.4385 80.6647 63.63 74.7332 63.63C68.8016 63.63 63.9932 68.4385 63.9932 74.37C63.9932 80.3015 68.8016 85.11 74.7332 85.11Z"
					fill="#00FFFF"
				/>
			</g>
		</svg>
	)
}
