import React, { useState, useCallback } from 'react'
import styled from 'styled-components/macro'
import { useOnClickOutside } from '../../../utility/hooks'
import { useCanvasMaterial, type CanvasMedia } from '../connect/hooks/useCanvasMaterial'
import { createCustomImageFromAsset } from '../customShapes/CustomImageShapeUtil'
import {
	useEditor,
	type Editor,
	track,
	useAssetUrls,
	GeoShapeGeoStyle,
	useEvents,
	DefaultColorStyle as ColorStyle,
	getDefaultColorTheme,
} from '@tldraw/tldraw'
import {
	PencilIcon,
	ImageIcon,
	SelectIcon,
	TextIcon,
	EraserIcon,
	ShapeIcon,
} from '../../../images/canvas'

type ToolType = 'select' | 'eraser' | 'draw' | 'text' | 'geo' | 'image'

export const ToolboxContainer: StyledType<> = styled.div.attrs({ className: 'z-[--layer-menus]' })`
	position: absolute;
	--toolbox-spacer: 8px;

	top: 0;
	height: 100%;
	width: var(--toolbox-width);
	margin-left: calc(-1 * var(--toolbox-width));
	> :not(:last-child) {
		margin-bottom: var(--toolbox-spacer);
	}
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`

/**
 * A button to select a tool in the toolbox.
 * @param {ToolType} props.type type of tool as accepted by tldraw
 * @param {?React$Node} props.children content for the button.
 *
 * @returns {React$Node}
 */
function _SelectButton({ type, children }: { type: ToolType, children: React$Node }): React$Node {
	const editor = useEditor()
	// const activeTool = app.useStore(app => {
	// 	return app.appState.activeTool
	// })
	const activeTool = editor.currentToolId
	const [showExtension, setShowExtension] = useState(false)
	const isActive = (toolContent[type].isActive ?? defaultIsActive)(activeTool, {
		type,
		expanded: showExtension,
	})
	const ref = useOnClickOutside(() => {
		if (showExtension) {
			setShowExtension(false)
		}
	})

	const Icon = toolContent[type].icon

	return (
		<CircularButton
			ref={ref}
			onClick={() => {
				;(toolContent[type].onClick ?? defaultOnClick)(editor, { type })
				setShowExtension(true)
			}}
			$active={isActive}>
			<Extension
				type={type}
				show={showExtension}
				isToolActive={isActive}
				hide={() => {
					setShowExtension(false)
				}}
			/>
			<Icon />
		</CircularButton>
	)
}
export const SelectButton: ({ type: ToolType, children: React$Node }) => React$Node = track(
	_SelectButton
)
const CircularButton = styled.button`
	display: flex;
	padding: 6px;
	align-items: center;
	justify-content: center;
	// flex: 1;

	max-height: 12%;
	max-width: 100%;

	position: relative;
	border-radius: 50%;
	border: 3px solid #0f193c;
	background-image: linear-gradient(
		var(--color-primary-darker) 0%,
		var(--color-primary-darker) 26%,
		var(--color-primary-darkest) 99%
	);

	${({ $active, theme }) => $active && `border-color: var(--color-accent-aqua);`}

	> svg {
		fill: var(--color-accent-aqua);
		height: 2em;
		width: 2em;
		margin: auto;
	}
`

/**
 * Default behavior when a tool is clicked. Make the tool active on the app
 * @param {TldrawApp} app
 * @param {ToolType} type
 */
const defaultOnClick = (editor: Editor, { type }: { type: ToolType }) => {
	if (type === 'image') {
		throw Error('image tools are custom tools and cannot use the default on click event.')
	}
	editor.setSelectedTool(type)
}
/**
 * Default callback to determine if a tool is active on the app
 * @param {ToolType} snapshot the snapshot of the app returned from TLDrawApp.useStore
 * @param {ToolType} type the type of the tool in question
 * @returns {boolean}
 */
const defaultIsActive = (activeTool, { type }: { type: ToolType, expanded: boolean }) => {
	return activeTool === type
}

type SharedToolContent = {|
	icon: () => React$Node,
	onClick?: (Editor, { type: ToolType }) => void,
	isActive?: (ToolType, { type: ToolType, expanded: boolean }) => boolean,
|}

type ExtendableToolContent = {|
	extensionWrapperStyle?: string,
	RenderExtension: ({
		editor: Editor,
		close: () => void,
	}) => React$Node,
|}

/**
 * Content used to uniquely define each specific tool. Some tools have additional content that is displayed when the tool is clicked.
 */
const toolContent: {
	[ToolType]:
		| {|
				type: 'draw' | 'geo' | 'image',
				...ExtendableToolContent,
				...SharedToolContent,
		  |}
		| {|
				type: 'select' | 'eraser' | 'text',
				...SharedToolContent,
		  |},
} = {
	draw: {
		type: 'draw',
		icon: PencilIcon,
		extensionWrapperStyle: `--item-width: 1.4em;`,
		// Renders a list of colors that can be chosen to draw with.
		RenderExtension: track(() => {
			const editor = useEditor()
			const theme = getDefaultColorTheme(editor)
			const handleColorChange = useCallback(
				(value: string) => {
					editor.setStyle(ColorStyle, value)
				},
				[editor]
			)
			const current = editor.sharedStyles.getAsKnownValue(ColorStyle)
			return ColorStyle.values.map(value => {
				const active = current === value
				return (
					<ExtensionItem
						key={value}
						onClick={() => handleColorChange(value)}
						style={{
							backgroundColor: theme[value].solid,
							borderRadius: '50%',
							border: active ? `2px solid var(--color-accent-aqua)` : 'none',
						}}
					/>
				)
			})
		}),
	},
	select: { type: 'select', icon: SelectIcon },
	eraser: { type: 'eraser', icon: EraserIcon },
	text: { type: 'text', icon: TextIcon },
	image: {
		type: 'image',
		icon: ImageIcon,
		onClick: editor => {
			// app.selectTool('select')
		},
		isActive: (activeTool, { expanded }) => {
			return expanded
		},
		extensionWrapperStyle: `--total-cols: 5; `,
		// Renders a list of images that are specifically defined in the tldraw app assets. When the image icon is selected this list appears. When an image is clicked it is added to the center of the canvas.
		RenderExtension: track(({ editor, close }: { editor: any, close: () => void }): React$Node => {
			const images = useCanvasMaterial().images
			// Adds an image shape of the selected asset to the center of the canvas
			const selectImage = async (media: CanvasMedia) => {
				const assetId = 'asset:' + media._id
				let asset = editor.getAssetById(assetId)
				let shouldAlsoCreateAsset = false
				if (!asset) {
					shouldAlsoCreateAsset = true
					asset = await editor.externalContentManager.createAssetFromUrl(editor, media.url, {
						text: media.text,
						type: media.type,
						id: assetId,
					})
				}
				editor.batch(() => {
					if (shouldAlsoCreateAsset) {
						editor.createAssets([asset])
					}
					createCustomImageFromAsset(editor, asset, editor.viewportPageBounds.center)
					editor.setSelectedTool('select')
				})
				close()
			}
			return images.map(image => (
				<ExtensionItem key={image._id} style={{ backgroundColor: 'white', padding: '2px' }}>
					<img
						alt={image.text}
						onDragEnd={e => {
							e.stopPropagation()
							e.preventDefault()
							selectImage(image)
						}}
						onClick={e => {
							e.stopPropagation()
							e.preventDefault()
							selectImage(image)
						}}
						src={image.url}
						css={`
							width: 100%;
							height: 100%;
							object-fit: contain;
							border-radius: 4px;
						`}
					/>
				</ExtensionItem>
			))
		}),
	},
	geo: {
		type: 'geo',
		icon: ShapeIcon,
		extensionWrapperStyle: '--total-cols: 6;',
		// Renders a list of shapes that can be chosen to apply to the canvas.
		RenderExtension: track((): React$Node => {
			const editor = useEditor()
			const trackEvent = useEvents()
			const geoState = editor.sharedStyles.getAsKnownValue(GeoShapeGeoStyle)

			const shapes = [...GeoShapeGeoStyle.values].map(id => ({
				id,
				icon: 'geo-' + id,
				label: `tool.${id}`,
				onSelect(source) {
					editor.batch(() => {
						editor.updateInstanceState(
							{
								stylesForNextShape: {
									...editor.instanceState.stylesForNextShape,
									[GeoShapeGeoStyle.id]: id,
								},
							},
							true
						)
						editor.setSelectedTool('geo')
						trackEvent('select-tool', { source, id: `geo-${id}` })
					})
				},
			}))
			const assetUrls = useAssetUrls()
			return shapes.map(shape => {
				const active = geoState === shape.id
				return (
					<ExtensionItem
						key={shape.id}
						onClick={shape.onSelect}
						style={{ backgroundColor: active ? 'aqua' : 'white' }}>
						<img src={assetUrls.icons[shape.icon]} alt={shape.label} />
					</ExtensionItem>
				)
			})
		}),
	},
}

/**
 * A component that displays additional styles or content for a tool when the tool is clicked.
 * The div will be displayed to the right of the tool button and will disappear on the next tap or click of the canvas
 */
function _Extension({
	type,
	show,
	hide,
	isToolActive,
}: {
	type: ToolType,
	hide: () => void,
	show: boolean,
	isToolActive: boolean,
}) {
	const editor = useEditor()

	const content = toolContent[type]
	if (content.type === 'draw' || content.type === 'geo' || content.type === 'image') {
		const Component = content.RenderExtension
		return (
			<div
				css="position: absolute; left: calc(100% + 1px); top: 1px; height: 100%; width: 100%; display: flex; align-items: center;"
				className="pointer-events-none">
				<ArrowRight $highlight={isToolActive} className="pointer-events-auto" />
				{show && (
					<ExtensionStyled
						$wrapper={content.extensionWrapperStyle ?? null}
						className="pointer-events-auto">
						<Component editor={editor} close={hide} />
					</ExtensionStyled>
				)}
			</div>
		)
	}
	return null
}
const Extension = track(_Extension)
const ArrowRight = styled.div`
	width: 0;
	height: 0;
	z-index; 1;
	border-top: 9px solid transparent;
	border-bottom: 9px solid transparent;
	${({ $highlight }) => `
	border-left: 9px solid ${$highlight ? 'aqua' : '#0f193c'};
	`}
		::after {
			z-index: 0;
			content: '';
			width: 0;
			height: 0;
			top: 50%;
			left: 0;
			transform: translateY(-50%);
			position: absolute;
			border-top: 6px solid transparent;
			border-bottom: 6px solid transparent;
			border-left: 6px solid aqua;
		}
	
`

const ExtensionStyled = styled.div`
	--item-width: 2.8em;
	--total-cols: 5;
	--grid-gap: 4px;

	display: flex;
	padding: 8px;
	justify-content: space-around;
	border-radius: 4px;
	background-color: var(--color-primary-darker);
	height: fit-content;
	flex-wrap: wrap;
	grid-gap: var(--grid-gap);
	align-content: flex-start;
	justify-content: flex-start;
	${({ $wrapper }) => $wrapper ?? ''}

	min-width: calc(
		var(--item-width) * var(--total-cols) + var(--grid-gap) * (var(--total-cols) - 1) + 16px
	);
`

const ExtensionItem = styled.div`
	height: var(--item-width);
	width: var(--item-width);
	cursor: pointer;
	border-radius: 4px;
`
