import React from 'react'
import Toggle from '../Components/Toggle'
import { COMPONENT_TYPES } from '../../../../../../store/selectors/jrPlusState/engineering'
import styled from 'styled-components'
import { SecondaryLayer } from '../SharedColors'
import type { ToggleData as ToggleComponent } from '@mission.io/mission-toolkit'
import type { PopulatedPanel } from '../../../../../../store/selectors/jrPlusState/engineering'

const StyledRow = styled.div`
	display: flex;
	justify-content: space-between;
	&:not(:last-child) {
		margin-bottom: 8px;
	}
`

export default function ButtonPanel({ panel }: { panel: PopulatedPanel }): React$Node {
	let components: { [componentType: string]: ToggleComponent[] } = {}
	panel.components.forEach(component => {
		if (component.type !== COMPONENT_TYPES.TOGGLE) {
			return
		}
		if (!components[component.type]) {
			components[component.type] = []
		}
		components[component.type].push(component)
	})
	Object.keys(components).forEach(type =>
		components[type].sort((a, b) => a.name.localeCompare(b.name))
	)
	let renderedComponents = []
	Object.keys(components).map(type =>
		components[type].forEach(component => {
			renderedComponents.push(<Toggle component={component} key={component.id} />)
		})
	)
	const rowCount = Math.ceil(Math.sqrt(renderedComponents.length))
	const columnCount = rowCount
	const rows: ToggleComponent[][] = []
	for (let i = 0; i < rowCount; i++) {
		rows.push([])
	}
	renderedComponents.forEach((component, index) => {
		rows[Math.floor(index / columnCount)].push(component)
	})
	return (
		<SecondaryLayer>
			{rows.map((components, index) => {
				return <StyledRow key={index}>{components}</StyledRow>
			})}
		</SecondaryLayer>
	)
}
