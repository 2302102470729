/** Converts a url to a data URI */
export function getDataURIFromURL(url: string): Promise<string> {
	return new Promise(resolve => {
		return getFileFromUrl(url).then((file: Blob) => {
			var fr = new FileReader()
			fr.onload = function() {
				resolve(this.result)
			}
			fr.readAsDataURL(file) // async call
		})
	})
}

// Loads media file from a url
const loadDataUrl = (
	url: string,
	options: {
		resolve: Blob => void,
		reject: Error => void,
		headers?: string[][],
	}
) => {
	const { resolve, reject, headers } = options
	const xhr = new XMLHttpRequest()
	xhr.open('get', url)
	xhr.responseType = 'blob'
	if (headers) {
		headers.forEach(header => xhr.setRequestHeader(...header))
	}
	xhr.onload = function() {
		resolve(xhr.response) // async call
	}
	xhr.onerror = function(e) {
		reject(new Error('Failed to fetch with error: ' + JSON.stringify(e)))
	}
	xhr.send()
}

/** Loads media file from a url. Tries twice, the second time will clear the cache for the url. */
function getFileFromUrl(url: string): Promise<Blob> {
	return new Promise((resolve, reject) => {
		const onFirstError = function(e) {
			// Try again with a cleared cache if the first attempt fails
			loadDataUrl(url, {
				resolve,
				reject,
				headers: [['Cache-Control', 'no-cache, no-store, max-age=0']],
			})
		}
		loadDataUrl(url, {
			resolve,
			reject: onFirstError,
		})
	})
}
