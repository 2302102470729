import React, { useRef, useCallback } from 'react'
import { animated, useTransition } from '@react-spring/web'
import styled from 'styled-components'
import type { LaserProps as Props } from './upgradeLookup'

export const SIZE = 800

export default function Laser({ show, x, checkImpact, onFinish }: Props): React$Node {
	const ref = useRef()

	const onChange = useCallback(() => {
		checkImpact && checkImpact(ref)
	}, [checkImpact])
	const transitions = useTransition(show, {
		from: { y: 0, opacity: 1 },
		enter: { y: 198, opacity: 1, config: { tension: 400, friction: 15 } },
		leave: { y: 198, opacity: 0, config: { duration: 500 } },
		onRest: (_, __, hasEntered) => {
			if (hasEntered && onFinish) {
				onFinish()
			}
		},
		onChange,
	})

	return transitions(
		(styles, show) =>
			show && (
				<Svg
					ref={ref}
					x={x}
					viewBox="0 0 8 40 "
					width={SIZE}
					style={{ opacity: styles.opacity, overflow: 'visible' }}>
					<g>
						<animated.rect
							className="laser-1"
							width="8"
							height={styles.y.to(o => o + 4)}
							x="0"
							y={styles.y.to(o => -1 * o)}
							rx="4"
						/>
						<animated.rect
							className="laser-2"
							width="4"
							height={styles.y.to(o => o + 2)}
							x="2"
							y={styles.y.to(o => -1 * (o - 2))}
							rx="4"
						/>
					</g>
				</Svg>
			)
	)
}
const Svg = styled(animated.svg)`
	.laser-1 {
		fill: #ffe19f;
	}

	.laser-2 {
		fill: #fbffd2;
	}
`
