import React, { useState, useRef, useEffect } from 'react'
import { useSpring, animated } from '@react-spring/web'
import AtomImage from '../../images/Atom.svg'

const MAX_SPEED = 1.25

const A_VERY_SMALL_AMOUNT = 0.1

export default function Atom({
	radius,
	centerX,
	centerY,
	speed,
}: {
	radius: number,
	centerX: number,
	centerY: number,
	speed: number,
}): React$Node {
	const imageRef = useRef()

	speed *= MAX_SPEED

	const [startPosition, setStartPosition] = useState(() => getRandomPosition())
	const [lastPosition, setLastPosition] = useState(() => {
		return { x: startPosition.x, y: startPosition.y }
	})
	const [endPosition, setEndPosition] = useState(() =>
		getNextPosition(startPosition.x, startPosition.y, startPosition.angle)
	)
	const [width, setWidth] = useState(() => 0)
	const [config, setConfig] = useState(() =>
		generateConfig(startPosition, lastPosition, speed, radius)
	)

	const updateWidth = () => setWidth((imageRef.current && imageRef.current.clientWidth) || 0)

	// will only change if speed, startPosition, endPosition, or radius change (lastPosition will cause a recreation of react spring on every render)
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => setConfig(generateConfig(lastPosition, endPosition, speed, radius)), [
		speed,
		startPosition,
		endPosition,
		radius,
	])

	useEffect(() => {
		window.addEventListener('resize', updateWidth)
		return () => {
			window.removeEventListener('resize', updateWidth)
		}
	}, [imageRef])

	const getRadius = () => radius - width

	const springProps = useSpring({
		to: { x: endPosition.x, y: endPosition.y },
		from: { x: startPosition.x, y: startPosition.y },
		onRest: () => {
			let nextPosition = getNextPosition(endPosition.x, endPosition.y, endPosition.angle)

			// Check if in invalid State due to force chaoticness
			if (
				isNaN(endPosition.x) ||
				isNaN(endPosition.y) ||
				getDistance(endPosition.x, endPosition.y, nextPosition.x, nextPosition.y, radius) / radius <
					A_VERY_SMALL_AMOUNT
			) {
				const newStartPosition = {
					x: Math.random() * 2 - 1,
					y: Math.random() * 2 - 1,
					angle: Math.random() * 2 * Math.PI,
				}
				setStartPosition(newStartPosition)
				setEndPosition(
					getNextPosition(newStartPosition.x, newStartPosition.y, newStartPosition.angle)
				)
				return
			}

			setStartPosition(endPosition)
			setEndPosition(nextPosition)
		},
		onFrame: currentProps => {
			setLastPosition({ x: currentProps.x, y: currentProps.y })
		},
		config,
	})

	return (
		<animated.div
			style={{
				position: 'absolute',
				pointerEvents: 'none',
				top: springProps.y.to(y => centerY + getRadius() * y - width / 2 + 'px'),
				left: springProps.x.to(x => centerX + getRadius() * x - width / 2 + 'px'),
			}}>
			<img
				src={AtomImage}
				style={{ width: radius * 0.1 + 'px' }}
				ref={imageRef}
				alt="Atom"
				onLoad={updateWidth}
			/>
		</animated.div>
	)
}

/**
 * @param {number} xInt the xIntercept of the line and the circle
 * @param {number} yInt the yIntercept of the line and the circle
 * @param { number} angle the angle [radians] of the trajectory (with respect to x axis)
 **/
function getNextPosition(
	xInt: number,
	yInt: number,
	angle: number
): { x: number, y: number, angle: number } {
	// This function is not intended to give a perfect bounce, it intends to give a choatic bounce

	if (angle % Math.PI === Math.PI / 2) {
		return { x: xInt, y: -yInt, angle: (angle + Math.PI) % (2 * Math.PI) }
	}

	const ricochetAngle = Math.atan(yInt / xInt) + angle

	if (ricochetAngle % Math.PI === Math.PI / 2) {
		return { x: xInt, y: -yInt, angle: ricochetAngle }
	}
	let slope = Math.tan(ricochetAngle)
	let discriminant = xInt * (yInt + slope * 0.5) - (xInt + 0.5) * yInt
	let dx = 0.5
	let dy = slope * 0.5
	let dr = Math.sqrt(dx * dx + dy * dy)
	let sign = 1
	let x = calculateX(dx, dy, dr, discriminant, sign)
	let y = calculateY(dx, dy, dr, discriminant, sign)
	if (getDistance(xInt, yInt, x, y, 1) <= A_VERY_SMALL_AMOUNT) {
		sign = -1
		x = calculateX(dx, dy, dr, discriminant, sign)
		y = calculateY(dx, dy, dr, discriminant, sign)
	}
	return { x, y, angle: ricochetAngle }
}

/**
 * calculate X for a line based on the deltas ans discriminant
 * @param  {number} dx   the change of x over the line
 * @param  {number} dy   the change of y over the line
 * @param  {number} dr   the distance between the linear x and y
 * @param  {number} discriminant    the discriminant of the line
 * @param  {number} sign the sign (which of the two possibilities) to calculate
 * @return {number}      the calculated target X position
 */
function calculateX(
	dx: number,
	dy: number,
	dr: number,
	discriminant: number,
	sign: 1 | -1
): number {
	let sg_num = 1
	if (dy < 0) {
		sg_num = -1
	}
	return (
		(discriminant * dy +
			sign * sg_num * dx * Math.sqrt(Math.abs(dr * dr - discriminant * discriminant))) /
		(dr * dr)
	)
}

/**
 * calculate Y for a line based on the deltas and discriminant
 * @param  {number} dx   the change of x over the line
 * @param  {number} dy   the change of y over the line
 * @param  {number} dr   the distance between the linear x and y
 * @param  {number} discriminant    the discriminant of the line
 * @param  {number} sign the sign (which of the two possibilities) to calculate
 * @return {number}      the calculated target X position
 */
function calculateY(
	dx: number,
	dy: number,
	dr: number,
	discriminant: number,
	sign: 1 | -1
): number {
	return -calculateX(Math.abs(dy), dx, dr, discriminant, -sign)
}

function generateConfig(
	position1: { x: number, y: number },
	position2: { x: number, y: number },
	speed: number,
	radius: number
) {
	if (speed > 0) {
		return {
			duration: getDistance(position1.x, position1.y, position2.x, position2.y, radius) / speed,
		}
	}
	return { duration: Infinity }
}

function getDistance(x1: number, y1: number, x2: number, y2: number, radius: number): number {
	return radius * Math.sqrt((x1 - x2) ** 2 + (y1 - y2) ** 2)
}

function getRandomPosition(): { x: number, y: number, angle: number } {
	const angle = Math.random() * 2 * Math.PI
	return { x: Math.random() * Math.cos(angle), y: Math.random() * Math.sin(angle), angle }
}
