import React, { useEffect, useRef } from 'react'
import { RoomControlsContext } from '../../videoChat/RoomControls'

/**
 * Attaches audio for participant with 'id'
 */
export function ParticipantAudio(props: { id: string }): React$Node {
	const roomControls = React.useContext(RoomControlsContext)
	const audioRef = useRef()
	const track = roomControls && roomControls.tracks[props.id]
	const audioTracks =
		track?.participant?.audioTracks && Array.from(track.participant.audioTracks.values())
	const audioTrack = audioTracks && audioTracks.length > 0 && audioTracks[0].track

	// Attaches audio track to audio source on this component when participant track changes
	useEffect(() => {
		if (audioTrack) {
			audioTrack.attach(audioRef.current)
			return () => {
				audioTrack.detach()
			}
		}
	}, [audioTrack])

	return <audio ref={audioRef} />
}
