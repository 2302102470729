import { REDUX_WEBSOCKET_MESSAGE } from '../../utility/websocketConstants'
import { disconnect } from '@giantmachines/redux-websocket'

import {
	getMissionId,
	getShouldRedirectToMissionSurveyOnMissionEnd,
	getStation,
} from '../stores/general'

import { requestHandledCallbackContainer } from '../stores/webSocket'
import {
	JR_PATCH,
	JR_FULL_STATE,
	JR_PLUS_PATCH,
	JR_PLUS_FULL_STATE,
	SET_MISSION_ANALYTICS,
	STUDENT_DETAILS,
	DISPLAY_ERROR,
	STATIC_DATA,
	JUNIOR_PLUS_STATIC_DATA,
	JUNIOR_STATIC_DATA,
	LOGIN_REQUIRED,
	ESSENTIAL_DATA,
	FINISHED_REQUEST,
	RESET_MISSION,
	MISSION_ENDED,
	SET_VOLUME,
	SHOW_MODAL,
	UPDATE_OPTIONAL_DATA,
} from '../actionTypes'
import { POINT_EVENT_RESULT } from '../stores/points'
import webSocketActions from '../webSocketActions'
import type { MiddleWare } from './general'
import { isWantingToRunMissionAsControl } from '../../components/AppSetup'
import { redirectToDashboard } from '../../utility/functions'

// Websocket message types that should be used as redux actions. If the websocket message type is in this object, the message will be passed through as a redux action
const WEBSOCKET_ACTION_TYPES = {
	[JR_PATCH]: JR_PATCH,
	[JR_PLUS_PATCH]: JR_PLUS_PATCH,
	[JR_FULL_STATE]: JR_FULL_STATE,
	[JR_PLUS_FULL_STATE]: JR_PLUS_FULL_STATE,
	[STUDENT_DETAILS]: STUDENT_DETAILS,
	[STATIC_DATA]: STATIC_DATA,
	[JUNIOR_PLUS_STATIC_DATA]: JUNIOR_PLUS_STATIC_DATA,
	[JUNIOR_STATIC_DATA]: JUNIOR_STATIC_DATA,
	[SET_MISSION_ANALYTICS]: SET_MISSION_ANALYTICS,
	[LOGIN_REQUIRED]: LOGIN_REQUIRED,
	[RESET_MISSION]: RESET_MISSION,
	[ESSENTIAL_DATA]: ESSENTIAL_DATA,
	[SET_VOLUME]: SET_VOLUME,
	[SHOW_MODAL]: SHOW_MODAL,
	[UPDATE_OPTIONAL_DATA]: UPDATE_OPTIONAL_DATA,
}

// this stores the end promise of a linked list (representing a First In First Out queue) used to order the
// handling of websocket messages from the server.
let nextMessagePromise = Promise.resolve()

/**
 * handleServerMessage - handles the message from the server
 *
 * @param {string} text - the text (JSON) from the server
 * @param {any} action - the @giantmachines/redux-websocket message received action
 * @param {any} store - the redux store
 * @param {any} next - the redux middleware next function
 */
const handleServerMessage = (text: string, action, store, next) => {
	try {
		const webSocketMessage = JSON.parse(text)

		if (webSocketMessage && webSocketMessage.type) {
			// side effects
			if (webSocketActions.hasOwnProperty(webSocketMessage.type)) {
				webSocketActions[webSocketMessage.type](webSocketMessage, store)
				next(webSocketMessage)
			}
			// redux store
			else if (WEBSOCKET_ACTION_TYPES.hasOwnProperty(webSocketMessage.type)) {
				next(webSocketMessage)
			}
			// messages that require more data from the store
			else if (webSocketMessage.type === POINT_EVENT_RESULT) {
				next({
					type: webSocketMessage.type,
					payload: { ...webSocketMessage.payload, currentStation: getStation(store.getState()) },
				})
			}
			// display error
			else if (webSocketMessage.type === DISPLAY_ERROR) {
				const { station, ...rest } = webSocketMessage.payload
				next({ type: `${station}_ERROR`, payload: rest })
			}
			// finished request
			else if (webSocketMessage.type === FINISHED_REQUEST) {
				const { requestId, success } = webSocketMessage.payload
				if (requestHandledCallbackContainer[requestId]) {
					requestHandledCallbackContainer[requestId](success)
				}
			}
			// mission ended
			else if (webSocketMessage.type === MISSION_ENDED) {
				store.dispatch(disconnect())
				if (isWantingToRunMissionAsControl()) {
					const state = store.getState()
					const missionId = getMissionId(state)
					redirectToDashboard(
						missionId && getShouldRedirectToMissionSurveyOnMissionEnd(state) ? missionId : undefined
					)
				} else {
					window.location.href = '/'
				}
			}
			// not recognized
			else {
				console.log('Unrecognized server message: ', webSocketMessage)
				next({
					type: action.type + '::' + webSocketMessage.type,
					payload: webSocketMessage.payload,
				})
			}
		}
	} catch (e) {
		console.log(e)
		console.log('Unable to parse websocket message data', action.payload)
	}
}

/**
 * handleMessageInOrder - handles a message from the server in the order they call this method
 *
 * @param {any} next - the redux middleware next function
 * @param {any} action - the @giantmachines/redux-websocket message received action
 * @param {any} store - the redux store
 */
const handleMessageInOrder = (next, action, store) => {
	const message: ?(string | Blob) = action.payload?.message
	const textPromise: Promise<string> =
		message instanceof Blob
			? (() =>
					new Promise((resolve, reject) => {
						let blobToJsonConverter = new FileReader()
						blobToJsonConverter.onload = () => resolve(((blobToJsonConverter.result: any): string))
						blobToJsonConverter.onerror = reject
						blobToJsonConverter.readAsText(message)
					}))()
			: Promise.resolve(message || '')

	// this adds the handler to the end of a linked list
	// to guarantee that the messages are handled in the
	// order they were received
	nextMessagePromise = new Promise((resolve, reject) => {
		nextMessagePromise
			.then(() => {
				textPromise
					.then((text: string) => {
						handleServerMessage(text, action, store, next)

						resolve()
					})
					.catch(reject)
			})
			.catch(reject)
	}).catch(error => console.log('Error handling server message, Error:', error, 'Action: ', action))
}

const webSocketMiddleware: MiddleWare = store => next => action => {
	if (action.type === REDUX_WEBSOCKET_MESSAGE) {
		handleMessageInOrder(next, action, store)
		return
	}
	return next(action)
}

const middlewares = [webSocketMiddleware]
export default middlewares
