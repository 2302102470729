import React from 'react'
import 'styled-components/macro'
export default function Top(props: {}): React$Node {
	return (
		<svg {...props} width="100%" viewBox="0 0 1406 23" fill="none" css="padding: 0 10%">
			<path
				d="M1403 0C1395.2 7.8 1390.8 12.2 1383 20H23C15.2 12.2 10.8 7.8 3 0"
				className="stroke-accent-aqua"
				strokeWidth="5"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	)
}
