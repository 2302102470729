import React, { Component, memo, type AbstractComponent } from 'react'
import Slider from 'react-rangeslider'

import './ThrottleSlider.css'

type Props = {
	value: number,
	max: number,
	steps: number,
	onChange: (value: number) => void,
	onChangeComplete: () => void,
}

class ThrottleSlider extends Component<Props> {
	render() {
		const { value, onChange, onChangeComplete, max } = this.props
		return (
			<div className="ThrottleSlider">
				<Slider
					min={0}
					max={max}
					value={value}
					orientation="vertical"
					onChange={value => onChange(value)}
					onChangeComplete={() => onChangeComplete()}
					tooltip={false}
					labels={this.labels}
				/>
			</div>
		)
	}
	get labels() {
		const labels = {}
		const incrementBy: number = Math.round(this.props.max / this.props.steps)
		for (let i = 0; i <= this.props.max; i += incrementBy) {
			let label = i / incrementBy
			labels[`${i}`] = label
		}
		return labels
	}
}

export default (memo(ThrottleSlider): AbstractComponent<Props>)
