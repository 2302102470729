import React, { useCallback, useRef, useState, useEffect } from 'react'
import { useSpring } from '@react-spring/web'
import { CANVAS_HEIGHT } from './assets/Background'
import { Upgrades } from './assets'

type Props = {
	upgrade: number,
	show: boolean,
	checkImpact: ({ current?: Element | null }) => ?boolean,
	onFinish: () => void,
}

export default function Blast({
	projectileAngle,
	...props
}: Props & { projectileAngle?: number }): React$Node {
	const upgradeConfig = Upgrades[props.upgrade]

	const [bulletAngle, setBulletAngle] = useState(projectileAngle || 90)
	useEffect(() => {
		if (projectileAngle && !props.show) {
			setBulletAngle(projectileAngle)
		}
	}, [projectileAngle, props.show])

	// We know that the id laser-start is only applied to svg elements (all svg elements have the getBBox() attribute)
	const element: ?{ getBBox: () => { x: number, width: number } } = (document.getElementById(
		'laser-start'
	): any)
	if (!element) {
		return null
	}
	const bBox = element.getBBox()
	const x = bBox ? bBox.x - upgradeConfig.projectileSize / 2 + bBox.width / 2 : 0

	return upgradeConfig.blastType === 'LASER' ? (
		<LaserPositioner {...props} x={x} />
	) : (
		props.show && (
			<g
				transform={`rotate(${90 - bulletAngle} ${upgradeConfig.pointOfRotation.x} ${
					upgradeConfig.pointOfRotation.y
				})`}>
				<ProjectilePositioner {...props} x={x} speed={upgradeConfig.projectileSpeed} />
			</g>
		)
	)
}

export function ProjectilePositioner(props: Props & { x: number, speed: number }): React$Node {
	const { checkImpact, show, upgrade, x, onFinish } = props
	const { projectile: ProjectileSvg } = Upgrades[upgrade]

	const ref = useRef()
	const onChange = useCallback(() => {
		checkImpact(ref)
	}, [checkImpact])

	const { y } = useSpring({
		config: { duration: 1000 / props.speed },
		from: { y: 0 },
		to: { y: -CANVAS_HEIGHT * 5 },
		onRest: onFinish,
		onChange,
	})

	return show && <ProjectileSvg x={x} y={y} ref={ref} />
}

export function LaserPositioner(props: Props & { x: number }): React$Node {
	const { projectile: LaserSvg } = Upgrades[props.upgrade]
	return (
		<LaserSvg
			x={props.x}
			style={{ overflow: 'visible' }}
			show={props.show}
			checkImpact={props.checkImpact}
			onFinish={props.onFinish}
		/>
	)
}
