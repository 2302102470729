import Markdown from './Markdown'
import TextToSpeech from '../TextToSpeech'
import { compiler } from 'markdown-to-jsx'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import type { CustomComponent } from './Markdown'
import { useSelector } from 'react-redux'
import { getTextToSpeechSettings } from '../../store/stores/settings'

export type CustomComponentDescriptor = {|
	markdownComponent: CustomComponent,
	textToSpeechComponent: CustomComponent,
|}

export type CustomComponentDescriptors = {
	[componentMarker: string]: CustomComponentDescriptor,
}

/**
 * NoStyle - A component which only displays the given children
 *
 * @param {{children?:?React$Node}} {children}
 *
 * @return {any}
 */
function NoStyle({ children }: { children?: ?React$Node }) {
	return children ?? null
}

/**
 * MarkdownTextToSpeech - adds support for text to speech on markdown fields
 *
 * @param {Object} props - the react props
 * @param {string} props.children - the markdown string
 * @param {?Array<string>} disabledComponents? - a list of components to refuse to render
 *
 * @return {React$Node}
 */
export default function MarkdownTextToSpeech({
	children,
	className,
	disabledComponents,
	customComponents,
	preparser,
	textToSpeechPosition = 'end',
}: {
	children: string,
	className?: string,
	disabledComponents?: ?Array<string>,
	customComponents?: ?CustomComponentDescriptors,
	preparser?: ?(text: string) => string,
	textToSpeechPosition?: 'start' | 'end',
}): React$Node {
	const preparsedValue = useMemo(() => {
		if (preparser) {
			return preparser(children)
		}
		return children
	}, [children, preparser])

	const strippedMarkdown = useMemo(() => {
		const overrides = new Proxy(
			{},
			{
				get: function(_, name) {
					return {
						// Note: since NoStyle return just the children and the components are responsible for adding the html styles. This causes the resulting component to not contain any of the markdown annotations.
						component: customComponents?.[name]?.textToSpeechComponent ?? NoStyle,
					}
				},
			}
		)
		// using the markdown compiler with this override effectively strips off the markdown syntax
		return compiler(preparsedValue, {
			overrides,
		})
	}, [preparsedValue, customComponents])

	const markdownComponentOverrides = useMemo(() => {
		const overrides = {}
		if (customComponents) {
			Object.keys(customComponents).forEach(componentMarker => {
				overrides[componentMarker] = customComponents[componentMarker].markdownComponent
			})
		}
		return overrides
	}, [customComponents])

	return (
		<>
			{textToSpeechPosition === 'start' && (
				<TextToSpeechWithWrappers position="start">{strippedMarkdown}</TextToSpeechWithWrappers>
			)}
			<Markdown
				className={className}
				disabledComponents={disabledComponents}
				customComponents={markdownComponentOverrides}>
				{preparsedValue}
			</Markdown>
			{textToSpeechPosition === 'end' && (
				<TextToSpeechWithWrappers position="end">{strippedMarkdown}</TextToSpeechWithWrappers>
			)}
		</>
	)
}

const TextToSpeechWithWrappers = ({
	children,
	position,
}: {
	children: React$Node,
	position: 'start' | 'end',
}) => {
	const isTextToSpeechEnabled = useSelector(getTextToSpeechSettings).isEnabled
	if (!isTextToSpeechEnabled) {
		return null
	}
	return (
		<TextToSpeech position={position}>
			{/* Note: HideThisComponent hides all the children from the DOM, but text to speech will override the it's children (HideThisComponent) with just the text when speaking */}
			<HideThisComponent>
				<TextToSpeech position={position}>{children}</TextToSpeech>
			</HideThisComponent>
		</TextToSpeech>
	)
}

const HideThisComponent = styled.div`
	display: None;
`
