import React from 'react'
import TeamToggle from '../Components/TeamToggle'
import { COMPONENT_TYPES } from '../../../../../../store/selectors/jrPlusState/engineering'

import type { PopulatedPanel } from '../../../../../../store/selectors/jrPlusState/engineering'
import styled from 'styled-components'

const StyledSwitchPanel: StyledType<> = styled.div`
	display: flex;
	justify-content: space-between;
	position: relative;
`

/**
 * TeamSwitchPanel - a react component for displaying the team switch panel
 *
 * @param {{panel:PopulatedPanel}} {panel} - the panel from the server
 *
 * @returns React$Node
 */
export default function TeamSwitchPanel({ panel }: { panel: PopulatedPanel }): React$Node {
	let teamToggle = null

	panel.components.forEach(component => {
		if (component.type !== COMPONENT_TYPES.TEAM_TOGGLE) {
			return
		}
		teamToggle = <TeamToggle component={component} />
	})
	return <StyledSwitchPanel>{teamToggle}</StyledSwitchPanel>
}
