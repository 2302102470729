import React from 'react'
import 'styled-components/macro'
import { MAX_POWER, POWER_STATION_DELTA } from '../types'

const OVER_TARGET = '#ff5c0f'
const UNDER_TARGET = '#ffd712'
const ON_TARGET = '#05b2ae'

const VIEW_BOX_WIDTH = 100
const ARC_WIDTH = 7
const ARC_RADIUS = VIEW_BOX_WIDTH / 2
const MAX_WITH_TRIM = 185
const MIN_WITH_TRIM = -5

/**
 * Gets the x,y position along the arc of a circle given an angle and radius of the circle.
 * This function is used to draw the path of an svg segment in the speedometer ring.
 * Note that in this function we return the y value made negative because the returned position is
 * meant for an svg plane and not a normal unit circle graph.
 * @param {number} _angle
 * @param {number} radius
 * @returns {{x: number, y: number}} coordinates for the svg, rounded to the nearest 100th.
 */
const getXYPosInCircle = (_angle, radius) => {
	const angle = _angle - 90
	var x = radius * Math.sin((Math.PI * 2 * angle) / 360)
	var y = radius * Math.cos((Math.PI * 2 * angle) / 360)
	return { x: Math.round(x * 100) / 100, y: -Math.round(y * 100) / 100 }
}

/**
 * Draws a segment of the power speedometer ring. Takes a start and end degree and a fill color for the ring segment.
 *
 * The start and end value represent angle in degrees from the center of the (-x)y quadrant out to the point in the circle where the segment
 * line should be drawn.
 * @param {{start: number, end: number, fill: string}} props
 * @returns {React$Node}
 */
const Segment = ({
	start: startDegree,
	end: endDegree,
	fill,
}: {
	start: number,
	end: number,
	fill: string,
}) => {
	const startOuterPos = getXYPosInCircle(startDegree, ARC_RADIUS)
	const startInnerPos = getXYPosInCircle(startDegree, ARC_RADIUS - ARC_WIDTH)
	const endOuterPos = getXYPosInCircle(endDegree, ARC_RADIUS)
	const endInnerPos = getXYPosInCircle(endDegree, ARC_RADIUS - ARC_WIDTH)

	return (
		<path
			fill={fill}
			d={`M ${startOuterPos.x} ${startOuterPos.y}
                A ${ARC_RADIUS} ${ARC_RADIUS} 0 0 1 ${endOuterPos.x} ${endOuterPos.y}
                L ${endInnerPos.x} ${endInnerPos.y}
                A ${ARC_RADIUS - ARC_WIDTH} ${ARC_RADIUS - ARC_WIDTH} 0 0 0 ${startInnerPos.x} ${
				startInnerPos.y
			}
                L ${startOuterPos.x} ${startOuterPos.y}
                Z`}
		/>
	)
}

// Gets the max bound given the target power value.
const getMaxBound = (target: number): number => {
	if (target >= MAX_POWER) {
		return MAX_WITH_TRIM
	}
	return ((target + POWER_STATION_DELTA) / MAX_POWER) * 180
}

// Gets the min bound given the target power value
const getMinBound = (target: number): number => {
	if (target <= 0) {
		return MIN_WITH_TRIM
	}
	return ((target - POWER_STATION_DELTA) / MAX_POWER) * 180
}

/* An svg speedometer to guide students to find the target value on the power station. The speedometer is segmented
into colored sections to show areas that are on target, off target, and under target. */
export default function Speedometer({ targetValue }: { targetValue: number }): React$Node {
	// Bounds are angles between -5 and 185 degrees.
	const minBound = getMinBound(targetValue)
	const maxBound = getMaxBound(targetValue)
	return (
		<svg
			width="100%"
			css={`
				padding: 24px;
			`}
			viewBox={`0 0 ${VIEW_BOX_WIDTH} 75`}>
			<g transform="translate(50, 50)">
				<Segment fill={UNDER_TARGET} start={MIN_WITH_TRIM} end={minBound} />
				<Segment fill={ON_TARGET} start={minBound} end={maxBound} />
				<Segment fill={OVER_TARGET} start={maxBound} end={MAX_WITH_TRIM} />
			</g>
		</svg>
	)
}
