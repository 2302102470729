import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components/macro'
import { useSpring, animated, config } from '@react-spring/web'
import { useDrag } from '@use-gesture/react'
import TeamDirection from './TeamDirection'
import { useThrottledUpdateDirectionChange } from '../../utility/hooks'

type Props = { device: string }

const JOYSTICK_RADIUS = 90

/**
 * Joystick - the component which handles the joy stick for the thrusters station
 *
 * @param  {Object} props - the react props
 * @param  {Device} props.device - the currently selected upgrade for the thrusters station
 *
 * @returns React$Node
 */
export default function Joystick({ device: Device, ...props }: Props): React$Node {
	const myDirection = useSelector(state => state.thrusters.myDirection)

	const setDirection = useThrottledUpdateDirectionChange()

	const [style, api] = useSpring(() => ({ x: 0, y: 0, scale: 1 }))
	const { scale } = useSpring({
		from: {
			scale: 1.1,
		},
		to: {
			scale: myDirection ? 1.2 : 1,
		},
		loop: !myDirection,
		config: config.slow,
	})

	const bind = useDrag(
		({ active, offset: [x, y], initial, last, direction: directionTuple }) => {
			let angle = Math.atan2(y, x)
			let magnitude = Math.min(Math.sqrt(x * x + y * y) / JOYSTICK_RADIUS, 1)
			if (last) {
				setDirection(null)
			} else {
				setDirection({ angle, magnitude })
			}
			api.start({
				x: active ? Math.cos(angle) * magnitude * JOYSTICK_RADIUS : 0,
				y: active ? Math.sin(angle) * magnitude * JOYSTICK_RADIUS : 0,
			})
		},
		{
			bounds: {
				left: -JOYSTICK_RADIUS,
				right: JOYSTICK_RADIUS,
				top: -JOYSTICK_RADIUS,
				bottom: JOYSTICK_RADIUS,
			},
			from: () => [0, 0],
		}
	)

	return (
		<StyledSvg
			{...props}
			width="590"
			height="589"
			viewBox="0 0 590 589"
			fill="none"
			overflow="visible">
			<path
				id="background-line"
				d="M544.88 48 L294 294 H-56.26"
				stroke="#00FFFF"
				strokeWidth="3"
				strokeLinejoin="round"
			/>

			<Device width="290" height="490" x="-346.26" y="45" />
			<rect
				id="engine"
				width="290"
				height="490"
				x="-346.26"
				y="45"
				rx="20"
				stroke="#00FFFF"
				strokeWidth="3"
			/>

			<TeamDirection
				id="team-direction"
				x="522"
				y="-75"
				width="124"
				height="124"
				viewBox="0 0 124 124"
				fill="none"
				overflow="visible">
				<path
					d="M513.361 29.363H124.583C116.299 29.363 109.583 36.0788 109.583 44.363V104.377C109.583 112.661 116.299 119.377 124.583 119.377H513.361C521.645 119.377 528.361 112.661 528.361 104.377V44.363C528.361 36.0788 521.645 29.363 513.361 29.363Z"
					stroke="#00FFFF"
					strokeWidth="3"
					strokeMiterlimit="10"
				/>
				<text
					x="173.74"
					y="78"
					alignmentBaseline="middle"
					fill="white"
					fontFamily="Orbitron"
					fontSize="32">
					TEAM DIRECTION
				</text>
				<path
					d="M74.7329 147.204C114.958 147.204 147.567 114.595 147.567 74.37C147.567 34.1449 114.958 1.53601 74.7329 1.53601C34.5078 1.53601 1.89893 34.1449 1.89893 74.37C1.89893 114.595 34.5078 147.204 74.7329 147.204Z"
					fill="#364984"
					stroke="#00FFFF"
					strokeWidth="3"
					strokeMiterlimit="10"
				/>
			</TeamDirection>
			<g id="aesthetics">
				<path
					d="M295 568.782C446.696 568.782 569.669 445.808 569.669 294.113C569.669 142.417 446.696 19.444 295 19.444C143.305 19.444 20.3311 142.417 20.3311 294.113C20.3311 445.808 143.305 568.782 295 568.782Z"
					fill="#171F4F"
				/>
				<path
					d="M26.145 294.113H563.856"
					stroke="#171F4F"
					strokeWidth="38"
					strokeMiterlimit="10"
					strokeLinecap="round"
				/>
				<path
					d="M295 25.258V562.969"
					stroke="#171F4F"
					strokeWidth="38"
					strokeMiterlimit="10"
					strokeLinecap="round"
				/>
				<g css="mix-blend-mode:multiply" opacity="0.5">
					<rect x="122.2" y="121.28" width="345.6" height="345.6" fill="url(#pattern0)" />
				</g>
				<path
					d="M23.7544 337.5C42.3134 454.444 134.67 546.8 251.614 565.36"
					stroke="#00FFFF"
					strokeWidth="3"
					strokeMiterlimit="10"
					strokeLinecap="round"
				/>
				<path
					d="M251.614 22.867C134.67 41.426 42.3144 133.783 23.7544 250.727"
					stroke="#00FFFF"
					strokeWidth="3"
					strokeMiterlimit="10"
					strokeLinecap="round"
				/>
				<path
					d="M338.386 565.359C455.33 546.8 547.686 454.443 566.246 337.499"
					stroke="#00FFFF"
					strokeWidth="3"
					strokeMiterlimit="10"
					strokeLinecap="round"
				/>
				<path
					d="M566.246 250.727C547.687 133.783 455.33 41.427 338.386 22.867"
					stroke="#00FFFF"
					strokeWidth="3"
					strokeMiterlimit="10"
					strokeLinecap="round"
				/>
				<path
					opacity="0.5"
					d="M543.201 249.794C534.167 198.82 509.667 151.861 473.027 115.29C436.386 78.7196 389.38 54.309 338.39 45.372"
					stroke="#00FFFF"
					strokeWidth="10"
					strokeLinejoin="round"
					strokeDasharray="1 36"
				/>
				<path
					opacity="0.5"
					d="M251.614 45.372C200.623 54.3084 153.617 78.7188 116.976 115.29C80.3348 151.86 55.8344 198.82 46.8003 249.794"
					stroke="#00FFFF"
					strokeWidth="10"
					strokeLinejoin="round"
					strokeDasharray="1 36"
				/>
				<path
					opacity="0.5"
					d="M338.386 541.921C389.535 532.956 436.67 508.421 473.354 471.667C510.038 434.913 534.484 387.732 543.352 336.566"
					stroke="#00FFFF"
					strokeWidth="10"
					strokeLinejoin="round"
					strokeDasharray="1 36"
				/>
				<path
					opacity="0.5"
					d="M46.6484 336.566C55.5168 387.732 79.9622 434.913 116.646 471.667C153.331 508.421 200.466 532.956 251.614 541.921"
					stroke="#00FFFF"
					strokeWidth="10"
					strokeLinejoin="round"
					strokeDasharray="1 36"
				/>
				<path
					d="M295 444.258C377.923 444.258 445.144 377.036 445.144 294.114C445.144 211.192 377.923 143.97 295 143.97C212.078 143.97 144.856 211.192 144.856 294.114C144.856 377.036 212.078 444.258 295 444.258Z"
					stroke="#5058BA"
					strokeWidth="3"
					strokeMiterlimit="10"
				/>
				<path
					opacity="0.75"
					d="M368.491 439.508C337.553 455.131 302.442 460.488 268.253 454.802C234.064 449.116 202.576 432.682 178.361 407.886L164.02 407.08L144.692 405.988C131.839 388.656 122.043 369.256 115.725 348.624L126.325 332.424L134.19 320.403C129.357 290.762 132.799 260.362 144.138 232.551C155.476 204.741 174.271 180.6 198.451 162.788L209.451 143.733V143.721C211.932 142.306 214.463 140.949 217.043 139.652C242.5 126.77 270.752 120.392 299.274 121.089C327.797 121.786 355.703 129.536 380.501 143.646L391.493 162.701L391.505 162.714C413.953 179.211 431.798 201.186 443.336 226.542C454.874 251.898 459.72 279.788 457.412 307.55C455.104 335.312 445.719 362.019 430.152 385.121C414.585 408.224 393.356 426.951 368.492 439.514L368.491 439.508Z"
					stroke="#00FFFF"
					strokeMiterlimit="10"
				/>
				<g opacity="0.5">
					<path
						d="M426.353 185.055C428.329 185.055 429.93 183.454 429.93 181.478C429.93 179.502 428.329 177.901 426.353 177.901C424.378 177.901 422.776 179.502 422.776 181.478C422.776 183.454 424.378 185.055 426.353 185.055Z"
						fill="#00FFFF"
					/>
					<path
						d="M437.296 197.681C439.272 197.681 440.873 196.08 440.873 194.104C440.873 192.128 439.272 190.527 437.296 190.527C435.321 190.527 433.719 192.128 433.719 194.104C433.719 196.08 435.321 197.681 437.296 197.681Z"
						fill="#00FFFF"
					/>
				</g>
				<ArrowPath
					id="UP"
					d="M328.32 19.237L295 0L261.68 19.237V52.954L275.203 45.146V22.151L295 10.722L314.8 22.151V45.146L328.323 52.954L328.32 19.237Z"
					selected={myDirection === 'UP'}
				/>
				<path
					d="M328.32 63.193L295 43.956L261.68 63.193"
					stroke="#00FFFF"
					strokeWidth="3"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<ArrowPath
					id="DOWN"
					d="M261.68 568.989L295 588.226L328.32 568.989V535.272L314.8 543.08V566.075L295 577.5L275.2 566.071V543.08L261.677 535.272L261.68 568.989Z"
					selected={myDirection === 'DOWN'}
				/>
				<path
					d="M261.68 525.033L295 544.27L328.32 525.033"
					stroke="#00FFFF"
					strokeWidth="3"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<ArrowPath
					id="RIGHT"
					d="M569.876 327.433L589.113 294.113L569.876 260.793H536.159L543.967 274.316H566.962L578.391 294.116L566.962 313.916H543.967L536.159 327.439L569.876 327.433Z"
					selected={myDirection === 'RIGHT'}
				/>
				<path
					d="M525.92 327.433L545.157 294.113L525.92 260.793"
					stroke="#00FFFF"
					strokeWidth="3"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<ArrowPath
					id="LEFT"
					d="M20.1242 260.793L0.887207 294.113L20.1242 327.433H53.8412L46.0332 313.91H23.0382L11.6092 294.11L23.0382 274.31H46.0332L53.8412 260.787L20.1242 260.793Z"
					selected={myDirection === 'LEFT'}
				/>
				<path
					d="M64.0803 260.793L44.8433 294.113L64.0803 327.433"
					stroke="#00FFFF"
					strokeWidth="3"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<g opacity="0.5">
					<path
						d="M466.808 165.634L457.652 131.462L423.48 122.306"
						stroke="#00FFFF"
						strokeWidth="3"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M123.192 422.592L132.348 456.764L166.52 465.92"
						stroke="#00FFFF"
						strokeWidth="3"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M423.479 465.921L457.651 456.765L466.807 422.593"
						stroke="#00FFFF"
						strokeWidth="3"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M166.521 122.305L132.349 131.461L123.193 165.633"
						stroke="#00FFFF"
						strokeWidth="3"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</g>
			</g>

			<AnimatedSvg {...bind()} x={style.x} y={style.y} overflow="visible" id="animated-joystick">
				<AnimatedGroup transform={scale.to(o => `scale(${o})`)}>
					<g css="mix-blend-mode:multiply" opacity="0.75" filter="url(#filter0_f_1299_2628)">
						<path
							d="M303.893 374.02C343.112 374.02 374.906 342.226 374.906 303.007C374.906 263.788 343.112 231.994 303.893 231.994C264.673 231.994 232.88 263.788 232.88 303.007C232.88 342.226 264.673 374.02 303.893 374.02Z"
							fill="#1B1464"
						/>
					</g>
					<path
						d="M294.576 364.703C333.795 364.703 365.589 332.909 365.589 293.69C365.589 254.471 333.795 222.677 294.576 222.677C255.357 222.677 223.563 254.471 223.563 293.69C223.563 332.909 255.357 364.703 294.576 364.703Z"
						fill="#5A83A8"
					/>
					<g css="mix-blend-mode:multiply" opacity="0.75" filter="url(#filter1_f_1299_2628)">
						<path
							d="M299.314 353.273C329.604 353.273 354.16 328.718 354.16 298.427C354.16 268.136 329.604 243.581 299.314 243.581C269.023 243.581 244.468 268.136 244.468 298.427C244.468 328.718 269.023 353.273 299.314 353.273Z"
							fill="#1B1464"
						/>
					</g>
					<path
						d="M294.576 348.536C324.867 348.536 349.422 323.981 349.422 293.69C349.422 263.399 324.867 238.844 294.576 238.844C264.285 238.844 239.73 263.399 239.73 293.69C239.73 323.981 264.285 348.536 294.576 348.536Z"
						fill="#00FFFF"
					/>
				</AnimatedGroup>
			</AnimatedSvg>
			<defs>
				<pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
					<use xlinkHref="#image0_1299_2628" transform="scale(0.00138889)" />
				</pattern>
				<filter
					id="filter0_f_1299_2628"
					x="222.88"
					y="221.994"
					width="162.026"
					height="162.026"
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB">
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
					<feGaussianBlur stdDeviation="5" result="effect1_foregroundBlur_1299_2628" />
				</filter>
				<filter
					id="filter1_f_1299_2628"
					x="234.468"
					y="233.581"
					width="129.692"
					height="129.692"
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB">
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
					<feGaussianBlur stdDeviation="5" result="effect1_foregroundBlur_1299_2628" />
				</filter>
			</defs>
		</StyledSvg>
	)
}

const ArrowPath = styled.path`
	transition: fill 300ms;
	fill: ${({ selected }) => (selected ? '#00ffff' : '#545BAF')};
`
const AnimatedSvg = styled(animated.svg)`
	cursor: grab;
	touch-action: none;
	user-select: none;
`
const AnimatedGroup = styled(animated.g)`
	transform-origin: 50% 50%;
`

const StyledSvg = styled.svg`
	${'' /* This is a fix for ipads, so that dragging the joystick does not cause safari to perform browser based actions (such as scroll-down to refresh page) */}
	touch-action: none;
`
