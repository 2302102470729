import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { usePrevious } from '../../../../../../utility/hooks'
import { useSpring, config, animated } from '@react-spring/web'
import styled from 'styled-components/macro'
import { sendMessage } from '../../../../../../store/stores/webSocket'
import { getStudentHealth } from '../../../../../../store/stores/general'
import { useAdvanceTraining } from '../../../../../../store/selectors/jrPlusState/training'
import { getIsHealthTaskStationActive } from '../../../../../../store/selectors/jrPlusState/healthTask'

const HEALTH_BAR_WIDTH = 156.5

export default function HealthBar(props: {|
	className?: string,
	id?: string,
	css?: string,
|}): React$Node {
	const { healthProgressSpring, healthColorSpring } = useHealthSpringStyles()
	const dispatch = useDispatch()
	const advanceTraining = useAdvanceTraining()

	const clickHealth = () => {
		if (advanceTraining) {
			advanceTraining()
		} else {
			dispatch(sendMessage('TRIGGER_HEALTH_TASK'))
		}
	}

	const isHealthTaskStationActive = useSelector(getIsHealthTaskStationActive)

	return (
		<svg viewBox="0 0 350 172" fill="none" {...props}>
			<path
				id="Vector"
				d="M177.8 13H0V172H350L200.4 22.4C194.4 16.4 186.3 13 177.8 13Z"
				fill="#364984"
			/>
			<path
				id="Vector_3"
				d="M313 172L261.2 120.2H172.8C156.5 143.4 129.9 157.3 101.5 157.3C53.5 157.3 14.4 118.2 14.4 70.2C14.4 49.1 22.1 28.8 35.8 13H0V142V172H313Z"
				fill="#5D568C"
			/>
			<path
				id="Vector_4"
				d="M261.3 117.7H172.9C172.1 117.7 171.3 118.1 170.9 118.8C155.1 141.4 129.2 154.8 101.7 154.8C55.1 154.8 17.1 116.9 17.1 70.2C17.1 49.8 24.5 30.1 37.9 14.7L39.3 13.1H32.7C19.4 29.1 12 49.4 12 70.2C12 119.6 52.2 159.8 101.6 159.8C130.3 159.8 157.3 146 174.2 122.7H260.2L309.5 172H316.6L263 118.4C262.6 118 261.9 117.7 261.3 117.7Z"
				fill="#14163D"
			/>
			<g id="Group">
				<path
					id="Vector_5"
					d="M226.6 149.5H222.5C220.1 149.5 218.2 147.6 218.2 145.2V131.8C218.2 129.4 220.1 127.5 222.5 127.5H226.6C229 127.5 230.9 129.4 230.9 131.8V145.2C230.9 147.5 229 149.5 226.6 149.5Z"
					fill="#364984"
				/>
				<path
					id="Vector_6"
					d="M246.3 149.5H242.2C239.8 149.5 237.9 147.6 237.9 145.2V131.8C237.9 129.4 239.8 127.5 242.2 127.5H246.3C248.7 127.5 250.6 129.4 250.6 131.8V145.2C250.6 147.5 248.7 149.5 246.3 149.5Z"
					fill="#364984"
				/>
			</g>
			<g id="Health">
				<path
					id="Vector_7"
					d="M314.7 43H165.4C154.5 17.5 128.8 -0.10002 99.2001 0.99998C62.7001 2.29998 33.2001 32.1 32.3001 68.6C31.4001 107.7 62.8001 139.6 101.6 139.6C130.2 139.6 154.8 122.3 165.4 97.5H314.7C322.8 97.5 329.3 91 329.3 82.9V57.6C329.3 49.5 322.8 43 314.7 43Z"
					fill="#5D568C"
				/>
				<path
					id="Vector_8"
					d="M101.6 127.4C133.191 127.4 158.8 101.791 158.8 70.2C158.8 38.6093 133.191 13 101.6 13C70.0097 13 44.4004 38.6093 44.4004 70.2C44.4004 101.791 70.0097 127.4 101.6 127.4Z"
					fill="#0F193C"
				/>
				<path
					id="Vector_9"
					d="M308.701 83.0999H145.401C141.901 83.0999 139.101 80.2999 139.101 76.7999V63.4999C139.101 59.9999 141.901 57.2 145.401 57.2H308.701C312.201 57.2 315.001 59.9999 315.001 63.4999V76.7999C315.001 80.2999 312.201 83.0999 308.701 83.0999Z"
					fill="#14163D"
				/>
				<HealthIcon
					onClick={() => {
						if (isHealthTaskStationActive) {
							clickHealth()
						}
					}}
					$disabled={!isHealthTaskStationActive}
					className="healthPlus">
					<animated.path
						d="M101.601 107.7C122.311 107.7 139.101 90.9106 139.101 70.2C139.101 49.4893 122.311 32.7 101.601 32.7C80.8899 32.7 64.1006 49.4893 64.1006 70.2C64.1006 90.9106 80.8899 107.7 101.601 107.7Z"
						style={{ fill: healthColorSpring.iconFill }}
					/>
					<path
						id="Vector_11"
						d="M107.1 88.9V75.7H120.3V64.7H107.1V51.5H96.1004V64.7H82.9004V75.7H96.1004V88.9H107.1Z"
						fill="white"
					/>
				</HealthIcon>
				<animated.rect
					x="158.5"
					y="59.7"
					rx="6.27"
					height="21.2"
					style={{
						width: healthProgressSpring.percent.to(x => x * HEALTH_BAR_WIDTH),
						fill: healthProgressSpring.colorGrade.to(x => `hsl(${x}, 100%, 70%)`),
					}}
				/>
			</g>
		</svg>
	)
}

const HealthIcon = styled.g`
	pointer-events: all;
	cursor: ${({ $disabled }) => ($disabled ? 'unset' : 'pointer')};

	& > * {
		transition: fill 1s;
		transition: filter 1s;
		filter: brightness(${({ $disabled }) => ($disabled ? '50%' : '100%')});
		opacity: ${({ $disabled }) => ($disabled ? '0.75' : '1')};
	}
`

/**
 * Uses react-spring to create animations for the different components of the student health animations in the frame
 * @returns { healthColorSpring.iconFill: ReactSpringAnimation } When student health goes up/down, the student health icon pulses color
 * @returns { healthProgressSpring.percent: ReactSpringAnimation } Progress bar animation when student health changes
 * @returns { healthProgressSpring.colorGrade: ReactSpringAnimation } Progress bar color change when student health changes.
 */
const useHealthSpringStyles = () => {
	const health = useSelector(getStudentHealth)

	const [healthBarBlink, setHealthBarBlink] = useState()
	const previousHealth = usePrevious(health) || health
	const loop = useRef(0)
	useEffect(() => {
		if (previousHealth > health) {
			setHealthBarBlink(-1)
			loop.current = 0
		} else if (previousHealth < health) {
			setHealthBarBlink(1)
			loop.current = 0
		}
	}, [previousHealth, health])
	const getColorGrade = healthValue => (healthValue > 60 ? 100 : healthValue < 35 ? 0 : 35)

	const healthProgressSpring = useSpring({
		from: { percent: previousHealth / 100, colorGrade: getColorGrade(previousHealth) },
		to: {
			percent: health / 100,
			colorGrade: getColorGrade(health),
		},
		config: config.molasses,
	})

	const [healthColorSpring] = useSpring(() => {
		const toColor = healthBarBlink === -1 ? '#ff2524' : healthBarBlink === 1 ? '#99ff99' : null
		loop.current = 0
		return {
			reset: !toColor,
			config: {
				mass: 0.5,
				tension: 160,
				friction: 10,
			},
			loop: () => {
				if (!toColor) return false
				if (loop.current++ > 5) {
					return false
				}
				return { reverse: true }
			},
			onRest: () => {
				if (loop.current === 5) {
					setHealthBarBlink(null)
				}
			},
			to: {
				iconFill: toColor || 'aqua',
			},
			from: {
				iconFill: 'aqua',
			},
		}
	}, [healthBarBlink])
	return {
		healthColorSpring,
		healthProgressSpring,
	}
}
