import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { switchStation, getStation } from '../../../../../store/stores/general'
import styled from 'styled-components'
import type { DisplayStationData } from '../../../../../constants/juniorPlusStationMap'
import type { JrPlusStationEnum } from '../../../../../types'
import { StationIcon, StationName } from '../../../shared'
import { getStationActivity } from '../../../../../utility/selectors'
import { isGivenStationInQuestionPhase } from '../../../../StationUpgradeOverlay/selectors'

// these stations are always on, so they should not be always throbbing
const STATIONS_THAT_SHOULD_NEVER_THROB = ['sensors']

export default function StationNav({
	station,
	large,
	stationId,
}: {
	station: DisplayStationData,
	large?: boolean,
	stationId: JrPlusStationEnum,
}): React$Node {
	const dispatch = useDispatch()
	let isActive = useSelector(state => getStationActivity(state)[stationId])
	const isInQuestionPhase = useSelector(state => isGivenStationInQuestionPhase(state, stationId))
	const isCurrent = useSelector(state => getStation(state) === stationId)
	const onClick = () => dispatch(switchStation(stationId))
	const children = (
		<>
			<station.icon />
			<StationName>{station.displayName}</StationName>
		</>
	)
	if (isInQuestionPhase) {
		isActive = true
	}

	if (STATIONS_THAT_SHOULD_NEVER_THROB.includes(stationId)) {
		// isActive only controls the styles for throbbing the station icon, so we can change this
		isActive = false
	}

	if (large) {
		return (
			<LargeStationIcon className={stationId} onClick={onClick} $isCurrent={isCurrent}>
				{children}
			</LargeStationIcon>
		)
	} else {
		return (
			<StationIcon
				className={stationId}
				$active={isActive}
				$isCurrent={isCurrent}
				onClick={onClick}>
				{children}
			</StationIcon>
		)
	}
}

const LargeStationIcon: StyledType<> = styled(StationIcon)`
	width: 8vh;
	height: 8vh;
`
