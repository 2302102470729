import React from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components/macro'
import { APP_WHITE, EMPTY_SPACE } from '../../../../../../constants/styles'
import { Button } from '../../../../../basics'
import { getCodeSocketMessage } from '../../../../../../store/selectors/jrPlusState/engineering'
import { sendMessage } from '../../../../../../store/stores/webSocket'
import DeltaClockProgressBar from '../../../../../DeltaClock/ProgressBar'
import { SecondaryLayer, DarkLayer } from '../SharedColors'
import type { CodeData as CodeComponent } from '@mission.io/mission-toolkit'
import { TimeLeftNumbersOnly } from '../../../../../DeltaClock/TimeLeft'

const StyledCode: StyledType<> = styled.div`
	background-color: ${EMPTY_SPACE};
	color: ${APP_WHITE};
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
`

const StyledSVG = styled.svg`
	width: 100%;
	flex: 1 1;
	margin-top: ${({ theme }) => theme.spacing};
`

const CodeDisplay = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 4px;
	font-family: ${({ theme }) => theme.fontFamily};
`

export default function Component({ component }: { component: CodeComponent }): React$Node {
	const dispatch = useDispatch()
	return (
		<SecondaryLayer>
			<DarkLayer>
				<StyledCode>
					{component.code ? (
						<CodeDisplay>
							<StyledSVG viewBox="0 0 76 18">
								<text textAnchor="middle" alignmentBaseline="middle" x="38" y="9" fill={APP_WHITE}>
									{component.code}
								</text>
							</StyledSVG>
							{component.timerId && (
								<>
									<DeltaClockProgressBar
										timerId={component.timerId}
										backgroundColor="var(--color-primary-darker)"
										progressColor="red"
										width="100%"
										backwards={true}
									/>
									<StyledSVG viewBox="0 0 78 14">
										<text
											textAnchor="middle"
											alignmentBaseline="middle"
											x="39"
											y="4"
											fontSize="6"
											fill={APP_WHITE}>
											Expires in
										</text>
										<text
											textAnchor="middle"
											alignmentBaseline="middle"
											x="39"
											y="12"
											fontSize="8"
											fill={APP_WHITE}>
											<TimeLeftNumbersOnly
												timerId={component.timerId}
												customTimeFormatter={(seconds: number) => String(seconds)}
											/>{' '}
											seconds
										</text>
									</StyledSVG>
								</>
							)}
						</CodeDisplay>
					) : (
						<Button
							className="bg-primary-600 mx-2 text-white"
							outline
							onClick={() =>
								dispatch(
									sendMessage('ENGINEERING_PANEL_ACTION', getCodeSocketMessage(component.id))
								)
							}>
							Generate new code
						</Button>
					)}
				</StyledCode>
			</DarkLayer>
		</SecondaryLayer>
	)
}
