import React, { forwardRef, type AbstractComponent } from 'react'
import styled from 'styled-components'
import type { LaserProps as Props } from './upgradeLookup'

export const SIZE = 320

function Laser(props: Props, ref): React$Node {
	return (
		<Svg viewBox="0 0 351.36 734.16" {...props} width={SIZE} ref={ref}>
			<path
				className="laser-1"
				d="M168.57,127.65,155.3,140.92a118.77,118.77,0,0,0-33.22,103.2L170.9,541.73a4.47,4.47,0,0,0,8.82,0l48.81-297.61a118.74,118.74,0,0,0-33.22-103.2l-13.26-13.27A9.54,9.54,0,0,0,168.57,127.65Z"
			/>
			<path
				className="laser-2"
				d="M171.06,149l-8.37,8.37a74.94,74.94,0,0,0-21,65.12l30.81,187.79a2.82,2.82,0,0,0,5.56,0l30.8-187.79a74.91,74.91,0,0,0-21-65.12L179.56,149A6,6,0,0,0,171.06,149Z"
			/>
		</Svg>
	)
}

export default (forwardRef(Laser): AbstractComponent<Props, Element>)

const Svg = styled.svg`
	.laser-1 {
		fill: #ffe19f;
	}

	.laser-2 {
		fill: #fbffd2;
	}
`
