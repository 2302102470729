import React from 'react'
import 'styled-components/macro'
import { Button } from '../../basics'
import { TaskOverlay } from './TaskOverlay'
import Markdown from '../../Markdown'
import { DivWithStyledScroll } from './DivWithStyledScroll'

/**
 * A single text option for a literacy event student action.
 * @param {Function} onSubmit callback when an option is submitted
 * @returns {React$node}
 */
function TextOption({
	onSubmit,
	index,
	selected,
	taskText,
	submitButtonText = 'Submit',
}: {
	onSubmit: () => void,
	index: number,
	selected: boolean,
	taskText: string,
	submitButtonText?: string,
}): React$Node {
	return (
		<div css="display: flex; flex-direction: column; height: 100%;">
			<b css="text-transform: uppercase; display: block;">Option {index + 1}:</b>
			<DivWithStyledScroll css="flex: 1;">
				<Markdown disabledComponents={['a', 'img']}>{taskText}</Markdown>
				{selected && (
					<TaskOverlay
						css={`
							border: 4px solid ${selected ? 'var(--color-accent-aqua)' : 'transparent'};
							border-radius: 5px;
						`}>
						<Button
							css="letter-spacing: normal; padding: 8px 12px;"
							onClick={e => {
								e.stopPropagation()
								onSubmit()
							}}>
							{submitButtonText}
						</Button>
					</TaskOverlay>
				)}
			</DivWithStyledScroll>
		</div>
	)
}

export default TextOption
