import React, { useState, useEffect, useContext } from 'react'
import { RoomControlsContext, setAudio } from '../../videoChat/RoomControls'
import styled from 'styled-components'
import 'styled-components/macro'
import { LIGHT_GREEN } from '../../constants/styles'

/**
 * Takes list of mediaDevices, filters down to audio inputs, and assigns them to the mic list
 */
function setAudioInputsFromDevices(
	setAudioDevices: ({ label: string, value: MediaDeviceInfo }[]) => void,
	mediaDevices: MediaDeviceInfo[]
) {
	const audios = []
	mediaDevices.forEach(m => {
		if (m.kind === 'audioinput') audios.push({ label: m.label, value: m })
	})
	setAudioDevices(audios)
}

/**
 * Provides option lists for Video chat microphone and
 * on set sets them in the video chat system
 */
export default function MicSelector(): React$Node {
	const [audioDevices, setAudioDevices] = useState([])

	const roomControls = useContext(RoomControlsContext)

	const localAudioDeviceId = roomControls && roomControls.activeTrackName
	// generate list of devices for options list
	useEffect(() => {
		let componentMounted = true
		if (navigator.mediaDevices) {
			navigator.mediaDevices.ondevicechange = function(event) {
				navigator.mediaDevices &&
					navigator.mediaDevices.enumerateDevices().then(devices => {
						if (componentMounted) setAudioInputsFromDevices(setAudioDevices, devices)
					})
			}

			navigator.mediaDevices.enumerateDevices().then(devices => {
				if (componentMounted) setAudioInputsFromDevices(setAudioDevices, devices)
			})
		}
		return () => {
			componentMounted = false
		}
	}, [localAudioDeviceId])

	const changeAudioOption = event => {
		const selectedAudioId = event.target.value
		const selectedAudioOption = audioDevices.find(ad => ad.value.deviceId === selectedAudioId)
		if (selectedAudioOption && roomControls) {
			setAudio(roomControls, selectedAudioOption.value)
		}
	}

	let availableOptions = audioDevices.map((device: { label: string, value: MediaDeviceInfo }) => (
		<option value={device.value.deviceId} key={device.value.deviceId}>
			{device.label}
		</option>
	))

	return (
		<InputSelector onChange={changeAudioOption} value={localAudioDeviceId}>
			{availableOptions}
		</InputSelector>
	)
}

const InputSelector: StyledType<> = styled.select`
	padding: 8px;
	margin: 0px 16px;
	background-color: ${LIGHT_GREEN};
	color: white;
	border: none;
	border-radius: 4px;
	width: fill-available;
`
