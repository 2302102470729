import { getStudentId, isTraining } from '../../stores/general'
import { isJrPlusCommunicationActive } from '@mission.io/mission-toolkit'

import type { ReduxStore } from '../../rootReducer'
import type { MapObjectIds } from '@mission.io/mission-toolkit'
import { getTraining } from './training'

export type CommunicationTarget = {
	angle: number,
	radius: number,
	id: string,
}
export function isCommunicationActive(store: ReduxStore): boolean {
	if (isTraining(store)) {
		return Boolean(getTraining(store)?.sensorData?.comActivated)
	}
	return isJrPlusCommunicationActive(store.jrPlusState)
}

export function getTargetSignalStrength(store: ReduxStore): ?number {
	return store.jrPlusState.stationData.communication.targetSignalStrength
}

export function getCurrentSignalStrength(store: ReduxStore): number {
	return store.jrPlusState.stationData.communication.signalStrength
}

export function getStudentTargets(store: ReduxStore): { [targetId: string]: CommunicationTarget } {
	const studentId = getStudentId(store.general)
	if (!studentId) {
		return {}
	}
	return store.jrPlusState.stationData.communication.targets[studentId] || {}
}

export function getCommunicationMapObject(store: ReduxStore): ?MapObjectIds {
	if (isTraining(store)) {
		return getTraining(store)?.sensorData?.comActivated
	}
	if (!isCommunicationActive(store)) return null
	return store.jrPlusState.stationData.communication.mapObject
}
