import React, { Component } from 'react'
import { connect } from 'react-redux'
import robot1 from '../../../images/robotSmall.png'
import robot2 from '../../../images/robot2Small.png'
import robot3 from '../../../images/robot3Small.png'
// import robot4 from '../../../images/robot4small.png'

import { getStudentId } from '../../../store/stores/general'
import { getMyBrokenSystems } from '../../../store/selectors/jrState'
import type { ReduxStore } from '../../../store/rootReducer'
import type { RobotData } from './types'

type BaseProps = {
	id: string,
	systemId: string,
	dimensions: $PropertyType<RobotData, 'dimensions'>,
}
type Props = {
	...BaseProps,

	// Redux Store Stuff
	brokenSystem: Object,
	studentId: string,
}

class WorkingRobot extends Component<Props> {
	render() {
		return (
			<div
				className="absolute top-0 left-0"
				style={{
					height: this.props.dimensions[1],
					width: this.props.dimensions[0],
				}}>
				<img
					src={this.getRobot()}
					alt="Robot"
					className="animate-robot-working absolute top-0 left-0"
				/>
				<div className="w-full h-2 absolute bottom-[-20px] border-2 border-white rounded-[2px] outset-border">
					<div className="bg-error h-[99%] rounded-[2px] animate-working-progress" />
				</div>
			</div>
		)
	}

	getRobot = () => {
		switch (this.props.id) {
			case '1':
				return robot1
			case '2':
				return robot2
			case '3':
				return robot3
			case '4':
				return robot1
			// return robot4
			default:
				return ''
		}
	}
}

function mapStateToProps(state: ReduxStore, ownProps) {
	const studentId = getStudentId(state.general)
	const myBrokenSystems = getMyBrokenSystems(state)
	return {
		brokenSystem: myBrokenSystems.hasOwnProperty(ownProps.systemId)
			? myBrokenSystems[ownProps.systemId]
			: null,
		studentId: studentId,
	}
}

export default (connect(mapStateToProps)(WorkingRobot): (props: BaseProps) => React$Node)
