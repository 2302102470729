import React from 'react'
import 'styled-components/macro'
import { useSpring, animated } from '@react-spring/web'
import { useSelector } from 'react-redux'
import { getCurrentStudentPoints } from '../../../../../../store/selectors/sharedSelectors'

// These constant values are based off of a view box width of 1920 and height of 172
const TOTAL_POINTS_RECT = { x: 1445, y: 32.54, width: 280.35, height: 76.27 }
const TOTAL_POINTS_LOCATION = { x: TOTAL_POINTS_RECT.x + 45, y: TOTAL_POINTS_RECT.y + 45 }
const INNER_RECT = {
	x: TOTAL_POINTS_RECT.x + 10,
	y: TOTAL_POINTS_RECT.y + 10,
	width: TOTAL_POINTS_RECT.width - 20,
	height: TOTAL_POINTS_RECT.height - 20,
}

/**
 * Displays points in the junior plus student frame
 */
export default function Points(): React$Node {
	const points = useSelector(getCurrentStudentPoints)

	const { number: totalPointsSpring } = useSpring({ number: points })

	return (
		<g id="Points">
			<g>
				<rect
					fill="var(--color-primary-dark)"
					x={TOTAL_POINTS_RECT.x}
					y={TOTAL_POINTS_RECT.y}
					width={TOTAL_POINTS_RECT.width}
					height={TOTAL_POINTS_RECT.height}
					rx="15.72"
				/>
				<rect
					fill="var(--color-primary-darkest)"
					x={INNER_RECT.x}
					y={INNER_RECT.y}
					width={INNER_RECT.width}
					height={INNER_RECT.height}
					rx="10"
				/>

				<animated.text
					x={TOTAL_POINTS_LOCATION.x}
					y={TOTAL_POINTS_LOCATION.y}
					fontFamily="Orbitron, sans-serif"
					fontSize="24"
					fill="white">
					{totalPointsSpring.to(
						x => `PTS: ${x.toLocaleString(undefined, { maximumFractionDigits: 0 })}`
					)}
				</animated.text>
			</g>

			<circle
				fill="aqua"
				cx={TOTAL_POINTS_LOCATION.x - 16.5}
				cy={TOTAL_POINTS_LOCATION.y - 8}
				r="8"
			/>
		</g>
	)
}
