import useInterval from 'use-interval'

import React, { useRef, useState } from 'react'

const FPS = 20
const STEP = 1
const TIMEOUT = (1 / FPS) * 1000

type Props = {
	children: React$Node,
	autoplay?: boolean,
	stopOnHover?: boolean,
	loop?: boolean,
	className?: string,
	leadingTime?: number,
	trailingTime?: number,
}

/**
 * A marquee component that scrolls its content from right to left. By default, does not scroll until hovered.
 *
 * @param props
 * @param props.children The content to scroll
 
 * @param props.stopOnHover Whether to pause when hovered. This is only relevant if autoplay is true.
 * @param props.loop Whether the scrolling loops
 * @param props.className A css class to add to the component
 * @param props.leadingTime How long to wait before starting the animation
 * @param props.trailingTime How long to wait after the animation completes before resetting the animation
 */
export function Marquee({
	children,
	autoplay = false,
	stopOnHover = false,
	loop = false,
	className,
	leadingTime = 0,
	trailingTime = 0,
}: Props): React$Node {
	const containerRef = useRef(null)
	const textRef = useRef(null)
	const trailingTimeoutRef = useRef(null)
	// How much the text has moved to the left
	const [animatedWidth, setAnimatedWidth] = useState(0)
	// How much the text overflows the container
	const overflowWidth =
		containerRef.current && textRef.current
			? textRef.current.offsetWidth - containerRef.current.offsetWidth
			: 0
	const textIsOverflowing = overflowWidth > 0
	const [isAnimating, setIsAnimating] = useState(autoplay)

	useInterval(
		() => {
			const newAnimatedWidth = animatedWidth + STEP

			if (newAnimatedWidth <= overflowWidth) {
				// mid animation

				setAnimatedWidth(newAnimatedWidth)
			} else {
				// Completed the animation

				if (!loop) {
					setIsAnimating(false)
				} else if (trailingTime) {
					if (!trailingTimeoutRef.current) {
						trailingTimeoutRef.current = setTimeout(() => {
							setAnimatedWidth(0)
							trailingTimeoutRef.current = null
						}, trailingTime)
					}
				} else {
					setAnimatedWidth(0)
				}
			}
		},
		textIsOverflowing && isAnimating ? (animatedWidth === 0 && leadingTime) || TIMEOUT : null
	)

	const style = {
		position: 'relative',
		right: animatedWidth,
		whiteSpace: 'nowrap',
	}

	return (
		<div
			ref={containerRef}
			className={`ui-marquee ${className || ''}`}
			style={{ overflow: 'hidden' }}
			onMouseEnter={() => {
				if (stopOnHover) {
					setIsAnimating(false)
				} else if (textIsOverflowing && !autoplay) {
					setIsAnimating(true)
				}
			}}
			onMouseLeave={() => {
				if (stopOnHover && textIsOverflowing && autoplay) {
					setIsAnimating(true)
				} else if (!autoplay) {
					setAnimatedWidth(0)
					setIsAnimating(false)
				}
			}}>
			<span ref={textRef} style={style} title={typeof children === 'string' ? children : undefined}>
				{children}
			</span>
		</div>
	)
}
