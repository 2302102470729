// @flow
import React, { useCallback, useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
	setWebSocketUrl,
	getWebSocketUrl,
	getMissionServerWebsocketAddress,
	getMissionServerHttpAddress,
	connectWebSocket,
} from '../store/stores/webSocket'
import { setMissionServerUrl, getMissionServerUrl } from '../store/stores/general'
import axios from 'axios'
import config from '../config'
import { ONE_SECOND } from '../constants'
import styled from 'styled-components'
import { PRIMARY_PURPLE_FAINT, DARK_RED } from '../constants/styles'
import { GoIssueOpened } from 'react-icons/go'
import { useNoSleep, usePageIsVisible } from '../utility/hooks'

const MISSION_SERVER_URL_RETRY_TIME = 2.5 * ONE_SECOND // ms
const COOKIE_CHECK_RETRY_TIME = 5 * ONE_SECOND

export default function AppSetup({ children }: { children: React$Node }): React$Node {
	const [cookieData, setCookieData] = useState(areCookiesSetupCorrectly())
	const dispatch = useDispatch()
	const [missionUrlResolvingError, setMissionUrlResolvingError] = useState(null)
	const websocketUrl = useSelector(getWebSocketUrl)
	const [showErrorDetails, setShowErrorDetails] = useState(false)
	const [didReceiveError, setDidReceiveError] = useState(false)

	useNoSleep(usePageIsVisible())

	useEffect(() => {
		if (websocketUrl) {
			dispatch(connectWebSocket(websocketUrl))
		}
	}, [websocketUrl, dispatch])

	const onMissionServerFetchError = useCallback(
		({ error: err, retryCount }: { error: FetchError, retryCount: number }) => {
			console.error(err)
			console.error(
				`unable to get mission url due to the above error, trying again in ${MISSION_SERVER_URL_RETRY_TIME /
					ONE_SECOND} seconds`
			)
			setMissionUrlResolvingError(
				`${err.message}: ${err?.response?.status ??
					'could not connect'}. Will retry in ${MISSION_SERVER_URL_RETRY_TIME /
					ONE_SECOND} seconds. Tried ${retryCount} time(s).`
			)
			setDidReceiveError(true)
		},
		[]
	)
	const clearMissionServerUrlError = useCallback(() => {
		setMissionUrlResolvingError(null)
		setDidReceiveError(false)
	}, [])

	useMissionServerUrlFetcher({
		onError: onMissionServerFetchError,
		clearError: clearMissionServerUrlError,
		missionCode: getMissionCodeFromUrl(),
	})

	useEffect(() => {
		const intervalId = setInterval(() => {
			const cookieData = areCookiesSetupCorrectly()
			setCookieData(cookieData)
			if (
				cookieData.websocketCookieExists &&
				cookieData.serverCookieExists &&
				!cookieData.cookiesDisabled
			) {
				clearInterval(intervalId)
			}
		}, COOKIE_CHECK_RETRY_TIME)
		return () => clearInterval(intervalId)
	}, [])

	if (cookieData.cookiesDisabled) {
		return (
			<ErrorWrapper>
				<Card>
					<ErrorTitle>
						<StyledIssue />
						Error: cookies are disabled
					</ErrorTitle>
					<div>You will have to enable cookies in your browser to continue.</div>
				</Card>
			</ErrorWrapper>
		)
	}

	if (didReceiveError && (!cookieData.serverCookieExists || !cookieData.websocketCookieExists)) {
		return (
			<ErrorWrapper>
				<Card>
					<div>
						<ErrorTitle>
							<StyledIssue />
							Error: Missing necessary cookie(s):
						</ErrorTitle>
					</div>
					<div>
						This can usually be fixed by{' '}
						<a href={config.missionLoginUrl}>joining the mission again.</a>
					</div>
					<ClickableAside onClick={() => setShowErrorDetails(!showErrorDetails)}>
						{showErrorDetails ? 'hide' : 'show'} technical details{' '}
					</ClickableAside>
					{showErrorDetails ? (
						<>
							{!cookieData.serverCookieExists ? (
								<Aside>{'  '}- Server Session Cookie Missing</Aside>
							) : null}
							{!cookieData.websocketCookieExists ? (
								<Aside>{'  '}- Websocket Session Cookie Missing</Aside>
							) : null}{' '}
						</>
					) : null}
				</Card>
			</ErrorWrapper>
		)
	}

	if (didReceiveError && missionUrlResolvingError) {
		return (
			<ErrorWrapper>
				<Card>
					<ErrorTitle>
						<StyledIssue />
						Error while connecting to mission:
					</ErrorTitle>
					<div>{missionUrlResolvingError}</div>
				</Card>
			</ErrorWrapper>
		)
	}

	return <>{children}</>
}

export type FetchError = { message: string, response?: { status?: string } }
const MAX_RETRIES = 5
/**
 * This hook will fetch the mission server url from matchmaker and dispatch it to the app redux store.
 * Error parameters are optional because it is acceptable to not get the mission server url on the Login Screen.
 * @param {{onError?: ({error: FetchError, retryCount: number}) => void, clearError?: () => void}} props
 */
export function useMissionServerUrlFetcher({
	onError,
	clearError,
	missionCode,
}: {
	onError?: ({ error: FetchError, retryCount: number }) => void,
	clearError?: () => void,
	missionCode: ?string,
} = {}): void {
	const dispatch = useDispatch()
	const missionServerUrl = useSelector(getMissionServerUrl)
	const websocketUrl = useSelector(getWebSocketUrl)
	const errorHandles = useRef({ onError, clearError })

	useEffect(() => {
		errorHandles.current.onError = onError
		errorHandles.current.clearError = clearError
	}, [onError, clearError])

	useEffect(() => {
		if (!missionCode) {
			if (errorHandles.current.onError) {
				errorHandles.current.onError({
					error: { message: 'no missionCode was provided' },
					retryCount: 0,
				})
			}
			return
		}
		if (missionServerUrl && websocketUrl) {
			return
		}
		if (!config.useMatchmaker) {
			dispatch(setMissionServerUrl(config.missionServerUrl))
			dispatch(setWebSocketUrl(config.webSocketServerUrl, missionCode, config.missionServerUrl))
			return
		}

		let retryCount = 0

		let invalidated = false
		const tryToFetch = () => {
			if (invalidated) {
				return
			}
			retryCount++
			const matchMakerConnectUrl = `${config.matchmakerUrl}/connect?missionCode=${missionCode}`
			axios
				.get(matchMakerConnectUrl, {
					withCredentials: true,
				})
				.then(res => {
					if (invalidated) {
						return
					}
					errorHandles.current?.clearError && errorHandles.current.clearError()
					const missionServerUrl = getMissionServerHttpAddress(res.data.missionServerUrl)
					dispatch(setMissionServerUrl(missionServerUrl))
					dispatch(
						setWebSocketUrl(
							getMissionServerWebsocketAddress(res.data.missionServerUrl),
							missionCode,
							missionServerUrl
						)
					)
				})
				.catch(err => {
					errorHandles.current?.onError && errorHandles.current.onError({ error: err, retryCount })
					if (invalidated) {
						return
					}
					if (retryCount === MAX_RETRIES) {
						if (isConnectedToMission()) {
							window.location.href = '/'
						}
						return
					}
					if (isConnectedToMission()) {
						setTimeout(tryToFetch, MISSION_SERVER_URL_RETRY_TIME)
					}
				})
		}
		tryToFetch()
		return () => {
			invalidated = true
		}
	}, [dispatch, websocketUrl, missionServerUrl, missionCode])
}

/**
 Change the url to reflect that we are "connected" to a mission
 * @param {string} missionCode include the mission code in the new url 
 */
export function connectToMission(missionCode: string, studentId?: string) {
	if (studentId) {
		sessionStorage.setItem(config.sessionStorageStudentIdKey, studentId)
	} else {
		sessionStorage.removeItem(config.sessionStorageStudentIdKey)
	}
	const newUrl = `/${missionCode}/run`
	if (window.location.pathname !== newUrl) {
		window.location.href = newUrl
	}
}

/**
 * Checks if url says we are connected to a mission
 * @returns {boolean}
 */
export function isConnectedToMission(): boolean {
	return isWantingToRunMissionAsStudent() || isWantingToRunMissionAsControl()
}

/**
 * Checks if url says we want to connect as a student to the mission
 * @returns {boolean}
 */
export function isWantingToRunMissionAsStudent(): boolean {
	const url = window.location.pathname
	const lastItemIndex = url.lastIndexOf('/') + 1
	if (lastItemIndex < window.location.pathname.length) {
		const action = url.substring(lastItemIndex)
		return action === 'run'
	}
	return false
}

/**
 * Checks if url says we want to connect as a controller to the mission
 * @returns {boolean}
 */
export function isWantingToRunMissionAsControl(): boolean {
	const url = window.location.pathname
	const lastItemIndex = url.lastIndexOf('/') + 1
	if (lastItemIndex < window.location.pathname.length) {
		const action = url.substring(lastItemIndex)
		return action === 'control'
	}
	return false
}

/**
 * An alias for `isWantingToRunMissionAsControl`
 * @returns {boolean}
 */
export function isController(): boolean {
	return isWantingToRunMissionAsControl()
}

/**
 * Gets mission code from the current url
 * @returns {?string} the missionCode
 */
export function getMissionCodeFromUrl(): ?string {
	const url = window.location.pathname
	return url.split('/')[1] || null
}

/**
 * getNewDomain - get the updated domain from the current domain (hostname). This will translate any `infinid.io` website domain to their `mission.io` counterpart.
 *
 * @param {string} currentDomain - the current domain
 *
 * @return {?string} - the new domain of the website that used to be hosted at the "currentDomain". null/undefined if the website has not moved.
 */
function getNewDomain(currentDomain: string, path: string): ?string {
	if (currentDomain === 'infinid.io' || currentDomain === 'www.infinid.io') {
		if (!path || path === '/') {
			return 'mission.io'
		}
		return 'launch.mission.io'
	} else if (currentDomain === 'staging.infinid.io') {
		return 'staging.launch.mission.io'
	}

	if (!currentDomain.endsWith('.infinid.io')) {
		return null
	}

	return currentDomain.replace(/\.infinid\.io$/, '.mission.io')
}

/**
 * If the url is an `infinid.io` url, it will return the new `mission.io` url. It will return null/undefined if there is no new url (ie. it has a mission.io domain)
 *
 * @returns {?string}
 */
export function getNewMissionIOUrlFromInfinidIOUrl(url: string): ?string {
	try {
		const currentUrl = new URL(url)
		const newDomain = getNewDomain(currentUrl.hostname, currentUrl.pathname)
		if (!newDomain) {
			return null
		}

		currentUrl.hostname = newDomain
		return currentUrl.toString()
	} catch (error) {
		console.error(error)
		return null
	}
}

const testCookieName = 'THIS_IS_A_TEST_COOKIE_TO_DETERMINE_IF_COOKIES_ARE_ENABLED'

/**
 * areCookiesSetupCorrectly - returns an object describing the current cookies stored and the cookie permissions
 *
 * @return {{
 *	 websocketCookieExists: boolean, true if the cookie needed for the websocket exists
 *	 serverCookieExists: boolean, true if the cookie needed for the mission-server exists
 *	 cookiesDisabled: boolean, true if cookies are disabled by the browser
 * }}
 */
function areCookiesSetupCorrectly(): {
	websocketCookieExists: boolean,
	serverCookieExists: boolean,
	cookiesDisabled: boolean,
} {
	// clear test cookie
	document.cookie = `${testCookieName}= ; expires = Thu, 01 Jan 1970 00:00:00 GMT`
	// set test cookie
	document.cookie = `${testCookieName}=`
	return {
		cookiesDisabled: document.cookie.indexOf(testCookieName) === -1,
		websocketCookieExists: document.cookie.indexOf(config.websocketCookieName) !== -1,
		serverCookieExists: document.cookie.indexOf(config.serverCookieName) !== -1,
	}
}

const ErrorWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;
	weight: 100%;

	${({ theme }) => `
	color: white;

	a {
		color: ${theme.secondary};
	}

	a:hover {
		color: ${theme.secondary}A0;
	}

	div {
		margin-top: ${theme.spacing};
	}
	`}
`
const Card = styled.div`
	${({ theme }) => `
	padding: ${theme.spacing2x};
	background-color: ${PRIMARY_PURPLE_FAINT};
	border-radius: ${theme.spacing};
	border: 1px solid ${theme.neutralLightest};
	`}
`

const StyledIssue = styled(GoIssueOpened)`
	color: ${DARK_RED};
	font-size: ${({ theme }) => theme.fontm};
	margin-right: ${({ theme }) => theme.spacing};
	justify-self: center;
	align-self: center;
`

const ErrorTitle = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: ${({ theme }) => theme.spacing};
	color: white;
	font-weight: bold;
	font-size: ${({ theme }) => theme.fontm};
`

const Aside = styled.div`
	color: ${({ theme }) => theme.neutralVariant};

	font-size: ${({ theme }) => theme.fontxxs};
`

const ClickableAside = styled(Aside)`
	color: ${({ theme }) => theme.neutral};

	&:hover {
		text-decoration: underline;
		cursor: pointer;
	}
`
