import React, { forwardRef, type AbstractComponent } from 'react'
import styled from 'styled-components/macro'
import type { BlasterProps as Props } from './upgradeLookup'
import { DefaultWrapper } from '../constants'

function Blaster(props: Props, ref): React$Node {
	const { transformationWrapper: Wrapper = DefaultWrapper, children, ...otherProps } = props
	return (
		<Svg viewBox="0 0 399 771" {...otherProps} fill="none">
			<defs>
				<radialGradient
					id="paint0_radial_1288_1000"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(8973.17 287.443) scale(45.8878 45.8861)">
					<stop offset="0.492" stopColor="#3B384C" />
					<stop offset="1" stopColor="#1A1A26" />
				</radialGradient>
				<linearGradient
					id="paint1_linear_1288_1000"
					x1="366.321"
					y1="309.744"
					x2="364.711"
					y2="186.812"
					gradientUnits="userSpaceOnUse">
					<stop stopColor="#5E5E78" />
					<stop offset="0.763" stopColor="#74748B" />
					<stop offset="1" stopColor="#7D7D93" />
				</linearGradient>
				<linearGradient
					id="paint2_linear_1288_1000"
					x1="363.974"
					y1="236.186"
					x2="-172.266"
					y2="-60.1458"
					gradientUnits="userSpaceOnUse">
					<stop stopColor="#5E5E78" />
					<stop offset="0.763" stopColor="#74748B" />
					<stop offset="1" stopColor="#7D7D93" />
				</linearGradient>
				<linearGradient
					id="paint3_linear_1288_1000"
					x1="367.552"
					y1="317.058"
					x2="367.348"
					y2="304.443"
					gradientUnits="userSpaceOnUse">
					<stop stopColor="#5E5E78" />
					<stop offset="0.763" stopColor="#74748B" />
					<stop offset="1" stopColor="#7D7D93" />
				</linearGradient>
				<linearGradient
					id="paint4_linear_1288_1000"
					x1="294.192"
					y1="410.932"
					x2="283.435"
					y2="410.932"
					gradientUnits="userSpaceOnUse">
					<stop stopColor="#5E5E78" />
					<stop offset="0.326" stopColor="#74748D" />
					<stop offset="1" stopColor="#AFAFC3" />
				</linearGradient>
				<linearGradient
					id="paint5_linear_1288_1000"
					x1="294.192"
					y1="335.752"
					x2="283.435"
					y2="335.752"
					gradientUnits="userSpaceOnUse">
					<stop stopColor="#5E5E78" />
					<stop offset="0.326" stopColor="#74748D" />
					<stop offset="1" stopColor="#AFAFC3" />
				</linearGradient>
				<radialGradient
					id="paint6_radial_1288_1000"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(199.197 571.827) scale(199.118)">
					<stop offset="0.492" stopColor="#3B384C" />
					<stop offset="1" stopColor="#1A1A26" />
				</radialGradient>
				<linearGradient
					id="paint7_linear_1288_1000"
					x1="171.572"
					y1="71.9549"
					x2="191.808"
					y2="294.298"
					gradientUnits="userSpaceOnUse">
					<stop stopColor="#5E5E78" />
					<stop offset="0.763" stopColor="#74748B" />
					<stop offset="1" stopColor="#7D7D93" />
				</linearGradient>
				<linearGradient
					id="paint8_linear_1288_1000"
					x1="85.043"
					y1="114.938"
					x2="313.351"
					y2="114.938"
					gradientUnits="userSpaceOnUse">
					<stop stopColor="#5E5E78" />
					<stop offset="0.384" stopColor="#74748B" />
					<stop offset="0.504" stopColor="#7D7D93" />
					<stop offset="1" stopColor="#5E5E78" />
				</linearGradient>
				<linearGradient
					id="paint9_linear_1288_1000"
					x1="151.697"
					y1="98.464"
					x2="242.318"
					y2="98.464"
					gradientUnits="userSpaceOnUse">
					<stop stopColor="#1A1A26" />
					<stop offset="0.492" stopColor="#3B384C" />
					<stop offset="0.994" stopColor="#1A1A26" />
				</linearGradient>
				<linearGradient
					id="paint10_linear_1288_1000"
					x1="194.049"
					y1="138.259"
					x2="205.414"
					y2="162.741"
					gradientUnits="userSpaceOnUse">
					<stop stopColor="#5E5E78" />
					<stop offset="0.763" stopColor="#74748B" />
					<stop offset="1" stopColor="#7D7D93" />
				</linearGradient>
				<linearGradient
					id="paint11_linear_1288_1000"
					x1="75.3779"
					y1="80.7"
					x2="323.017"
					y2="80.7"
					gradientUnits="userSpaceOnUse">
					<stop stopColor="#5E5E78" />
					<stop offset="0.384" stopColor="#74748B" />
					<stop offset="0.504" stopColor="#7D7D93" />
					<stop offset="1" stopColor="#5E5E78" />
				</linearGradient>
				<linearGradient
					id="paint12_linear_1288_1000"
					x1="68.7852"
					y1="6.23199"
					x2="329.609"
					y2="6.23199"
					gradientUnits="userSpaceOnUse">
					<stop stopColor="#5E5E78" />
					<stop offset="0.384" stopColor="#74748B" />
					<stop offset="0.504" stopColor="#7D7D93" />
					<stop offset="1" stopColor="#5E5E78" />
				</linearGradient>
				<linearGradient
					id="paint13_linear_1288_1000"
					x1="70.0893"
					y1="43.4645"
					x2="328.044"
					y2="43.4645"
					gradientUnits="userSpaceOnUse">
					<stop stopColor="#5E5E78" />
					<stop offset="0.384" stopColor="#74748B" />
					<stop offset="0.504" stopColor="#7D7D93" />
					<stop offset="1" stopColor="#5E5E78" />
				</linearGradient>
				<linearGradient
					id="paint14_linear_1288_1000"
					x1="199.197"
					y1="46.0307"
					x2="199.197"
					y2="37.8586"
					gradientUnits="userSpaceOnUse">
					<stop stopColor="#1A1A26" stopOpacity="0" />
					<stop offset="0.994" stopColor="#1A1A26" />
				</linearGradient>
				<linearGradient
					id="paint15_linear_1288_1000"
					x1="155.623"
					y1="10.1653"
					x2="148.08"
					y2="9.13033"
					gradientUnits="userSpaceOnUse">
					<stop stopColor="#505064" />
					<stop offset="0.527" stopColor="#65657A" />
					<stop offset="1" stopColor="#7D7D93" />
				</linearGradient>
				<linearGradient
					id="paint16_linear_1288_1000"
					x1="220.737"
					y1="2.73369"
					x2="213.155"
					y2="1.68805"
					gradientUnits="userSpaceOnUse">
					<stop stopColor="#505064" />
					<stop offset="0.527" stopColor="#65657A" />
					<stop offset="1" stopColor="#7D7D93" />
				</linearGradient>
				<linearGradient
					id="paint17_linear_1288_1000"
					x1="191.457"
					y1="270.101"
					x2="117.593"
					y2="169.103"
					gradientUnits="userSpaceOnUse">
					<stop stopColor="#5E5E78" />
					<stop offset="0.326" stopColor="#74748D" />
					<stop offset="1" stopColor="#AFAFC3" />
				</linearGradient>
				<linearGradient
					id="paint18_linear_1288_1000"
					x1="290.582"
					y1="215.666"
					x2="277.52"
					y2="190.263"
					gradientUnits="userSpaceOnUse">
					<stop stopColor="#5E5E78" />
					<stop offset="0.326" stopColor="#74748D" />
					<stop offset="1" stopColor="#AFAFC3" />
				</linearGradient>
				<linearGradient
					id="paint19_linear_1288_1000"
					x1="286.252"
					y1="479.612"
					x2="280.283"
					y2="448.055"
					gradientUnits="userSpaceOnUse">
					<stop stopColor="#5E5E78" />
					<stop offset="0.763" stopColor="#74748B" />
					<stop offset="1" stopColor="#7D7D93" />
				</linearGradient>
				<linearGradient
					id="paint20_linear_1288_1000"
					x1="301.244"
					y1="472.674"
					x2="280.752"
					y2="447.827"
					gradientUnits="userSpaceOnUse">
					<stop stopColor="#5E5E78" />
					<stop offset="0.763" stopColor="#74748B" />
					<stop offset="1" stopColor="#7D7D93" />
				</linearGradient>
				<linearGradient
					id="paint21_linear_1288_1000"
					x1="184.01"
					y1="522.437"
					x2="178.234"
					y2="491.972"
					gradientUnits="userSpaceOnUse">
					<stop stopColor="#5E5E78" />
					<stop offset="0.763" stopColor="#74748B" />
					<stop offset="1" stopColor="#7D7D93" />
				</linearGradient>
				<linearGradient
					id="paint22_linear_1288_1000"
					x1="176.094"
					y1="325.718"
					x2="238.97"
					y2="338.537"
					gradientUnits="userSpaceOnUse">
					<stop stopColor="#5E5E78" />
					<stop offset="0.326" stopColor="#74748D" />
					<stop offset="1" stopColor="#AFAFC3" />
				</linearGradient>
				<linearGradient
					id="paint23_linear_1288_1000"
					x1="177.569"
					y1="499.172"
					x2="176.174"
					y2="497.021"
					gradientUnits="userSpaceOnUse">
					<stop stopColor="#5E5E78" />
					<stop offset="0.763" stopColor="#74748B" />
					<stop offset="1" stopColor="#7D7D93" />
				</linearGradient>
				<linearGradient
					id="paint24_linear_1288_1000"
					x1="206.519"
					y1="515.996"
					x2="206.261"
					y2="511.568"
					gradientUnits="userSpaceOnUse">
					<stop stopColor="#5E5E78" />
					<stop offset="0.763" stopColor="#74748B" />
					<stop offset="1" stopColor="#7D7D93" />
				</linearGradient>
				<linearGradient
					id="paint25_linear_1288_1000"
					x1="234.455"
					y1="501.437"
					x2="232.624"
					y2="498.62"
					gradientUnits="userSpaceOnUse">
					<stop stopColor="#5E5E78" />
					<stop offset="0.763" stopColor="#74748B" />
					<stop offset="1" stopColor="#7D7D93" />
				</linearGradient>
				<linearGradient
					id="paint26_linear_1288_1000"
					x1="248.02"
					y1="455.526"
					x2="248.002"
					y2="453.686"
					gradientUnits="userSpaceOnUse">
					<stop stopColor="#5E5E78" />
					<stop offset="0.763" stopColor="#74748B" />
					<stop offset="1" stopColor="#7D7D93" />
				</linearGradient>
				<linearGradient
					id="paint27_linear_1288_1000"
					x1="157.806"
					y1="451.951"
					x2="157.793"
					y2="450.559"
					gradientUnits="userSpaceOnUse">
					<stop stopColor="#5E5E78" />
					<stop offset="0.763" stopColor="#74748B" />
					<stop offset="1" stopColor="#7D7D93" />
				</linearGradient>
				<linearGradient
					id="paint28_linear_1288_1000"
					x1="109.992"
					y1="406.262"
					x2="109.837"
					y2="405.63"
					gradientUnits="userSpaceOnUse">
					<stop stopColor="#5E5E78" />
					<stop offset="0.763" stopColor="#74748B" />
					<stop offset="1" stopColor="#7D7D93" />
				</linearGradient>
				<linearGradient
					id="paint29_linear_1288_1000"
					x1="293.965"
					y1="410.308"
					x2="293.524"
					y2="408.58"
					gradientUnits="userSpaceOnUse">
					<stop stopColor="#5E5E78" />
					<stop offset="0.763" stopColor="#74748B" />
					<stop offset="1" stopColor="#7D7D93" />
				</linearGradient>
			</defs>
			<Wrapper>
				{children}
				<path
					d="M331.049 332.319L362.171 301.197V287.397H356.671L355.557 288.511C355.008 289.06 354.356 289.496 353.639 289.793C352.922 290.09 352.153 290.243 351.376 290.243C350.6 290.243 349.831 290.09 349.114 289.793C348.396 289.496 347.745 289.06 347.196 288.511C346.088 287.402 345.465 285.899 345.465 284.331V237.456L358.504 250.495V241.162L337.47 220.128L331.049 332.319Z"
					fill="url(#paint0_radial_1288_1000)"
				/>
				<path
					d="M356.668 324.696C357.402 325.43 357.813 325.842 358.548 326.576H375.961L377.841 324.696V318.036H356.665L356.668 324.696Z"
					fill="url(#paint1_linear_1288_1000)"
				/>
				<path
					d="M375.961 310.332L377.841 308.452V235.979L373.218 218.994L371.404 217.607H363.104L361.29 218.994L356.668 235.979V308.452C357.402 309.186 357.813 309.598 358.548 310.332H359.491V316.156H358.548C357.813 316.89 357.402 317.301 356.668 318.036H377.844C377.11 317.301 376.698 316.89 375.964 316.156H375.021V310.332H375.961Z"
					fill="url(#paint2_linear_1288_1000)"
				/>
				<path
					css="mix-blend-mode:multiply"
					opacity="0.2"
					d="M358.548 310.332H359.491V316.156H358.548C357.813 316.89 357.402 317.301 356.668 318.036H377.844C377.11 317.301 376.698 316.89 375.964 316.156H375.021V310.332H375.964L377.844 308.452H356.668C357.402 309.186 357.813 309.598 358.548 310.332Z"
					fill="url(#paint3_linear_1288_1000)"
				/>
				<path d="M308.4 322.306H80.0918V489.945H308.4V322.306Z" fill="black" />
				<path d="M311.756 335.752V410.928" stroke="#8888A5" strokeWidth="8" strokeMiterlimit="10" />
				<path
					d="M311.756 374.929V410.929"
					stroke="#47475E"
					strokeWidth="12"
					strokeMiterlimit="10"
				/>
				<path
					d="M300.109 422.403H323.403L326.491 419.315V402.55L323.403 399.462H300.109L297.021 402.55V419.315L300.109 422.403Z"
					fill="url(#paint4_linear_1288_1000)"
				/>
				<path
					d="M300.109 347.223H323.403L326.491 344.135V327.37L323.403 324.282H300.109L297.021 327.37V344.135L300.109 347.223Z"
					fill="url(#paint5_linear_1288_1000)"
				/>
				<path
					d="M199.197 475.846C101.339 475.846 21.7261 396.233 21.7261 298.375C21.7261 200.517 101.339 120.905 199.197 120.905V160.905C123.397 160.905 61.7261 222.574 61.7261 298.375C61.7261 374.176 123.395 435.846 199.197 435.846V475.846Z"
					fill="#18181A"
				/>
				<path
					opacity="0.5"
					d="M37.1968 283.421C37.1968 283.421 35.0839 324.49 54.2579 368.357"
					stroke="white"
					strokeWidth="4"
					strokeMiterlimit="10"
					strokeLinecap="round"
				/>
				<path
					d="M111.861 393.336L62.3489 429.365C62.0778 429.562 61.7599 429.685 61.4267 429.722C61.0936 429.759 60.7566 429.708 60.449 429.575C60.1413 429.442 59.8737 429.231 59.6726 428.963C59.4714 428.695 59.3435 428.379 59.3017 428.046L57.2717 411.878C57.229 411.536 57.2788 411.188 57.4158 410.872C57.5527 410.555 57.772 410.281 58.0508 410.078L94.8318 383.314C95.1105 383.111 95.4386 382.987 95.7817 382.954C96.1248 382.921 96.4706 382.98 96.7829 383.126L111.543 390.026C111.847 390.168 112.11 390.387 112.304 390.661C112.498 390.935 112.617 391.255 112.649 391.59C112.682 391.924 112.626 392.262 112.487 392.568C112.349 392.874 112.133 393.139 111.861 393.336Z"
					fill="#414151"
				/>
				<path
					ref={ref}
					d="M199.197 770.945C309.167 770.945 398.315 681.797 398.315 571.827C398.315 461.857 309.167 372.709 199.197 372.709C89.2273 372.709 0.0791016 461.857 0.0791016 571.827C0.0791016 681.797 89.2273 770.945 199.197 770.945Z"
					fill="url(#paint6_radial_1288_1000)"
				/>
				<path
					d="M279.261 421.516V449.756L262.791 457.166V479.326C282.528 492.896 297.406 512.424 305.251 535.056C313.095 557.687 313.494 582.234 306.389 605.108C299.285 627.982 285.049 647.983 265.763 662.188C246.477 676.392 223.153 684.054 199.201 684.054C175.249 684.054 151.925 676.392 132.639 662.188C113.353 647.983 99.1174 627.982 92.0125 605.108C84.9077 582.234 85.3065 557.687 93.1509 535.056C100.995 512.424 115.874 492.896 135.611 479.326V457.166L119.141 449.756V421.516"
					stroke="#FBB03B"
					strokeWidth="12"
					strokeMiterlimit="10"
				/>
				<path
					d="M279.261 421.516V449.756L262.791 457.166V479.326C282.528 492.896 297.406 512.424 305.251 535.056C313.095 557.687 313.494 582.234 306.389 605.108C299.285 627.982 285.049 647.983 265.763 662.188C246.477 676.392 223.153 684.054 199.201 684.054C175.249 684.054 151.925 676.392 132.639 662.188C113.353 647.983 99.1174 627.982 92.0125 605.108C84.9077 582.234 85.3065 557.687 93.1509 535.056C100.995 512.424 115.874 492.896 135.611 479.326V457.166L119.141 449.756V421.516"
					stroke="#FFE19F"
					strokeWidth="10"
					strokeMiterlimit="10"
				/>
				<path
					d="M313.351 119.87H85.043V262.9H313.351V119.87Z"
					fill="url(#paint7_linear_1288_1000)"
				/>
				<path
					d="M308.822 110.003H89.572L85.043 119.874H313.351L308.822 110.003Z"
					fill="url(#paint8_linear_1288_1000)"
				/>
				<path
					d="M308.642 86.929H89.751V109.999H308.642V86.929Z"
					fill="url(#paint9_linear_1288_1000)"
				/>
				<path
					d="M199.197 127.635C204.329 127.635 209.252 129.674 212.881 133.303C216.511 136.933 218.55 141.855 218.55 146.988V191.635H179.844V146.988C179.844 141.855 181.883 136.933 185.512 133.303C189.142 129.674 194.064 127.635 199.197 127.635V127.635Z"
					fill="url(#paint10_linear_1288_1000)"
				/>
				<path
					d="M199.197 131.223C201.236 131.223 203.256 131.624 205.14 132.405C207.024 133.185 208.736 134.329 210.178 135.771C211.62 137.213 212.764 138.925 213.545 140.809C214.325 142.693 214.727 144.713 214.727 146.752V188.047H183.668V146.752C183.668 142.633 185.304 138.684 188.216 135.771C191.129 132.859 195.078 131.223 199.197 131.223Z"
					fill="#1A1A26"
				/>
				<path
					d="M71.8447 4.67401H52.3828L49.5498 7.50299V20.448L50.8359 22.822L78.1099 40.604L82.4907 38.231V13.812L81.4368 11.612L73.6238 5.30597L71.8447 4.67401Z"
					fill="#8888A5"
				/>
				<path
					d="M326.549 4.67401H346.011L348.844 7.50702V20.452L347.558 22.826L320.284 40.603L315.903 38.23V13.812L316.957 11.612L324.77 5.30597L326.549 4.67401Z"
					fill="#8888A5"
				/>
				<path
					d="M316.424 86.929H81.9697L75.3779 74.471H323.017L316.424 86.929Z"
					fill="url(#paint11_linear_1288_1000)"
				/>
				<path
					opacity="0.4"
					d="M316.424 86.929H81.9697L75.3779 74.471H323.017L316.424 86.929Z"
					fill="#0C0C23"
				/>
				<path
					d="M75.3782 0.00299072H323.017L329.609 12.461H68.7852L75.3782 0.00299072Z"
					fill="url(#paint12_linear_1288_1000)"
				/>
				<path
					d="M329.609 12.458H68.7852L75.3772 74.471H323.016L329.609 12.458Z"
					fill="url(#paint13_linear_1288_1000)"
				/>
				<path
					opacity="0.2"
					d="M329.609 12.458H68.7852L75.3772 74.471H323.016L329.609 12.458Z"
					fill="#1C1C3F"
				/>
				<path
					opacity="0.05"
					d="M211.609 30.223H186.785V48.105H211.609V30.223Z"
					fill="url(#paint14_linear_1288_1000)"
				/>
				<path d="M212.437 16.079H185.957V31.458H212.437V16.079Z" fill="#6D6D89" />
				<path id="laser-start" d="M212.437 0H185.957V17.967H212.437V0Z" fill="#8888A5" />
				<path d="M212.437 0H185.957V3.196H212.437V0Z" fill="#9393AD" />
				<path
					d="M134.672 51.99L109.604 26.922H71.3228"
					stroke="#1F1F2D"
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					opacity="0.3"
					d="M137.672 51.99L110.604 24.922H71.3228"
					stroke="#1F1F2D"
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					opacity="0.1"
					d="M133.672 53.99L108.604 28.922H71.3228"
					stroke="white"
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<g css="mix-blend-mode: overlay;">
					<g css="mix-blend-mode: overlay;">
						<g css="mix-blend-mode: overlay;" opacity="0.12">
							<path
								d="M74.1389 12.458H77.7209C77.9789 12.4582 78.2334 12.5164 78.4658 12.6284C78.6982 12.7405 78.9024 12.9034 79.0632 13.1051C79.224 13.3068 79.3373 13.5422 79.3947 13.7937C79.4521 14.0452 79.4521 14.3065 79.3947 14.558L78.5717 18.158C77.7219 21.8734 77.5059 25.7056 77.9328 29.493L80.8208 55.093C81.4365 60.5515 83.3727 65.7778 86.4619 70.32L87.4619 71.792C87.6375 72.0503 87.7394 72.3515 87.7566 72.6633C87.7738 72.9752 87.7057 73.2858 87.5595 73.5618C87.4134 73.8379 87.1948 74.0689 86.9272 74.2299C86.6596 74.391 86.3531 74.4761 86.0407 74.476H80.3608C79.9367 74.4759 79.5277 74.319 79.2124 74.0354C78.897 73.7518 78.6977 73.3617 78.6528 72.94L72.4257 14.362C72.3994 14.1217 72.4241 13.8785 72.4983 13.6483C72.5724 13.4182 72.6945 13.2064 72.8562 13.0266C73.0179 12.8469 73.2157 12.7033 73.4367 12.6053C73.6578 12.5073 73.8971 12.4571 74.1389 12.458Z"
								fill="#F2F2F2"
							/>
						</g>
						<g css="mix-blend-mode:overlay" opacity="0.1">
							<path
								d="M89.9938 74.471L80.0918 12.458"
								stroke="#1A1A26"
								strokeWidth="6"
								strokeMiterlimit="10"
							/>
						</g>
						<g css="mix-blend-mode:overlay" opacity="0.1">
							<path
								d="M97.6647 74.471L87.7627 12.458"
								stroke="#1A1A26"
								strokeWidth="4"
								strokeMiterlimit="10"
							/>
						</g>
					</g>
					<g css="mix-blend-mode:overlay">
						<g css="mix-blend-mode:overlay" opacity="0.12">
							<path
								d="M324.255 12.458H320.673C320.415 12.4582 320.16 12.5164 319.927 12.6284C319.695 12.7404 319.491 12.9034 319.33 13.1051C319.169 13.3068 319.056 13.5422 318.998 13.7937C318.941 14.0452 318.941 14.3065 318.998 14.558L319.821 18.158C320.671 21.8734 320.888 25.7056 320.461 29.493L317.572 55.093C316.957 60.5515 315.021 65.7778 311.932 70.32L310.932 71.792C310.756 72.0502 310.654 72.3513 310.637 72.6631C310.62 72.9748 310.688 73.2854 310.834 73.5614C310.98 73.8374 311.198 74.0684 311.466 74.2295C311.733 74.3906 312.039 74.4759 312.352 74.476H318.029C318.453 74.4759 318.862 74.319 319.177 74.0354C319.492 73.7518 319.692 73.3617 319.737 72.94L325.964 14.362C325.99 14.122 325.965 13.8793 325.891 13.6495C325.817 13.4197 325.695 13.2081 325.534 13.0284C325.373 12.8488 325.175 12.7051 324.955 12.6068C324.735 12.5085 324.496 12.4579 324.255 12.458Z"
								fill="#F2F2F2"
							/>
						</g>
						<g css="mix-blend-mode:overlay" opacity="0.1">
							<path
								d="M318.301 12.458L308.399 74.471"
								stroke="#1A1A26"
								strokeWidth="6"
								strokeMiterlimit="10"
							/>
						</g>
						<g css="mix-blend-mode:overlay" opacity="0.1">
							<path
								d="M300.729 74.471L310.631 12.458"
								stroke="#1A1A26"
								strokeWidth="4"
								strokeMiterlimit="10"
							/>
						</g>
					</g>
				</g>
				<path
					d="M136.216 48.579L95.7852 137.903C95.6401 138.223 95.5622 138.568 95.5562 138.919C95.5501 139.27 95.616 139.619 95.75 139.943L99.4502 148.935C99.6977 149.536 99.8195 150.181 99.8083 150.83C99.7972 151.48 99.653 152.12 99.385 152.712L83.2852 188.271C83.0172 188.863 82.6311 189.394 82.1504 189.831C81.6697 190.267 81.1046 190.602 80.49 190.812L71.5032 193.89C71.1039 194.027 70.7366 194.243 70.4243 194.527C70.112 194.811 69.8611 195.156 69.687 195.54L52.1292 234.331C51.8912 234.857 51.768 235.428 51.7681 236.005V308.577L70.592 337.283H97.415L168.003 203.448V46.458H139.496C138.804 46.4597 138.126 46.6605 137.544 47.0366C136.963 47.4126 136.502 47.9481 136.216 48.579Z"
					fill="#8888A5"
				/>
				<path
					d="M61.4229 307.164V238.693L77.6609 215.403L101.178 205.874L120.361 159.282L111.538 135.635L126.086 99.988L146.126 84.106H163.773V197.403L88.5959 329.403H76.5959L61.4229 307.164Z"
					fill="#AFAFC6"
				/>
				<path
					opacity="0.5"
					d="M70.9469 196.314L76.93 213.329C77.0247 213.596 77.1722 213.84 77.3639 214.049C77.5556 214.257 77.7873 214.424 78.0453 214.541C78.3032 214.657 78.5821 214.72 78.8651 214.726C79.148 214.732 79.4294 214.681 79.692 214.575L97.9501 207.175C98.2849 207.039 98.5789 206.82 98.8036 206.537C99.0283 206.254 99.1759 205.918 99.2323 205.561C99.2886 205.204 99.2517 204.839 99.1251 204.5C98.9985 204.162 98.7866 203.862 98.5099 203.63L83.3509 190.903C83.0743 190.671 82.7427 190.515 82.388 190.449C82.0333 190.382 81.6676 190.409 81.326 190.525L72.244 193.636C71.9822 193.726 71.7407 193.866 71.5336 194.05C71.3264 194.233 71.1576 194.456 71.037 194.705C70.9164 194.954 70.8464 195.224 70.8309 195.5C70.8154 195.777 70.8548 196.053 70.9469 196.314Z"
					fill="#AFAFC6"
				/>
				<g css="mix-blend-mode:multiply" opacity="0.1">
					<path
						d="M97.1492 138.546L110.257 135.894C110.615 135.822 110.987 135.88 111.306 136.057C111.626 136.235 111.871 136.52 111.999 136.862L119.124 155.962C119.229 156.242 119.249 156.548 119.183 156.839C119.116 157.131 118.966 157.398 118.75 157.605C118.534 157.812 118.262 157.952 117.968 158.007C117.673 158.062 117.369 158.029 117.093 157.913L100.821 151.085C100.447 150.928 100.15 150.63 99.9962 150.255L96.0342 140.638C95.9474 140.429 95.9079 140.203 95.9188 139.976C95.9297 139.75 95.9906 139.529 96.0972 139.329C96.2039 139.128 96.3536 138.955 96.5355 138.819C96.7173 138.684 96.927 138.591 97.1492 138.546Z"
						fill="#8888A5"
					/>
				</g>
				<path
					opacity="0.5"
					d="M118.693 89.067L125.16 98.617C125.279 98.792 125.432 98.9407 125.611 99.0537C125.789 99.1667 125.99 99.2415 126.198 99.2736C126.407 99.3057 126.621 99.2944 126.825 99.2403C127.03 99.1861 127.22 99.0904 127.386 98.959L145.361 84.713C145.59 84.5314 145.763 84.2881 145.859 84.0118C145.955 83.7355 145.97 83.4376 145.902 83.153L138.215 50.832C138.141 50.5202 137.971 50.2393 137.73 50.0288C137.488 49.8182 137.186 49.6886 136.867 49.6579C136.548 49.6273 136.228 49.6972 135.95 49.8579C135.673 50.0186 135.453 50.262 135.321 50.554L118.565 87.572C118.457 87.8114 118.412 88.0745 118.434 88.3362C118.457 88.5979 118.546 88.8495 118.693 89.067Z"
					fill="#AFAFC6"
				/>
				<path
					d="M142.301 127.07L154.176 135.196C154.67 135.534 155.247 135.73 155.844 135.765C156.441 135.799 157.037 135.669 157.566 135.39C158.095 135.111 158.538 134.693 158.847 134.181C159.157 133.668 159.32 133.081 159.32 132.483V116.231C159.32 115.633 159.157 115.046 158.847 114.533C158.538 114.021 158.095 113.603 157.566 113.324C157.037 113.045 156.441 112.915 155.844 112.949C155.247 112.984 154.67 113.18 154.176 113.518L142.301 121.644C141.86 121.946 141.499 122.351 141.25 122.823C141.001 123.296 140.871 123.823 140.871 124.357C140.871 124.891 141.001 125.418 141.25 125.891C141.499 126.363 141.86 126.768 142.301 127.07Z"
					fill="#8888A5"
				/>
				<path
					d="M144.214 125.823L154.673 132.98C154.94 133.163 155.251 133.269 155.574 133.287C155.897 133.306 156.219 133.236 156.505 133.085C156.791 132.934 157.03 132.708 157.197 132.431C157.364 132.155 157.452 131.837 157.452 131.514V117.203C157.452 116.88 157.364 116.562 157.197 116.286C157.03 116.009 156.791 115.783 156.505 115.632C156.219 115.481 155.897 115.411 155.574 115.43C155.251 115.448 154.94 115.554 154.673 115.737L144.214 122.894C143.976 123.057 143.782 123.276 143.647 123.531C143.513 123.786 143.443 124.07 143.443 124.359C143.443 124.647 143.513 124.931 143.647 125.186C143.782 125.441 143.976 125.66 144.214 125.823Z"
					fill="#1A1A26"
				/>
				<path
					d="M142.301 166.28L154.176 174.406C154.67 174.744 155.246 174.941 155.844 174.975C156.441 175.01 157.037 174.881 157.566 174.601C158.095 174.322 158.539 173.904 158.848 173.392C159.157 172.879 159.32 172.292 159.32 171.694V155.442C159.32 154.844 159.157 154.256 158.848 153.744C158.539 153.231 158.096 152.813 157.566 152.534C157.037 152.255 156.442 152.125 155.844 152.16C155.247 152.194 154.67 152.391 154.176 152.729L142.301 160.855C141.86 161.157 141.5 161.562 141.25 162.034C141.001 162.507 140.871 163.033 140.871 163.568C140.871 164.102 141.001 164.628 141.25 165.101C141.5 165.573 141.86 165.978 142.301 166.28Z"
					fill="#8888A5"
				/>
				<path
					d="M144.214 165.034L154.673 172.191C154.94 172.374 155.251 172.48 155.574 172.498C155.897 172.517 156.219 172.447 156.505 172.296C156.791 172.145 157.03 171.919 157.197 171.642C157.364 171.365 157.452 171.047 157.452 170.724V156.411C157.452 156.088 157.364 155.77 157.197 155.494C157.03 155.217 156.79 154.991 156.504 154.84C156.218 154.689 155.897 154.619 155.574 154.638C155.251 154.656 154.94 154.762 154.673 154.945L144.214 162.102C143.975 162.265 143.78 162.484 143.646 162.739C143.511 162.995 143.44 163.279 143.44 163.568C143.44 163.857 143.511 164.141 143.646 164.397C143.78 164.652 143.975 164.871 144.214 165.034Z"
					fill="#1A1A26"
				/>
				<path
					d="M157.889 141.25L146.014 133.124C145.52 132.786 144.944 132.59 144.347 132.555C143.749 132.521 143.154 132.65 142.625 132.929C142.096 133.208 141.653 133.627 141.344 134.139C141.035 134.651 140.871 135.238 140.871 135.836V152.088C140.871 152.686 141.034 153.273 141.343 153.786C141.652 154.298 142.095 154.716 142.625 154.995C143.154 155.275 143.749 155.404 144.346 155.37C144.944 155.335 145.52 155.139 146.014 154.801L157.889 146.675C158.33 146.373 158.691 145.968 158.94 145.496C159.189 145.023 159.32 144.497 159.32 143.962C159.32 143.428 159.189 142.902 158.94 142.429C158.691 141.957 158.33 141.552 157.889 141.25Z"
					fill="#8888A5"
				/>
				<path
					d="M155.976 142.496L145.518 135.339C145.251 135.156 144.94 135.05 144.617 135.032C144.294 135.013 143.972 135.083 143.686 135.234C143.4 135.385 143.161 135.611 142.994 135.888C142.827 136.165 142.738 136.483 142.738 136.806V151.119C142.738 151.443 142.826 151.76 142.993 152.037C143.16 152.314 143.4 152.54 143.686 152.691C143.972 152.842 144.294 152.912 144.617 152.893C144.94 152.874 145.251 152.768 145.518 152.585L155.976 145.428C156.215 145.265 156.41 145.046 156.545 144.791C156.679 144.535 156.75 144.251 156.75 143.962C156.75 143.673 156.679 143.389 156.545 143.133C156.41 142.878 156.215 142.659 155.976 142.496Z"
					fill="#1A1A26"
				/>
				<path
					d="M155.661 38.811V93.273C155.661 93.5113 155.713 93.7467 155.813 93.963L161.704 106.718C161.804 106.934 161.856 107.17 161.856 107.408V187.182C161.856 187.486 161.94 187.783 162.099 188.042C162.258 188.301 162.485 188.511 162.756 188.649C163.026 188.787 163.33 188.847 163.632 188.823C163.935 188.8 164.225 188.692 164.471 188.514L174.128 181.497C174.338 181.344 174.509 181.144 174.627 180.912C174.745 180.68 174.807 180.424 174.807 180.164V51.709C174.807 51.429 174.735 51.1537 174.599 50.909L167.426 38.009C167.284 37.7522 167.075 37.5383 166.822 37.3893C166.569 37.2404 166.281 37.1619 165.987 37.162H157.308C157.092 37.162 156.877 37.2046 156.677 37.2875C156.477 37.3704 156.296 37.4919 156.143 37.6451C155.99 37.7982 155.869 37.9801 155.786 38.1801C155.703 38.3802 155.661 38.5945 155.661 38.811Z"
					fill="url(#paint15_linear_1288_1000)"
				/>
				<path
					d="M262.178 48.579L302.609 137.902C302.754 138.222 302.832 138.567 302.838 138.918C302.844 139.269 302.778 139.618 302.644 139.942L298.944 148.934C298.696 149.535 298.575 150.18 298.586 150.829C298.597 151.479 298.741 152.119 299.009 152.711L315.104 188.27C315.372 188.862 315.758 189.392 316.239 189.829C316.719 190.266 317.284 190.6 317.899 190.811L326.886 193.889C327.285 194.025 327.652 194.242 327.965 194.526C328.277 194.81 328.528 195.155 328.702 195.539L346.26 234.33C346.498 234.856 346.621 235.427 346.621 236.004V308.576L327.797 337.282H300.971L230.383 203.447V46.458H258.89C259.584 46.458 260.263 46.6581 260.846 47.0342C261.429 47.4104 261.892 47.9467 262.178 48.579Z"
					fill="#8888A5"
				/>
				<path
					d="M336.971 307.164V238.693L320.736 215.399L297.219 205.87L278.03 159.282L286.853 135.635L272.305 99.988L252.261 84.103H234.614V197.403L309.79 329.403H321.79L336.971 307.164Z"
					fill="#AFAFC6"
				/>
				<path
					opacity="0.5"
					d="M327.447 196.314L321.464 213.329C321.369 213.596 321.221 213.84 321.03 214.049C320.838 214.257 320.606 214.424 320.348 214.541C320.09 214.657 319.812 214.72 319.529 214.726C319.246 214.732 318.965 214.681 318.702 214.575L300.444 207.175C300.109 207.039 299.815 206.82 299.59 206.537C299.366 206.254 299.218 205.918 299.161 205.561C299.105 205.204 299.142 204.839 299.269 204.5C299.395 204.162 299.607 203.862 299.884 203.63L315.043 190.903C315.319 190.671 315.651 190.515 316.006 190.449C316.361 190.382 316.726 190.409 317.068 190.525L326.15 193.636C326.412 193.726 326.653 193.866 326.86 194.05C327.068 194.233 327.236 194.456 327.357 194.705C327.477 194.954 327.547 195.224 327.563 195.5C327.578 195.777 327.539 196.053 327.447 196.314Z"
					fill="#AFAFC6"
				/>
				<g css="mix-blend-mode:multiply" opacity="0.1">
					<path
						d="M301.245 138.546L288.137 135.894C287.779 135.822 287.407 135.88 287.088 136.057C286.768 136.235 286.523 136.52 286.395 136.862L279.27 155.962C279.165 156.242 279.145 156.548 279.211 156.839C279.278 157.131 279.428 157.398 279.644 157.605C279.86 157.812 280.132 157.952 280.427 158.007C280.721 158.062 281.025 158.029 281.301 157.913L297.573 151.085C297.947 150.928 298.244 150.63 298.398 150.255L302.36 140.638C302.447 140.429 302.486 140.203 302.475 139.976C302.464 139.75 302.403 139.529 302.297 139.329C302.19 139.128 302.041 138.955 301.859 138.819C301.677 138.684 301.467 138.591 301.245 138.546Z"
						fill="#8888A5"
					/>
				</g>
				<path
					opacity="0.5"
					d="M279.701 89.067L273.234 98.617C273.115 98.792 272.962 98.9407 272.783 99.0537C272.605 99.1667 272.405 99.2415 272.196 99.2736C271.987 99.3057 271.773 99.2944 271.569 99.2403C271.365 99.1861 271.173 99.0904 271.008 98.959L253.032 84.713C252.803 84.5314 252.63 84.2881 252.534 84.0118C252.438 83.7355 252.423 83.4376 252.491 83.153L260.178 50.832C260.252 50.5202 260.422 50.2393 260.663 50.0288C260.905 49.8182 261.206 49.6886 261.525 49.6579C261.844 49.6273 262.165 49.6972 262.442 49.8579C262.72 50.0186 262.94 50.262 263.072 50.554L279.828 87.572C279.936 87.8113 279.981 88.0743 279.959 88.336C279.937 88.5977 279.848 88.8494 279.701 89.067Z"
					fill="#AFAFC6"
				/>
				<path
					d="M256.093 127.07L244.218 135.196C243.724 135.534 243.147 135.73 242.55 135.765C241.952 135.799 241.357 135.669 240.828 135.39C240.299 135.111 239.856 134.693 239.547 134.181C239.238 133.668 239.074 133.081 239.074 132.483V116.231C239.074 115.633 239.238 115.046 239.547 114.533C239.856 114.021 240.299 113.603 240.828 113.324C241.357 113.045 241.952 112.915 242.55 112.949C243.147 112.984 243.724 113.18 244.218 113.518L256.093 121.644C256.534 121.946 256.895 122.351 257.144 122.823C257.393 123.296 257.523 123.823 257.523 124.357C257.523 124.891 257.393 125.418 257.144 125.891C256.895 126.363 256.534 126.768 256.093 127.07Z"
					fill="#8888A5"
				/>
				<path
					d="M254.18 125.823L243.721 132.98C243.454 133.163 243.142 133.269 242.82 133.287C242.497 133.306 242.175 133.236 241.889 133.085C241.603 132.934 241.364 132.708 241.197 132.431C241.03 132.155 240.942 131.837 240.942 131.514V117.203C240.942 116.88 241.03 116.562 241.197 116.286C241.364 116.009 241.603 115.783 241.889 115.632C242.175 115.481 242.497 115.411 242.82 115.43C243.142 115.448 243.454 115.554 243.721 115.737L254.18 122.894C254.418 123.057 254.612 123.276 254.747 123.531C254.881 123.786 254.951 124.07 254.951 124.359C254.951 124.647 254.881 124.931 254.747 125.186C254.612 125.441 254.418 125.66 254.18 125.823Z"
					fill="#1A1A26"
				/>
				<path
					d="M256.093 166.28L244.218 174.406C243.724 174.744 243.147 174.941 242.55 174.975C241.953 175.01 241.357 174.881 240.828 174.601C240.299 174.322 239.855 173.904 239.546 173.392C239.237 172.879 239.074 172.292 239.074 171.694V155.442C239.074 154.844 239.237 154.256 239.546 153.744C239.855 153.231 240.298 152.813 240.827 152.534C241.357 152.255 241.952 152.125 242.55 152.16C243.147 152.194 243.724 152.391 244.218 152.729L256.093 160.855C256.534 161.157 256.895 161.562 257.144 162.034C257.393 162.507 257.523 163.033 257.523 163.568C257.523 164.102 257.393 164.628 257.144 165.101C256.895 165.573 256.534 165.978 256.093 166.28Z"
					fill="#8888A5"
				/>
				<path
					d="M254.18 165.034L243.721 172.191C243.454 172.374 243.142 172.48 242.819 172.498C242.496 172.517 242.175 172.447 241.889 172.296C241.603 172.145 241.363 171.919 241.197 171.642C241.03 171.365 240.942 171.047 240.942 170.724V156.411C240.942 156.088 241.03 155.77 241.197 155.494C241.364 155.217 241.603 154.991 241.889 154.84C242.175 154.689 242.497 154.619 242.82 154.638C243.142 154.656 243.454 154.762 243.721 154.945L254.18 162.102C254.418 162.265 254.613 162.484 254.748 162.739C254.883 162.995 254.953 163.279 254.953 163.568C254.953 163.857 254.883 164.141 254.748 164.397C254.613 164.652 254.418 164.871 254.18 165.034Z"
					fill="#1A1A26"
				/>
				<path
					d="M240.505 141.25L252.38 133.124C252.873 132.786 253.45 132.59 254.047 132.555C254.644 132.521 255.24 132.65 255.769 132.929C256.298 133.208 256.741 133.627 257.05 134.139C257.359 134.651 257.523 135.238 257.523 135.836V152.088C257.523 152.686 257.359 153.273 257.05 153.786C256.741 154.298 256.298 154.716 255.769 154.995C255.24 155.275 254.645 155.404 254.047 155.37C253.45 155.335 252.873 155.139 252.38 154.801L240.505 146.675C240.064 146.373 239.703 145.968 239.454 145.496C239.204 145.023 239.074 144.497 239.074 143.962C239.074 143.428 239.204 142.902 239.454 142.429C239.703 141.957 240.064 141.552 240.505 141.25Z"
					fill="#8888A5"
				/>
				<path
					d="M242.417 142.496L252.875 135.339C253.142 135.156 253.454 135.05 253.777 135.032C254.1 135.013 254.422 135.083 254.708 135.234C254.994 135.385 255.233 135.611 255.4 135.888C255.567 136.165 255.656 136.483 255.656 136.806V151.119C255.656 151.443 255.568 151.76 255.401 152.037C255.234 152.314 254.994 152.54 254.708 152.691C254.422 152.842 254.1 152.912 253.777 152.893C253.454 152.874 253.142 152.768 252.875 152.585L242.417 145.428C242.179 145.265 241.984 145.046 241.849 144.791C241.715 144.535 241.644 144.251 241.644 143.962C241.644 143.673 241.715 143.389 241.849 143.133C241.984 142.878 242.179 142.659 242.417 142.496Z"
					fill="#1A1A26"
				/>
				<path
					d="M242.736 38.811V93.273C242.736 93.5113 242.684 93.7468 242.584 93.963L236.693 106.718C236.593 106.934 236.541 107.17 236.541 107.408V187.182C236.541 187.486 236.457 187.783 236.298 188.042C236.139 188.301 235.912 188.511 235.641 188.649C235.371 188.787 235.067 188.847 234.765 188.823C234.462 188.8 234.171 188.692 233.926 188.514L224.269 181.497C224.059 181.344 223.888 181.144 223.77 180.912C223.652 180.68 223.59 180.424 223.59 180.164V51.709C223.59 51.429 223.662 51.1537 223.798 50.909L230.971 38.009C231.113 37.7522 231.322 37.5383 231.575 37.3893C231.828 37.2404 232.116 37.1619 232.41 37.162H241.089C241.305 37.162 241.52 37.2046 241.719 37.2875C241.919 37.3704 242.101 37.4919 242.254 37.6451C242.407 37.7982 242.528 37.9801 242.611 38.1801C242.694 38.3802 242.736 38.5945 242.736 38.811Z"
					fill="url(#paint16_linear_1288_1000)"
				/>
				<path
					d="M70.8274 290.024C73.6419 290.024 75.9234 287.742 75.9234 284.928C75.9234 282.114 73.6419 279.832 70.8274 279.832C68.013 279.832 65.7314 282.114 65.7314 284.928C65.7314 287.742 68.013 290.024 70.8274 290.024Z"
					fill="#8888A5"
				/>
				<path
					d="M70.8273 288.436C72.7648 288.436 74.3353 286.865 74.3353 284.928C74.3353 282.991 72.7648 281.42 70.8273 281.42C68.8899 281.42 67.3193 282.991 67.3193 284.928C67.3193 286.865 68.8899 288.436 70.8273 288.436Z"
					fill="#1A1A26"
				/>
				<path
					d="M70.8274 272.377C73.6419 272.377 75.9234 270.095 75.9234 267.281C75.9234 264.467 73.6419 262.185 70.8274 262.185C68.013 262.185 65.7314 264.467 65.7314 267.281C65.7314 270.095 68.013 272.377 70.8274 272.377Z"
					fill="#8888A5"
				/>
				<path
					d="M70.8273 270.79C72.7648 270.79 74.3353 269.219 74.3353 267.282C74.3353 265.345 72.7648 263.774 70.8273 263.774C68.8899 263.774 67.3193 265.345 67.3193 267.282C67.3193 269.219 68.8899 270.79 70.8273 270.79Z"
					fill="#1A1A26"
				/>
				<path
					d="M319.293 298.357C319.311 283.378 316.519 268.529 311.062 254.58C318.073 248.406 322.428 239.762 323.218 230.454C324.008 221.146 321.172 211.892 315.302 204.625C309.432 197.357 300.982 192.638 291.715 191.453C282.449 190.268 273.083 192.707 265.572 198.263C251.322 188.818 235.219 182.523 218.34 179.801C201.462 177.078 184.196 177.991 167.699 182.477C151.201 186.963 135.851 194.919 122.675 205.814C109.5 216.709 98.8015 230.291 91.2963 245.652C83.7911 261.013 79.6518 277.8 79.1552 294.889C78.6586 311.979 81.816 328.977 88.4164 344.749C95.0169 360.52 104.908 374.7 117.429 386.342C129.95 397.983 144.812 406.817 161.021 412.254V428.576H151.832L115.144 397.164L99.9442 404.929V415.87L140.474 448.827H154.297L159.835 492.256L169.186 500.49H229.198L238.548 492.256L244.087 448.827H257.91L298.44 415.87V404.929L283.24 397.164L246.552 428.576H237.363V412.254C261.231 404.246 281.98 388.944 296.681 368.507C311.381 348.07 319.291 323.532 319.293 298.357Z"
					fill="url(#paint17_linear_1288_1000)"
				/>
				<path
					d="M262.146 221.645L287.493 253.012L311.062 254.58L311.378 254.61C318.348 248.393 322.666 239.737 323.438 230.429C324.21 221.12 321.379 211.871 315.528 204.59C309.678 197.309 301.255 192.553 291.998 191.304C282.742 190.054 273.359 192.407 265.788 197.876L265.573 198.263L262.146 221.645Z"
					fill="url(#paint18_linear_1288_1000)"
				/>
				<path
					d="M257.538 444.576L294.244 413.517V408.34L283.361 402.717L249.538 430.929H228.861L228.194 444.576H257.538Z"
					fill="url(#paint19_linear_1288_1000)"
				/>
				<path
					d="M312.734 227.403C312.735 221.109 310.418 215.035 306.227 210.339C302.036 205.644 296.263 202.655 290.009 201.944C283.755 201.233 277.458 202.848 272.319 206.483C267.181 210.117 263.559 215.516 262.146 221.649C250.72 212.276 237.346 205.573 222.999 202.029C208.652 198.486 193.695 198.19 179.219 201.165C164.743 204.139 151.114 210.309 139.327 219.223C127.54 228.137 117.892 239.571 111.088 252.689C104.284 265.808 100.495 280.28 99.998 295.05C99.501 309.82 102.308 324.515 108.215 338.061C114.122 351.607 122.98 363.664 134.141 373.35C145.302 383.036 158.485 390.108 172.728 394.049L174.528 430.93L175.195 444.577L176.613 473.566L182.74 479.4H215.646L221.773 473.566L223.19 444.577L223.858 430.93L225.658 394.049C239.868 390.11 253.022 383.056 264.165 373.399C275.309 363.743 284.162 351.726 290.082 338.221C296.002 324.716 298.84 310.063 298.391 295.324C297.942 280.586 294.216 266.132 287.484 253.013C294.213 252.917 300.634 250.176 305.359 245.384C310.084 240.592 312.733 234.133 312.734 227.403Z"
					fill="url(#paint20_linear_1288_1000)"
				/>
				<path
					d="M148.861 430.929L115.038 402.717L104.156 408.341V413.518L140.862 444.577H170.205L169.538 430.93L148.861 430.929Z"
					fill="url(#paint21_linear_1288_1000)"
				/>
				<path
					d="M279.961 298.375C279.962 283.135 275.65 268.206 267.525 255.312C259.4 242.419 247.793 232.087 234.045 225.511C220.296 218.935 204.968 216.383 189.831 218.149C174.693 219.916 160.365 225.929 148.5 235.495C136.636 245.06 127.72 257.787 122.782 272.205C117.844 286.623 117.087 302.144 120.596 316.974C124.106 331.804 131.741 345.339 142.617 356.015C153.493 366.69 167.168 374.07 182.061 377.303L184.5 454.565L190.576 458.929H207.826L213.902 454.565L216.341 377.303C234.362 373.385 250.5 363.419 262.073 349.06C273.646 334.702 279.959 316.817 279.961 298.375Z"
					fill="url(#paint22_linear_1288_1000)"
				/>
				<path
					d="M199.197 372.709C240.251 372.709 273.531 339.428 273.531 298.375C273.531 257.321 240.251 224.041 199.197 224.041C158.144 224.041 124.863 257.321 124.863 298.375C124.863 339.428 158.144 372.709 199.197 372.709Z"
					fill="#1A1A26"
				/>
				<path
					d="M287.111 241.92C295.117 241.92 301.607 235.43 301.607 227.424C301.607 219.418 295.117 212.928 287.111 212.928C279.105 212.928 272.615 219.418 272.615 227.424C272.615 235.43 279.105 241.92 287.111 241.92Z"
					fill="#1A1A26"
				/>
				<path
					d="M287.112 227.424L199.197 298.375"
					stroke="#1A1A26"
					strokeWidth="18"
					strokeMiterlimit="10"
				/>
				<path
					d="M204.98 452.576H193.415L189.341 448.595L185.433 280.137H212.963L209.055 448.595L204.98 452.576Z"
					fill="#1A1A26"
				/>
				<path d="M199.198 218.345L195.257 225.171H203.139L199.198 218.345Z" fill="#1A1A26" />
				<path d="M199.197 178.339V224.12" stroke="#707070" strokeWidth="7" strokeMiterlimit="10" />
				<path d="M199.197 159.635V224.929" stroke="#1A1A26" strokeWidth="3" strokeMiterlimit="10" />
				<path
					d="M274.618 238.569L244.431 242.311L262.765 266.583L274.618 238.569Z"
					fill="#1A1A26"
				/>
				<path d="M199.198 182.721L203.58 175.131H194.814L199.198 182.721Z" fill="#1A1A26" />
				<path
					d="M199.197 148.948V173.87"
					stroke="#FBB03B"
					strokeWidth="12"
					strokeMiterlimit="10"
					strokeLinecap="round"
				/>
				<path
					d="M199.197 148.948V173.87"
					stroke="#FFE19F"
					strokeWidth="9"
					strokeMiterlimit="10"
					strokeLinecap="round"
				/>
				<path
					d="M199.197 366.623V440.811"
					stroke="#FBB03B"
					strokeWidth="9"
					strokeMiterlimit="10"
					strokeLinecap="round"
				/>
				<path
					d="M199.197 344.174C224.492 344.174 244.996 323.669 244.996 298.375C244.996 273.081 224.492 252.576 199.197 252.576C173.903 252.576 153.398 273.081 153.398 298.375C153.398 323.669 173.903 344.174 199.197 344.174Z"
					fill="#FBB03B"
					stroke="#FBB03B"
					strokeWidth="6"
					strokeMiterlimit="10"
				/>
				<path
					d="M287.112 235.979C291.837 235.979 295.667 232.149 295.667 227.424C295.667 222.699 291.837 218.869 287.112 218.869C282.387 218.869 278.557 222.699 278.557 227.424C278.557 232.149 282.387 235.979 287.112 235.979Z"
					fill="#FBB03B"
					stroke="#FBB03B"
					strokeWidth="2"
					strokeMiterlimit="10"
				/>
				<path
					d="M287.111 227.424L248.413 258.655"
					stroke="#FBB03B"
					strokeWidth="7"
					strokeMiterlimit="10"
				/>
				<path
					d="M199.197 361.622C234.128 361.622 262.444 333.305 262.444 298.375C262.444 263.445 234.128 235.128 199.197 235.128C164.267 235.128 135.95 263.445 135.95 298.375C135.95 333.305 164.267 361.622 199.197 361.622Z"
					stroke="#FBB03B"
					strokeWidth="7"
					strokeMiterlimit="10"
				/>
				<path
					d="M199.197 341.915C223.243 341.915 242.736 322.422 242.736 298.376C242.736 274.33 223.243 254.837 199.197 254.837C175.151 254.837 155.658 274.33 155.658 298.376C155.658 322.422 175.151 341.915 199.197 341.915Z"
					fill="#FFE19F"
				/>
				<path
					d="M199.197 361.622C234.128 361.622 262.444 333.305 262.444 298.375C262.444 263.445 234.128 235.128 199.197 235.128C164.267 235.128 135.95 263.445 135.95 298.375C135.95 333.305 164.267 361.622 199.197 361.622Z"
					stroke="#FFE19F"
					strokeWidth="5"
					strokeMiterlimit="10"
				/>
				<path
					d="M287.111 227.424L248.413 258.655"
					stroke="#FFE19F"
					strokeWidth="5"
					strokeMiterlimit="10"
				/>
				<path
					d="M287.112 235.979C291.837 235.979 295.667 232.149 295.667 227.424C295.667 222.699 291.837 218.869 287.112 218.869C282.387 218.869 278.557 222.699 278.557 227.424C278.557 232.149 282.387 235.979 287.112 235.979Z"
					fill="#FFE19F"
				/>
				<path
					d="M199.197 362.623V440.811"
					stroke="#FFE19F"
					strokeWidth="5"
					strokeMiterlimit="10"
					strokeLinecap="round"
				/>
				<path
					css="mix-blend-mode:multiply"
					opacity="0.1"
					d="M176.618 473.565L159.841 492.257L169.192 500.491L182.745 479.4L176.618 473.565Z"
					fill="url(#paint23_linear_1288_1000)"
				/>
				<path
					css="mix-blend-mode:multiply"
					opacity="0.2"
					d="M182.744 479.403L169.191 500.494H229.203L215.65 479.403H182.744Z"
					fill="url(#paint24_linear_1288_1000)"
				/>
				<path
					css="mix-blend-mode:multiply"
					opacity="0.1"
					d="M215.65 479.403L221.777 473.569L238.554 492.261L229.204 500.495L215.65 479.403Z"
					fill="url(#paint25_linear_1288_1000)"
				/>
				<path
					css="mix-blend-mode:multiply"
					opacity="0.2"
					d="M228.194 444.576L244.094 448.827H257.917L257.54 444.576H228.194Z"
					fill="url(#paint26_linear_1288_1000)"
				/>
				<path
					css="mix-blend-mode:multiply"
					opacity="0.2"
					d="M140.479 448.827L140.857 444.576H170.199L154.3 448.827H140.479Z"
					fill="url(#paint27_linear_1288_1000)"
				/>
				<path
					d="M115.693 413.282L130.733 426.223"
					stroke="#707070"
					strokeWidth="9"
					strokeMiterlimit="10"
					strokeLinecap="round"
				/>
				<path
					d="M115.693 413.282L130.733 426.223"
					stroke="#1A1A26"
					strokeWidth="5"
					strokeMiterlimit="10"
					strokeLinecap="round"
				/>
				<g css="mix-blend-mode:multiply" opacity="0.4">
					<path
						d="M282.701 413.282L267.661 426.223"
						stroke="#707070"
						strokeWidth="9"
						strokeMiterlimit="10"
						strokeLinecap="round"
					/>
				</g>
				<path
					d="M282.701 413.282L267.661 426.223"
					stroke="#1A1A26"
					strokeWidth="5"
					strokeMiterlimit="10"
					strokeLinecap="round"
				/>
				<path
					css="mix-blend-mode:screen"
					opacity="0.2"
					d="M115.033 402.717L115.145 397.164L99.9453 404.929L104.145 408.341L115.033 402.717Z"
					fill="url(#paint28_linear_1288_1000)"
				/>
				<path
					css="mix-blend-mode:screen"
					opacity="0.2"
					d="M283.361 402.717L283.249 397.164L298.449 404.929L294.249 408.341L283.361 402.717Z"
					fill="url(#paint29_linear_1288_1000)"
				/>
			</Wrapper>
		</Svg>
	)
}

export default (forwardRef(Blaster): AbstractComponent<Props, Element>)

const Svg = styled.svg``
