import { useEffect, useState } from 'react'

const supported = typeof window !== 'undefined' && !!window.speechSynthesis

/**
 * A react hook for the browser's SpeechSynthesis API
 * Copied from https://github.com/MikeyParton/react-speech-kit and modified to use onboundary event
 * @param {{
 *		onEnd: Function, Callback called when SpeechSynthesis finishes reading the text or is cancelled,
 *		onBoundary: Function, Callback fired when the spoken utterance reaches a word or sentence boundary,
 *  }} props
 * @returns {{
 *	 supported: boolean, will be true if the browser supports SpeechSynthesis,
 *	 speak: Function, call to make the browser read some text,
 *	 speaking: boolean, will be true when SpeechSynthesis is actively speaking,
 *	 cancel: Function, call to make SpeechSynthesis stop reading,
 *	 voices: Array, an array of available voices which can be passed to the speak function,
 *	}}
 */
const useSpeechSynthesis = (props: Object = {}): Object => {
	const { onEnd = () => {} } = props
	const { onBoundary = (event: any) => {} } = props
	const [voices, setVoices] = useState([])
	const [speaking, setSpeaking] = useState(false)

	const handleEnd = () => {
		setSpeaking(false)
		onEnd()
	}

	useEffect(() => {
		if (!supported) {
			return
		}

		// Firefox seems to have voices upfront and never calls the
		// voiceschanged event
		let voiceOptions = window.speechSynthesis.getVoices()
		if (voiceOptions.length > 0) {
			setVoices(voiceOptions)
			return
		}

		window.speechSynthesis.onvoiceschanged = event => {
			voiceOptions = event.target.getVoices()
			setVoices(voiceOptions)
		}
	}, [])

	const speak = (args = {}) => {
		const { voice = null, text = '', rate = 1, pitch = 1, volume = 1 } = args
		if (!supported) return
		setSpeaking(true)
		// Firefox won't repeat an utterance that has been
		// spoken, so we need to create a new instance each time
		const utterance = new window.SpeechSynthesisUtterance()
		utterance.text = text
		utterance.voice = voice
		utterance.onend = handleEnd
		utterance.onboundary = onBoundary
		utterance.rate = rate
		utterance.pitch = pitch
		utterance.volume = volume
		window.speechSynthesis.speak(utterance)
	}

	const cancel = () => {
		if (!supported) return
		setSpeaking(false)
		window.speechSynthesis.cancel()
	}

	return {
		supported,
		speak,
		speaking,
		cancel,
		voices,
	}
}

export default useSpeechSynthesis
