import React, { useState } from 'react'
import { sendMessage } from '../../../../../../store/stores/webSocket'
import { useDispatch, useSelector } from 'react-redux'
import {
	getInteractiveCodeSocketMessage,
	AVAILABLE_CODES,
	getInteractiveCode,
} from '../../../../../../store/selectors/jrPlusState/engineering'

const BLANK = '--'

export default function InteractiveCode({ componentId }: { componentId: string }): React$Node {
	const dispatch = useDispatch()
	const [value, setValue] = useState(useSelector(state => getInteractiveCode(state, componentId)))
	const handleValueChange = event => {
		if (event.target.value === BLANK) {
			return
		}
		setValue(event.target.value)
		dispatch(
			sendMessage(
				'ENGINEERING_PANEL_INTERACTIVE_ACTION',
				getInteractiveCodeSocketMessage(componentId, event.target.value)
			)
		)
	}

	let availableOptions = AVAILABLE_CODES.map((code: string) => (
		<option value={code} key={code} className="notranslate text-2xl">
			{code}
		</option>
	))
	if (value == null) {
		availableOptions.unshift(
			<option value={BLANK} key={BLANK} className="text-2xl">
				{BLANK}
			</option>
		)
	}

	return (
		<div>
			<select
				className="text-base pointer-events-auto font-roboto text-black ml-1 bg-primary-300 rounded"
				onChange={handleValueChange}
				value={value}
				defaultValue={BLANK}
				key={
					// on ipads the select options will break (visually it will be correct but the wrong element will be selected) if the options change while the element is focused, remount the element when options change
					value ? 'only-values' : 'include-blank'
				}>
				{availableOptions}
			</select>
		</div>
	)
}
