import { someEntry, reduceObject } from '../../../../utility/functions'
import { descriptorSizeMap, optionSelectorSizeMap, mapObjectKeys } from '../../types'
import { MAP_SIZE, ACTION_TYPES, type ActionType } from '../helpers/constants'
import { intersect, shape } from 'svg-intersections'
import type { Map, MapObject, ObjectInfo as ObjectInformation } from '@mission.io/mission-toolkit'
import type { SizeKey } from '../../types'
import type { StudentStatus } from '../../../../store/selectors/jrPlusState/sensor'
import type { Coordinates } from '../../../../types'

const MAP_RADIUS = MAP_SIZE / 2
/**
 * Takes a pair of coordinates for a map and scales them for viewing
 * @param {Array} [x,y] The tuple of values to scale
 * @param {number} size The size of the drawing area (if circle, diameter)
 * @return {Array} returns a scaled [x,y] coordinate pair
 */
export function toViewCoordinates([x, y]: [number, number], mapSize: number): [number, number] {
	const originalMapSize = 300
	const newX = (x * mapSize) / originalMapSize
	const newY = (y * mapSize) / originalMapSize
	return [newX + mapSize / 2, newY + mapSize / 2]
}

export function fromViewCoordinates([x, y]: [number, number], mapSize: number): [number, number] {
	const originalMapSize = 300
	const oldX = ((x - mapSize / 2) * originalMapSize) / mapSize
	const oldY = ((y - mapSize / 2) * originalMapSize) / mapSize
	return [oldX, oldY]
}

/**
 * Given an svg object, the x, y coordinates will be at its top left corner,
 * this changes the coordinates so that the center of the object is the coordinate base.
 * @param  {[number, number]} [x, y]   coordinates
 * @param  {[number, number]} sizes [width, height] of the object being placed
 * @return {[number, number]}      new centered coordinates
 */
export function centerCoordinates(
	[x, y]: [number, number],
	size: [number, number]
): [number, number] {
	return [x - size[0] / 2, y - size[1] / 2]
}

export function scaledObjectSize(
	mapSize: number,
	objectData: string | { width: number, height: number }
): [number, number] {
	const originMapSize = 300
	let sizes = []
	if (typeof objectData === 'string') {
		if (!descriptorSizeMap[objectData]) {
			console.error(
				'Error, that object size type is not included in the descriptor size map in mapTypes.js'
			)
		}
		const size = (mapSize / originMapSize) * descriptorSizeMap[objectData]
		sizes = [size, size]
	} else {
		sizes = [objectData.width, objectData.height]
	}
	return sizes
}

export function getOptionSelectorInnerRadius(objectType: string): number {
	const DEFAULT_INNER_RADIUS = 10

	if (optionSelectorSizeMap[objectType]) {
		return optionSelectorSizeMap[objectType]
	} else if (descriptorSizeMap[objectType]) {
		return descriptorSizeMap[objectType] + 2
	} else {
		return DEFAULT_INNER_RADIUS
	}
}

/**
 * A helper function for looping through the map given
 * @param  {Object}  map assumed to be  map
 * @param  {Function} cb  callback
 */
export function forEachObjectInMap(map: Map, cb: (MapObject, string) => void) {
	mapObjectKeys.forEach((key: string) => {
		map.objects[key].forEach(body => {
			cb(body, key)
		})
	})
}

/**
 * Given 2 points in a circle on a grid where the top-left corner is [0,0],
 * finds the point midway between the two along the circle's circumference.
 * The radius value passed is ideally less than the MAP_RADIUS. That way,
 * the intersection location point returned is scaled towards the center of the circle,
 * instead of exactly at the circle's edge.
 * @param {Coordinates} a
 * @param {Coordinates} b
 * @param {number} radius
 */
export function getMidpointBetweenIntersects(
	a: Coordinates,
	b: Coordinates,
	radius: number
): Coordinates {
	const circlePointA = getPointOnCircle(a, MAP_RADIUS)
	const circlePointB = getPointOnCircle(b, MAP_RADIUS)
	let startFeta = Math.atan2(circlePointA.y, circlePointA.x)
	let endFeta = Math.atan2(circlePointB.y, circlePointB.x)
	const midFeta = Math.atan2(
		Math.sin(startFeta) + Math.sin(endFeta),
		Math.cos(startFeta) + Math.cos(endFeta)
	)
	const x = radius * Math.cos(midFeta)
	const y = radius * Math.sin(midFeta)
	return getPointFromCircle({ x, y }, MAP_RADIUS)
}

/**
 * Changes the point as if the center of the circle is [0,0] instead of the top left corner
 * @param {Coordinates} point
 * @param {number} radius
 */
function getPointOnCircle(point: Coordinates, radius: number): Coordinates {
	const { x, y } = point
	return { x: x - radius, y: radius - y }
}

/**
 * Gets the point as if the top left corner of the circle is [0,0] instead of the center
 * @param {Coordinates} point
 * @param {number} radius
 */
function getPointFromCircle(point: Coordinates, radius: number): Coordinates {
	const { x, y } = point
	return { x: x + radius, y: radius - y }
}

/**
 * Assuming that all the action student info will be a map of student Id's to booleans, This function takes an action,
 * Scan, target, contact, tractor beam, and the state of the object, and returns whether or not the action for that object
 * has been engaged by at least one student.
 * @param {ObjectInformation} state
 * @param {ActionType} action
 * @return boolean
 */
export function isActionActivated(state: ObjectInformation, action: ActionType): boolean {
	const studentInfo = state.actionData[action].students
	if (Object.values(studentInfo).length === 0) return false
	return someEntry(studentInfo, entry => entry === true)
}

/**
 * Returns important data for knowing the placement and sizing of an object element within the svg map element
 * centeredX and centeredY represent where the object will be located on the map
 * viewX and viewY represent where the objects option selector will be located on the map
 * 		If an object intersects with the edge of the map, then viewX and viewY will change in order to remain on the map
 * size is the size of the object.
 * @param {'OTHER'} objectType
 * @param {GeneralMapObject} mapObject
 * @param {?SizeKey} sizeType
 * @param {boolean} shouldCenterCoordinates
 */
export function getMapObjectData(
	objectType: 'OTHER',
	mapObject: MapObject,
	sizeData: ?SizeKey | { width: number, height: number },
	shouldCenterCoordinates?: boolean = true
): { sizes: [number, number], viewX: number, viewY: number, centeredX: number, centeredY: number } {
	let sizes: [number, number] = scaledObjectSize(MAP_SIZE, sizeData || objectType)
	let [viewX, viewY] = toViewCoordinates([mapObject.location.x, -mapObject.location.y], MAP_SIZE)
	const [centeredX, centeredY] = shouldCenterCoordinates
		? centerCoordinates([viewX, viewY], sizes)
		: [viewX, viewY]
	const innerRadius = getOptionSelectorInnerRadius(objectType)
	const mapDimension = MAP_SIZE / 2
	var intersections = intersect(
		shape('circle', { cx: centeredX, cy: centeredY, r: innerRadius }),
		shape('circle', { cx: mapDimension, cy: mapDimension, r: mapDimension })
	)
	if (intersections.points.length >= 2) {
		const point: Coordinates = getMidpointBetweenIntersects(
			intersections.points[0],
			intersections.points[1],
			mapDimension - (getOptionSelectorInnerRadius(objectType) + 10)
		)
		viewX = point.x
		viewY = point.y
	}
	return { sizes, viewX, viewY, centeredX, centeredY }
}

/**
 * Returns a map of action ids to boolean values which show whether or not the
 * current student is actively engaged in that action on that object
 * @param {ObjectInformation} objectInfo
 * @param {string} studentId
 */
export function didStudentActivateObjectForActionType(
	objectInfo: ObjectInformation,
	studentId: string,
	type: ActionType
): boolean {
	return Boolean(objectInfo.actionData[type].students[studentId])
}

/**
 * Given object information and the type of action performed on the object,
 * returns the total number of students engaged in that action
 * @param {ObjectInformation} objectInfo
 * @param {ActionType} type
 */
export function getTotalStudentsEngaged(objectInfo: ObjectInformation, type: ActionType): number {
	const actionTypeMap = objectInfo.actionData[type].students
	return reduceObject(
		actionTypeMap,
		(sum, isActive) => {
			if (isActive) return sum + 1
			return sum
		},
		0
	)
}

export function getDOMIdFromMapObjectId(mapObjectId: string): string {
	return `map-${mapObjectId}`
}

export const isCompleted = (info: ObjectInformation, actionType: ActionType): boolean => {
	return info.actionData[actionType].complete
}

/**
 * Determines which action types are unavailable for a student to activate for a given map object
 * Follows the following rules:
 * A student
 *  - is only allowed 2 actions: a scan, plus one other action.
 *  - cannot activate 2 actions on the same object
 * An object
 *  - cannot be activated again when a control station is currently activated for that object
 * @param {} mapObjectId
 * @param {*} status
 * @param {*} objectsActivatingStations
 */
export function getLockedActions(
	mapObjectId: string,
	status: StudentStatus,
	objectsActivatingStations: { [mapObjectId: string]: ActionType[] }
): ActionType[] {
	// If this object is connected to an active station, no action types can be activated for this object
	if (objectsActivatingStations[mapObjectId]) {
		return ACTION_TYPES
	}
	// If student is doing 1 action to this object (a scan or a different action) student cannot activate other action types on this object
	if (
		status.scanning === mapObjectId ||
		(status.otherAction && status.otherAction.mapObjectId === mapObjectId)
	) {
		return ACTION_TYPES
	}
	// If student is scanning an object and doing another action (2 actions) student cannot activate other action types for ANY object
	if (status.scanning && status.otherAction) {
		return ACTION_TYPES
	}
	// If student is scanning a different object, cannot scan this object
	else if (status.scanning) {
		return ['SCAN']
	}
	// If student is doing an action to a different object, can ONLY do scan for other objects
	else if (status.otherAction) {
		return ACTION_TYPES.filter(val => val !== 'SCAN')
	}
	return []
}
