import React from 'react'

import { BackgroundCircle, FilledPolygon } from '../helpers'
import type { Props } from '../helpers'

export default function PowerIcon({
	iconOnly,
	transitionPattern = {},
	isOpen = true,
	...props
}: Props): React$Node {
	return (
		<svg
			{...props}
			id="powerPlusPanel"
			data-name="Power Plus Panel"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 70 70">
			<g
				style={{
					...transitionPattern,
					transform: `${isOpen ? 'scale(1)' : 'scale(0)'}`,
					transformOrigin: '50% 50%',
				}}>
				{!iconOnly && <BackgroundCircle cx="35" cy="35" r="35" />}
				<FilledPolygon
					points="35.95 38.82 26.1 38.33 54.17 59.5 37.67 42.51 48.51 43.99 33.73 31.68 41.12 31.68 16.5 11 30.29 27.25 21.92 27.25 35.95 38.82"
					iconOnly={iconOnly}
					fill="#fff"
				/>
			</g>
		</svg>
	)
}
