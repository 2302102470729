import React, { Component } from 'react'
import LineDragLayer from '../../components/LineDragLayer'

import ConnectionEndpoint from './ConnectionEndpoint'

import { useSpring, animated } from '@react-spring/web'
import type { Point } from '../../types'
import type { TransporterLinkItem } from '../../store/stores/transporter'
import type { Vertex } from '../../store/stores/sharedGameStateTypes'

import './ConnectionArea.css'

type Props = {
	lines: Array<[TransporterLinkItem, TransporterLinkItem]>,
	dots: Vertex[],
	onNewConnection: (
		[TransporterLinkItem, TransporterLinkItem],
		{ x: number, y: number },
		{ x: number, y: number }
	) => void,
	removeLine: ([TransporterLinkItem, TransporterLinkItem]) => void,
	showError: boolean,
	resetError: () => void,
}

type State = {
	currentLineStart: ?TransporterLinkItem,
	linkPositions: Point[],
	gotAllLinkPositions: boolean,
}

const initialState: State = {
	currentLineStart: null,
	linkPositions: [],
	gotAllLinkPositions: false,
}

export default class ConnectionArea extends Component<Props, State> {
	state: State = initialState

	shouldComponentUpdate(nextProps: Props, nextState: State): boolean {
		if (nextProps !== this.props || !this.state.gotAllLinkPositions) {
			return true
		}

		const nonUpdateRequiredFields = ['linkPositions']
		for (let stateKey in nextState) {
			if (nextState.hasOwnProperty(stateKey)) {
				if (
					!nonUpdateRequiredFields.includes(stateKey) &&
					nextState[stateKey] !== this.state[stateKey]
				) {
					return true
				}
			}
		}
		return false
	}

	componentDidUpdate(prevProps: Props, prevState: State) {
		if (
			!this.state.gotAllLinkPositions &&
			this.state.linkPositions.length === this.props.dots.length
		) {
			this.setState({ gotAllLinkPositions: true })
		}
		if (!prevProps.showError && this.props.showError) {
			setTimeout(this.props.resetError, 1100)
		}
	}

	render(): React$Node {
		const { linkPositions, currentLineStart } = this.state
		const gridWarn = `grid${this.props.showError ? ' warning' : ''}`
		return (
			<div className="ConnectionArea">
				<div className={gridWarn}>{this.createDotMatrix()}</div>
				<LineDragLayer
					start={currentLineStart ? linkPositions[currentLineStart.index] : null}
					renderLine={([start, end]) => <Line drawn={false} start={start} end={end} />}
				/>
				<svg className="myLines">
					<Lines
						linkPositions={linkPositions}
						lines={this.props.lines}
						removeLine={this.props.removeLine}
					/>
				</svg>
			</div>
		)
	}

	/**
		Because of weird scoping issues, the call to get the Points/dots needs to be in its own function so that they do not
		each have i=12.  But the connections are passed into each call so that they know what color to be.
	 */
	createDotMatrix(): Array<React$Node> {
		let matrix = []
		let connections = this.getConnections()
		this.props.dots.forEach((dot, index) => {
			matrix.push(this.getPointElement(index, dot, connections))
		})
		return matrix
	}

	getPointElement(i: number, dot: Vertex, connections: boolean[]): React$Node {
		return (
			<ConnectionEndpoint
				data={dot}
				addLine={this.addLine}
				beginDrag={this.beginDrag}
				endDrag={this.endDrag}
				canDragItem={Boolean(this.props.dots)}
				identifier={{ index: i }}
				canDropItem={(props, monitor) => {
					const draggedCell = monitor.getItem()
					return draggedCell.index !== i && !connections[i]
				}}
				hasConnection={connections[i]}
				setLinkPosition={position => this.setLinkPosition({ index: i, position: position })}
				key={'dot' + String(i)}
				id={i}
			/>
		)
	}

	/**
		This function is passed into the Endpoint so that it can calculate its position and update the state
	 */
	setLinkPosition: ({ index: number, position: [number, number] }) => void = ({
		index,
		position,
	}: {
		index: number,
		position: [number, number],
	}) => {
		this.setState(prevState => {
			const updatedLinkPositions = [...prevState.linkPositions]
			updatedLinkPositions[index] = position
			return {
				linkPositions: updatedLinkPositions,
			}
		})
	}

	/**
		Returns the correct color from the correctConnections prop
	 */
	getColorPreset(i: number): ?string {
		if (this.props.dots[i]) {
			return this.props.dots[i].color
		}
		return null
	}

	/**
		Gets each connection we have in our lines (aka what dots have a line drawn to them)
		and returns a boolean array of the dots that have a line on them.  If no line, it is undefined,
		which the endpoint accepts as false
	 */
	getConnections(): boolean[] {
		const connections = []
		this.props.lines.forEach(([startCell, endCell]) => {
			connections[startCell.index] = true
			connections[endCell.index] = true
		})
		return connections
	}

	addLine: (
		startCell: TransporterLinkItem,
		endCell: TransporterLinkItem,
		point1: { x: number, y: number },
		point2: { x: number, y: number }
	) => void = (
		startCell: TransporterLinkItem,
		endCell: TransporterLinkItem,
		point1: { x: number, y: number },
		point2: { x: number, y: number }
	) => {
		const orderedCells = getOrderedCells(startCell, endCell)
		this.props.onNewConnection(orderedCells, point1, point2)
	}

	beginDrag: (item: TransporterLinkItem) => void = (item: TransporterLinkItem) => {
		this.setState({
			currentLineStart: item,
		})
	}

	endDrag: () => void = () => {
		this.setState({ currentLineStart: null })
	}
}

function getOrderedCells(
	cellA: TransporterLinkItem,
	cellB: TransporterLinkItem
): [TransporterLinkItem, TransporterLinkItem] {
	if (cellA.index < cellB.index) {
		return [cellA, cellB]
	}

	return [cellB, cellA]
}

type LineProps = {
	drawn: boolean, // called from LineDragLayer == false, Lines function == true
	start: Point,
	end: Point,
	removeLine?: () => void,
}

/**
	Gives a functional line with a useSpring on it to make the line dim out if it was successfully drawn
 */
function Line({ drawn, start, end, removeLine }: LineProps) {
	const props2 = useSpring({
		config: { duration: 3000 },
		from: { opacity: 1 },
		opacity: drawn ? 0 : 1,
		onRest: () => {
			if (removeLine) {
				removeLine()
			}
		},
	})

	return <animated.line style={props2} x1={start[0]} y1={start[1]} x2={end[0]} y2={end[1]} />
}

/**
	How we map each line in our array to a useSpring that dissapears
 */
function Lines({
	linkPositions,
	lines,
	removeLine,
}: {
	linkPositions: Point[],
	lines: Array<[TransporterLinkItem, TransporterLinkItem]>,
	removeLine: ([TransporterLinkItem, TransporterLinkItem]) => void,
}) {
	return lines.map(([start, end]) => {
		const startPoint = linkPositions[start.index]
		const endPoint = linkPositions[end.index]
		if (!startPoint || !endPoint) return null

		return (
			<Line
				drawn={true}
				start={linkPositions[start.index]}
				end={linkPositions[end.index]}
				key={`${start.index}-${end.index}`}
				removeLine={() => {
					removeLine([start, end])
				}}
			/>
		)
	})
}
