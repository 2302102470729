import React from 'react'

import type { Props } from '../helpers'

type RestProps = {
	width?: ?string,
	height?: ?string,
}

export function Hourglass({ transitionPattern = {}, isOpen = true, ...props }: Props): React$Node {
	return (
		<svg
			{...(props: RestProps)}
			width={props?.width ? props.width : '27'}
			height={props?.height ? props.height : '36'}
			id="Hourglass_1"
			data-name="Hourglass_1"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 27 36">
			<g
				style={{
					...transitionPattern,
					transform: `${isOpen ? 'scale(1)' : 'scale(0)'}`,
					transformOrigin: '50% 50%',
				}}>
				<defs>
					<clipPath id="clip-Hourglass">
						<rect width="27" height="36" />
					</clipPath>
				</defs>
				<g id="Hourglass" clipPath="url(#clip-Hourglass)">
					<rect width="27" height="36" fill="rgba(255,255,255,0)" />
					<path
						id="Icon_awesome-hourglass-half"
						data-name="Icon awesome-hourglass-half"
						d="M25.313,0H1.688A1.687,1.687,0,0,0,0,1.688V2.813A1.687,1.687,0,0,0,1.688,4.5c0,6.4,3.587,11.794,8.5,13.5-4.91,1.706-8.5,7.1-8.5,13.5A1.687,1.687,0,0,0,0,33.188v1.125A1.687,1.687,0,0,0,1.688,36H25.313A1.687,1.687,0,0,0,27,34.313V33.188A1.687,1.687,0,0,0,25.313,31.5c0-6.4-3.587-11.794-8.5-13.5,4.91-1.706,8.5-7.1,8.5-13.5A1.687,1.687,0,0,0,27,2.813V1.688A1.687,1.687,0,0,0,25.313,0ZM20.034,27H6.967c1.2-3.29,3.663-5.625,6.533-5.625S18.834,23.709,20.034,27Zm0-18H6.966a13.192,13.192,0,0,1-.779-4.5H20.813A13.193,13.193,0,0,1,20.035,9Z"
						fill="#13163d"
					/>
				</g>
			</g>
		</svg>
	)
}
