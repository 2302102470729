// @flow
import createReducer, { type Reducer } from '../createReducer'

import {
	SET_PLAYER_VOLUME_BY_KEY,
	SET_OVERALL_PLAYER_VOLUME,
	SET_IS_TEXT_TO_SPEECH_ENABLED,
	SET_TEXT_TO_SPEECH_GENDER,
	SET_IS_DEBUGGING,
	SET_TRANSLATION_LANGUAGE,
} from '../actionTypes'

import {
	DEFAULT_VOLUME_RATIOS,
	DEFAULT_VOLUME,
	typeof DEFAULT_VOLUME_RATIOS as VolumeRatiosObject,
} from '@mission.io/mission-toolkit/constants'

import type { ReduxStore } from '../rootReducer'
import { mapValues } from 'lodash'
import { GENDER } from '../../constants/speechConstants'

type SetPlayVolumeByKeyAction = {
	type: 'SET_PLAYER_VOLUME_BY_KEY',
	payload: {
		volumeKey: string,
		volumeValue: number,
	},
}

type SetOverallVolumePlayerAction = { type: 'SET_OVERALL_PLAYER_VOLUME', payload: number }

type setIsTextToSpeechEnabledAction = { type: 'SET_IS_TEXT_TO_SPEECH_ENABLED', payload: boolean }

type setTextToSpeechGenderAction = { type: 'SET_TEXT_TO_SPEECH_GENDER', payload: string }

type SetIsDebuggingAction = { type: 'SET_IS_DEBUGGING', payload: { isDebugging: boolean } }

type setTranslationLanguageAction = { type: 'SET_TRANSLATION_LANGUAGE', payload: string }

// Action Creators

export function setPlayerVolumeByKey(
	volumeValue: number,
	volumeKey: string
): SetPlayVolumeByKeyAction {
	return {
		type: SET_PLAYER_VOLUME_BY_KEY,
		payload: {
			volumeKey,
			volumeValue,
		},
	}
}

export function setOverallPlayerVolume(value: number): SetOverallVolumePlayerAction {
	return { type: SET_OVERALL_PLAYER_VOLUME, payload: value }
}

export function setIsTextToSpeechEnabled(value: boolean): setIsTextToSpeechEnabledAction {
	return { type: SET_IS_TEXT_TO_SPEECH_ENABLED, payload: value }
}

export function setTextToSpeechGender(value: string): setTextToSpeechGenderAction {
	return { type: SET_TEXT_TO_SPEECH_GENDER, payload: value }
}

export function setIsDebugging(isDebugging: boolean): SetIsDebuggingAction {
	return { type: SET_IS_DEBUGGING, payload: { isDebugging } }
}

export function setTranslationLanguage(value: string): setTranslationLanguageAction {
	return { type: SET_TRANSLATION_LANGUAGE, payload: value }
}

// Reducer

export type SettingsStore = {
	playerVolumes: VolumeRatiosObject,
	overallPlayerVolume: number,
	textToSpeech: { isEnabled: boolean, gender: 'Female' | 'Male' },
	translationLanguage: string,
	isDebugging: boolean,
}

/**
 * Gets the initial state for the `settings` redux store
 */
export function getInitialState(): SettingsStore {
	return {
		playerVolumes: {
			...DEFAULT_VOLUME_RATIOS,
			AMBIANCE: DEFAULT_VOLUME_RATIOS.AMBIANCE * 0.5,
			MUSIC: DEFAULT_VOLUME_RATIOS.MUSIC * 0.5,
			EFFECTS: DEFAULT_VOLUME_RATIOS.EFFECTS * 0.5,
		},
		overallPlayerVolume: DEFAULT_VOLUME,
		textToSpeech: {
			isEnabled: false,
			gender: GENDER.FEMALE,
		},
		translationLanguage: (() => {
			let language = 'English'
			try {
				language = localStorage.getItem('languageValue') || language
			} catch (error) {
				console.error('could not get language from local storage', error)
			}
			return language
		})(),
		isDebugging: false,
	}
}

export default (createReducer<SettingsStore>(getInitialState(), {
	[SET_PLAYER_VOLUME_BY_KEY]: (state, { payload }) => {
		return {
			...state,
			playerVolumes: {
				...state.playerVolumes,
				[payload.volumeKey]: payload.volumeValue,
			},
		}
	},
	[SET_OVERALL_PLAYER_VOLUME]: (state, { payload }) => {
		return {
			...state,
			overallPlayerVolume: payload,
		}
	},
	[SET_IS_TEXT_TO_SPEECH_ENABLED]: (state, { payload }) => {
		return {
			...state,
			textToSpeech: {
				...state.textToSpeech,
				isEnabled: payload,
			},
		}
	},
	[SET_TEXT_TO_SPEECH_GENDER]: (state, { payload }) => {
		return {
			...state,
			textToSpeech: {
				...state.textToSpeech,
				gender: payload,
			},
		}
	},
	[SET_IS_DEBUGGING]: (state, { payload: { isDebugging } }) => {
		return {
			...state,
			isDebugging,
		}
	},
	[SET_TRANSLATION_LANGUAGE]: (state, { payload }) => {
		return {
			...state,
			translationLanguage: payload,
		}
	},
}): Reducer<SettingsStore>)

// Selectors

export function getPlayerVolumes(state: ReduxStore): VolumeRatiosObject {
	return state.settings.playerVolumes
}

export function getOverallPlayerVolume(state: ReduxStore): number {
	return state.settings.overallPlayerVolume
}

export function getTextToSpeechSettings(state: ReduxStore): Object {
	return state.settings.textToSpeech
}

export function getTranslationLanguage(state: ReduxStore): string {
	return state.settings.translationLanguage
}

/** Gets configured volumes (as a ratio of the overall player volume)  */
export function getConfiguredPlayerVolumes(state: ReduxStore): VolumeRatiosObject {
	const playerVolumes = getPlayerVolumes(state)
	const overallPlayerVolume = getOverallPlayerVolume(state)
	return mapValues(playerVolumes, volumeRatio => {
		return overallPlayerVolume * volumeRatio
	})
}

export function getIsDebugging(state: ReduxStore): boolean {
	return state.settings.isDebugging
}
