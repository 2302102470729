import React, { type AbstractComponent, forwardRef } from 'react'
import 'styled-components/macro'
import { animated, useSpring } from '@react-spring/web'
import type { BeamProps as Props } from './upgradeLookup'

const SVG_HEIGHT = 167
const DISTANCE_ROPE_OVERLAPS_WITH_PLUNGER = 27
function Plunger(props: Props, ref) {
	const { targetLength, onExtended, onRetracted, springConfig, ...otherProps } = props
	const { grow } = useSpring({
		from: { grow: 0 },
		to: {
			grow: targetLength ? targetLength - SVG_HEIGHT - DISTANCE_ROPE_OVERLAPS_WITH_PLUNGER : 0,
		},
		config: springConfig,
		onRest: targetLength ? onExtended : onRetracted,
	})
	return (
		<svg {...otherProps} width="50" height="167" viewBox={`0 0 50 ${SVG_HEIGHT}`} fill="none">
			<animated.g id="plunger" transform={grow.to(i => `translate(0 ${-i})`)}>
				<path
					d="M24.707 110.768C23.8964 110.768 23.0941 110.605 22.3473 110.29C21.6004 109.975 20.9241 109.514 20.3583 108.934C19.7924 108.353 19.3484 107.666 19.0525 106.911C18.7565 106.156 18.6146 105.35 18.635 104.54L20.935 13.528H28.489L30.789 104.54C30.8096 105.351 30.6674 106.158 30.3709 106.914C30.0744 107.669 29.6295 108.357 29.0625 108.938C28.4956 109.518 27.8181 109.979 27.07 110.294C26.322 110.608 25.5185 110.769 24.707 110.768Z"
					fill="#C6A293"
				/>
				<g css="mix-blend-mode:multiply" opacity="0.75">
					<path
						d="M18.635 104.54C18.6078 105.615 18.8667 106.679 19.3852 107.621C19.9038 108.564 20.6634 109.352 21.5864 109.905C22.5094 110.457 23.5626 110.755 24.6384 110.767C25.7141 110.779 26.7738 110.506 27.709 109.974C20.86 110.951 21.035 101.752 21.035 101.752V30.752L22.299 13.526H20.927L18.635 104.54Z"
						fill="#C6A293"
					/>
				</g>
				<g css="mix-blend-mode:multiply" opacity="0.3">
					<path
						d="M23.171 28.8201C23.171 28.8201 22.248 46.0461 23.171 56.6721C24.094 67.2981 24.738 84.0411 24.416 90.3191C24.094 96.5971 27.709 104.326 27.709 104.326"
						stroke="#C6A293"
						strokeWidth="1.314"
						strokeMiterlimit="10"
						strokeLinecap="round"
					/>
				</g>
				<g css="mix-blend-mode:multiply" opacity="0.3">
					<path
						d="M26 27C26 27 27.5305 39.7553 27.7856 47.3429C28.0407 54.9306 27.7856 64.6191 27.7856 64.6191L29 73"
						stroke="#C6A293"
						strokeWidth="1.314"
						strokeMiterlimit="10"
						strokeLinecap="round"
					/>
				</g>
				<g css="mix-blend-mode:multiply" opacity="0.3">
					<path
						d="M23.1709 98.6589C23.1709 98.6589 23.3709 109.828 29.0429 107.946"
						stroke="#C6A293"
						strokeWidth="1.314"
						strokeMiterlimit="10"
						strokeLinecap="round"
					/>
				</g>
				<g css="mix-blend-mode:multiply" opacity="0.3">
					<path
						d="M25.463 61.478C26.2489 61.478 26.886 57.2972 26.886 52.14C26.886 46.9828 26.2489 42.802 25.463 42.802C24.6771 42.802 24.04 46.9828 24.04 52.14C24.04 57.2972 24.6771 61.478 25.463 61.478Z"
						stroke="#C6A293"
						strokeWidth="0.789"
						strokeMiterlimit="10"
					/>
				</g>
				<g css="mix-blend-mode:multiply" opacity="0.3">
					<path
						d="M26 65C26 65 27.156 77.718 27.2 83.514C27.4086 88.0708 28.0423 92.5981 29.093 97.037L29.9 100.901"
						stroke="#C6A293"
						strokeWidth="0.789"
						strokeMiterlimit="10"
						strokeLinecap="round"
					/>
				</g>
				<path
					d="M0 0.0039978V3.18799L1.01501 4.035C1.31083 4.26876 1.55122 4.5651 1.71893 4.90277C1.88664 5.24044 1.97754 5.61106 1.98505 5.98801V12.263C1.98505 20.135 7.67603 26.975 15.832 29.906C16.5671 30.1736 17.2016 30.6615 17.649 31.3031C18.0964 31.9447 18.335 32.7088 18.332 33.491V38.891C18.3319 38.9988 18.353 39.1057 18.3942 39.2053C18.4354 39.305 18.4959 39.3956 18.5722 39.4718C18.6485 39.5481 18.739 39.6086 18.8387 39.6498C18.9384 39.691 19.0452 39.7121 19.153 39.712H30.109C30.3268 39.712 30.5356 39.6255 30.6896 39.4715C30.8435 39.3176 30.9301 39.1087 30.9301 38.891V33.565C30.9265 32.7761 31.1686 32.0057 31.6226 31.3607C32.0767 30.7156 32.7203 30.2278 33.4641 29.965C41.6641 27.105 47.433 20.346 47.433 12.465V5.98401C47.4406 5.60706 47.5314 5.23644 47.6991 4.89877C47.8668 4.56111 48.1072 4.26476 48.403 4.03101L49.418 3.18399V0L0 0.0039978Z"
					fill="#C1402F"
				/>
				<path
					css="mix-blend-mode:multiply"
					opacity="0.6"
					d="M0 0.0039978V3.18799L1.01501 4.035C1.31083 4.26876 1.55122 4.5651 1.71893 4.90277C1.88664 5.24044 1.97754 5.61106 1.98505 5.98801V12.263C1.98505 20.135 7.67603 26.975 15.832 29.906C16.5671 30.1736 17.2016 30.6615 17.649 31.3031C18.0964 31.9447 18.335 32.7088 18.332 33.491V38.891C18.3319 38.9988 18.353 39.1057 18.3942 39.2053C18.4354 39.305 18.4959 39.3956 18.5722 39.4718C18.6485 39.5481 18.739 39.6086 18.8387 39.6498C18.9384 39.691 19.0452 39.7121 19.153 39.712H30.109C30.3268 39.712 30.5356 39.6255 30.6896 39.4715C30.8435 39.3176 30.9301 39.1087 30.9301 38.891V33.565C30.9265 32.7761 31.1686 32.0057 31.6226 31.3607C32.0767 30.7156 32.7203 30.2278 33.4641 29.965C41.6641 27.105 47.433 20.346 47.433 12.465V5.98401C47.4406 5.60706 47.5314 5.23644 47.6991 4.89877C47.8668 4.56111 48.1072 4.26476 48.403 4.03101L49.418 3.18399V0L0 0.0039978Z"
					fill="url(#paint0_radial_1297_2235)"
				/>
				<path
					opacity="0.76"
					d="M46.1501 4.62H2.68213"
					stroke="#721F0F"
					strokeWidth="2.103"
					strokeMiterlimit="10"
					strokeLinecap="round"
				/>
				<g css="mix-blend-mode:screen" opacity="0.5">
					<path
						d="M34.2711 11.92L33.8601 12.237C33.6131 12.4271 33.4203 12.6787 33.3012 12.9667C33.182 13.2547 33.1406 13.569 33.1811 13.878L33.3271 14.996C33.3589 15.2337 33.4383 15.4624 33.5607 15.6686C33.683 15.8748 33.8457 16.0542 34.0391 16.196L34.3681 16.437C34.7032 16.6831 35.114 16.8041 35.5291 16.7789C35.9441 16.7537 36.3372 16.5839 36.6401 16.299L37.2191 15.755C37.4236 15.5629 37.5797 15.3251 37.6748 15.0611C37.7699 14.7971 37.8012 14.5144 37.7661 14.236L37.6311 13.157C37.5811 12.7576 37.3972 12.3869 37.1093 12.1055C36.8214 11.8242 36.4466 11.6488 36.0461 11.608L35.5381 11.556C35.0848 11.5112 34.6315 11.6414 34.2711 11.92Z"
						fill="white"
					/>
				</g>
				<path
					opacity="0.5"
					d="M32.3052 1.73499H41.2132"
					stroke="white"
					strokeWidth="2.628"
					strokeMiterlimit="10"
					strokeLinecap="round"
				/>
				<path
					d="M18.8101 85.967L30.6041 81.764"
					stroke="white"
					strokeWidth="3.154"
					strokeMiterlimit="10"
					strokeLinecap="round"
				/>
				<path
					d="M18.7119 88.0971L30.7889 85.1691"
					stroke="white"
					strokeWidth="3.154"
					strokeMiterlimit="10"
					strokeLinecap="round"
				/>
				<path
					d="M18.54 84.0191L30.875 83.7111"
					stroke="white"
					strokeWidth="3.154"
					strokeMiterlimit="10"
					strokeLinecap="round"
				/>
				<path
					d="M30.205 90.418L18.54 86.941"
					stroke="white"
					strokeWidth="3.154"
					strokeMiterlimit="10"
					strokeLinecap="round"
				/>
				<g css="mix-blend-mode:multiply" opacity="0.5">
					<path
						d="M18.8521 86.239L29.2291 83.318"
						stroke="#928A99"
						strokeWidth="1.051"
						strokeMiterlimit="10"
						strokeLinecap="round"
					/>
				</g>
				<g css="mix-blend-mode:multiply" opacity="0.5">
					<path
						d="M19.793 88.8061L28.952 86.6331"
						stroke="#928A99"
						strokeWidth="1.051"
						strokeMiterlimit="10"
						strokeLinecap="round"
					/>
				</g>
				<path
					d="M21.0349 82.9689C21.0349 82.9689 19.318 75.569 16.206 76.53C13.094 77.491 12.878 80.343 15.24 81.764C17.0358 82.6576 19.0318 83.0726 21.0349 82.9689V82.9689Z"
					stroke="white"
					strokeWidth="2.103"
					strokeMiterlimit="10"
					strokeLinecap="round"
				/>
				<path
					d="M21.837 82.62C21.837 82.62 28.4489 78.869 26.6369 76.159C24.8249 73.449 22.037 74.053 21.344 76.721C20.9982 78.697 21.168 80.7288 21.837 82.62V82.62Z"
					stroke="white"
					strokeWidth="2.103"
					strokeMiterlimit="10"
					strokeLinecap="round"
				/>
			</animated.g>

			<g id="rope">
				<path
					d="M24.7071 166.535C31.9978 166.535 37.9081 160.625 37.9081 153.334C37.9081 146.043 31.9978 140.133 24.7071 140.133C17.4164 140.133 11.5061 146.043 11.5061 153.334C11.5061 160.625 17.4164 166.535 24.7071 166.535Z"
					fill="url(#paint1_linear_1297_2235)"
				/>
				<path
					d="M16.5271 149.148V157.627L19.7921 162.135L29.2281 161.276L32.7331 157.734L33.3771 148.611L29.2281 145.176H20.4981L16.5271 149.148Z"
					stroke="white"
					strokeWidth="3.154"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M28.3629 160.579H21.0519L17.4629 156.99V149.679L21.0519 146.09H28.3629L31.9519 149.679V156.99L28.3629 160.579Z"
					fill="url(#paint2_linear_1297_2235)"
				/>
				<animated.path
					d={grow.to(i => `M25 89V${116.75 - DISTANCE_ROPE_OVERLAPS_WITH_PLUNGER - i}V144.5`)}
					stroke="white"
					strokeWidth="2"
				/>
			</g>
			<defs>
				<radialGradient
					id="paint0_radial_1297_2235"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(49 -4.99999) rotate(152.734) scale(73.1249 46.0716)">
					<stop offset="0.173035" stopColor="#7D7D93" stopOpacity="0" />
					<stop offset="0.38" stopColor="#69687A" stopOpacity="0.208" />
					<stop offset="0.758" stopColor="#343037" stopOpacity="0.784" />
					<stop offset="0.872" stopColor="#201B1E" />
				</radialGradient>
				<linearGradient
					id="paint1_linear_1297_2235"
					x1="11.5061"
					y1="153.334"
					x2="37.9081"
					y2="153.334"
					gradientUnits="userSpaceOnUse">
					<stop stopColor="#5E5E78" />
					<stop offset="0.763" stopColor="#74748B" />
					<stop offset="1" stopColor="#7D7D93" />
				</linearGradient>
				<linearGradient
					id="paint2_linear_1297_2235"
					x1="21.7082"
					y1="173.764"
					x2="20.5056"
					y2="181.907"
					gradientUnits="userSpaceOnUse">
					<stop stopColor="#5E5E78" />
					<stop offset="0.763" stopColor="#74748B" />
					<stop offset="1" stopColor="#7D7D93" />
				</linearGradient>
			</defs>
		</svg>
	)
}
export default (forwardRef(Plunger): AbstractComponent<Props, Element>)
