import React, { useMemo } from 'react'
import {
	getNextDocumentToGrade,
	type CreativeCanvasStationCriterion,
} from '@mission.io/mission-toolkit'
import {
	COLLABORATIVE_CULMINATING_MOMENT,
	CREATIVE_CANVAS_STATION,
} from '@mission.io/mission-toolkit/constants'
import { FaCheck } from 'react-icons/fa'
import { Checkbox } from './Checkbox'
import { useDispatch, useSelector } from 'react-redux'
import {
	getCurrentScreen,
	getIsCreativeCanvasGradingStep,
} from '../../../store/selectors/sharedSelectors'
import { sendMessage } from '../../../store/stores/webSocket'
import { Button } from '../../basics'
import classnames from 'classnames'

// A listing of all criteria expected to be met for the current issue solution
export default function Rubric({
	criteria,
	className,
	hideHeader,
	isTeacher,
	grades,
	documentId,
	currentStepStatus,
}: {
	criteria: Array<CreativeCanvasStationCriterion>,
	documentId: ?string,
	className?: string,
	hideHeader?: boolean,
	isTeacher?: boolean,
	grades: ?{ criteria: { [criterionId: string]: { selectedOption: string } } },
	currentStepStatus: string,
}): React$Node {
	const dispatch = useDispatch()
	const screenId = useSelector(state => {
		const screen = getCurrentScreen(state)
		return screen ? screen._actionId : null
	})
	const isLastDocument = useSelector(state => !getNextDocumentToGrade(state.jrPlusState))
	const isGrading = useSelector(getIsCreativeCanvasGradingStep) ?? false
	const showHiddenCriteria =
		isTeacher ||
		[
			COLLABORATIVE_CULMINATING_MOMENT.SCREEN_STATUS.GRADING_ALL,
			COLLABORATIVE_CULMINATING_MOMENT.SCREEN_STATUS.GRADING_HIDDEN,
		].includes(currentStepStatus)

	const onGradeCriteria = ({ criteriaId, optionId }) => {
		if (!isTeacher) return
		dispatch(
			sendMessage('COLLABORATIVE_CULMINATING_MOMENT_GRADE_DOCUMENT_CRITERION', {
				screenId,
				documentId,
				criteriaId,
				optionId,
			})
		)
	}
	const onFinishDocument = () => {
		if (!isTeacher) return
		dispatch(
			sendMessage('COLLABORATIVE_CULMINATING_MOMENT_FINISH_GRADING_CURRENT_DOCUMENT', {
				documentId,
				screenId,
			})
		)
	}
	const isFullyGraded = useMemo(
		() =>
			criteria.every(criterion => {
				const isYesNo = criterion.gradingOptions.every(
					option => option.text === 'Yes' || option.text === 'No'
				)
				if (
					// We don't need teachers to grade hidden criteria
					(criterion.displayType === CREATIVE_CANVAS_STATION.RUBRIC.CRITERIA.DISPLAY_TYPE.HIDDEN &&
						!showHiddenCriteria) ||
					// Teachers can leave yes/no criteria ungraded
					isYesNo
				) {
					return true
				}

				return grades?.criteria[criterion.id]?.selectedOption
			}),
		[criteria, grades, showHiddenCriteria]
	)

	return (
		<div className="size-full">
			{!hideHeader ? (
				<div className="flex gap-1 pt-2 px-2 w-fit min-w-[50%] bg-primary-600 rounded-t-lg">
					<FaCheck className="text-accent-green self-center" />
					<h4 className="m-0 font-normal">Rubric</h4>
				</div>
			) : null}

			<div
				className={classnames(
					'text-white bg-primary-600 size-full p-2 rounded-b-lg text-xl',
					// Scrollbar styling
					'overflow-y-auto overflow-x-hidden [&::-webkit-scrollbar]:w-2.5 [&::-webkit-scrollbar-track]:rounded-[10px] [&::-webkit-scrollbar-thumb]:bg-white/20 [&::-webkit-scrollbar-thumb]:rounded-[10px]',
					className
				)}>
				<div className={`flex flex-col bg-white/20 min-h-full p-4 space-y-4 rounded`}>
					<ol className="space-y-4 pl-0 mt-0 list-none">
						{criteria
							.filter(({ displayType }) => {
								if (isGrading) {
									return true
								}
								return displayType === CREATIVE_CANVAS_STATION.RUBRIC.CRITERIA.DISPLAY_TYPE.VISIBLE
							})
							.map((criterion, i) => {
								const isHidden =
									criterion.displayType ===
										CREATIVE_CANVAS_STATION.RUBRIC.CRITERIA.DISPLAY_TYPE.HIDDEN &&
									!showHiddenCriteria

								const useCheckbox = criterion.gradingOptions.every(
									option => option.text === 'Yes' || option.text === 'No'
								)
								const selectedOption = criterion.gradingOptions.find(
									option => option.id === grades?.criteria[criterion.id]?.selectedOption
								)

								const isCorrect = selectedOption?.correct

								const disabled = !(isGrading && isTeacher && !isHidden)

								return (
									<li
										key={`${documentId || ''}-${criterion.id}`}
										className={isHidden ? 'blur-sm' : ''}>
										<label
											className={classnames(
												!disabled && 'cursor-pointer hover:bg-white/10',
												isCorrect ? 'border-2 border-accent-green' : 'border-2 border-transparent',
												`flex list-none list-outside rounded overflow-hidden text-sm`
											)}>
											<span
												className={classnames(
													'flex items-center tabular-num px-2 bg-primary-green rounded-l'
												)}>
												{i + 1}
											</span>
											<span
												className={classnames(
													'flex-1 flex items-center justify-between px-2 py-0.5 bg-white/60 text-black min-h-[3em] rounded-r'
												)}>
												{isHidden
													? replaceAlphabetCharactersWithQuestionMarks(criterion.text)
													: criterion.text}
												{isGrading && isTeacher ? (
													useCheckbox ? (
														<Checkbox
															checked={selectedOption?.text === 'Yes'}
															disabled={disabled}
															onChange={checked => {
																const selectedOption = criterion.gradingOptions.find(option => {
																	return option.text === (checked ? 'Yes' : 'No')
																})
																if (!selectedOption) {
																	return
																}

																onGradeCriteria({
																	criteriaId: criterion.id,
																	optionId: selectedOption.id,
																})
															}}
														/>
													) : (
														<select
															value={grades?.criteria[criterion.id]?.selectedOption}
															onChange={e =>
																onGradeCriteria({
																	criteriaId: criterion.id,
																	optionId: e.target.value,
																})
															}
															disabled={disabled}>
															<option />
															{criterion.gradingOptions.map(option => {
																return (
																	<option key={option.id} value={option.id}>
																		{option.text}
																	</option>
																)
															})}
														</select>
													)
												) : null}
											</span>
										</label>
									</li>
								)
							})}
					</ol>
					{isGrading && isTeacher && (
						<Button
							className="self-end"
							disabled={!isFullyGraded}
							onClick={() => {
								// If the criteria is ungraded and there is no "No" option, then we cannot proceed
								let ableToSubmit = true
								// Submit the "No" option for each of the ungraded criteria
								criteria.forEach(criterion => {
									if (
										criterion.displayType ===
											CREATIVE_CANVAS_STATION.RUBRIC.CRITERIA.DISPLAY_TYPE.HIDDEN &&
										!showHiddenCriteria
									) {
										return
									}

									if (!grades?.criteria[criterion.id]?.selectedOption) {
										const selectedOption = criterion.gradingOptions.find(
											option => option.text === 'No'
										)
										if (!selectedOption) {
											ableToSubmit = false
											return
										}
										onGradeCriteria({
											criteriaId: criterion.id,
											optionId: selectedOption.id,
										})
									}
								})
								if (ableToSubmit) {
									onFinishDocument()
								}
							}}>
							{isLastDocument ? 'Submit' : 'Next'}
						</Button>
					)}
				</div>
			</div>
		</div>
	)
}

/**
 * replaceAlphabetCharactersWithQuestionMarks - replaces all alphabet characters in a string with question marks
 *
 * @param {string} str - the string to replace characters in
 *
 * @return {string} - str with all alphabet characters replaced with question marks
 */
function replaceAlphabetCharactersWithQuestionMarks(str: string): string {
	// $FlowExpectedError[prop-missing] replaceAll is a relatively new function call
	return str.replaceAll(/[a-z]/gi, '?')
}
