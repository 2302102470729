import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getHealthTaskInfo } from '../../../store/stores/staticData'
import { getStudent } from '../../../store/selectors/sharedSelectors'
import { sendMessage } from '../../../store/stores/webSocket'
import { insertName } from '../helpers'
import styled from 'styled-components'
import { RATINGS } from '../../basics'

import type { SurveyPrompt } from '@mission.io/mission-toolkit'

const StyledSurveyResponse: StyledType<> = styled.div`
	h2,
	h3 {
		margin: 0;
		padding-top: 12px;
	}
	color: white;
`
const Tiny = styled.div`
	font-size: 0.75rem;
`
const DidNotComplete = styled(Tiny)`
	cursor: pointer;
	text-align: right;
	float: right;
	padding: 12px 0px;

	&:hover {
		text-decoration: underline;
	}
`

export default function SurveyResponse({ prompt }: { prompt: SurveyPrompt }): React$Node {
	const allTaskInfo = useSelector(getHealthTaskInfo)
	const actingStudent = useSelector(state => getStudent(state, prompt.studentIdInQuestion))
	const dispatch = useDispatch()
	if (!actingStudent) return <div>ERROR</div>
	const actingStudentName = `${actingStudent.firstName}${
		actingStudent.lastName ? ' ' + actingStudent.lastName : ''
	}`
	const taskInfo = allTaskInfo[prompt.taskType]
	if (!taskInfo) return null
	const submit = ({ didComplete, rating }: { didComplete: boolean, rating: number }) => {
		dispatch(sendMessage('SUBMIT_HEALTH_SURVEY', { didComplete, rating, id: prompt.id }))
	}
	return (
		<StyledSurveyResponse>
			<h3>{insertName(taskInfo.surveyMessage, actingStudentName)}</h3>
			<Ratings>
				{RATINGS.map(rating => {
					return (
						<rating.icon
							key={rating.value}
							onClick={() => submit({ rating: rating.value, didComplete: true })}
						/>
					)
				})}
			</Ratings>
			<DidNotComplete onClick={() => submit({ rating: 0, didComplete: false })}>
				Did not complete task
			</DidNotComplete>
		</StyledSurveyResponse>
	)
}

const Ratings = styled.div`
	svg {
		height: 40px;
		width: 40px;
		cursor: pointer;
		transition: all 0.3s ease 0s;
		&:hover {
			transform: translateY(-7px);
		}
		margin: 0px 20px;
	}
	display: flex;
	padding: 40px 0;
	flex-direction: row;
	justify-content: center;
`
