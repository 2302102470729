import { FRAME_DIMENSIONS } from '../../customShapes/CustomFrameShapeUtil'
import { useEditor } from '@tldraw/tldraw'
import { useEffect } from 'react'

const extraSpaceBeforeCanvasEdge = 16
/**
 * This hook will automatically resize any text boxes that are added to the canvas to the fill the rest of the width of the canvas.
 */
export const useTextBoxesWithDynamicWidth = () => {
	const editor = useEditor()
	useEffect(() => {
		const handleTextChange = change => {
			if (change.source === 'user') {
				// Look at shapes added by the user
				Object.keys(change.changes.added).forEach(id => {
					const record = change.changes.added[id]
					if (record.type === 'text') {
						const dynamicWidth = FRAME_DIMENSIONS[0] - record.x - extraSpaceBeforeCanvasEdge
						// dynamic width could be negative if the text box is created past the bounds of the frame.
						if (dynamicWidth < 0) {
							return
						}
						editor.updateShapes(
							[{ id: record.id, props: { w: dynamicWidth, autoSize: false } }],
							true // set to true to make an ephemeral change (will not be included in undo history)
						)
					}
				})
			}
		}

		editor.on('change', handleTextChange)

		return () => {
			editor.off('change', handleTextChange)
		}
	}, [editor])
}
