import { getStudentId } from '../../stores/general'
import { getActiveStudents } from '../../selectors/sharedSelectors'
import { isJrPlusPowerActive, type MapObjectIds } from '@mission.io/mission-toolkit'

import type { ReduxStore } from '../../rootReducer'

export function isPowerActive(state: ReduxStore): boolean {
	return isJrPlusPowerActive(state.jrPlusState)
}

export function getMin(state: ReduxStore): ?number {
	return state.jrPlusState.stationData.power.min
}

export function getMax(state: ReduxStore): number {
	return state.jrPlusState.stationData.power.max
}

export function getCurrentStudentPower(state: ReduxStore): number {
	const studentId = getStudentId(state.general)
	if (!studentId) {
		return 0
	}
	return state.jrPlusState.stationData.power.studentValues[studentId] || 0
}

export function getStartTime(state: ReduxStore): number {
	return state.jrPlusState.stationData.power.startTime
}

export function getTimerId(state: ReduxStore): ?string {
	return state.jrPlusState.stationData.power.completionTimerId
}

export function getCurrentPower(state: ReduxStore): number {
	const { studentValues } = state.jrPlusState.stationData.power
	const activeStudents = getActiveStudents(state)
	if (activeStudents.length === 0) {
		return 0
	}
	return activeStudents.reduce((total, student) => total + (studentValues[student.id] || 0), 0)
}

export function getCurrentAverage(state: ReduxStore): number {
	const activeStudents = getActiveStudents(state)
	if (activeStudents.length === 0) {
		return 0
	}
	return getCurrentPower(state) / activeStudents.length
}

export function getPowerMapObject(store: ReduxStore): ?MapObjectIds {
	if (!isPowerActive(store)) return null
	return store.jrPlusState.stationData.power.mapObject
}
