import React from 'react'
import styled from 'styled-components/macro'
import stationMap, { coreStationList } from '../../../../../constants/juniorPlusStationMap'
import { mapObject } from '../../../../../utility/functions'
import StationNav from './StationNav'
import PushAlert from './PushAlert'
import { PRIMARY_PURPLE, OVERLAY_Z_INDEX } from '../../../../../constants/styles'
import type { JrPlusStationEnum } from '../../../../../types'

type Props = { isRemote: boolean }

const StyledSideNavigation = styled.div`
	position: relative;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	z-index: ${OVERLAY_Z_INDEX + 1};
	grid-row: station-select-start / station-select-end;
	grid-column: station-select-start / station-select-end;
`

const RemoteSideNavigation = styled(StyledSideNavigation)`
	width: 100%;
	align-items: center;
	justify-content: space-around;
	background-color: ${PRIMARY_PURPLE};
	height: 100%;
`

const CoreFrame = styled.div`
	position: relative;
`

export default function SideBar({ isRemote }: Props): React$Node {
	const coreStations = []
	coreStationList.forEach(station => {
		coreStations.push({ ...stationMap[station], id: station })
	})
	if (isRemote) {
		return (
			<RemoteSideNavigation>
				{coreStations.map(({ id: stationId, ...station }) => (
					<CoreFrame key={stationId}>
						<PushAlert stationId={stationId} isRemote={isRemote} />
						<StationNav stationId={stationId} station={station} />
					</CoreFrame>
				))}
				{mapObject(stationMap, (station, id) => {
					if (coreStationList.includes(id)) return null
					let stationId: JrPlusStationEnum = (id: any) // Cast to any because flow does not recognize that the id string is the same as the enum
					return <StationNav stationId={stationId} key={id} station={station} />
				})}
			</RemoteSideNavigation>
		)
	}
}
