import Color from 'color'

import {
	CommunicationsIcon,
	DefenseIcon,
	TractorBeamIcon,
	ScanningIcon,
	TransporterIcon,
	PowerIcon,
} from '../../../../../images/jrPlusPanel'
import type { ActionType } from '../../helpers/constants'
const scanColor = Color('#00648A')
const targetColor = Color('#902EDA')
const contactColor = Color('#49FF40')
const tractorBeamColor = Color('#FB931F')
const powerColor = Color('#FFFFFF')
const transporterColor = Color('#EFFE2C')
const DARKEN_PERCENTAGE = 0.25
const LIGHTEN_PERCENTAGE = 0.5
const DISABLED_PERCENTAGE = 0.6

export type ObjectActionStyles = {
	color: string,
	hoverColor: string,
	activeColor: string,
	disabledColor: string,
	title: string,
	type: ActionType,
	icon: ({ iconOnly?: boolean, isOpen?: boolean, transitionPattern?: any }) => React$Node,
	r: number,
}

export const DEFAULT_RADIUS = 8
export const SCAN_DEFAULT_RADIUS = DEFAULT_RADIUS * 2

export const OBJECT_ACTION_STYLES: {
	[ActionType]: ObjectActionStyles,
} = {
	SCAN: {
		color: scanColor.hex(),
		hoverColor: scanColor.darken(DARKEN_PERCENTAGE).hex(),
		activeColor: scanColor.lighten(LIGHTEN_PERCENTAGE).hex(),
		disabledColor: scanColor.fade(DISABLED_PERCENTAGE).hex(),
		title: 'Scan',
		type: 'SCAN',
		icon: ScanningIcon,
		r: SCAN_DEFAULT_RADIUS,
	},
	CONTACT: {
		color: contactColor.hex(),
		hoverColor: contactColor.darken(DARKEN_PERCENTAGE).hex(),
		activeColor: contactColor.lighten(LIGHTEN_PERCENTAGE).hex(),
		disabledColor: contactColor.fade(DISABLED_PERCENTAGE).hex(),
		title: 'Contact',
		type: 'CONTACT',
		icon: CommunicationsIcon,
		r: DEFAULT_RADIUS,
	},
	POWER: {
		color: powerColor.hex(),
		hoverColor: powerColor.darken(DARKEN_PERCENTAGE).hex(),
		activeColor: powerColor.lighten(LIGHTEN_PERCENTAGE).hex(),
		disabledColor: powerColor.fade(DISABLED_PERCENTAGE).hex(),
		title: 'Power',
		type: 'POWER',
		icon: PowerIcon,
		r: DEFAULT_RADIUS,
	},
	TARGET: {
		color: targetColor.hex(),
		hoverColor: targetColor.darken(DARKEN_PERCENTAGE).hex(),
		activeColor: targetColor.lighten(LIGHTEN_PERCENTAGE).hex(),
		disabledColor: targetColor.fade(DISABLED_PERCENTAGE).hex(),
		title: 'Target',
		type: 'TARGET',
		icon: DefenseIcon,
		r: DEFAULT_RADIUS,
	},
	TRACTOR_BEAM: {
		color: tractorBeamColor.hex(),
		hoverColor: tractorBeamColor.darken(DARKEN_PERCENTAGE).hex(),
		activeColor: tractorBeamColor.lighten(LIGHTEN_PERCENTAGE).hex(),
		disabledColor: tractorBeamColor.fade(DISABLED_PERCENTAGE).hex(),
		title: 'Tractor Beam',
		type: 'TRACTOR_BEAM',
		icon: TractorBeamIcon,
		r: DEFAULT_RADIUS,
	},
	TRANSPORT: {
		color: transporterColor.hex(),
		hoverColor: transporterColor.darken(DARKEN_PERCENTAGE).hex(),
		activeColor: transporterColor.lighten(LIGHTEN_PERCENTAGE).hex(),
		disabledColor: transporterColor.fade(DISABLED_PERCENTAGE).hex(),
		title: 'Transport',
		type: 'TRANSPORT',
		icon: TransporterIcon,
		r: DEFAULT_RADIUS,
	},
}

export const ICON_SIZE = 10

export const TEXT_SIZE = 6

const MAIN_CIRCLE_R = 28

const INNER_ANGLE = 18
const INNER_ODD_ANGLE = 38
const OUTER_ANGLE = 52
const OUTER_ODD_ANGLE = 76

const COSINES = {
	INNER_ANGLE: Math.cos(INNER_ANGLE * (Math.PI / 180)) * MAIN_CIRCLE_R,
	INNER_ODD_ANGLE: Math.cos(INNER_ODD_ANGLE * (Math.PI / 180)) * MAIN_CIRCLE_R,
	OUTER_ANGLE: Math.cos(OUTER_ANGLE * (Math.PI / 180)) * MAIN_CIRCLE_R,
	OUTER_ODD_ANGLE: Math.cos(OUTER_ODD_ANGLE * (Math.PI / 180)) * MAIN_CIRCLE_R,
}
const SINES = {
	INNER_ANGLE: Math.sin(INNER_ANGLE * (Math.PI / 180)) * MAIN_CIRCLE_R,
	INNER_ODD_ANGLE: Math.sin(INNER_ODD_ANGLE * (Math.PI / 180)) * MAIN_CIRCLE_R,
	OUTER_ANGLE: Math.sin(OUTER_ANGLE * (Math.PI / 180)) * MAIN_CIRCLE_R,
	OUTER_ODD_ANGLE: Math.sin(OUTER_ODD_ANGLE * (Math.PI / 180)) * MAIN_CIRCLE_R,
}

type CenterPoint = {| cx: number, cy: number |}

export const ALL_SLOTS = (
	x: number,
	y: number
): {
	center: CenterPoint,
	innerRight: CenterPoint,
	innerLeft: CenterPoint,
	innerOddRight: CenterPoint,
	innerOddLeft: CenterPoint,
	outerRight: CenterPoint,
	outerLeft: CenterPoint,
	outerOddRight: CenterPoint,
	outerOddLeft: CenterPoint,
} => ({
	center: { cx: x, cy: y + MAIN_CIRCLE_R },
	innerRight: { cx: x + SINES.INNER_ANGLE, cy: y + COSINES.INNER_ANGLE },
	innerLeft: { cx: x - SINES.INNER_ANGLE, cy: y + COSINES.INNER_ANGLE },
	innerOddRight: { cx: x + SINES.INNER_ODD_ANGLE, cy: y + COSINES.INNER_ODD_ANGLE },
	innerOddLeft: { cx: x - SINES.INNER_ODD_ANGLE, cy: y + COSINES.INNER_ODD_ANGLE },
	outerRight: { cx: x + SINES.OUTER_ANGLE, cy: y + COSINES.OUTER_ANGLE },
	outerLeft: { cx: x - SINES.OUTER_ANGLE, cy: y + COSINES.OUTER_ANGLE },
	outerOddRight: { cx: x + SINES.OUTER_ODD_ANGLE, cy: y + COSINES.OUTER_ODD_ANGLE },
	outerOddLeft: { cx: x - SINES.OUTER_ODD_ANGLE, cy: y + COSINES.OUTER_ODD_ANGLE },
})
