import Color from 'color'
import type { AbstractComponent } from 'react'
import styled from 'styled-components'
import { PRIMARY_GREEN, EMPTY_SPACE, ACCENT_BLUE } from '../../../../../constants/styles'
export const BACKGROUND_BLUE = EMPTY_SPACE
export const FOREGROUND_BLUE = ACCENT_BLUE
export const HIGHLIGHT_BLUE = 'rgb(13, 178, 174)'
export const HIGHLIGHT_ORANGE = '#fea13d'

const primaryGreenColor = Color(PRIMARY_GREEN)
export const BACKGROUND_PANEL: string = primaryGreenColor.darken(0.2).hex()

export const DarkLayer: AbstractComponent<*, HTMLDivElement> = styled.div`
	background-color: var(--color-primary-darkest);
	padding: 8px;
	border-radius: 8px;
`
export const SecondaryLayer: AbstractComponent<*, HTMLDivElement> = styled.div`
	background-color: var(--color-primary-darker);
	padding: 8px;
	border-radius: 8px;
`

export const PrimaryLayer: AbstractComponent<*, HTMLDivElement> = styled.div`
	background-color: var(--color-primary-dark);
	padding: 8px;
	border-radius: 8px;
`
