import React from 'react'
import type { Image, Video, Audio } from '@mission.io/mission-toolkit/actions'
import styled from 'styled-components/macro'
import { VideoController } from '../../VideoController'
import { ContentExpander } from './ContentExpander'

/**
 * Media event input is a component for displaying media without text during a literacy event.
 * @param {Image|Video|Audio} props.media the media that will be displayed
 * @param {string} props.title the title of the media
 * @returns {React$Node}
 */
export function MediaEventInput({
	media,
	title,
}: {
	media: Image | Video | Audio,
	title: string,
}): React$Node {
	if (media.type === 'IMAGE') {
		return (
			<MediaContainer $fillSpace>
				{/* Title is purposefully left off of the image media so that an image can be fully seen. */}
				<img alt="Event Alert" css="max-width: 100%; max-height: 100%;" src={media.url} />
				<ContentExpander css="bottom: var(--spacing); right: var(--spacing)">
					<img alt="Event Alert" css="max-width: 100%; max-height: 100%;" src={media.url} />
				</ContentExpander>
			</MediaContainer>
		)
	} else if (media.type === 'VIDEO') {
		return (
			<MediaContainer>
				<Header title={title} />
				<VideoController
					autoPlay={false}
					css="max-width: 100%; max-height: 100%; flex: 1; overflow: hidden;"
					src={media.url}
				/>
			</MediaContainer>
		)
	} else if (media.type === 'AUDIO') {
		return (
			<MediaContainer>
				<Header title={title} />
				<audio css="width: 100%;" controls>
					<source src={media.url} />
				</audio>
			</MediaContainer>
		)
	}
}

const Header = ({ title }) => {
	return title ? <h3 css="margin: 0 0 var(--spacing2x) 0; color: inherit;">{title}</h3> : null
}

const MediaContainer = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	${({ $fillSpace }) => ($fillSpace ? `height: 100%;` : `height: calc(100% - var(--spacing2x));`)}
	align-items: center;
	justify-content: start;
`
