import React from 'react'
import styled from 'styled-components'
import { FaPauseCircle } from 'react-icons/fa'
import { rgba } from '../../constants/styles'
import config from '../../config'

export default function PauseScreen(): React$Node {
	if (config.devFlags?.hidePauseScreenOverlay === true) {
		return null
	}
	return (
		<PausedScreen className="z-30 top-0 right-0 absolute">
			<PausedText>
				<FaPauseCircle className="white" size={45} />
				Eyes Up!
				<FaPauseCircle className="white" size={45} />
			</PausedText>
		</PausedScreen>
	)
}

const PausedScreen: StyledType<{ $isController?: boolean }> = styled.div`
	background-color: rgba(0, 0, 0, 0.95);
	display: flex;
	align-items: center;
	width: 100%;
	height: 100%;
`

const PausedText = styled.div`
	width: 100%;
	font-weight: 300;
	font-size: 2rem;
	justify-content: space-around;
	display: flex;
	color: ${rgba('#FFFFFF', 0.9)};
`
