import React from 'react'
import styled, { keyframes } from 'styled-components/macro'

/**
 * A marquee that will infinitely scroll the given title.
 * @param {string} props.title the title that will be scrolled infinitely.
 * @returns {React$Node}
 */
export function InfiniteMarquee({ title }: { title: string }): React$Node {
	return (
		<MarqueeStyle>
			<div className="content">
				<span>{title}</span>
				<span>·</span>
				<span>{title}</span>
				<span>·</span>
				<span>{title}</span>
				<span>·</span>
			</div>
			<div className="content" aria-hidden="true">
				<span>{title}</span>
				<span>·</span>
				<span>{title}</span>
				<span>·</span>
				<span>{title}</span>
				<span>·</span>
			</div>
		</MarqueeStyle>
	)
}
const marqueeKeyframe = keyframes`
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(calc(-100% - var(--gap)));
    }
`

const MarqueeStyle = styled.h4.attrs({ className: 'mb-2' })`
	--gap: 1rem;
	--duration: 32s;
	position: relative;
	display: flex;
	overflow: hidden;
	user-select: none;
	gap: var(--gap);

	.content {
		flex-shrink: 0;
		display: flex;
		align-items: center;
		justify-content: space-around;
		gap: var(--gap);
		min-width: 100%;
		animation: ${marqueeKeyframe} var(--duration) linear infinite;
	}

	/* Pause on hover */
	&:hover .content {
		animation-play-state: paused;
	}

	/* Pause animation when reduced-motion is set */
	@media (prefers-reduced-motion: reduce) {
		.content {
			animation-play-state: paused !important;
		}
	}
`
