import type { Action } from './actionTypes'

type SubClass<Super, C: Super> = Class<C>
export type Reducer<State> = (State, SubClass<Action, *>) => State

export default function createReducer<State>(
	initialState: State,
	handlers: { [type: string]: (State, SubClass<Action, *>) => State }
): (State, SubClass<Action, *>) => State {
	return (state: State = initialState, action: SubClass<Action, *>): State => {
		return handlers[action.type] ? handlers[action.type](state, action) : state
	}
}
