import React from 'react'
import CurrentStation from '../../../../StationSelector'
import Points from './Points'
import 'styled-components/macro'

export default function BottomLeftCorner(props: { className?: string }): React$Node {
	return (
		<svg {...props} viewBox="1570 0 350 172" fill="none" overflow="visible">
			<g id="Bottom_Corner_Panels">
				<path
					id="Vector_2"
					d="M1742.2 13C1733.7 13 1725.6 16.4 1719.6 22.4L1570 172H1920V13H1742.2Z"
					fill="#364984"
				/>
			</g>

			<g id="Little_panel__x28_bottom_right_x29_">
				<g id="Group_2">
					<path
						id="Vector_13"
						d="M1767.9 137.1H1643.4C1629.8 150.7 1622.1 158.4 1608.5 172H1806.4C1790.3 164.5 1776.8 152.3 1767.9 137.1Z"
						fill="#5D568C"
					/>
				</g>
				<path
					id="Vector_14"
					d="M1695.6 157.5H1657.8C1654.8 157.5 1652.4 155.1 1652.4 152.1V149.6C1652.4 146.6 1654.8 144.2 1657.8 144.2H1695.6C1698.6 144.2 1701 146.6 1701 149.6V152.1C1701.1 155 1698.6 157.5 1695.6 157.5Z"
					fill="#364984"
				/>
				<path
					id="Vector_15"
					d="M1643.3 137.1H1767.8C1776.8 152.3 1790.2 164.5 1806.3 172H1818.1L1808.4 167.5C1793.2 160.4 1780.7 149 1772.1 134.6L1770.6 132.1H1767.7H1643.2H1641.1L1639.6 133.6L1618.7 154.5L1604.7 168.5L1601.2 172H1608.3C1622.1 158.4 1629.7 150.7 1643.3 137.1Z"
					fill="#0F193C"
				/>
			</g>
			<CurrentStation />
			<Points />
		</svg>
	)
}
