import React, { useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import styled, { keyframes } from 'styled-components/macro'
import UpgradeAnimation from './UpgradeAnimation'
import { variantTypes, type Score, type StudentAnswer } from '@mission.io/question-toolkit'
import { isCurrentStationUpgradable } from '../StationUpgradeOverlay/selectors'

const RESPONSE_MODAL_TIME = 2000

type QuestionResponseType = $ReadOnly<{ score?: ?Score, answer?: ?StudentAnswer }>

/**
 * Determines if a question response is correct or not based on the score recorded on the response
 * @param {QuestionResponseType} questionResponse
 * @returns {boolean}
 */
export const isQuestionResponseCorrect = (questionResponse: ?QuestionResponseType): ?boolean => {
	return !questionResponse?.score || questionResponse?.score?.score == null
		? null
		: questionResponse?.score?.score === questionResponse.score.perfectScore
}

/**
 * If the student's question response is ungraded because it was for a free response question
 * @param {?QuestionResponseType} questionResponse
 */
const isUngradedFreeResponse = (questionResponse: ?QuestionResponseType): ?boolean => {
	return (
		questionResponse?.score?.score === null &&
		questionResponse?.answer?.type === variantTypes.FREE_RESPONSE
	)
}

/**
 * Displays whether the students question response is correct or not.
 * If they answered correctly and the mission is a 4+ mission, the response will also display an animation
 * for the station upgrade that was earned from answering the question correctly.
 * @param {?QuestionResponseType} questionResponse the student's response to a question
 * @param {cb~releaseQuestion}	releaseQuestion called when the question can be released.
 * @param {boolean} is4Plus a 4+ mission or not
 */
export default function QuestionResponse({
	questionResponse,
	is4Plus,
	releaseQuestion,
	finishedAnimation,
}: {
	questionResponse: ?QuestionResponseType,
	releaseQuestion: () => void,
	finishedAnimation: () => void,
	is4Plus: boolean,
}): React$Node {
	const isCorrect = isQuestionResponseCorrect(questionResponse)
	const ungradedFreeResponse = isUngradedFreeResponse(questionResponse)
	const skipQuestionReview = isCorrect || ungradedFreeResponse
	const finishResponseAnimation = useCallback(() => {
		finishedAnimation()
		if (skipQuestionReview) {
			releaseQuestion()
		}
	}, [skipQuestionReview, releaseQuestion, finishedAnimation])
	const currentStationIsUpgradable = useSelector(isCurrentStationUpgradable)
	const doUpgradeAnimation =
		is4Plus && currentStationIsUpgradable && (isCorrect || ungradedFreeResponse)
	useEffect(() => {
		if (!doUpgradeAnimation && ungradedFreeResponse) {
			finishedAnimation()
			finishResponseAnimation()
		}
	}, [doUpgradeAnimation, ungradedFreeResponse, finishResponseAnimation, finishedAnimation])

	useEffect(() => {
		if (!doUpgradeAnimation && ungradedFreeResponse) {
			finishedAnimation()
		}
	}, [doUpgradeAnimation, ungradedFreeResponse, finishResponseAnimation, finishedAnimation])

	if (!doUpgradeAnimation && ungradedFreeResponse) {
		// Do not show correct/incorrect animation for an ungraded free response question unless we are displaying the upgrade animation
		return null
	} else {
		return doUpgradeAnimation ? (
			<UpgradeAnimation isCorrect={Boolean(isCorrect)} onFinish={finishResponseAnimation} />
		) : (
			<TrivialAnimation isCorrect={isCorrect} onFinish={finishResponseAnimation} />
		)
	}
}

/**
 * A trivial animation that display whether the answer was correct or wrong, and then automatically calls
 * onFinish when the animation completes.
 * @param {cb~onFinish} props.onFinish callback function for when the question animation completes
 */
function TrivialAnimation({ onFinish, isCorrect }: { onFinish: () => void, isCorrect: ?boolean }) {
	useEffect(() => {
		let timeoutId = setTimeout(onFinish, RESPONSE_MODAL_TIME)
		return () => {
			clearTimeout(timeoutId)
		}
	}, [onFinish, isCorrect])
	return (
		<AnswerResponse>
			<section className="c-container">
				{isCorrect ? (
					<div className="o-circle c-container__circle o-circle__sign--success">
						<div className="o-circle__sign"></div>
					</div>
				) : (
					<div className="o-circle c-container__circle o-circle__sign--failure">
						<div className="o-circle__sign"></div>
					</div>
				)}
			</section>
		</AnswerResponse>
	)
}

const keyframeAnimations = {
	successSign: keyframes`
		50% {
			opacity: 1;
			transform: translateX(130%) translateY(35%) rotate(45deg) scale(1.7);
		}

		100% {
			opacity: 1;
			transform: translateX(130%) translateY(35%) rotate(45deg) scale(1);
		}
	`,
	errorSign: keyframes`
		50% {
			opacity: 1;
			transform: translateY(25%) rotate(45deg) scale(1.7);
		}

		100% {
			opacity: 1;
			transform: translateY(25%) rotate(45deg) scale(1);
		}
	`,
	circle: keyframes`
		0% {
			transform: scale(0);
		}

		50% {
			transform: scale(1.5);
		}

		60% {
			transform: scale(1);
		}

		100% {
			transform: scale(1);
		}
	`,
	setOverflow: keyframes`
		to {
			overflow: hidden;
		}
	`,
}

const AnswerResponse = styled.div`
	margin: auto;
	width: fit-content;
	height: fit-content;
	min-width: 40vw;
	min-height: 20%;
	max-width: 100vw;
	max-height: 100%;
	${'' /* IPad "Fix"/hack - https://allthingssmitty.com/2020/05/11/css-fix-for-100vh-in-mobile-webkit/ */}
	max-height: -webkit-fill-available;
	overflow: hidden;

	/*=======================
          C-Container
  =========================*/
	.c-container {
		max-width: 27rem;
		margin: 1rem auto;
	}

	/*=======================
          O-Circle
  =========================*/

	.o-circle {
		display: flex;
		width: 20rem;
		height: 20rem;
		justify-content: center;
		align-items: flex-start;
		border-radius: 50%;
		animation: ${keyframeAnimations.circle} 0.8s ease-in-out 1 forwards,
			${keyframeAnimations.setOverflow} 0.1s 1.1s forwards;
	}

	.c-container__circle {
		margin: 0 auto;
	}

	/*=======================
      C-Circle Sign
  =========================*/

	.o-circle__sign {
		position: relative;
		opacity: 0;
		background: #fff;
		animation-duration: 0.8s;
		animation-delay: 0.2s;
		animation-timing-function: ease-in-out;
		animation-iteration-count: 1;
		animation-fill-mode: forwards;
	}

	.o-circle__sign::before,
	.o-circle__sign::after {
		content: '';
		position: absolute;
		background: inherit;
	}

	.o-circle__sign::after {
		left: 100%;
		top: 0%;
		width: 500%;
		height: 95%;
		transform: translateY(4%) rotate(0deg);
		border-radius: 0;
		opacity: 0;
		z-index: -1;
	}

	/*=======================
        Sign Success
  =========================*/

	.o-circle__sign--success {
		background: ${({ theme }) => theme.success};
	}

	.o-circle__sign--success .o-circle__sign {
		width: 2.4rem;
		height: 12.4rem;
		transform: translateX(180%) translateY(90%) rotate(45deg) scale(0.11);
		animation-name: ${keyframeAnimations.successSign};
	}

	.o-circle__sign--success .o-circle__sign::before {
		bottom: -17%;
		width: 100%;
		height: 58%;
		transform: translateX(-150%) translateY(15%) rotate(90deg);
	}

	/*=======================
        Sign Failure
  =========================*/

	.o-circle__sign--failure {
		background: #ff1301;
	}

	.o-circle__sign--failure .o-circle__sign {
		width: 2rem;
		height: 14rem;
		transform: translateY(25%) rotate(45deg) scale(0.1);
		animation-name: ${keyframeAnimations.errorSign};
	}

	.o-circle__sign--failure .o-circle__sign::before {
		top: 50%;
		width: 100%;
		height: 100%;
		transform: translateX(-55%) translateY(-50%) rotate(90deg);
	}
`
