// @flow
import React, { useRef, useState, useEffect } from 'react'

import type { Node } from 'react'

import Projectile, { PROJECTILE_TYPE } from './Projectile.jsx'
import Vehicle from '../VehicleImage'
import Enemy from './Enemy'
import { getDimensions, STATUS_TO_IMAGE as IMAGES } from '../../utility/targets'
import { useDimensions, useImageWidth } from '../../utility/hooks.jsx'

import { doesCollide } from '../../juniorPlusStations/Communication/collision'
import { getCollision } from '../../juniorPlusStations/Defense/components/helpers'
import classnames from 'classnames'

export type HitResults = {
	enemyLocation: ?ClientRect,
}

type Props = {
	active: boolean,
	onMiss: () => void,
	onHit: HitResults => void,

	enemyHitDuration?: number,
	enemyRightOffset: number,
	enemyDrift: number,
	enemyDriftSpeed?: number,

	projectileWidth: number,
	projectileHeight: number,

	className?: string,
	projectileType: $Keys<typeof PROJECTILE_TYPE>,
	imageType: 'ASTEROID' | 'ENEMY_SHIP' | 'TARGET' | 'SATELLITE',

	children?: Node,
}

/**
 * A game where the player must shoot a projectile at an enemy. Supports different projectile types and enemy images.
 * This game fills the entire width and height of its parent container. All pieces within the game are positioned relative
 * to the game's container.
 *
 * @param {boolean} props.active - whether the game is active
 * @param {() => void} props.onMiss - callback when the player misses the target
 * @param {(HitResults) => void} props.onHit - callback when the player hits the target
 * @param {?number} props.enemyHitDuration - optional prop the duration of the enemy hit animation defaults to 2500ms
 * @param {number} props.enemyRightOffset - the right offset of the enemy respective to the full width of this component.
 * @param {number} props.enemyDrift - the amount the enemy drifts horizontally respective to the right offset.
 * @param {?number} props.enemyDriftSpeed - the speed at which the enemy drifts horizontally.
 * @param {number} props.projectileWidth - the width of the projectile.
 * @param {number} props.projectileHeight - the height of the projectile.
 * @param {?string} props.className - optional class name for the component.
 * @param {string} props.projectileType - the type of projectile to shoot LASER | RADIO_WAVE.
 * @param {string} props.imageType - the type of enemy image to display ASTEROID | ENEMY_SHIP | TARGET | SATELLITE.
 * @param {?Node} props.children - optional children to render within the game. It is recommended that the children have position:absolute.
 */
export default function ShooterGame({
	active,
	enemyRightOffset,
	enemyDrift,
	projectileWidth,
	projectileHeight,
	className,
	projectileType,
	imageType,
	enemyDriftSpeed,
	enemyHitDuration = 2500,
	onHit,
	onMiss,
	children,
}: Props): React$Node {
	const [canvasRef, { width: canvasWidth, height: canvasHeight }] = useDimensions()
	const [vehicleRef, vehicleWidth] = useImageWidth()
	const [shooting, setShooting] = useState(false)
	const [enemyHit, setEnemyHit] = useState(false)

	const projectileRef = useRef<HTMLImageElement | HTMLDivElement | null>(null)
	const enemyRef = useRef<HTMLImageElement | null>(null)

	const enemyHitTimeout = useRef<?TimeoutID>()

	useEffect(() => {
		return () => {
			if (enemyHitTimeout.current) {
				clearTimeout(enemyHitTimeout.current)
				enemyHitTimeout.current = null
			}
		}
	}, [])

	const handleCanvasClick = () => {
		if (active && !shooting && !enemyHit) {
			setShooting(true)
		}
	}

	const handleBulletUpdate = () => {
		const projectile = getCollision(projectileRef.current)
		const enemy = getCollision(enemyRef.current)

		if (!enemyHit && doesCollide(projectile, enemy)) {
			onHit({
				enemyLocation: enemyRef.current?.getBoundingClientRect(),
			})

			setEnemyHit(true)

			enemyHitTimeout.current = setTimeout(() => {
				setEnemyHit(false)
				enemyHitTimeout.current = null
			}, enemyHitDuration)
		}
	}
	const handleBulletFinish: () => void = () => {
		if (!enemyHit) onMiss()
		setShooting(false)
	}

	const [width, height] = getDimensions(imageType)

	const projectileTop = (canvasHeight || 0) / 2 - projectileHeight / 2
	return (
		<div className={classnames(className, 'relative w-full h-full')}>
			<div
				ref={canvasRef}
				onClick={handleCanvasClick}
				className="w-full h-full flex items-center relative">
				<Vehicle ref={vehicleRef} className={`max-h-[80%]`} />

				{shooting && canvasWidth && vehicleWidth ? (
					<Projectile
						width={projectileWidth}
						height={projectileHeight}
						ref={projectileRef}
						type={projectileType}
						begin={{ top: projectileTop, left: vehicleWidth - projectileWidth }}
						end={{ top: projectileTop, left: canvasWidth - projectileWidth }}
						onUpdate={handleBulletUpdate}
						onFinish={handleBulletFinish}
					/>
				) : null}

				{active && canvasHeight && (
					<Enemy
						ref={enemyRef}
						image={IMAGES[imageType] || IMAGES.ENEMY_SHIP}
						containerHeight={canvasHeight}
						width={width}
						height={height}
						drift={enemyDrift}
						driftSpeed={enemyDriftSpeed}
						right={enemyRightOffset}
						className={
							enemyHit
								? projectileType === PROJECTILE_TYPE.LASER
									? 'animate-shooter-game-shake-hit'
									: projectileType === PROJECTILE_TYPE.RADIO_WAVE
									? 'animate-shooter-game-ping-hit'
									: ''
								: ''
						}
					/>
				)}
			</div>
			{children}
		</div>
	)
}
