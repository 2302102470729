import React from 'react'
import styled from 'styled-components'

import { BackgroundCircle, FilledPath, FilledPolygon } from '../helpers'
import type { Props } from '../helpers'

const Line = styled.line`
	fill: none;
`

export default function TractorBeamIcon({
	iconOnly,
	transitionPattern = {},
	isOpen = true,
	...props
}: Props): React$Node {
	return (
		<svg
			{...props}
			id="tractorBeamPlusPanel"
			data-name="TractorBeam Plus Panel"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 70 70"
			fill="#ff931e">
			<g
				style={{
					...transitionPattern,
					transform: `${isOpen ? 'scale(1)' : 'scale(0)'}`,
					transformOrigin: '50% 50%',
				}}>
				{!iconOnly && <BackgroundCircle cx="35" cy="35" r="35" />}
				<FilledPolygon
					points="40.75 26.34 27.17 26.34 29.07 21 38.66 21 40.75 26.34"
					iconOnly={iconOnly}
				/>
				<FilledPolygon
					points="44.52 35.84 23.75 35.84 25.65 31 42.43 31 44.52 35.84"
					iconOnly={iconOnly}
				/>
				<FilledPolygon
					points="47.87 45.42 20.7 45.42 22.23 40.63 46.19 40.63 47.87 45.42"
					iconOnly={iconOnly}
				/>
				<FilledPolygon
					points="51.22 55 17.66 55 19.18 50.21 49.54 50.21 51.22 55"
					iconOnly={iconOnly}
				/>
				<Line x1="42.43" y1="27.74" x2="40.75" y2="24.01" />
				<Line x1="46.19" y1="36.13" x2="44.52" y2="32.4" />
				<Line x1="49.54" y1="43.58" x2="47.87" y2="39.85" />
				<Line x1="17.67" y1="47.31" x2="16.14" y2="51.04" />
				<Line x1="52.88" y1="51.04" x2="51.22" y2="47.31" />
				<FilledPath
					d="M58,55H85s-4-9-14-9S58,55,58,55Z"
					transform="translate(-37 -37)"
					iconOnly={iconOnly}
				/>
			</g>
		</svg>
	)
}
