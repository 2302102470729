import { useSpring, useSpringRef, animated } from '@react-spring/web'
import React, { useState, useRef, useCallback, useLayoutEffect } from 'react'
import type { Node } from 'react'

type Props = {
	top: number,
	bottom: number,
	leftDrift: number,
	rightDrift: number,
	verticalTransitionTime: number,
	horizontalTransitionTime: number,
	onFrame?: (location: { top: number, rightOffset: number }) => void,
	children: (location: { top: number, rightOffset: number }) => Node,
}

/**
 * PathHOC - A higher order component used to bounce the child around a box
 *
 * @param {Object} props - the react props
 * @param {number} props.top - the location of the top (y position) of the box to bounce around in
 * @param {number} props.bottom - the location of the bottom (y position) of the box to bounce around in
 * @param {number} props.leftDrift - the location of the left (x position) of the box to bounce around in
 * @param {number} rightDrift - location of the right (x position) of the box to bounce around in
 * @param {number} verticalTransitionTime - the amount of time (ms) it takes to traverse the entire y direction
 * @param {number} horizontalTransitionTime - the amount of time (ms) it takes to traverse the entire x direction
 * @param {?(location: { top: number, rightOffset: number }) => void} onFrame - a callback for every time the x, y position changes
 * @param {?(location: { top: number, rightOffset: number }) => React$Node} children - a function to generate the child using the position
 *
 * @return {React$Node}
 */
export default function PathHOC({
	top,
	bottom,
	leftDrift,
	rightDrift,
	verticalTransitionTime,
	horizontalTransitionTime,
	onFrame,
	children,
}: Props): React$Node {
	const [isMovingDown, setIsMovingDown] = useState(true)
	const [isMovingLeft, setIsMovingLeft] = useState(false)

	const onFrameRef = useRef(onFrame)
	onFrameRef.current = onFrame

	const wasOtherAttributeAlreadyUpdatedThisFrame = useRef(false)
	const sendUpdateIfBothAttributesUpdated = useCallback(() => {
		if (wasOtherAttributeAlreadyUpdatedThisFrame.current) {
			// the other attribute has been updated this frame, send the location through the callback
			if (onFrameRef.current) {
				onFrameRef.current(location.current)
			}
			wasOtherAttributeAlreadyUpdatedThisFrame.current = false
		} else {
			// the other attribute has not been updated yet
			wasOtherAttributeAlreadyUpdatedThisFrame.current = true
		}
	}, [])

	const location = useRef({
		top,
		rightOffset: leftDrift,
	})

	const verticalSpringRef = useSpringRef()
	const verticalSpring = useSpring({
		ref: verticalSpringRef,
	})

	const horizontalSpringRef = useSpringRef()
	const horizontalSpring = useSpring({
		ref: horizontalSpringRef,
	})

	useLayoutEffect(() => {
		verticalSpringRef.start({
			config: { duration: verticalTransitionTime },
			from: { top: isMovingDown ? top : bottom },
			to: { top: isMovingDown ? bottom : top },
			onRest: () => {
				setIsMovingDown(!isMovingDown)
			},
			onChange: ({ value: { top } }) => {
				location.current.top = top
				sendUpdateIfBothAttributesUpdated()
			},
		})
	}, [
		isMovingDown,
		verticalSpringRef,
		top,
		bottom,
		verticalTransitionTime,
		sendUpdateIfBothAttributesUpdated,
	])

	useLayoutEffect(() => {
		horizontalSpringRef.start({
			config: { duration: horizontalTransitionTime },
			from: { rightOffset: isMovingLeft ? leftDrift : rightDrift },
			to: { rightOffset: isMovingLeft ? rightDrift : leftDrift },
			onRest: () => {
				setIsMovingLeft(!isMovingLeft)
			},
			onChange: ({ value: { rightOffset } }) => {
				location.current.rightOffset = rightOffset
				sendUpdateIfBothAttributesUpdated()
			},
		})
	}, [
		isMovingLeft,
		horizontalSpringRef,
		leftDrift,
		rightDrift,
		horizontalTransitionTime,
		sendUpdateIfBothAttributesUpdated,
	])

	return (
		<LocationHOC
			onFrame={onFrame}
			rightOffset={horizontalSpring.rightOffset}
			top={verticalSpring.top}>
			{children}
		</LocationHOC>
	)
}

/**
 * LocationHOC - A higher order component used to convert the react-spring values to numeric values
 *
 * @param {Object} props - the react props
 * @param {number} props.top - the location from the top the bouncing point is
 * @param {number} props.rightOffset - the location from the right the bouncing point is
 * @param {?(location: { top: number, rightOffset: number }) => React$Node} children - a function to generate the child using the position
 *
 * @return {React$Node}
 */
const LocationHOC = animated(function Location({
	top,
	rightOffset,
	children,
}: {
	top: number,
	rightOffset: number,
	children: (location: { top: number, rightOffset: number }) => Node,
}) {
	return children({ top, rightOffset })
})
