import React from 'react'
import MicSelector from '../VideoChat/MicSelector'
import Volumes from './Volumes'
import styled from 'styled-components/macro'
import { BACKGROUND_PANEL } from '../Frame/JrPlusFrame/components/Toolkit/SharedColors'

export default function RemoteSettings(): React$Node {
	return (
		<Wrapper>
			<div>
				<h3>Microphone</h3>
				<MicSelector />
			</div>
			<div>
				<h3>Player Volume</h3>
				<Volumes />
			</div>
		</Wrapper>
	)
}

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	background-color: ${BACKGROUND_PANEL};
	height: 100%;
	h3 {
		margin: 16px;
		padding-top: 32px;
	}
`
