import React, { Component } from 'react'
import { connect, useSelector } from 'react-redux'
import { MdBatteryAlert, MdBatteryChargingFull } from 'react-icons/md'
import styled from 'styled-components'
import 'styled-components/macro'
import ThrottleSlider from './ThrottleSlider'
import { sendMessage } from '../../store/stores/webSocket'
import { getStudentPowerData } from '../../store/selectors/jrState'
import { EMPTY_SPACE, ACCENT_GREEN, PRIMARY_GREEN, RED } from '../../constants/styles'
import { DeltaClockProgressBar } from '../../components/DeltaClock'
import { Speedometer, Dial } from './assets'
import { POWER_GAUGE_STATUS, type PowerGaugeStatus, MAX_POWER, POWER_STATION_DELTA } from './types'

type Props = {
	myValue: number,
	completionTimerId: ?string,
	sendMessage: Function,
}

type State = {
	myLocalValue: number,
}

// The actual value on the Slider is out of 100, so the click and drag feels more fluid
const LOCAL_RANGE: number = 100

class Power extends Component<Props, State> {
	constructor(props: Props) {
		super(props)
		this.state = {
			myLocalValue: props.myValue * (LOCAL_RANGE / MAX_POWER),
		}
	}
	componentDidUpdate(prevProps: Props) {
		if ((!prevProps.myValue && this.props.myValue) || (!this.props.myValue && prevProps.myValue)) {
			this.setState({ myLocalValue: (this.props.myValue || 0) * (LOCAL_RANGE / MAX_POWER) })
		}
	}

	handleChange = (value: number) => {
		this.setState({ myLocalValue: value })
	}

	handleChangeComplete = () => {
		const visibleValue = this.state.myLocalValue / (LOCAL_RANGE / MAX_POWER) // The local value is scaled on the local range but the server only cares about the step value
		const roundedValue = Math.round(visibleValue) // round to nearest integer value
		this.props.sendMessage('POWER', { power: roundedValue })
	}

	render() {
		return (
			<PowerStation>
				<div>
					<ThrottleSlider
						max={LOCAL_RANGE}
						steps={MAX_POWER}
						value={this.state.myLocalValue}
						onChange={this.handleChange}
						onChangeComplete={this.handleChangeComplete}
					/>
				</div>
				<div className="view">
					<PowerProgress completionTimerId={this.props.completionTimerId} />
					<PowerGauge />
				</div>
			</PowerStation>
		)
	}
}

function PowerProgress({ completionTimerId }: { completionTimerId: ?string }) {
	const iconSize = 40
	return (
		<TimerContainer>
			<div
				css={`
					margin-left: -${iconSize}px;
				`}>
				{completionTimerId ? (
					<MdBatteryChargingFull color={PRIMARY_GREEN} size={iconSize} />
				) : (
					<MdBatteryAlert color={RED} size={iconSize} />
				)}
			</div>
			<DeltaClockProgressBar
				timerId={completionTimerId}
				height="20px"
				progressColor={PRIMARY_GREEN}
			/>
		</TimerContainer>
	)
}

/**
 * Determines the status of the power gauge
 * @param {number} target
 * @param {number} actual
 */
function getStatus(target: number, actual: number): PowerGaugeStatus {
	if (actual >= target + POWER_STATION_DELTA) {
		return POWER_GAUGE_STATUS.OVER_TARGET
	}
	if (actual <= target - POWER_STATION_DELTA) {
		return POWER_GAUGE_STATUS.UNDER_TARGET
	}
	return POWER_GAUGE_STATUS.ON_TARGET
}

function PowerGauge() {
	const { averagePower, targetPower } = useSelector(getStudentPowerData)
	const status = getStatus(targetPower, averagePower)
	const rotation = (averagePower / MAX_POWER) * 180

	return (
		<CircleContainer>
			<Circle>
				<CircleContent>
					<Speedometer targetValue={targetPower} />
					<Positioned>
						<Dial status={status} rotation={rotation} />
						<PowerDiv>
							<h1>{averagePower.toFixed(1)}</h1>
							<h3>POWER LEVEL</h3>
						</PowerDiv>
					</Positioned>
				</CircleContent>
			</Circle>
		</CircleContainer>
	)
}

const CircleContainer = styled.div`
	flex: 1;
	display: flex;
	align-items: center;
`
const Positioned = styled.div`
	position: absolute;
	top: 55%;
	left: 50%;
	width: 100%;
	transform: translate(-50%, -50%);
`

const Circle = styled.div`
	position: relative;
	background-color: ${EMPTY_SPACE};
	margin: 0 auto;
	height: 60vh;
	width: 60vh;
	border-radius: 50%;
`
const CircleContent = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
`

const TimerContainer = styled.div`
	display: flex;
	width: 70%;
	padding: 12px;
	align-items: center;
`

const mapStateToProps = getStudentPowerData

const mapDispatchToProps = {
	sendMessage,
}

export default (connect(mapStateToProps, mapDispatchToProps)(Power): (
	props: $Shape<{||}>
) => React$Node)

const PowerDiv = styled.div.attrs({ className: 'text-center' })`
	width: fit-content;
	margin: 0 auto;
	h1,
	h3 {
		color: ${ACCENT_GREEN} !important;
		margin: 0;
	}
	h1 {
		font-size: 3rem;
	}
`
const PowerStation = styled.div`
	display: grid;
	height: 100%;
	grid-template-columns: 30% 1fr;
	grid-template-rows: 100%;
	grid-gap: 30px;
	padding: 30px;
	.view {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
`
