import React from 'react'
import Modal from 'react-modal'
import { MODAL_Z_INDEX } from '../../constants/styles'

export const FRAME_WRAPPER_ID = 'Frame-wrapper'
export const MAIN_ROOT_ID = 'Main-root'
export const MODAL_APP_ELEMENT_FOR_OUTER_FRAME = 'Modal-App-Element-for-outer-frame'
export const MODAL_APP_ELEMENT_FOR_FRAME = 'Modal-App-Element-for-frame'

Modal.defaultStyles = {
	overlay: {
		...Modal.defaultStyles.overlay,
		zIndex: MODAL_Z_INDEX,
		position: 'absolute',
	},
	content: {
		...Modal.defaultStyles.content,
		zIndex: MODAL_Z_INDEX,
		top: '45%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		minWidth: '300px',
		maxWidth: '80%',
		fontSize: '2em',
		borderRadius: '10px',
		padding: '24px',
	},
}

/**
 * A wrapper around react-modal that sets the appElement and parentSelector.
 * @param {boolean} frameContent - If true, the modal will be contained within the station frame. Otherwise, it will be contained within the entire app.
 */
export default function ReactModal({
	frameContent = false,
	..._props
}: {
	frameContent?: boolean,
}): React$Node {
	const props: {
		appElement?: Element | null,
		ariaHideApp?: boolean,
		parentSelector?: () => HTMLElement | null,
	} = {
		..._props,
	}
	if (!props.appElement) {
		if (
			document.getElementById(MAIN_ROOT_ID) &&
			document.getElementById(MODAL_APP_ELEMENT_FOR_OUTER_FRAME)
		) {
			props.parentSelector = () => document.getElementById(MAIN_ROOT_ID)
			props.appElement = document.getElementById(MODAL_APP_ELEMENT_FOR_OUTER_FRAME)
		}
	}
	if (frameContent) {
		props.parentSelector = () => document.getElementById(FRAME_WRAPPER_ID)
		props.appElement = document.getElementById(MODAL_APP_ELEMENT_FOR_FRAME)
	}
	if (!props.appElement) {
		props.ariaHideApp = false
	}
	return <Modal {...props} />
}
