import React, { forwardRef, type AbstractComponent } from 'react'
import styled from 'styled-components'
import type { LaserProps as Props } from './upgradeLookup'

export const SIZE = 120

function Laser(props: Props, ref): React$Node {
	return (
		<Svg viewBox="0 0 157.44 345.12" width={SIZE} {...props} ref={ref}>
			<path
				className="laser-1"
				d="M116.23,83.58,86,277.35a7.31,7.31,0,0,1-14.44,0L41.23,83.58a38,38,0,1,1,75-11.72A38.69,38.69,0,0,1,116.23,83.58Z"
			/>
			<path
				className="laser-2"
				d="M106.23,76.58,84,241.05a5.34,5.34,0,0,1-10.59,0L51.23,76.58a27.75,27.75,0,1,1,55-7.43A28.43,28.43,0,0,1,106.23,76.58Z"
			/>
		</Svg>
	)
}
export default (forwardRef(Laser): AbstractComponent<Props, Element>)

const Svg = styled.svg`
	.laser-1 {
		fill: #ffe19f;
	}

	.laser-2 {
		fill: #fbffd2;
	}
`
