// @flow
import createReducer from '../createReducer'
import {
	getStudent,
	getActiveStudents,
	getIsCulminatingMoment,
	classQuestionVotingComplete,
} from '../selectors/sharedSelectors'
import { getTraining } from '../selectors/jrPlusState/training'
import {
	STUDENT_DETAILS,
	DID_USE_TOUCH,
	JR_FULL_STATE,
	JR_PLUS_FULL_STATE,
	SWITCH_STATION,
	SET_MISSION_SERVER_URL,
	ESSENTIAL_DATA,
	SET_VOLUME,
	SHOW_MODAL,
	UPDATE_OPTIONAL_DATA,
} from '../actionTypes'
import {
	getIsTrainingFromMission,
	getPhaseFromMission,
	getStudentName as getTheStudentName,
	getIsPausedFromMission,
	getScreenFromMission,
	getConnectedStudents as getMissionToolkitConnectedStudents,
	getDocumentBeingGraded,
	getCurrentCollaborativeCulminatingMoment,
} from '@mission.io/mission-toolkit'
import {
	MISSION_PHASES,
	MISSION_TYPES,
	COLLABORATIVE_CULMINATING_MOMENT,
} from '@mission.io/mission-toolkit/constants'
import config from '../../config'
import type {
	MissionEvent,
	MissionType,
	JuniorPlusMissionFullState,
	JuniorMissionFullState,
	SimulationLeaderboard,
	CollaborativeCulminatingMomentScreen,
	CollaborativeCulminatingMomentScreenStep,
	Screen,
	StudentWithPoints,
} from '@mission.io/mission-toolkit'
import type { StationEnum, MissionPhaseType } from '../../types'
import type { ReduxStore } from '../rootReducer'
import type { Reducer } from '../createReducer'
import { useSelector } from 'react-redux'

export const JUNIOR = MISSION_TYPES.K_THROUGH_3
export const JUNIOR_PLUS = MISSION_TYPES.FOUR_PLUS

// Action Creators
const SET_LOGIN_ERROR = 'SET_LOGIN_ERROR'
const SET_SHOULD_REDIRECT_TO_SURVEY_ON_MISSION_END = 'SET_SHOULD_REDIRECT_TO_SURVEY_ON_MISSION_END'
export const SHOW_SCREEN_EXTENSION_GUIDE = 'SHOW_SCREEN_EXTENSION_GUIDE'

type DidUseTouchAction = {| type: 'DID_USE_TOUCH' |}
type SwitchStationAction = {|
	type: 'SWITCH_STATION',
	payload: { stationId: StationEnum, isProgrammatic: boolean },
|}
type SetMissionServerUrlAction = {|
	type: 'SET_MISSION_SERVER_URL',
	payload: string,
|}

export type SetVolumeAction = { type: 'SET_VOLUME', payload: { overall: number } }

type UiConfigurations = {| SHOW_SCREEN_EXTENSION_GUIDE?: boolean |}

type SetShowModalAction = {
	type: 'SHOW_MODAL',
	payload: {
		name: $Keys<UiConfigurations>,
		show: boolean,
		params?: { [key: string]: any },
	},
}

export type LoginError = { type: 'USER_NOT_ALLOWED', redirectUrl: string }
export type SetLoginErrorAction = {
	type: 'SET_LOGIN_ERROR',
	payload: ?LoginError,
}

type SetShouldRedirectToSurveyOnMissionEndAction = {
	type: 'SET_SHOULD_REDIRECT_TO_SURVEY_ON_MISSION_END',
	payload: {
		shouldRedirectToSurvey: boolean,
	},
}

type UpdateOptionalDataAction = {
	type: 'UPDATE_OPTIONAL_DATA',
	payload: {|
		leaderboard?: SimulationLeaderboard,
	|},
}

export function didUseTouch(): DidUseTouchAction {
	return {
		type: DID_USE_TOUCH,
	}
}

export function switchStation(
	stationId: StationEnum,
	options?: { isProgrammatic: boolean } = { isProgrammatic: false }
): SwitchStationAction {
	return {
		type: SWITCH_STATION,
		payload: { stationId, isProgrammatic: options.isProgrammatic },
	}
}

export function setMissionServerUrl(missionServerUrl: string): SetMissionServerUrlAction {
	return {
		type: SET_MISSION_SERVER_URL,
		payload: missionServerUrl,
	}
}

export function setVolume(vol: number): SetVolumeAction {
	return { type: SET_VOLUME, payload: { overall: vol } }
}

export function setShowModal(name: $Keys<UiConfigurations>, show?: boolean): SetShowModalAction {
	return { type: SHOW_MODAL, payload: { name, show: !!show } }
}

export function setLoginError(error: ?LoginError): SetLoginErrorAction {
	return {
		type: SET_LOGIN_ERROR,
		payload: error,
	}
}

/**
 * export function setShouldRedirectToSurveyOnMissionEnd - change the destination to redirect to when the mission ends to the survey for the mission
 *
 * @param  {boolean} shouldRedirectToSurvey - true if this is the webpage should redirect to the survey on mission end, false otherwise
 *
 * @return {SetShouldRedirectToSurveyOnMissionEndAction} the action to update the redux store
 */
export function setShouldRedirectToSurveyOnMissionEnd(
	shouldRedirectToSurvey: boolean
): SetShouldRedirectToSurveyOnMissionEndAction {
	return {
		type: SET_SHOULD_REDIRECT_TO_SURVEY_ON_MISSION_END,
		payload: { shouldRedirectToSurvey },
	}
}

export type PendingStudent = {|
	name: string,
	pendingRequestId: string,
	nameIsNaughty: boolean,
|}

// Reducer

export type GeneralStore = {
	student: ?{
		id: string,
		name: string,
	},
	isUsingTouch: boolean,
	station: ?StationEnum,
	type: ?MissionType,
	missionServerUrl: ?string,
	essentialData: ?{
		missionEvents?: MissionEvent[] | null,
		missionId?: string | null,
		missionTitle?: string | null,
		classTitle?: string | null,
		// Students who have not yet been approved by the teacher
		pendingStudents?: Array<PendingStudent>,
		isTrainingSimulation?: boolean,
		allowGeneratingAnalytics?: boolean,
	},
	loginError: ?LoginError,
	shouldRedirectToSurveyOnMissionEnd: boolean,
	overallVolume: number,
	uiConfigurations: UiConfigurations,
	optionalData: {|
		leaderboard: ?SimulationLeaderboard,
	|},
}

/**
 * Gets the initial state for the `general` redux store
 */
export function getInitialState(): GeneralStore {
	return {
		student: null,
		isUsingTouch: false,
		station: null,
		type: null,
		missionServerUrl: null,
		essentialData: null,
		loginError: null,
		shouldRedirectToSurveyOnMissionEnd: false,
		overallVolume: 100,
		uiConfigurations: {
			SHOW_SCREEN_EXTENSION_GUIDE: false,
		},
		optionalData: {
			leaderboard: null,
		},
	}
}

type SetEssentialDataAction = {
	type: 'ESSENTIAL_DATA',
	payload: {|
		classTitle?: string,
		missionEvents?: MissionEvent[],
		missionId?: string,
		missionTitle?: string,
		pendingStudents?: Array<PendingStudent>,
		isTrainingSimulation?: boolean,
		allowGeneratingAnalytics?: boolean,
	|},
}

export default (createReducer<GeneralStore>(getInitialState(), {
	[STUDENT_DETAILS]: (state, { payload: { id, name } }) => {
		return {
			...state,
			student: {
				...state.student,
				name,
				id,
			},
		}
	},
	[DID_USE_TOUCH]: state => {
		return {
			...state,
			isUsingTouch: true,
		}
	},
	[JR_FULL_STATE]: state => {
		return {
			...state,
			type: JUNIOR,
		}
	},
	[JR_PLUS_FULL_STATE]: state => {
		return {
			...state,
			type: JUNIOR_PLUS,
		}
	},
	[SWITCH_STATION]: (state, { payload: { stationId } }) => {
		return {
			...state,
			station: stationId,
		}
	},
	[SET_MISSION_SERVER_URL]: (state, { payload: missionServerUrl }) => {
		return {
			...state,
			missionServerUrl,
		}
	},
	[ESSENTIAL_DATA]: (state, action: SetEssentialDataAction) => {
		return {
			...state,
			essentialData: { ...state.essentialData, ...action.payload },
		}
	},
	[SET_VOLUME]: (state, action: SetVolumeAction) => {
		return {
			...state,
			overallVolume: action.payload.overall,
		}
	},
	[SHOW_MODAL]: (state, action: SetShowModalAction) => {
		return {
			...state,
			uiConfigurations: {
				...state.uiConfigurations,
				[action.payload.name]: action.payload.show,
			},
		}
	},
	[SET_LOGIN_ERROR]: (state, action: SetLoginErrorAction) => {
		return {
			...state,
			loginError: action.payload,
		}
	},
	[SET_SHOULD_REDIRECT_TO_SURVEY_ON_MISSION_END]: (
		state,
		action: SetShouldRedirectToSurveyOnMissionEndAction
	) => {
		return { ...state, shouldRedirectToSurveyOnMissionEnd: action.payload.shouldRedirectToSurvey }
	},
	[UPDATE_OPTIONAL_DATA]: (state, action: UpdateOptionalDataAction) => {
		return { ...state, optionalData: { ...state.optionalData, ...action.payload } }
	},
}): Reducer<GeneralStore>)

// Selectors

export function getStudentId(state: GeneralStore): ?string {
	return state.student ? state.student.id : null
}

/**
 * A hook for getting the id of the current student, aka the user if the user is a student
 */
export function useStudentId(): ?string {
	return useSelector(state => getStudentId(state.general))
}

export function getStudentName(state: ReduxStore, possibleStudentId?: ?string): ?string {
	const studentId = possibleStudentId || getStudentId(state.general)
	if (!studentId) {
		return null
	}
	return getTheStudentName(getMissionState(state), studentId)
}

export function isUsingTouch(state: GeneralStore): boolean {
	return state.isUsingTouch
}

export function isPaused(state: ReduxStore): boolean {
	const missionState = getMissionState(state)
	return getIsPausedFromMission(missionState)
}

/**
 * Returns true when the pause screen should be shown. Never returns true during training mode.
 */
export function shouldShowPauseScreen(state: ReduxStore): boolean {
	if (isTraining(state)) {
		return false
	}
	const missionState = getMissionState(state)
	if (!missionState || isMissionComplete(state)) return false
	return (
		isPaused(state) ||
		missionState.pausedState.state === 'SOFT_PAUSE' ||
		classQuestionVotingComplete(state) ||
		isCollaborativeCulminatingMomentPause(missionState)
	)
}

/**
 * Gets the current canvas id according to the current screen on the mission.
 * @param {ReduxStore} state
 * @returns {?string}
 */
export function getScreenCanvasId(state: ReduxStore): ?string {
	const launchpadScreen = getLaunchpadScreen(state)
	if (launchpadScreen?.type !== 'COLLABORATIVE_CULMINATING_MOMENT_SCREEN') {
		return
	}
	return launchpadScreen.canvasId
}

/**
 * getCollaborativeCulminatingMomentStep - selector for getting the current collaborative culminating moment step
 *
 * @param {ReduxStore} state - the current state of the redux store
 *
 * @return {?CollaborativeCulminatingMomentScreenStep}
 */
export function getCollaborativeCulminatingMomentStep(
	state: ReduxStore
): ?CollaborativeCulminatingMomentScreenStep {
	const launchpadScreen = getLaunchpadScreen(state)
	if (launchpadScreen?.type !== 'COLLABORATIVE_CULMINATING_MOMENT_SCREEN') {
		return
	}
	return launchpadScreen.currentStep
}

/**
 * getLaunchpadScreen - selector for getting the current launchpad screen
 *
 * @param {ReduxStore} state - the current state of the redux store
 *
 * @return {?Screen}
 */
export function getLaunchpadScreen(state: ReduxStore): ?Screen {
	const missionState = getMissionState(state)
	if (!missionState) {
		return
	}
	return getScreenFromMission(missionState)
}

/**
 * getDocumentIdBeingGraded - get the id of the document currently being graded
 *
 * @param {ReduxStore} state - the current redux state
 *
 * @return {?string} - the id of the document currently being graded (or null/undefined if there is no document being graded)
 */
export function getDocumentIdBeingGraded(state: ReduxStore): ?string {
	const missionState = getMissionState(state)
	if (!missionState) {
		return
	}
	return getDocumentBeingGraded(missionState)
}

/**
 * getCollaborativeCulminatingMomentScreen - get the current collaborative culminating moment screen
 *
 * @param {ReduxStore} state - the current redux state
 *
 * @return {?string} - the current collaborative culminating moment screen (or null/undefined if the current screen is not a collaborative culminating moment)
 */
export function getCollaborativeCulminatingMomentScreen(
	state: ReduxStore
): ?CollaborativeCulminatingMomentScreen {
	const missionState = getMissionState(state)
	if (!missionState) {
		return
	}
	return getCurrentCollaborativeCulminatingMoment(missionState)
}

/**
 * useStudentChoiceDuringCanvasVotePhase - a hook for getting the student's choice during the canvas vote phase
 * Since we do not know if the canvas the student is voting for will pass the next grading phase, we optimistically assume
 * the choice is correct.
 *
 * @param {string} studentId
 * @returns {?{index: boolean, correct: true}}
 */
export function useStudentChoiceDuringCanvasVotePhase(
	studentId: string
): ?{ index: number, correct: true } {
	return useSelector(state => {
		const collaborativeCulminatingMomentScreen = getCollaborativeCulminatingMomentScreen(state)
		const collaborativeVotePhase = collaborativeCulminatingMomentScreen?.currentStep
		if (
			collaborativeCulminatingMomentScreen &&
			collaborativeVotePhase &&
			collaborativeVotePhase.status === COLLABORATIVE_CULMINATING_MOMENT.SCREEN_STATUS.VOTING
		) {
			const votedDocumentIds = collaborativeVotePhase.votes.students[studentId]?.documentIds
			const mostRecentId = votedDocumentIds?.[votedDocumentIds.length - 1]
			const index = collaborativeVotePhase.qualifiedDocuments.findIndex(
				({ documentId }) => documentId === mostRecentId
			)
			if (index === -1) {
				return null
			}
			return { index, correct: true }
		}
	})
}

/**
 * getConnectedStudents - a selector for getting the currently connected students
 *
 * @param {ReduxStore} state - the current state of the redux store
 *
 * @return {?{[string]: StudentWithPoints}}
 */
export function getConnectedStudents(
	state: ReduxStore
): ?{
	[string]: StudentWithPoints,
} {
	const missionState = getMissionState(state)
	if (!missionState) {
		return
	}
	return getMissionToolkitConnectedStudents(missionState)
}

/**
 * Tells whether the user's screen should be paused for a collaborative culminating moment.
 */
function isCollaborativeCulminatingMomentPause(
	state: JuniorMissionFullState | JuniorPlusMissionFullState
): boolean {
	const screen = state.launchpad.screen
	return (
		screen?.type === 'COLLABORATIVE_CULMINATING_MOMENT_SCREEN' &&
		screen.currentStep.status !== COLLABORATIVE_CULMINATING_MOMENT.SCREEN_STATUS.EDITING &&
		screen.currentStep.status !== COLLABORATIVE_CULMINATING_MOMENT.SCREEN_STATUS.VOTING &&
		screen.currentStep.status !== COLLABORATIVE_CULMINATING_MOMENT.SCREEN_STATUS.GRADING_ALL &&
		screen.currentStep.status !== COLLABORATIVE_CULMINATING_MOMENT.SCREEN_STATUS.GRADING_UNHIDDEN &&
		screen.currentStep.status !== COLLABORATIVE_CULMINATING_MOMENT.SCREEN_STATUS.GRADING_HIDDEN
	)
}

export function getMissionState(
	state: ReduxStore
): ?(JuniorPlusMissionFullState | JuniorMissionFullState) {
	return state.general.type === JUNIOR
		? state.jrState
		: state.general.type === JUNIOR_PLUS
		? state.jrPlusState
		: null
}

export const getMissionEvents: (state: ReduxStore) => MissionEvent[] | null = state => {
	return state.general.essentialData?.missionEvents ?? null
}

/**
 * Selector to know if a mission is a jrPlus mission
 */
export function getIsJuniorPlus(state: ReduxStore): boolean {
	return state.general.type === JUNIOR_PLUS
}

export function isSurvey(state: ReduxStore): boolean {
	const missionPhase = getMissionPhase(state)
	return missionPhase === MISSION_PHASES.SURVEY_STANDBY
}

export function getMissionPhase(state: ReduxStore): ?MissionPhaseType {
	const missionState = getMissionState(state)
	return getPhaseFromMission(missionState)
}
export function getOverallVolume(state: ReduxStore): number {
	return state.general.overallVolume
}
export function getUIConfigurations(state: ReduxStore): UiConfigurations {
	return state.general.uiConfigurations
}

export function isMissionComplete(state: ReduxStore): boolean {
	const missionPhase = getMissionPhase(state)
	return [
		MISSION_PHASES.SURVEY_STANDBY,
		MISSION_PHASES.REVIEW_RESULTS,
		MISSION_PHASES.CLOSED,
	].includes(missionPhase)
}

export function isTraining(state: ReduxStore): boolean {
	return getIsTrainingFromMission(getMissionState(state))
}

export function isLoggingIn(state: ReduxStore): boolean {
	const missionPhase = getMissionPhase(state)
	return missionPhase === MISSION_PHASES.LOGIN
}

export function isEnded(state: ReduxStore): boolean {
	const missionPhase = getMissionPhase(state)
	return missionPhase === MISSION_PHASES.CLOSED
}

export function getStation(state: ReduxStore): ?StationEnum {
	if (config.devFlags?.stationDevIsWorkingOn) {
		return config.devFlags.stationDevIsWorkingOn
	}

	return state.general.station || state.staticData.defaultStation
}

export function getMissionType(state: ReduxStore): ?MissionType {
	return state.general.type
}

export function getActiveStudentCount(state: ReduxStore): number {
	return getActiveStudents(state).length
}

export function getStudentHealth(state: ReduxStore): number {
	const studentId = getStudentId(state.general)
	if (!studentId) return 0
	if (isTraining(state)) {
		const training = getTraining(state)
		return training ? training.health : 0
	}
	const student = getStudent(state, studentId)
	if (!student) return 0
	return student.health
}

export function hasSubmittedSurvey(state: ReduxStore): boolean {
	const missionState = getMissionState(state)
	if (!missionState || !missionState.surveys) return false
	return missionState.surveys.studentResponses.hasOwnProperty(getStudentId(state.general))
}

export function showFullScreen(state: ReduxStore): boolean {
	return isMissionComplete(state) || getIsCulminatingMoment(state)
}

export function getMissionServerUrl(state: ReduxStore): ?string {
	return state.general.missionServerUrl
}

export function getMissionId(state: ReduxStore): ?string {
	return state.general.essentialData?.missionId
}

/**
 * getShouldRedirectToMissionSurveyOnMissionEnd - true if page should redirect to mission survey on mission end, false otherwise
 *
 * @param  {ReduxStore} state - the current redux state
 *
 * @return {boolean} true if page should redirect to mission survey on mission end, false otherwise
 */
export function getShouldRedirectToMissionSurveyOnMissionEnd(state: ReduxStore): boolean {
	return state.general.shouldRedirectToSurveyOnMissionEnd
}

/**
 * Gets all pending students from the redux store
 */
export function getPendingStudents(state: ReduxStore): PendingStudent[] {
	return state.general.essentialData?.pendingStudents || []
}

/**
 * getIsTrainingSimulation - true if the current mission is using a training simulation, false otherwise
 *
 * @param {State} state - the current redux state
 * @returns {boolean} true if the current mission is using a training simulation, false otherwise
 */
export function getIsTrainingSimulation(state: ReduxStore): boolean {
	return Boolean(state.general?.essentialData?.isTrainingSimulation)
}

/**
 * getMissionCanGenerateAnalytics - true if it is possible to generate analytics for the current mission, false otherwise
 *
 * @param {State} state - the current redux state
 * @returns {boolean}
 */
export function getMissionCanGenerateAnalytics(state: ReduxStore): boolean {
	return state.general?.essentialData?.allowGeneratingAnalytics ?? true
}

/**
 * getLeaderboard - get the leaderbaord for the current mission's simulation
 *
 * @param  {ReduxStore} state - the current redux state
 *
 * @returns ?SimulationLeaderboard
 */
export function getLeaderboard(state: ReduxStore): ?SimulationLeaderboard {
	return state.general.optionalData.leaderboard
}
