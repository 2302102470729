import React from 'react'
import { useSelector } from 'react-redux'
import Main from './Main'
import { IconContext } from 'react-icons'
import { Provider } from 'react-redux'
import Login from './components/LoginPage'
import * as Sentry from '@sentry/react'
import store from './store'
import AppSetup, {
	getNewMissionIOUrlFromInfinidIOUrl,
	isConnectedToMission,
	isWantingToRunMissionAsControl,
	isWantingToRunMissionAsStudent,
} from './components/AppSetup'
import { WebsiteUrlChange } from './components/WebsiteUrlChange'
import { getLoginError } from './store/selectors/sharedSelectors'
import config from './config'
import './index.css'
import ReportCrashViaWebsocket, {
	FallbackComponent,
} from './components/ReportCrashViaWebsocket.jsx'

const USE_LOGIN = true

function App(): React$Node {
	const connected = isConnectedToMission()
	const isConnectedAsStudent = isWantingToRunMissionAsStudent()
	const isConnectedAsController = isWantingToRunMissionAsControl()
	const loginError = useSelector(getLoginError)
	const newMissionIoUrl = getNewMissionIOUrlFromInfinidIOUrl(window.location.href)

	return (
		<div className="App" id="App-root">
			{newMissionIoUrl ? (
				<WebsiteUrlChange newUrl={newMissionIoUrl} />
			) : loginError || (!connected && USE_LOGIN) ? (
				<Login />
			) : (
				<AppSetup>
					{isConnectedAsStudent ? (
						<Main />
					) : isConnectedAsController ? (
						<Main isController />
					) : (
						<div>Could not determine connection type</div>
					)}
				</AppSetup>
			)}
		</div>
	)
}

/**
 * This is the top level component for the app. It wraps App in ErrorBoundaries and Providers
 */
export default function AppWithProviders(): React$Node {
	return (
		<ErrorBoundary>
			<Provider store={store}>
				{/* ReportCrashViaWebsocket is a transient error boundary (ie. it will continue the error propagation up to the parent). It has to be located under the redux provider since we use redux-websocket for websocket communication.   */}
				<ReportCrashViaWebsocket>
					<IconContext.Provider value={{ className: 'infinid-react-icons' }}>
						<App />
					</IconContext.Provider>
				</ReportCrashViaWebsocket>
			</Provider>
		</ErrorBoundary>
	)
}

/**
 * Wraps its children in an ErrorBoundaries component
 * Does not wrap children in the the ErrorBoundary if the dev flag `hideSentryErrorFallbackPage` is true
 */
function ErrorBoundary({ children }) {
	if (config.devFlags?.hideSentryErrorFallbackPage) return children
	return (
		<Sentry.ErrorBoundary
			// FallbackComponent must be inside this anonymous function because it uses hooks
			fallback={() => <FallbackComponent />}
			showDialog>
			{children}
		</Sentry.ErrorBoundary>
	)
}
