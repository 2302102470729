import React, { useState, type AbstractComponent } from 'react'
import { animated, useSpring } from '@react-spring/web'

const DEFAULT_RAY_WIDTH = 100
const DEFAULT_RAY_HEIGHT = 80

const BEAM_DURATION = 1000
export const TARGET_BEAM_DURATION = 2250

type Props = {
	beam: boolean,
	beamWidth: number,
	beamHeight: number,
	retractingTarget: boolean,

	onPositionUpdate: () => void,
	onBeamRetracting: () => void,
	onBeamRetracted: () => void,
}

export default (React.forwardRef(Beam): AbstractComponent<Props, Element>)

/**
 * The Beam component is a visual representation of the tractor beam. It is a SVG element that is animated to extend and retract.
 * This component receives a forwarded ref from its parent and applies it to the animated SVG element so that the parent can access the beams location.
 * @param {boolean} props.beam - a boolean value that determines if the beam is active
 * @param {number} props.beamWidth - the max width of the beam when it is fully extended.
 * @param {number} props.beamHeight - the max height of the beam when it is fully extended.
 * @param {boolean} props.retractingTarget - a boolean value that determines if the beam is retracting.
 * @param {() => void} props.onPositionUpdate - a callback function that is called when the beam's position is updated.
 * @param {() => void} props.onBeamRetracting - a callback function that is called when the beam starts retracting.
 * @param {() => void} props.onBeamRetracted - a callback function that is called when the beam is fully retracted.
 */
function Beam(
	{
		beam,
		beamWidth,
		beamHeight,
		retractingTarget,
		onPositionUpdate,
		onBeamRetracting,
		onBeamRetracted,
	}: Props,
	ref
): React$Node {
	const [isRetracting, setIsRetracting] = useState(false)

	const retracted = { width: DEFAULT_RAY_WIDTH, height: DEFAULT_RAY_HEIGHT, opacity: 0.1 }
	const extended = { width: beamWidth, height: beamHeight, opacity: 1 }

	let animationProps = {}
	if (isRetracting) {
		animationProps = {
			from: extended,
			to: retracted,
			config: {
				duration: retractingTarget ? TARGET_BEAM_DURATION : BEAM_DURATION,
			},
		}
	} else {
		animationProps = {
			from: retracted,
			to: extended,
			config: {
				duration: BEAM_DURATION,
			},
		}
	}

	const style = useSpring({
		onRest: () => {
			setIsRetracting(!isRetracting)

			if (!isRetracting) {
				onBeamRetracting()
			} else {
				onBeamRetracted()
			}
		},
		onChange: () => onPositionUpdate(),
		...animationProps,
	})

	if (beam) {
		return <Ray {...style} ref={ref} />
	}
	return <Ray width={DEFAULT_RAY_WIDTH} height={DEFAULT_RAY_HEIGHT} opacity={0} ref={ref} />
}

const Ray = React.forwardRef(
	({ width, height, opacity = 1 }: { width: number, height: number, opacity?: number }, ref) => {
		return (
			<animated.svg
				ref={ref}
				width={width}
				height={height}
				viewBox="0 0 100 80"
				preserveAspectRatio="none"
				className=""
				style={opacity !== null ? { opacity } : {}}>
				<defs>
					<linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
						<stop offset="0%" style={{ stopColor: 'rgb(162, 232, 255)', stopOpacity: 1 }} />
						<stop offset="40%" style={{ stopColor: 'rgb(162, 232, 255)', stopOpacity: 0.5 }} />
						<stop offset="100%" style={{ stopColor: 'rgb(162, 232, 255)', stopOpacity: 0.05 }} />
					</linearGradient>
				</defs>
				<polygon points="0,20 100,0 100,80 0,60" fill="url(#gradient)" />
			</animated.svg>
		)
	}
)
