import React from 'react'
import { useSpring, animated } from '@react-spring/web'
import { useSelector } from 'react-redux'
import { clamp } from '../../utility/functions'
import { getTimer, NO_PROGRESS_TIMER } from '../../store/selectors/sharedSelectors'

export default function PercentText({ timerId, ...props }: { timerId: string }): React$Node {
	const { delayLeft, isPaused, currentProgress } = useSelector(
		state => getTimer(state, timerId) || NO_PROGRESS_TIMER
	)
	const spring = useSpring({
		to: { progress: 100 },
		from: { progress: clamp(currentProgress * 100, 0, 100) },
		config: { duration: isPaused ? Infinity : delayLeft },
		reset: true,
	})

	return (
		<div {...props}>
			<animated.span>
				{spring.progress.to(n => {
					return n.toFixed()
				})}
			</animated.span>
			%
		</div>
	)
}
