import React, { useState } from 'react'
import { useSpringRef, animated, useSpring, useChain } from '@react-spring/web'
import { useCurrentUpgrade, type UpgradeData } from '../StationUpgradeOverlay/upgrades'
import styled, { keyframes } from 'styled-components/macro'
import { IoIosLock } from 'react-icons/io'

const timeCorrectTextStaysVisible = 800
const timeForStationaryUpgradeIconAnimation = 800
const timeBeforeUpgradeIconLeavesScreen = 900

export default function WithUpgrade(props: {
	onFinish: () => void,
	isCorrect: boolean,
}): React$Node {
	const upgrade = useCurrentUpgrade()
	if (!upgrade) {
		return null
	}
	return <UpgradeAnimation {...props} upgrade={upgrade} />
}

/**
 * A component that runs a series of animations to demonstrate that an upgrade has been unlocked for a jr plus station.
 * This animation is expected to be run within the Question Modal after the student answers their question and correct score is received for
 * said question. When the animation is complete, the prop provided, onFinish will be called.
 * @param {cb~onFinish} onFinish a callback function for when the animation is over.
 */
function UpgradeAnimation({
	onFinish,
	upgrade,
	isCorrect,
}: {
	onFinish: () => void,
	upgrade: UpgradeData,
	isCorrect: boolean,
}): React$Node {
	const [leaving, setLeaving] = useState(false)
	const [flash, setFlash] = useState(false)
	const correctTextSpringRef = useSpringRef()
	const disappearTextRef = useSpringRef()
	const slideInRef = useSpringRef()
	const fadeRef = useSpringRef()
	const correctTextSpringProps = useSpring({
		from: { scale: 0 },
		to: { scale: 1 },
		ref: correctTextSpringRef,
	})
	const disappearText = useSpring({
		from: { opacity: 1 },
		to: { opacity: 0 },
		delay: isCorrect ? timeCorrectTextStaysVisible : 0,
		ref: disappearTextRef,
	})
	const slideInProps = useSpring({
		from: { left: leaving ? '0%' : '-100%' },
		to: { left: leaving ? '100%' : '0%' },
		delay: leaving ? timeBeforeUpgradeIconLeavesScreen : 0,
		onRest: () => {
			if (leaving) {
				onFinish()
			} else {
				setFlash(true)
			}
		},
		ref: slideInRef,
	})
	const fadeProps = useSpring({
		from: { out: 1, in: 0 },
		to: { out: 0, in: 1 },
		onRest: () => {
			setFlash(false)
			setLeaving(true)
		},
		config: { duration: timeForStationaryUpgradeIconAnimation },
		ref: fadeRef,
	})

	const upgradeAnimations = [slideInRef, fadeRef]
	const correctAnswerAnimations = [correctTextSpringRef, disappearTextRef]
	const allAnimations = isCorrect
		? [...correctAnswerAnimations, ...upgradeAnimations]
		: upgradeAnimations
	useChain(allAnimations)
	const { icon, IconRenderer, name: upgradeName } = upgrade
	return (
		<Container>
			<AnimatedCorrectTextContainer style={disappearText}>
				<animated.h1
					style={{
						transform: correctTextSpringProps.scale.to(o => `scale(${o})`),
					}}>
					Correct!
				</animated.h1>
			</AnimatedCorrectTextContainer>
			<AnimatedUpgradeContainer style={slideInProps}>
				<animated.h4 style={{ opacity: fadeProps.in }} className="mb-4">
					{upgradeName} unlocked!
				</animated.h4>
				<SvgPositioner>
					<UpgradeSvg viewBox="0 0 100 100">
						<defs>
							<filter id="softGlow">
								<feGaussianBlur stdDeviation="5" result="blurred" />
								<feMerge>
									<feMergeNode in="blurred" />
									<feMergeNode in="SourceGraphic" />
								</feMerge>
							</filter>
						</defs>

						<IconRenderer
							active={flash || leaving}
							icon={icon}
							className={flash && 'render-flash'}
						/>
						<AnimatedLock size={30} color="white" style={{ opacity: fadeProps.out }} />
					</UpgradeSvg>
				</SvgPositioner>
			</AnimatedUpgradeContainer>
		</Container>
	)
}

const flash = keyframes`
	0% {
		fill: #5C5189;
		stroke: #5C5189;
	}
	50% {
		fill: #ffffff;
		stroke: #ffffff;
	}
`
const Container = styled.div`
	max-width: 100%;
	overflow-x: hidden;
	height: 100%;
	max-height: 100%;
	position: relative;
`

const UpgradeSvg = styled.svg`
	border: 4px solid aqua;
	border-radius: 16px;
	background-color: #2b2f6d;
	flex: 1;
	width: fit-content;
	height: 100%;

	.render-flash {
		filter: url(#softGlow);
		* {
			animation: ${flash} ${timeForStationaryUpgradeIconAnimation}ms ease-in-out;
		}
	}
`

const AnimatedUpgradeContainer = styled(animated.div)`
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	padding-bottom: 1.33em;
`
const AnimatedCorrectTextContainer = styled(animated.div)`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 10% 0;
`

const AnimatedLock = animated(IoIosLock)

const SvgPositioner = styled.div`
	flex: 1 1 0;
`
