import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { sendMessage } from '../../store/stores/webSocket'
import { getDefenseGroupHits as getGroupHits } from '../../store/selectors/jrState'
import { getStudentId } from '../../store/stores/general'
import ShooterGame from '../../components/ShooterGame'
import type { HitResults } from '../../components/ShooterGame/ShooterGame'

import { getStationActivity } from '../../utility/selectors'
import { PROJECTILE_TYPE } from '../../components/ShooterGame/Projectile.jsx'

const BULLET_WIDTH = 60
const BULLET_HEIGHT = 15

const ENEMY_RIGHT_OFFSET = 170
const ENEMY_DRIFT = 170
const ENEMY_DRIFT_SPEED = 1129

/**
 * The K-3 Defense Station. Students must click on the canvas to fire a laser at incoming targets.
 */
export default function Defense(): React$Node {
	const dispatch = useDispatch()

	const { status, active, myHits, groupHits } = useSelector(state => {
		const studentId = getStudentId(state.general)
		const { status, studentHits } = state.jrState.stationData.defense
		const groupHits = getGroupHits(state)
		const myHits = studentId && studentHits.hasOwnProperty(studentId) ? studentHits[studentId] : 0

		return {
			status,
			active: getStationActivity(state).defense,
			myHits,
			groupHits,
		}
	})
	const onHit = ({ enemyLocation }: HitResults) => {
		const pointLocation = enemyLocation
			? {
					x: (enemyLocation.left + enemyLocation.right) / 2,
					y: (enemyLocation.top + enemyLocation.bottom) / 2,
			  }
			: { x: '50%', y: '50%' }
		dispatch(sendMessage('DEFENSE', { hit: true }, { location: pointLocation }))
	}
	const onMiss = () => {
		dispatch(sendMessage('DEFENSE', { hit: false }))
	}
	return (
		<ShooterGame
			active={active}
			onHit={onHit}
			onMiss={onMiss}
			enemyRightOffset={ENEMY_RIGHT_OFFSET}
			enemyDrift={ENEMY_DRIFT}
			enemyDriftSpeed={ENEMY_DRIFT_SPEED}
			projectileWidth={BULLET_WIDTH}
			projectileHeight={BULLET_HEIGHT}
			projectileType={PROJECTILE_TYPE.LASER}
			imageType={status === 'OFFLINE' ? 'ASTEROID' : status}>
			<div className="absolute top-10 right-12 text-primary-200 text-left text-lg">
				<div>Your Hits: {myHits}</div>
				<div>Team Hits: {groupHits}</div>
			</div>
		</ShooterGame>
	)
}
