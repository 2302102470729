import React from 'react'
import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import { ACCENT_BLUE } from '../../../constants/styles'
import { getCurrentRoomIdOfRobot } from '../../../store/selectors/jrPlusState/decks'

type Props = {
	roomId: string,
	path?: string,
	points?: string,
	transform?: string,
	selectable: boolean,
	onClick?: () => void,
	hasAlert: boolean,
}

const PathStyle: StyledType<> = styled.path`
	fill: ${({ $hasAlert }) => ($hasAlert ? 'red' : ACCENT_BLUE)};
	opacity: 0.75
		${({ $selectable }) =>
			$selectable &&
			css`
				opacity: 1;
				filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
				&:hover {
					cursor: pointer;
					stroke: ${ACCENT_BLUE};
					stroke-width: 4px;
				}
			`};
	${({ $selected }) =>
		$selected &&
		css`
			opacity: 1;
		`}
`

const PolygonStyle: StyledType<> = styled.polygon`
	fill: ${({ $hasAlert }) => ($hasAlert ? 'red' : ACCENT_BLUE)};
	opacity: 0.75;
	${({ $selectable }) =>
		$selectable &&
		css`
			opacity: 1;
			filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.4));
			&:hover {
				cursor: pointer;
				stroke: ${ACCENT_BLUE};
				stroke-width: 4px;
			}
		`};
	${({ $selected }) =>
		$selected &&
		css`
			opacity: 1;
		`}
`

export default function Room({
	roomId,
	path,
	transform,
	selectable,
	onClick,
	points,
	hasAlert,
}: Props): React$Node {
	const selected = useSelector(getCurrentRoomIdOfRobot) === roomId
	if (path) {
		return (
			<PathStyle
				className={roomId}
				d={path}
				transform={transform}
				$selectable={selectable}
				$selected={selected}
				$hasAlert={hasAlert}
				onClick={onClick}
			/>
		)
	} else if (points) {
		return (
			<PolygonStyle
				className={roomId}
				points={points}
				$selectable={selectable}
				$selected={selected}
				$hasAlert={hasAlert}
				onClick={onClick}
			/>
		)
	}
	return <path />
}
