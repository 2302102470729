// @flow
import React, { useState, useRef, useEffect, forwardRef, useCallback } from 'react'
import { useSelector } from 'react-redux'
import Pinpoint from '../../../images/Crosshair.svg'
import type { StudentDefenseTarget } from '@mission.io/mission-toolkit'
import { Background, Upgrades } from './assets'
import Blast from './Blast'
import { getTheta } from '../../../utility/functions'
import * as easings from 'd3-ease'
import {
	BASE_HEIGHT,
	HIT_ANIMATION_TIME,
	DESTROYED_ANIMATION_TIME,
	TARGET_STATUS,
	RESPAWN_DELAY_TIME,
	type TargetStatus,
	FORGET_DESTROYED_IDS_TIME,
} from './constants'
import Target from './Target'
import { getCollision, getLaserCollision } from './helpers'
import { doesCollide } from '../../Communication/collision'
import 'styled-components/macro'
import { animated, useSpring } from '@react-spring/web'
import { CANVAS_HEIGHT, MIDDLE_LINE } from './assets/Background'
import {
	getStudentTargets,
	getTargetMap,
	getWeaponIndex,
} from '../../../store/selectors/jrPlusState/defense'

const CURSOR_OFFSET_WIDTH = 31.78
const CURSOR_OFFSET_HEIGHT = 1.76

type Props = {
	onHit: ({ x: number, y: number }, string) => void,
	onMiss: () => void,
}

// Determines if a ClientRect as obtained from a DOM element has left the screen
const isOffScreen = rect => {
	return (
		rect.left + rect.width < 0 ||
		rect.top + rect.height < 0 ||
		rect.left > window.innerWidth ||
		rect.top > window.innerHeight
	)
}

// Status of the projectile.
type ProjectileStatus = 'FIRE' | 'HIT' | 'INACTIVE'

/**
 *  Defense station. Students will shoot targets using projectile weapons
 * @returns {React$Node}
 */
export default function ShooterGame(props: Props): React$Node {
	const targetMap = useSelector(getTargetMap)
	const upgradeIndex = useSelector(getWeaponIndex)
	const [blasterAngle, setBlasterAngle] = useState(90)
	// This ref will be attached to the svg circle element within in the blaster that will be considered the base of rotation for the blaster.
	const pointOfRotationRef = useRef<?Element>()
	const targetsRef = useRef({})
	const [targetsStatus, _setTargetsStatus] = useState({})
	const targetsStatusRef = useRef({})
	const setTargetsStatus = (id: string, status: ?TargetStatus) => {
		if (!status) {
			_setTargetsStatus(state => {
				if (state[id] !== TARGET_STATUS.DESTROYED) {
					return state
				}
				const newState = { ...state }
				delete newState[id]
				delete targetsStatusRef.current[id]
				return newState
			})
			return
		}
		_setTargetsStatus(state => ({ ...state, [id]: status }))
		targetsStatusRef.current[id] = status
	}

	const targets = useStudentTargets(setTargetsStatus)
	const [firingBullet, setFiringBullet] = useState(false)
	const bulletStatus = useRef<?ProjectileStatus>()

	const { onHit, onMiss } = props

	/**
	 * Sets the angle to aim for the blaster.
	 * @param {Event} e
	 */
	const setAim = e => {
		const cursorX = e.clientX + CURSOR_OFFSET_WIDTH
		const cursorY = e.clientY + CURSOR_OFFSET_HEIGHT
		if (pointOfRotationRef.current) {
			const rect = pointOfRotationRef.current.getBoundingClientRect()
			// $FlowFixMe[prop-missing] x and y do exist in the ClientRect
			const center = { x: rect.x + rect.width / 2, y: rect.y + rect.height / 2 }

			setBlasterAngle(getTheta(cursorX, cursorY, center))
		} else {
			console.error('set aim without reference to center')
		}
	}

	/**
	 * Cleans up the projectile status when the projectile has finished its movement.
	 */
	const finishProjectile = useCallback(() => {
		if (firingBullet) {
			if (bulletStatus.current === 'FIRE') {
				onMiss()
			}
			setFiringBullet(false)
			bulletStatus.current = 'INACTIVE'
		}
	}, [onMiss, firingBullet])
	/**
	 * Determines if the projectile/bullet is colliding with any target.
	 * Also checks if the projectile has left the screen, and cleans it up if so.
	 */
	const checkImpact = useCallback(
		(bulletRef: { current?: Element | null }, type: 'LASER' | 'PROJECTILE') => {
			if (!bulletRef.current || !targetsRef.current) {
				return
			}
			const bulletElement = bulletRef.current
			if (isOffScreen(bulletElement.getBoundingClientRect())) {
				finishProjectile()
				return
			}
			let bulletCollision
			if (type === 'LASER') {
				bulletCollision = getLaserCollision(bulletElement, pointOfRotationRef.current)
			} else {
				bulletCollision = getCollision(bulletElement)
			}
			for (const targetId in targetsRef.current) {
				const status = targetsStatusRef.current[targetId]
				if (status === TARGET_STATUS.HIT || status === TARGET_STATUS.DESTROYED) {
					continue
				}
				const targetElement = targetsRef.current[targetId]
				if (doesCollide(bulletCollision, getCollision(targetElement))) {
					bulletStatus.current = TARGET_STATUS.HIT
					const targetRect = targetElement.getBoundingClientRect()
					onHit({ x: targetRect.left, y: targetRect.top }, targetId)
					setTargetsStatus(targetId, TARGET_STATUS.HIT)
					setTimeout(() => {
						finishProjectile()
						if (targetsStatusRef.current[targetId] === TARGET_STATUS.HIT) {
							setTargetsStatus(targetId, TARGET_STATUS.RECOVERED)
						}
					}, HIT_ANIMATION_TIME)
				}
			}
		},
		[onHit, finishProjectile]
	)

	// Logic required to fire a projectile.
	const fire = useCallback(() => {
		bulletStatus.current = 'FIRE'
		setFiringBullet(true)
	}, [])

	useEffect(() => {
		// when upgrade changes, update firing bullet
		setFiringBullet(false)
	}, [upgradeIndex])

	return (
		<>
			<div
				css={`display: flex; align-items: end; justify-content: center; height: 100%; width: 100%; cursor: url(${Pinpoint}), crosshair};`}>
				<Background css="width: 100%; max-height: 100%; overflow: visible;" onClick={setAim}>
					{targets.map(target => {
						const fullTargetData = targetMap[target.targetId]
						if (!fullTargetData) {
							return null
						}
						return (
							<Target
								instanceId={target.instanceId}
								key={target.instanceId}
								target={fullTargetData}
								ref={el => (targetsRef.current[target.instanceId] = el)}
								status={targetsStatus[target.instanceId]}
								reset={() => {
									setTargetsStatus(target.instanceId, TARGET_STATUS.ACTIVE)
								}}
							/>
						)
					})}
					<AnimatedBlaster
						upgrade={upgradeIndex}
						ref={pointOfRotationRef}
						angle={blasterAngle}
						checkImpact={checkImpact}
						fire={fire}
						firingBullet={firingBullet}
						finish={finishProjectile}
					/>
					<BaseStation upgrade={upgradeIndex} />
				</Background>
			</div>
		</>
	)
}

function BaseStation(props: { upgrade: number }) {
	const { base: Base } = Upgrades[props.upgrade]
	return <Base height={BASE_HEIGHT} y={CANVAS_HEIGHT} />
}

/**
 * A react component that controls the blaster and the projectile animations in the shooter game.
 */
const AnimatedBlaster = forwardRef(function _AnimatedBlaster(
	props: {
		upgrade: number,
		angle: number,
		fire: () => void,
		checkImpact: ({ current?: Element | null }, type: 'LASER' | 'PROJECTILE') => ?boolean,
		firingBullet: boolean,
		finish: () => void,
	},
	pointOfRotationRef
) {
	const { upgrade = 0, angle: targetAngle, fire, checkImpact, firingBullet, finish } = props
	const { blaster: Blaster, pointOfRotation, blasterDimensions } = Upgrades[upgrade]
	const [currentAngle, setCurrentAngle] = useState(targetAngle)

	const currentAngleRef = useRef(currentAngle)
	currentAngleRef.current = currentAngle

	const fireRef = useRef(fire)
	const speedRef = useRef(Upgrades[upgrade].blasterMovementSpeed)

	const [{ angle }, springRef] = useSpring(() => ({
		from: { angle: currentAngleRef.current },
	}))

	useEffect(() => {
		// When the upgrade changes, update blaster speed
		speedRef.current = Upgrades[upgrade].blasterMovementSpeed
	}, [upgrade])

	useEffect(() => {
		springRef.start({
			config: {
				duration: (Math.abs(currentAngleRef.current - targetAngle) / speedRef.current) * 1000,
				easing: easings.easeCubic,
			},
			from: { angle: currentAngleRef.current },
			to: { angle: targetAngle },
			onRest: () => {
				if (fireRef.current) {
					fireRef.current()
				}
				setCurrentAngle(currentAngleRef.current)
			},
			onChange: ({ value: { angle } }) => {
				setCurrentAngle(angle)
			},
		})
	}, [targetAngle, springRef])

	const Wrapper = useCallback(
		(props: { children: React$Node }): React$Node => {
			const x = pointOfRotation.x
			const y = pointOfRotation.y
			return (
				<animated.g
					transform={angle.to(o => {
						return `rotate(${90 - o} ${x} ${y})`
					})}>
					{props.children}
				</animated.g>
			)
		},
		[pointOfRotation.x, pointOfRotation.y, angle]
	)
	const BLASTER_WIDTH = 148
	const BLASTER_HEIGHT = Math.floor((blasterDimensions.h / blasterDimensions.w) * BLASTER_WIDTH)
	const OFFSET_Y = Math.floor((pointOfRotation.y / blasterDimensions.h) * BLASTER_HEIGHT)
	const OFFSET_X = Math.floor((pointOfRotation.x / blasterDimensions.w) * BLASTER_WIDTH)
	const svgProperties = {
		height: BLASTER_HEIGHT,
		width: BLASTER_WIDTH,
		x: MIDDLE_LINE - OFFSET_X,
		y: CANVAS_HEIGHT - OFFSET_Y,
		style: { overflow: 'visible' },
	}

	return (
		<>
			{/* bullet projectiles should not change with the blaster after the projectile is fired, so it is not rendered as a child of the blaster */}
			{Upgrades[upgrade].blastType === 'PROJECTILE' && (
				<svg viewBox={`0 0 ${blasterDimensions.w} ${blasterDimensions.h}`} {...svgProperties}>
					<Blast
						checkImpact={ref => checkImpact(ref, 'PROJECTILE')}
						upgrade={upgrade}
						show={firingBullet}
						onFinish={finish}
						projectileAngle={targetAngle}
					/>
				</svg>
			)}
			{/* laser projectiles should follow and line up with the blaster as it changes angles, so the blast will be a child of the blaster. */}
			<Blaster {...svgProperties} ref={pointOfRotationRef} transformationWrapper={Wrapper}>
				{Upgrades[upgrade].blastType === 'LASER' && (
					<Blast
						checkImpact={ref => checkImpact(ref, 'LASER')}
						upgrade={upgrade}
						show={firingBullet}
						onFinish={finish}
					/>
				)}
			</Blaster>
		</>
	)
})

/**
 * Gets the targets that are available to shoot for a given student. Includes extra logic which sets target status based on if the target has been
 * destroyed and then removes the target from the target array after a certain amount of animation time for the target to be destroyed.
 * @param {(id: string, status: ?TargetStatus) => void} setTargetsStatus accepts the instance id of the target and the status that the target should be updated to, if the status is "null" the id should be forgotten if the id's current status is destroyed
 * @returns {Array<StudentDefenseTarget>}
 */
const useStudentTargets = (setTargetsStatus: (id: string, status: ?TargetStatus) => void) => {
	const targets = useSelector(getStudentTargets)
	const prevTargetsRef = useRef<?Array<StudentDefenseTarget>>()
	const [localTargets, setLocalTargets] = useState([])
	useEffect(() => {
		// If student targets don't exist yet, do nothing.
		if (!targets) {
			prevTargetsRef.current = null
			return
		}
		// If targets exist and they haven't been initialized yet, set all targets and continue
		if (!prevTargetsRef.current) {
			setLocalTargets(targets)
		} else {
			const prevTargets = prevTargetsRef.current

			const prevInstanceMap = prevTargets.reduce(
				(acc, target) => ({ ...acc, [target.instanceId]: true }),
				{}
			)
			const currentInstanceMap = {}
			const newTargets = []
			targets.forEach(target => {
				if (!prevInstanceMap[target.instanceId]) {
					setTargetsStatus(target.instanceId, TARGET_STATUS.ACTIVE)
					newTargets.push(target)
				}
				currentInstanceMap[target.instanceId] = true
			})

			// CHECK FOR TARGETS THAT HAVE BEEN DESTROYED
			const destroyedTargets = {}
			prevTargets.forEach(target => {
				if (!currentInstanceMap[target.instanceId]) {
					setTargetsStatus(target.instanceId, TARGET_STATUS.DESTROYED)
					destroyedTargets[target.instanceId] = true
				}
			})

			// DELAY ADDING RE-SPAWNED TARGETS
			if (newTargets.length) {
				setTimeout(() => {
					setLocalTargets(local => {
						return local.concat(newTargets)
					})
				}, DESTROYED_ANIMATION_TIME + RESPAWN_DELAY_TIME)
			}

			// DELAY REMOVING DESTROYED TARGETS
			const destroyedTargetIds = Object.keys(destroyedTargets)
			if (destroyedTargetIds.length) {
				setTimeout(() => {
					setLocalTargets(local => {
						return local?.filter(l => !destroyedTargets[l.instanceId]) || local
					})
				}, DESTROYED_ANIMATION_TIME)
				setTimeout(() => {
					// handle if a checkpoint restarts the current station (in which case all current ids will be the same as previous seen ids)
					destroyedTargetIds.forEach(instanceId => {
						setTargetsStatus(instanceId, null)
					})
				}, FORGET_DESTROYED_IDS_TIME)
			}
		}
		// Update the ref every render
		prevTargetsRef.current = targets
	}, [targets, setTargetsStatus])

	return localTargets
}
