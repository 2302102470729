import Confetti from 'react-confetti'
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components/macro'
import { hasSubmittedSurvey, isSurvey } from '../../store/stores/general'
import Survey from '../Survey'
import MissionReviewScreen from '../MissionReviewScreen/MissionReviewScreen'

export default function FinalDisplay(): React$Node {
	const showSurvey = useSelector(isSurvey)
	const hasSubmitted = useSelector(hasSubmittedSurvey)

	const [showConfetti, setShowConfetti] = useState(true)

	useEffect(() => {
		// we only want to show the confetti screen for about 5 seconds
		let confettiTimeout = null
		if (hasSubmitted || !showSurvey) {
			confettiTimeout = setTimeout(() => setShowConfetti(false), 5000)
		}
		return () => clearTimeout(confettiTimeout)
	}, [hasSubmitted, showSurvey])

	return showSurvey && !hasSubmitted ? (
		<Survey />
	) : showConfetti ? (
		<FinalDisplayStyle className="z-40 absolute top-0 left-0 flex-col bg-black w-full h-full flex justify-center items-center">
			<>
				<Confetti style={{ maxHeight: '100%' }} numberOfPieces={400} friction={1} />
				<>
					<h1>Congratulations!</h1>
					<h3>
						You
						{"'"}
						ve completed your mission!
					</h3>
				</>
			</>
		</FinalDisplayStyle>
	) : (
		<MissionReviewScreen />
	)
}

const FinalDisplayStyle = styled.div`
	h1,
	h3 {
		color: white;
		z-index: 50;
	}

	h1 {
		font-size: 3rem;
	}
	h3 {
		font-size: 1.5rem;
	}
`
