import React, { forwardRef, type AbstractComponent } from 'react'
import styled from 'styled-components'
import type { LaserProps as Props } from './upgradeLookup'

export const SIZE = 120

function Laser(props: Props, ref): React$Node {
	return (
		<Svg viewBox="0 0 167.76 167.76" width={SIZE} {...props} ref={ref}>
			<circle className="laser1-1" cx="83.85" cy="83.84" r="37.67" />
			<circle className="laser1-2" cx="83.85" cy="83.84" r="28.82" />
		</Svg>
	)
}

export default (forwardRef(Laser): AbstractComponent<Props, Element>)
const Svg = styled.svg`
	.laser1-1 {
		fill: #ffe19f;
	}

	.laser1-2 {
		fill: #fbffd2;
	}
`
