import React, { forwardRef, useMemo, type AbstractComponent, useEffect } from 'react'
import { CANVAS_HEIGHT, CANVAS_WIDTH } from './assets/Background'
import type { DefenseMovement, DefenseTarget } from '@mission.io/mission-toolkit'
import { DEFENSE_STATION } from '@mission.io/mission-toolkit/constants'
import { random } from '../../../utility/functions'
import styled, { keyframes, css } from 'styled-components/macro'
import {
	HIT_ANIMATION_TIME,
	type TargetStatus,
	DESTROYED_ANIMATION_TIME,
	TARGET_STATUS,
} from './constants'
import { useSpring, animated, config } from '@react-spring/web'

type Props = { target: DefenseTarget, status: TargetStatus, reset: () => void, instanceId: string }

/**
 * A Target element in the shooter game that follows a movement pattern and can be destroyed/damaged by the blaster.
 * @param {TargetType} target configuration value for the displayed target
 * @param {ElementRef} ref a reference to the target so we can track its movement and check to see if a projectile collides with it
 * @returns {React$Node}
 */
function Target({ target, status, reset, instanceId }: Props, ref): React$Node {
	const { width, height } = target.size
	const points = useMovementPattern(target.movement)
	const exitAnimation = target.exitAnimation

	const [{ xy, x, y }, api] = useSpring(() => ({
		config:
			target.movement.type === DEFENSE_STATION.MOVEMENT.RANDOM
				? config.molasses
				: { duration: 4000 },
		from: points[0],
		loop: { reverse: false },
		to: points,
		delay: random(0, 500),
	}))
	useEffect(() => {
		if (status === TARGET_STATUS.HIT) {
			api.pause()
		} else if (status === TARGET_STATUS.RECOVERED) {
			api.resume()
		}
	}, [api, status])

	return (
		<svg
			id={instanceId}
			height={CANVAS_HEIGHT}
			width={CANVAS_WIDTH}
			viewBox={`0 0 ${VIEW_BOX[0]} ${VIEW_BOX[1]}`}
			x={0}
			y={0}
			css="overflow: visible">
			<Image
				ref={ref}
				xlinkHref={target.image.url}
				alt="Enemy Target"
				height={height}
				width={width}
				x={x}
				y={y}
				$status={status}
				$exitAnimation={exitAnimation.type}
				style={{
					transformOrigin: xy.to(point => `${point.x}px ${point.y}px`),
				}}
				transform={`translate(${-width / 2}, ${-height / 2})`}
			/>
		</svg>
	)
}

export default (forwardRef(Target): AbstractComponent<Props, Element>)

const VIEW_BOX = [200, 100] // width height of viewbox
const INTERNAL_BOUNDS = [80, 40] // width height of internal rectangle (this is the space where the blaster exists)
const X_SPACE_BEFORE_INTERNAL_BOUND = (VIEW_BOX[0] - INTERNAL_BOUNDS[0]) / 2

// The canvas which a target can travel through is split into smaller rectangles to ensure that the random path which the
// robot travels along is evenly distributed across the canvas. CANVAS_RECTS is an array of those rectangles and their [x,y] point
// coordinates relative to the view box dimensions.
const CANVAS_RECTS = [
	[
		[0, 0],
		[X_SPACE_BEFORE_INTERNAL_BOUND, 0],
		[X_SPACE_BEFORE_INTERNAL_BOUND, VIEW_BOX[1]],
		[0, VIEW_BOX[1]],
	],
	[
		[X_SPACE_BEFORE_INTERNAL_BOUND, 0],
		[X_SPACE_BEFORE_INTERNAL_BOUND + INTERNAL_BOUNDS[0], 0],
		[X_SPACE_BEFORE_INTERNAL_BOUND + INTERNAL_BOUNDS[0], VIEW_BOX[1] - INTERNAL_BOUNDS[1]],
		[X_SPACE_BEFORE_INTERNAL_BOUND, VIEW_BOX[1] - INTERNAL_BOUNDS[1]],
	],
	[
		[X_SPACE_BEFORE_INTERNAL_BOUND + INTERNAL_BOUNDS[0], 0],
		[VIEW_BOX[0], 0],
		[VIEW_BOX[0], VIEW_BOX[1]],
		[VIEW_BOX[0] - X_SPACE_BEFORE_INTERNAL_BOUND, VIEW_BOX[1]],
	],
]

/**
 * Based on the movement type of the target, returns an array of point values that will be fed to the react-spring animation
 * for the target.
 * Note: We pass individual x, y fields and a combined `xy` field in order to get proper animations for the transformOrigin field on the target.
 * @param {DefenseMovement} movement a pattern that the target will move in.
 * @returns {Array<{ xy: { x: number, y: number }, x: number, y: number }>}
 */
const useMovementPattern = (
	movement: DefenseMovement
): Array<{ xy: { x: number, y: number }, x: number, y: number }> => {
	return useMemo(() => {
		let points = []
		switch (movement.type) {
			case DEFENSE_STATION.MOVEMENT.STATIC: {
				if (movement.location.type === 'DEFINED') {
					points = [{ x: movement.location.x, y: movement.location.y }]
				} else {
					points = [getRandomPointOnCanvas()]
				}
				break
			}
			case DEFENSE_STATION.MOVEMENT.STRAIGHT_LINE: {
				points = [
					...getRandomLineAcrossCanvas(),
					...getRandomLineAcrossCanvas(),
					...getRandomLineAcrossCanvas(),
				]
				break
			}
			default:
				points = new Array(4).fill([]).map(() => {
					const point = getRandomPointOnCanvas()
					return { x: point.x, y: point.y }
				})
				break
		}
		return points.map(point => ({ xy: point, x: point.x, y: point.y }))
	}, [movement])
}

/**
 * Returns a line segment that spans across the screen. Used to determine a random line
 * for the target to travel across.
 * @returns {Array<{x: number, y: number}>}
 */
const getRandomLineAcrossCanvas = () => {
	const offsetToExitScreenLeft = -10
	const offsetToExitScreenRight = 60
	const offsetToCreepAboveCanvas = -10
	const boundaryBeforeLineGetsToCloseToShip = Math.ceil(VIEW_BOX[1] / 2)

	const yBounds = { min: offsetToCreepAboveCanvas, max: boundaryBeforeLineGetsToCloseToShip }
	return [
		{ x: 0 + offsetToExitScreenLeft, y: random(yBounds.min, yBounds.max) },
		{ x: VIEW_BOX[0] + offsetToExitScreenRight, y: random(yBounds.min, yBounds.max) },
	]
}

/**
 * Gets a random point on the svg canvas. Ensures that when this function is
 * called multiple times consecutively, the random point will be in different sections of the canvas.
 * This function is used to create a random path through the canvas for a target.
 */
const getRandomPointOnCanvas = (function() {
	let iter = random(0, CANVAS_RECTS.length - 1)
	let inc = true
	return () => {
		const randomRect = CANVAS_RECTS[iter]
		if (iter + 1 === CANVAS_RECTS.length) {
			inc = false
		}
		if (iter === 0) {
			inc = true
		}

		iter = inc ? iter + 1 : iter - 1

		const xInRect = random(randomRect[0][0], randomRect[1][0])
		const yInRect = random(randomRect[1][1], randomRect[2][1])

		return { x: xInRect, y: yInRect }
	}
})()

const wobble = (width, height) => {
	const offsetX = -width / 2
	const offsetY = -height / 2
	return keyframes`

/* ----------------------------------------------
 * Generated by Animista on 2022-7-13 15:47:39
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

  0%,
  100% {
    transform: translate(${offsetX}px, ${offsetY}px);
  }
  15% {
    transform: translate(${offsetX - width * 0.25}px, ${offsetY}px) rotate(-6deg); 
  }
  30% {
    transform: translate(${offsetX + width * 0.125}px, ${offsetY}px) rotate(6deg);
  }
  45% {
    transform: translate(${offsetX - width * 0.125}px, ${offsetY}px) rotate(-3.6deg);
  }
  60% {
    transform: translate(${offsetX + width * 0.08}px, ${offsetY}px) rotate(2.4deg);
  }
  75% {
    transform: translate(${offsetX - width * 0.05}px, ${offsetY}px) rotate(-1.2deg);
  }

`
}

const HIT_RECOVERY_TIME = 500
const Image = styled(animated.image)`
	
	// HIT ANIMATION
	${({ width, height, $status }) =>
		$status === TARGET_STATUS.HIT &&
		css`
			animation: ${wobble(width, height)} ${HIT_ANIMATION_TIME - HIT_RECOVERY_TIME}ms both;
		`}

	// DESTROYED ANIMATION 
	${({ $status, $exitAnimation }) =>
		$status === TARGET_STATUS.DESTROYED &&
		($exitAnimation === DEFENSE_STATION.EXIT_ANIMATION.FADE_OUT
			? `transition: opacity ${DESTROYED_ANIMATION_TIME}ms ease; opacity: 0`
			: '')}
`
