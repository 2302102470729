import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components/macro'
import { APP_WHITE } from '../../constants/styles'
import Toggle from '../basics/Toggle'
import {
	setIsTextToSpeechEnabled,
	getTextToSpeechSettings,
	setTextToSpeechGender,
	getTranslationLanguage,
} from '../../store/stores/settings'
import { GENDER } from '../../constants/speechConstants'
import useSpeechSynthesis from '../../utility/useSpeechSynthesis'
import { FaShieldAlt as FaShield, FaMale, FaFemale } from 'react-icons/fa'
import GoogleTranslate from '../GoogleTranslate'
import config from '../../config'
import { isEnglishLanguage as fnIsEnglishLanguage } from '../../utility/functions'
import { BACKGROUND_PANEL } from '../Frame/JrPlusFrame/components/Toolkit/SharedColors'
export default function TextToSpeechSettings(): React$Node {
	const dispatch = useDispatch()
	const settings = useSelector(getTextToSpeechSettings)
	const translationLanguage = useSelector(getTranslationLanguage)
	const { supported } = useSpeechSynthesis()
	const isEnglishLanguage = fnIsEnglishLanguage(translationLanguage)

	useEffect(() => {
		const shouldTurnOffTextToSpeech = !supported || !isEnglishLanguage
		if (shouldTurnOffTextToSpeech) {
			dispatch(setIsTextToSpeechEnabled(false))
		}
	}, [dispatch, supported, isEnglishLanguage])

	return (
		<Wrapper>
			{!supported && (
				<>
					<ShieldIcon>
						<FaShield color={APP_WHITE} size={50} />
					</ShieldIcon>
					<h4>Your browser does not support text to speech</h4>
				</>
			)}
			{isEnglishLanguage && supported && (
				<>
					<Title>Text To Speech</Title>
					<ToggleWrapper>
						<Toggle
							id="text-to-speech-enabled"
							placeholder="Turn ON/OFF"
							checked={settings.isEnabled}
							onChange={() => {
								dispatch(setIsTextToSpeechEnabled(!settings.isEnabled))
							}}
						/>
					</ToggleWrapper>
					<h4 css="padding-top: 8px;">Voice</h4>
					<ToggleWrapper>
						<Toggle
							id="text-to-speech-gender"
							placeholder=""
							checked={settings.gender === GENDER.FEMALE}
							onChange={checked => {
								dispatch(setTextToSpeechGender(checked ? GENDER.FEMALE : GENDER.MALE))
							}}
							type={'square'}
							leftIcon={<FaMale color={APP_WHITE} />}
							rightIcon={<FaFemale color={APP_WHITE} />}
						/>
					</ToggleWrapper>
				</>
			)}
			{isEnglishLanguage && config.useGoogleTranslate && <Divider />}
			{config.useGoogleTranslate && (
				<div>
					<Title>Language</Title>
					<GoogleTranslate />
				</div>
			)}
		</Wrapper>
	)
}

const Title = styled.h3`
	text-align: left;
	padding-top: ${({ theme }) => theme.spacing3x} !important;
`
const Divider = styled.hr`
	margin: ${({ theme }) => theme.spacing2x};
	margin-top: ${({ theme }) => theme.spacing5x};
`

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	background-color: ${BACKGROUND_PANEL};
	height: 100%;

	h3 {
		margin: 16px;
		padding-top: 32px;
	}
	h4 {
		margin: 16px;
		padding-top: 32px;
		align-self: flex-start;
	}
`

const ToggleWrapper = styled.div`
	padding-left: 16px;
	padding-right: 16px;
`

const ShieldIcon = styled.div`
	margin: 16px;
	padding-top: 32px;
`
