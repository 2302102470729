import { getStudentId, getActiveStudentCount } from '../../stores/general'
import { forEachObject, clamp } from '../../../utility/functions'
import { getCommunicationMapObject } from './communication'
import { getTractorBeamMapObjects } from './tractorBeam'
import { getDefenseMapObjects } from './defense'
import { getPowerMapObject } from './power'
import { getTransporterMapObject } from './transporter'
import { getTrainingSensorData } from './training'
import { getScanTimeScale } from '@mission.io/mission-toolkit'
import { getMapInfoFromMission, getMapFromMission } from '@mission.io/mission-toolkit'
import type { ActionType } from '../../../juniorPlusStations/Sensor/Map/helpers/constants'
import type { ReduxStore } from '../../rootReducer'
import type { VisitedMap } from '../../../juniorPlusStations/Sensor/types'
import type {
	CollectedContentInfo as CollectedDatum,
	Map,
	ObjectInfo as ObjectInformation,
	MapObjectIds,
	ObjectData,
} from '@mission.io/mission-toolkit'

export type StationActionData = {
	allowed: boolean,
	complete: boolean,
	students: { [studentId: string]: boolean },
}

export type StudentStatus = {
	scanning?: string,
	otherAction?: {
		type: ActionType,
		mapObjectId: string,
	},
}

export type MapObjectInfo = {
	[objectId: string]: ObjectInformation,
}
export type MapInfo = {
	[mapId: string]: { objectInfo: MapObjectInfo },
}

export type StudentVotes = { [studentId: string]: number }

export type CollectedData = {
	[contentId: string]: CollectedDatum,
}

export type CollectedDataForView = {
	id: string,
	objectId: ?string,
	upVotes: number,
	downVotes: number,
	studentVotes: ?number,
	objectName: string,
	mapName: string,
	text: string,
	mapName: string,
	type: 'VIDEO' | 'IMAGE' | 'TEXT' | 'AUDIO',
	url: ?string,
	icon?: React$ComponentType<*>,
	mapObjectSubtitle?: ?string,
}

export type VisibleMapDataEntry = {
	data: ObjectData,
	mapObjectName?: string,
	mapObjectSubtitle?: string,
	mapObjectId?: string,
	mapName: string,
	Icon: React$ComponentType<{}>,
}

/**
 * Return a decimal number representing the scanning multiplier gained when
 * 'scanningStudentCount' students in a class of 'activeStudentCount' scan an object
 */
export function getMultiplier(scanningStudentCount: number, activeStudentCount: number): number {
	const scale = getScanTimeScale(scanningStudentCount, activeStudentCount)
	return scanningStudentCount / scale
}

export function getCurrentMapId(state: ReduxStore): ?string {
	const currentMap = getCurrentMap(state)
	return currentMap ? currentMap._id : null
}

export function getVisitedMaps(state: ReduxStore): Array<VisitedMap> {
	const trainingMap = getTrainingSensorData(state)?.map
	if (trainingMap) {
		return [{ id: trainingMap._id, name: trainingMap.name, visitedTime: 0 }]
	}
	return state.jrPlusState.stationData.sensor.visitedMaps
}

export function getUnInteractedDataCount(state: ReduxStore): number {
	const studentId = getStudentId(state.general)
	if (!studentId) {
		return 0
	}

	let unInteracted = 0
	const sensorData = state.jrPlusState.stationData.sensor
	forEachObject(sensorData.collectedData, (datum: CollectedDatum) => {
		if (!sensorData.studentVotes.contentId[datum._id]?.studentId.hasOwnProperty(studentId)) {
			unInteracted++
		}
	})
	return unInteracted
}

export function getObjectsWithActiveStations(
	state: ReduxStore
): { [mapObjectId: string]: ActionType[] } {
	const mapObjects = {}

	function buildMap(mapObjectIds: ?MapObjectIds, actionType: ActionType) {
		if (mapObjectIds) {
			if (!mapObjects[mapObjectIds.objectId]) {
				mapObjects[mapObjectIds.objectId] = []
			}
			mapObjects[mapObjectIds.objectId].push(actionType)
		}
	}
	buildMap(getCommunicationMapObject(state), 'CONTACT')
	getTractorBeamMapObjects(state).forEach(ids => buildMap(ids, 'TRACTOR_BEAM'))
	getDefenseMapObjects(state).forEach(ids => buildMap(ids, 'TARGET'))
	buildMap(getTransporterMapObject(state), 'TRANSPORT')
	buildMap(getPowerMapObject(state), 'POWER')

	return mapObjects
}

export function getTotalRequiredStudentsForAction(state: ReduxStore, type: ActionType): number {
	if (type === 'SCAN') return 0

	const activeCount = getActiveStudentCount(state)
	const total = clamp(Math.ceil(activeCount / 2), 1, activeCount) || 1
	return total
}

/**
 * Given the student id and the state, determines the status of the student in regards to what map objects
 * they are currently acting upon. A student can do up to 2 actions at a time: A scan action, and one other.
 * @param {ReduxStore} state
 * @param {string} studentId
 *
 * @return studentStatus
 */
export function getStudentStatus(state: ReduxStore, studentId: string): ?StudentStatus {
	const mapId = getCurrentMapId(state)
	if (!mapId) {
		return null
	}
	const singleMapInfo = getCurrentMapInfo(state)
	const objectInfo = singleMapInfo && singleMapInfo.objectInfo
	if (!objectInfo) {
		return null
	}
	const studentStatus = {}
	forEachObject(objectInfo, (eachObjectInfo, objectId) => {
		if (studentStatus.scanning && studentStatus.otherAction) {
			return
		}
		forEachObject(eachObjectInfo.actionData, (actionForObject, actionType) => {
			if (actionForObject.students[studentId]) {
				if (actionType === 'SCAN') {
					studentStatus.scanning = objectId
				} else {
					studentStatus.otherAction = {
						type: ((actionType: any): ActionType), // keys for actionData are all ActionTypes
						mapObjectId: objectId,
					}
				}
			}
		})
	})
	return studentStatus
}

export function getCurrentMap(state: ReduxStore): ?Map {
	return getMapFromMission(state.jrPlusState)
}

export function getCurrentMapInfo(state: ReduxStore): ?{ objectInfo: MapObjectInfo } {
	const mapInfo = getMapInfoFromMission(state.jrPlusState)
	const currentMapId = getCurrentMapId(state)
	return currentMapId && mapInfo ? mapInfo[currentMapId] : null
}

export function getBackgroundImage(state: ReduxStore): ?string {
	const currentMap = getCurrentMap(state)
	if (!currentMap) return null
	return currentMap.backgroundImage
}
