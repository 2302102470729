import React, { Suspense, lazy } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { sendMessage } from '../../store/stores/webSocket'
import styled from 'styled-components/macro'
import Toolkit, { toolkitTabTypes } from '../Frame/JrPlusFrame/components/Toolkit/Toolkit'
import { getMissionCategory } from '../../store/stores/staticData'
import { LEADERBOARD_STATUS, MISSION_CATEGORIES } from '@mission.io/mission-toolkit/constants'
import GoFullHeader from '../GoFullHeader'
import { OneClickButton } from '../basics/Buttons'
import { getConnectedStudents, getLeaderboard, getStudentId } from '../../store/stores/general'
import type { SimulationLeaderboardElement } from '@mission.io/mission-toolkit'
import LargeCircleURL from '../../images/LoadingWheel.svg'
import { getPopulatedStudentTeamWithAllStudents } from '../../store/selectors/jrPlusState/teams'
import { getStudentsWithPoints } from '../../store/selectors/sharedSelectors'
import classnames from 'classnames'
import { TAILWIND_SCREEN_SIZES } from '../../constants/styles'
import { useWindowWidth } from '../../utility/hooks.jsx'
const JoinVoiceChatModal = lazy(() => import('../VideoChat/JoinVoiceChatModal'))
const RemoteStatusBar = lazy(() => import('../Frame/remote'))

const TAILWIND_MD_SCREEN = parseInt(TAILWIND_SCREEN_SIZES.md, 10)
const BACKGROUND_VIDEO_URL = 'https://resources-cdn.mission.io/public/videos/background.webm'

export default function LoginDisplay({
	goFullScreen,
	isController,
}: {
	goFullScreen: ?() => void,
	isController?: boolean,
}): React$Node {
	const missionCategory = useSelector(getMissionCategory)
	const isRemote = missionCategory && missionCategory !== MISSION_CATEGORIES.ON_SITE
	const remoteTabs = [toolkitTabTypes.REMOTE_SETTINGS, toolkitTabTypes.TEXT_TO_SPEECH_SETTINGS]
	const noRemoteTabs = [toolkitTabTypes.TEXT_TO_SPEECH_SETTINGS]
	const dispatch = useDispatch()
	const leaderboard = useSelector(getLeaderboard)
	const studentId = useSelector(store => getStudentId(store.general))
	const studentTeam = useSelector(getPopulatedStudentTeamWithAllStudents)
	const connectedStudents = Object.keys(useSelector(getConnectedStudents) ?? {}).length
	const totalStudents = Object.keys(useSelector(state => getStudentsWithPoints(state, false)) ?? {})
		.length
	const windowWidth = useWindowWidth()
	const isTeacherMobile = windowWidth < TAILWIND_MD_SCREEN && isController

	return (
		<>
			{goFullScreen && <GoFullHeader goFull={goFullScreen} />}
			{isRemote ? <Toolkit tabs={remoteTabs} /> : <Toolkit tabs={noRemoteTabs} />}
			{isRemote && (
				<Suspense fallback={<div>Loading ...</div>}>
					<JoinVoiceChatModal />
				</Suspense>
			)}
			<HexagonBackground autoPlay={true} loop={true} muted={true} src={BACKGROUND_VIDEO_URL} />
			<PageContainer
				className={classnames(
					leaderboard ? 'md:gap-8 gap-4' : 'md:gap-y-8 gap-y-4',
					'grid grid-cols-1 md:grid-cols-2 h-dvh w-screen p-4 md:p-8 relative overflow-auto grid-rows-[1fr,auto]'
				)}>
				{studentTeam && !isTeacherMobile ? (
					<div
						className={classnames(
							leaderboard ? 'col-span-1' : 'col-span-2',
							`bg-[var(--card-background)] rounded-lg flex flex-col md:overflow-auto `
						)}>
						<div className="flex justify-between items-center p-3 h-[4.75em] bg-primary-700 w-full rounded-t-lg">
							<h2 className="md:text-3xl text-xl">{studentTeam.name.toUpperCase()}</h2>
							<TeamIconWrapper>
								{studentTeam.icon ? (
									<img src={studentTeam.icon} alt={studentTeam.type} />
								) : (
									studentTeam.type
								)}
							</TeamIconWrapper>
						</div>
						<div className="flex-grow flex-shrink flex-basis-0 relative p-3 space-y-3 ">
							<IconBackground $icon={studentTeam.icon} />

							<div className="relative space-y-3">
								{studentTeam.members.map((student, index) => (
									<TeamMember
										key={index}
										$isMe={studentId === student.id}
										$isConnected={student.connected}>
										<span data-sentry-mask>
											{student.firstName} {student.lastName}
										</span>
										{student.id === studentId ? <>&nbsp;(You)</> : null}
									</TeamMember>
								))}
							</div>
						</div>
					</div>
				) : null}
				{leaderboard ? (
					<div
						className={classnames(
							'bg-[var(--card-background)] p-4 rounded-lg flex flex-col overflow-y-hidden',
							studentTeam && !isTeacherMobile ? 'col-span-1' : 'col-span-2'
						)}>
						<StyledLeaderboardHeader>
							<h2 className="md:text-3xl text-xl">{leaderboard.name} Leaderboard</h2>
						</StyledLeaderboardHeader>
						<ElementWrapper className="flex-1">
							{(leaderboard.status ?? LEADERBOARD_STATUS.AVAILABLE) ===
							LEADERBOARD_STATUS.AVAILABLE ? (
								leaderboard.order.map((element, index) => (
									<LeaderboardElement element={element} index={index} key={index} />
								))
							) : (
								<div className="mt-8 text-white">Leaderboard is disabled for this mission</div>
							)}
						</ElementWrapper>
					</div>
				) : null}

				<div
					className={classnames(
						`bg-[var(--card-background)] md:col-span-2 rounded-lg p-4 md:p-8 h-fit w-full`,
						!isTeacherMobile && 'flex justify-between items-center'
					)}
					style={isTeacherMobile && !leaderboard ? { margin: 'auto' } : null}>
					{isController ? (
						<>
							<h2 className="md:text-3xl text-xl">
								{connectedStudents} / {totalStudents} students connected
							</h2>
							<OneClickButton
								className="w-full md:w-fit md:mt-0 mt-2"
								onClick={() => {
									dispatch(sendMessage('START_MISSION'))
								}}>
								Start Mission
							</OneClickButton>
						</>
					) : (
						<>
							<h2 className="md:text-3xl text-xl">PREPARING FOR LAUNCH...</h2>
							<CirclePositioner>
								<LargeCircle src={LargeCircleURL} />
								<FloatingCircle viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
									<circle cx="50" cy="50" r="50" />
								</FloatingCircle>
							</CirclePositioner>
						</>
					)}
				</div>

				{isRemote && (
					<div css="height: 17.5%; bottom: 0px; position: absolute; width: 100%;">
						<Suspense fallback={<div>Loading ...</div>}>
							<RemoteStatusBar isJuniorPlus={false} hidePlayer={false} />
						</Suspense>
					</div>
				)}
			</PageContainer>
		</>
	)
}

/**
 * LeaderboardElement - display an element for the leaderboard
 *
 * @param  {Object} props -the react props
 * @param  {SimulationLeaderboardElement} props.element - the leaderboard element to display
 * @param  {number} props.index - the index of the element in the leaderboard
 */
function LeaderboardElement({
	element,
	index,
}: {
	element: SimulationLeaderboardElement,
	index: number,
}) {
	let moreInfo = []
	if (element.className) {
		moreInfo.push(element.className)
	}
	if (element.teacherName) {
		moreInfo.push(element.teacherName)
	}

	let moreInfoString = moreInfo.length > 0 ? `(${moreInfo.join(' - ')})` : ''
	return (
		<div className="text-white flex items-center justify-between w-full bg-primary-700 my-2 rounded py-2 px-3 text-[1.25em]">
			<div>
				{index + 1}. {element.schoolName}
				{element.state ? `, ${element.state}` : ''} {moreInfoString}
			</div>
			<div>{Math.floor(element.score).toLocaleString()}</div>
		</div>
	)
}

const CARD_HIGHLIGHT = '#676495'
const CARD_BACKGROUND = '#14163d80'
const CIRCLE_HIGHLIGHT = 'aqua'
const HIGHLIGHT_TEXT = `text-shadow: ${CIRCLE_HIGHLIGHT} 1px 0 10px;`

const TeamMember = styled.div`
	color: white;
	font-size: 1.5em;
	padding: var(--spacing2x);
	border-radius: var(--spacing);
	flex-grow: 1;
	max-height: 4.5em;
	display: flex;
	justify-content: center;
	align-items: center;
	${({ $isMe }) => ($isMe ? HIGHLIGHT_TEXT : '')};
	${({ $isConnected }) =>
		$isConnected
			? `background-color: ${CARD_HIGHLIGHT};`
			: `background-color: var(--color-primary-700); opacity:.7;`}
`

const CirclePositioner = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	position: relative;
	width: 9em;
`

const LargeCircle = styled.img`
	height: 4em;
	animation-name: rotate;
	animation-duration: 2s;
	animation-direction: normal;
	animation-iteration-count: infinite;
	animation-timing-function: linear;

	@keyframes rotate {
		0% {
			transform: rotate(0deg);
		}

		50% {
			transform: rotate(180deg);
		}

		100% {
			transform: rotate(360deg);
		}
	}
`

const FloatingCircle = styled.svg`
	height: 30%;
	position: absolute;
	top: auto;
	left: 0%;
	animation-name: circle;
	animation-duration: 2s;
	animation-direction: normal;
	animation-iteration-count: infinite;
	animation-timing-function: linear;

	circle {
		fill: ${CIRCLE_HIGHLIGHT};
	}

	@keyframes circle {
		0% {
			left: 0%;
			transform: scale(1);
			opacity: 1;
		}

		25% {
			transform: scale(0.5);
			opacity: 0.5;
		}

		50% {
			left: calc(100% - 1.25em);
			transform: scale(1);
			opacity: 1;
		}

		75% {
			transform: scale(1.5);
		}

		100% {
			left: 0%;
			transform: scale(1);
			opacity: 1;
		}
	}
`

const IconBackground = styled.div`
	position: absolute;
	top: 10%;
	bottom: 10%;
	right: 10%;
	left: 10%;
	background-image: url(${({ $icon }) => $icon});
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	opacity: 0.5;
`

const TeamIconWrapper = styled.div`
	max-height: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	flex: 0.25 1 0;

	@media only screen and (max-width: 1500px) {
		padding-right: var(--spacing3x);
		padding-left: var(--spacing3x);
	}

	@media only screen and (max-width: 1200px) {
		display: none;
	}

	> * {
		height: 36px;
	}
`

const PageContainer = styled.div`
	--card-background: ${CARD_BACKGROUND};
`

const StyledLeaderboardHeader = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	color: white;
	min-height: 2.375em;
`

const ElementWrapper = styled.div`
	overflow-y: scroll;
	overflow-x: hidden;
	scrollbar-color: white white;

	&::-webkit-scrollbar {
		width: 8px;
		background: Transparent;
	}

	&::-webkit-scrollbar-thumb {
		background: ${CARD_HIGHLIGHT};
		-webkit-border-radius: 1ex;
		-webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
	}

	&::-webkit-scrollbar-corner {
		background: Transparent;
	}
`

const HexagonBackground = styled.video`
	position: absolute;
	top: 0;
	z-index: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
`
