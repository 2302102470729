import classnames from 'classnames'
import React from 'react'

const Name = ({
	value,
	style,
	className,
}: {
	value: string,
	style?: {},
	className?: string,
}): React$Node => {
	let name = value
	if (value.length > 14) {
		const words = value.split(' ')
		if (words.length > 1) {
			const lastInitial = words[words.length - 1][0]
			const firstWords = words.splice(0, words.length - 1)
			const firstName = firstWords.join(' ')
			name = `${firstName} ${lastInitial}.`
		}
	}
	return (
		<div style={style} className={classnames('w-fit', className)}>
			<h2 className="m-0" data-sentry-mask>
				{name}
			</h2>
		</div>
	)
}

export default Name
