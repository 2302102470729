import React, { Component } from 'react'
import Progress from '../../../components/Progress'
import classnames from 'classnames'

import './Wifi.css'

type Props = {
	targetReception: ?number,
	currentReception: number,
}

export default class Wifi extends Component<Props> {
	render(): React$Node {
		const { currentReception, targetReception } = this.props
		const step = (targetReception || 0) / 10
		const progress = [
			scale(currentReception, 0, step),
			scale(currentReception, step, 3 * step),
			scale(currentReception, 3 * step, 6 * step),
			scale(currentReception, 6 * step, 10 * step),
		]
		return (
			<div className={classnames(progress[3] === 100 && 'complete', 'wifi')}>
				{progress.map((progress, index) => {
					return <Bar key={index} className={`progress-${index + 1}`} progress={progress} />
				})}
			</div>
		)
	}
}

function Bar({ className, progress }: { className: string, progress: number }) {
	return (
		<div className={className + ' bar' + (progress === 100 ? ' complete' : '')}>
			<Progress progress={progress} vertical animate />
		</div>
	)
}

/**
 * clamps the value between min and max and then
 * scales that value to the precentage between
 * min and and max
 *
 * @param {number}	value - the value to scale
 * @param {number}	min - the mininum cutoff (0%)
 * @param {number}	max - the maximum cutoff (100%)
 *
 * @return {number} precentage
 *
 */
function scale(value: number, min: number, max: number) {
	if (max === min) {
		return 100
	}
	return Math.max(0, Math.min(1, (value - min) / (max - min))) * 100
}
