import React, { useState, useRef, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import Engine from '../../images/Engine.svg'
import styled from 'styled-components'
import Lever from './Lever'
import AtomContainer from './AtomContainer'

import {
	isPowerActive,
	getTimerId,
	getMin,
	getMax,
	getCurrentAverage,
	getCurrentPower,
} from '../../store/selectors/jrPlusState/power'

import 'react-rangeslider/lib/index.css'

import { FEED_BACK_BOX, POWER_DISPLAY_BOX } from './EngineSVGLayout'
import { DeltaClockProgressBar } from '../../components/DeltaClock'

const MIN = 0
const MAX = 10
const POWER_SCALE = MAX - MIN

const Power: StyledType<> = styled.div`
	display: flex;
	justify-content: center;
`

const EngineImage = styled.img`
	pointer-events: none;
	object-fit: contain;
	height: 90%;
	width: auto;
`

const Feedback = styled.div`
	position: absolute;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	left: ${props => props.x}px;
	top: ${props => props.y}px;
	width: ${props => props.width}px;
	height: ${props => props.height}px;
	text-align: center;
	color: #36c7d9;
	span {
		position: absolute;
	}
`

const EngineWrapper = styled.div`
	position: relative;
	display: flex;
	align-content: center;
	align-items: center;
	justify-content: center;
	justify-items: center;
	width: 100%;
	height: 100%;
`

export default function PowerPlus(): React$Node {
	const leverRef = useRef()

	const active = useSelector(isPowerActive)
	const min = useSelector(getMin)
	const max = useSelector(getMax)
	const currentAverage = useSelector(getCurrentAverage)
	const currentPower = useSelector(getCurrentPower)
	const timerId = useSelector(getTimerId)

	const [imageDimensions, setImageDimensions] = useState({ x: 0, y: 0, width: 100, height: 100 })
	const [loaded, setLoaded] = useState(false)

	const updateEngineDimensions = useCallback(() => {
		const current = leverRef.current
		if (current) {
			setImageDimensions({
				x: current.offsetLeft,
				y: current.offsetTop,
				width: current.width,
				height: current.height,
			})
		}
	}, [setImageDimensions])

	useEffect(updateEngineDimensions, [leverRef.current, updateEngineDimensions])

	useEffect(() => {
		window.addEventListener('resize', updateEngineDimensions)
		return () => {
			window.removeEventListener('resize', updateEngineDimensions)
		}
	}, [leverRef, updateEngineDimensions])

	return (
		<Power className="power-plus station">
			<EngineWrapper>
				<EngineImage
					src={Engine}
					alt="The Lever"
					className="power-station lever"
					key="lever"
					ref={leverRef}
					onLoad={() => {
						updateEngineDimensions()
						setLoaded(true)
					}}
				/>

				<>
					<Lever min={MIN} max={MAX} currentDimensions={imageDimensions} />
					{loaded ? (
						<>
							<AtomContainer currentDimensions={imageDimensions} />
							{active && (
								<>
									<Feedback
										x={imageDimensions.x + imageDimensions.width * FEED_BACK_BOX.x}
										y={imageDimensions.y + imageDimensions.height * FEED_BACK_BOX.y}
										width={imageDimensions.width * FEED_BACK_BOX.width}
										height={imageDimensions.height * FEED_BACK_BOX.height}>
										{timerId && (
											<DeltaClockProgressBar
												timerId={timerId}
												width={imageDimensions.width * FEED_BACK_BOX.width + 'px'}
												height={imageDimensions.height * FEED_BACK_BOX.height + 'px'}
												progressColor={'green'}
											/>
										)}
										<span>
											{currentAverage < min
												? 'INCREASE POWER'
												: currentAverage > max
												? 'DECREASE POWER'
												: 'STABLE'}
										</span>
									</Feedback>

									<Feedback
										x={imageDimensions.x + imageDimensions.width * POWER_DISPLAY_BOX.x}
										y={imageDimensions.y + imageDimensions.height * POWER_DISPLAY_BOX.y}
										width={imageDimensions.width * POWER_DISPLAY_BOX.width}
										height={imageDimensions.height * POWER_DISPLAY_BOX.height}>
										{Math.floor(currentPower * POWER_SCALE + MIN)} MEGA JOULES
									</Feedback>
								</>
							)}
						</>
					) : null}
				</>
			</EngineWrapper>
		</Power>
	)
}
