import type { GetCurrentLiteracyEventFeedbackRequestResult } from '@mission.io/mission-toolkit'

import config from '../../../config'
import { getMissionState, getStudentId } from '../../../store/stores/general'
import {
	getCurrentLiteracyEventTaskForStudent,
	getCurrentLiteracyEventFeedbackForStudent,
	type GetCurrentLiteracyEventStudentTaskResult,
} from '@mission.io/mission-toolkit'
import { LITERACY_EVENT } from '@mission.io/mission-toolkit/constants'
import { useSelector } from 'react-redux'

export type LiteracyEventForStudent = GetCurrentLiteracyEventStudentTaskResult

// We use this selector to get a reference to the literacy event on the full state
export function useLiteracyEvent(): void | LiteracyEventForStudent {
	const literacyEventForStudent = useSelector(state => {
		const studentId = getStudentId(state.general)
		if (!studentId) return
		const missionState = getMissionState(state)
		return getCurrentLiteracyEventTaskForStudent(missionState, studentId)
	})
	if (!config.featureFlags.literacyEvents || !literacyEventForStudent) return
	return literacyEventForStudent
}

// We use this hook to return the binary grade for the literacy event, true (correct) or false (incorrect)	)
export function useLiteracyEventGrade(): void | boolean {
	const literacyEvent = useLiteracyEvent()
	if (!literacyEvent || !literacyEvent.taskData) return
	const studentTaskState = literacyEvent.taskData.studentState
	if (studentTaskState.type === LITERACY_EVENT.TASK.TYPE.MULTIPLE_CHOICE) {
		const submittedOption =
			literacyEvent.taskData.task.type !== LITERACY_EVENT.TASK.TYPE.MULTIPLE_CHOICE
				? null
				: literacyEvent.taskData.task.options.find(
						option => option.id === studentTaskState.submittedChoice
				  )
		return submittedOption?.correct
	} else if (studentTaskState.type === LITERACY_EVENT.TASK.TYPE.CLOSE_READING) {
		// TODO: Determine grade of close reading task
	} else if (studentTaskState.type === LITERACY_EVENT.TASK.TYPE.SPEAKING) {
		// TODO: Determine grade (if there will be one) of speaking task
	}
}

/**
 * Hook to fetch the explanation for the student's choice in a multiple choice task
 * @returns {string | void} the explanation for the student's choice in a multiple choice task
 */
export function useExplanationForStudentChoice(): void | string {
	const literacyEvent = useLiteracyEvent()
	if (!literacyEvent || !literacyEvent.taskData) return
	const studentTaskState = literacyEvent.taskData.studentState
	if (
		studentTaskState.type === LITERACY_EVENT.TASK.TYPE.MULTIPLE_CHOICE &&
		literacyEvent.taskData.task.type === LITERACY_EVENT.TASK.TYPE.MULTIPLE_CHOICE
	) {
		const selectedOption = literacyEvent.taskData.task.options.find(
			option => option.id === studentTaskState.submittedChoice
		)
		return selectedOption?.explanation
	}
}

/**
 * useLiteracyEventStudentFeedbackRequests - get the literacy feedback the current student should submit, null/undefined if there is no feedback to submit
 *
 * @return {?GetCurrentLiteracyEventFeedbackRequestResult}
 */
export function useLiteracyEventStudentFeedbackRequests(): ?GetCurrentLiteracyEventFeedbackRequestResult {
	const currentStudentFeedbackRequest = useSelector(state => {
		const studentId = getStudentId(state.general)
		if (!studentId) {
			return
		}

		const missionState = getMissionState(state)
		return getCurrentLiteracyEventFeedbackForStudent(missionState, studentId)
	})

	if (!config.featureFlags.literacyEvents) {
		return
	}

	return currentStudentFeedbackRequest
}
