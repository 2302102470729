import React from 'react'
import { useSelector } from 'react-redux'
import { isAnyPauseInMission } from '../../../store/selectors/sharedSelectors'

import Robot from './Robot'
import robot1 from '../../../images/robot.png'
import robot1Small from '../../../images/robotSmall.png'
import robot2 from '../../../images/robot2.png'
import robot2Small from '../../../images/robot2Small.png'
import robot3 from '../../../images/robot3.png'
import robot3Small from '../../../images/robot3Small.png'
// import robot4 from '../../../images/robot4.png'
// import robot4Small from '../../../images/robot4Small.png'

import type { Robots as RobotsType } from './types'

type Props = {
	robots: RobotsType,
}

export default function Robots({ robots }: Props): React$Node {
	let pauseStyle = {}
	const isPaused = useSelector(isAnyPauseInMission)
	if (isPaused) {
		pauseStyle.pointerEvents = 'none'
	}

	return (
		<div className="h-full w-[30%] items-center p-2.5 flex-table" style={pauseStyle}>
			<Robot
				id="1"
				robotImg={robot1}
				dragImg={robot1Small}
				dragImgScale={robots['1'].dimensions}
				isBusy={robots['1'].busy}
			/>
			<Robot
				id="2"
				robotImg={robot2}
				dragImg={robot2Small}
				dragImgScale={robots['2'].dimensions}
				isBusy={robots['2'].busy}
			/>
			<Robot
				id="3"
				robotImg={robot3}
				dragImg={robot3Small}
				dragImgScale={robots['3'].dimensions}
				isBusy={robots['3'].busy}
			/>

			{/* {this.props && false ? ( // change this once the points are developed
					<Robot
						id="4"
						robotImg={robot1}
						dragImg={robot1Small}
						// robotImg={robot4}
						// dragImg={robot4Small}
						dragImgScale={this.props.robots['4'].dimensions}
						isBusy={this.props.robotsBusyStatus['4']}
					/> 
				<Robot
					id="4"
					robotImg={robot1}
					dragImg={robot1Small}
					dragImgScale={this.props.robots['4'].dimensions}
					isBusy={true}
				/>*/}
		</div>
	)
}
