import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { COMPONENT_TYPES } from '../../../../../../store/selectors/jrPlusState/engineering'
import { getStudentId } from '../../../../../../store/stores/general'
import { useTeamToggleData } from '../../../../../../juniorPlusStations/Decks/components/components'

import type { PopulatedPanel } from '../../../../../../store/selectors/jrPlusState/engineering'

/**
 * TeamAnimation - displays the animation for the teamSwitch panel
 *
 * @param {{panel:PopulatedPanel}} {panel} - the panel containing the teamToggle component (the teamSwitch panel)
 *
 * @return {React$Node} - returns the animation overlay or null if animation should not be played
 */
export default function TeamAnimation({ panel }: { panel: PopulatedPanel }): React$Node {
	const studentId = useSelector(state => getStudentId(state.general))
	const teamData = useTeamToggleData(studentId)

	let teamToggleComponent = null

	panel.components.forEach(component => {
		if (component.type !== COMPONENT_TYPES.TEAM_TOGGLE) {
			return
		}
		teamToggleComponent = component
	})

	if (!teamToggleComponent?.timerId) {
		return null
	}

	return (
		<StyledAnimationPositioner>
			<video src={teamData.teamTaskAnimation} muted style={{ width: '80%' }} autoPlay />
		</StyledAnimationPositioner>
	)
}

const StyledAnimationPositioner = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 1;
	video {
		width: 80%;
		border-radius: 8px;
	}
`
