import type { ReduxStore } from '../../rootReducer'
import {
	type DisplayTeam,
	type CreativeCanvasStudentInfo,
	getTeamIdForDocument,
} from '@mission.io/mission-toolkit'

import { getTeams } from './teams'

import { useSelector } from 'react-redux'

/**
 * Gets the team associated with a document
 */
export function useTeamForDocumentId(documentId: string): DisplayTeam | void {
	const teamId = useSelector((state: ReduxStore) =>
		getTeamIdForDocument(state.jrPlusState, documentId)
	)
	const teams = useSelector(getTeams)
	return teamId ? teams?.find(t => t.id === teamId) : undefined
}

/**
 * Gets the student info object for the creative canvas station
 */
function getCanvasStudentInfo(state: ReduxStore): CreativeCanvasStudentInfo {
	return state.jrPlusState.stationData.creativeCanvas.meta.studentInfo
}

/**
 * Gets the student info object for the creative canvas station
 */
export function useCanvasStudentInfo(): CreativeCanvasStudentInfo {
	return useSelector(getCanvasStudentInfo)
}
