import React from 'react'
import { useSelector } from 'react-redux'
import { Row, Name } from '../../../../basics'
import { BottomLeftCorner, HealthBar } from './components'
import { getStudentName } from '../../../../../store/stores/general'
import styled from 'styled-components/macro'
import { getMyTeam } from '../../../../../store/selectors/jrPlusState/teams'
import { isController } from '../../../../AppSetup.jsx'

export default function Footer(): React$Node {
	const name = useSelector(getStudentName)

	return (
		<FooterRow>
			<div className="background-row">
				<HealthBar css="height: 12.5vh; width: auto; " id="healthBar" />
				{/* In order to allow the teacher station container to shrink more we do not display the teacher's name. */}
				{!isController() && <NameWrapper name={name} />}

				<BottomLeftCorner css="height: 12.5vh; width: auto; " />
			</div>
		</FooterRow>
	)
}

const BORDER_HEIGHT = 8

const FooterRow = styled(Row)`
	flex-shrink: 0;
	grid-row: status-start / status-end;
	grid-column: status-start / status-end;
	z-index: var(--z-index-overlay);

	.background-row {
		.dynamic-center {
			flex: 1;
		}
		background-color: #020223;
		height: 8vh;
		width: 100%;
		border-top: ${BORDER_HEIGHT}px solid var(--color-primary-darker);
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: flex-end;
	}
	h1 {
		font-size: 1.75em;
	}
	h2 {
		font-size: 1.55em;
	}
	h3 {
		font-size: 1.35em;
	}
	h4 {
		font-size: 1.15em;
	}

	.name-container {
		display: flex;
		align-items: center;
		background-color: var(--color-primary-darker);
		padding: 8px;
		border-radius: 8px;
		position: relative;
		height: fit-content;
		min-width: 400px;
		align-self: flex-start;
		transform: translateY(calc(-50% + var(--font-size-xxxs) - ${BORDER_HEIGHT / 2}px));
		.aesthetic-lights {
			position: absolute;
			bottom: -8px;
			right: 16px;
		}
	}
`

function NameWrapper({ name }: { name: ?string }): React$Node {
	const team = useSelector(getMyTeam)

	return (
		<div className="name-container">
			{team?.icon ? (
				<img
					src={team.icon}
					alt=""
					css={`
						height: 2.9em;
					`}
				/>
			) : null}
			<div css="flex: 1;">
				<Name value={name || 'Unknown'} className="text-center w-full" />
				{team && (
					<h3 className="m-0 text-center" style={{ fontSize: 'var(--font-size-xxxs)' }}>
						{team.teamName}
					</h3>
				)}
			</div>
			<svg className="aesthetic-lights" width="48.8" height="16.8" viewBox="0 0 61 21" fill="none">
				<path
					d="M55.2006 0.699951H5.90063L0.100586 10.7L5.00061 19.0999C5.60061 20.0999 6.60056 20.6999 7.70056 20.6999H53.4006C54.5006 20.6999 55.5006 20.0999 56.1006 19.0999L61.0006 10.7L55.2006 0.699951Z"
					fill="var(--color-primary-darker)"
				/>
				<path
					d="M19.1002 13.9999C20.978 13.9999 22.5002 12.4777 22.5002 10.5999C22.5002 8.72215 20.978 7.19995 19.1002 7.19995C17.2225 7.19995 15.7002 8.72215 15.7002 10.5999C15.7002 12.4777 17.2225 13.9999 19.1002 13.9999Z"
					fill="#00FFFF"
				/>
				<path
					d="M30.5006 13.9999C32.3784 13.9999 33.9006 12.4777 33.9006 10.5999C33.9006 8.72215 32.3784 7.19995 30.5006 7.19995C28.6228 7.19995 27.1006 8.72215 27.1006 10.5999C27.1006 12.4777 28.6228 13.9999 30.5006 13.9999Z"
					fill="#00FFFF"
				/>
				<path
					d="M41.9 13.9999C43.7778 13.9999 45.3 12.4777 45.3 10.5999C45.3 8.72215 43.7778 7.19995 41.9 7.19995C40.0223 7.19995 38.5 8.72215 38.5 10.5999C38.5 12.4777 40.0223 13.9999 41.9 13.9999Z"
					fill="#00FFFF"
				/>
			</svg>
		</div>
	)
}
