import { getStudent, getActiveStudents } from '../../selectors/sharedSelectors'
import { getStudentId } from '../../stores/general'
import type { ReduxStore } from '../../rootReducer'
import type { StudentStatus } from '../../stores/sharedGameStateTypes'
import {
	type DisplayTeam,
	type StudentWithPoints,
	type JuniorTeam,
	getTeamsFromMission,
	getStudent as getStudentFromFullState,
} from '@mission.io/mission-toolkit'
import { getFullState } from '../sharedSelectors'
import { useSelector } from 'react-redux'
export type Team = {
	id: string,
	type: string,
	displayName: string,
	minStudents: number,
	maxStudents: number,
	lead: string,
	students: string[],
}

export type PopulatedTeam = {
	id: string,
	type: string,
	displayName: string,
	lead: ?StudentStatus,
	students: StudentStatus[],
}

export function getTeamId(team: ?Team): ?string {
	return team ? team.id : null
}

export function getTeams(state: ReduxStore): ?Array<DisplayTeam> {
	return getTeamsFromMission(state.jrPlusState)
}

export function getPopulatedTeam(state: ReduxStore, teamId: string): ?PopulatedTeam {
	let team = state.jrPlusState.teams[teamId]
	if (!team) return null
	let students = []
	team.students.forEach((studentId: string) => {
		let student = getStudent(state, studentId)
		if (student) {
			students.push(student)
		}
	})
	return {
		id: team.id,
		type: team.type,
		displayName: team.displayName,
		students,
		lead: getStudent(state, team.lead),
	}
}

export function getAllPopulatedTeams(state: ReduxStore): { [teamId: string]: PopulatedTeam } {
	let populatedTeams = {}
	Object.keys(state.jrPlusState.teams).forEach(
		(teamId: string) => (populatedTeams[teamId] = getPopulatedTeam(state, teamId))
	)
	return populatedTeams
}

/**
 * Get a student's team by id, and return all the team-members who are
 * connected to the mission and are NOT that same student.
 */
export function getActiveTeammates(state: ReduxStore): StudentStatus[] {
	const studentId = getStudentId(state.general)
	if (!studentId) {
		return []
	}

	const activeStudents = getActiveStudents(state)
	const teamMemberIds = getTeamForStudent(state, studentId)?.teamMemberIds ?? null
	if (!teamMemberIds) {
		return []
	}
	const teamStudentIds = new Set(teamMemberIds)
	return activeStudents.filter(
		({ id, connected }) => id !== studentId && teamStudentIds.has(id) && connected
	)
}

export type MinimumTeamData = {
	id: string,
	teamType: string,
	teamName: string,
	position: number, // index of the requested student in the teamMemberIds array
	teamMemberIds: string[],
	icon: string,
}

/**
 * getTeamForStudent - get the teamType and student's position in the team
 *
 * @param {ReduxStore} state - the redux state
 * @param {string} studentId - the id of the student
 *
 * @return {?MinimumTeamData}
 */
export function getTeamForStudent(state: ReduxStore, studentId: string): ?MinimumTeamData {
	const missionState = state.jrPlusState
	if (!missionState) {
		return null
	}

	const teams = missionState.teams
	const teamIds = Object.keys(teams)

	for (let i = 0; i < teamIds.length; i++) {
		const team = teams[teamIds[i]]
		let teamMembers = [...team.students]
		if (team.lead) {
			teamMembers = [team.lead, ...team.students]
		}
		for (let j = 0; j < teamMembers.length; j++) {
			if (teamMembers[j] === studentId) {
				return {
					id: teamIds[i],
					teamType: team.type,
					teamName: team.displayName,
					position: j,
					teamMemberIds: teamMembers,
					icon: team.icon,
				}
			}
		}
	}
}

/**
 * getPopulatedStudentTeamWithAllStudents - get the teams with all students populated
 *
 * @param  {ReduxStore} store - the current redux store
 * @return {?{
 *	 id: string, - the id of the team
 *	 name: string, - the name of the team
 *	 type: string, - the type of the team
 *	 members: Array<StudentWithPoints>  - the members in the team
 * }}
 */
export function getPopulatedStudentTeamWithAllStudents(
	store: ReduxStore
): ?{ id: string, name: string, type: string, members: Array<StudentWithPoints>, icon: string } {
	const studentId = getStudentId(store.general)
	if (!studentId) {
		return null
	}
	const team = getTeamForStudent(store, studentId)
	if (!team) {
		return null
	}
	const members: Array<StudentWithPoints> = []
	team.teamMemberIds.forEach(studentId => {
		let student = getStudentFromFullState(getFullState(store), studentId)
		if (student) {
			members.push(student)
		}
	})
	return {
		id: team.id,
		name: team.teamName,
		type: team.teamType,
		members,
		icon: team.icon,
	}
}

export const TEAM_TYPES = {
	COMMAND: 'COMMAND',
	ENGINEERING: 'ENGINEERING',
	MEDICAL: 'MEDICAL',
	SECURITY: 'SECURITY',
	IT: 'IT',
	OPERATIONS: 'OPERATIONS',
	SCIENCE: 'SCIENCE',
	FLIGHT_DIRECTOR: 'FLIGHT_DIRECTOR',
}

export type TeamType = $Keys<typeof TEAM_TYPES>

/**
 * getTeam - get the user's team on the mission
 * @param {ReduxStore} store
 * @returns {?MinimumTeamData}
 */
export function getMyTeam(state: ReduxStore): ?MinimumTeamData {
	const studentId = getStudentId(state.general)
	if (studentId) {
		return getTeamForStudent(state, studentId)
	}
	return null
}

/**
 * Gets a team by its id.
 */
function getTeamById(teamId: ?string): (state: ReduxStore) => ?JuniorTeam {
	return (state: ReduxStore) => (teamId ? state.jrPlusState.teams[teamId] : null)
}

/**
 * A hook to get a team by its id
 */
export function useTeam(teamId: ?string): ?JuniorTeam {
	return useSelector(getTeamById(teamId))
}
