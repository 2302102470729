import React from 'react'
import { useSelector } from 'react-redux'

import Compass from '../components/Compass'

import type { ReduxStore } from '../store/rootReducer'

import './HackerData.css'

const STORE_KEYS_TO_DISPLAY = ['jrPlusState', 'jrState']

export default function HackerData(): React$Node {
	const reduxStore: ReduxStore = useSelector(state => state)

	return (
		<div className="HackerData">
			<section className="store">
				{STORE_KEYS_TO_DISPLAY.map(storeKey => {
					return <ReduxStoreData storeName={storeKey} store={reduxStore[storeKey]} key={storeKey} />
				})}
			</section>

			<section className="components">
				<div>
					<h2>Compass</h2>
					<Compass />
				</div>
			</section>
		</div>
	)
}

const ReduxStoreData = ({ storeName, store }: { storeName: string, store: { [string]: any } }) => {
	return (
		<div className="ReduxStoreData">
			<h2>{storeName}</h2>
			{Object.keys(store).map(key => {
				return (
					<div key={key}>
						<b>{key}:</b> {String(store[key])}
					</div>
				)
			})}
		</div>
	)
}
