// $FlowFixMe can import svg as react component
import { ReactComponent as base1 } from './Base.svg'
import type { AbstractComponent } from 'react'
import { animated } from '@react-spring/web'

import blaster1 from './DefaultBlaster'
import blaster2 from './Upgrade1Blaster'
import blaster3 from './Upgrade2Blaster'
import blaster4 from './Upgrade3Blaster'

import laser1, { SIZE as LASER_1_SIZE } from './DefaultLaser'
import laser2, { SIZE as LASER_2_SIZE } from './Upgrade1Laser'
import laser3, { SIZE as LASER_3_SIZE } from './Upgrade2Laser'
import laser4, { SIZE as LASER_4_SIZE } from './Upgrade3Laser'

export type LaserProps = {|
	className?: string,
	style?: {},
	show?: boolean,
	x?: number | string,
	y?: number | string,
	checkImpact?: (({ current?: Element | null }) => ?boolean) | null,
	onFinish?: () => void,
|}

export type BlasterProps = {|
	className?: string,
	children?: React$Node,
	transformationWrapper?: ({ children: React$Node }) => React$Node,
	height?: number | string,
	width?: number | string,
	x?: number | string,
	y?: number | string,
	style?: {},
|}

const upgrades = [
	{
		blastType: 'PROJECTILE',
		name: 'B1 Blaster',
		base: base1,
		blaster: blaster1,
		blasterMovementSpeed: 20,
		blasterDimensions: { h: 550.88, w: 398.24 },
		pointOfRotation: { x: 199.12, y: 351.77, r: 199.12 },
		projectile: (animated(laser1): AbstractComponent<LaserProps, Element>),
		projectileSize: LASER_1_SIZE,
		projectileSpeed: 0.05,
	},
	{
		blastType: 'PROJECTILE',
		name: 'Z8 Pulse Blaster',
		base: base1,
		blaster: blaster2,
		blasterMovementSpeed: 30,
		blasterDimensions: { h: 644.49, w: 398.24 },
		pointOfRotation: { x: 199.12, y: 445.37, r: 199.12 },
		projectile: (animated(laser2): AbstractComponent<LaserProps, Element>),
		projectileSize: LASER_2_SIZE,
		projectileSpeed: 0.1,
	},
	{
		blastType: 'PROJECTILE',
		name: 'X3 Blaster Deluxe',
		base: base1,
		blaster: blaster3,
		blasterMovementSpeed: 80,
		blasterDimensions: { h: 708.37, w: 398.24 },
		pointOfRotation: { x: 199.12, y: 509.25, r: 199.12 },
		projectile: (animated(laser3): AbstractComponent<LaserProps, Element>),
		projectileSize: LASER_3_SIZE,
		projectileSpeed: 0.2,
	},
	{
		blastType: 'LASER',
		name: 'X12 Plasma Cannon',
		base: base1,
		blaster: blaster4,
		blasterDimensions: { h: 771, w: 399 },
		blasterMovementSpeed: 80,
		pointOfRotation: { x: 199.12, y: 571, r: 199.12 },
		projectile: laser4,
		projectileSize: LASER_4_SIZE,
	},
]

export default upgrades
