import React from 'react'
import styled from 'styled-components'

/**
 * A styled checkbox that looks so nice
 */
export function Checkbox({
	className,
	children,
	checked,
	onChange,
	...props
}: {|
	className?: string,
	children?: React$Node,
	checked: boolean,
	onChange?: (checked: boolean) => mixed,
	disabled?: boolean,
|}): React$Node {
	return (
		<CheckboxContainer
			{...{
				className: className,
			}}>
			{children}
			<input
				type="checkbox"
				{...props}
				checked={checked}
				onChange={e => {
					if (onChange) {
						onChange(e.target.checked)
					}
				}}
			/>
			<span className="checkmark" />
		</CheckboxContainer>
	)
}

const CheckboxContainer = styled.label`
	--size: 18px;
	--border-width: 3px;
	--left-checkmark-shift: 3px;
	--top-checkmark-shift: -1px;

	display: flex;
	align-items: center;
	position: relative;
	cursor: pointer;
	user-select: none;

	// hide the actual checkbox
	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
	}

	// the box that will be displayed
	.checkmark {
		position: relative;
		display: inline-block;
		height: var(--size);
		width: var(--size);
		background-color: transparent;
		border: var(--border-width) solid ${({ theme }) => theme.primary};
		border-radius: 3px;

		// The checkmark (hidden when not checked)
		&:after {
			--short-side: calc(var(--size) / 3);
			--long-side: calc(var(--size) / 1.5);
			content: '';
			position: absolute;
			display: none;
			width: var(--short-side);
			height: var(--long-side);
			border: solid ${({ theme }) => theme.primary};
			border-width: 0 var(--border-width) var(--border-width) 0;
			transform: translate(var(--left-checkmark-shift), var(--top-checkmark-shift)) rotate(40deg);
		}
	}

	// when hovered...
	&:hover input ~ .checkmark {
		background-color: rgba(0, 0, 0, 0.05);
	}

	// when checked...
	input:checked ~ .checkmark:after {
		display: block;
	}
`
