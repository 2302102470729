import React, { useEffect, useRef } from 'react'
import { ONE_SECOND } from '../../../constants'
import { Overlay } from '../helperComponents'
import styled, { keyframes, css } from 'styled-components'

const PSEUDO_PENDING_TIME = 5 * ONE_SECOND

/**
 * A component that displays a "Submitting Decision..." message for a set amount of time.
 * @param {Object} props - The props object.
 * @param {Function} props.onEnd - A callback function to be called when the pending time is over.
 * @returns {ReactNode} A React component that displays a "Submitting Answer..." message.
 */
export function PendingSubmissionFrame({ onEnd }: { onEnd: () => void }): React$Node {
	const onTimerEnd = useRef(onEnd)
	useEffect(() => {
		onTimerEnd.current = onEnd
	}, [onEnd])
	useEffect(() => {
		const timeout = setTimeout(() => onTimerEnd.current(), PSEUDO_PENDING_TIME)
		return () => clearTimeout(timeout)
	}, [])

	return (
		<Overlay
			style={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
			}}>
			<h1 style={{ color: 'white' }}>
				Submitting Decision
				<AnimatedSpanGroup $length={3}>
					<span>.</span>
					<span>.</span>
					<span>.</span>
				</AnimatedSpanGroup>
			</h1>
		</Overlay>
	)
}

const MS_PER_CHARACTER = 300
const PERCENT_STATIC_TIME = 30

/* An animation where each child in the group blinks in after the other.
 When all children have entered, they blink out together.
 This repeats indefinitely.

 @prop {number} $length - The number of children in the group.
*/
const AnimatedSpanGroup = styled.span`
	span {
		opacity: 0;
	}

	${({ $length: length }) => {
		let cssAnimations = ``
		const animateSegment = (100 - PERCENT_STATIC_TIME) / (length + 1)
		for (let i = 0; i < length; i++) {
			const keyframe = keyframes`
                ${animateSegment * i}% {
                    opacity: 0;
                } ${animateSegment * i + 1}% {
                    opacity: 1;
                }
                ${animateSegment * length + PERCENT_STATIC_TIME}% {
                    opacity: 1;
                }
                ${animateSegment * length + 1 + PERCENT_STATIC_TIME}% {
                    opacity: 0;
                }
            `
			cssAnimations = css`
                ${cssAnimations}
				span:nth-child(${i + 1}) {
					animation: ${keyframe} ${MS_PER_CHARACTER * length}ms infinite;
				}
			`
		}
		return cssAnimations
	}}
`

export default PendingSubmissionFrame
