import React, { Component } from 'react'
import { connect } from 'react-redux'
import { STATUS_MESSAGE_RECONNECT_ATTEMPTS } from '../store/stores/webSocket'
import { FaExclamationTriangle } from 'react-icons/fa'
import './ConnectionStatus.css'

type Props = {
	isOpen: boolean,
	isOpening: boolean,
	connectAttempts: number,
}

class ConnectionStatus extends Component<Props> {
	render() {
		return this.getStatus()
	}

	getStatus() {
		let message = ''
		let classNames = 'ConnectionStatus'

		if (this.props.connectAttempts >= STATUS_MESSAGE_RECONNECT_ATTEMPTS) {
			classNames += ' disconnected'
			message =
				'Station has lost contact with the ship. Attempting to reconnect. Please wait for instructions from your flight director.'
		}
		// else if (!this.props.isOpen) {
		// 	classNames += ' disconnected'
		// 	message = this.props.isOpening ? 'Attempting to connect...' : ('Disconnected! Attempted to reconnect ' + (this.props.connectAttempts + 1) + ' time(s).' )
		// }
		else {
			classNames += ' hidden'
		}

		return (
			<div className={classNames}>
				{!this.props.isOpen && <FaExclamationTriangle />}
				{message}
			</div>
		)
	}
}

function mapStateToProps(state) {
	return {
		...state.webSocket,
	}
}

export default (connect(mapStateToProps)(ConnectionStatus): (props: $Shape<{||}>) => React$Node)
