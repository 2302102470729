import React from 'react'
import styled from 'styled-components/macro'
import { BASE_HEIGHT } from '../constants'

export const CANVAS_HEIGHT = 827.41
export const MIDDLE_LINE = 862.55
export const CANVAS_WIDTH = 1697.11

export default function Background(props: { children?: React$Node }): React$Node {
	return (
		<Svg {...props} viewBox="0 0 1697.11 827.41">
			<clipPath id="bottom-cutoff">
				<rect
					x={-CANVAS_WIDTH / 2}
					y={-CANVAS_HEIGHT}
					height={CANVAS_HEIGHT + CANVAS_HEIGHT + BASE_HEIGHT}
					width={CANVAS_WIDTH + CANVAS_WIDTH}
				/>
			</clipPath>

			<g className="background-1">
				<path
					className="background-2"
					d="M1582.64,821.85c0-305.78-190.57-567-459.42-671.47l-61.9,35.74a669.62,669.62,0,0,0-397.53,0l-61.9-35.74C333,254.83,142.47,516.07,142.47,821.85"
				/>
				<polyline className="background-3" points="1422.13 264.04 862.55 821.08 303.98 261.5" />
				<line className="background-3" x1="862.55" x2="862.55" y1="821.08" y2="29.65" />
				<path
					className="background-4"
					d="M1654,821.85c0-437.13-354.36-791.49-791.48-791.49S71.07,384.72,71.07,821.85Z"
				/>
				<g>
					<path className="background-5" d="M226.31,822.31V822" />
					<path
						className="background-6"
						d="M227.25,787.3c18.18-335.66,296.11-602.24,636.31-602.24,346,0,627.59,275.75,637,619.48"
					/>
					<path className="background-5" d="M1500.8,822v.27" />
				</g>
				<path className="background-7" d="M1099.82,163.74c267.54,98,458.47,354.84,458.47,656.32" />
				<path className="background-7" d="M160.87,820.06c0-302.79,192.6-560.6,462-657.59" />
				<path className="background-3" d="M862.56,821.85v0Z" />
			</g>
			<g>
				<text className="background-8" transform="translate(837.34 20.65)">
					90°
				</text>
				<g>
					<text className="background-8" transform="translate(1420.36 247.66)">
						45°
					</text>
					<text className="background-8" transform="translate(250.77 247.66)">
						135°
					</text>
				</g>
				<g>
					<text className="background-8" transform="translate(1666.56 821.58)">
						0°
					</text>
					<text className="background-8" transform="translate(0 821.58)">
						180°
					</text>
				</g>
			</g>
			<g clipPath="url(#bottom-cutoff)">{props.children}</g>
		</Svg>
	)
}

const Svg = styled.svg`
	.background-1 {
		opacity: 0.5;
	}

	.background-2,
	.background-3,
	.background-4,
	.background-5,
	.background-6,
	.background-7 {
		fill: none;
		stroke: aqua;
		stroke-miterlimit: 10;
	}

	.background-2 {
		stroke-width: 5px;
	}

	.background-3 {
		stroke-width: 2px;
	}

	.background-4 {
		stroke-width: 3px;
	}

	.background-5,
	.background-6 {
		stroke-width: 12px;
	}

	.background-6 {
		stroke-dasharray: 0.99 34.76;
	}

	.background-8 {
		font-size: 24px;
		fill: aqua;
		font-family: Orbitron-Regular, Orbitron;
	}
`
