import classnames from 'classnames'
import React from 'react'
import { LITERACY_EVENT_Z_INDEX } from '../../../constants/styles'

export const Overlay = ({
	children,
	className,
	...props
}: {
	className?: string,
	children?: React$Node,
}): React$Node => (
	<div
		{...props}
		style={{ zIndex: LITERACY_EVENT_Z_INDEX }}
		className={classnames('absolute w-full h-full bg-black/75 top-0 left-0', className)}>
		{children}
	</div>
)
