const SVG_VIEWBOX_WIDTH = 1056
const SVG_VIEWBOX_HEIGHT = 837.17

export const TEXT_OFFSET = 25 / SVG_VIEWBOX_WIDTH

export const LEVER_BOX = {
	x: 144.5 / SVG_VIEWBOX_WIDTH,
	y: 64.17 / SVG_VIEWBOX_HEIGHT,
	width: 85.5 / SVG_VIEWBOX_WIDTH,
	height: 698 / SVG_VIEWBOX_HEIGHT,
}

export const ATOM_CONTAINER = {
	centerY: 419.17 / SVG_VIEWBOX_HEIGHT,
	centerX: 786 / SVG_VIEWBOX_WIDTH,
	radius: 253 / SVG_VIEWBOX_WIDTH, // related to width
}

export const FEED_BACK_BOX = {
	x: 603.5 / SVG_VIEWBOX_WIDTH,
	y: 24.89 / SVG_VIEWBOX_HEIGHT,
	width: 370 / SVG_VIEWBOX_WIDTH,
	height: 60.02 / SVG_VIEWBOX_HEIGHT,
}

export const POWER_DISPLAY_BOX = {
	x: 603.5 / SVG_VIEWBOX_WIDTH,
	y: 751.69 / SVG_VIEWBOX_HEIGHT,
	width: 370 / SVG_VIEWBOX_WIDTH,
	height: 60.02 / SVG_VIEWBOX_HEIGHT,
}
