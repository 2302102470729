import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import Arrow from './Arrow'
import './Compass.css'

import { getThrusterClassDirection } from '../../store/selectors/sharedSelectors'

export { Arrow }

const Needle = styled.div`
	width: 100%;
	height: 100%;
`

const NeedleWrapper = styled.div`
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	transition: transform 200ms;
	transform: ${({ angle }) => `rotate(${angle + Math.PI / 2}rad)`};
`

export default function Compass(): React$Node {
	const direction = useSelector(getThrusterClassDirection)

	const compassNeedle = () => {
		if (!direction?.magnitude) {
			return <div className="compass-center" />
		}

		return (
			<NeedleWrapper angle={direction.angle}>
				<Needle>
					<Arrow
						style={{
							width: '4vw',
							height: '3vw',
							position: 'absolute',
							left: '50%',
							top: '0%',
							transform: 'translateX(-50%)',
						}}
					/>
				</Needle>
			</NeedleWrapper>
		)
	}

	return (
		<div className="Compass">
			<div className="vertical-axis" />
			<div className="horizontal-axis" />
			{compassNeedle()}
		</div>
	)
}
