import type { StudentDefenseTarget, DefenseTarget, MapObjectIds } from '@mission.io/mission-toolkit'
import { DEFENSE_STATION } from '@mission.io/mission-toolkit/constants'

import type { ReduxStore } from '../../rootReducer'
import { forEachObject } from '../../../utility/functions'
import { getStudentId } from '../../stores/general'

export function isDefenseActive(state: ReduxStore): boolean %checks {
	return state.jrPlusState.stationData.defense.status === DEFENSE_STATION.STATUS.ACTIVE
}

export function getDefenseMapObjects(store: ReduxStore): MapObjectIds[] {
	if (store.jrPlusState.stationData.defense.status !== DEFENSE_STATION.STATUS.ACTIVE) return []
	const mapObjects = []
	forEachObject(store.jrPlusState.stationData.defense.targetMap, target => {
		if (target.mapObject) {
			mapObjects.push(target.mapObject)
		}
	})
	return mapObjects
}

/**
 * Gets the targets that should appear on the defense screen.
 * @param {ReduxStore} state
 * @returns  {Array<RequiredDefenseTargetData>} The necessary data required for the defense station to display and attack the targets
 */
export function getStudentTargets(state: ReduxStore): ?(StudentDefenseTarget[]) {
	const studentId = getStudentId(state.general)
	if (
		!studentId ||
		state.jrPlusState.stationData.defense.status !== DEFENSE_STATION.STATUS.ACTIVE
	) {
		return null
	}
	const { students } = state.jrPlusState.stationData.defense
	return students[studentId]?.targets
}

/**
 * Gets the data map to look up details regarding each target id.
 * @param {ReduxStore} state
 * @returns {{[string]: DefenseTarget}} A map of target ids to target details
 */
export function getTargetMap(state: ReduxStore): { [string]: DefenseTarget } {
	if (state.jrPlusState.stationData.defense.status !== DEFENSE_STATION.STATUS.ACTIVE) {
		return {}
	}
	return state.jrPlusState.stationData.defense.targetMap
}

/**
 * Gets an indicator value for the student defense weapon to be used while the station is active
 * @param {ReduxStore} state
 * @returns {number}
 */
export function getWeaponIndex(state: ReduxStore): number {
	const studentId = getStudentId(state.general)
	if (
		!studentId ||
		state.jrPlusState.stationData.defense.status !== DEFENSE_STATION.STATUS.ACTIVE
	) {
		return 0
	}
	const { students } = state.jrPlusState.stationData.defense
	return students[studentId]?.weapon.level || 0
}
