// $FlowFixMe ReactComponent is a valid way to get SVGs
import { ReactComponent as Device0 } from './Device0.svg'
// $FlowFixMe ReactComponent is a valid way to get SVGs
import { ReactComponent as Device1 } from './Device1.svg'
// $FlowFixMe ReactComponent is a valid way to get SVGs
import { ReactComponent as Device2 } from './Device2.svg'
// $FlowFixMe ReactComponent is a valid way to get SVGs
import { ReactComponent as Device3 } from './Device3.svg'

const upgrades = [
	{ id: 'level0', device: Device0, name: 'Dug’s Makeshift Engine' },
	{ id: 'level1', device: Device1, name: 'Beta Engine 3' },
	{ id: 'level2', device: Device2, name: 'Nebula 2000' },
	{ id: 'level3', device: Device3, name: 'Turbo Engine X' },
]

export default upgrades
