import React from 'react'
import { useSelector } from 'react-redux'
import { getStudentName, getStudentId } from '../../../../store/stores/general'
import { getComponent } from '../../../../store/selectors/jrPlusState/engineering'
import { ACTION_DATA_RENDER_MAP, getTeamComponentData } from '../components'
import { CONTRAST, RED } from '../../../../constants/styles'
import { getTeamForStudent } from '../../../../store/selectors/jrPlusState/teams'
import { FaCheckSquare as CheckSquare } from 'react-icons/fa'
import { FaSquare } from 'react-icons/fa'
import styled from 'styled-components'

import type { WaitStatus } from '../../../../store/selectors/jrPlusState/waiters'

const ActionData = styled.div`
	display: flex;

	> * {
		flex: 1 1;
	}

	* {
		display: inline;
	}
`

const StatusBoxWrapper = styled.div`
	flex: 0 0 auto;
	display: flex;
	align-items: center;
`

const ActionRequiredDataWrapper = styled.div`
	> * {
		border-bottom: 2px solid ${CONTRAST};
		padding: 4px;
	}

	> *:last-child {
		border: 0px solid Transparent;
	}
`

const StyledActionRequired: StyledType<> = styled.div`
	overflow-y: auto;
`

export default function ActionRequired({ waitStatus }: { waitStatus: WaitStatus[] }): React$Node {
	return (
		<StyledActionRequired>
			<div className="w-full p-2 bg-error text-center font-bold rounded-lg mt-2">
				Action Required
			</div>
			<ActionRequiredDataWrapper>
				{waitStatus.map((status, index) => {
					return (
						<StudentRequirements
							key={index}
							studentId={status.extra.targetStudent}
							status={status}
						/>
					)
				})}
			</ActionRequiredDataWrapper>
		</StyledActionRequired>
	)
}

function StudentRequirements({ studentId, status }: { studentId: string, status: WaitStatus }) {
	let studentName = useSelector(state => getStudentName(state, studentId))
	let component = useSelector(state => getComponent(state, status.extra.componentId))
	let isThisStudent = studentId === useSelector(state => getStudentId(state.general))
	const studentTeamComponentData = useSelector(state => getTeamComponentData(state, studentId))
	const team = useSelector(state => getTeamForStudent(state, studentId)) || {
		teamName: 'Unknown Team',
		teamType: 'UNKNOWN',
		position: 0,
	}
	let extra = status.extra

	const ComponentComponent = ACTION_DATA_RENDER_MAP[component.type]

	return (
		<ActionData>
			<div>
				<ComponentComponent
					{...{
						component,
						studentName,
						actionData: (extra && extra.actionData) || {},
						isThisStudent,
						studentId,
						studentTeamComponentData,
						teamData: team,
					}}
				/>
			</div>
			<StatusBoxWrapper>
				{status.passed ? <CheckSquare color={CONTRAST} /> : <FaSquare color={RED} />}
			</StatusBoxWrapper>
		</ActionData>
	)
}
