// @flow
import styled, { css } from 'styled-components/macro'
import { LENGTHS, IDS, MAX_MULTIPLE_CHOICE_OPTIONS } from './constants'
import { growIn, shrink, fadeIn, fadeOut } from '../../../helperComponents/keyframes'
import React from 'react'
import { TOTAL_DELAY } from '../InformationFrame'
import { keyframes } from 'styled-components'

type AnimationConstant = {
	id: string,
	durationMs: number,
	delayMs: number,
	unitsToAnimate: number,
}

/**
 * This wrapper handles animations for the options frame. It will animate the svg path, the title, and the options in both forward motion or reverse
 * motion if set. Children of this frame should render the options and title and insert animation IDS defined in ./constants.js into the appropriate components.
 * @param {boolean} reverse - if true, will animate in reverse, paths will erase as other components fade out or disappear
 * @param {0 | 1 | 2} totalLeafPaths - total extra option paths to animate besides the main path.
 * @param {number} horizontalPathLength - length of the main path from the option title to the last option.
 * @param {null | number[]} positionsOnPath - Each extra options position on the main horizontal path. if provided, will animate the extra option paths in the order provided.
 * @param {() => void} onAnimationEnd - callback for when the animation ends
 * @returns {React$Node}
 */
export const AnimationWrapper = ({
	reverse,
	totalLeafPaths,
	horizontalPathLength,
	titleHeight,
	positionsOnPath,
	onAnimationEnd,
	children,
}: {
	reverse: boolean,
	totalLeafPaths: number,
	horizontalPathLength: number,
	titleHeight: number,
	positionsOnPath: null | number[],
	children: React$Node,
	onAnimationEnd?: () => void,
}): React$Node => {
	const AC = React.useMemo(() => {
		return buildAnimationLookup(
			reverse,
			totalLeafPaths,
			horizontalPathLength,
			positionsOnPath,
			titleHeight
		)
	}, [totalLeafPaths, horizontalPathLength, positionsOnPath, reverse, titleHeight])

	const lastElementId = reverse ? IDS['animate-1-marker'] : IDS['animate-5-display-content']
	const element = document.getElementById(lastElementId)
	React.useEffect(() => {
		if (element && onAnimationEnd) {
			element.addEventListener('animationend', onAnimationEnd)
		}
		return () => {
			if (element && onAnimationEnd) {
				element.removeEventListener('animationend', onAnimationEnd)
			}
		}
	}, [element, onAnimationEnd])
	return (
		<AnimationStyles $AC={AC} $reverse={reverse}>
			{children}
		</AnimationStyles>
	)
}
// The smaller this value the faster the animation will be, when debugging it is EXTREMELY helpful to make this number a bit larger.
const ANIMATION_MS_PER_UNIT = 2

// These animation area ids are more generic than specific animation ids. And are used to build the unitsToAnimate object.
// The units to animate object is a lookup object that defines lengths of paths, sizes of circles, etc. which help us determine animation durations for dynamic sized components.
const ANIMATION_AREA_ID = {
	'initial-path': 'initial-path',
	'path-out-from-title': 'path-out-from-title',
	'leaf-from-main-path': 'leaf-from-main-path',
	'large-marker': 'large-marker',
	'small-marker': 'small-marker',
	'option-container': 'option-container',
}

type AnimationId = $Keys<typeof IDS>
type AnimationTreeNode = {
	id: AnimationId,
	meta: {
		duration: number,
		unitsToAnimate: number,
	},
	extraDelay?: number,
	next?: Array<AnimationTreeNode>,
}
type UnitsToAnimate = { [$Keys<typeof ANIMATION_AREA_ID>]: number }

/**
 * Meet and potatoes of the animation wrapper. This function builds a lookup object that contains animation constants for each animation id.
 * An animation constant includes the duration, delay, and units to animate for each animation id.
 * @param {boolean} reverse
 * @param {number} totalLeafPaths
 * @param {number} horizontalPathLength
 * @param {number[] | null} positionsOnPath
 * @returns
 */
function buildAnimationLookup(
	reverse,
	totalLeafPaths: number,
	horizontalPathLength,
	positionsOnPath,
	titleHeight
): { [AnimationId]: AnimationConstant } {
	// Defines svg lengths and sizes of different elements we need to animate. These are used to calculate animation durations.
	const unitsToAnimate: UnitsToAnimate = {
		[ANIMATION_AREA_ID['initial-path']]:
			LENGTHS.handleLength + LENGTHS.pathBorderRadius * 2 + LENGTHS.preTitleSpace,
		[ANIMATION_AREA_ID['path-out-from-title']]:
			horizontalPathLength + LENGTHS.handleLength + LENGTHS.pathBorderRadius * 2 + titleHeight,
		[ANIMATION_AREA_ID['leaf-from-main-path']]:
			LENGTHS.handleLength + LENGTHS.pathBorderRadius * 2 + titleHeight,
		[ANIMATION_AREA_ID['large-marker']]: LENGTHS.largeNodeMarkerRadius * 6,
		[ANIMATION_AREA_ID['small-marker']]: LENGTHS.smallNodeMarkerRadius * 6,
		[ANIMATION_AREA_ID['option-container']]: LENGTHS.optionContainerHeight,
	}
	const tree = buildTree(reverse, totalLeafPaths, unitsToAnimate, positionsOnPath)

	const AC = {}
	buildMapFromTree(tree, AC)
	return AC
}

/**
 * A map that returns all the animation nodes that will be the building blocks for our animation tree.
 * Note that these tree modes do not have any delays attached to them, as the delays are calculated based off of each nodes location
 * in the tree structure. (root nodes have shortest delays, leaf nodes have longest delays)
 */
const getTreeNodes: UnitsToAnimate => {
	[AnimationId]: AnimationTreeNode,
} = unitsToAnimate => ({
	...new Array(MAX_MULTIPLE_CHOICE_OPTIONS).fill({}).reduce((acc, _, index) => {
		if (index === 0) return acc // skip first option, which is the path from main title
		acc[IDS[`animate-4-${index}-leaf-from-main-path`]] = {
			id: IDS[`animate-4-${index}-leaf-from-main-path`],
			meta: {
				duration: unitsToAnimate['leaf-from-main-path'] * ANIMATION_MS_PER_UNIT,
				unitsToAnimate: unitsToAnimate['leaf-from-main-path'],
			},
		}
		acc[IDS[`animate-4-${index}-marker`]] = {
			id: IDS[`animate-4-${index}-marker`],
			meta: {
				duration: unitsToAnimate['small-marker'] * ANIMATION_MS_PER_UNIT,
				unitsToAnimate: unitsToAnimate['small-marker'],
			},
		}
		acc[IDS[`animate-4-${index}-option-container`]] = {
			id: IDS[`animate-4-${index}-option-container`],
			meta: {
				duration: unitsToAnimate['option-container'] * ANIMATION_MS_PER_UNIT,
				unitsToAnimate: 1,
			},
		}
		return acc
	}, ({}: { [AnimationId]: AnimationTreeNode })),
	[IDS['animate-1-marker']]: {
		id: IDS['animate-1-marker'],
		meta: {
			duration: unitsToAnimate['large-marker'] * ANIMATION_MS_PER_UNIT,
			unitsToAnimate: unitsToAnimate['large-marker'],
		},
	},
	[IDS['animate-2-options-path']]: {
		id: IDS['animate-2-options-path'],
		meta: {
			duration: unitsToAnimate['initial-path'] * ANIMATION_MS_PER_UNIT,
			unitsToAnimate: unitsToAnimate['initial-path'],
		},
	},
	[IDS['animate-3-options-title']]: {
		id: IDS['animate-3-options-title'],
		meta: {
			duration: 100 * ANIMATION_MS_PER_UNIT,
			unitsToAnimate: 1,
		},
	},
	[IDS['animate-4-path-out-from-title']]: {
		id: IDS['animate-4-path-out-from-title'],
		meta: {
			duration: unitsToAnimate['path-out-from-title'] * ANIMATION_MS_PER_UNIT,
			unitsToAnimate: unitsToAnimate['path-out-from-title'],
		},
	},
	[IDS['animate-4-marker']]: {
		id: IDS[`animate-4-marker`],
		meta: {
			duration: unitsToAnimate['small-marker'] * ANIMATION_MS_PER_UNIT,
			unitsToAnimate: unitsToAnimate['small-marker'],
		},
	},
	[IDS['animate-4-option-container']]: {
		id: IDS[`animate-4-option-container`],
		meta: {
			duration: unitsToAnimate['option-container'] * ANIMATION_MS_PER_UNIT,
			unitsToAnimate: 1,
		},
	},
	[IDS['animate-5-display-content']]: {
		id: IDS[`animate-5-display-content`],
		meta: {
			duration: 100 * ANIMATION_MS_PER_UNIT,
			unitsToAnimate: 1,
		},
	},
})

/**
 * The trees main purpose is to define the order of animations. We build the tree using nodes defined in `getTreeNodes` then
 * parse the tree to build the animation constants in buildMapFromTree.
 * @param {boolean} reverse - if true, will animate in reverse, paths will erase as other components fade out or disappear
 * @param {number} totalLeafPaths - total extra option paths to animate besides the main path.
 * @param {UnitsToAnimate} unitsToAnimate - lookup object that defines lengths of paths, sizes of circles, etc. which help us determine animation durations for dynamic sized components.
 * @param {number[]} positionsOnPath - Each extra options position on the main horizontal path. if provided, will animate the extra option paths in the order provided.
 * @returns
 */
function buildTree(
	reverse: boolean,
	totalLeafPaths: number,
	unitsToAnimate: UnitsToAnimate,
	positionsOnPath
): AnimationTreeNode {
	const nodes = getTreeNodes(unitsToAnimate)
	if (reverse) {
		return {
			...nodes['animate-5-display-content'],
			next: [
				{
					...nodes['animate-4-option-container'],
					next: [
						{
							...nodes['animate-4-marker'],
							next: [
								{
									...nodes['animate-4-path-out-from-title'],
									next: [
										{
											...nodes['animate-3-options-title'],
											next: [
												{
													...nodes['animate-2-options-path'],
													next: [nodes['animate-1-marker']],
												},
											],
										},
									],
								},
								...(positionsOnPath
									? new Array(totalLeafPaths).fill({}).map((_, index) => {
											return {
												// $FlowFixMe[prop-missing] - this is a valid id
												...nodes[`animate-4-${index + 1}-option-container`],
												extraDelay: Math.max(
													-100,
													positionsOnPath[totalLeafPaths - 1 - index] * ANIMATION_MS_PER_UNIT
												),
												next: [
													{
														// $FlowFixMe[prop-missing] - this is a valid id
														...nodes[`animate-4-${index + 1}-marker`],
														// $FlowFixMe[prop-missing] - this is a valid id
														next: [nodes[`animate-4-${index + 1}-leaf-from-main-path`]],
													},
												],
											}
									  })
									: []),
							],
						},
					],
				},
			],
		}
	}
	return {
		...nodes['animate-1-marker'],
		extraDelay: TOTAL_DELAY,
		next: [
			{
				...nodes['animate-2-options-path'],
				next: [
					{
						...nodes['animate-3-options-title'],
						next: [
							{
								...nodes['animate-4-path-out-from-title'],
								next: [
									{
										...nodes['animate-4-marker'],
										next: [
											{
												...nodes['animate-4-option-container'],
												next: [nodes['animate-5-display-content']],
											},
										],
									},
								],
							},
							...(positionsOnPath
								? new Array(totalLeafPaths).fill({}).map((_, index) => {
										return {
											// $FlowFixMe[prop-missing] - this is a valid id
											...nodes[`animate-4-${index + 1}-leaf-from-main-path`],
											extraDelay: Math.max(-100, positionsOnPath[index]) * ANIMATION_MS_PER_UNIT,

											next: [
												{
													// $FlowFixMe[prop-missing] - this is a valid id
													...nodes[`animate-4-${index + 1}-marker`],
													// $FlowFixMe[prop-missing] - this is a valid id
													next: [nodes[`animate-4-${index + 1}-option-container`]],
												},
											],
										}
								  })
								: []),
						],
					},
				],
			},
		],
	}
}

/**
 * Reads animation the tree recursively, calculating delays for each animation and adding each animation to a
 * lookup object so we can quickly define css animations.
 * @param {AnimationTreeNode} node - current node in the tree
 * @param { { [string]: AnimationConstant }} AC	- lookup object for animation constants
 * @param {?number} delay- delay for the current node
 */
function buildMapFromTree(node: AnimationTreeNode, AC: { [string]: AnimationConstant }, delay = 0) {
	AC[node.id] = {
		id: node.id,
		durationMs: Math.ceil(node.meta.duration),
		delayMs: Math.ceil(delay + (node.extraDelay || 0)),
		unitsToAnimate: node.meta.unitsToAnimate,
	}
	if (node.next) {
		node.next.forEach(nextNode => {
			buildMapFromTree(nextNode, AC, AC[node.id].delayMs + AC[node.id].durationMs)
		})
	}
}

/* ANIMATION FUNCTIONS -------------------------------------------
 These functions are used to build the css animations for each component with an AnimationId. 
 They use the animation constants to calculate the animation duration and delay.
*/

// Builds css for an svg path draw animation
const getPathAnimation = (
	props: {
		$AC: { [AnimationId]: AnimationConstant },
		$reverse: boolean,
	},
	key: AnimationId
) => {
	const { $AC: AC, $reverse: reverse } = props
	const animationConstant = AC[key]
	if (!animationConstant) {
		return ''
	}
	const draw = keyframes`
	from {
		stroke-dashoffset: ${animationConstant.unitsToAnimate};
	} to {
		stroke-dashoffset: 0;
	}
	`
	const erase = keyframes`
		from {
			stroke-dashoffset: 0;
		} to {
			stroke-dashoffset: ${animationConstant.unitsToAnimate};
		}

	`
	return css`
		stroke-dashoffset: ${animationConstant.unitsToAnimate};
		stroke-dasharray: ${animationConstant.unitsToAnimate};
		animation: ${reverse ? erase : draw} ${animationConstant.durationMs}ms linear
			${animationConstant.delayMs}ms 1 ${reverse ? 'backwards' : 'forwards'};
	`
}

// Builds css for an opacity animation (fades in on appear, fades out on disappear)
const getOpacityAnimation = (
	props: { $AC: { [AnimationId]: AnimationConstant }, $reverse: boolean },
	key: AnimationId
) => {
	const { $AC: AC, $reverse: reverse } = props
	const animationConstant = AC[key]
	if (!animationConstant) {
		return ''
	}
	return css`
		opacity: ${reverse ? 1 : 0};
		animation: ${reverse ? fadeOut : fadeIn} ${animationConstant.durationMs}ms linear
			${animationConstant.delayMs}ms 1 forwards;
	`
}
// Builds css for a scaling animation (grows in on appear, shrinks out on disappear)
const getScalingAnimation = (
	props: {
		$AC: { [AnimationId]: AnimationConstant },
		$reverse: boolean,
	},
	key: AnimationId
) => {
	const { $AC: AC, $reverse: reverse } = props
	const animationConstant = AC[key]
	if (!animationConstant) {
		return ''
	}
	return css`
		transform: scale(${reverse ? 1 : 0});
		animation: ${reverse ? shrink : growIn} ${animationConstant.durationMs}ms linear
			${animationConstant.delayMs}ms 1 forwards;
	`
}
// These styles are applied to the AnimationWrapper component and are used to apply the animations to the appropriate components.
const AnimationStyles = styled.div`
	display: flex;
	${props => {
		let cssDefs = css`
			path#${IDS['animate-2-options-path']} {
				${getPathAnimation(props, IDS['animate-2-options-path'])}
			}

			#${IDS['animate-3-options-title']} {
				${getOpacityAnimation(props, IDS['animate-3-options-title'])}
			}

			path#${IDS['animate-4-path-out-from-title']} {
				${getPathAnimation(props, IDS['animate-4-path-out-from-title'])}
			}

			#${IDS['animate-4-option-container']} {
				${getScalingAnimation(props, IDS['animate-4-option-container'])}
			}

			#${IDS['animate-4-marker']} {
				${getScalingAnimation(props, IDS['animate-4-marker'])}
			}

			#${IDS['animate-1-marker']} {
				${getScalingAnimation(props, IDS['animate-1-marker'])}
			}

			.${IDS['animate-5-display-content']} {
				${getOpacityAnimation(props, IDS['animate-5-display-content'])}
			}
		`
		for (let i = 0; i < MAX_MULTIPLE_CHOICE_OPTIONS; i++) {
			if (i === 0) continue
			cssDefs = css`
				${cssDefs}
				path#${IDS[`animate-4-${i}-leaf-from-main-path`]} {
					${getPathAnimation(props, IDS[`animate-4-${i}-leaf-from-main-path`])}
				}

				#${IDS[`animate-4-${i}-option-container`]} {
					${getScalingAnimation(props, IDS[`animate-4-${i}-option-container`])}
				}
				#${IDS[`animate-4-${i}-marker`]} {
					${getScalingAnimation(props, IDS[`animate-4-${i}-marker`])}
				}
		
			`
		}
		return cssDefs
	}}
`
