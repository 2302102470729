import React from 'react'

export default function CoordinatePlane({
	radius,
	midY,
}: {
	radius: number,
	midY: number,
}): React$Node {
	return (
		<div
			className="coordinate-plane"
			style={{ width: radius * 2, height: radius * 2, bottom: midY }}>
			<div className="zero-180" style={{ width: radius * 2 }}>
				<div className="number"> 180° </div>
				<div className="line" />
				<div className="number"> 0° </div>
			</div>
			<div className="fortyFive-90" style={{ width: radius, height: radius, bottom: radius / 5 }}>
				<div className="number fortyFive"> 45° </div>
				<div className="box" style={{ width: radius, height: radius }} />
				<div className="number oneThirtyFive"> 135° </div>
			</div>
			<div style={{ height: radius }} className="ninety">
				<div className="number"> 90° </div>
				<div className="line" />
			</div>
		</div>
	)
}
