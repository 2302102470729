import React, { Component } from 'react'
import Asteroid from '../../images/communicationSatellite.svg'
import CollisionTypes from './collision'
import SAT from 'sat'
import { getX, getY } from './coordinateSystem'

import type { ReactObjRef } from '../../types'

type Props = {
	angle: number,
	radius: number,
	canvasWidth: number,
	canvasHeight: number,
	bottomOffset: number,
	wasHit: boolean,
	radialOffset?: number,
	animationHitStyle: string,
}

type State = {
	width: number,
	height: number,
}

export default class Target extends Component<Props, State> {
	imgRef: ReactObjRef<'img'>

	constructor(props: Props) {
		super(props)

		this.state = { width: 100, height: 100 }

		this.imgRef = React.createRef()
	}

	componentDidMount() {
		window.addEventListener('resize', this.updateDimensions)

		this.updateDimensions()
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.updateDimensions)
	}

	updateDimensions: () => void = () => {
		this.setState({
			width: (this.imgRef.current && this.imgRef.current.clientWidth) || 100,
			height: (this.imgRef.current && this.imgRef.current.clientHeight) || 100,
		})
	}

	render(): React$Node {
		const {
			angle,
			radius,
			canvasWidth,
			canvasHeight,
			bottomOffset,
			radialOffset: _radialOffset,
			wasHit,
			animationHitStyle,
		} = this.props
		const { width, height } = this.state
		const radialOffset = _radialOffset ?? 0

		return (
			<img
				src={Asteroid}
				ref={this.imgRef}
				alt="dish base"
				className="target"
				style={{
					position: 'absolute',
					left:
						canvasWidth / 2 +
						getX(
							angle,
							radius,
							canvasWidth / 2 - width / 2,
							canvasHeight - height / 2,
							radialOffset + width / 2,
							radialOffset + height / 2
						) -
						width / 2 +
						'px',
					bottom:
						getY(
							angle,
							radius,
							canvasWidth / 2 - width / 2,
							canvasHeight - height / 2,
							radialOffset + width / 2,
							radialOffset + height / 2
						) +
						bottomOffset -
						height / 2 +
						'px',
					width: '7vw',
					animation: wasHit ? animationHitStyle : '',
				}}
			/>
		)
	}

	getCollision: () => { type: string, collidable: boolean, bounds: any } = () => {
		const { current } = this.imgRef
		if (current) {
			let position = current.getBoundingClientRect()
			return {
				type: CollisionTypes.POLYGON,
				collidable: true,
				bounds: new SAT.Polygon(new SAT.Vector(position.left, position.top), [
					new SAT.Vector(),
					new SAT.Vector(position.width, 0),
					new SAT.Vector(position.width, position.height),
					new SAT.Vector(0, position.height),
				]),
			}
		}

		return {
			type: CollisionTypes.CIRCLE,
			collidable: false,
			bounds: new SAT.Circle(new SAT.Vector(Infinity, 0), 10),
		}
	}
}
