import asteroid from '../images/asteroid1-temp.svg'
import enemyShip from '../images/enemyShip.png'
import bullseye from '../images/Target.svg'
import satellite from '../images/satellite.png'

import type { TractorBeamStatus, DefenseStatus } from '../types'

export const STATUS_TO_IMAGE = {
	ASTEROID: asteroid,
	ENEMY_SHIP: enemyShip,
	TARGET: bullseye,
	SATELLITE: satellite,
}

export function getDimensions(
	status: TractorBeamStatus | DefenseStatus | 'SATELLITE'
): [number, number] {
	const sideDimension = window.innerHeight * 0.15
	if (status === 'ASTEROID') {
		return [sideDimension, sideDimension]
	}

	if (status === 'ENEMY_SHIP') {
		return [window.innerWidth * 0.06, sideDimension]
	}

	if (status === 'SATELLITE') {
		return [240, 130]
	}
	if (status === 'TARGET') {
		return [sideDimension, sideDimension]
	}

	return [160, 160]
}
