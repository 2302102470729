import React from 'react'
import styled from 'styled-components/macro'
import { LIGHT_GREEN } from '../../../constants/styles'

const volumeToSliderValue = volume => volume * 100
const sliderValueToVolume = value => value / 100

type VolumeSliderProps = {
	volume: number,
	handleChange: number => void,
	className?: string,
	backgroundColor?: string,
	color?: string,
	inputColor?: string,
}

/**
 * A Display for a single volume type
 * @param {VolumeSliderProps} props props passed to this component
 * @returns {React$Node} the display with a slider
 */
export function VolumeSlider(props: VolumeSliderProps): React$Node {
	const { volume, handleChange, className, color, backgroundColor, inputColor } = props
	return (
		<Slider
			className={className}
			min={0}
			max={100}
			value={volumeToSliderValue(volume)}
			onChange={e => handleChange(sliderValueToVolume(e.target.value))}
			$backgroundColor={backgroundColor}
			$color={color}
			$inputColor={inputColor}
			type="range"
		/>
	)
}

const Slider = styled.input`
	appearance: none;
	width: 100%;
	border-radius: 4px;
	opacity: 1;
	outline: none;
	margin: 8px auto;
	${({ $backgroundColor, $color, value, max, min }) => `
		height: 8px;
		background: -webkit-linear-gradient(left, ${$color || LIGHT_GREEN} ${((value - min) / (max - min)) *
		100}%, ${$backgroundColor || 'white'} ${((value - min) / (max - min)) * 100}% );
	`}
	&::-webkit-slider-thumb {
		cursor: pointer;
		appearance: none;

		background: ${({ $inputColor }) => $inputColor || LIGHT_GREEN};
		height: ${({ theme }) => theme.spacing2x};
		width: ${({ theme }) => theme.spacing2x};

		border-radius: 50%;
	}
`
