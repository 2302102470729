// @flow
import update from 'immutability-helper'
import createReducer, { type Reducer } from '../createReducer'

import type { Coordinates, StationEnum } from '../../types'

export const ADD_POINT_EVENT = 'ADD_POINT_EVENT'
export const REMOVE_POINT_EVENT = 'REMOVE_POINT_EVENT'
export const POINT_EVENT_RESULT = 'POINT_EVENT_RESULT'

export type PointEventLocation = Coordinates | { x: string, y: string }
export type PointEvent = {
	id: string,
	location: PointEventLocation,
	station: ?StationEnum,
	amount: ?number,
	boostAmount: ?number,
}

type TrimmedPointEvent = {
	id: string,
	station: ?StationEnum,
	location: PointEventLocation,
}
type AddPointEventAction = {|
	type: 'ADD_POINT_EVENT',
	payload: TrimmedPointEvent,
|}
type PointEventResultAction = {
	type: 'POINT_EVENT_RESULT',
	payload: {
		id: string,
		pointDifference: number,
		boostAmount: ?number,
		currentStation: ?StationEnum,
	},
}

type RemovePointEventAction = {
	type: 'REMOVE_POINT_EVENT',
	payload: string,
}

export function addPointEvent(pointEvent: TrimmedPointEvent): AddPointEventAction {
	return {
		type: ADD_POINT_EVENT,
		payload: pointEvent,
	}
}

export function removePointEvent(id: string): RemovePointEventAction {
	return {
		type: REMOVE_POINT_EVENT,
		payload: id,
	}
}

export type PointsStore = {
	pointEvents: PointEvent[],
}

/**
 * Gets the initial state for the `points` redux store
 */
export function getInitialState(): PointsStore {
	return {
		pointEvents: [],
	}
}

export default (createReducer<PointsStore>(getInitialState(), {
	[ADD_POINT_EVENT]: (state: PointsStore, { payload: pointEvent }: AddPointEventAction) => {
		return {
			...state,
			pointEvents: [...state.pointEvents, { ...pointEvent, amount: null, boostAmount: null }],
		}
	},
	[REMOVE_POINT_EVENT]: (state: PointsStore, { payload: eventId }: RemovePointEventAction) => {
		return update(state, { pointEvents: events => events.filter(event => event.id !== eventId) })
	},
	[POINT_EVENT_RESULT]: (
		state: PointsStore,
		{
			payload: { id: pointEventId, pointDifference, boostAmount, currentStation },
		}: PointEventResultAction
	) => {
		const pointEventIndex = state.pointEvents.findIndex(event => event.id === pointEventId)
		if (pointEventIndex < 0) {
			return state
		}
		const shouldRemoveEvent =
			currentStation !== state.pointEvents[pointEventIndex].station || pointDifference === 0

		const updateObject = shouldRemoveEvent
			? { pointEvents: { $splice: [[1, 1]] } }
			: {
					pointEvents: {
						[pointEventIndex]: {
							amount: { $set: pointDifference },
							boostAmount: { $set: boostAmount },
						},
					},
			  }

		return update(state, updateObject)
	},
}): Reducer<PointsStore>)

// SELECTORS

export function getPointEventsToDisplay({ points }: { points: PointsStore }): PointEvent[] {
	return points.pointEvents.filter(event => event.amount)
}
