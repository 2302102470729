import React from 'react'
import { useSelector } from 'react-redux'
import type { StationEnum } from '../../../types'
import { getStationActivity } from '../../../utility/selectors'
import { isGivenStationInQuestionPhase } from '../../StationUpgradeOverlay/selectors'

type Props = {
	station: StationEnum,
	style?: {},
}

/**
 * An overlay that appears above the station when a station is not active.
 * @param {{}} props.style
 * @param {StationEnum} props.station the station specifically to display the overlay for
 * @returns
 */
export default function InactiveDisplay({ style, station }: Props): React$Node {
	const isStationActive = useSelector(getStationActivity)[station]
	const isStationInQuestionPhase = useSelector(state =>
		isGivenStationInQuestionPhase(state, station)
	)
	if (isStationInQuestionPhase) {
		return null
	}
	if (isStationActive) {
		return null
	}
	return (
		<div
			className="absolute z-[var(--z-index-overlay)] w-full h-full flex flex-col top-0 left-0 bottom-0 right-0 bg-primary-800/90"
			style={style}>
			<h1 className="text-6xl my-auto mx-0 text-center text-error">System not active</h1>
		</div>
	)
}
