import React from 'react'
export const BASE_HEIGHT = 50
export const HIT_ANIMATION_TIME = 2000
export const DESTROYED_ANIMATION_TIME = 2000
export const FORGET_DESTROYED_IDS_TIME = DESTROYED_ANIMATION_TIME * 2 // used to remove ids from the defense screen in case the station gets reset by going back to a checkpoint
export const RESPAWN_DELAY_TIME = 2000
export const TARGET_STATUS = {
	HIT: 'HIT',
	ACTIVE: 'ACTIVE',
	DESTROYED: 'DESTROYED',
	RECOVERED: 'RECOVERED',
}
export const DefaultWrapper = ({ children }: { children: React$Node }): React$Node => {
	return <g>{children}</g>
}

export type TargetStatus = $Keys<typeof TARGET_STATUS>
