import React from 'react'
import 'styled-components/macro'
import Markdown from '../../Markdown'

/**
 * This component is for displaying the text event input during a literacy event.
 * @returns {React$Node}
 */
export default function TextEventInput({
	text,
	title,
}: {
	text: string,
	title: string,
}): React$Node {
	return (
		<div css="padding: var(--spacing2x);">
			{title && <h3 css="margin-top: 0; color: inherit;">{title}</h3>}
			<Markdown textToSpeechPosition="start" disabledComponents={['a', 'img']}>
				{text}
			</Markdown>
		</div>
	)
}
