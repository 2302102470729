import React, { useEffect, useState, useRef } from 'react'
import { useDrop, useDrag } from 'react-dnd'
import { useSpring, animated } from '@react-spring/web'
import { getEmptyImage } from 'react-dnd-html5-backend'
import type { Vertex } from '../../store/stores/sharedGameStateTypes'
import type { Point } from '../../types'
import { usePrevious } from '../../utility/hooks'
import './ConnectionEndpoint.css'

type Props<U> = {
	addLine: (U, U, { x: number, y: number }, { x: number, y: number }) => void,
	beginDrag: U => void,
	endDrag: () => void,
	hasConnection: boolean,
	setLinkPosition: Point => mixed,
	canDropItem: (Props<U>, Object) => boolean,
	canDragItem?: boolean,

	identifier: {},
	data: Vertex,
	id?: number,
}
const ENDPOINT = 'ENDPOINT'

function getRectFromRef(ref) {
	return ref.current ? ref.current.getBoundingClientRect() : null
}

export function ConnectionEndpoint<U>({
	addLine,
	beginDrag,
	endDrag,
	hasConnection,
	setLinkPosition,
	canDropItem,
	canDragItem,
	identifier,
	data,
	id,
}: Props<U>): React$Node {
	const ref = useRef(null)
	let boundingRect = getRectFromRef(ref)
	useEffect(() => {
		let newBoundingRect = getRectFromRef(ref)
		if (newBoundingRect) {
			setLinkPosition([
				newBoundingRect.x + newBoundingRect.width / 2,
				newBoundingRect.y + newBoundingRect.height / 2,
			])
		}
	}, [boundingRect, setLinkPosition])
	const [, connectDrag, preview] = useDrag({
		item: { id, type: ENDPOINT },
		canDrag: () => {
			return canDragItem !== undefined ? !hasConnection && canDragItem : !hasConnection
		},
		begin: () => {
			beginDrag(identifier)
			return identifier
		},
		end: (_, monitor) => {
			endDrag()
			if (!monitor.didDrop() || !ref.current) return
			const point1 = ref.current.getBoundingClientRect()
			const point2 = monitor.getDropResult().location
			addLine(monitor.getItem(), monitor.getDropResult().identifier, point1, point2)
		},
		previewOptions: {
			disabled: true,
		},
	})
	const [, connectDrop] = useDrop({
		accept: ENDPOINT,
		drop: () => {
			return {
				location: ref.current.getBoundingClientRect(),
				identifier,
			}
		},
		canDrop: (item, monitor) => {
			return canDropItem(item, monitor)
		},
	})

	connectDrag(ref)
	connectDrop(ref)

	useEffect(() => {
		preview(getEmptyImage())
	}, [preview])

	const [local, setLocal] = useState(data)

	return (
		<div ref={ref} style={{ gridColumnStart: local.column, gridRowStart: local.row }}>
			<DisappearingPoint
				setLocal={setLocal}
				data={data}
				id={'ConnectionEndpoint' + String(id)}
				className={local.color ? 'ConnectionEndpoint ' + local.color : 'ConnectionEndpoint'}
			/>
		</div>
	)
}

const DisappearingPoint = ({
	setLocal,
	data,
	...rest
}: {
	setLocal: ({}) => void,
	data: Vertex,
}) => {
	const [visible, setVisible] = useState(true)
	const prevData = usePrevious(data)

	useEffect(() => {
		if (prevData) {
			setLocal(prevData)
			setVisible(false)
		}
		// We do not want to add prevData to the dependency array, but eslint throws an error. We only run this effect when data changes.
		// eslint-disable-next-line
	}, [data.column, data.row, data.color, setLocal, setVisible])

	const reRender = () => {
		setLocal(data)
		setVisible(true)
	}

	const springProps = useSpring({
		config: { duration: 3000 },
		onRest: reRender,
		from: { opacity: visible ? 0 : 1 },
		opacity: visible ? 1 : 0,
	})
	return <animated.div {...rest} style={springProps} />
}

export default ConnectionEndpoint
