import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import ImportedJoyride, { ACTIONS, EVENTS } from 'react-joyride'
import { isTraining } from '../store/stores/general'
import { steps } from '../constants/TrainingModeSteps'
import { getStepIndex, useOptimisticAdvanceTraining } from '../store/selectors/jrPlusState/training'

type CallbackProps = {
	action: string,
	index: number,
	type: string,
}

const TrainingBanner: StyledType<> = styled.div`
	width: 50%;
	height: fit-content;
	border-radius: 3px;
	display: flex;
	justify-content: center;
	z-index: 10;
	position: absolute;
	margin: auto;
	left: 0;
	right: 0;
	font-family: 'Orbitron', sans-serif;
	background-color: #5d578d;
	color: red;
	font-size: 35px;
`
const RESTART_INDEX = 0
const MAX_PAUSE_EVENT_TRIES = 5

export default function Joyride(): React$Node {
	const isTrainingMode = useSelector(isTraining)
	const [showTraining, setShowTraining] = useState(isTrainingMode)
	const advanceTraining = useOptimisticAdvanceTraining()
	const [shouldRestart, setShouldRestart] = useState(false)
	const currentStepIndex = useSelector(getStepIndex)
	const [pauseTour, setPauseTour] = useState(false)
	let pauseTries = 0

	useEffect(() => {
		if (shouldRestart && currentStepIndex === 0) {
			setShouldRestart(false)
		}
	}, [currentStepIndex, shouldRestart, setShouldRestart])

	return !showTraining ? (
		<TrainingBanner>TRAINING MODE ACTIVE</TrainingBanner>
	) : (
		<ImportedJoyride
			callback={(e: CallbackProps) => {
				if (shouldRestart) {
					return
				}
				let stepIndex = e.index
				if (EVENTS.STEP_AFTER === e.type) {
					const isLastTrainingCard = steps.length - 1 === e.index
					const advanceTrainingData = {}
					if (isLastTrainingCard) {
						if (e.action === ACTIONS.PREV) {
							// we are on the last training card and want to restart
							advanceTrainingData.nextStepIndex = RESTART_INDEX
						} else setShowTraining(false)
					} else {
						if (e.action === ACTIONS.PREV) {
							// we are just going to the previous training card
							if (e.index < 2) {
								advanceTrainingData.nextStepIndex = RESTART_INDEX
							} else {
								advanceTrainingData.nextStepIndex = e.index - 1
							}
						}
					}
					// prevent joyride's dual state updating to ping the server twice per click
					const nextStepIndex = !isLastTrainingCard ? stepIndex + 1 : null
					if (currentStepIndex !== nextStepIndex) {
						advanceTraining(advanceTrainingData)
					}
				}
				if ([EVENTS.TOUR_START, EVENTS.TARGET_NOT_FOUND].includes(e.type)) {
					const isAsync = steps[e.index]?.async ?? false
					if (isAsync && e.type === EVENTS.TARGET_NOT_FOUND && pauseTries < MAX_PAUSE_EVENT_TRIES) {
						/* By pausing and un-pausing the tour, we create a pocket of time for the target of the ANSWER_QUESTION event to appear.
							When the tour unpauses, joyride will attempt to find the target of the ANSWER_QUESTION event again and will
							most likely succeed. In order to avoid an infinite loop (due to joyride failing to find the target every time)
							we count the total tries `answerQuestionEventTries` which ensures this pause only occurs MAX_ANSWER_QUESTION_EVENT_TRIES.  
						*/
						pauseTries += 1
						setPauseTour(() => {
							setTimeout(() => {
								setPauseTour(false)
							}, 400)
							return true
						})
					} else if (!shouldRestart) {
						setShouldRestart(true)
						advanceTraining({ nextStepIndex: RESTART_INDEX })
					}
				}
			}}
			continuous
			disableCloseOnEsc
			disableOverlayClose
			disableScrolling
			disableScrollParentFix
			showProgress
			steps={steps}
			run={!pauseTour}
			stepIndex={shouldRestart ? 0 : currentStepIndex}
			styles={{
				options: { zIndex: 1003 },
				buttonClose: { display: 'none' },
				tooltip: { fontSize: 25 },
				tooltipContent: { padding: 0 },
				tooltipFooter: { justifyContent: 'center' },
				tooltipFooterSpacer: { flex: 0 },
			}}
		/>
	)
}
