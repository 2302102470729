// @flow
import React, { useEffect, memo, type AbstractComponent } from 'react'

import TitleTooltip, { TooltipContainer } from './TitleTooltip'
import { CLIP_PATH_URL, type InfoForOtherActions } from '../helpers/constants'
import { getDOMIdFromMapObjectId } from '../helpers/functions'
import type { ObjectInfo as ObjectInformation } from '@mission.io/mission-toolkit'
import ActionUpdates from './ActionUpdates'

type RenderComponentProps = {
	className: string,
	x: number,
	y: number,
	width: number,
	height: number,
	onClick: (e: SyntheticMouseEvent<>) => void,
}

export type Props = {
	mapObjectId: string,
	amIScanning: boolean,
	onClick: (e: SyntheticMouseEvent<>, id: string) => void,
	component: React$ComponentType<RenderComponentProps>,
	objectInfo: ?ObjectInformation,
	centeredX: number,
	centeredY: number,
	sizes: [number, number],
	name: string,
	setHover: (boolean, id: string) => void,
	selected: boolean,
	subtitle?: ?string,
	infoForOtherActions: Array<InfoForOtherActions>,
}

function MapObject({
	component: Component,
	mapObjectId,
	amIScanning,
	onClick,
	objectInfo,
	sizes,
	centeredX,
	centeredY,
	name,
	setHover,
	selected,
	subtitle,
	infoForOtherActions,
}: Props) {
	useEffect(() => {
		if (selected) {
			setHover(false, mapObjectId)
		}
	}, [selected, mapObjectId, setHover])

	return (
		<g>
			<TooltipContainer clipPath={CLIP_PATH_URL}>
				<Component
					id={getDOMIdFromMapObjectId(mapObjectId)}
					/* mapObjectId as a className is required for Training mode */
					className={`clickable ${mapObjectId}`}
					x={centeredX}
					y={centeredY}
					width={sizes[0]}
					height={sizes[1]}
					onClick={e => {
						onClick(e, mapObjectId)
					}}></Component>
				{!selected && (
					<>
						<TitleTooltip x={centeredX} y={centeredY} text={name} subtitle={subtitle} />
					</>
				)}
			</TooltipContainer>
			{objectInfo && (
				<ActionUpdates
					x={centeredX + sizes[0] / 2}
					y={centeredY + sizes[1]}
					sizes={sizes}
					objectInfo={objectInfo}
					amIScanning={amIScanning}
					infoForOtherActions={infoForOtherActions}
				/>
			)}
		</g>
	)
}

export default (memo<Props>(MapObject): AbstractComponent<Props>)
