const GOOGLE_TRANSLATE_URL: string =
	'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit'
const GOOGLE_TRANSLATE_COOKIE_NAME: string = 'googtrans'
const englishWords: Array<string> = ['English', 'inglés']

const googleAriaLabelSelector: Array<string> = [
	'Language Translate Widget',
	'Widget de idiomas del Traductor',
]

export { GOOGLE_TRANSLATE_URL, englishWords, googleAriaLabelSelector, GOOGLE_TRANSLATE_COOKIE_NAME }
