import React, { Component } from 'react'
import { DropTarget } from 'react-dnd'
import shipImage from '../../images/ship.svg'

import type { System } from '@mission.io/mission-toolkit'
import { WorkingRobot } from './Robots'
import { mapObject } from '../../utility/functions'

import { ROBOT } from '../../constants'
import type { Robots, RobotData } from './Robots/types'

import './ShipRepairs.css'

const COLLISION_SIZE = 100

type Prop = {
	robots: Robots,
	myBrokenSystems: { [string]: System },
	fixBrokenSystem: Function,
}

export default class ShipRepairs extends Component<Prop> {
	render(): React$Node {
		return (
			<div className="ShipRepairs" style={{ backgroundImage: `url(${shipImage})` }}>
				<div className="ship-size-container">
					{mapObject(this.props.myBrokenSystems, brokenSystem => {
						return brokenSystem.fixed ? null : (
							<BrokenSystem
								robot={brokenSystem.robotId ? this.props.robots[brokenSystem.robotId] : null}
								brokenSystem={brokenSystem}
								key={brokenSystem.id}
								robotId={brokenSystem.robotId}
								fixBrokenSystem={(robotId: string) => {
									this.props.fixBrokenSystem(brokenSystem.id, robotId, brokenSystem.point)
								}}
							/>
						)
					})}
				</div>
			</div>
		)
	}
}

// Broken System for the robot to fix

type BrokenSystemProps = {
	brokenSystem: System,
	robot: ?RobotData,
	connectDropTarget: React$Node => React$Node,
	isOver: boolean,
	robotId: string,
	fixBrokenSystem: Function,
}

class BrknSystem extends Component<BrokenSystemProps> {
	render() {
		return this.props.connectDropTarget(
			<div
				className={this.props.robotId ? '' : 'blinking'}
				style={getBrokenSystemStyle(this.props.brokenSystem, this.props.isOver)}>
				{this.props.robotId && this.props.robot ? (
					<WorkingRobot
						id={this.props.robotId}
						systemId={this.props.brokenSystem.id}
						dimensions={this.props.robot?.dimensions}
					/>
				) : (
					''
				)}
			</div>
		)
	}
}

const brokenSystemTarget = {
	drop(props: BrokenSystemProps) {
		return {
			fixSystem: props.fixBrokenSystem,
		}
	},
	canDrop(props) {
		return !props.brokenSystem.robotId
	},
}

function collect(connect, monitor) {
	return {
		connectDropTarget: connect.dropTarget(),
		isOver: monitor.isOver(),
	}
}

const BrokenSystem = DropTarget(ROBOT, brokenSystemTarget, collect)(BrknSystem)

/**
 * This function helps determine the size of the blinking dot
 *	so size is how big the issue's blink is. FunFact the robot can be dropped
 *	to fix an issue anywhere within the size radius. (So size 1000 means after the blink hits 1000,
 *	the robot can be dropped almost anywhere in the screen and it will fix the issue)
 *
 */
function getBrokenSystemStyle(brokenSystem: System, isOver: boolean) {
	const size = isOver && !brokenSystem.robotId ? 100 : 25
	return {
		position: 'absolute',
		backgroundImage: `radial-gradient(${size / 2}px, rgb(255, 0, 0), rgba(255, 0, 0, 0))`,
		top: `calc(${brokenSystem.point[1]}% - ${COLLISION_SIZE / 2}px)`,
		left: `calc(${brokenSystem.point[0]}% - ${COLLISION_SIZE / 2}px)`,
		padding: `${COLLISION_SIZE / 2}px`,
		width: '0px',
		height: '0px',
	}
}
