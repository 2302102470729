import React, { type ComponentType } from 'react'
import { forIn, isFinite, clamp } from 'lodash'
import { useSelector } from 'react-redux'
import { MISSION_TYPES } from '@mission.io/mission-toolkit/constants'

import { getMissionType } from '../../store/stores/general'
import type { StudentAnalytics, MissionType } from '@mission.io/mission-toolkit'
import {
	BasicModalContent,
	QuestionReviewModalContent,
	CMReviewModalContent,
} from './CategoryModal'

type StudentAnalyticsCategory = mixed

type Category = {
	title: string,
	controlSets: MissionType[],
	key: string,
	accessor: StudentAnalytics => StudentAnalyticsCategory,
	ModalContent: ComponentType<*>,
	background: string,
}

/**
 * An array of objects that represent each category from which a student was scored.
 * Each object has valuable data for displaying the category to the student.
 */
const CATEGORIES: Array<Category> = [
	{
		title: 'Knowledge',
		controlSets: ['K-3', '4+'],
		key: 'knowledge',
		accessor: student => student.knowledge?.questions,
		ModalContent: QuestionReviewModalContent,
		background: 'linear-gradient(180deg, #D06AB0 0%, rgba(208, 106, 176, 0) 100%)',
	},
	{
		title: 'Application',
		controlSets: ['K-3', '4+'],
		key: 'application',
		accessor: student => student.knowledge?.application,
		ModalContent: CMReviewModalContent,
		background: 'linear-gradient(180deg, #D06AB0 0%, rgba(208, 106, 176, 0) 100%)',
	},
	{
		title: 'Initiative',
		key: 'initiative',
		controlSets: ['K-3', '4+'],
		accessor: student => student.disposition?.initiative,
		ModalContent: function Content(props: { title: string }) {
			return (
				<BasicModalContent
					{...props}
					suggestions={{
						'K-3': [
							'Respond more quickly to repairs when they appear',
							'When a ship station turns on, start working on it more quickly',
						],
						'4+': [
							'Respond more quickly to repairs when they appear',
							'When a ship system activates, start working on it more quickly',
							'Always be participating in scans when they are available',
						],
					}}
				/>
			)
		},
		background: 'linear-gradient(180deg, #4F9D99 0%, rgba(79, 157, 153, 0) 100%)',
	},
	{
		title: 'Collaboration',
		controlSets: ['4+'],
		key: 'collaboration',
		accessor: student => student.skills?.collaboration,
		ModalContent: function Content(props: { title: string }) {
			return (
				<BasicModalContent
					{...props}
					suggestions={{
						'K-3': [],
						'4+': [
							'Complete repair tasks more quickly',
							'Earn higher ratings from others on health tasks',
							'Be a better teammate (your team rates you)',
						],
					}}
				/>
			)
		},
		background: 'linear-gradient(180deg, #4E83D3 0%, rgba(78, 131, 211, 0) 100%)',
	},
	{
		title: 'Critical Thinking',
		controlSets: ['4+'],
		key: 'criticalThinking',
		accessor: student => student.skills?.criticalThinking,
		ModalContent: function Content(props: { title: string }) {
			return (
				<BasicModalContent
					{...props}
					suggestions={{
						'K-3': [],
						'4+': [
							'Accurately vote on all collected data‘s relevancy.',
							'Make sure the vote that you give data is accurate',
							'Pick a correct answer on critical decisions without changing your vote',
						],
					}}
				/>
			)
		},
		background: 'linear-gradient(180deg, #4E83D3 0%, rgba(78, 131, 211, 0) 100%)',
	},
	{
		title: 'Resilience',
		controlSets: ['K-3', '4+'],
		key: 'grit',
		accessor: student => student.disposition?.grit,
		ModalContent: function Content(props: { title: string }) {
			return (
				<BasicModalContent
					{...props}
					suggestions={{
						'K-3': [
							'After something hard, don‘t give up!',
							'Participate in every activity, even when it’s hard',
						],
						'4+': [
							'After a death or incorrect choice, get more involved in the mission',
							'Participate in every available activity, even when it’s hard',
							'When you lose health, quickly complete tasks to get it back',
						],
					}}
				/>
			)
		},

		background: 'linear-gradient(180deg, #4F9D99 0%, rgba(79, 157, 153, 0) 100%)',
	},
]
const K3Categories = CATEGORIES.filter(category => category.controlSets.includes('K-3'))

/**
 * A hook that returns analytics categories used based on mission type
 * @returns {Array<Category>}
 */
export function useCategories(): Array<Category> {
	const k3Mission = useSelector(getMissionType) === MISSION_TYPES.K_THROUGH_3
	if (k3Mission) {
		return K3Categories
	}
	return CATEGORIES
}

/**
 * Takes the studentAnalytic scores, and generates the proper SEL score
 * based off of the weight scores of the mission.
 */
export function calculateWeightedScore(
	items: ?StudentAnalyticsCategory,
	weights: { [string]: { [string]: number } }
): ?number {
	let total = 0
	let totalWeight = 0
	if (items != null) {
		if (typeof items === 'object') {
			forIn(items, (value, key) => {
				const weight = weights[key] ?? 1
				if (isFinite(value)) {
					total += value * weight
					totalWeight += weight
				} else {
					if (value != null && isFinite(value.score)) {
						total += value.score * weight
						totalWeight += weight
					}
				}
			})
		} else if (typeof items === 'number') {
			total = items
			totalWeight = 1
		}
		const score = total / totalWeight
		return isFinite(score) ? score * 100 : null
	}
	return null
}

/**
 * Displays the category score based off the analytics category object for a student
 * @param {mixed} studentAnalyticsCategory a group of scores for a specific category (the return value of the Category.accessor() function)
 * @param {[key:string]: number} weights definitions for the weights of the category
 * @returns
 */
export function getAnalyticsCategoryScore(
	studentAnalyticsCategory: StudentAnalyticsCategory,
	weights: { [string]: { [string]: number } } = {}
): ?number {
	const score = calculateWeightedScore(studentAnalyticsCategory, weights)
	return translatePercentageToProficiencyLevel(score)
}

/**
 * Translates a percentage to a number between 1 and 4.99 based off of the
 * thresholds where a
 * 1 starts at 0%,
 * 2 starts at 50%,
 * 3 starts at 80%,
 * 4 starts at 95%
 */
const translateScore: (?number) => ?number = (num: ?number) => {
	if (num == null) return null
	const percentageScores = [0, 50, 80, 95]
	const proficiencyScores = [1, 2, 3, 4]
	const index = percentageScores.findIndex(
		(baseline, i, list) => (i + 1 === list.length || num < list[i + 1]) && num >= list[i]
	)
	if (index > -1) {
		const MAX_SCORE = 100
		// 1, 2, 3, or 4
		const proficiencyBase = proficiencyScores[index]
		const percentageBase = percentageScores[index]
		// Convert percentage excess to decimal places after the proficiency base.
		// Note: It could be possible for a student to score a 5 proficiency if their score is >=100.
		const baselinePercentageDiff = (percentageScores[index + 1] ?? MAX_SCORE) - percentageBase
		const scorePercentageDiff = Math.min(num, MAX_SCORE) - percentageBase
		return proficiencyBase + scorePercentageDiff / baselinePercentageDiff
	}
	return null
}

/**
 * Gets an integer between 1 and 4 to represent a student's score.
 * @param {?number} exactProficiency proficiency score with a decimal value.
 * @return {?number} proficiency score without decimal places. or null if the exact proficiency is null
 */
const getBaseProficiency: (?number) => number | null = (exactProficiency: ?number) => {
	if (exactProficiency) {
		return clamp(Math.floor(exactProficiency), 1, 4)
	}
	return null
}

/**
 * Translates a percentage score into an integer: 1, 2, 3, or 4.
 * @param {number} percentage
 * @returns {number}
 */
export function translatePercentageToProficiencyLevel(percentage: ?number): ?number {
	return getBaseProficiency(translateScore(percentage))
}
