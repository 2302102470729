// @flow
import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import { useSelector, useDispatch } from 'react-redux'
import { Robots } from './Robots'
import ShipRepairs from './ShipRepairs'
import { getStudentRepairData } from '../../store/selectors/jrState'
import { sendMessage } from '../../store/stores/webSocket'
import { PRIMARY_PURPLE } from '../../constants/styles'

import type { Robots as RobotsType } from './Robots/types'

const initialRobots = {
	'1': {
		busy: false,
		dimensions: [86, 143],
	},
	'2': {
		busy: false,
		dimensions: [86, 116],
	},
	'3': {
		busy: false,
		dimensions: [86, 114],
	},
	'4': {
		busy: false,
		dimensions: [86, 143],
	},
}

export default function Repairs(): React$Node {
	const [localRobots, setLocalRobots] = useState<RobotsType>(initialRobots)
	const { myFixCount, myBrokenSystems } = useSelector(getStudentRepairData)

	const dispatch = useDispatch()

	const fixBrokenSystem = (systemId: string, robotId: string, [x, y]: [number, number]) => {
		const pointLocation = { x: '50%', y: '50%' }
		setLocalRobots(state => ({
			...state,
			[robotId]: { busy: true, dimensions: localRobots[robotId]?.dimensions ?? [100, 100] },
		}))
		dispatch(
			sendMessage('REPAIR', { systemId: systemId, robotId: robotId }, { location: pointLocation })
		)
	}
	useEffect(() => {
		if (myBrokenSystems) {
			const busyRobots = {}
			Object.keys(myBrokenSystems).forEach(systemId => {
				if (myBrokenSystems[systemId].robotId) {
					busyRobots[myBrokenSystems[systemId].robotId] = true
				}
			})
			setLocalRobots(state => {
				const newState = { ...state }
				Object.keys(state).forEach(robotId => {
					if (!busyRobots[robotId]) {
						newState[robotId] = initialRobots[robotId]
					}
				})
				return newState
			})
		}
	}, [myBrokenSystems])
	/**
	 * like all REACT apps, this render returns the issues fixed along with team issues
	 * along with the line of robots and the ship with/without errors
	 */

	return (
		<RepairsStyled className="station">
			<Robots robots={localRobots} />
			<ShipRepairs
				myBrokenSystems={myBrokenSystems || {}}
				fixBrokenSystem={fixBrokenSystem}
				robots={localRobots}
			/>
			<div className="total-repaired">
				{myFixCount ? (
					<>
						<div className="counter">{myFixCount}</div>
						System{myFixCount > 1 && 's'} Fixed
					</>
				) : (
					'Get to work!'
				)}
			</div>
		</RepairsStyled>
	)
}

/**
		This allows the robot to do the repair, and the timeout allows the state to change once thats happened
	 */

const RepairsStyled = styled.div`
	display: flex;
	justify-content: space-around;
	padding: 34px 0;
	.total-repaired {
		position: absolute;
		display: flex;
		flex-direction: row;
		align-items: center;
		left: 10%;
		top: 20px;
		background-color: #d0eefb;
		border-radius: 8px;
		padding: 8px 15px;
		font-size: 20px;
		line-height: 20px;

		.counter {
			text-align: center;
			background-color: ${PRIMARY_PURPLE};
			font-size: 32px;
			line-height: 32px;
			border-radius: 50%;
			padding: 4px;
			width: 40px;
			height: auto;
			color: #d0eefb;
			margin: auto;
		}
	}
`
