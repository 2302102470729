import React from 'react'
import styled from 'styled-components/macro'
import { ACCENT_BLUE_FAINT, APP_WHITE } from '../../../../constants/styles'
import { MAP_RADIUS } from '../helpers/constants'

const APPROXIMATE_TITLE_CHAR_WIDTH = 3.25 // title character width in svg units
const APPROXIMATE_SUB_TITLE_CHAR_WIDTH = 2.25 // sub-title character width in svg units
const APPROXIMATE_PADDING = 10 // total padding (ie. left + right padding) in svg units

/**
 * A tooltip element for  of an svg component.
 * In order for this tooltip to display, it must be a child of `TooltipContainer`, exported
 * from this file as well.
 * Example usage:
 * <TooltipContainer>
 * 		<Text>Hover over me!</Text>
 * 		<TitleTooltip text="Look a tooltip!"/>
 * </TooltipContainer>
 * @param {number} props.x x location in svg
 * @param {number} props.y y location in svg
 * @param {string} text the text to display in the tooltip
 * @param {?string} subtitle the subtitle of the map object
 */
export default function TitleTooltip({
	x,
	y,
	text,
	subtitle,
}: {
	x: number,
	y: number,
	text: string,
	subtitle?: ?string,
}): React$Node {
	const translated_y = y - (subtitle ? 7 : 0)

	const approximate_text_bounding_box_width_in_map_units =
		Math.max(
			text.length * APPROXIMATE_TITLE_CHAR_WIDTH,
			(subtitle?.length ?? 0) * APPROXIMATE_SUB_TITLE_CHAR_WIDTH
		) + APPROXIMATE_PADDING

	const y_in_circle = y - APPROXIMATE_PADDING - MAP_RADIUS // max y coordinate
	const maximum_x_displacement_from_origin = Math.abs(
		Math.cos(Math.asin(y_in_circle / MAP_RADIUS)) * MAP_RADIUS
	) // determine the displacement of the min and max "x" location in a circle given a horizontal line "y_in_circle" units away from the origin
	let translated_x = x // middle

	let allowable_range_min_x = MAP_RADIUS - maximum_x_displacement_from_origin // translate from circle coordinates to svg coordinates
	let allowable_range_max_x = MAP_RADIUS + maximum_x_displacement_from_origin

	const text_bounding_box_max_x = translated_x + approximate_text_bounding_box_width_in_map_units // approximate right most point of the text bounding box
	if (text_bounding_box_max_x > allowable_range_max_x) {
		// move left so that the right most point aligns with allowable_range_max_x
		translated_x -= Math.abs(allowable_range_max_x - text_bounding_box_max_x)
	}

	// note this translation happening at the end means that the left most character is always prioritized to show on the screen
	if (translated_x < allowable_range_min_x) {
		// move right so that the lest most point aligns with allowable_range_min_x
		translated_x += Math.abs(allowable_range_min_x - translated_x)
	}

	return (
		<StyledForeignObject
			transform="translate(0 -15)"
			fill="none"
			x={translated_x}
			y={translated_y}
			width={'100%'}
			height={String(24 + (subtitle ? 10 : 0)) + 'px'}
			pointerEvents="none">
			<ToolTip>
				<Title>{text}</Title>
				{subtitle ? <SubTitle>{subtitle}</SubTitle> : null}
			</ToolTip>
		</StyledForeignObject>
	)
}

const ToolTip = styled.div`
	background-color: ${ACCENT_BLUE_FAINT};
	border: 1px solid ${ACCENT_BLUE_FAINT};
	border-radius: 2px;
	width: fit-content;
	padding: 2px 2px;
	height: fit-content;
	visibility: hidden;
	text-align: left;
`

const Title = styled.p`
	margin: 0px 2px;
	font-size: 7px;
	color: ${APP_WHITE};
`

const SubTitle = styled(Title)`
	font-size: 5px;
`

const StyledForeignObject = styled.foreignObject`
	pointer-events: none;
`

export const TooltipContainer: StyledType<> = styled.g`
	&:hover ${ToolTip} {
		visibility: visible;
	}
`
