import React, { useMemo } from 'react'
import defenseUpgrades from '../../juniorPlusStations/Defense/components/assets/upgradeLookup'
import tractorBeamUpgrades from '../../juniorPlusStations/TractorBeam/components/assets/upgradeLookup'
import thrusterUpgrades from '../../stations/Thrusters/assets/upgradeLookup'
import 'styled-components/macro'
import { useSelector } from 'react-redux'
import type { QuestionPhaseStation } from '@mission.io/mission-toolkit'
import { QUESTION_PHASE } from '@mission.io/mission-toolkit/constants'
import { getStationUpgradeIndexForCurrentStation, getCurrentStation } from './selectors'
import type { BeamProps } from '../../juniorPlusStations/TractorBeam/components/assets/upgradeLookup'
import type { BlasterProps } from '../../juniorPlusStations/Defense/components/assets/upgradeLookup'

type IconType = React$ComponentType<BlasterProps> | React$ComponentType<BeamProps>

type IconRendererType = React$ComponentType<{ icon: IconType, active: boolean }>
type IconData = {
	icon: IconType,
	name: string,
}

export type UpgradeData = {
	...IconData,
	IconRenderer: IconRendererType,
}

/**
 * Determines which station upgrade is currently being rewarded to the student.
 * @returns {?UpgradeData} The icon, its name, and a renderer for the icon.
 */
export function useCurrentUpgrade(): ?UpgradeData {
	const upgradeIndex = useSelector(getStationUpgradeIndexForCurrentStation)
	const station = useSelector(getCurrentStation)
	return useMemo(() => {
		const upgradeData = station ? STATION_UPGRADE_ICONS[station] : null
		if (upgradeIndex - 1 < 0 || !station || !upgradeData?.icons[upgradeIndex - 1]) return null
		return { ...upgradeData.icons[upgradeIndex - 1], IconRenderer: upgradeData.IconRenderer }
	}, [upgradeIndex, station])
}

/**
 * A map of station ids to an array of icon data (the upgrade icon and its name) and an IconRenderer - a wrapper which
 * renders a station icon specific to the station upgrade question phase.
 */
export const STATION_UPGRADE_ICONS: {
	[QuestionPhaseStation]: { icons: Array<IconData>, IconRenderer: IconRendererType },
} = {
	[QUESTION_PHASE.STATIONS.DEFENSE]: {
		icons: defenseUpgrades.slice(1).map(upgrade => ({ icon: upgrade.blaster, name: upgrade.name })),
		IconRenderer: DefenseIconRenderer,
	},
	[QUESTION_PHASE.STATIONS.TRACTOR_BEAM]: {
		icons: tractorBeamUpgrades
			.slice(1)
			.map(upgrade => ({ icon: upgrade.tractorBeam, name: upgrade.name })),
		IconRenderer: TractorBeamIconRenderer,
	},
	[QUESTION_PHASE.STATIONS.THRUSTERS]: {
		icons: thrusterUpgrades.slice(1).map(upgrade => ({ icon: upgrade.device, name: upgrade.name })),
		IconRenderer: ThrustersRenderer,
	},
}

/**
 * Colors in an svg image entirely with the color purple.
 */
const PurpleMaskCss = `
* { fill: #5C5189 !important; stroke: #5C5189 !important; opacity: 1 !important; mix-blend-mode: normal !important;}
`

/**
 * Renders station upgrade icons for the defense station.
 * @param {IconType} props.iconType an svg for a single defense weapon upgrade option
 * @param {boolean} active whether the defense weapon has been unlocked/activated.
 */
function DefenseIconRenderer({
	icon: Icon,
	active,
	className,
}: {
	icon: IconType,
	active: boolean,
	className?: string,
}) {
	return (
		<g transform="rotate(45 10 30)" css={!active && PurpleMaskCss} className={className}>
			<Icon />
		</g>
	)
}

/**
 * Renders station upgrade icons for the tractor beam station.
 * @param {IconType} props.iconType an svg for a single tractor beam weapon device
 * @param {boolean} active whether the beam device has been unlocked/activated.
 */
function TractorBeamIconRenderer({
	icon: Icon,
	active,
	className,
}: {
	icon: IconType,
	active: boolean,
	className?: string,
}) {
	return (
		<g
			css={`
				${!active ? PurpleMaskCss : ''} transform-origin: 10px 30px;
				transform: rotate(30deg) scale(0.5);
			`}
			className={className}>
			<Icon width={150} height={150} x="50" y="0" />
		</g>
	)
}

/**
 * Renders station upgrade icons for the thrusters station.
 * @param {IconType} props.iconType an svg for a single thruster upgrade option
 * @param {boolean} active whether the upgrade option been unlocked/activated.
 */
// eslint-disable-next-line no-unused-vars
function ThrustersRenderer({
	icon: Icon,
	active,
	className,
}: {
	icon: IconType,
	active: boolean,
	className?: string,
}) {
	return (
		<g
			css={`
				#FrameBG,
				#GradientRect,
				#Exhaust {
					display: none;
				}
				${!active ? PurpleMaskCss : ''}
			`}
			className={className}>
			<Icon width="100" height="100" />
		</g>
	)
}
