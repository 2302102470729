import React from 'react'
import { ACCENT_GREEN } from '../../constants/styles'
export default function Arrow(props: { doClick?: boolean }): React$Node {
	return (
		<svg {...props} viewBox="0 0 15 10">
			<path
				className={props.doClick ? 'click-arrow' : ''}
				d="M5 10 L0 10 L7.5 0 L15 10 L10 10 L7.5 6.5 Z"
				fill={ACCENT_GREEN}></path>
		</svg>
	)
}
