import React from 'react'
import { Station } from '../../components/basics'
import { connect } from 'react-redux'
import { sendMessage } from '../../store/stores/webSocket'
import ShooterGame from './components/ShooterGame'

import type { ReduxStore } from '../../store/rootReducer'

type Props = {
	sendMessage: (string, {}, ?{}) => mixed,
}

function Defense({ sendMessage }: Props) {
	const miss = () => {
		sendMessage('DEFENSE', { targetHit: false })
	}
	const hit = (location, instanceId) => {
		sendMessage('DEFENSE', { targetHit: true, instanceId }, { location })
	}
	return (
		<Station>
			<ShooterGame onMiss={miss} onHit={hit} />
		</Station>
	)
}
const mapDispatchToProps = {
	sendMessage,
}
const mapStateToProps = (state: ReduxStore) => {
	return {
		status: state.jrPlusState.stationData.defense.status,
	}
}

export default (connect(mapStateToProps, mapDispatchToProps)(Defense): (
	props: $Shape<{||}>
) => React$Node)
