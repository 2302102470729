import React from 'react'
import classnames from 'classnames'
import Path from './Path'
import type { AbstractComponent } from 'react'

type Props = {
	image: string,
	height: number,
	width: number,
	containerHeight: number,
	className?: string,
	right?: number,
	drift: number,
	driftSpeed?: number,
}

export default (React.forwardRef(Target): AbstractComponent<Props, HTMLImageElement>)

/**
 * Target - animate the target moving from left to right and up and down.
 *
 * It is expected that this target will appear on the right end of if its parent container.
 * If the target is in the top left corner of the parent container then the location.top and location.rightOffset are 0.
 * There is an instance when the targets right offset has not been calculated yet when first rendering the station.
 * In this case we do not render the target to a void a flash of the target in the wrong location.
 *
 * Also receives a forwarded ref from its parent and applies it to the image element so that the parent can access the targets location.
 * @param {HTMLImageELement} ref
 * @param {string} props.image - the url for the image
 * @param {number} props.height - the height of the target
 * @param {number} props.width - the width of the target
 * @param {number} props.containerHeight - the height of the parent container
 * @param {string} props.className - the class name to apply to the target
 * @param {number} props.right - the right offset of the target
 * @param {number} props.drift - the amount of horizontal drift the target will have
 * @param {number} props.driftSpeed - the speed at which the target will drift horizontally
 * @returns
 */
function Target(props: Props, ref): React$Node {
	const { image, containerHeight, className, height, width, right, drift, driftSpeed } = props

	return (
		<Path
			top={0}
			bottom={containerHeight - height}
			leftDrift={-drift}
			rightDrift={drift}
			verticalTransitionTime={4000}
			horizontalTransitionTime={driftSpeed || 1825}>
			{location => {
				if (!(location.top && location.rightOffset)) {
					return
				}
				return (
					<img
						className={classnames('absolute', className)}
						src={image}
						alt="Enemy"
						ref={ref}
						style={{
							width,
							height,
							top: location.top,
							right: (right || 0) + location.rightOffset,
						}}
					/>
				)
			}}
		</Path>
	)
}
