// @flow
import * as React from 'react'
import { connect } from 'react-redux'
import StationEndedDisplay from './StationEndedDisplay'
import InactiveDisplay from './InactiveDisplay'
import type { StationEnum } from '../../types'
import { getStationActivity, successMessages } from '../../utility/selectors'
import { getStation } from '../../store/stores/general'
import type { ReduxStore } from '../../store/rootReducer'
import { usePrevious } from '../../utility/hooks'

const overlayTypes = {
	STATION_ENDED: 'STATION_ENDED',
	INACTIVE: 'INACTIVE',
}
type Props = {
	style?: {},
}

type ReduxProps = {
	isStationInactive: boolean,
	successMessageForStation: ?string,
	station: StationEnum,
}

type State = {
	type: $Keys<typeof overlayTypes>,
	successMessage: ?string,
}

/**
 * A component that displays an overlay over the current station when it's complete
 */
function Overlay({ station, isStationInactive, style }: Props & ReduxProps) {
	const [state, setState] = React.useState<State>({
		type: overlayTypes.INACTIVE,
		successMessage: null,
	})

	const previousIsStationInactive = usePrevious(isStationInactive)
	const previousStation = usePrevious(station)
	React.useEffect(() => {
		if (isStationInactive && !previousIsStationInactive) {
			const justCompletedStation = previousStation === station
			if (!previousStation) {
				// This should never happen
				return
			}
			if (justCompletedStation) {
				setState({
					type: overlayTypes.STATION_ENDED,
					// $FlowFixMe[prop-missing] We don't care if `previousStation` is not in `successMessages`
					successMessage: successMessages[previousStation],
				})
			}
			return
		}
		if (station !== previousStation) {
			setState({ type: overlayTypes.INACTIVE, successMessage: null })
		}
	}, [station, isStationInactive, previousIsStationInactive, previousStation])

	return state.type === overlayTypes.INACTIVE ? (
		<InactiveDisplay station={station} style={style || {}} />
	) : state.type === overlayTypes.STATION_ENDED ? (
		<StationEndedDisplay
			onClose={() => {
				setState({
					type: overlayTypes.INACTIVE,
					successMessage: null,
				})
			}}
			station={station}
			message={state.successMessage}
			style={style || {}}
		/>
	) : null
}

function mapStateToProps(state: ReduxStore) {
	const station = getStation(state)
	return {
		station,
		isStationInactive: station ? !getStationActivity(state)[station] : true,
	}
}

export default (connect(mapStateToProps)(Overlay): (props: Props) => React$Node)
