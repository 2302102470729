import React, { Component } from 'react'
import { DragSource } from 'react-dnd'

import { ROBOT } from '../../../constants'

type BaseProps = {
	id: string,
	robotImg: string,
	dragImg: string,
	dragImgScale: number[],
	isBusy: boolean,
}
type Props = {
	...BaseProps,

	// react-dnd stuff
	connectDragSource: Function,
	connectDragPreview: Function,
	isDragging: boolean,
}

class Robot extends Component<Props> {
	componentDidMount() {
		const { connectDragPreview, dragImg, dragImgScale } = this.props

		const img = new Image(dragImgScale[0], dragImgScale[1])
		img.src = dragImg
		img.onload = () => connectDragPreview(img)
	}

	render() {
		return this.props.connectDragSource(
			<div
				className="w-[35%] my-3 h-[40%] bg-no-repeat bg-contain bg-center"
				style={{
					backgroundImage: `url(${this.props.robotImg})`,
					opacity: this.props.isDragging || this.props.isBusy ? 0.1 : 1,
				}}
			/>
		)
	}
}

const robotSource = {
	beginDrag(props: Props) {
		return {
			id: props.id,
		}
	},

	endDrag(props, monitor, component) {
		if (!monitor.didDrop()) return

		const robot = monitor.getItem()
		const dropResult = monitor.getDropResult()
		dropResult.fixSystem(robot.id)
	},

	canDrag(props) {
		return !props.isBusy
	},
}

function collect(connect, monitor) {
	return {
		connectDragSource: connect.dragSource(),
		isDragging: monitor.isDragging(),
		connectDragPreview: connect.dragPreview(),
	}
}

export default (DragSource(ROBOT, robotSource, collect)(Robot): (props: BaseProps) => React$Node)
