import React from 'react'
import { useState } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { Question } from '@mission.io/question-views'
import {
	getCollaborativeCulminatingMomentCanvasesFromAnalytics,
	getReviewCulminatingMomentAnswers,
	getStudentAnswersToQuestions,
} from '../../store/selectors/sharedSelectors'
import TextToSpeech from '../TextToSpeech'
import {
	AttemptFlipper,
	SimpleQuestionWrapper,
	StyledQuestionGrid,
	Title,
} from './sharedComponents'
import type { FormattedResponse } from '../../store/selectors/sharedSelectors'
import { CollaborativeCulminatingMomentReview } from './CollaborativeCulminatingMomentReview'

/**
 * QuestionReview - shows the current student's answers for the questions and culminating moment
 * throughout the mission
 *
 * @return {React$Node}
 */
export default function QuestionReview(): React$Node {
	const studentAnswers = useSelector(getStudentAnswersToQuestions)
	const formattedQuestionsAndAnswers = useSelector(getReviewCulminatingMomentAnswers)
	const culminatingMomentData = useSelector(getCollaborativeCulminatingMomentCanvasesFromAnalytics)

	return (
		<>
			{studentAnswers.length ? (
				<>
					<Header>
						<HeaderTitle>Questions</HeaderTitle>
					</Header>
					<StyledQuestionGrid>
						{studentAnswers.map((responses, questionIndex) => {
							if (!responses.length) {
								return null
							}
							return (
								<QuestionFlipper
									key={questionIndex}
									responses={responses}
									questionIndex={questionIndex}
								/>
							)
						})}
					</StyledQuestionGrid>
				</>
			) : null}
			{formattedQuestionsAndAnswers.length ? (
				<>
					<Header>
						<HeaderTitle>Application</HeaderTitle>
					</Header>
					<StyledQuestionGrid>
						{formattedQuestionsAndAnswers.map((responses, questionIndex) => (
							<QuestionFlipper
								key={questionIndex}
								responses={responses}
								questionIndex={questionIndex}
							/>
						))}
					</StyledQuestionGrid>
				</>
			) : null}
			{culminatingMomentData?.length ? (
				<>
					<Header>
						<HeaderTitle>Documents</HeaderTitle>
					</Header>
					<CollaborativeCulminatingMomentReview culminatingMomentData={culminatingMomentData} />
				</>
			) : null}
		</>
	)
}

/**
 * QuestionFlipper - a react component which allows looking through question attempts
 *
 * @param {Object} props - the react props
 * @param {Array<FormattedResponse>} props.responses - the responses to the questions
 * @param {number} props.questionIndex - the index of the question the responses are for
 *
 * @returns React$Node
 */
export function QuestionFlipper({
	responses,
	questionIndex,
}: {
	responses: Array<FormattedResponse>,
	questionIndex: number,
}): React$Node {
	const [selectedResponseIndex, setSelectedResponseIndex] = useState(0)

	const selectedResponse = responses[selectedResponseIndex]

	if (!selectedResponse) {
		return null
	}

	return (
		<SimpleQuestionWrapper>
			<Question
				review="OVERVIEW"
				questionIndex={questionIndex}
				question={selectedResponse.question}
				studentResponse={selectedResponse}
				textToSpeech={TextToSpeech}
			/>
			<AttemptFlipper
				currentIndex={selectedResponseIndex}
				length={responses.length}
				setCurrentIndex={setSelectedResponseIndex}
			/>
		</SimpleQuestionWrapper>
	)
}

const HeaderTitle = styled(Title)`
	background-color: #5d568c;
	border-radius: ${({ theme }) => theme.spacing};
	padding: ${({ theme }) => theme.spacing2x};
	text-align: center;
	margin-bottom: ${({ theme }) => theme.spacing2x};
`

const Header = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: ${({ theme }) => theme.spacing};
	font-size: ${({ theme }) => theme.fontxl};
`
