import React from 'react'

import { BackgroundCircle, FilledPath, FilledCircle } from '../helpers'
import type { Props } from '../helpers'

export default function ScanningIcon({
	iconOnly,
	transitionPattern = {},
	isOpen = true,
	...props
}: Props): React$Node {
	return (
		<svg
			{...props}
			id="scanningPlusPanel"
			data-name="Scanning Plus Panel"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 94 94"
			fill="#00658a">
			<g
				style={{
					...transitionPattern,
					transform: `${isOpen ? 'scale(1)' : 'scale(0)'}`,
					transformOrigin: '50% 50%',
				}}>
				{!iconOnly && <BackgroundCircle cx="47" cy="47" r="47" />}

				<FilledPath
					d="M72,70.84V119A47,47,0,0,1,29.23,91.51Z"
					transform="translate(-25 -25)"
					iconOnly={iconOnly}
				/>
				<FilledCircle cx="47" cy="46" r="6" iconOnly={iconOnly} />
				<FilledCircle cx="14" cy="37" r="5" iconOnly={iconOnly} />
				<FilledCircle cx="73" cy="65" r="5" iconOnly={iconOnly} />
				<FilledCircle cx="78" cy="38" r="5" iconOnly={iconOnly} />
			</g>
		</svg>
	)
}
