import SAT from 'sat'
import CollisionTypes from '../../Communication/collision'

type CollisionReturnType = { type: $Keys<typeof CollisionTypes>, collidable: boolean, bounds: any }
/**
 * Given a DOM element returns an object that allows us to determine if it
 * collides with another object
 * @param {Element | null} element
 * @returns {Collision}
 */
export const getCollision: (element: Element | null) => CollisionReturnType = element => {
	if (element) {
		let position = element.getBoundingClientRect()
		return {
			type: CollisionTypes.POLYGON,
			collidable: true,
			bounds: new SAT.Polygon(new SAT.Vector(position.left, position.top), [
				new SAT.Vector(),
				new SAT.Vector(position.width, 0),
				new SAT.Vector(position.width, position.height),
				new SAT.Vector(0, position.height),
			]),
		}
	}

	return {
		type: CollisionTypes.CIRCLE,
		collidable: false,
		bounds: new SAT.Circle(new SAT.Vector(Infinity, 0), 10),
	}
}

const LASER_WIDTH = 20
/**
 * Given a DOM element representing the bounding box around a diagonal line, determines the polygon shape of the diagonal line
 * @param {Element | null} element
 * @param {Element | null} centralElement
 * @returns {CollisionReturnType}
 */
export const getLaserCollision: (
	element: ?Element,
	centralElement: ?Element
) => CollisionReturnType = (element, centralElement) => {
	if (element) {
		let position = element.getBoundingClientRect()
		const centerX = centralElement ? centralElement.getBoundingClientRect().left : 0
		/* Builds SAT polygons that are diagonal rectangles across the given element's bounding client boxes.
		 Determines the direction of the diagonal rectangle, (\ or /) based off of where the box is 
		 located on the screen relative to the central element. (the blaster) */
		if (position.left > centerX) {
			return {
				type: CollisionTypes.POLYGON,
				collidable: true,
				bounds: new SAT.Polygon(new SAT.Vector(position.left, position.top), [
					new SAT.Vector(0, position.height),
					new SAT.Vector(0, position.height - LASER_WIDTH),
					new SAT.Vector(position.width, 0),
					new SAT.Vector(position.width, LASER_WIDTH),
				]),
			}
		} else {
			return {
				type: CollisionTypes.POLYGON,
				collidable: true,
				bounds: new SAT.Polygon(new SAT.Vector(position.left, position.top), [
					new SAT.Vector(0, LASER_WIDTH),
					new SAT.Vector(LASER_WIDTH, 0),
					new SAT.Vector(position.width, position.height),
					new SAT.Vector(position.width - LASER_WIDTH, position.height),
				]),
			}
		}
	}
	return {
		type: CollisionTypes.CIRCLE,
		collidable: false,
		bounds: new SAT.Circle(new SAT.Vector(Infinity, 0), 10),
	}
}
