import React, { forwardRef, type AbstractComponent } from 'react'
import styled from 'styled-components'
import { animated } from '@react-spring/web'
import { animateThrob } from './extras'

export const WEDGE_LENGTH = 0.1 // The target wedge will be x% of the circle

type Props = { start: number }

const Wedge = forwardRef(function OurRef({ start }: Props, ref) {
	return (
		<StyledWedge
			ref={ref}
			d={`${moveCommand(start)} ${arcCommand(start, WEDGE_LENGTH)} ${lineCommand}`}
		/>
	)
})

const lineCommand = 'L 0 0'

function arcCommand(percent: number, length: number) {
	const endPoint = getPointForPercent(percent + length)
	return `A 1 1 0 0 1 ${endPoint[0]} ${endPoint[1]}`
}

function moveCommand(percent: number) {
	const point = getPointForPercent(percent)
	return `M ${point[0]} ${point[1]}`
}

const StyledWedge = styled.path`
	animation: ${animateThrob('#ffffff')} 2s infinite;
`

function getPointForPercent(percent: number) {
	const x = Math.cos(2 * Math.PI * percent)
	const y = Math.sin(2 * Math.PI * percent)
	return [x, y]
}

export default (animated(Wedge): AbstractComponent<Props, HTMLElement>)
