import React, { Component } from 'react'
import { connect } from 'react-redux'
import PartialImage from './PartialImage'
import ConnectionArea from './ConnectionArea'
import TransporterBase from '../../images/TransporterBase.png'
import { sendMessage } from '../../store/stores/webSocket'
import { resetError } from '../../store/stores/transporter'
import { getStudentValue } from '../../utility/selectors'
import type { ReduxStore } from '../../store/rootReducer'
import type { TransporterLinkItem } from '../../store/stores/transporter'
import type { Vertex } from '../../store/stores/sharedGameStateTypes'
import {
	getGroupSegmentsTransported,
	isTransporterActive as isActive,
	getTransporter,
} from '../../store/selectors/sharedSelectors'
import type { PointEvent } from '../../types'

import './Transporter.css'
import transportationPod from '../../images/transportationPod.png'

type Props = {
	active: boolean,
	dots: Vertex[],
	groupRequiredSegmentCount: number,
	groupSegmentsTransported: number,
	mySegmentsTransported: number,
	showError: boolean,
	resetError: () => void,
	sendMessage: (type: string, payload: any, PointEvent) => void,
}

type State = {
	lines: Array<[TransporterLinkItem, TransporterLinkItem]>,
}

class Transporter extends Component<Props, State> {
	state: State = {
		lines: [],
	}

	componentDidUpdate() {
		if (!this.props.active && this.state.lines.length > 0) {
			// ensure all lines are cleared when the station shuts off
			// so now stray lines stay off the screen indefinitely
			this.setState({ lines: [] })
		}
	}

	render() {
		const { active, groupRequiredSegmentCount, groupSegmentsTransported, dots } = this.props
		let transportPercentage
		if (groupRequiredSegmentCount > 0) {
			transportPercentage = (groupSegmentsTransported / groupRequiredSegmentCount) * 100
		} else {
			transportPercentage = 0
		}

		return (
			<div className="Transporter station">
				<div className="content row">
					<div className="transport-structure">
						<img
							src={TransporterBase}
							alt="transporter-base"
							style={{ transform: 'scaleX(-1) rotate(180deg)' }}
						/>
						<PartialImage
							src={transportationPod}
							percentage={active ? 100 - transportPercentage : 0}
						/>
						<img src={TransporterBase} alt="transporter-base" />
					</div>
					<ConnectionArea
						dots={active ? dots : []}
						onNewConnection={this.onNewConnection}
						removeLine={this.removeLine}
						lines={this.state.lines}
						showError={this.props.showError}
						resetError={this.props.resetError}
					/>
					<div className="transport-structure">
						<img
							src={TransporterBase}
							alt="transporter-base"
							style={{ transform: 'scaleX(-1) rotate(180deg)' }}
						/>
						<PartialImage src={transportationPod} percentage={active ? transportPercentage : 0} />
						<img src={TransporterBase} alt="transporter-base" />
					</div>
				</div>
			</div>
		)
	}

	sendMessage(payload: any, pointLocation: { x: number, y: number }) {
		this.props.sendMessage('TRANSPORTER', payload, { location: pointLocation })
	}

	onNewConnection = (
		[startCell, endCell]: [TransporterLinkItem, TransporterLinkItem],
		point1: { x: number, y: number },
		point2: { x: number, y: number }
	) => {
		const offset =
			point1.x < point2.x ? (point1.y < point2.y ? 50 : -50) : point1.y > point2.y ? 50 : -50
		const pointLocation = {
			x: (point1.x + point2.x) / 2 + offset,
			y: (point1.y + point2.y) / 2,
		}
		this.sendMessage({ vertexIndices: [startCell.index, endCell.index] }, pointLocation)
		this.setState(state => ({ lines: [...state.lines, [startCell, endCell]] }))
	}

	removeLine = ([startCell, endCell]: [TransporterLinkItem, TransporterLinkItem]) => {
		const index = this.getIndexForLine([startCell, endCell])
		if (index >= 0) {
			this.setState(state => ({ lines: this.getSlicedArray(state, index) }))
		}
	}

	getIndexForLine = ([startCell, endCell]: [TransporterLinkItem, TransporterLinkItem]) => {
		return this.state.lines.findIndex(([curStart, curEnd]) => {
			return startCell.index === curStart.index && endCell.index === curEnd.index
		})
	}

	getSlicedArray = (state: State, index: number) => {
		return [...state.lines.slice(0, index), ...state.lines.slice(index + 1)]
	}
}

function mapStateToProps(state: ReduxStore) {
	const { groupRequiredSegmentCount, studentData } = getTransporter(state)
	const myData = getStudentValue(state, studentData)
	return {
		active: isActive(state),
		showError: state.transporter.showError,
		groupRequiredSegmentCount,
		groupSegmentsTransported: getGroupSegmentsTransported(state),
		dots: myData ? myData.vertices : [],
		mySegmentsTransported: myData ? myData.total : 0,
	}
}

const mapDispatchToProps = {
	sendMessage,
	resetError,
}

export default (connect(mapStateToProps, mapDispatchToProps)(Transporter): (
	props: $Shape<{||}>
) => React$Node)
