import React from 'react'
import { captureMessage } from '@sentry/react'
import { useSelector, useDispatch } from 'react-redux'
import { getHealthTaskInfo } from '../../../store/stores/staticData'
import { getStudent } from '../../../store/selectors/sharedSelectors'
import { sendMessage } from '../../../store/stores/webSocket'
import { DeltaClockTimeLeft } from '../../DeltaClock'
import { insertName } from '../helpers'
import Example from './Example'
import { Button, Row } from '../../basics'
import styled from 'styled-components'

import type { HealthTask } from '@mission.io/mission-toolkit'
import { useAdvanceTraining } from '../../../store/selectors/jrPlusState/training'

const StyledTaskInfo: StyledType<> = styled.div`
	h2,
	h3 {
		margin: 0;
		padding: 4px 0;
	}
	color: white;
`

export default function TaskInfo({ task }: { task: HealthTask }): React$Node {
	const allTaskInfo = useSelector(getHealthTaskInfo)
	const dispatch = useDispatch()
	const advanceTraining = useAdvanceTraining()
	const trainingMode = Boolean(advanceTraining)
	let partner = useSelector(state => getStudent(state, task.partnerId))
	if (trainingMode) partner = { firstName: 'a classmate' }
	if (!partner) {
		captureMessage('no partner assigned for healthTask for ' + task.studentId)
		return <div>ERROR</div>
	}

	const partnerName = `${partner.firstName}${partner.lastName ? ' ' + partner.lastName : ''}`
	const taskInfo = task?.type ? allTaskInfo[task.type] : null
	if (!taskInfo) return <div>FETCHING: {task.type}</div>

	const done = () => {
		if (advanceTraining) {
			advanceTraining()
		} else {
			dispatch(sendMessage('FINISH_HEALTH_TASK', task.id))
		}
	}
	return (
		<StyledTaskInfo className="taskInfo">
			<h2>{taskInfo.name}</h2>
			<h3>{insertName(taskInfo.description, partnerName)}</h3>
			<div style={{ textAlign: 'left', padding: '22px 0px' }}>
				<div>For Example:</div>
				<Example taskInfo={taskInfo} partnerName={partnerName} />
			</div>
			<Row>
				{task.timerId ? (
					<DeltaClockTimeLeft timerId={task.timerId} customMessage="Time remaining: " />
				) : (
					<div></div>
				)}
				<Button onClick={done} disabled={!task.canComplete}>
					Done
				</Button>
			</Row>
		</StyledTaskInfo>
	)
}
