/**
 * Rectangle class with helper methods.
 */
export default class Rectangle {
	top: number
	bottom: number
	left: number
	right: number
	constructor(params: ?{ x: number, y: number, width: number, height: number }) {
		if (params) {
			this.top = params.y + params.height
			this.bottom = params.y
			this.left = params.x
			this.right = params.x + params.width
		}
	}

	/**
	 * Tests if this rectangle intersects the other rectangle.
	 * @param {Rectangle} other The other rectangle
	 */
	intersects(other: Rectangle): boolean {
		// see https://stackoverflow.com/questions/306316/determine-if-two-rectangles-overlap-each-other
		return (
			this.left < other.right &&
			this.right > other.left &&
			this.top > other.bottom &&
			this.bottom < other.top
		)
	}

	/**
	 * Creates the intersecting rectangle.
	 * @param {Rectangle} other Other rectangle
	 * @returns	{Rectangle} Resulting intersection rectangle
	 */
	// eslint-disable-next-line no-use-before-define
	intersectionRectangle(other: Rectangle): Rectangle {
		const intersection = new Rectangle()
		intersection.top = Math.min(this.top, other.top)
		intersection.bottom = Math.max(this.bottom, other.bottom)
		intersection.left = Math.max(this.left, other.left)
		intersection.right = Math.min(this.right, other.right)

		return intersection
	}

	/**
	 * Calculates the area of the rectangle.
	 */
	area(): number {
		return Math.abs(this.top - this.bottom) * Math.abs(this.left - this.right)
	}
}
