import React, { forwardRef, type AbstractComponent } from 'react'
import styled from 'styled-components/macro'
import type { BlasterProps as Props } from './upgradeLookup'
import { DefaultWrapper } from '../constants'

export default (forwardRef(function Blaster(props: Props, ref): React$Node {
	const { transformationWrapper: Wrapper = DefaultWrapper, children, ...otherProps } = props
	return (
		<Svg {...otherProps} viewBox="0 0 398.24 644.49">
			<defs>
				<radialGradient
					id="radial-gradient"
					cx="199.12"
					cy="445.37"
					r="199.12"
					gradientUnits="userSpaceOnUse">
					<stop offset="0.49" stopColor="#3b384c" />
					<stop offset="1" stopColor="#1a1a26" />
				</radialGradient>
				<linearGradient
					id="linear-gradient"
					x1="299.16"
					y1="20.88"
					x2="170.31"
					y2="32.97"
					gradientUnits="userSpaceOnUse">
					<stop offset="0" stopColor="#5e5e78" />
					<stop offset="0.38" stopColor="#75758c" />
					<stop offset="0.5" stopColor="#7d7d93" />
					<stop offset="1" stopColor="#5e5e78" />
				</linearGradient>
				<linearGradient
					id="linear-gradient-2"
					x1="167.65"
					y1="36.94"
					x2="230.59"
					y2="36.94"
					xlinkHref="#linear-gradient"
				/>
				<linearGradient
					id="linear-gradient-3"
					x1="167.65"
					y1="42.71"
					x2="230.59"
					y2="42.71"
					xlinkHref="#linear-gradient"
				/>
				<radialGradient
					id="radial-gradient-2"
					cx="201.29"
					cy="-31.41"
					r="114.8"
					xlinkHref="#radial-gradient"
				/>
				<linearGradient
					id="linear-gradient-4"
					x1="203.9"
					y1="308.93"
					x2="199.66"
					y2="410.58"
					gradientUnits="userSpaceOnUse">
					<stop offset="0" stopColor="#505069" />
					<stop offset="0.33" stopColor="#52526b" />
					<stop offset="0.52" stopColor="#5a5a73" />
					<stop offset="0.66" stopColor="#686880" />
					<stop offset="0.79" stopColor="#7c7c93" />
					<stop offset="0.91" stopColor="#9595aa" />
					<stop offset="1" stopColor="#afafc3" />
				</linearGradient>
				<linearGradient
					id="linear-gradient-5"
					x1="260.21"
					y1="215.32"
					x2="247.27"
					y2="185.44"
					gradientUnits="userSpaceOnUse">
					<stop offset="0" stopColor="#5e5e78" />
					<stop offset="0.33" stopColor="#75758d" />
					<stop offset="1" stopColor="#afafc3" />
				</linearGradient>
				<linearGradient
					id="linear-gradient-6"
					x1="252.35"
					y1="242.35"
					x2="252.35"
					y2="185.4"
					xlinkHref="#linear-gradient-5"
				/>
				<linearGradient
					id="linear-gradient-7"
					x1="252.35"
					y1="229.12"
					x2="252.35"
					y2="188.85"
					xlinkHref="#radial-gradient"
				/>
				<linearGradient
					id="linear-gradient-8"
					x1="286.24"
					y1="256.47"
					x2="370.1"
					y2="256.47"
					xlinkHref="#linear-gradient-4"
				/>
			</defs>
			<g className="blaster-1">
				<Wrapper>
					{children}
					<g id="Layer_1-2" data-name="Layer 1">
						<g id="_1" data-name="1">
							<path
								className="blaster-2"
								d="M130.86,262.77a3,3,0,0,1-3-3V243l-7.5-7.5a3,3,0,0,1-.88-2.12V167a3,3,0,0,1,.88-2.12l13.3-13.3a3,3,0,0,1,4.24,4.24L125.48,168.2v64l7.5,7.5a3,3,0,0,1,.88,2.12v18A3,3,0,0,1,130.86,262.77Z"
							/>
							<g id="Orbital_Thing" data-name="Orbital Thing">
								<circle className="blaster-3" cx="199.12" cy="445.37" r="199.12" ref={ref} />
								{/* Filter here */}
								<path
									className="blaster-4"
									d="M245,257.41v90.36h0a107.79,107.79,0,0,1,62,97.6h0A105.22,105.22,0,0,1,201.72,550.59h-2"
								/>
							</g>
							<path
								className="blaster-2"
								d="M270.8,100.63l26.63,26.64v51.47l-27.95,27.95V194.35l17.83-17.83V142.65a11.92,11.92,0,0,0-12.84-11.89h0Z"
							/>
							<g id="laser-start">
								<path
									className="blaster-5"
									d="M227.05,18.35h14.48l1.23,1.24V27l-.6,1.06-15.11,8.88Z"
								/>
								<path
									className="blaster-6"
									d="M214.75,0H183.49l-2.22,1.19-13.19,20-.43,1.46V71.23l2.65,2.65h57.64l2.65-2.65V22.68l-.44-1.46L217,1.19Z"
								/>
								<rect className="blaster-7" x="167.65" y="21.88" width="62.94" height="41.65" />
								<path
									className="blaster-8"
									d="M197.29,1.41h2.2a1.13,1.13,0,0,1,1.12,1l2.49,16.79a1.14,1.14,0,0,1-1.12,1.3h-5.93a1.15,1.15,0,0,1-1.14-1.22l1.25-16.79A1.13,1.13,0,0,1,197.29,1.41Z"
								/>
								<polyline
									className="blaster-9"
									points="189.12 1 188.65 1.41 181.94 21.88 181.94 36.94 189.71 41.29 189.71 60.76"
								/>
							</g>
							<polygon
								className="blaster-10"
								points="148.6 256.72 137.76 256.72 137.76 109.06 178.59 68.24 241.53 68.24 241.53 142.24 148.6 256.72"
							/>
							<line className="blaster-11" x1="241.53" y1="162.09" x2="241.53" y2="158.12" />
							<path
								className="blaster-12"
								d="M235.53,147.09V192h-3.41L220,179.86l-20.8-20.8V117.65l-22.12,22.11v48l-31.53,31.53v30.36l7.76,7.76H260.71l7.76-7.76V152.88a36.79,36.79,0,0,0-32.94-5.79Z"
							/>
							<path
								className="blaster-13"
								d="M248.12,39.53h-6.59L168.12,59.29,129.06,88l-5.18,11.29.23,48.45a6,6,0,0,0,1.77,4.26l10.24,10.24h13.94l3-3v-27l40-40h12l2.59,2.58v14.36l-8.47,8.47v41.41l20.8,20.8L232.12,192h9.41V162.09a36.84,36.84,0,0,1,27,2.13l5.94-3.64V61.18ZM135.78,144.21a4.19,4.19,0,0,1-8.38,0V125.38a4.19,4.19,0,1,1,8.38,0Z"
							/>
							<circle className="blaster-14" cx="252.35" cy="197.18" r="27.29" />
							<circle className="blaster-15" cx="252.35" cy="197.18" r="18.44" />
							<circle className="blaster-16" cx="252.35" cy="197.18" r="13.04" />
							<circle className="blaster-17" cx="252.35" cy="197.18" r="8.52" />
							<path
								className="blaster-18"
								d="M202.41,155.36V119.29l10.85-10.85v-15L207,79.76H192.59l-50.83,50.83v16.82l11.3,11.3V132.24l40-40h12l2.59,2.58v14.36l-8.47,8.47v41.41l20.8,20.8,7.07,7.07V180Z"
							/>
							<path
								className="blaster-18"
								d="M252.36,160.47a36.49,36.49,0,0,1,16.26,3.79l5.85-3.59V61.18L248.12,39.53h-6.59L168.12,59.29,129.06,88l-5.18,11.29,47.06-33.64,68.24-19.06h8.47l16.47,14.12v92.23l-3.41,3.41H240.59l-3.53,3.53v7.88l4.47,4.47V162.09A36.86,36.86,0,0,1,252.36,160.47Z"
							/>
							<path
								className="blaster-19"
								d="M193,91.07l-.37-10a1.21,1.21,0,0,1,1.21-1.26h11.73a1.21,1.21,0,0,1,1.2,1.4l-1.56,10a1.22,1.22,0,0,1-1.2,1h-9.79A1.22,1.22,0,0,1,193,91.07Z"
							/>
							<path
								className="blaster-19"
								d="M241.08,161.87l-2.87-1.42a.8.8,0,0,0-1.15.72v6.26a.79.79,0,0,0,.23.57l2.87,2.87a.81.81,0,0,0,1.37-.57v-7.71A.8.8,0,0,0,241.08,161.87Z"
							/>
							<path
								className="blaster-18"
								d="M189.65,127.18v64.48l-1.91,4.6-25,25v36.18h-9.47l-7.76-7.76V219.29l31.53-31.53v-48Z"
							/>
							<path
								className="blaster-20"
								d="M148.31,219.61l13.21,1.49a2.63,2.63,0,0,0,2.13-.75l22-22a2.58,2.58,0,0,0-.22-3.86l-6.59-5.24a2.59,2.59,0,0,0-3.45.19l-27.91,27.92A1.35,1.35,0,0,0,148.31,219.61Z"
							/>
							<g>
								<g>
									<path
										className="blaster-21"
										d="M240.24,117.53l11.88-8.13a3.29,3.29,0,0,1,5.14,2.71v16.26a3.29,3.29,0,0,1-5.14,2.71L240.24,123A3.29,3.29,0,0,1,240.24,117.53Z"
									/>
									<path
										className="blaster-22"
										d="M242.16,118.77l10.45-7.15a1.77,1.77,0,0,1,2.78,1.46V127.4a1.77,1.77,0,0,1-2.78,1.46l-10.45-7.15A1.78,1.78,0,0,1,242.16,118.77Z"
									/>
								</g>
								<g>
									<path
										className="blaster-21"
										d="M240.24,78.32l11.88-8.13a3.29,3.29,0,0,1,5.14,2.71V89.16a3.29,3.29,0,0,1-5.14,2.71l-11.88-8.13A3.29,3.29,0,0,1,240.24,78.32Z"
									/>
									<path
										className="blaster-22"
										d="M242.16,79.56l10.45-7.15a1.77,1.77,0,0,1,2.78,1.46V88.19a1.78,1.78,0,0,1-2.78,1.46L242.16,82.5A1.78,1.78,0,0,1,242.16,79.56Z"
									/>
								</g>
								<g>
									<path
										className="blaster-21"
										d="M255.83,103.35,244,111.47a3.29,3.29,0,0,1-5.15-2.71V92.51A3.29,3.29,0,0,1,244,89.8l11.87,8.12A3.29,3.29,0,0,1,255.83,103.35Z"
									/>
									<path
										className="blaster-22"
										d="M253.92,102.1l-10.46,7.16a1.78,1.78,0,0,1-2.78-1.47V93.48A1.78,1.78,0,0,1,243.46,92l10.46,7.16A1.78,1.78,0,0,1,253.92,102.1Z"
									/>
								</g>
							</g>
							<path
								className="blaster-23"
								d="M248.31,233.41h6.75l2.23,2.24v39.74l-4.14,4.14H236.81l-4.14-4.14V252.91l.83-2.48L245,235.07Z"
							/>
							<path
								className="blaster-18"
								d="M248.31,233.41h6.75l2.23,2.24v39.74l-4.14,4.14H236.81l-4.14-4.14V252.91l.83-2.48L245,235.07Z"
							/>
							<g>
								<circle className="blaster-24" cx="178.71" cy="246.25" r="3.63" />
								<circle className="blaster-24" cx="191.76" cy="246.25" r="3.63" />
							</g>
						</g>
					</g>
				</Wrapper>
			</g>
		</Svg>
	)
}): AbstractComponent<Props, Element>)

const Svg = styled.svg`
	.blaster-1 {
		isolation: isolate;
	}

	.blaster-2 {
		fill: #08081c;
	}

	.blaster-3 {
		fill: url(#radial-gradient);
	}

	.blaster-11,
	.blaster-4,
	.blaster-9 {
		fill: none;
	}

	.blaster-4 {
		stroke: #ffe19f;
		stroke-width: 10px;
	}

	.blaster-4,
	.blaster-9 {
		stroke-linejoin: round;
	}

	.blaster-5 {
		fill: url(#linear-gradient);
	}

	.blaster-6 {
		fill: url(#linear-gradient-2);
	}

	.blaster-18,
	.blaster-19,
	.blaster-24,
	.blaster-7 {
		mix-blend-mode: multiply;
	}

	.blaster-20,
	.blaster-7,
	.blaster-8 {
		opacity: 0.2;
	}

	.blaster-7 {
		fill: url(#linear-gradient-3);
	}

	.blaster-8 {
		fill: #fff;
	}

	.blaster-9 {
		stroke: #1a1a26;
		stroke-linecap: round;
		stroke-width: 2px;
	}

	.blaster-10 {
		fill: url(#radial-gradient-2);
	}

	.blaster-11 {
		stroke: #000;
		stroke-miterlimit: 10;
		stroke-width: 4px;
	}

	.blaster-12 {
		fill: url(#linear-gradient-4);
	}

	.blaster-13,
	.blaster-18,
	.blaster-19,
	.blaster-20,
	.blaster-24 {
		fill: #afafc6;
	}

	.blaster-14 {
		fill: url(#linear-gradient-5);
	}

	.blaster-15 {
		fill: url(#linear-gradient-6);
	}

	.blaster-16 {
		fill: url(#linear-gradient-7);
	}

	.blaster-17 {
		fill: #ffe19f;
	}

	.blaster-18 {
		opacity: 0.5;
	}

	.blaster-19 {
		opacity: 0.35;
	}

	.blaster-20 {
		mix-blend-mode: screen;
	}

	.blaster-21 {
		fill: #8888a5;
	}

	.blaster-22 {
		fill: #1a1a26;
	}

	.blaster-23 {
		fill: url(#linear-gradient-8);
	}

	.blaster-24 {
		opacity: 0.8;
	}
`
