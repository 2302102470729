// @flow
import { createContext, useContext } from 'react'

type MapContextType = {|
	widthSvg: number, // the width of the svg in svg units
	heightSvg: number, // the height of the svg in svg units
	widthInScreenCoordinates: number, // the width of the svg in screen units
	heightInScreenCoordinates: number, // the height of the svg in screen units
|}

export const MapContext: React$Context<MapContextType> = createContext<MapContextType>({
	widthSvg: 1,
	heightSvg: 1,
	widthInScreenCoordinates: 1,
	heightInScreenCoordinates: 1,
})

export function useMapContext(): MapContextType {
	return useContext(MapContext)
}

/**
 * useScreenPositionToMapPosition - scales the screen coordinates into the svg coordinates (does not account for rotation of the svg)
 *
 * @param  {number} screenX - the screen coordinate (horizontal) to translate into the map coordinate
 * @param  {number} screenY - the screen coordinate (vertical) to translate into the map coordinate
 * @returns {{
 *	 x: number, the screenX coordinate in the map svg x coordinate,
 *	 y: number, the screenY coordinate in the map svg y coordinate,
 *}}
 */
export function useScreenPositionToMapPosition(
	screenX: number,
	screenY: number
): { x: number, y: number } {
	const mapContext = useContext(MapContext)
	return {
		x: (screenX * mapContext.widthSvg) / mapContext.widthInScreenCoordinates,
		y: (screenY * mapContext.heightSvg) / mapContext.heightInScreenCoordinates,
	}
}
