import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components/macro'
import SensorData from './SensorData'
import { rgba, APP_WHITE } from '../../constants/styles'
import type { VisibleQuestionContent } from '../../types'
import VoteOptionCard from './VoteOptionCard'

import { JUNIOR_PLUS, getMissionType } from '../../store/stores/general'
import type { QuestionInfo as ClassQuestion } from '@mission.io/mission-toolkit'

type Props = {
	currentQuestion: ClassQuestion,
	selectedOptionId: ?string,
}

export default function Vote({ currentQuestion, selectedOptionId }: Props): React$Node {
	const isJrPlus = useSelector(getMissionType) === JUNIOR_PLUS

	const media = currentQuestion.media[0]

	return (
		<StyledVoting $shrink={isJrPlus}>
			<QuestionInfo $shrink={isJrPlus}>
				{media && media.type === 'IMAGE' && (
					<img
						src={media.url}
						css="max-height: 200px; max-width: 100%; object-fit: contain;"
						alt="Culminating Moment"
					/>
				)}
				<QuestionOptions>
					{currentQuestion.options.map((option: VisibleQuestionContent, index) => (
						<VoteOptionCard
							content={option}
							index={index}
							isSelected={option._id === selectedOptionId}
							key={option._id}
						/>
					))}
				</QuestionOptions>
			</QuestionInfo>
			{isJrPlus && <SensorData />}
		</StyledVoting>
	)
}

const QuestionInfo = styled.div`
	width: ${({ $shrink }) => ($shrink ? '50%' : '70%')};
	display: flex;
	flex-direction: column;
	gap: ${({ theme }) => theme.spacing};
`

const QuestionOptions = styled.div`
	overflow-y: scroll;
	overflow-x: hidden;
	border-radius: 3px;
	&::-webkit-scrollbar {
		width: 10px;
	}

	&::-webkit-scrollbar-track {
		border-radius: 10px;
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 10px;
		background: ${rgba(APP_WHITE, 0.2)};
	}
`

export const StyledVoting: StyledType<> = styled.div`
	flex: 1;
	min-height: 0;
	display: flex;
	flex-direction: row;
	justify-content: ${({ $shrink }) => ($shrink ? 'space-between' : 'center')};
	margin: 0 40px;
`
