import { reduceObject, getSum } from '../../utility/functions'
import {
	isJrCommunicationActive,
	isJrDefenseActive,
	isJrPowerActive,
	isJrScannerActive,
	isJrTractorBeamActive,
	isJrRepairsActive,
	type System,
} from '@mission.io/mission-toolkit'
import { getStudentId } from '../stores/general'

import type { ReduxStore } from '../rootReducer'
import { getActiveStudents } from './sharedSelectors'
import { getCanvasId } from '../../components/CreativeCanvas/connect/selectors'
import { getTraining } from './jrPlusState/training'

// communication

export function getMyReception(store: ReduxStore): number {
	const studentId = getStudentId(store.general)
	if (!studentId) {
		return 0
	}
	return store.jrState.stationData.communication.studentHits[studentId] || 0
}

export function isCommunicationActive(store: ReduxStore): boolean {
	return isJrCommunicationActive(store.jrState)
}

export function isCreativeCanvasStationActive(store: ReduxStore): boolean {
	if (getTraining(store)?.currentStation === 'creativeCanvas') {
		return true
	}
	return Boolean(getCanvasId(store))
}

export function getGroupRequiredReception(store: ReduxStore): ?number {
	return store.jrState.stationData.communication.groupRequiredConnectionCount
}

export function getGroupReception(store: ReduxStore): number {
	return reduceObject(
		store.jrState.stationData.communication.studentHits,
		(count, currentValue) => count + currentValue,
		0
	)
}

// defense

export function isDefenseActive(state: ReduxStore): boolean {
	return isJrDefenseActive(state.jrState)
}

export function getDefenseGroupHits(state: ReduxStore): number {
	return getSum(state.jrState.stationData.defense.studentHits, val => val)
}

// power

export function isPowerActive(state: ReduxStore): boolean {
	return isJrPowerActive(state.jrState)
}

export function getStudentPowerData(
	state: ReduxStore
): { averagePower: number, myValue: number, targetPower: number, completionTimerId: ?string } {
	let myVal: number = 0
	let studentId: ?string = getStudentId(state.general)
	const student = state.general.student
	const { power } = state.jrState.stationData
	if (student && studentId && power.studentValues) {
		myVal = power.studentValues[studentId]
	}
	return {
		averagePower: getGroupPower(state),
		completionTimerId: power.completionTimerId,
		targetPower: power.targetPower,
		myValue: myVal || 0,
	}
}

/**
 * Gets the average power for all active students
 */
export function getGroupPower(state: ReduxStore): number {
	const activeStudents = getActiveStudents(state)
	if (activeStudents.length === 0) {
		return 0
	}

	let totalPower = 0

	activeStudents.forEach(student => {
		totalPower += state.jrState.stationData.power.studentValues[student.id]
	})

	return totalPower / activeStudents.length
}

// scanner

export function isScannerActive(store: ReduxStore): boolean {
	return isJrScannerActive(store.jrState)
}

export function getGroupScans(store: ReduxStore): number {
	return reduceObject(
		store.jrState.stationData.scanner.studentScans,
		(total, current) => total + current.total,
		0
	)
}

// tractorBeam

export function isTractorBeamActive(state: ReduxStore): boolean {
	return isJrTractorBeamActive(state.jrState)
}

export function getTractorBeamGroupHits(state: ReduxStore): number {
	return getSum(state.jrState.stationData.tractorBeam.studentHits, val => val)
}

// repairs

export function getStudentRepairData(
	state: ReduxStore
): {
	myFixCount: number,
	myBrokenSystems: { [string]: System },
} {
	return {
		myFixCount: getMyFixCount(state),
		myBrokenSystems: getMyBrokenSystems(state),
	}
}

export function getMyFixCount(state: ReduxStore): number {
	const studentId = getStudentId(state.general)
	if (!studentId) {
		return 0
	}
	const myFixCount = state.jrState.stationData.repair.studentFixes.hasOwnProperty(studentId)
		? state.jrState.stationData.repair.studentFixes[studentId]
		: 0
	return myFixCount
}

export function getMyBrokenSystems(state: ReduxStore): { [string]: System } {
	const studentId = getStudentId(state.general)
	if (!studentId) {
		return {}
	}
	const myBrokenSystems = state.jrState.stationData.repair.brokenSystems.hasOwnProperty(studentId)
		? state.jrState.stationData.repair.brokenSystems[studentId]
		: {}
	// $FlowFixMe[incompatible-return] there is a mistake in mission toolkit for this type!
	return myBrokenSystems
}

export function isRepairsActive(state: ReduxStore): boolean {
	return isJrRepairsActive(state.jrState)
}
