import React from 'react'
import type { TLBaseShape } from '@tldraw/tlschema'
import { BaseBoxShapeUtil, HTMLContainer, defineShape } from '@tldraw/tldraw'
import { getDataURIFromURL } from './util'

export const FRAME_DIMENSIONS = [900, 675]

export const FRAME_LOCK_ID = 'shape:-CANVAS_FRAME_LOCK'

export const CUSTOM_FRAME_SHAPE_TYPE = 'custom-frame'

export type CustomFrameShapeType = TLBaseShape<
	'custom-frame',
	{ w: number, h: number, url?: string }
>

export const getFrameLockShape: (?string) => CustomFrameShapeType = (
	backgroundImageUrl: ?string
) => ({
	type: CUSTOM_FRAME_SHAPE_TYPE,
	isLocked: true,
	id: FRAME_LOCK_ID,
	x: 0,
	y: 0,
	index: 'a1',
	opacity: 1,
	parentId: 'page:page',
	props: {
		...CustomFrameShapeUtil.getDefaultProps(),
		...(backgroundImageUrl ? { url: backgroundImageUrl } : {}),
	},
})
/**
 * This custom shape overrides the base box shape in tldraw.
 * It is a custom background image shape that is locked to the canvas size.
 * There should only ever be one of these shapes on the canvas at a time.
 */
export class CustomFrameShapeUtil extends BaseBoxShapeUtil<CustomFrameShapeType> {
	static type: string = CUSTOM_FRAME_SHAPE_TYPE
	isAspectRatioLocked: CustomFrameShapeType => boolean = (_shape: CustomFrameShapeType) => true
	canResize: CustomFrameShapeType => boolean = (_shape: CustomFrameShapeType) => false
	canBind: CustomFrameShapeType => boolean = (_shape: CustomFrameShapeType) => false

	static getDefaultProps(): $PropertyType<CustomFrameShapeType, 'props'> {
		return {
			w: FRAME_DIMENSIONS[0],
			h: FRAME_DIMENSIONS[1],
		}
	}

	// Indicator — used when hovering over a shape or when it's selected; must return only SVG elements here. Since this is
	// a background image, we don't want to show any indicator.
	indicator(shape: CustomFrameShapeType): React$Node {
		return null
	}

	// The render method for rendering the shape to the canvas.
	component(shape: CustomFrameShapeType): React$Node {
		return (
			<HTMLContainer
				id={shape.id}
				style={{ border: 'none', overflow: 'hidden', pointerEvents: 'none' }}>
				{shape.props.url && (
					<img
						src={shape.props.url}
						alt="Canvas Background"
						style={{ width: '100%', height: '100%' }}
					/>
				)}
			</HTMLContainer>
		)
	}

	// An override method for downloading the canvas to svg.
	async toSvg(shape: CustomFrameShapeType): Promise<Element> {
		const g = document.createElementNS('http://www.w3.org/2000/svg', 'g')
		let src = shape.props.url
		if (src && src.startsWith('http')) {
			// If it's a remote image, we need to fetch it and convert it to a data URI
			src = (await getDataURIFromURL(src)) || ''
		}
		const image = document.createElementNS('http://www.w3.org/2000/svg', 'image')
		if (src) {
			image.setAttributeNS('http://www.w3.org/1999/xlink', 'href', src)
		}
		image.setAttribute('width', shape.props.w.toString())
		image.setAttribute('height', shape.props.h.toString())
		g.appendChild(image)
		return g
	}
}

export const CustomFrameShape: any = defineShape(CUSTOM_FRAME_SHAPE_TYPE, {
	util: CustomFrameShapeUtil,
	// to use a style prop, you need to describe all the props in your shape.
})
