// @flow
import createReducer, { type Reducer } from '../createReducer'

export type TransporterLinkItem = {
	index: number,
}

const types = {
	TRANSPORTER_ERROR: 'TRANSPORTER_ERROR',
	TRANSPORTER_RESET_ERROR: 'TRANSPORTER_RESET_ERROR',
}

type ResetErrorAction = {
	type: 'TRANSPORTER_RESET_ERROR',
}

export function resetError(): ResetErrorAction {
	return {
		type: types.TRANSPORTER_RESET_ERROR,
	}
}

// Reducer

export type TransporterStore = {
	showError: boolean,
}

/**
 * Gets the initial state for the `transporter` redux store
 */
export function getInitialState(): TransporterStore {
	return {
		showError: false,
	}
}

export default (createReducer<TransporterStore>(getInitialState(), {
	[types.TRANSPORTER_ERROR]: (state: TransporterStore, _) => {
		return { ...state, showError: true }
	},
	[types.TRANSPORTER_RESET_ERROR]: (state: TransporterStore, _) => {
		return { ...state, showError: false }
	},
}): Reducer<TransporterStore>)
