import React, { useState, useEffect } from 'react'
import {
	ImageShapeUtil,
	useValue,
	HTMLContainer,
	defineShape,
	type TLImageShapeProps,
	type Editor,
	usePrefersReducedMotion,
	useEditor,
	createShapeId,
	Vec2d,
} from '@tldraw/tldraw'
import type { TLBaseShape, TLImageAsset } from '@tldraw/tlschema'
import Loading from '../../basics/Loading'

const CUSTOM_IMAGE_SHAPE_TYPE = 'custom-image'
export type CustomImageShapeType = TLBaseShape<'custom-image', TLImageShapeProps>

// Support for gifs: copied from @tldraw/tldraw/packages/editor/src/lib/editor/shapes/ImageShapeUtil.tsx
const loadImage = async (url: string): Promise<HTMLImageElement> => {
	return new Promise((resolve, reject) => {
		const image = new Image()
		image.onload = () => resolve(image)
		image.onerror = () => reject(new Error('Failed to load image'))
		image.crossOrigin = 'anonymous'
		image.src = url
	})
}
// Support for gifs: copied from @tldraw/tldraw/packages/editor/src/lib/editor/shapes/ImageShapeUtil.tsx
const getStateFrame = async (url: string) => {
	const image = await loadImage(url)

	const canvas = document.createElement('canvas')
	canvas.width = image.width
	canvas.height = image.height

	const ctx = canvas.getContext('2d')
	if (!ctx) return

	ctx.drawImage(image, 0, 0)
	return canvas.toDataURL()
}

// Support for cropping: copied from @tldraw/tldraw/packages/editor/src/lib/editor/shapes/ImageShapeUtil.tsx
function useIsCropping(shapeId: string) {
	const editor = useEditor()
	return useValue('isCropping', () => editor.croppingId === shapeId, [editor, shapeId])
}

// Support for cropping: copied from @tldraw/tldraw/packages/editor/src/lib/editor/shapes/ImageShapeUtil.tsx
/**
 * When an image is cropped we need to translate the image to show the portion withing the cropped
 * area. We do this by translating the image by the negative of the top left corner of the crop
 * area.
 *
 * @param shape - Shape The image shape for which to get the container style
 * @returns - Styles to apply to the image container
 */
function getContainerStyle(shape: CustomImageShapeType) {
	const crop = shape.props.crop
	const topLeft = crop?.topLeft
	if (!topLeft || !crop) return

	const w = (1 / (crop.bottomRight.x - crop.topLeft.x)) * shape.props.w
	const h = (1 / (crop.bottomRight.y - crop.topLeft.y)) * shape.props.h

	const offsetX = -topLeft.x * w
	const offsetY = -topLeft.y * h
	return {
		transform: `translate(${offsetX}px, ${offsetY}px)`,
		width: w,
		height: h,
	}
}

// We had to create a custom shape util because the default image shape util doesn't allow us to render an image with a url src.
export class CustomImageShapeUtil extends ImageShapeUtil {
	static type: string = CUSTOM_IMAGE_SHAPE_TYPE
	canBind: CustomImageShapeType => boolean = (_shape: CustomImageShapeType) => false

	// There seems to be a problem with eslint here - it's not recognizing that this is a React function component because it is nested within a class.
	component(shape: CustomImageShapeType): React$Node {
		const containerStyle = getContainerStyle(shape)
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const isCropping = useIsCropping(shape.id)
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const prefersReducedMotion = usePrefersReducedMotion()
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const [staticFrameSrc, setStaticFrameSrc] = useState('')

		const { w, h } = shape.props
		const asset = shape.props.assetId ? this.editor.getAssetById(shape.props.assetId) : undefined

		if (asset?.type === 'bookmark') {
			throw Error("Bookmark assets can't be rendered as images")
		}
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const isSelected = useValue(
			'onlySelectedShape',
			() => shape.id === this.editor.onlySelectedShape?.id,
			[this.editor]
		)

		const showCropPreview =
			isSelected &&
			isCropping &&
			this.editor.isInAny('select.crop', 'select.cropping', 'select.pointing_crop_handle')

		// We only want to reduce motion for mimeTypes that have motion
		const reduceMotion =
			prefersReducedMotion &&
			(asset?.props.mimeType?.includes('video') || asset?.props.mimeType?.includes('gif'))
		// eslint-disable-next-line react-hooks/rules-of-hooks
		useEffect(() => {
			if (asset?.props.src && 'mimeType' in asset.props && asset?.props.mimeType === 'image/gif') {
				let cancelled = false
				const run = async () => {
					const newStaticFrame = await getStateFrame(asset.props.src)
					if (cancelled) return
					if (newStaticFrame) {
						setStaticFrameSrc(newStaticFrame)
					}
				}
				run()

				return () => {
					cancelled = true
				}
			}
		}, [prefersReducedMotion, asset?.props])

		const mediaSrc = !shape.props.playing || reduceMotion ? staticFrameSrc : asset?.props.src
		// Problematic code with default TLdraw image is fixed here! In order for css to render the image as a url, we need to wrap the url in quotes.
		const isUrl = mediaSrc?.startsWith('http')
		const backgroundImage = mediaSrc ? `url(${isUrl ? `"${mediaSrc}"` : mediaSrc})` : undefined

		return (
			<>
				{backgroundImage && showCropPreview && (
					<div style={containerStyle}>
						<div
							className={`tl-image tl-image-${shape.id}-crop`}
							style={{
								opacity: 0.1,
								backgroundImage,
							}}
							draggable={false}
						/>
					</div>
				)}
				<HTMLContainer id={shape.id} style={{ overflow: 'hidden' }}>
					<div className="tl-image-container" style={containerStyle}>
						{backgroundImage ? (
							<div
								className={`tl-image tl-image-${shape.id}`}
								style={{
									backgroundImage,
								}}
								draggable={false}
							/>
						) : (
							<g transform={`translate(${(w - 38) / 2}, ${(h - 38) / 2})`}>
								<Loading className="size-5" />
							</g>
						)}
						{asset?.props.isAnimated && !shape.props.playing && (
							<div className="tl-image__tg">GIF</div>
						)}
					</div>
				</HTMLContainer>
			</>
		)
	}
}

export const CustomImageShape: any = defineShape(CUSTOM_IMAGE_SHAPE_TYPE, {
	util: CustomImageShapeUtil,
	// to use a style prop, you need to describe all the props in your shape.
})

/**
 * Creates a custom image shape from an asset.
 * @param {Editor} editor
 * @param {TLImageAsset} asset
 * @param {{x: number, y: number, z?: number}} position
 */
export function createCustomImageFromAsset(
	editor: Editor,
	asset: TLImageAsset,
	position: { x: number, y: number, z?: number }
) {
	const currentPoint = Vec2d.From(position)
	const customImageShape = {
		id: createShapeId(),
		type: CUSTOM_IMAGE_SHAPE_TYPE,
		x: currentPoint.x - asset.props.w / 2,
		y: currentPoint.y - asset.props.h / 2,
		opacity: 1,
		props: {
			assetId: asset.id,
			w: asset.props.w,
			h: asset.props.h,
		},
	}
	editor.batch(() => {
		// Create the shapes
		editor.createShapes([customImageShape], true)

		// Re-position shapes so that the center of the group is at the provided point
		let { selectedPageBounds } = editor

		if (selectedPageBounds) {
			const offset = selectedPageBounds.center.sub(position)

			editor.updateShapes([
				{
					id: customImageShape.id,
					type: customImageShape.type,
					x: customImageShape.x - offset.x,
					y: customImageShape.y - offset.y,
				},
			])
		}
	})
}
