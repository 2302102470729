import React from 'react'

// An svg image that represents an arrow for voting, accepts a special prop `inverted` that will flip the arrow
export default function VoteArrow({ inverted, ...svgProps }: { inverted?: boolean }): React$Node {
	return (
		<svg
			{...svgProps}
			id="Vote-Arrow"
			data-name="Vote Arrow"
			viewBox={`0 0 ${VIEW_BOX.X} ${VIEW_BOX.Y}`}>
			<path
				d="M11.74,1,.65,14.25a1,1,0,0,0,.76,1.62H23.59a1,1,0,0,0,.76-1.62L13.26,1A1,1,0,0,0,11.74,1Z"
				transform={
					inverted
						? `translate(${VIEW_BOX.X - SKEW.X} ${VIEW_BOX.Y - SKEW.Y}) rotate(180)`
						: `translate(${SKEW.X} ${SKEW.Y})`
				}
			/>
		</svg>
	)
}

const VIEW_BOX = { X: 24.16, Y: 15.28 }
const SKEW = { X: -0.42, Y: -0.6 }
