import { sendMessage } from '../../store/stores/webSocket'
import MarkdownTextToSpeech from '../Markdown/MarkdownTextToSpeech'
import { Button } from '../basics/Buttons'
import { BaseSVG, SVG_HEIGHT } from './displays/LiteracyEventFrame/BaseSVG'
import { RelativePositioner } from './displays/LiteracyEventFrame/LiteracyEventFrame'
import { SingleOptionWrap, Title } from './displays/LiteracyEventFrame/OptionsFrame'
import { Overlay } from './helperComponents/Overlay'
import { useMarkdownOverridesForSpeakingTaskFeedback } from './hooks/markdownOverrides'
import { useLiteracyEventStudentFeedbackRequests } from './hooks/useLiteracyEvent'

import { LITERACY_EVENT } from '@mission.io/mission-toolkit/constants'

import React from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components/macro'

/**
 * LiteracyEventFeedback - an overlay which allows the student to submit feedback for the speaking tasks
 *
 * @return {React$Node}
 */
export function LiteracyEventFeedback(): React$Node {
	const providingFeedbackFor = useLiteracyEventStudentFeedbackRequests()
	const { Wrapper, wrapperProps, markdownOverrides } = useMarkdownOverridesForSpeakingTaskFeedback(
		providingFeedbackFor?.feedbackRequest
	)
	const dispatch = useDispatch()

	if (!providingFeedbackFor) {
		return null
	}

	const onSubmit = selectedOption => {
		dispatch(
			sendMessage('LITERACY_EVENT_SUBMIT_SPEAKING_TASK_FEEDBACK', {
				feedbackInstanceId: providingFeedbackFor.feedbackRequest.instanceId,
				selectedOption,
			})
		)
	}

	let promptInfo = {
		prompt: 'How was your interaction with the other students?',
		affirmativeButtonPrompt: 'Good',
		negativeButtonPrompt: 'Bad',
	}

	const target = providingFeedbackFor.forTask.target
	if (target.type === LITERACY_EVENT.TASK.SPEAKING_TASK_DISCRIMINATORS.TARGET.STUDENT) {
		promptInfo.prompt = target.feedbackPrompt
		promptInfo.affirmativeButtonPrompt =
			target.affirmativeButtonText ?? promptInfo.affirmativeButtonPrompt
		promptInfo.negativeButtonPrompt = target.negativeButtonText ?? promptInfo.negativeButtonPrompt
	}

	return (
		<Overlay style={{ padding: '4vh' }}>
			<CenterDiv>
				<RelativePositioner
					orientation="HORIZONTAL"
					style={{ flexDirection: 'column', alignItems: 'center' }}>
					<Title text="Confirm Interaction" id="literacy-event-feedback-form-title" />
					<StyledBaseSVG>
						<path
							id={'base-svg'}
							className="stroke-accent-aqua"
							fill="transparent"
							strokeWidth="10"
							d={`M${SVG_HEIGHT / 2} ${0} v${SVG_HEIGHT} `}
						/>
					</StyledBaseSVG>

					<SingleOptionWrap id={'literacy-event-feedback'} markerId={'literacy-event-feedback'}>
						<div
							css={`
								padding: var(--spacing2x) var(--spacing4x);
								text-align: center;
							`}>
							<Wrapper {...wrapperProps}>
								<MarkdownTextToSpeech disabledComponents={['a', 'img']} {...markdownOverrides}>
									{promptInfo.prompt}
								</MarkdownTextToSpeech>
							</Wrapper>
						</div>
					</SingleOptionWrap>
					<ButtonWrapper>
						<StyledMarkdownButton onClick={() => onSubmit('POSITIVE')}>
							<Wrapper {...wrapperProps}>
								<MarkdownTextToSpeech disabledComponents={['a', 'img']} {...markdownOverrides}>
									{promptInfo.affirmativeButtonPrompt}
								</MarkdownTextToSpeech>
							</Wrapper>
						</StyledMarkdownButton>
						<StyledMarkdownButton variant="danger" onClick={() => onSubmit('NEGATIVE')}>
							<Wrapper {...wrapperProps}>
								<MarkdownTextToSpeech disabledComponents={['a', 'img']} {...markdownOverrides}>
									{promptInfo.negativeButtonPrompt}
								</MarkdownTextToSpeech>
							</Wrapper>
						</StyledMarkdownButton>
					</ButtonWrapper>
				</RelativePositioner>
			</CenterDiv>
		</Overlay>
	)
}

const StyledBaseSVG = styled(BaseSVG)`
	height: 20px;
`

const ButtonWrapper = styled.div`
	display: flex;
	flex-direction: row;
	gap: var(--spacing) var(--spacing4x);
	padding-top: var(--spacing4x);
	flex-wrap: wrap;
	justify-content: center;
`

const CenterDiv = styled.div`
	display: flex;
	width: 100%;
	height: 100%;
	justify-content: center;
	align-items: center;
`

const StyledMarkdownButton = styled(Button)`
	display: flex;
	align-items: center;
	justify-content: center;
`
