import React, { useCallback } from 'react'
import { animated, useSpring } from '@react-spring/web'

import type { BeamProps as Props, LightBeamConfig } from './assets/upgradeLookup'

type BeamProps = Props & { width: number, beamConfig: LightBeamConfig }

export default function AnimatedBeam({
	targetLength,
	onRetracted,
	onExtended,
	springConfig,
	width,
	beamConfig,
}: BeamProps): React$Node {
	const onBeamRest = useCallback(() => {
		if (targetLength != null) {
			if (targetLength > 0 && onExtended) {
				onExtended()
			} else if (onRetracted) {
				onRetracted()
			}
		}
	}, [targetLength, onExtended, onRetracted])
	let animationProps = useSpring({
		from: { targetLength: 0, height: beamConfig.minHeight, opacity: 0.1 },
		to: { targetLength, height: beamConfig.maxHeight, opacity: 1 },
		config: springConfig,
		onRest: onBeamRest,
		delay: 0,
	})
	return (
		<g transform={`translate(${width / 2 - beamConfig.maxHeight / 2} 10) rotate(-90)`}>
			<AnimatedRay {...animationProps} beamConfig={beamConfig} />
		</g>
	)
}

const Ray = ({
	height,
	opacity,
	targetLength,
	beamConfig,
	...svgProps
}: {
	height: number,
	opacity: number,
	targetLength: number,
	beamConfig: LightBeamConfig,
}) => {
	const points = `0,${beamConfig.maxHeight * 0.25} ${targetLength},0 ${targetLength},${
		beamConfig.maxHeight
	} 0,${beamConfig.maxHeight * 0.75}`
	if (targetLength < 0) targetLength = Math.abs(targetLength)
	if (height < 0) height = Math.abs(height)
	return (
		<svg
			width={targetLength}
			height={height}
			viewBox={`0 0 ${targetLength} ${beamConfig.maxHeight}`}
			preserveAspectRatio="none"
			style={{ opacity }}>
			<defs>
				<linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
					<stop offset="0%" style={{ stopColor: 'rgb(162, 232, 255)', stopOpacity: 1 }} />
					<stop offset="40%" style={{ stopColor: 'rgb(162, 232, 255)', stopOpacity: 0.5 }} />
					<stop offset="100%" style={{ stopColor: 'rgb(162, 232, 255)', stopOpacity: 0.05 }} />
				</linearGradient>
			</defs>
			<polygon points={points} fill="url(#gradient)" />
		</svg>
	)
}

const AnimatedRay = animated(Ray)
