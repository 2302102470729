import React from 'react'
import { SECONDARY_PURPLE } from '../../../constants/styles'
import { SHIP_WIDTH, SHIP_HEIGHT } from '../constants'

// CONSTANTS USED FOR BUILDING THE SVG
const STRIP_HEIGHT = 70
const RIGHT_OVERFLOW = 500
const LEFT_OFFSET = 160
const LEFT_OVERFLOW = LEFT_OFFSET + 600
const TOP_OVERFLOW = 1200
const BOTTOM_OVERFLOW = 800

/**
 * A fancy path that looks like a shadow behind the ship overview.
 * The path is actually the negative of the ship and is lined up with the center of the ship, including overflow values
 * @returns
 */
export default function BackgroundShadow(): React$Node {
	return (
		<svg viewBox={`${-LEFT_OFFSET} 0 ${SHIP_WIDTH} ${SHIP_HEIGHT}`} fill="none" overflow="visible">
			<path
				fill={SECONDARY_PURPLE}
				opacity="0.25"
				d={`
                M${SHIP_WIDTH + RIGHT_OVERFLOW} ${SHIP_HEIGHT / 2 - STRIP_HEIGHT / 2}
                h ${-1491.4 - RIGHT_OVERFLOW} 
                    l .6 -85.1  
                        l 132.7 -305.7 
                            v ${-57.2 - TOP_OVERFLOW}  
                                h ${-561.9 - LEFT_OVERFLOW}  
                                    v ${1080 + TOP_OVERFLOW + BOTTOM_OVERFLOW}
                                h ${561.9 + LEFT_OVERFLOW} 
                            v ${-171.1 - BOTTOM_OVERFLOW}
                        l -132.7 -305.7
                    l -.6 -85.1
                h ${1491.4 + RIGHT_OVERFLOW}
                v -70
                Z`}
			/>
		</svg>
	)
}
