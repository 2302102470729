import React from 'react'

export default function TopFrame(props: { className?: string }): React$Node {
	return (
		<svg {...props} viewBox="17.76 17.76 1920 104.64">
			<polyline
				className="stroke-accent-aqua stroke-[5px] fill-none"
				style={{ strokeLinecap: 'round', strokeLinejoin: 'round' }}
				points="103.67 23.31 114.8 34.44 1838.86 34.44 1848.52 24.78"
			/>
		</svg>
	)
}
