import React, { Component, useState, useEffect, useRef } from 'react'
import * as easings from 'd3-ease'
import classnames from 'classnames'
import BaseStationBase from '../../images/BaseStationBase.svg'
import { useSpring, useSpringRef, animated } from '@react-spring/web'
import styled from 'styled-components'

import type { ReactObjRef } from '../../types'

const BASE_STATION_DISH_PROPORTION_OFFSET = 0.88

const StyledBase = styled.div`
	background-image: url(${BaseStationBase});
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	flex: 1 1 0;
	height: 100px;
	width: 100%;
`

type Props = {
	angle: number,
	bottomOffset: number,
	className?: string,
	whenLoaded: () => void,
	dishSpeed: number,
	onFinishRelocating?: () => void,
	// we can not move this definition after BaseStation definition because BaseStation relies on this definition as well
	// eslint-disable-next-line no-use-before-define
	baseStationRef: ReactObjRef<typeof BaseStation>,
}
type State = { baseHeight: number, dishHeight: number }

const VIEW_BOX_WIDTH = 520.56
const VIEW_BOX_HEIGHT = 358.33

export default function AnglePositioner(props: Props): React$Node {
	const { angle: targetAngle, dishSpeed, onFinishRelocating } = props
	const [currentAngle, setCurrentAngle] = useState(targetAngle)

	const currentAngleRef = useRef(currentAngle)
	currentAngleRef.current = currentAngle

	const onFinishLocatingRef = useRef(onFinishRelocating)
	onFinishLocatingRef.current = onFinishRelocating

	const springRef = useSpringRef()
	const changingData = useSpring({
		ref: springRef,
	})

	useEffect(() => {
		springRef.start({
			config: {
				duration: (Math.abs(currentAngleRef.current - targetAngle) / dishSpeed) * 1000,
				easing: easings.easeCubic,
			},
			from: { angle: currentAngleRef.current },
			to: { angle: targetAngle },
			onRest: () => {
				if (onFinishLocatingRef.current) {
					onFinishLocatingRef.current()
				}
				setCurrentAngle(currentAngleRef.current)
			},
			onChange: ({ value: { angle } }) => {
				setCurrentAngle(angle)
			},
		})
	}, [targetAngle, springRef, dishSpeed, onFinishLocatingRef])

	return <AnimatedBaseStation {...props} angle={changingData.angle || 0} />
}

export class BaseStation extends Component<Props, State> {
	baseRef: ReactObjRef<'div'>
	dishRef: ReactObjRef<'svg'>

	constructor(props: Props) {
		super(props)

		props.baseStationRef.current = this

		this.state = { baseHeight: 100, dishHeight: 100 }

		this.baseRef = React.createRef()
		this.dishRef = React.createRef()
	}

	render(): React$Node {
		const { angle, className } = this.props

		const partRotation = `rotate(${90 - angle} ${VIEW_BOX_WIDTH / 2} ${VIEW_BOX_HEIGHT})`

		return (
			<>
				<StyledBase className="base-station base-station-base" key="base" ref={this.baseRef} />
				<svg
					className={classnames('base-station', 'base-station-dish', className)}
					ref={this.dishRef}
					style={{ bottom: `${this.getCoordinateOffset()}px`, position: 'absolute' }}
					id="Layer_1"
					data-name="Layer 1"
					xmlns="http://www.w3.org/2000/svg"
					viewBox={`0 0 ${VIEW_BOX_WIDTH} ${VIEW_BOX_HEIGHT}`}>
					<defs>
						<linearGradient
							id="linear-gradient"
							x1="868.14"
							y1="445.91"
							x2="1009.97"
							y2="445.91"
							gradientUnits="userSpaceOnUse">
							<stop offset="0.04" stopColor="#423f40" />
							<stop offset="0.15" stopColor="#8b8889" />
							<stop offset="0.16" stopColor="#9f9c9d" />
							<stop offset="0.2" stopColor="#dbd9da" />
							<stop offset="0.22" stopColor="#f2f1f2" />
							<stop offset="0.33" stopColor="#f7f7f7" />
							<stop offset="0.35" stopColor="#fafafa" />
							<stop offset="0.42" stopColor="#fff" />
							<stop offset="0.46" stopColor="#faf9fa" />
							<stop offset="0.52" stopColor="#eaeaea" />
							<stop offset="0.58" stopColor="#d0d0d0" />
							<stop offset="0.64" stopColor="#adabac" />
							<stop offset="0.67" stopColor="#989697" />
							<stop offset="0.74" stopColor="#cfcece" />
							<stop offset="0.8" stopColor="#a8a7a7" />
							<stop offset="0.92" stopColor="#464545" />
							<stop offset="1" />
						</linearGradient>
						<linearGradient
							id="linear-gradient-2"
							x1="819.54"
							y1="445.91"
							x2="961.37"
							y2="445.91"
							gradientTransform="matrix(-1, 0, 0, 1, 1968.51, 0)"
							xlinkHref="#linear-gradient"
						/>
						<linearGradient
							id="linear-gradient-3"
							x1="752.76"
							y1="551.84"
							x2="1258.4"
							y2="551.84"
							gradientUnits="userSpaceOnUse">
							<stop offset="0.04" stopColor="#423f40" />
							<stop offset="0.16" stopColor="#454344" />
							<stop offset="0.28" stopColor="#504d4e" />
							<stop offset="0.39" stopColor="#626061" />
							<stop offset="0.49" stopColor="#7b797a" />
							<stop offset="0.58" stopColor="#989697" />
							<stop offset="0.64" stopColor="#918f90" />
							<stop offset="0.71" stopColor="#7d7c7c" />
							<stop offset="0.8" stopColor="#5d5c5c" />
							<stop offset="0.9" stopColor="#313030" />
							<stop offset="1" />
						</linearGradient>
						<linearGradient
							id="linear-gradient-4"
							x1="741.02"
							y1="560.65"
							x2="1270.15"
							y2="560.65"
							xlinkHref="#linear-gradient"
						/>
						<linearGradient
							id="linear-gradient-5"
							x1="0"
							y1="186.78"
							x2="520.56"
							y2="186.78"
							xlinkHref="#linear-gradient"
						/>
						<linearGradient
							id="linear-gradient-6"
							x1="251.32"
							y1="270.99"
							x2="275"
							y2="270.99"
							xlinkHref="#linear-gradient"
						/>
					</defs>
					<title>Dish</title>
					<path
						className="communication-cls-1"
						d="M1010,605.55,868.14,574.88,1009,286.26ZM896.35,569.92l106.6,27-.82-280.49Z"
						transform={`${partRotation} translate(-745.68 -286.26)`}
					/>
					<path
						className="communication-cls-2"
						d="M1007.13,605.55l.94-319.29L1149,574.88ZM1015,316.38l-.82,280.49,108.6-27Z"
						transform={`${partRotation} translate(-745.68 -286.26)`}
					/>
					<path
						className="communication-cls-3"
						d="M1258.4,474.09c-30.92,89.71-132.4,155.5-252.82,155.5s-221.9-65.79-252.82-155.5Z"
						transform={`${partRotation} translate(-745.68 -286.26)`}
					/>
					<path
						className="communication-cls-4"
						d="M1261.66,478.39a57.73,57.73,0,0,1-.82,6.7c-.23,1.19-.54,2.38-.88,3.53-.18.57-.36,1.14-.55,1.69l-.14.39-.11.29-.23.59-1.84,4.71c-.61,1.57-1.21,3.15-1.93,4.68l-2.08,4.61c-.67,1.55-1.44,3.05-2.21,4.55l-2.3,4.52c-.75,1.52-1.65,2.94-2.46,4.42a212.49,212.49,0,0,1-23.32,33.12,239.63,239.63,0,0,1-29,28.35,258.86,258.86,0,0,1-33.25,23.19l-8.83,5-9,4.68-9.17,4.32-9.33,4a304.86,304.86,0,0,1-38.45,12.75,322.42,322.42,0,0,1-160.37,0,305,305,0,0,1-38.46-12.75l-9.32-4-9.17-4.32-9-4.68-8.83-5a258.17,258.17,0,0,1-33.25-23.19,238.9,238.9,0,0,1-29-28.35C770.82,531.65,756.89,507.8,749,482l-1.62-5.3h5.4q127.23,0,254.66.44Zm-6.51,4.65-251.4,1.23q-125.49.48-251,.45l3.79-5.31a202.37,202.37,0,0,0,41,64.6,229.78,229.78,0,0,0,28.38,25.8A259.08,259.08,0,0,0,858,590.88l8.48,4.51,8.63,4.21,8.76,3.93,8.89,3.64a291.48,291.48,0,0,0,36.64,11.52,321.83,321.83,0,0,0,152.39,0,292,292,0,0,0,36.64-11.52l8.89-3.64,8.76-3.93,8.63-4.21,8.48-4.51a259.83,259.83,0,0,0,32.07-21.07,229.78,229.78,0,0,0,28.38-25.8,209.44,209.44,0,0,0,23.55-30.3c.84-1.36,1.78-2.67,2.56-4.07l2.42-4.16c1.68-2.74,3-5.64,4.57-8.47.78-1.41,1.45-2.87,2.13-4.32l2.05-4.37.25-.54.21-.46c.1-.22.2-.43.31-.63a5.74,5.74,0,0,1,.84-1.1C1253.2,484.89,1254.17,484.12,1255.15,483Z"
						transform={`${partRotation} translate(-745.68 -286.26)`}
					/>
					<rect
						className="communication-cls-5"
						y="174.9"
						width="520.56"
						height="23.76"
						transform={partRotation}
					/>
					<polygon
						className="communication-cls-6"
						points="253.44 198.66 251.32 343.33 275 343.33 270 198.66 253.44 198.66"
						transform={partRotation}
					/>
				</svg>
			</>
		)
	}

	getCoordinateOffset: () => number = () => {
		return this.state.baseHeight * BASE_STATION_DISH_PROPORTION_OFFSET
	}

	getRadialOffset: () => number = () => {
		return (
			(this.dishRef.current &&
				Math.sqrt(
					this.dishRef.current.clientWidth ** 2 + this.dishRef.current.clientHeight ** 2
				)) ||
			0
		)
	}

	updateDimensions: () => void = () => {
		this.setState({
			baseHeight: this.baseRef.current ? this.baseRef.current.clientHeight : 100,
			dishHeight: this.dishRef.current ? this.dishRef.current.clientHeight : 100,
		})
	}

	componentDidMount() {
		window.addEventListener('resize', this.updateDimensions)

		this.updateDimensions()
		this.props.whenLoaded()
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.updateDimensions)
	}
}

const AnimatedBaseStation = animated(BaseStation)
