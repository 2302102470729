import type { StationEnum } from '../../../../types'

export const ACTION_TYPES = ['CONTACT', 'TARGET', 'SCAN', 'TRACTOR_BEAM', 'TRANSPORT', 'POWER']

export type ActionType = 'CONTACT' | 'TARGET' | 'SCAN' | 'TRACTOR_BEAM' | 'TRANSPORT' | 'POWER'

export type InfoForOtherActions = {
	key: ActionType,
	isStationActive: boolean,
	complete: boolean,
	students: { [string]: boolean },
	locked: boolean,
}

export const MAP_RADIUS = 150
export const MAP_SIZE = MAP_RADIUS * 2

export const ACTIONS_TO_STATIONS: { [ActionType]: StationEnum } = {
	TARGET: 'defensePlus',
	TRACTOR_BEAM: 'tractorBeamPlus',
	CONTACT: 'communicationPlus',
	TRANSPORT: 'transporterPlus',
	POWER: 'powerPlus',
}

export const ACTIONS_TO_MESSAGES = {
	SCAN: {
		COMMAND: 'Scan',
		TITLE: 'Now Scanning',
		MESSAGE: 'Ask more students to scan this object to increase scanning speed',
		PRESENT_TENSE: 'Scanning',
		COMPLETED: 'Done Scanning',
		ACTIVE: '',
	},
	TARGET: {
		COMMAND: 'Target',
		TITLE: 'Preparing to Defend',
		MESSAGE: 'engage defenses',
		ACTIVE: 'Defense Active',
		PRESENT_TENSE: 'Defense',
		COMPLETED: 'Done Targeting',
	},
	TRACTOR_BEAM: {
		COMMAND: 'Beam',
		TITLE: 'Preparing to Tractor Beam',
		MESSAGE: 'engage tractor beams',
		ACTIVE: 'TB Active',
		PRESENT_TENSE: 'Beaming',
		COMPLETED: 'Done Tractor Beaming',
	},
	CONTACT: {
		COMMAND: 'Contact',
		TITLE: 'Preparing to load Communications',
		MESSAGE: 'engage communications',
		ACTIVE: 'Comm Active',
		PRESENT_TENSE: 'Contacting',
		COMPLETED: 'Done Contacting',
	},
	TRANSPORT: {
		COMMAND: 'Transport',
		TITLE: 'Preparing to Transport',
		MESSAGE: 'engage transportation',
		ACTIVE: 'Transport Active',
		PRESENT_TENSE: 'Transport',
		COMPLETED: 'Done Transporting',
	},
	POWER: {
		COMMAND: 'Power',
		TITLE: 'Preparing to Power',
		MESSAGE: 'engage powering station',
		ACTIVE: 'Power engaged',
		PRESENT_TENSE: 'Power',
		COMPLETED: 'Done Powering',
	},
}

export const CLIP_PATH_ID = 'clip'
export const CLIP_PATH_URL = `url(#${CLIP_PATH_ID})`
