export type VisitedMap = {
	id: string,
	name: string,
	visitedTime: number,
}

export const descriptorSizeMap = {
	ASTEROID: 16,
	SHIP: 20,
	CONTACT: 18,
	OTHER: 20,
	SMALL_PLANET: 140,
	MEDIUM_PLANET: 200,
	LARGE_PLANET: 400,
	DEFAULT_PLANET: 200,
}

export type SizeKey = $Keys<typeof descriptorSizeMap>

export const optionSelectorSizeMap: $Shape<typeof descriptorSizeMap> = {
	ASTEROID: descriptorSizeMap.ASTEROID + 4,
	SHIP: descriptorSizeMap.SHIP / 2 + 3,
	SMALL_PLANET: 2,
	MEDIUM_PLANET: 2,
	LARGE_PLANET: 2,
	DEFAULT_PLANET: 2,
	OTHER: descriptorSizeMap.OTHER / 2 + 2,
}

export const mapObjectKeys = ['other']
