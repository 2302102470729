import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getDeckMessages } from '../../../../store/selectors/jrPlusState/decks'
import { sendMessage } from '../../../../store/stores/webSocket'
import { AlertMessage } from './Alert'
import { APP_WHITE } from '../../../../constants/styles'
import styled, { keyframes } from 'styled-components/macro'
import { useScreenScaledValues } from '../../hooks'

import type { Message as MessageType } from '../../../../types'

export default function Messages(): React$Node {
	const messages = useSelector(getDeckMessages)
	const dispatch = useDispatch()

	return messages.map((message: MessageType) => {
		return (
			<Message
				key={`msg-${message.alertId}`}
				onClick={() => dispatch(sendMessage('DISMISS_ALERT', message.alertId))}
				text={message.text}
				location={message.point}
				status={message.status}
			/>
		)
	})
}
const MESSAGE_WIDTH = 310

const PARTY_EMOJI = '\uD83C\uDF89'
const SAD_EMOJI = '\uD83D\uDE1E'

const Message = ({
	text,
	status,
	onClick,
	location,
}: {
	text: string,
	status: 'error' | 'success',
	onClick: () => void,
	location: { x: number, y: number },
}) => {
	let [newX, newY] = useScreenScaledValues([location.x, location.y])
	return (
		<MessageWrapper x={newX} y={newY}>
			<PostAlertMessage onClick={onClick}>
				{status === 'success' ? (
					<div>
						<span role="img" aria-label="party">
							{PARTY_EMOJI}
						</span>{' '}
						{text}
					</div>
				) : (
					<div>
						<span role="img" aria-label="frown">
							{SAD_EMOJI}
						</span>{' '}
						{text}
					</div>
				)}
			</PostAlertMessage>
		</MessageWrapper>
	)
}

const slideIn = keyframes`
	0% {
		transform: translateY(-${MESSAGE_WIDTH}px);
		opacity: 0;
	}
	100% {
		transform: translateY(0);
		opacity: 1;
	}
`
const MessageWrapper = styled.div`
	animation: ${slideIn} 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
	position: absolute;
	width: ${MESSAGE_WIDTH}px;
	pointer-events: all;
	z-index: 50;
	${({ x, y }) => `
		left: ${x}px;
		top: ${y}px;
	`}
`

const PostAlertMessage = styled(AlertMessage)`
	color: ${APP_WHITE};
	cursor: pointer;
	padding: 10px;
	&:hover {
		opacity: 1;
	}
`
