import React from 'react'
import { Station } from '../../components/basics'
import { useDispatch } from 'react-redux'
import { sendMessage } from '../../store/stores/webSocket'
import ShooterGame from './components/ShooterGame'
import 'styled-components/macro'

export type OnHitFunctionType = ({ at: { x: number, y: number }, id: string }) => void

export default function TractorBeam(): React$Node {
	const dispatch = useDispatch()
	const hit: OnHitFunctionType = payload => {
		dispatch(
			sendMessage(
				'TRACTOR_BEAM',
				{ targetHit: true, instanceId: payload.id },
				{ location: payload.at }
			)
		)
	}

	const miss = () => {
		dispatch(sendMessage('TRACTOR_BEAM', { targetHit: false }))
	}

	return (
		<Station css="padding: 0;">
			<ShooterGame onHit={hit} onMiss={miss} />
		</Station>
	)
}
