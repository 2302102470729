import React from 'react'

import { BackgroundCircle, FilledPath } from '../helpers'
import type { Props } from '../helpers'

export default function DefenseIcon({
	transitionPattern = {},
	iconOnly,
	isOpen = true,
	...props
}: Props): React$Node {
	return (
		<svg
			{...props}
			id="Defense_1"
			data-name="Defense 1"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 70 70">
			<g
				style={{
					...transitionPattern,
					transform: `${isOpen ? 'scale(1)' : 'scale(0)'}`,
					transformOrigin: '50% 50%',
				}}>
				{!iconOnly && <BackgroundCircle cx="35" cy="35" r="35" />}
				<FilledPath
					d="M69,51.38,47,61.54S47,79.82,54.31,91C57.56,96,69,99.62,69,99.62c.85,0,11.84-3.91,15.09-9.22C91,79.13,91,61.54,91,61.54Zm-.85,45.7s-9.3-4.23-11.84-6.77c-5.93-7.62-6.77-27.08-6.77-27.08l18.61-8.46Z"
					transform="translate(-34 -38)"
					iconOnly={iconOnly}
					fill="#a335f9"
				/>
			</g>
		</svg>
	)
}
