// @flow
import type { CustomComponentDescriptors } from '../../Markdown/MarkdownTextToSpeech'
import type {
	FullStateLiteracyEventFeedbackRequest,
	FullStateLiteracyEventStudentTaskStateSpeaking,
} from '@mission.io/mission-toolkit'

import { getTeams } from '../../../store/selectors/jrPlusState/teams'
import { getStudents } from '../../../store/selectors/sharedSelectors'

import { LITERACY_EVENT } from '@mission.io/mission-toolkit/constants'

import React, { createContext, useContext } from 'react'
import { useSelector } from 'react-redux'

const STUDENT_NAME_FALLBACK = 'unknown student'
const TEAM_NAME_FALLBACK = 'unknown'

const NameContext = createContext<{ studentName: string, teamName: string }>({
	studentName: STUDENT_NAME_FALLBACK,
	teamName: TEAM_NAME_FALLBACK,
})

/**
 * NameProvider - a provider used to populate the studentName and teamName placeholders in markdown
 *
 * @param {Object} props - the react props
 * @param {string[]} props.studentIds - the ids of the students used to populate the studentName and teamName placeholder
 * @param {React$Node} props.children - the children to use this context
 *
 * @return {React$Node}
 */
function NameProvider({
	studentIds,
	children,
}: {
	studentIds: string[],
	children: React$Node,
}): React$Node {
	const students = useSelector(getStudents)

	const teams = useSelector(getTeams)
	const studentIdToTeamId = {}
	const teamIdToTeam = {}
	teams?.forEach(team => {
		team.students.forEach(student => {
			studentIdToTeamId[student.id] = team.id
		})
		if (team.lead) {
			studentIdToTeamId[team.lead.id] = team.id
		}
		teamIdToTeam[team.id] = team
	})

	const studentNames = []
	const teamIdsToShow = new Set()

	studentIds.forEach(studentId => {
		const studentData = students[studentId]
		if (studentData) {
			studentNames.push(`${studentData.firstName} ${studentData.lastName}`)
		}

		const studentTeamId = studentIdToTeamId[studentId]
		if (studentTeamId) {
			teamIdsToShow.add(studentTeamId)
		}
	})

	const teamNames = []
	teamIdsToShow.forEach(teamId => {
		let teamName = teamIdToTeam[teamId]?.displayName
		if (!teamName) {
			return
		}
		teamNames.push(teamName)
	})

	return (
		<NameContext.Provider
			value={{
				studentName: studentNames.join(' and '),
				teamName: teamNames.join(' and '),
			}}>
			{children}
		</NameContext.Provider>
	)
}

/**
 * StudentNameComponent - renders the studentName in markdown
 *
 * @return {React$Node}
 */
function StudentNameComponent(): React$Node {
	const studentName = useContext(NameContext)?.studentName ?? STUDENT_NAME_FALLBACK

	return <b>{studentName}</b>
}

/**
 * StudentNameTextToSpeechComponent - renders the studentName in a more screen reader friendly way
 *
 * @return {React$Node}
 */
function StudentNameTextToSpeechComponent(): React$Node {
	const studentName = useContext(NameContext)?.studentName ?? STUDENT_NAME_FALLBACK

	return <>{studentName}</>
}

/**
 * TeamNameComponent - renders the teamName in markdown
 *
 * @return {React$Node}
 */
function TeamNameComponent(): React$Node {
	const teamName = useContext(NameContext)?.teamName ?? TEAM_NAME_FALLBACK

	return <b>{teamName}</b>
}

/**
 * TeamNameTextToSpeechComponent - renders the teamName in a more screen reader friendly way
 *
 * @return {React$Node}
 */
function TeamNameTextToSpeechComponent(): React$Node {
	const teamName = useContext(NameContext)?.teamName ?? TEAM_NAME_FALLBACK

	return <>{teamName}</>
}

/**
 * getSpeakingTaskPreparser - get a markdown preparser to act on a string to make studentName (if allowed) and teamName (if allowed) renderable by the markdown parser.
 *
 * @param {Object} permissions - what should be made renderable
 * @param {boolean} permissions.allowStudentName - if true, will allow rendering the studentName component in markdown
 * @param {boolean} permissions.allowTeamName - if true, will allow rendering the teamName component in markdown
 *
 * @return {(string) => string} - a markdown perparser which will take in a string with custom component tokens and convert it to a string that markdown can process
 */
export function getSpeakingTaskPreparser(permissions: {
	allowStudentName: boolean,
	allowTeamName: boolean,
}): string => string {
	return (markdownText: string): string => {
		let convertedText = markdownText
		if (permissions.allowStudentName) {
			// the `g` flag causes all instances to be replaced
			convertedText = convertedText.replace(/{{student( |_){0,1}name}}/gi, '<studentName />')
		}
		if (permissions.allowTeamName) {
			// the `g` flag causes all instances to be replaced
			convertedText = convertedText.replace(/{{team( |_){0,1}name}}/gi, '<teamName />')
		}
		return convertedText
	}
}

type MarkdownOverridesBundle = {
	Wrapper: React$FragmentType | typeof NameProvider, // this should wrap the markdownDown component
	wrapperProps: any, // these props should be passed into the wrapper above where it is rendered
	// these should be passed into the component which renders markdown
	markdownOverrides: {|
		customComponents?: ?CustomComponentDescriptors,
		preparser?: ?(text: string) => string,
	|},
}

const NO_OVERRIDES_BUNDLE: MarkdownOverridesBundle = {
	Wrapper: React.Fragment,
	wrapperProps: {},
	markdownOverrides: Object.freeze({}),
}

/**
 * getMarkdownOverridesForStudentIds - get overrides to populate the studentName and teamName placeholders in markdown text
 *
 * @param {Array<string>} studentIds - the ids of the students to populate the placeholders with
 *
 * @return {MarkdownOverridesBundle}
 */
export function getMarkdownOverridesForStudentIds(
	studentIds: Array<string>
): MarkdownOverridesBundle {
	return {
		Wrapper: NameProvider,
		wrapperProps: { studentIds: studentIds },
		markdownOverrides: {
			customComponents: {
				studentName: {
					markdownComponent: StudentNameComponent,
					textToSpeechComponent: StudentNameTextToSpeechComponent,
				},
				teamName: {
					markdownComponent: TeamNameComponent,
					textToSpeechComponent: TeamNameTextToSpeechComponent,
				},
			},
			preparser: getSpeakingTaskPreparser({ allowStudentName: true, allowTeamName: true }),
		},
	}
}

/**
 * useMarkdownOverridesForSpeakingTaskFeedback - get any overrides which need to be used to render markdown for the literacy event feedback request
 *
 * @param {?FullStateLiteracyEventFeedbackRequest} feedbackRequest? - the feedback request being rendered
 *
 * @return {MarkdownOverridesBundle}
 */
export function useMarkdownOverridesForSpeakingTaskFeedback(
	feedbackRequest?: ?FullStateLiteracyEventFeedbackRequest
): MarkdownOverridesBundle {
	if (!feedbackRequest) {
		return NO_OVERRIDES_BUNDLE
	}

	return getMarkdownOverridesForStudentIds([feedbackRequest.studentId])
}

/**
 * useMarkdownOverridesForSpeakingTask - get any overrides which need to be used to render markdown for the given literacy event speaking task target
 *
 * @param {?FullStateLiteracyEventFeedbackRequest} feedbackRequest? - the feedback request being rendered
 *
 * @return {MarkdownOverridesBundle}
 */
export function useMarkdownOverridesForSpeakingTask(
	speakingTask?: ?$PropertyType<FullStateLiteracyEventStudentTaskStateSpeaking, 'talkTo'>
): MarkdownOverridesBundle {
	if (
		!speakingTask ||
		speakingTask.type !== LITERACY_EVENT.TASK.SPEAKING_TASK_DISCRIMINATORS.TARGET.STUDENT
	) {
		return NO_OVERRIDES_BUNDLE
	}

	return getMarkdownOverridesForStudentIds(speakingTask.studentIds)
}
