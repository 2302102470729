import LogRocket from 'logrocket'
import { SWITCH_STATION, STUDENT_DETAILS } from '../actionTypes'
import { sendMessage } from '../stores/webSocket'

import type { ReduxStore } from '../rootReducer'
import type { Action } from '../actionTypes'
import type { Dispatch } from 'redux'

export type MiddleWare = (
	store: $ReadOnly<{
		getState: () => ReduxStore,
		dispatch: Dispatch<Action>,
	}>
) => (next: (action: Action) => any) => (action: Action) => any

const generalMiddleware: MiddleWare = store => next => action => {
	if (action.type === SWITCH_STATION) {
		store.dispatch(sendMessage('STATION_SELECT', action.payload))
	} else if (action.type === STUDENT_DETAILS) {
		LogRocket.identify(action.payload.id)
	}
	return next(action)
}

export default generalMiddleware
