/**
 * get the `X` position from polar coordinates
 *
 * @param {number} angle the angle from the origin
 * @param {number} radius the distance proportion from the origin (0-1)
 * @param {number} canvasWidth the (radial) width of the canvas the polar plane sits upon [the x radius of underlying ellipse]
 * @param {number} canvasHeight the (radial) height of the canvas the polar plane sits upon [the y radius of underlying ellipse]
 * @param {number} radialOffsetX the inner x-radius which should be excluded from scaling
 * @param {number} radialOffsetY the inner y-radius which should be excluded from scaling
 * @return {number} the x position based on the origin
 **/
export function getX(
	angle: number,
	radius: number,
	canvasWidth: number,
	canvasHeight: number,
	radialOffsetX: number = 0,
	radialOffsetY: number = 0
): number {
	angle = angle % (Math.PI * 2)
	let multiplier = 1
	if (angle >= Math.PI / 2 && angle <= (Math.PI * 3) / 2) {
		multiplier = -1
	}
	if (angle === Math.PI / 2 || angle === (3 * Math.PI) / 2) {
		return 0
	}
	return (
		radialOffsetX * Math.cos(angle) +
		radius *
			Math.sqrt(
				Math.max(
					((canvasWidth - radialOffsetX * Math.abs(Math.cos(angle))) ** -2 +
						(Math.tan(angle) / (canvasHeight - radialOffsetY * Math.abs(Math.sin(angle)))) ** 2) **
						-1,
					0
				)
			) *
			multiplier
	)
}

/**
 * get the `Y` position from polar coordinates
 *
 * @param {number} angle the angle from the origin
 * @param {number} radius the distance proportion from the origin (0-1)
 * @param {number} canvasWidth the (radial) width of the canvas the polar plane sits upon [the x radius of underlying ellipse]
 * @param {number} canvasHeight the (radial) height of the canvas the polar plane sits upon [the y radius of underlying ellipse]
 * @param {number} radialOffsetX the inner x-radius which should be excluded from scaling
 * @param {number} radialOffsetY the inner y-radius which should be excluded from scaling
 * @return {number} the y position based on the origin
 **/
export function getY(
	angle: number,
	radius: number,
	canvasWidth: number,
	canvasHeight: number,
	radialOffsetX: number = 0,
	radialOffsetY: number = 0
): number {
	angle = angle % (Math.PI * 2)
	let multiplier = 1
	if (angle >= Math.PI) {
		multiplier = -1
	}
	if (angle % Math.PI === Math.PI / 2) {
		return radialOffsetY + radius * Math.max(canvasHeight - radialOffsetY, 0) * multiplier
	}

	return (
		radialOffsetY * Math.sin(angle) +
		radius *
			Math.sqrt(
				Math.max(
					((Math.tan(angle) * (canvasWidth - radialOffsetX * Math.abs(Math.cos(angle)))) ** -2 +
						(canvasHeight - radialOffsetY * Math.abs(Math.sin(angle))) ** -2) **
						-1,
					0
				)
			) *
			multiplier
	)
}

/**
 * rotate a rectangle about its origin and get its points
 *
 * @param {number} angle the angle to rotate
 * @param {number} width the width of the rectangle
 * @param {number} height the height of the rectangle
 * @param {number} makeAbsolute makes all values absolute and translates the top left corner to be the origin
 * @return {number[][]} the x,y coordinates of each point of the rotated rectangle
 **/
export function getRotatedRectanglePoints(
	angle: number,
	width: number,
	height: number,
	makeAbsolute: boolean = false
): Array<[number, number]> {
	angle = angle % (Math.PI * 2)
	if (angle % Math.PI === Math.PI / 2) {
		return [
			[0, 0],
			[0, height],
			[width, height],
			[width, 0],
		]
	}
	const midX = width / 2
	const midY = height / 2
	const points: Array<[number, number]> = [
		rotate(-midX, -midY, midX, midY, angle),
		rotate(-midX, midY, midX, midY, angle),
		rotate(midX, midY, midX, midY, angle),
		rotate(midX, -midY, midX, midY, angle),
	]

	if (!makeAbsolute) {
		return points
	}

	const { minX, maxY } = points.reduce(
		(boundary, current) => {
			if (current[0] < boundary.minX) {
				boundary.minX = current[0]
			}
			if (current[1] > boundary.maxY) {
				boundary.maxY = current[1]
			}
			return boundary
		},
		{ minX: Infinity, maxY: -Infinity }
	)
	return points.map(point => [point[0] - minX, maxY - point[1]])
}

function rotate(
	x: number,
	y: number,
	origX: number,
	origY: number,
	angle: number
): [number, number] {
	return [
		x * Math.cos(angle) - y * Math.sin(angle) + origX,
		x * Math.sin(angle) + y * Math.cos(angle) + origY,
	]
}
