// @flow
import React, { useMemo, createContext, useContext } from 'react'
import type { ImageWithText } from '@mission.io/mission-toolkit/actions'
import { CREATIVE_CANVAS_STATION } from '@mission.io/mission-toolkit/constants'

import { useSelector } from 'react-redux'
import { getCanvasIdForDocumentId } from '../selectors'

export type CanvasMedia = { ...ImageWithText, _id: string }
export type BackgroundCanvasMedia = { url: string, _id: string }

// The key that is used as the media type for the background image
export const BACKGROUND_IMAGE_KEY = 'BACKGROUND_IMAGE'
export const FRAME_LOCK_ID = 'CANVAS_FRAME_LOCK'
export const BACKGROUND_IMAGE_ID: string = 'CANVAS_BACKGROUND_IMAGE'

// /**
type CanvasMaterialContextType = {
	backgroundImage: ?BackgroundCanvasMedia,
	tools: Array<$Keys<typeof CREATIVE_CANVAS_STATION.CANVAS_TOOLS>>,
	images: Array<CanvasMedia>,
}

// Order that tools should be displayed in
const TOOL_ORDER = [
	CREATIVE_CANVAS_STATION.CANVAS_TOOLS.SHAPE,
	CREATIVE_CANVAS_STATION.CANVAS_TOOLS.DRAW,
	CREATIVE_CANVAS_STATION.CANVAS_TOOLS.TEXT,
	CREATIVE_CANVAS_STATION.CANVAS_TOOLS.IMAGE,
]

/**
 * Get any image media that will be displayed on the canvas. For now this includes background media and design media.
 * @param {ReduxStore} state
 * @returns {Array<CanvasMedia>} array of objects containing the url and type of the media
 * @returns {Array<CanvasMedia>} array of objects containing the url and type of the media
 */
function useMemoizedCanvasMaterial(documentId: string): CanvasMaterialContextType {
	const canvasId = useSelector(getCanvasIdForDocumentId(documentId))
	const canvasData = useSelector(
		state => state.jrPlusState.stationData.creativeCanvas.canvases[canvasId]?.canvasData
	)
	const team = useSelector(state =>
		state.jrPlusState.stationData.creativeCanvas.canvases[canvasId]?.teams.find(
			team => team.document.id === documentId
		)
	)

	return useMemo(() => {
		let media = []
		let backgroundImage = null
		let tools = new Set()
		const teamSpecificCanvasData = team ? canvasData.issues[team.issueId] : null
		// Add background image
		if (teamSpecificCanvasData?.backgroundMedia) {
			backgroundImage = {
				url: teamSpecificCanvasData.backgroundMedia.url,
				_id: teamSpecificCanvasData.backgroundMedia._id,
			}
		} else if (canvasData?.backgroundMedia) {
			backgroundImage = {
				url: canvasData.backgroundMedia.url,
				_id: canvasData.backgroundMedia._id,
			}
		}
		// Add tools
		if (canvasData?.canvasTools.length) {
			tools = new Set(canvasData.canvasTools)
		}
		if (teamSpecificCanvasData?.canvasTools.length) {
			teamSpecificCanvasData.canvasTools.forEach(tool => tools.add(tool))
		}
		if (tools.size === 0) {
			// This should never occur, but if there are no tools add all tools by default.
			tools = new Set(Object.keys(CREATIVE_CANVAS_STATION.CANVAS_TOOLS))
		}
		if (tools.has(CREATIVE_CANVAS_STATION.CANVAS_TOOLS.IMAGE)) {
			// Add design media
			if (canvasData?.designMedia?.length) {
				media.push(...canvasData.designMedia)
			}
			if (teamSpecificCanvasData?.designMedia?.length) {
				media.push(...teamSpecificCanvasData.designMedia)
			}
			if (media.length === 0) {
				tools.delete(CREATIVE_CANVAS_STATION.CANVAS_TOOLS.IMAGE)
			}
		}

		return {
			images: media,
			backgroundImage,
			tools: Array.from(tools).sort((a, b) => {
				return TOOL_ORDER.indexOf(a) - TOOL_ORDER.indexOf(b)
			}),
		}
	}, [team, canvasData])
}

export const CanvasMaterialContext: React$Context<CanvasMaterialContextType> = createContext<CanvasMaterialContextType>(
	{ images: [], backgroundImage: null, tools: [] }
)

export const CanvasMaterialProvider = ({
	children,
	documentId,
}: {
	children: React$Node,
	documentId: string,
}): React$Node => {
	const media = useMemoizedCanvasMaterial(documentId)
	return <CanvasMaterialContext.Provider value={media}>{children}</CanvasMaterialContext.Provider>
}

export const useCanvasMaterial = (): CanvasMaterialContextType => {
	return useContext(CanvasMaterialContext)
}
