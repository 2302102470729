import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import * as SliderIcons from '../../../images/jrPlusPanel/engineeringPanel/slidersIcons'
import * as TaskIcons from '../../../images/jrPlusPanel/engineeringPanel/taskIcons'
import { CONTRAST } from '../../../constants/styles'
import { TEAM_TYPES, getTeamForStudent } from '../../../store/selectors/jrPlusState/teams'
import { COMPONENT_TYPES } from '../../../store/selectors/jrPlusState/engineering'
import type { ReduxStore } from '../../../store/rootReducer'
import TextToSpeech from '../../../components/TextToSpeech'
import InterActiveCode from '../../../components/Frame/JrPlusFrame/components/Toolkit/Interactive/Code'

type TeamAlertData = {|
	alertActionMessage: string,
	teamTaskAnimation: string,
	teamTaskSliderIcon: string, // this must be an svg
	teamTaskIcon: string,
	prefix: string,
|}

type TeamPrefixData = {|
	prefix: string,
|}

type TogglePrefixData = {|
	onPrefix: string,
	offPrefix: string,
|}

type TeamComponentData = {|
	code: TeamPrefixData,
	slider: TeamPrefixData,
	toggle: TogglePrefixData,
	connection: TeamPrefixData,
	teamToggle: TeamAlertData[],
|}

type TeamComponentDataMap = {
	[teamType: $Keys<typeof TEAM_TYPES>]: TeamComponentData,
}

const TEAM_COMPONENT_DATA_MAP: TeamComponentDataMap = {
	[TEAM_TYPES.COMMAND]: {
		code: {
			prefix: 'Command code needed to reinitiate critical systems.',
		},
		slider: {
			prefix: 'Alert priority change needed.',
		},
		toggle: {
			onPrefix: 'Action approval required.',
			offPrefix: 'Alarm system shutdown required.',
		},
		connection: {
			prefix: 'Backup systems activation required.',
		},
		teamToggle: [
			{
				alertActionMessage: 'initiate reassignment',
				teamTaskAnimation:
					'https://resources-cdn.mission.io/simulations/engineeringPanel/taskCompletionVideos/Command.mp4',
				teamTaskSliderIcon: SliderIcons.CommandUrl,
				teamTaskIcon: TaskIcons.CommandUrl,
				prefix: 'Additional personnel needed to assist in repair.',
			},
		],
	},
	[TEAM_TYPES.ENGINEERING]: {
		code: {
			prefix: 'Engineering code needed to finalize repair.',
		},
		slider: {
			prefix: 'Power change needed to repair damaged systems.',
		},
		toggle: {
			onPrefix: 'Backup system ready.',
			offPrefix: 'Broken system identified.',
		},
		connection: {
			prefix: 'Power channels reroute required.',
		},
		teamToggle: [
			{
				alertActionMessage: 'initiate optimizations',
				teamTaskAnimation:
					'https://resources-cdn.mission.io/simulations/engineeringPanel/taskCompletionVideos/Engineering.mp4',
				teamTaskSliderIcon: SliderIcons.EngineeringUrl,
				teamTaskIcon: TaskIcons.EngineeringUrl,
				prefix: 'Repairs needed.',
			},
		],
	},
	[TEAM_TYPES.IT]: {
		code: {
			prefix: 'I.T. code needed to reconnect repaired systems to network.',
		},
		slider: {
			prefix: 'Network change needed to restore connection.',
		},
		toggle: {
			onPrefix: 'Additional network port needed.',
			offPrefix: 'Signal interference detected.',
		},
		connection: {
			prefix: 'Firewall bypass required.',
		},
		teamToggle: [
			{
				alertActionMessage: 'initiate upgrades',
				teamTaskAnimation:
					'https://resources-cdn.mission.io/simulations/engineeringPanel/taskCompletionVideos/IT.mp4',
				teamTaskSliderIcon: SliderIcons.ITUrl,
				teamTaskIcon: TaskIcons.ITUrl,
				prefix: 'Network upgrades needed.',
			},
		],
	},
	[TEAM_TYPES.MEDICAL]: {
		code: {
			prefix: 'Medical code needed to approve ongoing treatment of injured personnel.',
		},
		slider: {
			prefix: 'An injured person needs a change in treatment plan.',
		},
		toggle: {
			onPrefix: 'Emergency equipment needed to treat injured personnel.',
			offPrefix: 'Adjacent medical wing closure recommended.',
		},
		connection: {
			prefix: 'Transfer of injured personnel required.',
		},
		teamToggle: [
			{
				alertActionMessage: 'initiate treatment',
				teamTaskAnimation:
					'https://resources-cdn.mission.io/simulations/engineeringPanel/taskCompletionVideos/Medical.mp4',
				teamTaskSliderIcon: SliderIcons.MedicalUrl,
				teamTaskIcon: TaskIcons.MedicalUrl,
				prefix: 'Injuries reported.',
			},
		],
	},
	[TEAM_TYPES.OPERATIONS]: {
		code: {
			prefix: 'Operations code needed to approve expediting shipment of critical supplies.',
		},
		slider: {
			prefix: 'Movement needs to be rerouted to another deck.',
		},
		toggle: {
			onPrefix: 'Equipment request approval required.',
			offPrefix: 'Restricted travel implementation required.',
		},
		connection: {
			prefix: 'Personnel and equipment route changes required.',
		},
		teamToggle: [
			{
				alertActionMessage: 'initiate supply transport',
				teamTaskAnimation:
					'https://resources-cdn.mission.io/simulations/engineeringPanel/taskCompletionVideos/Operations.mp4',
				teamTaskSliderIcon: SliderIcons.OperationsUrl,
				teamTaskIcon: TaskIcons.OperationsUrl,
				prefix: 'Materials needed to complete repair.',
			},
		],
	},
	[TEAM_TYPES.SCIENCE]: {
		code: {
			prefix: 'Science code needed to initiate decontamination of area.',
		},
		slider: {
			prefix: 'CO2 scrubbers require adjustment.',
		},
		toggle: {
			onPrefix: 'Decontamination required.',
			offPrefix: 'Ventilation of area required.',
		},
		connection: {
			prefix: 'Atmospheric controls bypass required.',
		},
		teamToggle: [
			{
				alertActionMessage: 'initiate analysis',
				teamTaskAnimation:
					'https://resources-cdn.mission.io/simulations/engineeringPanel/taskCompletionVideos/Science.mp4',
				teamTaskSliderIcon: SliderIcons.ScienceUrl,
				teamTaskIcon: TaskIcons.ScienceUrl,
				prefix: 'Toxic material removal confirmation required.',
			},
		],
	},
	[TEAM_TYPES.SECURITY]: {
		code: {
			prefix: 'Security code needed to restore access to area after repair.',
		},
		slider: {
			prefix: 'Adjustment to number of security personnel needed.',
		},
		toggle: {
			onPrefix: 'Bulk head doors need to be shut.',
			offPrefix: 'Security protocols need to be temporarily removed.',
		},
		connection: {
			prefix: 'Security systems bypass required.',
		},
		teamToggle: [
			{
				alertActionMessage: 'initiate security sweep',
				teamTaskAnimation:
					'https://resources-cdn.mission.io/simulations/engineeringPanel/taskCompletionVideos/Security.mp4',
				teamTaskSliderIcon: SliderIcons.SecurityUrl,
				teamTaskIcon: TaskIcons.SecurityUrl,
				prefix: 'Ensuring secure area required.',
			},
		],
	},
	[TEAM_TYPES.FLIGHT_DIRECTOR]: {
		code: {
			prefix: 'Command code needed to locate ship.',
		},
		slider: {
			prefix: 'Power change needed for positioning device.',
		},
		toggle: {
			onPrefix: 'Action approval required.',
			offPrefix: 'Rogue computer shutdown required.',
		},
		connection: {
			prefix: 'Redirect incoming call required.',
		},
		teamToggle: [
			{
				alertActionMessage: 'initiate reassignment',
				teamTaskAnimation:
					'https://resources-cdn.mission.io/simulations/engineeringPanel/taskCompletionVideos/Command.mp4',
				teamTaskSliderIcon: SliderIcons.CommandUrl,
				teamTaskIcon: TaskIcons.CommandUrl,
				prefix: 'Additional personnel needed to assist in repair.',
			},
		],
	},
}

const DEFAULT_COMPONENT_DATA: TeamComponentData = {
	code: {
		prefix: '',
	},
	slider: {
		prefix: '',
	},
	toggle: {
		onPrefix: '',
		offPrefix: '',
	},
	connection: {
		prefix: '',
	},
	teamToggle: [
		{
			alertActionMessage: 'initiate something',
			teamTaskAnimation:
				'https://resources-cdn.mission.io/simulations/engineeringPanel/taskCompletionVideos/Security.mp4',
			teamTaskSliderIcon: SliderIcons.SecurityUrl,
			teamTaskIcon: TaskIcons.SecurityUrl,
			prefix: '',
		},
	],
}

const StudentName = styled.span`
	color: ${CONTRAST};
`

/**
 * getTeamAlertData - get the team data for the student
 *
 * @param {ReduxStore} state - the redux state
 * @param {string} studentId - the id of the student
 *
 * @return {TeamAlertData} the data for the team the student is a part of
 */
export function getTeamComponentData(state: ReduxStore, studentId: string): TeamComponentData {
	const teamData = getTeamForStudent(state, studentId)
	if (!teamData) {
		return DEFAULT_COMPONENT_DATA
	}
	return TEAM_COMPONENT_DATA_MAP[(teamData.teamType: any)] || DEFAULT_COMPONENT_DATA
}

export function useTeamToggleData(studentId: string): TeamAlertData {
	const studentsTeamComponentData = useSelector(state => getTeamComponentData(state, studentId))
	const positionInTeam = useSelector(state => getTeamForStudent(state, studentId))?.position ?? 0

	return studentsTeamComponentData.teamToggle[
		positionInTeam % studentsTeamComponentData.teamToggle.length
	]
}

type ActionDataRenderProps = {
	component: { id: string, name: string },
	isThisStudent: boolean,
	studentName: string,
	actionData: any,
	studentTeamComponentData: TeamComponentData,
	teamData: { teamName: string, teamType: string, position: number },
	studentId: string,
}

export const ACTION_DATA_RENDER_MAP: {
	[componentType: $Keys<typeof COMPONENT_TYPES>]: (props: ActionDataRenderProps) => React$Node,
} = {
	[COMPONENT_TYPES.CODE]: function CodeRequirements({
		component,
		isThisStudent,
		studentName,
		studentTeamComponentData,
		teamData,
	}: ActionDataRenderProps) {
		if (isThisStudent) {
			return (
				<TextToSpeech>
					{`${studentTeamComponentData.code.prefix} Get an active code on `}
					<StudentName>Your</StudentName>&nbsp;engineering panel&nbsp;
					<InterActiveCode componentId={component.id} />
				</TextToSpeech>
			)
		}
		return (
			<TextToSpeech>
				{`${studentTeamComponentData.code.prefix} Ask `}
				<StudentName>{studentName}</StudentName>
				&nbsp;{`on the ${teamData.teamName} Team to give you an active code.`}
				<InterActiveCode componentId={component.id} />
			</TextToSpeech>
		)
	},
	[COMPONENT_TYPES.CONNECTION]: function ConnectionRequirements({
		component,
		isThisStudent,
		studentName,
		actionData,
		studentTeamComponentData,
		teamData,
	}: ActionDataRenderProps) {
		const actionWord = actionData.connect ? 'connect' : 'disconnect'

		const phrase = (
			<span>
				{isThisStudent ? capitalizeFirstLetter(actionWord) : actionWord}
				&nbsp;<span className="notranslate"> {actionData.from} </span>
				{actionData.connect ? 'and' : 'from'}
				&nbsp;<span className="notranslate"> {actionData.to}</span>
			</span>
		)

		if (isThisStudent) {
			return (
				<TextToSpeech>
					{studentTeamComponentData.connection.prefix}&nbsp;{phrase} on{' '}
					<StudentName>Your</StudentName>
					&nbsp;engineering panel
				</TextToSpeech>
			)
		}
		return (
			<TextToSpeech>
				{studentTeamComponentData.connection.prefix} Ask <StudentName>{studentName}</StudentName>
				&nbsp;{`on the ${teamData.teamName} Team to`}
				&nbsp;{phrase} on their engineering panel
			</TextToSpeech>
		)
	},
	[COMPONENT_TYPES.SLIDER]: function SliderRequirements({
		component,
		isThisStudent,
		studentName,
		actionData,
		studentTeamComponentData,
		teamData,
	}: ActionDataRenderProps) {
		const phrase = (
			<span>
				{isThisStudent ? 'Move' : 'move'}
				&nbsp;<span className="notranslate"> {component.name}</span> to {actionData.to}
			</span>
		)
		if (isThisStudent) {
			return (
				<TextToSpeech>
					{studentTeamComponentData.slider.prefix}&nbsp;{phrase} on <StudentName>Your</StudentName>
					&nbsp;engineering panel
				</TextToSpeech>
			)
		}
		return (
			<TextToSpeech>
				{studentTeamComponentData.slider.prefix} Ask <StudentName>{studentName}</StudentName>
				&nbsp;{`on the ${teamData.teamName} Team to`}
				&nbsp;{phrase} on their engineering panel
			</TextToSpeech>
		)
	},
	[COMPONENT_TYPES.TOGGLE]: function ToggleRequirements({
		component,
		isThisStudent,
		studentName,
		actionData,
		studentTeamComponentData,
		teamData,
	}: ActionDataRenderProps) {
		const phrase = (
			<span>
				{isThisStudent ? 'Turn' : 'turn'}
				{actionData.to ? ' on' : ' off'}
				<span>&nbsp;{component.name}</span>
			</span>
		)
		const toggleData = actionData.to
			? studentTeamComponentData.toggle.onPrefix
			: studentTeamComponentData.toggle.offPrefix
		if (isThisStudent) {
			return (
				<TextToSpeech>
					{toggleData}&nbsp;{phrase} on <StudentName>Your</StudentName>&nbsp;engineering panel
				</TextToSpeech>
			)
		}
		return (
			<TextToSpeech>
				{toggleData} Ask <StudentName>{studentName}</StudentName>&nbsp;
				{`on the ${teamData.teamName} Team to`}
				&nbsp;
				{phrase} on their engineering panel
			</TextToSpeech>
		)
	},
	[COMPONENT_TYPES.TEAM_TOGGLE]: function TeamToggleRequirements({
		studentName,
		studentId,
		isThisStudent,
		studentTeamComponentData,
		teamData,
	}: ActionDataRenderProps) {
		const teamToggleData = useTeamToggleData(studentId)
		if (isThisStudent) {
			return (
				<TextToSpeech>
					{`${teamToggleData.prefix} ${capitalizeFirstLetter(
						teamToggleData.alertActionMessage
					)} on `}
					<StudentName>Your</StudentName>&nbsp;engineering panel.
				</TextToSpeech>
			)
		}
		return (
			<TextToSpeech>
				{teamToggleData.prefix} Ask <StudentName>{studentName}</StudentName>
				&nbsp;
				{`on the ${teamData.teamName} Team to ${teamToggleData.alertActionMessage} from their engineering panel.`}
			</TextToSpeech>
		)
	},
}

function capitalizeFirstLetter(string: string): string {
	return string.charAt(0).toLocaleUpperCase() + string.slice(1)
}
