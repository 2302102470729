import type { SetLoginErrorAction } from './stores/general'

import { isConnectedToMission, isWantingToRunMissionAsControl } from '../components/AppSetup'
import config from '../config'
import { setLoginError } from './stores/general'

const webSocketActions = {
	LOGIN_REQUIRED: (
		data: { payload?: { type?: string } },
		store: { dispatch: (action: SetLoginErrorAction) => void }
	) => {
		if (isWantingToRunMissionAsControl()) {
			const redirectUrl = `${config.loginUrl}?sendto=${window.location.href}`
			if (data?.payload?.type === 'USER_NOT_ALLOWED') {
				store.dispatch(setLoginError({ type: 'USER_NOT_ALLOWED', redirectUrl }))
				return
			}
			window.location.href = redirectUrl
			return
		}
		if (isConnectedToMission()) {
			window.location.href = '/'
		}
	},
}

export default webSocketActions
