// @flow
import React from 'react'
import styled from 'styled-components/macro'

/**
 * A component to display each proficiency score and its correlating colors
 * @param {{className?: string}} props
 * @returns
 */
export default function ProficiencyLegend(props: { className?: string }): React$Node {
	return (
		<div {...props}>
			{SCORES.map(({ level, description }) => (
				<ListItem key={level}>
					<ColorCoin level={level} />{' '}
					<span className="whitespace-nowrap">
						{level} - {description}
					</span>
				</ListItem>
			))}
		</div>
	)
}

export const SCORES = [
	{ level: 4, description: 'Beyond' },
	{ level: 3, description: 'Proficient' },
	{ level: 2, description: 'Progressing' },
	{ level: 1, description: 'Beginning' },
]

const ListItem = styled.div`
	display: flex;
	align-items: center;
	&:not(:last-child) {
		margin-bottom: var(--spacing);
	}
`

const ColorLevel = styled.div`
	background: ${({ level, theme }) => (level ? `var(--proficiency-level-${level})` : '#3f3b6e')};
	color: ${({ level }) => (!level ? '#ffffff66' : '#000')};
`

export const ColorCoin: StyledType<{ level: number }> = styled(ColorLevel)`
	border-radius: 50%;
	aspect-ratio: 1 / 1;
	width: 24px;
	margin-right: var(--spacing);
	display: flex;
	align-items: center;
	justify-content: center;
`
