import { useSelector } from 'react-redux'
import type { ReduxStore } from '../../../store/rootReducer'
import type { CreativeCanvasStationCriterion } from '@mission.io/mission-toolkit'
import { TRAINING_CANVAS_DATA, TRAINING_CANVAS_PROPS } from '../constants'
import { getTeams } from '../../../store/selectors/jrPlusState/teams'

// A state selector for a current canvas action id.
export function getCanvasId(state: ReduxStore): ?string {
	return state.jrPlusState.stationData.creativeCanvas?.currentCanvasId
}

/**
 * Given a document id, find the canvas id which the document is on.
 * @param {string} documentId
 */
export function getCanvasIdForDocumentId(documentId: string): ReduxStore => ?string {
	return (state: ReduxStore): ?string => {
		const canvasLookup = state.jrPlusState.stationData.creativeCanvas?.canvases
		return Object.keys(canvasLookup).find(canvasId => {
			const canvas = canvasLookup[canvasId]
			return canvas.teams.some(team => team.document.id === documentId)
		})
	}
}

/**
 * Gets team ids and document ids for all teams with documents for the current canvas
 */
export function getCanvasTeams(
	state: ReduxStore
): null | Array<{ teamId: string, documentId: string }> {
	const canvasId = getCanvasId(state)
	if (!canvasId) {
		return null
	}
	return state.jrPlusState.stationData.creativeCanvas.canvases[canvasId]?.teams.map(team => ({
		teamId: team.teamId,
		documentId: team.document.id,
	}))
}

/**
 * The hook version of `getCanvasTeams`
 */
export function useCanvasTeams(): null | Array<{ teamId: string, documentId: string }> {
	const teams = useSelector(getTeams)
	// Handle race condition where the server doesn't return the fact that the student is connected before the canvas station loads.
	const me = useSelector(state => state.general.student?.id)
	return useSelector(state => {
		const canvasTeams = getCanvasTeams(state)
		const connectedTeams = []
		teams?.forEach((team, index) => {
			let students = [...team.students]
			if (team.lead) {
				students.push(team.lead)
			}
			if (students.some(student => student.connected || student.id === me)) {
				const canvasTeam = canvasTeams?.find(ct => ct.teamId === team.id)
				if (canvasTeam) {
					connectedTeams.push(canvasTeam)
				}
			}
		})
		return connectedTeams
	})
}

// Hook that extracts all content needed from issue to display to students during canvas collaboration.
export function getIssueContent({
	canvasId,
	teamId,
}: {
	canvasId: string,
	teamId: string,
}): ReduxStore => {|
	prompt: string,
	criteria: Array<CreativeCanvasStationCriterion>,
|} | null {
	return (state: ReduxStore) => {
		if (canvasId === TRAINING_CANVAS_PROPS.canvasId) {
			return {
				prompt: TRAINING_CANVAS_DATA.prompt,
				criteria: TRAINING_CANVAS_DATA.criteria,
			}
		}
		const canvas = state.jrPlusState.stationData.creativeCanvas?.canvases[canvasId]
		const issuesList = Object.keys(canvas.canvasData.issues)
		const generalIssue = issuesList.length === 1 ? canvas.canvasData.issues[issuesList[0]] : null
		if (generalIssue) {
			return {
				prompt: generalIssue.prompt,
				criteria: generalIssue.rubric.criteria,
			}
		}
		if (!teamId || !canvas) return null
		const documentData = canvas.teams.find(team => team.teamId === teamId)
		if (!documentData) {
			return null
		}
		const issue = canvas.canvasData.issues[documentData.issueId]
		if (!issue) {
			return null
		}

		return {
			prompt: issue.prompt,
			criteria: issue.rubric.criteria,
		}
	}
}
