const VOICE_LANG = 'en-US'

const VOICE_NAMES = {
	WOMAN: [
		'Victoria', // Mac
		'Zira', // Windows
	],
	MAN: [
		'Alex', // Mac
		'David', // Windows
	],
}

const GENDER = {
	FEMALE: 'Female',
	MALE: 'Male',
}

const VOICES = {
	English: {
		Lang: 'en-US',
		Female: 'Victoria',
		Male: 'Alex',
	},
	Spanish: {
		Lang: 'es-MX',
		Female: 'Paulina',
		Male: 'Juan',
	},
}

export { VOICE_NAMES, VOICE_LANG, GENDER, VOICES }
