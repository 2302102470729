import React, { useCallback, useContext } from 'react'
import { ThemeContext } from 'styled-components'
import { CONTROLLER_Z_INDEX, rgba } from '../../../constants/styles'
import { ReactModal as Modal, CloseButton } from '../../basics'
import { useDispatch, useSelector } from 'react-redux'
import { sendMessage } from '../../../store/stores/webSocket'
import {
	getUIConfigurations,
	setShowModal,
	SHOW_SCREEN_EXTENSION_GUIDE,
} from '../../../store/stores/general'
import { getPlatform } from '../../../utility/functions'
import { Button } from '../../basics/Buttons.jsx'
import { useConsumeParam } from '../../../utility/hooks.jsx'

function getImage(fileName: string): string {
	const baseUrlImage =
		'https://resources-cdn.mission.io/public/images/screen-extension-setup-guide/'
	return `${baseUrlImage}${fileName}.gif`
}

/** This is a modal showing the steps to set up
 * the screen extension
 * @returns {React$Node} a React component
 */
export function SetupScreenExtension(): React$Node {
	const dispatch = useDispatch()
	const uiConfigurations = useSelector(getUIConfigurations)

	const isMac =
		getPlatform()
			.toUpperCase()
			.indexOf('MAC') >= 0

	const steps = [
		{
			description: isMac
				? 'Choose Apple menu and select "System Preferences"'
				: 'Right-click the desktop and select "Display Settings"',
			image: getImage(isMac ? 'step1_mac' : 'step1_pc'),
		},
		{
			description: isMac
				? 'Select "Display", then select the "Arrangement" tab and uncheck "Mirror Displays"'
				: 'Scroll down to "Multiple Displays" and select "Extend these Displays"',
			image: getImage(isMac ? 'step2_mac' : 'step2_pc'),
		},
		{
			description:
				'Drag the Mission Control tab to projector/TV for everyone to see. Keep Teacher Station (this tab) on your own computer where only you can see it',
			image: getImage('step3'),
		},
	]

	const themeContext = useContext(ThemeContext)
	const ModalStyles = {
		overlay: {
			background: rgba(themeContext.darkGreyColor, 0.8),
			zIndex: CONTROLLER_Z_INDEX + 1,
		},
	}

	const onClose = () => {
		dispatch(setShowModal(SHOW_SCREEN_EXTENSION_GUIDE, false))
		dispatch(
			sendMessage('SHOW_MODAL', {
				name: SHOW_SCREEN_EXTENSION_GUIDE,
				show: false,
			})
		)
	}

	// open the modal if the URL param is present
	useConsumeParam(
		'screenExtensionGuide',
		useCallback(
			(value: string) => {
				dispatch(setShowModal(SHOW_SCREEN_EXTENSION_GUIDE, value !== '0' && value !== 'false'))
			},
			[dispatch]
		)
	)

	return (
		<Modal
			className="w-11/12 md:w-5/6 lg:w-4/6 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 transform rounded-lg overflow-hidden max-h-[90%] text-white flex flex-col"
			frameContent={false}
			isOpen={uiConfigurations.SHOW_SCREEN_EXTENSION_GUIDE}
			style={ModalStyles}
			onRequestClose={onClose}>
			<div className="relative p-4 text-xl font-bold bg-primary-600 text-center">
				<CloseButton onClick={onClose} />
				<strong>THIS TAB ON TEACHER DEVICE</strong>
				<p className="text-sm font-normal mt-2 ">Teacher Station for teacher eyes only</p>
			</div>
			<div className="flex-1 py-4 bg-primary-900 overflow-auto">
				<ol className="flex flex-col gap-4 m-4 sm:flex-row">
					{steps.map((step, index) => (
						<li key={step.description} className="flex justify-center gap-2 w-full">
							<span className="text-xl font-bold">{index + 1}.</span>
							<div className="max-w-80">
								<img className="rounded mb-2" alt={`Step ${index + 1}`} src={step.image} />
								{step.description}
							</div>
						</li>
					))}
				</ol>
				<div className="flex items-center justify-center gap-8">
					<Button className="font-base min-w-[20%]" onClick={onClose}>
						Got it
					</Button>
				</div>
			</div>
		</Modal>
	)
}
