import React from 'react'
import { useSelector } from 'react-redux'
import { getMyTeam, useTeam } from '../../../store/selectors/jrPlusState/teams'
import { CanvasGridItemWrapper, TeamName, ExpandIcon, OptionLetter } from './CanvasGrid'
import Canvas from '../Canvas'
import classnames from 'classnames'
/**
 * A vote card for a single team's canvas. Displays the canvas and the team name. Clicking on the card will select the canvas.
 * @param {string} props.teamId the team id of the team whose canvas is being displayed
 * @param {string} props.documentId the documentId that represents the canvas created
 * @param {number} props.index the index of the canvas in the list of canvases
 * @param {boolean} props.selected whether or not the canvas is selected
 * @param {(documentId: ?string) => void} props.onSelect a callback that is called when the canvas is voted for
 * @param {(documentId: string) => void} props.onExpand a callback that is called when the expand icon is clicked
 */
export function CanvasCardForVoting({
	teamId,
	documentId,
	index,
	selected,
	onSelect,
	onExpand,
	className,
	voteTotal,
}: {
	teamId: string,
	documentId: string,
	onSelect?: (?string) => void,
	selected?: boolean,
	index: number,
	voteTotal?: number,
	className?: string,
	onExpand?: string => void,
}): React$Node {
	const team = useTeam(teamId)
	const myTeam = useSelector(getMyTeam)
	const isMyTeam = myTeam?.id === teamId

	const backgroundColorClass = 'bg-primary-700'

	return (
		<CanvasGridItemWrapper
			onClick={
				onSelect
					? () => {
							if (!selected) {
								onSelect(documentId)
							}
					  }
					: undefined
			}
			className={classnames(
				'[--canvas-border-radius:theme("spacing.3")] [--canvas-border-width:0]',
				'p-2 border-2 md:border-4 rounded-[--canvas-border-radius] ',
				backgroundColorClass,
				selected ? 'border-accent-green' : 'border-transparent',
				className
			)}>
			<Canvas documentId={documentId} readOnly />
			{onExpand && (
				<ExpandIcon
					className="absolute bottom-3 right-3"
					onClick={e => {
						onExpand(documentId)
					}}
				/>
			)}
			<OptionLetter index={index} className={backgroundColorClass} />
			{voteTotal ? (
				<div className="bg-primary-700 z-10 top-0 right-0 absolute p-2 rounded-tr-3xl rounded-bl-3xl text-white w-fit h-10 flex items-center justify-center">
					{`${voteTotal} Vote${voteTotal > 1 ? 's' : ''}`}
				</div>
			) : null}

			<TeamName isMyTeam={isMyTeam} team={team} className={backgroundColorClass} />
		</CanvasGridItemWrapper>
	)
}
