export const POWER_GAUGE_STATUS = {
	ON_TARGET: 'ON_TARGET',
	UNDER_TARGET: 'UNDER_TARGET',
	OVER_TARGET: 'OVER_TARGET',
}

/* STEPS are the power levels that a user can see, myValue passed in from props is based on a range from 0 to STEPS
 if you would like to change this value, please note these 2 things:
 1. The slider labels are only guaranteed to be drawn correctly if MAX_POWER is a factor of LOCAL_RANGE
 2. Max power is assumed be 5 on mission server (jrMission/constants.js) */
export const MAX_POWER: number = 5
export const POWER_STATION_DELTA = 0.25

export type PowerGaugeStatus = $Keys<typeof POWER_GAUGE_STATUS>
