import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import { FaCheck } from 'react-icons/fa'
import { getTotalRequiredStudentsForAction } from '../../../../store/selectors/jrPlusState/sensor'
import { getTotalStudentsEngaged, isActionActivated, isCompleted } from '../helpers/functions'
import { DeltaClockProgressBar } from '../../../../components/DeltaClock'
import { ProgressBar } from '../../../../components/basics'
import { ACCENT_GREEN } from '../../../../constants/styles'
import {
	ACTIONS_TO_MESSAGES,
	ACTIONS_TO_STATIONS,
	type ActionType,
	type InfoForOtherActions,
} from '../helpers/constants'
import { getActiveStudentCount, switchStation } from '../../../../store/stores/general'
import PercentText from '../../../../components/DeltaClock/PercentText'
import { OBJECT_ACTION_STYLES } from './OptionSelector/definitions'
import type { ObjectInfo as ObjectInformation } from '@mission.io/mission-toolkit'

type Props = {
	x: number,
	y: number,
	infoForOtherActions: InfoForOtherActions[],
	objectInfo: ObjectInformation,
	amIScanning: boolean,
}

const HEIGHT_PER_ACTION_LINE = 10
const HEIGHT_PER_BUTTON_LINE = 14
const WIDTH = 70

export default function ActionUpdates({
	x,
	y,
	objectInfo,
	infoForOtherActions,
	amIScanning,
}: Props): React$Node {
	const dispatch = useDispatch()
	const actionsWithActiveStations = infoForOtherActions.filter(info => info.isStationActive)
	const actionsWithActiveStudents = infoForOtherActions.filter(
		info => Object.keys(info.students).filter(studentId => info.students[studentId] === true).length
	)
	const activeScan = isActionActivated(objectInfo, 'SCAN')
	const scanComplete = isCompleted(objectInfo, 'SCAN')
	const height =
		HEIGHT_PER_ACTION_LINE *
			(infoForOtherActions.length + (scanComplete ? 1 : 0) + (activeScan && !amIScanning ? 1 : 0)) +
		actionsWithActiveStations.length * HEIGHT_PER_BUTTON_LINE

	const goToStation = (type: ActionType) => {
		const stationId = ACTIONS_TO_STATIONS[type]
		dispatch(switchStation(stationId))
	}
	return (
		<foreignObject x={x - WIDTH / 2} y={y} height={height} width={WIDTH}>
			<ActionUpdateStyle>
				{scanComplete && (
					<StyledDiv $justifyCenter>
						<span className="title">Scanned</span>
						<StyledCheck color={ACCENT_GREEN} />
					</StyledDiv>
				)}
				{actionsWithActiveStudents.map(({ key, students }) => (
					<ActionProgress key={key} type={key} objectInfo={objectInfo} />
				))}
				{activeScan && !amIScanning && <ActionProgress type={'SCAN'} objectInfo={objectInfo} />}
				{actionsWithActiveStations.map(({ key }) => {
					const activeMessage = ACTIONS_TO_MESSAGES[key].ACTIVE
					if (!activeMessage) return null
					return (
						<StationButton
							key={`station-button-${key}`}
							type={key}
							onClick={() => goToStation(key)}>
							{activeMessage}
						</StationButton>
					)
				})}
			</ActionUpdateStyle>
		</foreignObject>
	)
}

function ActionProgress({ type, objectInfo }: { type: ActionType, objectInfo: ObjectInformation }) {
	const selector =
		type === 'SCAN'
			? getActiveStudentCount
			: state => getTotalRequiredStudentsForAction(state, type)
	const totalStudentsRequired = useSelector(selector)
	const totalActiveStudents = getTotalStudentsEngaged(objectInfo, type)
	return (
		<StyledDiv>
			<span className="title">{ACTIONS_TO_MESSAGES[type].PRESENT_TENSE}</span>
			<div className="progress">
				{type === 'SCAN' ? (
					<DeltaClockProgressBar
						timerId={objectInfo.scanTimerId}
						progressColor={OBJECT_ACTION_STYLES[type].color}
						pausedProgressColor={OBJECT_ACTION_STYLES[type].color}
						className="scan-progress"
					/>
				) : (
					<ProgressBar
						className="custom-sweet-progress"
						percent={(totalActiveStudents / totalStudentsRequired) * 100}
						color={OBJECT_ACTION_STYLES[type].color}
					/>
				)}
			</div>
			<span>
				{type !== 'SCAN' ? (
					`${totalActiveStudents}/${totalStudentsRequired}`
				) : (
					<PercentText
						dominantBaseline="middle"
						textAnchor="middle"
						pointerEvents="none"
						timerId={objectInfo.scanTimerId ?? ''}
					/>
				)}
			</span>
		</StyledDiv>
	)
}

const StyledCheck = styled(FaCheck)`
	z-index: 0 !important;
`

const StyledDiv = styled.div`
	display: flex;
	justify-content: ${({ $justifyCenter }) => ($justifyCenter ? 'center' : 'space-between')};
	align-items: center;
	height: ${HEIGHT_PER_ACTION_LINE}px;
	width: 100%;
	color: white;
	.title {
		width: 30%;
		margin: 4px;
	}
	.progress {
		width: 40%;
	}
	.custom-sweet-progress {
		.react-sweet-progress-line-inner {
			min-height: 4px;
		}
		.react-sweet-progress-symbol {
			padding: 0;
		}
	}
	.scan-progress {
		height: ${HEIGHT_PER_ACTION_LINE - 5}px;
		border: 1px solid ${OBJECT_ACTION_STYLES.SCAN.color};
		border-radius: 4px;
	}

	> svg {
		z-index: revert !important;
	}
`
const ActionUpdateStyle = styled.div`
	font-family: 'Roboto', sans-serif !important;
	font-size: 6px;
	text-align: left;
	> {
		margin-top: 2px;
	}
`

const StationButton = styled.div`
	font-family: 'Orbitron', sans-serif !important;
	font-size: 5px;
	text-transform: uppercase;
	text-align: center;
	cursor: pointer;
	height: ${HEIGHT_PER_BUTTON_LINE - 4}px;
	width: fit-content;
	margin: 2px auto;
	padding: 2px;
	color: black;
	background-color: ${({ type }) => OBJECT_ACTION_STYLES[type].color};
	border-radius: 8px;
	&:hover {
		background-color: ${({ type }) => OBJECT_ACTION_STYLES[type].hoverColor};
	}
`
