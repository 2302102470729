import type { ReduxStore } from '../../rootReducer'
import {
	type QuestionPhase,
	type JuniorPlusStudentQuestionData as StudentQuestionData,
	getQuestionPhaseForStudent,
	getStudentQuestionData as getStudentQuestionData_MT,
	type AnalyticsQuestionResponse,
	type CulminatingMomentQuestion,
} from '@mission.io/mission-toolkit'
import { QUESTION_PHASE } from '@mission.io/mission-toolkit/constants'
import { getStudentId } from '../../stores/general'

export function getStudentQuestionData(
	store: ReduxStore,
	studentId: ?string
): ?StudentQuestionData {
	return studentId ? getStudentQuestionData_MT(store.jrPlusState, studentId) : null
}

/**
 * getStudentQuestionAnswersFromAnalytics - get the student question responses from analytics
 *
 * @param  {ReduxStore} store - the current redux store
 * @param  {?string} studentId - the id of the student to get the student answers for
 *
 * @returns {?Array<AnalyticsQuestionResponse>} - the student's question answers, null if not available
 */
export function getStudentQuestionAnswersFromAnalytics(
	store: ReduxStore,
	studentId: ?string
): ?Array<AnalyticsQuestionResponse> {
	if (!studentId) {
		return null
	}
	return store.analytics?.analytics?.studentAnalytics?.[studentId]?.questionAnswers
}

/**
 * getStudentCulminatingMomentAnswersFromAnalytics - get the student's culminating moment answers from analytics
 *
 * @param  {ReduxStore} store - the current redux store
 * @param  {?string} studentId - the id of the student to get the student answers for
 *
 * @returns {?Array<AnalyticsQuestionResponse>} - the student's culminating moment answers, null if not available
 */
export function getStudentCulminatingMomentAnswersFromAnalytics(
	store: ReduxStore,
	studentId: ?string
): ?Array<AnalyticsQuestionResponse> {
	if (!studentId) {
		return null
	}
	return store.analytics?.analytics?.studentAnalytics?.[studentId]?.culminatingMomentAnswers
}

/**
 * getCulminatingMomentQuestionsFromAnalytics - get the culminating moment questions from analytics
 *
 * @param  {ReduxStore} store - the current redux store
 *
 * @returns {?Array<CulminatingMomentQuestion>} - the culminating moment questions from analytics, null if not available
 */
export function getCulminatingMomentQuestionsFromAnalytics(
	store: ReduxStore
): ?Array<CulminatingMomentQuestion> {
	return store.analytics?.analytics?.culminatingMomentQuestions
}

export function getQuestionPhase(store: ReduxStore): QuestionPhase {
	const studentId = getStudentId(store.general)
	if (studentId) {
		return getQuestionPhaseForStudent(store.jrPlusState, studentId)
	}
	return { status: QUESTION_PHASE.STATUS.INACTIVE }
}

export function getIsQuestionSlotJrPlus(store: ReduxStore): boolean {
	return getQuestionPhase(store).status === QUESTION_PHASE.STATUS.ACTIVE
}
