// @flow
import React from 'react'
import { mapObject, filterObject } from '../../../../../utility/functions'

import Content from './Content'

import type { ObjectInfo as ObjectInformation } from '@mission.io/mission-toolkit'
import { MAP_SIZE, type InfoForOtherActions } from '../../helpers/constants'

type Props = {
	infoForOtherActions: Array<InfoForOtherActions>,
	objectId: string,
	objectType: string,
	x: number,
	y: number,
	open: boolean,
	hovered: boolean,
	objectInfo: ObjectInformation,
	name: string,
	open: boolean,
	subtitle: ?string,
	sizes: [number, number],
	amIScanning: boolean,
	scanComplete: boolean,
}

const PADDING = 20
const GAP = 3
const BUTTON_HEIGHT = 14

const SUBTITLE_LINE_HEIGHT = 8
const TITLE_HEIGHT = 10

const PROGRESS_HEIGHT = 20 + GAP + SUBTITLE_LINE_HEIGHT
const SUBTITLE_CHARACTER_LIMIT = 40
const TITLE_CHARACTER_LIMIT = 22

const RING_VIEW_BOX_WIDTH = 120

export default function OptionSelector({
	x,
	y,
	open,
	hovered,
	objectId,
	objectInfo,
	objectType,
	name,
	subtitle,
	sizes,
	amIScanning,
	infoForOtherActions,
	scanComplete,
}: Props): React$Node {
	const map = objectInfo.actionData.SCAN.students
	const scanningStudents = mapObject(
		filterObject(map, active => !!active),
		(val, id) => id
	)
	const totalScanningStudents = scanningStudents.length
	const totalLines =
		(!scanComplete ? 1 : 0) +
		(infoForOtherActions.length > 0 ? 1 : 0) +
		(name?.length > 0 ? 1 : 0) +
		((subtitle || '')?.length > 0 ? 1 : 0) +
		(totalScanningStudents > 0 ? 1 : 0)
	const boxHeight =
		PADDING +
		(totalLines - 1) * GAP +
		(!scanComplete ? BUTTON_HEIGHT : 0) +
		(infoForOtherActions.length > 0 ? BUTTON_HEIGHT : 0) +
		TITLE_HEIGHT * Math.ceil((name?.length ?? 0) / TITLE_CHARACTER_LIMIT) +
		SUBTITLE_LINE_HEIGHT * Math.ceil((subtitle?.length ?? 0) / SUBTITLE_CHARACTER_LIMIT) +
		(totalScanningStudents > 0 ? PROGRESS_HEIGHT : 0)
	const boxSize = [130, boxHeight]
	const boundingCircleBoxWidth = (sizes[0] > sizes[1] ? sizes[0] / 2 : sizes[1] / 2) * 2 + 10
	const offset = (20 / RING_VIEW_BOX_WIDTH) * boundingCircleBoxWidth
	const radius = (50 / RING_VIEW_BOX_WIDTH) * boundingCircleBoxWidth + offset
	const position =
		x > MAP_SIZE / 2
			? y < MAP_SIZE / 2
				? [offset - boxSize[0], offset]
				: [-offset - boxSize[0], -offset - boxSize[1]]
			: y < MAP_SIZE / 2
			? [offset, offset]
			: [offset, -offset - boxSize[1]]
	return (
		<g>
			{(amIScanning || open) && (
				<svg
					width={boundingCircleBoxWidth}
					height={boundingCircleBoxWidth}
					style={{ pointerEvents: 'none' }}
					x={x}
					y={y}
					viewBox={`0 0 ${RING_VIEW_BOX_WIDTH} ${RING_VIEW_BOX_WIDTH}`}>
					<circle cx={0} cy={0} r={52} stroke="white" fill="transparent" strokeWidth={3}>
						{open && <Blink />}
					</circle>

					<circle
						cx={0}
						cy={0}
						r={RING_VIEW_BOX_WIDTH / 2}
						stroke="white"
						fill="transparent"
						strokeDasharray="4 4 32 20"
						strokeWidth={3}>
						{amIScanning ? <Spin /> : open && <Blink />}
					</circle>
				</svg>
			)}
			{open && (
				<g>
					<svg width={boxSize[0]} height={boxSize[1]} x={x} y={y} fillOpacity={0.75}>
						<mask id="m1" fill="#fff" fillOpacity={1}>
							<rect
								id="d1"
								width={boxSize[0]}
								height={boxSize[1]}
								rx="4"
								x={position[0]}
								y={position[1]}
							/>
							<circle id="c1" r={radius} fill="#000" cx={0} cy={0} />
						</mask>
						<mask id="m2" fill="#fff" fillOpacity={1}>
							<rect
								id="d2"
								width={boxSize[0] - 5}
								height={boxSize[1] - 5}
								rx="2"
								x={position[0] + 2.5}
								y={position[1] + 2.5}
							/>
							<circle id="c2" r={radius + 2.5} fill="#000" cx={0} cy={0} />
						</mask>
						<use xlinkHref="#d1" fill="#ffffff" mask="url(#m1)" />
						<use xlinkHref="#d2" fill="#3d357d" mask="url(#m2)" />
					</svg>
					<foreignObject
						data-training={`${objectId}-SCAN`}
						width={boxSize[0]}
						height={boxSize[1]}
						x={x + position[0]}
						y={y + position[1]}>
						<Content
							name={name}
							subtitle={subtitle}
							objectInfo={objectInfo}
							objectId={objectId}
							totalScanningStudents={totalScanningStudents}
							infoForOtherActions={infoForOtherActions}
							scanComplete={scanComplete}
						/>
					</foreignObject>
				</g>
			)}
		</g>
	)
}

// SVG animation
const Blink = () => {
	return (
		<animate
			repeatCount="indefinite"
			dur="1.2s"
			attributeName="stroke"
			values="#ffffff79;#ffffff;#ffffff79;"
		/>
	)
}

// SVG animation
const Spin = () => {
	return (
		<animateTransform
			attributeName="transform"
			attributeType="XML"
			type="rotate"
			from="0 0 0"
			to="360 0 0"
			dur="8s"
			repeatCount="indefinite"
		/>
	)
}
