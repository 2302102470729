import React from 'react'
import styled from 'styled-components/macro'
import { BaseSVG } from '../BaseSVG'

// The amount of px space between the left edge of the frame and the start of the drawn option line.
export const HORIZONTAL_DRAW_START = 32

/**
 * A decorative svg design that appears on the bottom of the information frame when in the horizontal view for a literacy event.
 * @returns {React$Node}
 */
export function DecalInformation(): React$Node {
	return (
		<AbsoluteDiv>
			<BaseSVG
				css={`
					z-index: 1;
				`}>
				<clipPath id="cut-off-circle-svg-information-decal">
					<rect x="0" y="0" width="100" height="50" />{' '}
				</clipPath>
				<circle
					cx="25"
					cy="50"
					r="25"
					fill="#364984"
					clipPath="url(#cut-off-circle-svg-information-decal)"
				/>
				<circle
					cx="25"
					cy="50"
					r="15"
					fill="#020223"
					clipPath="url(#cut-off-circle-svg-information-decal)"
				/>
			</BaseSVG>
			<div css="flex: 1; position: relative; height: 10px;">
				<div css="position: absolute; height: 100%; left: -8px; bottom: 0; background-color: #25346c; width: 100%;" />
			</div>
			<svg viewBox="0 0 10 10" css="height: 10px; margin-left: -8px;">
				<path d="M0 0 L10 10 L0 10 L0 0 Z" fill="#25346C" />
			</svg>
		</AbsoluteDiv>
	)
}

const AbsoluteDiv = styled.div`
	pointer-events: none;
	align-items: baseline;
	padding-left: ${HORIZONTAL_DRAW_START}px;
	padding-right: 84px;
	width: 100%;
	position: absolute;
	overflow: visible;
	bottom: 0;
	left: 0;
	display: flex;
`
