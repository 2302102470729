import type { TLImageAsset } from '@tldraw/tlschema'
import { type Editor, type TLUiOverrides } from '@tldraw/tldraw'

/**
 * Tldraw override function for the editor.externalContentManager.createAssetFromUrl.
 * By default the manager will create "bookmark" shapes for urls added through this function.
 * We want to create image assets instead. This function would also hypothetically work for videos as well, but
 * we'll cross that bridge when we need to.
 * @param {Editor} _editor
 * @param {string} url
 * @param {{type: string, text: string}} meta
 * @returns {TLAsset} an asset that can be added to the document state
 */
export async function createAssetFromUrl(
	_editor: Editor,
	url: string,
	meta: { type: string, text: string, id: string }
): Promise<?TLImageAsset> {
	// Only support image url assets
	if (meta.type !== 'IMAGE') {
		return null
	}
	const mimeType = getMimeTypeFromUrl(url)
	const size = await getImageSizeFromSource(url).catch(() => ({ w: 100, h: 100 })) // default to a square if we can't get the size
	return {
		id: meta.id,
		type: 'image',
		typeName: 'asset',
		props: {
			name: meta.text,
			src: url,
			w: size.w,
			h: size.h,
			mimeType: mimeType,
			isAnimated: false,
		},
		meta: {},
	}
}

const getExtension = (url: string) => {
	const fileName = url
		.substr(1 + url.lastIndexOf('/'))
		.split('?')[0]
		.split('#')[0]
	return fileName.substr(1 + fileName.lastIndexOf('.'))
}

function getMimeTypeFromUrl(url: string) {
	const extension = getExtension(url)
	switch (extension) {
		case 'png':
			return 'image/png'
		case 'jpg':
		case 'jpeg':
			return 'image/jpeg'
		case 'gif':
			return 'image/gif'
		case 'svg':
			return 'image/svg+xml'
		default:
			return 'image/png'
	}
}

function getImageSizeFromSource(url: string) {
	const img = new Image()
	img.src = url

	return new Promise((resolve, reject) => {
		img.onload = function() {
			resolve({ w: img.width, h: img.height })
		}
		img.onerror = function(err) {
			reject(err)
		}
	})
}

// These ui overrids allow us to customize how we want the tldraw ui to behave
export const uiOverrides: TLUiOverrides = {
	// Here we customize the keyboard shortcuts we allow users to make in the editor. We remove the export as svg and png shortcuts and keep only edit shortcuts.
	keyboardShortcutsMenu: (_editor, keyboardShortcutsMenuSchema) => {
		const editShortcuts = keyboardShortcutsMenuSchema.find(
			shortcut => shortcut.id === 'shortcuts-dialog.edit'
		)
		if (!editShortcuts) {
			return []
		}
		return [
			{
				...editShortcuts,
				children: editShortcuts.children.filter(
					child => child.id !== 'export-as-svg' && child.id !== 'export-as-png'
				),
			},
		]
	},
	// Here we customize the context menu that appears when you right click on a shape. We remove all the options except for the ones we want.
	contextMenu: (_editor, contextMenuSchema) => {
		return includeChildrenInMenu(contextMenuItemsToKeep, contextMenuSchema)
	},
}
type MenuType = {
	[string]: {
		children?: MenuType,
	},
}

type ItemType = {
	id: string,
	children?: Array<ItemType>,
}

// This function recursively traverses along a MenuType, a tree of context menu items, and returns a new tree with only the items we want to keep.
// - If we do not explicitly say which children we want to keep in the MenuType, then all children are kept.
const includeChildrenInMenu = (childrenToKeep: ?MenuType, items: Array<ItemType>) => {
	if (!childrenToKeep) {
		return [...items]
	}
	return items
		.filter(item => {
			return childrenToKeep[item.id]
		})
		.map(item =>
			item.children
				? {
						...item,
						children: includeChildrenInMenu(childrenToKeep[item.id].children, item.children),
				  }
				: { ...item }
		)
}

// A map of context menu item keys to include. The keys map to objects. If the "children" key is not included in the object, then all children will be included.
const contextMenuItemsToKeep: MenuType = {
	'delete-group': {},
	'set-selection-group': {},
	'clipboard-group': {},
	modify: {
		children: {
			reorder: {
				children: {
					reorder: {
						children: {
							'bring-to-front': {},
							'bring-forward': {},
						},
					},
				},
			},
			arrange: {},
		},
	},
	selection: { children: { duplicate: {} } },
}
