import React, { Component } from 'react'
import { animated, useSpring } from '@react-spring/web'
import CollisionTypes from './collision'
import type { Collision } from './collision'
import SAT from 'sat'
import { getX, getY, getRotatedRectanglePoints } from './coordinateSystem'

import type { ReactObjRef } from '../../types'

import './Communication.css'

type Props = {
	angle: number,
	speed: number,
	height: number,
	width: number,
	canvasWidth: number,
	canvasHeight: number,
	bottomOffset: number,
	radialOffset: number,
	id: string,
	onFinish: () => void,
	onFrame?: (bulletId: string, collision: Collision) => void,
}

export default function BulletPositioner(props: Props): React$Node {
	const { opacity, radius } = useSpring({
		config: { duration: 1000 / props.speed },
		from: { radius: 0, opacity: 1 },
		to: { radius: 1, opacity: 0 },
		onRest: props.onFinish,
	})

	return <AnimatedBullet {...props} opacity={opacity} radius={radius} />
}

type AnimatedBulletProps = { ...Props, opacity: number, radius: number }

const AnimatedBullet = animated(
	class Bullet extends Component<AnimatedBulletProps> {
		bulletRef: ReactObjRef<'div'>

		constructor(props: AnimatedBulletProps) {
			super(props)

			this.bulletRef = React.createRef()
		}

		render(): React$Node {
			const {
				angle,
				opacity,
				radius,
				canvasWidth,
				canvasHeight,
				bottomOffset,
				onFrame,
				radialOffset,
				id,
			} = this.props
			const scaledRadial = Math.sqrt(this.getScaledWidth() ** 2 + this.getScaledHeight() ** 2) / 2
			if (onFrame) {
				onFrame(id, this.getCollision())
			}
			return (
				<div
					className="bullet"
					ref={this.bulletRef}
					style={{
						position: 'absolute',
						transform: `rotate(-${angle}rad)`,
						left:
							String(
								canvasWidth / 2 +
									getX(
										angle,
										radius,
										canvasWidth / 2,
										canvasHeight,
										radialOffset - scaledRadial,
										radialOffset - scaledRadial
									) -
									scaledRadial
							) + 'px',
						bottom:
							String(
								bottomOffset +
									getY(
										angle,
										radius,
										canvasWidth / 2,
										canvasHeight,
										radialOffset - scaledRadial,
										radialOffset - scaledRadial
									) -
									scaledRadial
							) + 'px',
						width: 0 + 'px',
						height: 0 + 'px',
						opacity: opacity ** 3,
						borderWidth: `${scaledRadial}px`,
						zIndex: 1,
					}}
				/>
			)
		}

		getScaledWidth: () => number = () => {
			return (this.props.canvasWidth / 2) * this.props.width
		}

		getScaledHeight: () => number = () => {
			return this.props.canvasHeight * this.props.height
		}

		/**
		 * gets the rectangular collision bounds of the bullet,
		 *
		 * @return {Collision} a rotated rectangle defining the collision bounds if the bullet has been drawn,
		 * 										 a Collision which will never collide with any other collision if otherwise
		 *
		 **/
		getCollision: () => Collision = (): Collision => {
			const { current } = this.bulletRef

			if (current) {
				let position = current.getBoundingClientRect()
				let points = getRotatedRectanglePoints(
					this.props.angle,
					position.width,
					position.height,
					true
				)
				return {
					type: CollisionTypes.POLYGON,
					collidable: true,
					bounds: new SAT.Polygon(
						new SAT.Vector(position.left, position.top),
						points.map(point => new SAT.Vector(...point))
					),
				}
			}

			return {
				type: CollisionTypes.CIRCLE,
				collidable: false,
				bounds: new SAT.Circle(new SAT.Vector(-99999999999, 0), 10), // this should never be tested, but if it is it should always be false
			}
		}
	}
)
