import React, { useState } from 'react'
import styled from 'styled-components'
import { Button, CloseButton } from './basics'
import { rgba, RED } from '../constants/styles'
import { isMobile } from 'react-device-detect'
import config from '../config'

const StyledTakeOff: StyledType<> = styled.div`
	position: absolute;
	top: 0;
	width: 100%;
	padding: 6px 0;
	background-color: ${rgba(RED, 0.5)};
	z-index: 1000;
`

const Centered = styled.div`
	align-items: center;
	justify-content: center;
	display: flex;
	height: 100%;
`

export default function GoFullHeader({ goFull }: { goFull: () => void }): React$Node {
	const [visible, setVisible] = useState(!isMobile)
	if (config.devFlags?.hideFullscreenHeader === true) {
		return null
	}
	return (
		visible && (
			<StyledTakeOff>
				<Centered>
					<h3 style={{ marginRight: '6px' }}>These controls are best in</h3>
					<Button onClick={goFull}>full screen</Button>
				</Centered>
				<CloseButton onClick={() => setVisible(false)} />
			</StyledTakeOff>
		)
	)
}
