import React, { Component, memo, type AbstractComponent } from 'react'

import './PartialImage.css'

type Props = {
	src: string,
	percentage: number,
}

class PartialImage extends Component<Props> {
	render() {
		return <div className={'PartialImage'} style={this.getContainerStyle()} />
	}

	getContainerStyle() {
		return {
			background: `url(${this.props.src}) no-repeat center center`,
			backgroundSize: 'contain',
			position: 'relative',
			opacity: this.props.percentage / 100,
		}
	}
}
export default (memo(PartialImage): AbstractComponent<Props>)
