import styled from 'styled-components/macro'
import { PRIMARY_PURPLE_VERY_FAINT } from '../../../constants/styles'

export const TaskOverlay: StyledType<> = styled.div`
	display: flex;
	position: absolute;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	justify-content: center;
	align-items: center;
	background-color: ${PRIMARY_PURPLE_VERY_FAINT};
`
