import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import reduxWebsocket_ from '@giantmachines/redux-websocket'
import LogRocket from 'logrocket'
import thunk from 'redux-thunk'
import * as middlewares from './middlewares'
import rootReducer from './rootReducer'
import { RECONNECT_DELAY } from './stores/webSocket'

import type { Store as _Store, Dispatch, Middleware } from 'redux'
import type { ReduxStore } from './rootReducer'
import type { Action } from './actionTypes'

const reduxWebsocketMiddleware =
	// @ts-expect-error when using vite, the default export of @giantmachines/redux-websocket is the correct export (ie. the middleware function), while in vitest the default export of @giantmachines/redux-websocket is the module and we have to get the middleware function from the named default export.
	typeof reduxWebsocket_ === 'function' ? reduxWebsocket_ : reduxWebsocket_.default

const enhancers = []
const initialState = {}
const middleware: Array<Middleware<ReduxStore, Action, Dispatch<Action>>> = [
	middlewares.preSendMessage,
	reduxWebsocketMiddleware({
		reconnectInterval: RECONNECT_DELAY,
		reconnectOnClose: true,
		reconnectOnError: true,
	}),
	...middlewares.webSocket,
	thunk,
	middlewares.general, // general middleware needs to come after websocket middleware so it can handle redux actions parsed from websocket messages
]

if (process.env.NODE_ENV !== 'development') {
	middleware.push(LogRocket.reduxMiddleware()) // Logrocket middleware needs to come last
}

const composedEnhancers = composeWithDevTools(applyMiddleware(...middleware), ...enhancers)

export type Store = _Store<ReduxStore, Action, Dispatch<Action>>

export function createReduxStore(initialState: {}): Store {
	return createStore(rootReducer, ((initialState: any): ReduxStore), composedEnhancers)
}
const store: Store = createReduxStore(initialState)

export default store
