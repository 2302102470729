import type { Coordinates as Point } from '../../types'

/**
 * Given a list of points, determines the weight or distance across all of them. This function matches an identical implementation on the
 * server and eventually we will want to remove it here and only use values given to us from the server
 * @param {Point[]} points
 */
export function getWeight(points: Point[]): number {
	if (points.length < 2) {
		return 0
	}
	let sum = 0
	let previous = points[0]
	for (let i = 1; i < points.length; i++) {
		let current = points[i]
		sum += getDistance(previous, current)
		previous = current
	}
	return sum
}

export function getDistance(point1: Point, point2: Point): number {
	return Math.sqrt((point1.x - point2.x) ** 2 + (point1.y - point2.y) ** 2)
}
