import type { Cell } from './types'

export function getDistanceToCell(cell: Cell): number {
	const {
		coordinates,
		rectangle: { height, width },
	} = cell

	const xDistance = width * Math.abs(coordinates[0])
	const yDistance = height * Math.abs(coordinates[1])

	return Math.abs(Math.sqrt(Math.pow(xDistance, 2) + Math.pow(yDistance, 2)))
}
