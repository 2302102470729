import React from 'react'
import styled, { css } from 'styled-components'
import { FaArrowLeft, FaArrowRight, FaCircle, FaRegCircle } from 'react-icons/fa'

const CssBloom = css`
	text-shadow: 0 0 1px #fff, 0 0 4px #fff;
`

export const Title: StyledType<> = styled.h3`
	font-size: ${({ theme }) => theme.fontxl};
	${CssBloom}
`

/**
 * AttemptFlipper - a react component used to navigate through a list of attempts
 *
 * @param {Object} props - the react props
 * @param {number} props.currentIndex - the index of the currently selected attempt
 * @param {number} props.length - the number of attempts made
 * @param {(currentIndex: number) => void} props.setCurrentIndex - a callback used to set the index of the selected attempt
 */
export function AttemptFlipper({
	currentIndex,
	length,
	setCurrentIndex,
}: {
	currentIndex: number,
	length: number,
	setCurrentIndex: (currentIndex: number) => void,
}): React$Node {
	if (length <= 1) {
		return null
	}
	return (
		<AttemptSelectorPositioner>
			{currentIndex > 0 ? (
				<AttemptSelectorLeft>
					<AttemptArrowWrapper onClick={() => setCurrentIndex(currentIndex - 1)}>
						<FaArrowLeft />
						&nbsp;Previous
					</AttemptArrowWrapper>
				</AttemptSelectorLeft>
			) : null}
			<AttemptWrapper>
				<AttemptLabel>Attempt {currentIndex + 1}</AttemptLabel>
				<AttemptList>
					{new Array(length).fill(0).map((_, index) => (
						<FaCircleWrapper onClick={() => setCurrentIndex(index)}>
							{currentIndex === index ? <FaCircle /> : <FaRegCircle />}
						</FaCircleWrapper>
					))}
				</AttemptList>
			</AttemptWrapper>

			{currentIndex < length - 1 ? (
				<AttemptSelectorRight>
					<AttemptArrowWrapper onClick={() => setCurrentIndex(currentIndex + 1)}>
						Next&nbsp;
						<FaArrowRight />
					</AttemptArrowWrapper>
				</AttemptSelectorRight>
			) : null}
		</AttemptSelectorPositioner>
	)
}

const AttemptArrowWrapper = styled.div`
	cursor: pointer;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	min-width: 2.5em;
`

const FaCircleWrapper = styled.div`
	cursor: pointer;
`

const AttemptSelectorPositioner = styled.div`
	margin-top: var(--spacing3x);
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
	position: relative;
`

const AttemptList = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	font-size: 0.5em;
	gap: 4px;
`

const AttemptWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`

const AttemptSelector = styled.div`
	background-color: #0008;
	display: flex;
	flex-direction: row;
	justify-content: center;
	border-radius: 4px;
	margin: 8px;
	padding: 4px;
	gap: 20px;
	z-index: 1;
	position: absolute;
`

const AttemptSelectorLeft = styled(AttemptSelector)`
	left: 0;
`

const AttemptSelectorRight = styled(AttemptSelector)`
	right: 0;
`

const AttemptLabel = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	font-size: 1.1em;
`

export const SimpleQuestionWrapper: StyledType<> = styled.div`
	background-color: #364984;
	color: white;
	padding: ${({ theme }) => theme.spacing};
	border-radius: ${({ theme }) => theme.spacing};
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`

export const StyledQuestionGrid: StyledType<> = styled.div`
	width: 100%;
	padding: ${({ theme }) => theme.spacing};
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: auto;
	gap: ${({ theme }) => theme.spacing};
	margin-bottom: ${({ theme }) => theme.spacing2x};
`
