import React from 'react'
import { useDispatch } from 'react-redux'
import { StyledVoting } from './Vote'
import { useSelector } from 'react-redux'
import { type CollaborativeCulminatingMomentType } from './ClassQuestion'
import Canvas from '../CreativeCanvas/Canvas'
import { getMyTeam } from '../../store/selectors/jrPlusState/teams'
import { CanvasGrid, ExpandIcon } from '../CreativeCanvas/components/CanvasGrid'
import { CanvasCardForVoting } from '../CreativeCanvas/components/CanvasCardForVoting'
import { ViewToObserveVotes as TeacherVoteView } from '../CreativeCanvas/components/TeacherStation'
import { sendMessage } from '../../store/stores/webSocket'
import { getScreen } from '../../store/selectors/sharedSelectors'
import { ReactModal as Modal } from '../basics'
import { CLASS_QUESTION_INDEX } from '../../constants/styles'
import Tabs from './Tabs'
import { isController } from '../AppSetup'

type Props = {
	selectedDocumentId: ?string,
	collaborativeCulminatingMoment: CollaborativeCulminatingMomentType,
}

/**
 * A component that renders all canvases a student may vote for. Also includes access to the requirements and data used to create the canvases.
 * Students may vote for the canvas they think best represents the mission issue.
 * @returns {React$Node}
 */
export default function CanvasVote({
	selectedDocumentId,
	collaborativeCulminatingMoment,
}: Props): React$Node {
	const { qualifiedDocuments } = collaborativeCulminatingMoment
	const screenId = useSelector(getScreen)?._actionId
	const [expandedDocumentId, setExpandedDocumentId] = React.useState()
	const studentTeam = useSelector(getMyTeam)
	const isTeacher = isController()

	const dispatch = useDispatch()

	const selectDocument = (documentId: ?string) => {
		dispatch(
			sendMessage('VOTE_FOR_COLLABORATIVE_CULMINATING_MOMENT_DOCUMENT', {
				screenId,
				documentId,
			})
		)
	}
	if (isTeacher) {
		// Voting logic for teacher is handled in the creative canvas component
		return <TeacherVoteView qualifiedDocuments={qualifiedDocuments} />
	}
	return (
		<StyledVoting>
			<CanvasGrid totalTeams={qualifiedDocuments.length}>
				{qualifiedDocuments.map(({ documentId, teamId }, index) => (
					<CanvasCardForVoting
						className="max-w-80"
						key={teamId}
						documentId={documentId}
						canvas
						teamId={teamId}
						index={index}
						onSelect={selectDocument}
						onExpand={setExpandedDocumentId}
						selected={selectedDocumentId === documentId}
					/>
				))}
			</CanvasGrid>

			<div className="flex flex-col gap-4 flex-[2] relative">
				{studentTeam ? (
					<Tabs canvasId={collaborativeCulminatingMoment.canvasStationId} teamId={studentTeam.id} />
				) : null}
			</div>
			<Modal
				isOpen={Boolean(expandedDocumentId)}
				onRequestClose={() => setExpandedDocumentId(null)}
				style={{
					overlay: { backgroundColor: 'transparent', zIndex: CLASS_QUESTION_INDEX + 1 },
					content: {
						padding: 0,
						inset: 'auto 28vw 64px 80px',
						transform: 'none',
						height: 'fit-content',
						maxWidth: '60vw',
						width: '60vw',
						overflow: 'hidden',
						border: 'none',
						backgroundColor: 'transparent',
					},
				}}>
				{expandedDocumentId && (
					<div className="relative">
						<Canvas documentId={expandedDocumentId} readOnly />
						<ExpandIcon
							className="bottom-6 right-6"
							onClick={() => setExpandedDocumentId(null)}
							expanded
						/>
					</div>
				)}
			</Modal>
		</StyledVoting>
	)
}
