const shipPath = `M163.638 82.819V86.794C163.528 87.4217 163.468 88.0569 163.458 88.694C163.171 91.104 162.913 93.518 162.645 95.93C162.27
 99.292 161.895 102.655 161.521 106.018C161.159 109.258 160.797 112.498 160.435 115.739C160.066 119.053 159.699 122.367 159.335 125.681C158.967
  128.97 158.601 132.26 158.235 135.549C157.915 138.424 157.596 141.3 157.279 144.175C156.864 147.903 156.447 151.631 156.031 155.359L154.921 165.3C154.556 
  168.567 154.191 171.832 153.826 175.095C153.407 178.872 152.99 182.649 152.575 186.426C152.153 190.226 151.667 194.026 151.323 197.831C151.145 200.408 150.382 
  202.91 149.094 205.149C147.921 207.108 146.528 208.926 144.942 210.568C142.209 213.358 139.154 215.813 135.842 217.881C133.454 219.478 130.907 220.823 128.242 
  221.894C123.603 223.577 118.766 224.655 113.852 225.101C113.07 225.137 112.29 225.221 111.519 225.354H55.2778C54.5812 225.214 53.8733 225.138 53.1628 225.127C47.9052 
  224.666 42.7087 223.667 37.6548 222.146C34.5748 221.218 31.6046 219.958 28.7968 218.388C26.249 216.904 23.931 215.056 21.9158 212.904C18.6362 209.543 15.9233 205.673 
  13.8828 201.444C13.5229 200.8 13.275 200.099 13.1498 199.372C12.9052 197.154 12.6578 194.936 12.4078 192.717C11.9958 189.012 11.5845 185.307 11.1738 181.602C10.7498 
  177.802 10.3248 173.999 9.8988 170.194C9.53414 166.927 9.17084 163.661 8.80884 160.394C8.43284 157.006 8.05648 153.618 7.67981 150.229C7.31714 146.962 6.95279 143.696 
  6.58679 140.429C6.21613 137.114 5.84546 133.799 5.47479 130.484C5.11413 127.242 4.75448 124 4.39581 120.758C3.97448 116.98 3.55249 113.202 3.12982 109.423C2.71782 105.718 
  2.30716 102.012 1.89783 98.307C1.51883 94.894 1.15082 91.48 0.749817 88.07C0.753211 87.2845 0.621978 86.5042 0.361816 85.763V84.143C0.521436 83.7571 0.607244 83.3446 0.614807
   82.927C0.734807 81.5337 0.857145 80.1413 0.981812 78.75C1.20481 76.283 1.4128 73.815 1.6618 71.35C1.7189 69.9287 1.91284 68.5161 2.24084 67.132C3.24084 63.795 4.2558 60.461 
   5.16479 57.098C5.9534 54.1088 7.08988 51.2224 8.55084 48.498C10.4198 45.048 12.1588 41.526 13.9508 38.034C14.0995 37.7517 14.2772 37.4856 14.4808 37.24C17.0548 34.052 19.6118 
   30.851 22.2068 27.681C23.256 26.261 24.4431 24.9482 25.7508 23.762C29.1018 21.003 32.3838 18.162 35.6948 15.35C36.1763 14.9311 36.6993 14.5625 37.2558 14.25C41.4725 11.9413 
   45.6885 9.62967 49.9038 7.315C50.4751 6.98118 51.078 6.70481 51.7038 6.48999C56.2925 5.05732 60.8818 3.624 65.4718 2.19C66.6958 1.808 81.9958 0.640991 81.9958 0.640991C81.9958 
   0.640991 97.5788 1.80299 98.9018 2.21899C102.811 3.44699 106.723 4.66201 110.63 5.89401C112.003 6.26777 113.333 6.78181 114.601 7.42801C118.585 9.65301 122.601 11.828 126.593 
   14.028C127.184 14.3253 127.745 14.6814 128.265 15.091C131.643 17.972 135.038 20.832 138.42 23.709C139.044 24.1844 139.623 24.7177 140.147 25.302C143.329 29.26 146.518 33.212 
   149.716 37.158C149.966 37.4604 150.184 37.7882 150.366 38.136C151.36 40.08 152.356 42.023 153.354 43.965C154.386 45.972 155.44 47.965 156.449 49.987C157.051 51.0277 157.556 
   52.1212 157.959 53.254C159.32 57.942 160.7 62.625 162.067 67.312C162.191 67.6844 162.28 68.0678 162.332 68.457C162.692 72.314 163.02 76.175 163.397 80.031C163.43 80.9638 
   163.51 81.8944 163.638 82.819ZM54.3488 181.493C54.3488 181.174 54.3388 180.855 54.3488 180.536C54.3745 180.291 54.3107 180.044 54.1693 179.842C54.0278 179.64 53.8181 
   179.496 53.5788 179.436C52.6788 179.108 51.8048 178.724 50.9128 178.379C50.4564 178.199 50.0288 177.952 49.6438 177.648C47.6891 176.128 45.7315 174.611 43.7708 173.096C41.8315 
   171.594 39.8918 170.094 37.9518 168.596C37.3518 168.13 37.3058 168.145 37.0518 168.832C36.7978 169.519 36.5668 170.222 36.3048 170.909C35.7368 172.398 35.3168 173.942 34.6268 
   175.384C32.9035 178.99 31.1755 182.594 29.4428 186.195C29.2163 186.658 29.1012 187.167 29.1068 187.682C29.1218 190.182 29.1308 192.682 29.1068 195.188C29.0954 195.975 29.3031 
   196.75 29.7068 197.426C30.4482 198.643 31.3571 199.75 32.4068 200.714C34.0292 202.303 36.0663 203.403 38.2848 203.888C38.9577 204.037 39.6548 204.04 40.3288 203.896C40.9758 
   203.76 41.6288 203.628 42.2778 203.521C45.5938 202.976 48.9318 202.621 52.2778 202.328C55.5778 202.039 58.8778 201.848 62.1778 201.674C64.3308 201.561 66.4878 201.517 68.6448 
   201.469C69.7448 201.445 70.8448 201.324 71.9498 201.343C72.4988 201.352 72.6178 201.189 72.6498 200.624C72.6598 200.428 72.6598 200.231 72.6498 200.035C72.5958 198.689 72.5395 
   197.342 72.4808 195.995C72.4878 195.333 72.4587 194.671 72.3938 194.012C72.2678 193.188 72.2588 193.13 71.4388 193.159C68.6438 193.149 65.8518 192.977 63.0768 192.642C62.586 
   192.632 62.0991 192.552 61.6308 192.405C60.5308 191.989 59.4238 191.586 58.3178 191.182C58.0146 191.08 57.7454 190.896 57.5398 190.651C56.2505 189.076 54.9595 187.502 53.6668 
   185.93C53.4756 185.735 53.3592 185.479 53.3378 185.207C53.3318 184.692 53.3328 184.177 53.3328 183.662C53.3236 183.601 53.3269 183.538 53.3425 183.478C53.3581 183.418 53.3857 
   183.362 53.4236 183.313C53.4616 183.264 53.5091 183.223 53.5633 183.193C53.6175 183.163 53.6772 183.144 53.7388 183.138C54.2528 183.076 54.3698 182.754 54.3468 182.307C54.3378 
   182.033 54.3488 181.762 54.3488 181.493ZM92.0998 199.393H92.0858C92.0858 199.809 92.0708 200.227 92.0858 200.643C92.1118 201.195 92.2408 201.337 92.8048 201.343C93.7608 201.356
    94.7158 201.435 95.6688 201.443C98.8078 201.477 101.942 201.627 105.075 201.781C108.375 201.944 111.682 202.161 114.975 202.481C118.348 202.754 121.703 203.227 125.02 
	203.899C125.5 204.005 126 203.989 126.472 203.85C127.465 203.57 128.416 203.157 129.298 202.621C131.728 201.104 133.662 198.91 134.862 196.308C135.066 195.916 135.138 195.47 
	135.069 195.034C134.575 191.634 134.105 188.234 133.62 184.834C133.586 184.123 133.437 183.422 133.179 182.759C132.507 181.395 131.842 180.028 131.2 178.649C130.336 176.97 
	129.559 175.247 128.874 173.488C128.369 172.028 127.841 170.576 127.322 169.12C126.959 168.101 126.951 168.093 126.09 168.758C122.284 171.695 118.481 174.637 114.682 
	177.582C114.239 177.932 113.747 178.213 113.222 178.418C112.329 178.762 111.444 179.125 110.556 179.482C110.256 179.604 109.956 179.741 109.956 180.135C109.951 180.945 
	109.951 181.755 109.956 182.564C109.956 182.808 110.066 183.027 110.315 183.053C110.976 183.122 110.988 183.574 110.969 184.067C110.955 184.287 110.955 184.509 110.969 
	184.729C110.992 184.997 110.958 185.267 110.869 185.52C110.781 185.774 110.639 186.007 110.455 186.202C109.267 187.602 108.106 189.034 106.955 190.465C106.7 190.796 
	106.358 191.048 105.966 191.193C104.905 191.577 103.849 191.974 102.79 192.363C102.538 192.461 102.275 192.526 102.007 192.554C99.1107 192.909 96.1974 193.105 93.2798 
	193.144C92.4898 193.127 92.4158 193.183 92.3688 193.944C92.2558 195.765 92.1858 197.577 92.0998 199.389V199.393Z`
export default shipPath
