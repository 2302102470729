import { findEntry } from '../../../utility/functions'
import { getStudentId } from '../../stores/general'
import { getTraining } from './training'
import type { HealthTask, SurveyPrompt } from '@mission.io/mission-toolkit'
import type { ReduxStore } from '../../rootReducer'

export function getActiveHealthTask(state: ReduxStore): ?HealthTask {
	const studentId = getStudentId(state.general)
	if (!studentId) return null
	const trainingHealthTask = getTraining(state)?.healthTask
	if (trainingHealthTask) {
		return trainingHealthTask
	}
	const entry = findEntry(state.jrPlusState.healthTasks.tasks, task => task.studentId === studentId)
	if (!entry) {
		return null
	}

	const currentTask = entry[1] // Note: entry is a tuple of (taskId, Task)
	if (!currentTask.type && !getIsHealthTaskStationActive(state)) {
		// the task has not been started by the student yet and health tasks are not active
		return
	}
	// student has started the task, allow finishing the task even if the health tasks have been disabled since the students may already be interacting
	return currentTask
}

export function getIsHealthTaskStationActive(state: ReduxStore): ?boolean {
	return state.jrPlusState.healthTasks.isActive
}

export function getAssignedSurveyPrompt(state: ReduxStore): ?SurveyPrompt {
	const studentId = getStudentId(state.general)
	if (!studentId) return null
	const entry = findEntry(state.jrPlusState.healthTasks.surveys, survey => {
		return survey.assignedStudentId === studentId
	})
	return entry ? entry[1] : null
}

export function hasHealthTask(store: ReduxStore): boolean {
	return Boolean(getActiveHealthTask(store))
}
export function canCompleteHealthTask(store: ReduxStore): boolean {
	const task = getActiveHealthTask(store)
	if (!task) return false
	return task.canComplete
}
