import type {
	JrPlusTractorBeamData,
	StudentTractorBeamTarget,
	TractorBeamTarget,
	MapObjectIds,
} from '@mission.io/mission-toolkit'
import { TRACTOR_BEAM_STATION } from '@mission.io/mission-toolkit/constants'

import type { ReduxStore } from '../../rootReducer'
import { forEachObject } from '../../../utility/functions'
import { getStudentId } from '../../stores/general'

export function isTractorBeamActive(tractorBeam: JrPlusTractorBeamData): boolean %checks {
	return !!tractorBeam && tractorBeam.status === TRACTOR_BEAM_STATION.STATUS.ACTIVE
}

export function getTractorBeamMapObjects(store: ReduxStore): MapObjectIds[] {
	const tractorBeam = store.jrPlusState.stationData.tractorBeam
	if (!isTractorBeamActive(tractorBeam)) {
		return []
	}
	const mapObjects = []
	forEachObject(tractorBeam.targetMap, target => {
		if (target.mapObject) {
			mapObjects.push(target.mapObject)
		}
	})
	return mapObjects
}

/**
 * Gets an indicator value for the tractor beam machine the student can use while the station is active
 * @param {ReduxStore} store
 * @returns {number}
 */
export function getMachineIndex(store: ReduxStore): number {
	const tractorBeam = store.jrPlusState.stationData.tractorBeam
	const studentId = getStudentId(store.general)
	if (!studentId || !isTractorBeamActive(tractorBeam)) {
		return 0
	}
	return tractorBeam.students[studentId]?.machine.level || 0
}

/**
 * Gets the targets that should appear on the tractor beam screen.
 * @param {ReduxStore} store
 * @returns  {Array<StudentTractorBeamTarget>} Student tractor beam target ids
 */
export function getStudentTargets(store: ReduxStore): ?(StudentTractorBeamTarget[]) {
	const tractorBeam = store.jrPlusState.stationData.tractorBeam
	const studentId = getStudentId(store.general)
	if (!studentId || !isTractorBeamActive(tractorBeam)) {
		return null
	}
	return tractorBeam.students[studentId]?.targets
}

/**
 * Gets the data map to look up details regarding each target id.
 * @param {ReduxStore} state
 * @returns {{[string]: DefenseTarget}} A map of target ids to target details
 */
export function getTargetMap(store: ReduxStore): { [string]: TractorBeamTarget } {
	const tractorBeam = store.jrPlusState.stationData.tractorBeam
	return isTractorBeamActive(tractorBeam) ? tractorBeam.targetMap : {}
}
