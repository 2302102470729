import React, { lazy, Suspense } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components/macro'
import HealthTask from '../../HealthTask'
import { Footer, SideNavigation, Toolkit, Top } from './components'
import { toolkitTabTypes } from './components/Toolkit/Toolkit'
import Overlay from '../../Overlays'
import { isRemote as isRemoteSelector } from '../../../store/stores/staticData'
import { showFullScreen } from '../../../store/stores/general'
import StationUpgradeOverlay from '../../StationUpgradeOverlay'

const RemoteStatusBar = lazy(() => import('../remote'))
const JoinVoiceChatModal = lazy(() => import('../../VideoChat/JoinVoiceChatModal'))

const STATION_SELECTOR_WIDTH_PX = '104'

export const Main: StyledType<> = styled.div`
	width: 100%;
	height: 100%;
	display: grid;
	position: relative;

	${({ $isRemote, $fullscreen }) => {
		let footerHeightVh = $fullscreen ? 0 : 8
		let sidePanelWidthPx = $fullscreen ? 0 : STATION_SELECTOR_WIDTH_PX
		return $isRemote
			? `
					grid-template-rows: [station-start station-select-start] 82% [station-end station-select-end status-start] 18% [status-end];
					grid-template-columns: [station-start status-start] calc(100% - ${sidePanelWidthPx}px) [station-end station-select-start] ${sidePanelWidthPx}px [station-select-end status-end];
			  `
			: `
					grid-template-rows: [station-start station-select-start] ${100 -
						footerHeightVh}% [station-end status-start] ${footerHeightVh}% [status-end station-select-end];
					grid-template-columns: [station-start status-start] 100%;
			  `
	}}
`

const View = styled.div`
	position: relative;
	height: 100%;
	flex: 1;
	grid-row: station-start / station-end;
	grid-column: station-start / station-end;
`

export default function JrPlusFrameWrapper({ children }: { children: React$Node }): React$Node {
	const isRemote = useSelector(isRemoteSelector)
	const fullscreen = useSelector(showFullScreen)

	const toolkitTabs = isRemote
		? [toolkitTabTypes.REMOTE_SETTINGS, toolkitTabTypes.TEXT_TO_SPEECH_SETTINGS]
		: [toolkitTabTypes.TEXT_TO_SPEECH_SETTINGS]

	return (
		<>
			<Main $isRemote={isRemote} $fullscreen={fullscreen}>
				<StationUpgradeOverlay />
				<View>
					<Top css="position: absolute; top: 0; width: 100%; left: 0; pointer-events: none;" />
					<HealthTask />
					{!fullscreen && (
						<>
							<Toolkit tabs={toolkitTabs} />
							<Overlay style={{ width: 'calc(100% + 40px)' }} />
						</>
					)}
					{children}
				</View>
				{!isRemote ? (
					!fullscreen ? (
						<Footer />
					) : (
						<div /> // Return empty div to make our grid happy
					)
				) : (
					<Suspense fallback={<div>Loading ...</div>}>
						<RemoteStatusBar isJuniorPlus={true} />
					</Suspense>
				)}
				{!fullscreen && isRemote ? <SideNavigation isRemote /> : <div /> // Return empty div to make our grid happy
				}
			</Main>
			{isRemote && (
				<Suspense fallback={<div>Loading ...</div>}>
					<JoinVoiceChatModal />
				</Suspense>
			)}
		</>
	)
}
