import React from 'react'
import { useSelector } from 'react-redux'
import { getAllQuestions } from '../../store/stores/staticData'
import QuestionModal from './QuestionModal'

/**
 * A wrapper to ensure that the browser has received questions from the server before rendering the question modal.
 * This wrapper helps ensure that the question and question review modals appears properly on browser reload.
 * @returns {React$Node}
 */
export default function WithQuestions(): React$Node {
	const staticQuestionsExist = useSelector(getAllQuestions).length > 0
	if (!staticQuestionsExist) {
		return null
	}
	return <QuestionModal />
}
