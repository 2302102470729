import React, { type AbstractComponent } from 'react'
import {
	CommunicationsIcon,
	TractorBeamIcon,
	ThrustersIcon,
	DefenseIcon,
	SensorsIcon,
	RepairsIcon,
	PowerIcon,
	TransporterIcon,
} from '../images/jrPanel'

import {
	Repairs,
	Transporter,
	Thrusters,
	Communication,
	Defense,
	Scanner,
	TractorBeam,
	Power,
} from '../stations'

import type { JrStationEnum } from '../types'

type StationMapProps = {
	classNameVehicle?: string,
}

type DisplayStationData = {|
	displayName: string,
	component: React$Node,
	icon: AbstractComponent<*, *>,
|}

export const stationMap = (
	props: StationMapProps
): { [stationId: string]: DisplayStationData } => ({
	repairs: {
		displayName: 'Repairs',
		component: <Repairs />,
		icon: RepairsIcon,
	},
	transporter: {
		displayName: 'Transporter',
		component: <Transporter />,
		icon: TransporterIcon,
	},
	thrusters: {
		displayName: 'Thrusters',
		component: <Thrusters />,
		icon: ThrustersIcon,
	},
	communication: {
		displayName: 'Communication',
		component: <Communication {...props} />,
		icon: CommunicationsIcon,
	},
	defense: {
		displayName: 'Defense',
		component: <Defense {...props} />,
		icon: DefenseIcon,
	},
	scanner: {
		displayName: 'Scanner',
		component: <Scanner />,
		icon: SensorsIcon,
	},
	tractorBeam: {
		displayName: 'Tractor Beam',
		component: <TractorBeam {...props} />,
		icon: TractorBeamIcon,
	},
	power: {
		displayName: 'Power',
		component: <Power />,
		icon: PowerIcon,
	},
})

export default stationMap

export type JuniorStationMap = {|
	communication: DisplayStationData,
	defense: DisplayStationData,
	power: DisplayStationData,
	repairs: DisplayStationData,
	scanner: DisplayStationData,
	thrusters: DisplayStationData,
	tractorBeam: DisplayStationData,
	transporter: DisplayStationData,
|}

export const orderedStations: JrStationEnum[] = [
	'repairs',
	'scanner',
	'transporter',
	'thrusters',
	'power',
	'communication',
	'defense',
	'tractorBeam',
]
