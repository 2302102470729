// @flow
import createReducer, { type Reducer } from '../createReducer'

import type { Direction } from '@mission.io/mission-toolkit'

const types = {
	CHANGE_DIRECTION: 'CHANGE_DIRECTION',
}

type ChangeDirectionAction = {
	type: 'CHANGE_DIRECTION',
	payload: ?Direction,
}

export function changeDirection(direction: ?Direction): ChangeDirectionAction {
	return {
		type: types.CHANGE_DIRECTION,
		payload: direction,
	}
}

// Reducer

export type ThrusterStore = {
	myDirection: ?Direction,
}

/**
 * Gets the initial state for the `thrusters` redux store
 */
export function getInitialState(): ThrusterStore {
	return {
		myDirection: null,
	}
}

export default (createReducer<ThrusterStore>(getInitialState(), {
	[types.CHANGE_DIRECTION]: (state, { payload: direction }) => {
		return {
			...state,
			myDirection: direction,
		}
	},
}): Reducer<ThrusterStore>)
