import React from 'react'
import 'styled-components/macro'
import { LandingPageWrapper } from './LandingPageWrapper'
import 'styled-components/macro'
import classnames from 'classnames'

/**
 * A simple page that tells the user that the website URL has changed and redirects them to the new URL.
 */
export function WebsiteUrlChange({ newUrl }: { newUrl: string }): React$Node {
	return (
		<LandingPageWrapper>
			<div className="rounded-md w-full max-w-4xl max-h-full mx-auto overflow-y-auto">
				<h1 className="bg-brand-deep-blue text-brand-cloud-blue flex items-center justify-between px-4 py-3 text-2xl font-brand-headline font-normal tracking-normal [text-transform:none]">
					Infini-D Learning is now...
				</h1>
				<div className="p-4 pt-0 flex flex-col gap-4 bg-brand-cloud-blue text-brand-space-blue font-brand-body">
					<div className="flex justify-center">
						<img
							src="https://resources-cdn.mission.io/public/logos/mission.io/mission.io_reveal.gif"
							alt="Mission.io Reveal"
							className="h-40 -m-8"
						/>
					</div>
					<p>
						Welcome to our glow-up era. Infini‑D Learning is now Mission.io! If you would like to
						know more about this brand new update, you can read about it{' '}
						<a
							href="https://mission.io/its-time-for-evolution"
							className="text-brand-horizon-blue hover:text-brand-ultra-soft-violet">
							here
						</a>
						.
					</p>
					<p>
						From here on out, you can find us at Mission.io. So update your bookmarks, and get ready
						to join the Mission!
					</p>
					<BrandButtonLink href={newUrl} className="self-center">
						Go To Mission.io
					</BrandButtonLink>
				</div>
			</div>
		</LandingPageWrapper>
	)
}

/**
 * A button consistent with the Mission.io brand style guide.
 * We do NOT import the button from mission.io/styles because the tailwind class names for the colors and fonts in this project (controls)
 * are different from the class names in @mission.io/styles.
 *
 * NOTE: Do not use this component elsewhere, since Controls does not follow the brand style guide.
 * */
function BrandButtonLink({ children, className, ...props }): React$Node {
	return (
		<a
			{...{
				...props,
				className: classnames(
					'rounded-lg font-brand-button uppercase whitespace-nowrap font-medium py-1 px-2 text-sm border-2 border-transparent hover:brightness-90 active:brightness-75 bg-gradient-to-r from-brand-horizon-blue to-brand-ultra-soft-violet text-brand-white bg-origin-border text-brand-cloud-blue',
					className
				),
			}}>
			{children}
		</a>
	)
}
