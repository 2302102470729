import React from 'react'

import { BackgroundCircle, FilledPolygon } from '../helpers'
import type { Props } from '../helpers'

export default function TransporterIcon({
	iconOnly,
	transitionPattern = {},
	isOpen = true,
	...props
}: Props): React$Node {
	return (
		<svg
			{...props}
			id="Transporter_1"
			data-name="Tranposrter 1"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 70 70">
			{!iconOnly && <BackgroundCircle cx="35" cy="35" r="35" />}
			<g
				style={{
					...transitionPattern,
					transform: `${isOpen ? 'scale(1)' : 'scale(0)'}`,
					transformOrigin: '50% 50%',
				}}>
				<FilledPolygon
					points="13 23 38 20 34 14 57 23 35 33 38 26 13 23"
					iconOnly={iconOnly}
					fill="#efff2b"
				/>
				<FilledPolygon
					points="58 46 33 49 37 55 14 46 36 36 33 43 58 46"
					iconOnly={iconOnly}
					fill="#efff2b"
				/>
			</g>
		</svg>
	)
}
