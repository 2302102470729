import React, { type AbstractComponent } from 'react'

import {
	Sensor,
	Defense,
	Communication,
	Transporter,
	Power,
	Decks,
	TractorBeam,
	Thrusters,
} from '../juniorPlusStations'

import {
	DefenseIcon,
	TransporterIcon,
	TractorBeamIcon,
	CreativeCanvasIcon,
	DecksIcon,
	ScanningIcon,
	PowerIcon,
	ThrustersIcon,
	CommunicationsIcon,
} from '../images/jrPlusPanel'
import { CreativeCanvas } from '../components/CreativeCanvas'

export type DisplayStationData = {|
	displayName: string,
	component: React$Node,
	icon: AbstractComponent<*, *>,
|}

const stations: {
	[stationId: string]: DisplayStationData,
} = {
	transporterPlus: {
		displayName: 'Transporter',
		component: <Transporter />,
		icon: TransporterIcon,
	},
	thrustersPlus: {
		displayName: 'Thrusters',
		component: <Thrusters />,
		icon: ThrustersIcon,
	},
	powerPlus: {
		displayName: 'Power',
		component: <Power />,
		icon: PowerIcon,
	},
	communicationPlus: {
		displayName: 'Communication',
		component: <Communication />,
		icon: CommunicationsIcon,
	},
	defensePlus: {
		displayName: 'Defense',
		component: <Defense />,
		icon: DefenseIcon,
	},
	decks: {
		displayName: 'Decks',
		component: <Decks />,
		icon: DecksIcon,
	},
	sensors: {
		displayName: 'Sensor',
		component: <Sensor />,
		icon: ScanningIcon,
	},
	tractorBeamPlus: {
		displayName: 'Tractor Beam',
		component: <TractorBeam />,
		icon: TractorBeamIcon,
	},
	creativeCanvas: {
		displayName: 'Canvas',
		component: <CreativeCanvas />,
		icon: CreativeCanvasIcon,
	},
}

export default stations

export const coreStationList = ['decks', 'sensors']
