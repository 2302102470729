import React, { type AbstractComponent, useState } from 'react'
import styled, { css, keyframes } from 'styled-components/macro'
import { useDispatch } from 'react-redux'
import VoteArrow from '../images/vote'
import classnames from 'classnames'
import type { StudentSpecificContextualDataEntry } from '@mission.io/mission-toolkit'
import {
	ACCENT_GREEN,
	BOOST_PHRASE_ACTIVE,
	DARK_RED,
	DEFAULT_MEDIA_MODAL_Z_INDEX,
} from '../constants/styles'
import { sendMessage } from '../store/stores/webSocket'
import { Marquee } from './Marquee'
import TextToSpeech from './TextToSpeech'
import { Button } from './basics/Buttons'
import Modal from './basics/ReactModal'
import Markdown from './Markdown'
import VideoImage from '../images/videoImage.svg'
import { SvgIcon } from '../juniorPlusStations/Sensor/Map/Map'
import { VideoController } from './VideoController'

export const GRID_AREA_KEY_WORDS = {
	vote: 'vote',
	importance: 'importance',
}

export type MediaModalOverrides = {
	overlayStyle: {| zIndex: number |},
	frameContent: boolean,
}

type Props = {
	allowVote?: boolean,
	contextualData: StudentSpecificContextualDataEntry,
	onHover?: () => void,
	onBlur?: () => void,
	smallScreenSize?: string,
	mediaModalOverrides?: ?MediaModalOverrides,
}

// If text is longer than this, it will start collapsed and can be expanded to see the full text
const COLLAPSE_TEXT_LENGTH = 150

/**
 * A card that displays a single piece of contextual data. Includes the data, the current class and student vote, and a way
 * to view any media.
 */
export const ContextualDataCard = (React.forwardRef(function ContextualDataCard(
	{
		contextualData,
		onHover,
		onBlur,
		smallScreenSize,
		mediaModalOverrides,
		allowVote = true,
		...props
	}: Props,
	ref
) {
	const dispatch = useDispatch()
	const [isExpanded, setIsExpanded] = useState(false)

	const [showMedia, setShowMedia] = useState(false)
	const media /* : StudentSpecificContextualDataEntry['media'][0] | void*/ = contextualData.media[0]

	const isCollapsible = contextualData.text.length > COLLAPSE_TEXT_LENGTH

	return (
		<>
			{showMedia && media && (
				<MediaModal
					{...{
						modalOverrides: mediaModalOverrides,
						media,
						onRequestClose() {
							setShowMedia(false)
						},
					}}
				/>
			)}
			<CardContainer
				{...props}
				ref={ref}
				onMouseEnter={() => onHover?.()}
				onTouchStart={() => onHover?.()}
				onMouseLeave={() => onBlur?.()}
				$shouldPulse={allowVote && contextualData.studentVote === null}
				$hasSubtitle={!!contextualData.subtitle}
				$smallScreenWidth={smallScreenSize ?? '1200px'}>
				<IconGridItem>
					<IconWrapper>
						{contextualData.icon && (
							<SvgIcon
								{...{
									url: contextualData.icon,
									width: 25,
									height: 25,
								}}
							/>
						)}
					</IconWrapper>
				</IconGridItem>
				<TitleWrapper title={contextualData.title}>
					<Title>
						<TextToSpeech>{contextualData.title}</TextToSpeech>
					</Title>
				</TitleWrapper>
				<Subtitle title={contextualData.subtitle}>{contextualData.subtitle}</Subtitle>

				<Border />
				<Description>
					{isExpanded && isCollapsible && (
						<Button $small $variant="link" onClick={() => setIsExpanded(false)}>
							Show Less
						</Button>
					)}

					<FadingContainer collapsed={isCollapsible && !isExpanded}>
						<Markdown
							textToSpeechPosition="start"
							disabledComponents={['a', 'img']}
							className="prose-on-game-light-purple">
							{getTextFromContextualData(contextualData)}
						</Markdown>
					</FadingContainer>

					{isCollapsible && (
						<Button
							$small
							$variant="link"
							onClick={() => {
								setIsExpanded(!isExpanded)
							}}>
							Show {isExpanded ? 'Less' : 'More'}
						</Button>
					)}
				</Description>
				<BorderBottom className="border-bottom" />

				{allowVote && (
					<Voting
						vote={contextualData.studentVote ?? 0}
						onSelect={(vote: number, location: { x: number, y: number }) => {
							dispatch(
								sendMessage(
									'CONTEXTUAL_DATA_VOTE',
									{
										dataId: contextualData.id,
										vote,
										dataSource: contextualData.dataSource,
									},
									{ location }
								)
							)
						}}
					/>
				)}
				<ImportanceScore title="Crew vote">
					Importance Score: {contextualData.classVote}
				</ImportanceScore>
				<AdditionalOptions>
					{media && (
						<Button
							$small
							onClick={e => {
								e.stopPropagation()
								setShowMedia(true)
							}}>
							<div css="align-items: center; display: inline-flex;">
								<ImageClipArt src={VideoImage} />
								{media.type === 'AUDIO' ? 'Play' : 'Show'}{' '}
								{media.type[0] + media.type.slice(1).toLowerCase()}
							</div>
						</Button>
					)}
				</AdditionalOptions>
			</CardContainer>
		</>
	)
}): AbstractComponent<Props, HTMLDivElement>)

const DEFAULT_MEDIA_MODAL_OVERRIDES: MediaModalOverrides = {
	overlayStyle: { zIndex: DEFAULT_MEDIA_MODAL_Z_INDEX },
	frameContent: true,
}

/**
 * Retrieves the correct text to display for the given contextual data.
 * @param {StudentSpecificContextualDataEntry} contextualData
 * @returns {string}
 */
function getTextFromContextualData(contextualData: StudentSpecificContextualDataEntry) {
	if (contextualData.text === '' || !contextualData.text) {
		if (contextualData.media.length > 0) {
			const firstMedia = contextualData.media[0]
			if (firstMedia.type === 'VIDEO') {
				return 'See Video'
			} else if (firstMedia.type === 'AUDIO') {
				return 'Listen to Audio'
			} else {
				return 'See Image'
			}
		}
		return 'No Data Available'
	}
	return contextualData.text
}

/**
 * A modal that displays the given media.
 */
function MediaModal({
	media,
	onRequestClose,
	modalOverrides: _modalOverrides,
}: {
	media: $ElementType<$PropertyType<StudentSpecificContextualDataEntry, 'media'>, 0>,
	onRequestClose: () => mixed,
	modalOverrides?: ?MediaModalOverrides,
}) {
	const modalOverrides = _modalOverrides ?? DEFAULT_MEDIA_MODAL_OVERRIDES
	return (
		<Modal
			frameContent={modalOverrides.frameContent}
			onRequestClose={() => onRequestClose()}
			style={{
				...ModalStyles,
				overlay: { ...ModalStyles.overlay, ...modalOverrides.overlayStyle },
			}}
			isOpen={true}>
			<div
				css="width: 100%; height: 100%; display: flex; align-items: center; justify-content: center;"
				onClick={() => onRequestClose()}>
				{media.type === 'VIDEO' ? (
					<VideoController css="width: 100%; height: 100%;" src={media.url} />
				) : media.type === 'AUDIO' ? (
					<audio autoPlay src={media.url} controls />
				) : (
					<Img src={media.url} />
				)}
			</div>
		</Modal>
	)
}

// Show container with fading gradient at the bottom of the card if the collapsed prop is true
function FadingContainer({
	children,
	collapsed,
}: {
	children: React$Node,
	collapsed: boolean,
}): React$Node {
	const maxHeightClass = collapsed ? `max-h-[110px]` : 'max-h-fit'
	return (
		<div className={classnames('overflow-hidden relative', maxHeightClass)}>
			<div className="max-h-full overflow-hidden">{children}</div>
			{collapsed && (
				<div
					className="absolute left-0 bottom-0 w-full h-full pointer-events-none"
					style={{
						backgroundImage: 'linear-gradient(to bottom, #5c699200, #5c6992ff)',
					}}
				/>
			)}
		</div>
	)
}

const pulse = keyframes`
	0% {
		box-shadow: none;
	}
	100% {
		box-shadow: ${BOOST_PHRASE_ACTIVE} 0px 0px 12px;
	}
`

const ImportanceScore = styled.div`
	grid-area: ${GRID_AREA_KEY_WORDS.importance};
	color: white;
	font-family: 'Orbitron', sans-serif;
	text-align: right;
	font-size: 1rem;
	max-height: 100%;
`

/**
 * A component that allows the user to vote on the importance of the contextual data.
 */
function Voting({
	vote,
	onSelect,
}: {
	vote: number,
	onSelect: (vote: number, location: { x: number, y: number }) => mixed,
}): React$Node {
	const positiveValues = [3, 2, 1]
	const negativeValues = [-1, -2, -3]

	const lightIsActive = (value: number) => {
		if (vote > 0 && value > 0) {
			return vote >= value
		} else if (vote < 0 && value < 0) {
			return vote <= value
		}
		return false
	}
	const upVote = e => {
		if (vote >= 3) {
			return
		}
		onSelect(vote + 1, { x: e.pageX, y: e.pageY })
		e.stopPropagation()
	}

	const downVote = e => {
		if (vote <= -3) {
			return
		}
		onSelect(vote - 1, { x: e.pageX, y: e.pageY })
		e.stopPropagation()
	}

	const setVoteToValue = value => e => {
		e.stopPropagation()
		onSelect(value, { x: e.pageX, y: e.pageY })
	}

	let voteText = `${vote > 0 ? '+' : ''}${vote}`
	return (
		<VoteIndicatorContainer>
			<Vote>
				<VoteArrow
					fill={ACCENT_GREEN}
					width={25}
					height={15}
					onClick={upVote}
					onTouchStart={upVote}
				/>
			</Vote>
			{positiveValues.map(value => {
				const setVote = setVoteToValue(value)

				return (
					<VoteIndicatorLight
						key={value}
						className="clickable"
						$value={value}
						$active={lightIsActive(value)}
						onClick={setVote}
						onTouchStart={setVote}
					/>
				)
			})}
			<StudentVote className="notranslate">{voteText}</StudentVote>
			{negativeValues.map(value => {
				const setVote = setVoteToValue(value)
				return (
					<VoteIndicatorLight
						key={value}
						className="clickable"
						$value={value}
						$active={lightIsActive(value)}
						onClick={setVote}
						onTouchStart={setVote}
					/>
				)
			})}
			<Vote $down>
				<VoteArrow
					inverted
					fill={DARK_RED}
					width={25}
					height={15}
					onTouchStart={downVote}
					onClick={downVote}
				/>
			</Vote>
		</VoteIndicatorContainer>
	)
}

const INDICATOR_LIGHT_HEIGHT = 4
const HOVER_TRANSLATE = 5

const Vote = styled.div`
	display: flex;
	align-items: center;
	margin: 0;
	svg {
		cursor: pointer;
		transition: transform 0.3s ease 0s;
		&:hover {
			transform: ${({ $down }) => `translateY(${$down ? '' : '-'}${HOVER_TRANSLATE}px)`};
	}
	h6 {
		display: inline;
	}
`

const VoteIndicatorContainer = styled.div`
	grid-area: ${GRID_AREA_KEY_WORDS.vote};
	display: flex;
	flex-direction: column;
	align-self: stretch;
	align-items: center;
	padding: 0 0 2px 0;
	justify-content: space-around;
	height: 105px;
`

const StudentVote = styled.div`
	text-align: left;
	color: white;
	font-family: 'Orbitron', sans-serif;
`

const VoteIndicatorLight = styled.div`
	width: 25px;
	height: ${INDICATOR_LIGHT_HEIGHT}px;
	max-height: ${INDICATOR_LIGHT_HEIGHT}px;
	background-color: ${({ $active, $value }) => $active && ($value > 0 ? ACCENT_GREEN : DARK_RED)};
	${({ $active }) => !$active && 'box-shadow: 1px 1px 3px black inset;'}
	flex: 1;
	border-radius: 3px;
	transition: background-color 1s;
`

const Img = styled.img`
	max-height: 100%;
	max-width: 100%;
	margin-bottom: -9px;
	border-radius: 8px;
`

const ModalStyles = {
	overlay: {
		backgroundColor: 'rgba(0, 0, 0, 0.6)',
		zIndex: DEFAULT_MEDIA_MODAL_Z_INDEX,
	},
	content: {
		width: '90%',
		height: '90%',
		border: 'none',
		backgroundColor: 'transparent',
		padding: '5px',
		inset: '50% auto auto 50%',
	},
}

const IconGridItem = styled.div`
	grid-area: icon;
`

const IconWrapper = styled.div`
	width: 25px;
	height: 25px;
	margin: auto;
`

const Title = styled(Marquee)`
	text-align: left;
	color: white;
	font-family: 'Orbitron', sans-serif;
	font-size: 1rem;
	text-overflow: ellipsis;
	&:hover {
		text-overflow: initial;
	}

	> * {
		pointer-events: none;
	}
`

const TitleWrapper = styled.div`
	grid-area: name;
	padding-bottom: 5px;
`

const Subtitle = styled.div`
	grid-area: subtitle;
	vertical-align: center;
	color: white;
	text-align: left;
`

const Border = styled.div`
	grid-area: border;
	border-bottom: 1px solid white;
`

const BorderBottom = styled.div`
	grid-area: border-bottom;
	border-bottom: 1px solid white;
	${'' /* This gets displayed when needed through a selector on the parent */}
	display: none;
`

const AdditionalOptions = styled.div`
	grid-area: show_more;
`

const CardContainer = styled.div`
	&:hover {
		box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.4);
	}
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
	cursor: pointer;
	transition: box-shadow 0.5s ease;
	background-color: #5c6992;
	width: 100%;
	min-width: 200px;
	&::-webkit-scrollbar {
		width: 10px;
	}
	&::-webkit-scrollbar-track {
		border-radius: 10px;
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 10px;
		background: #5c6992;
	}
	min-height: 165px;
	height: auto;
	display: grid;

	grid-template-columns: 10% 1fr 1fr 1fr 1fr;
	grid-template-rows: 18px ${({ $hasSubtitle }) => ($hasSubtitle ? '20px' : '')} 1px 1fr;
	grid-template-areas:
		'icon name name ${GRID_AREA_KEY_WORDS.importance} ${GRID_AREA_KEY_WORDS.importance}'
		${({ $hasSubtitle }) => ($hasSubtitle ? "'. subtitle subtitle subtitle subtitle'" : '')}
		'border border border border border'
		'${GRID_AREA_KEY_WORDS.vote} description description description show_more';
	grid-row-gap: 10px;
	
	@media only screen and (max-width: ${({ $smallScreenWidth }) => $smallScreenWidth}) {
		grid-template-columns: 10% 1fr 1fr 1fr;
		grid-template-rows: 18px ${({ $hasSubtitle }) => ($hasSubtitle ? '20px' : '')} 1px 1fr 1px 1em;
		grid-template-areas:
			'icon name name name'
			${({ $hasSubtitle }) => ($hasSubtitle ? "'. subtitle subtitle subtitle'" : '')}
			'border border border border'
			'${GRID_AREA_KEY_WORDS.vote} description description show_more'
			'border-bottom border-bottom border-bottom border-bottom'
			'${GRID_AREA_KEY_WORDS.importance} ${GRID_AREA_KEY_WORDS.importance} ${
	GRID_AREA_KEY_WORDS.importance
} ${GRID_AREA_KEY_WORDS.importance}';
		width: 100%; 
		grid-column-gap: var(--spacing);

		& .border-bottom {
			display: unset;
		}

		& .importance-score {
			text-align: center;
		}
	}
	&:not(:last-child) {
		margin-bottom: 16px;
	}
	&:last-child {
		margin-bottom: 75px;
	}

	border-radius: 4px;
	padding: 10px 10px 2px 10px;

	${({ $shouldPulse }) =>
		$shouldPulse &&
		css`
			animation: 1.5s ${pulse} ease-out alternate infinite;
		`}
`

const Description = styled.div`
	color: white;
	text-align: left;
	width: 100%;
	display: block;
	line-height: 1.5em;
	grid-area: description;
	text-overflow: ellipsis;
	height: auto;
`

const ImageClipArt = styled.img`
	width: 20px;
	margin-right: 4px;
`
