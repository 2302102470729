// @flow
import { useState, useEffect, useCallback, useRef } from 'react'
import { useLiteracyEventGrade } from './useLiteracyEvent'
import { usePrevious } from '../../../utility/hooks'
import { LITERACY_EVENT } from '@mission.io/mission-toolkit/constants'

export const DISPLAY_STATUS = {
	INACTIVE: 'INACTIVE',
	INTRO: 'INTRO',
	ACTIVE: 'ACTIVE',
	EXITING: 'EXITING',
	SUBMITTING: 'SUBMITTING',
	DISPLAY_GRADE: 'DISPLAY_GRADE',
	SHOW_EXPLANATION: 'SHOW_EXPLANATION',
	COMPLETE: 'COMPLETE',
}

const ORDER = [
	DISPLAY_STATUS.INACTIVE,
	DISPLAY_STATUS.INTRO,
	DISPLAY_STATUS.ACTIVE,
	DISPLAY_STATUS.EXITING, // If tasks are stacked, we return to the ACTIVE status after a speaking task completes its EXIT status.
	DISPLAY_STATUS.SUBMITTING, // This display status is NOT used for speaking tasks
	DISPLAY_STATUS.DISPLAY_GRADE, // This display status is NOT used for speaking tasks
	DISPLAY_STATUS.SHOW_EXPLANATION, // This display status is NOT used for speaking tasks, and only sometimes used by other tasks.
]

const NEXT_STEP_AFTER_EXIT = ORDER[ORDER.findIndex(status => status === DISPLAY_STATUS.EXITING) + 1]

type LiteracyEventDisplayStatus = $Keys<typeof DISPLAY_STATUS>

/**
 * A hook to manage the display status of a literacy event.
 * @param {string | void} id - The id of the literacy event
 * @returns {[LiteracyEventDisplayStatus, (newStatus: LiteracyEventDisplayStatus) => void]} - A tuple containing the current status and a function to update the status
 */
export function useLiteracyEventDisplayStatus(
	id: string | void
): [
	LiteracyEventDisplayStatus,
	(LiteracyEventDisplayStatus, opts?: { for: void | { id: string, type: string } }) => void
] {
	const [status, _setStatus] = useState<LiteracyEventDisplayStatus>(DISPLAY_STATUS.INACTIVE)
	const exitingFor = useRef()

	const shouldDisplayGrade = useRef()
	// Assuming if a student grade is undefined or null it has not been calculated yet
	const studentGrade = useLiteracyEventGrade()
	const prevGrade = usePrevious(studentGrade)

	useEffect(() => {
		if (studentGrade != null && shouldDisplayGrade.current) {
			_setStatus(DISPLAY_STATUS.DISPLAY_GRADE)
			shouldDisplayGrade.current = false
		}
	}, [studentGrade])

	useEffect(() => {
		if (!id) {
			_setStatus(DISPLAY_STATUS.INACTIVE)
			shouldDisplayGrade.current = false
			exitingFor.current = null
		}
		if (id) {
			_setStatus(DISPLAY_STATUS.INTRO)
		}
	}, [id])

	// Move on after student dismisses the current task
	useEffect(() => {
		if (id && prevGrade != null && studentGrade == null) {
			_setStatus(DISPLAY_STATUS.ACTIVE)
		}
	}, [studentGrade, id, prevGrade])

	const setStatus = useCallback(
		(
			newStatus: LiteracyEventDisplayStatus,
			options?: { for: void | { id: string, type: string } }
		) => {
			// Special logic for handling the ending of a speaking task: when a speaking task is finished with the EXIT display, set the status to active
			// so the next literacy event (if there is any) can animate in.
			if (
				newStatus === NEXT_STEP_AFTER_EXIT &&
				exitingFor.current &&
				exitingFor.current.type === LITERACY_EVENT.TASK.TYPE.SPEAKING
			) {
				_setStatus(DISPLAY_STATUS.ACTIVE)
				exitingFor.current = null
				return
			}

			if (ORDER.indexOf(newStatus) !== ORDER.indexOf(status) + 1) {
				throw new Error(`Cannot set status to ${newStatus} from ${status}`)
			}
			// Handle race condition where we are waiting for the grade to be calculated
			if (newStatus === DISPLAY_STATUS.DISPLAY_GRADE && studentGrade == null) {
				shouldDisplayGrade.current = true
				return
			}

			if (options?.for && newStatus === DISPLAY_STATUS.EXITING) {
				exitingFor.current = options.for
			}
			_setStatus(newStatus)
		},
		[status, studentGrade]
	)

	return [status, setStatus]
}
