import React, { forwardRef, type AbstractComponent } from 'react'
import styled from 'styled-components/macro'
import type { BlasterProps as Props } from './upgradeLookup'
import { DefaultWrapper } from '../constants'

function Blaster(props: Props, ref): React$Node {
	const { transformationWrapper: Wrapper = DefaultWrapper, children, ...otherProps } = props
	return (
		<Svg {...otherProps} viewBox="0 0 398.24 708.37">
			<defs>
				<linearGradient
					id="linear-gradient"
					x1="294.36"
					y1="256.98"
					x2="316.6"
					y2="257.68"
					gradientUnits="userSpaceOnUse">
					<stop offset="0" stopColor="#5e5e78" />
					<stop offset="0.76" stopColor="#75758c" />
					<stop offset="1" stopColor="#7d7d93" />
				</linearGradient>
				<linearGradient
					id="linear-gradient-2"
					x1="324.14"
					y1="331.32"
					x2="378.4"
					y2="333.04"
					xlinkHref="#linear-gradient"
				/>
				<linearGradient
					id="linear-gradient-3"
					x1="188.59"
					y1="290.47"
					x2="188.59"
					y2="324.09"
					gradientUnits="userSpaceOnUse">
					<stop offset="0" stopColor="#1a1a26" />
					<stop offset="0.64" stopColor="#0a0a0f" />
					<stop offset="1" />
				</linearGradient>
				<radialGradient
					id="radial-gradient"
					cx="199.12"
					cy="509.25"
					r="199.12"
					gradientUnits="userSpaceOnUse">
					<stop offset="0.49" stopColor="#3b384c" />
					<stop offset="1" stopColor="#1a1a26" />
				</radialGradient>
				<radialGradient
					id="radial-gradient-2"
					cx="383.81"
					cy="197.12"
					r="30.77"
					xlinkHref="#radial-gradient"
				/>
				<linearGradient
					id="linear-gradient-4"
					x1="306.02"
					y1="212.11"
					x2="299.39"
					y2="129.74"
					xlinkHref="#linear-gradient"
				/>
				<linearGradient
					id="linear-gradient-5"
					x1="304.44"
					y1="162.77"
					x2="244.78"
					y2="-578.72"
					xlinkHref="#linear-gradient"
				/>
				<linearGradient
					id="linear-gradient-6"
					x1="306.85"
					y1="217.01"
					x2="306.17"
					y2="208.56"
					xlinkHref="#linear-gradient"
				/>
				<linearGradient
					id="linear-gradient-7"
					x1="152.14"
					y1="89.43"
					x2="149.32"
					y2="-13.28"
					gradientUnits="userSpaceOnUse">
					<stop offset="0" stopColor="#5e5e78" />
					<stop offset="0.35" stopColor="#60607a" />
					<stop offset="0.54" stopColor="#686882" />
					<stop offset="0.7" stopColor="#76768e" />
					<stop offset="0.83" stopColor="#8a8aa1" />
					<stop offset="0.95" stopColor="#a3a3b8" />
					<stop offset="1" stopColor="#afafc3" />
				</linearGradient>
				<linearGradient
					id="linear-gradient-8"
					x1="164.7"
					y1="49.02"
					x2="174.23"
					y2="96.66"
					xlinkHref="#linear-gradient-7"
				/>
				<linearGradient
					id="linear-gradient-9"
					x1="334.73"
					y1="89.43"
					x2="331.9"
					y2="-13.28"
					gradientTransform="matrix(-1, 0, 0, 1, 580.82, 0)"
					xlinkHref="#linear-gradient-7"
				/>
				<linearGradient
					id="linear-gradient-10"
					x1="347.29"
					y1="49.02"
					x2="356.82"
					y2="96.66"
					gradientTransform="matrix(-1, 0, 0, 1, 580.82, 0)"
					xlinkHref="#linear-gradient-7"
				/>
				<linearGradient
					id="linear-gradient-11"
					x1="173.46"
					y1="53.1"
					x2="182.74"
					y2="166.9"
					gradientUnits="userSpaceOnUse">
					<stop offset="0" stopColor="#505064" />
					<stop offset="0.53" stopColor="#66667b" />
					<stop offset="1" stopColor="#7d7d93" />
				</linearGradient>
				<linearGradient
					id="linear-gradient-12"
					x1="244.7"
					y1="53.1"
					x2="253.98"
					y2="166.9"
					gradientTransform="matrix(-1, 0, 0, 1, 469.47, 0)"
					xlinkHref="#linear-gradient-11"
				/>
				<linearGradient
					id="linear-gradient-13"
					x1="229.67"
					y1="308.49"
					x2="172.14"
					y2="129.55"
					xlinkHref="#linear-gradient-7"
				/>
				<linearGradient
					id="linear-gradient-14"
					x1="177.97"
					y1="110.63"
					x2="226.68"
					y2="347.46"
					xlinkHref="#linear-gradient"
				/>
				<linearGradient
					id="linear-gradient-15"
					x1="157.3"
					y1="268.07"
					x2="175.29"
					y2="268.07"
					xlinkHref="#linear-gradient"
				/>
				<linearGradient
					id="linear-gradient-16"
					x1="222.94"
					y1="268.07"
					x2="240.94"
					y2="268.07"
					xlinkHref="#linear-gradient"
				/>
				<linearGradient
					id="linear-gradient-17"
					x1="175.17"
					y1="303.51"
					x2="223.07"
					y2="303.51"
					xlinkHref="#linear-gradient"
				/>
				<linearGradient
					id="linear-gradient-18"
					x1="212.76"
					y1="298.42"
					x2="240.94"
					y2="298.42"
					xlinkHref="#linear-gradient"
				/>
				<linearGradient
					id="linear-gradient-19"
					x1="195.12"
					y1="162.6"
					x2="203.12"
					y2="162.6"
					xlinkHref="#linear-gradient-7"
				/>
				<linearGradient
					id="linear-gradient-20"
					x1="169.09"
					y1="386.34"
					x2="177.57"
					y2="410.69"
					xlinkHref="#linear-gradient-11"
				/>
				<linearGradient
					id="linear-gradient-21"
					x1="244.19"
					y1="360.22"
					x2="252.66"
					y2="384.57"
					xlinkHref="#linear-gradient-11"
				/>
				<linearGradient
					id="linear-gradient-22"
					x1="211.86"
					y1="373.14"
					x2="169.68"
					y2="270.44"
					xlinkHref="#linear-gradient"
				/>
				<linearGradient
					id="linear-gradient-23"
					x1="195.55"
					y1="358.47"
					x2="192.24"
					y2="376.77"
					xlinkHref="#linear-gradient"
				/>
				<linearGradient
					id="linear-gradient-24"
					x1="199.03"
					y1="342.12"
					x2="198.91"
					y2="351.3"
					xlinkHref="#linear-gradient"
				/>
				<linearGradient
					id="linear-gradient-25"
					x1="201.07"
					y1="357.9"
					x2="197.54"
					y2="314.84"
					xlinkHref="#linear-gradient"
				/>
				<linearGradient
					id="linear-gradient-26"
					x1="179.89"
					y1="353.75"
					x2="178.75"
					y2="339.87"
					xlinkHref="#linear-gradient"
				/>
				<linearGradient
					id="linear-gradient-27"
					x1="219.89"
					y1="353.75"
					x2="218.75"
					y2="339.87"
					xlinkHref="#linear-gradient"
				/>
			</defs>
			<g className="blaster-1">
				<Wrapper>
					{children}
					<g id="Layer_1-2" data-name="Layer 1">
						<g id="_3" data-name="3">
							<g>
								<path
									className="blaster-2"
									d="M281,273.71a4,4,0,0,0-3.21-1.13l3.21-3.21a4,4,0,1,0-5.66-5.66l-8.82,8.83a4,4,0,0,0,2.82,6.83c.13,0,.26,0,.38,0l-3.2,3.21a4,4,0,0,0,2.82,6.83c.13,0,.26,0,.39,0l-3.21,3.21a4,4,0,0,0,2.82,6.83c.13,0,.26,0,.39,0l-3.21,3.21a4,4,0,0,0,2.82,6.83c.13,0,.26,0,.39,0l-3.21,3.21a4,4,0,0,0,2.82,6.83c.13,0,.26,0,.39,0l-3.21,3.21a4,4,0,1,0,5.65,5.65l8.83-8.82a4,4,0,0,0-3.21-6.79l3.21-3.21a4,4,0,0,0-3.21-6.79l3.21-3.21a4,4,0,0,0-3.21-6.79l3.21-3.21a4,4,0,0,0-3.21-6.79l3.21-3.21A4,4,0,0,0,281,273.71Z"
								/>
								<path className="blaster-3" d="M269,268.07h9.63l3.13-3.12V244.67H265.83V265Z" />
								<path className="blaster-4" d="M293.59,340.41H253.94V318.88h35l4.68,4.68Z" />
								<g>
									<line className="blaster-5" x1="269.35" y1="272.83" x2="272.38" y2="269.8" />
									<line className="blaster-5" x1="269.35" y1="282.83" x2="272.38" y2="279.8" />
									<line className="blaster-5" x1="269.35" y1="292.83" x2="272.38" y2="289.8" />
									<line className="blaster-5" x1="269.35" y1="302.83" x2="272.38" y2="299.8" />
									<line className="blaster-5" x1="269.35" y1="312.83" x2="272.38" y2="309.8" />
								</g>
							</g>
							<g>
								<path d="M199.12,306.78a93.7,93.7,0,1,1,0-187.39Z" />
								<polygon
									className="blaster-6"
									points="237.76 218.82 253.53 271.06 253.53 330.65 123.65 333.18 138.47 295.06 138.47 272.12 160.47 218.82 160.47 19.95 237.76 19.95 237.76 218.82"
								/>
								<polyline
									className="blaster-7"
									points="137.06 232.59 117.65 252 117.65 303.51 142.04 327.9"
								/>
								<path
									className="blaster-8"
									d="M125.68,241.5l-8.89,8.89a3.1,3.1,0,0,0-.91,2.19v29.74"
								/>
							</g>
							<g id="Orbital_Thing" data-name="Orbital Thing">
								<circle className="blaster-9" cx="199.12" cy="509.25" r="199.12" ref={ref} />
								<g id="Light_Line" data-name="Light Line">
									<path
										className="blaster-10"
										d="M199.12,334.71l80.06,24.23v28.24l-16.47,7.41v22.16a112.24,112.24,0,1,1-127.18,0V394.59l-16.47-7.41V358.94Z"
									/>
								</g>
							</g>
							<g>
								<path
									className="blaster-11"
									d="M282.37,227.24l20.87-20.87v-9.25h-3.69l-.75.75a4,4,0,0,1-5.61,0h0a4,4,0,0,1-1.16-2.81V163.63l8.75,8.74v-6.26L286.67,152Z"
								/>
								<g>
									<path
										className="blaster-12"
										d="M299.55,222.13l1.26,1.26h11.68l1.26-1.26v-4.47h-14.2Z"
									/>
									<path
										className="blaster-13"
										d="M312.49,212.5l1.26-1.26v-48.6l-3.1-11.39-1.22-.93h-5.57l-1.21.93-3.1,11.39v48.6l1.26,1.26h.63v3.9h-.63l-1.26,1.26h14.2l-1.26-1.26h-.64v-3.9Z"
									/>
									<path
										className="blaster-14"
										d="M300.81,212.5h.63v3.9h-.63l-1.26,1.26h14.2l-1.26-1.26h-.64v-3.9h.64l1.26-1.26h-14.2Z"
									/>
								</g>
							</g>
							<g>
								<g id="laser-start">
									<g>
										<path
											className="blaster-2"
											d="M178.94,34.94H152.16l-6.34-6.34V6.34L152.16,0h26.78l6.34,6.34V28.6Z"
										/>
										<path
											className="blaster-15"
											d="M105.9,130.56V40.15l2.38-2.39h85.5V49.41L180.21,63V178.37H144Z"
										/>
										<path
											className="blaster-16"
											d="M107,36.22,129.19,5.29,138,2.47l52.36,7.77,3.41,5.29V37.76h-86Z"
										/>
										<path
											className="blaster-17"
											d="M192.07,15.46l-56.93-2.35a1.79,1.79,0,0,0-1.77,1.25l-6.63,21.09a1.78,1.78,0,0,0,1.7,2.31H192A1.78,1.78,0,0,0,193.78,36V17.24A1.78,1.78,0,0,0,192.07,15.46Z"
										/>
										<path
											className="blaster-18"
											d="M133,11.78l-3.36-5.7a.72.72,0,0,1,.4-1.06l6.48-2.08a.73.73,0,0,1,.9,1l-3.11,7.77A.73.73,0,0,1,133,11.78Z"
										/>
										<g>
											<line className="blaster-19" x1="108.28" y1="37.76" x2="193.78" y2="37.76" />
											<line className="blaster-20" x1="108.28" y1="35.76" x2="193.78" y2="35.76" />
											<line className="blaster-21" x1="107.28" y1="39.76" x2="193.78" y2="39.76" />
										</g>
										<path
											className="blaster-22"
											d="M107.13,40.53h18.29a1.24,1.24,0,0,1,1.24,1.23v111a1.23,1.23,0,0,1-2.18.79l-18.29-22.76a1.25,1.25,0,0,1-.29-.8V41.76A1.23,1.23,0,0,1,107.13,40.53Z"
										/>
									</g>
									<g>
										<path
											className="blaster-2"
											d="M219.29,34.94h26.79l6.34-6.34V6.34L246.08,0H219.29L213,6.34V28.6Z"
										/>
										<path
											className="blaster-15"
											d="M292.34,243.14v-203L290,37.76h-85.5V49.41L218,63V291h36.19Z"
										/>
										<path
											className="blaster-23"
											d="M291.23,36.22,269,5.29l-8.82-2.82-52.35,7.77-3.41,5.29V37.76h86Z"
										/>
										<path
											className="blaster-24"
											d="M206.16,15.46l56.93-2.35a1.78,1.78,0,0,1,1.77,1.25l6.63,21.09a1.77,1.77,0,0,1-1.69,2.31H206.23A1.77,1.77,0,0,1,204.46,36V17.24A1.78,1.78,0,0,1,206.16,15.46Z"
										/>
										<path
											className="blaster-18"
											d="M265.21,11.78l3.37-5.7A.72.72,0,0,0,268.17,5L261.7,2.94a.73.73,0,0,0-.9,1l3.1,7.77A.73.73,0,0,0,265.21,11.78Z"
										/>
										<g>
											<line className="blaster-19" x1="289.96" y1="37.76" x2="204.46" y2="37.76" />
											<line className="blaster-20" x1="289.96" y1="35.76" x2="204.46" y2="35.76" />
											<line className="blaster-21" x1="290.96" y1="39.76" x2="204.46" y2="39.76" />
										</g>
										<path
											className="blaster-22"
											d="M291.1,40.53H272.81a1.23,1.23,0,0,0-1.23,1.23V265.91a1.23,1.23,0,0,0,2.18.79l18.29-22.76a1.25,1.25,0,0,0,.29-.8V41.76A1.23,1.23,0,0,0,291.1,40.53Z"
										/>
									</g>
								</g>
								<g>
									<line className="blaster-25" x1="199.12" y1="75.65" x2="199.12" y2="146.84" />
								</g>
								<g>
									<polyline className="blaster-21" points="105.9 61.82 131.2 61.82 155.61 96.06" />
									<polyline className="blaster-26" points="106.9 59.82 132.2 59.82 156.61 94.06" />
								</g>
							</g>
							<g>
								<g>
									<polygon
										className="blaster-27"
										points="177.32 149.29 177.32 63.88 158.97 74.71 131.91 140.82 131.91 167.18 143.11 178.37 177.32 149.29"
									/>
									<path
										className="blaster-15"
										d="M175,78.94l-2.81-3.29-10.83,3.29-23.06,60.71v11.76l2.74,2.73A82.62,82.62,0,0,1,175,134Z"
									/>
									<g>
										<g>
											<path
												className="blaster-27"
												d="M158,122.13l7.56-5.18a2.1,2.1,0,0,1,3.28,1.73V129a2.1,2.1,0,0,1-3.28,1.73L158,125.58A2.08,2.08,0,0,1,158,122.13Z"
											/>
											<path
												className="blaster-2"
												d="M159.22,122.92l6.67-4.56a1.13,1.13,0,0,1,1.77.94v9.12a1.13,1.13,0,0,1-1.77.93l-6.67-4.56A1.14,1.14,0,0,1,159.22,122.92Z"
											/>
										</g>
										<g>
											<path
												className="blaster-27"
												d="M158,97.15,165.57,92a2.1,2.1,0,0,1,3.28,1.73v10.35a2.1,2.1,0,0,1-3.28,1.73L158,100.6A2.08,2.08,0,0,1,158,97.15Z"
											/>
											<path
												className="blaster-2"
												d="M159.22,97.94l6.67-4.56a1.13,1.13,0,0,1,1.77.94v9.11a1.13,1.13,0,0,1-1.77.94l-6.67-4.56A1.14,1.14,0,0,1,159.22,97.94Z"
											/>
										</g>
										<g>
											<path
												className="blaster-27"
												d="M167.94,113.09l-7.57,5.18a2.1,2.1,0,0,1-3.28-1.73V106.19a2.1,2.1,0,0,1,3.28-1.73l7.57,5.18A2.09,2.09,0,0,1,167.94,113.09Z"
											/>
											<path
												className="blaster-2"
												d="M166.72,112.3l-6.67,4.56a1.13,1.13,0,0,1-1.77-.93v-9.12a1.13,1.13,0,0,1,1.77-.94l6.67,4.56A1.14,1.14,0,0,1,166.72,112.3Z"
											/>
										</g>
									</g>
									<path
										className="blaster-28"
										d="M179.62,61.12h-6.68a2,2,0,0,0-2,2v20.2l3.12,3.12v63.06h7.41V63Z"
									/>
									<path
										className="blaster-29"
										d="M132.75,140.67l5.26-1a.45.45,0,0,0,.35-.29l22.89-60.26a.45.45,0,0,0,0-.39l-1.78-3.2a.47.47,0,0,0-.84,0L132.23,140A.47.47,0,0,0,132.75,140.67Z"
									/>
								</g>
								<g>
									<polygon
										className="blaster-27"
										points="220.91 149.29 220.91 63.88 239.26 74.71 266.32 140.82 266.32 167.18 255.13 178.37 220.91 149.29"
									/>
									<path
										className="blaster-15"
										d="M223.27,78.94l2.82-3.29,10.82,3.29L260,139.65v11.76l-2.73,2.73a82.65,82.65,0,0,0-34-20.17Z"
									/>
									<g>
										<g>
											<path
												className="blaster-27"
												d="M240.23,122.13,232.66,117a2.09,2.09,0,0,0-3.27,1.73V129a2.09,2.09,0,0,0,3.27,1.73l7.57-5.18A2.08,2.08,0,0,0,240.23,122.13Z"
											/>
											<path
												className="blaster-2"
												d="M239,122.92l-6.66-4.56a1.13,1.13,0,0,0-1.77.94v9.12a1.13,1.13,0,0,0,1.77.93l6.66-4.56A1.13,1.13,0,0,0,239,122.92Z"
											/>
										</g>
										<g>
											<path
												className="blaster-27"
												d="M240.23,97.15,232.66,92a2.09,2.09,0,0,0-3.27,1.73v10.35a2.09,2.09,0,0,0,3.27,1.73l7.57-5.18A2.08,2.08,0,0,0,240.23,97.15Z"
											/>
											<path
												className="blaster-2"
												d="M239,97.94l-6.66-4.56a1.13,1.13,0,0,0-1.77.94v9.11a1.13,1.13,0,0,0,1.77.94L239,99.81A1.13,1.13,0,0,0,239,97.94Z"
											/>
										</g>
										<g>
											<path
												className="blaster-27"
												d="M230.3,113.09l7.56,5.18a2.1,2.1,0,0,0,3.28-1.73V106.19a2.1,2.1,0,0,0-3.28-1.73l-7.56,5.18A2.08,2.08,0,0,0,230.3,113.09Z"
											/>
											<path
												className="blaster-2"
												d="M231.52,112.3l6.66,4.56a1.13,1.13,0,0,0,1.77-.93v-9.12a1.13,1.13,0,0,0-1.77-.94l-6.66,4.56A1.13,1.13,0,0,0,231.52,112.3Z"
											/>
										</g>
									</g>
									<path
										className="blaster-30"
										d="M218.62,61.12h6.67a2,2,0,0,1,2,2v20.2l-3.11,3.12v63.06h-7.41V63Z"
									/>
									<path
										className="blaster-29"
										d="M265.49,140.67l-5.26-1a.45.45,0,0,1-.35-.29L237,79.14a.45.45,0,0,1,0-.39l1.78-3.2a.46.46,0,0,1,.83,0L266,140A.46.46,0,0,1,265.49,140.67Z"
									/>
								</g>
							</g>
							<g>
								<rect className="blaster-31" x="182.82" y="293.81" width="32.59" height="38.05" />
								<path
									className="blaster-32"
									d="M270.41,213.46A71.3,71.3,0,1,0,157.3,271.19v21.08l17.87,17.86h47.9l17.87-17.86V271.19A71.2,71.2,0,0,0,270.41,213.46Z"
								/>
								<path
									className="blaster-33"
									d="M237.76,213.46a38.65,38.65,0,1,0-62.47,30.42v42.83l10.18,10.18h27.29l10.18-10.18V243.88A38.55,38.55,0,0,0,237.76,213.46Z"
								/>
								<path
									className="blaster-2"
									d="M205.41,286.71H192.82l-6.23-6.24V209.73l6.23-6.23h12.59l6.24,6.23v70.74Z"
								/>
								<circle className="blaster-2" cx="199.12" cy="213.08" r="30.06" />
								<polygon
									className="blaster-34"
									points="157.3 271.19 175.29 243.88 175.29 286.71 157.3 292.27 157.3 271.19"
								/>
								<polygon
									className="blaster-35"
									points="222.94 243.88 240.94 271.19 240.94 292.27 222.94 286.71 222.94 243.88"
								/>
								<polygon
									className="blaster-36"
									points="223.07 310.13 212.76 296.88 185.47 296.88 175.17 310.13 223.07 310.13"
								/>
								<polygon
									className="blaster-37"
									points="212.76 296.88 222.94 286.71 240.94 292.27 223.07 310.13 212.76 296.88"
								/>
								<g>
									<path className="blaster-38" d="M226.39,166.4a54.18,54.18,0,0,1,16.37,14.77" />
									<line className="blaster-39" x1="199.12" y1="146.17" x2="199.12" y2="179.03" />
									<line className="blaster-40" x1="199.12" y1="142.17" x2="199.12" y2="183.03" />
									<path
										className="blaster-41"
										d="M263.23,244.67,247.62,237h0a54.08,54.08,0,0,0-48.5-78"
									/>
								</g>
								{/* <!-- Maybe apply filter --> */}
								<path
									className="blaster-42"
									d="M220.37,213.08A21.26,21.26,0,1,0,195.12,234v38.52a4,4,0,0,0,8,0V234A21.25,21.25,0,0,0,220.37,213.08Z"
								/>
								<path
									className="blaster-43"
									d="M177.54,174.62l-11.17-19.91a3.34,3.34,0,0,0-5.06-.91L146.4,166.48a3.33,3.33,0,0,0,.09,5.15l18,14.16a3.36,3.36,0,0,0,4.55-.4,40.91,40.91,0,0,1,7.41-6.35A3.34,3.34,0,0,0,177.54,174.62Z"
								/>
								<g>
									<g>
										<polygon
											className="blaster-44"
											points="159.76 335.12 149.65 339.35 149.65 364.06 156.94 364.06 168.71 351.59 159.76 335.12"
										/>
										<polygon
											className="blaster-45"
											points="238.47 335.12 248.59 339.35 248.59 364.06 241.29 364.06 229.53 351.59 238.47 335.12"
										/>
									</g>
									<path
										className="blaster-46"
										d="M230.41,321.94H167.82l-12.17,12.18v16l3.35,3.35h80.23l3.36-3.35v-16Z"
									/>
									<polygon
										className="blaster-47"
										points="227.18 353.47 171.06 353.47 169.06 335.12 229.18 335.12 227.18 353.47"
									/>
									<polygon
										className="blaster-48"
										points="227.18 353.47 171.06 353.47 169.06 335.12 229.18 335.12 227.18 353.47"
									/>
									<polygon
										className="blaster-49"
										points="222.94 326.18 199.12 326.18 175.29 326.18 169.06 335.12 199.12 335.12 229.18 335.12 222.94 326.18"
									/>
									<polygon
										className="blaster-50"
										points="155.65 334.12 169.06 335.12 175.29 326.18 222.94 326.18 229.18 335.12 242.59 334.12 230.41 321.94 167.82 321.94 155.65 334.12"
									/>
									<g className="blaster-51">
										<circle className="blaster-2" cx="180.12" cy="346.29" r="2.94" />
										<circle className="blaster-2" cx="220.12" cy="346.29" r="2.94" />
									</g>
									<g>
										<circle className="blaster-52" cx="179.12" cy="344.29" r="2.94" />
										<circle className="blaster-53" cx="219.12" cy="344.29" r="2.94" />
									</g>
								</g>
							</g>
						</g>
					</g>
				</Wrapper>
			</g>
		</Svg>
	)
}

export default (forwardRef(Blaster): AbstractComponent<Props, Element>)

const Svg = styled.svg`
	.blaster-1 {
		isolation: isolate;
	}

	.blaster-2,
	.blaster-48 {
		fill: #1a1a26;
	}

	.blaster-3 {
		fill: url(#linear-gradient);
	}

	.blaster-4 {
		fill: url(#linear-gradient-2);
	}

	.blaster-10,
	.blaster-19,
	.blaster-20,
	.blaster-21,
	.blaster-25,
	.blaster-26,
	.blaster-31,
	.blaster-38,
	.blaster-39,
	.blaster-40,
	.blaster-41,
	.blaster-5,
	.blaster-7,
	.blaster-8 {
		fill: none;
	}

	.blaster-5,
	.blaster-8 {
		stroke: #fff;
	}

	.blaster-19,
	.blaster-20,
	.blaster-21,
	.blaster-25,
	.blaster-26,
	.blaster-38,
	.blaster-39,
	.blaster-5,
	.blaster-8 {
		stroke-linecap: round;
	}

	.blaster-10,
	.blaster-19,
	.blaster-20,
	.blaster-21,
	.blaster-25,
	.blaster-31,
	.blaster-38,
	.blaster-39,
	.blaster-40,
	.blaster-5 {
		stroke-miterlimit: 10;
	}

	.blaster-19,
	.blaster-20,
	.blaster-21,
	.blaster-26,
	.blaster-40,
	.blaster-41,
	.blaster-5 {
		stroke-width: 2px;
	}

	.blaster-14,
	.blaster-20,
	.blaster-34,
	.blaster-35,
	.blaster-36,
	.blaster-48,
	.blaster-5,
	.blaster-51 {
		opacity: 0.2;
	}

	.blaster-6 {
		fill: url(#linear-gradient-3);
	}

	.blaster-19,
	.blaster-20,
	.blaster-21,
	.blaster-26,
	.blaster-38,
	.blaster-40,
	.blaster-41,
	.blaster-7 {
		stroke: #1a1a26;
	}

	.blaster-26,
	.blaster-41,
	.blaster-7,
	.blaster-8 {
		stroke-linejoin: round;
	}

	.blaster-7 {
		stroke-width: 12px;
	}

	.blaster-8 {
		stroke-width: 3px;
		opacity: 0.15;
	}

	.blaster-9 {
		fill: url(#radial-gradient);
	}

	.blaster-10,
	.blaster-25 {
		stroke: #ffe19f;
	}

	.blaster-10,
	.blaster-31 {
		stroke-width: 10px;
	}

	.blaster-11 {
		fill: url(#radial-gradient-2);
	}

	.blaster-12 {
		fill: url(#linear-gradient-4);
	}

	.blaster-13 {
		fill: url(#linear-gradient-5);
	}

	.blaster-14,
	.blaster-22,
	.blaster-34,
	.blaster-35,
	.blaster-36,
	.blaster-37 {
		mix-blend-mode: multiply;
	}

	.blaster-14 {
		fill: url(#linear-gradient-6);
	}

	.blaster-15,
	.blaster-18,
	.blaster-22,
	.blaster-29 {
		fill: #afafc6;
	}

	.blaster-16 {
		fill: url(#linear-gradient-7);
	}

	.blaster-17 {
		fill: url(#linear-gradient-8);
	}

	.blaster-18 {
		opacity: 0.5;
	}

	.blaster-21 {
		opacity: 0.3;
	}

	.blaster-22,
	.blaster-29 {
		opacity: 0.25;
	}

	.blaster-23 {
		fill: url(#linear-gradient-9);
	}

	.blaster-24 {
		fill: url(#linear-gradient-10);
	}

	.blaster-25,
	.blaster-39 {
		stroke-width: 8px;
	}

	.blaster-27 {
		fill: #8888a5;
	}

	.blaster-28 {
		fill: url(#linear-gradient-11);
	}

	.blaster-30 {
		fill: url(#linear-gradient-12);
	}

	.blaster-31 {
		stroke: #3c3c56;
	}

	.blaster-32 {
		fill: url(#linear-gradient-13);
	}

	.blaster-33 {
		fill: url(#linear-gradient-14);
	}

	.blaster-34 {
		fill: url(#linear-gradient-15);
	}

	.blaster-35 {
		fill: url(#linear-gradient-16);
	}

	.blaster-36 {
		fill: url(#linear-gradient-17);
	}

	.blaster-37 {
		opacity: 0.4;
		fill: url(#linear-gradient-18);
	}

	.blaster-38 {
		stroke-width: 5px;
	}

	.blaster-39 {
		stroke: url(#linear-gradient-19);
	}

	.blaster-42 {
		fill: #ffe19f;
	}

	.blaster-43 {
		fill: #fff;
		opacity: 0.1;
	}

	.blaster-44 {
		fill: url(#linear-gradient-20);
	}

	.blaster-45 {
		fill: url(#linear-gradient-21);
	}

	.blaster-46 {
		fill: url(#linear-gradient-22);
	}

	.blaster-47 {
		fill: url(#linear-gradient-23);
	}

	.blaster-49 {
		fill: url(#linear-gradient-24);
	}

	.blaster-50 {
		fill: url(#linear-gradient-25);
	}

	.blaster-52 {
		fill: url(#linear-gradient-26);
	}

	.blaster-53 {
		fill: url(#linear-gradient-27);
	}
`
