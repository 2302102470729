import React from 'react'
import { useSelector } from 'react-redux'
// $FlowFixMe ReactComponent is a valid way to get SVGs
import { ReactComponent as Motif } from './assets/BackgroundMotif.svg'
import Joystick from './Joystick'
import Upgrades from './assets/upgradeLookup'
import 'styled-components/macro'
import { Station } from '../../components/basics'
import { getEngineIndex } from '../../store/selectors/sharedSelectors'

export default function Thrusters(): React$Node {
	const upgradeIndex = useSelector(getEngineIndex)

	return (
		<Station>
			<div css="width: 100%; position: absolute; top: 50%; transform: translateY(-50%);">
				<Motif css="width:50%; float: right;" />
				<Joystick
					device={Upgrades[upgradeIndex].device}
					css="position: absolute; height: 50vh; width: 100%; left: 50%; top: 50%; transform: translate(-50%, -50%);"
				/>
			</div>
		</Station>
	)
}
