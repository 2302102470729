import React from 'react'
import styled from 'styled-components/macro'
import { BaseSVG } from './BaseSVG'
import { useLiteracyEventGrade, useLiteracyEvent } from '../../hooks'
import { LENGTHS } from './OptionsFrame/constants'
import { SingleOptionWrap } from './OptionsFrame'
import { Overlay } from '../../helperComponents'
import { MdClose, MdCheck } from 'react-icons/md'
import { Button } from '../../../basics'
import { useDispatch } from 'react-redux'
import { sendMessage } from '../../../../store/stores/webSocket'
import MarkdownTextToSpeech from '../../../Markdown/MarkdownTextToSpeech'
import { LITERACY_EVENT_POINT_PING_LOCATION } from './constants'

/**
 * After grading, this gives the student feedback on their choice.
 * @returns
 */
export default function ExplanationFrame({ text }: { text: string }): React$Node {
	const dispatch = useDispatch()
	const literacyEvent = useLiteracyEvent()
	const correct = useLiteracyEventGrade()

	const dismiss = () => {
		const taskData = literacyEvent?.taskData
		if (taskData) {
			dispatch(
				sendMessage(
					'LITERACY_EVENT_DISMISS_STUDENT_MULTIPLE_CHOICE_FEEDBACK',
					{
						taskInstanceId: taskData.instanceId,
					},
					LITERACY_EVENT_POINT_PING_LOCATION
				)
			)
		}
	}

	return (
		<Overlay>
			<Positioner>
				<div className={`left-1/2 -translate-x-full w-fit relative flex items-start`}>
					<div
						style={{ borderRadius: `${LENGTHS.pathBorderRadius * LENGTHS.svgToDomRatio}px` }}
						className={`-mt-6 w-fit border-2 border-accent-aqua z-[1] p-2 bg-black/50`}>
						<h2 className="flex items-center gap-2 justify-center whitespace-nowrap">
							{correct ? (
								<>
									<MdCheck className="text-accent-aqua" size={32} /> Well Done
								</>
							) : (
								<>
									<MdClose className="text-error" size={32} /> Incorrect
								</>
							)}
						</h2>
					</div>
					<BaseSVG>
						<path
							className="stroke-accent-aqua"
							fill="transparent"
							strokeWidth="2"
							d={`M0 0 h${LENGTHS.handleLength * 3} a${LENGTHS.pathBorderRadius},${
								LENGTHS.pathBorderRadius
							} 0 0 1 ${LENGTHS.pathBorderRadius},${LENGTHS.pathBorderRadius} v${
								LENGTHS.handleLength
							}`}
						/>
					</BaseSVG>
				</div>
				<SingleOptionWrap
					markerId={'explanation-marker'}
					id={'explanation'}
					x={0}
					y={0}
					width={0}
					css={`
						left: 50%;
						transform: translateX(-50%);
						width: 50%;
					`}>
					<div
						css={`
							padding: var(--spacing2x) var(--spacing4x);
							text-align: center;
						`}>
						<MarkdownTextToSpeech disabledComponents={['a', 'img']}>{text}</MarkdownTextToSpeech>
					</div>
				</SingleOptionWrap>
				<Button
					style={{
						top: `${(LENGTHS.svgHeight + LENGTHS.optionContainerHeight) * LENGTHS.svgToDomRatio}px`,
					}}
					className="inset-center"
					onClick={dismiss}>
					<h2 css="margin: 0;">{correct ? 'Continue' : 'Try Again'}</h2>
				</Button>
			</Positioner>
		</Overlay>
	)
}

const Positioner = styled.div`
	position: relative;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	height: 60%;
`
