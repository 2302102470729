// @flow
import React, { memo, type AbstractComponent } from 'react'

import './Progress.css'

type Props = {
	progress?: number,
	vertical?: true,
	animate?: true,
}

const Progress = ({ progress, vertical, animate }: Props) => {
	const [containerStyle, spanStyle, afterElementStyle] = getStyles(progress, vertical, animate)
	return (
		<div className="Progress" style={containerStyle}>
			<span style={spanStyle}>
				<div className="after-element" style={afterElementStyle} />
			</span>
		</div>
	)
}

function getStyles(progress: ?number, vertical: ?boolean, animate: ?boolean) {
	let primaryDimension: 'height' | 'width'
	let secondaryDimension: 'height' | 'width'
	if (vertical) {
		primaryDimension = 'height'
		secondaryDimension = 'width'
	} else {
		primaryDimension = 'width'
		secondaryDimension = 'height'
	}

	const containerStyle: {| height?: string, width?: string, transform?: string |} = {
		[primaryDimension]: '100%',
		[secondaryDimension]: '40px',
	}

	if (vertical) {
		containerStyle.transform = 'rotate(180deg)'
	}

	const spanStyle: {| [string]: string |} = {
		[primaryDimension]: `${progress || 0}%`,
		[secondaryDimension]: '100%',
	}

	if (animate) {
		spanStyle.transition = primaryDimension + ' 5s'
	}

	const afterElementStyle = {
		animation: `${
			vertical ? 'animate-shine-vertical' : 'animate-shine-horizontal'
		} 4s ease-out infinite`,
	}

	return [containerStyle, spanStyle, afterElementStyle]
}

export default (memo<Props>(Progress): AbstractComponent<Props>)
