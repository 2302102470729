import React, { useState, useRef, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { MdClose } from 'react-icons/md'
import { getWebSocketUrl } from '../../store/stores/webSocket'
import ReactModal from '../basics/ReactModal'
import { APP_WHITE, SECONDARY_PURPLE } from '../../constants/styles'
import { Input } from './shared'
import { IconButton } from '../basics/Buttons'
import { Button } from '../basics/Buttons.jsx'

/**
 * A button that will open a modal to allow a new student to join the mission. The modal will display a form to enter the student's name, and then
 * will display a message to wait for the teacher to add the student to the mission.
 */
export function AddNewStudentButton({
	onNewStudentCode,
	className,
	children,
	...otherProps
}: {
	onNewStudentCode: string => mixed,
	className?: string,
	children?: React$Node,
}): React$Node {
	const [modalIsOpen, setModalIsOpen] = useState(false)
	const [error, setError] = useState(null)
	const [studentName, setStudentName] = useState('')
	const { addNewStudent, isPending } = useConnectNewStudent({
		onError: setError,
		handleResponse: response => {
			if (response.type === 'success') {
				onNewStudentCode(response.code)
			} else {
				setError(
					'You were not added to the mission. Try again, or talk to your teacher to get connected.'
				)
			}
		},
	})

	const canClose = !isPending || error
	const onClose = () => {
		// Scroll to top of page for tablet user experience. When tablet input is focused,
		// the keyboard takes up space, and the browser scrolls content up, messing with screen height.
		window.scrollTo(0, 0)
		if (canClose) {
			setError(null)
			setModalIsOpen(false)
		}
	}

	return (
		<>
			<Button onClick={() => setModalIsOpen(true)} className={className}>
				{children}
			</Button>
			<ReactModal
				onRequestClose={onClose}
				isOpen={modalIsOpen}
				style={{
					overlay: {
						zIndex: 20,
					},
					content: {
						backgroundColor: SECONDARY_PURPLE,
						color: APP_WHITE,
					},
				}}>
				{canClose && (
					<IconButton onClick={onClose} className="absolute top-1 right-1">
						<MdClose size={23} />
					</IconButton>
				)}
				{error ? (
					<>
						<div>{error}</div>
						<Button
							type="submit"
							className="self-center mt-4"
							outline
							onClick={() => setError(null)}>
							{' '}
							Try Again
						</Button>
					</>
				) : isPending ? (
					<>
						Sit tight! You will be connected to the mission as soon as your teacher adds you.
						<br />
						<div className="mt-4">
							Name: <span data-sentry-mask>{studentName}</span>
						</div>
					</>
				) : (
					<form
						onSubmit={e => {
							e.preventDefault()
							// Scroll to top of page for tablet user experience. When tablet input is focused,
							// the keyboard takes up space, and the browser scrolls content up, messing with screen height.
							window.scrollTo(0, 0)
							const name = studentName.trim()
							if (name.length > 50) {
								setError('Name is too long')
								return
							}
							if (name === '') {
								setError('Please enter a name')
								return
							}
							addNewStudent(name)
						}}
						className="flex flex-col">
						<label>
							Enter your name
							<Input
								className="mt-2"
								data-sentry-mask
								name="new-student-name"
								autoFocus
								value={studentName}
								onChange={e => setStudentName(e.currentTarget.value)}
							/>
						</label>
						<Button type="submit" className="self-center mt-4">
							Join Mission
						</Button>
					</form>
				)}
			</ReactModal>
		</>
	)
}

/**
 * A hook to facilitate adding a new student to a mission server. Returns a function to add a new student and a boolean indicating if the request to add a new
 * student is in progress. When the request to add a new student is sent, the hook will create a new websocket to listen for a response from the server.
 *
 * @param arg
 * @param {string} arg.missionServerUrl the url of the mission server
 * @param {({ code: }) => void} arg.handleResponse a callback to be called when the student code is received from the mission server
 */
function useConnectNewStudent({
	handleResponse: _handleResponse,
	onError,
}: {
	handleResponse: ({| type: 'success', code: string |} | {| type: 'fail' |}) => void,
	onError: (error: string) => mixed,
}): { addNewStudent: (name: string) => void, isPending: boolean } {
	const websocketServerUrl = useSelector(getWebSocketUrl)
	const [isPending, setIsPending] = useState(false)
	const handleResponse = useRef(_handleResponse)
	handleResponse.current = _handleResponse
	const isMounted = useRef(true)

	useEffect(() => {
		return () => {
			isMounted.current = false
		}
	}, [])

	const addNewStudent = (name: string) => {
		if (!websocketServerUrl) {
			onError('Unable to decide which server to use. Please try again.')
			return
		}
		const url = new URL(websocketServerUrl)
		url.searchParams.set('newStudent', 'true')
		url.searchParams.set('name', name)

		setIsPending(true)
		const ws = new WebSocket(url.toString())

		let closedOnPurpose = false

		ws.onmessage = (event: MessageEvent) => {
			if (typeof event.data !== 'string') {
				return
			}

			try {
				const message = JSON.parse(event.data)
				if (message.type === 'added') {
					setIsPending(false)
					closedOnPurpose = true
					ws.close()
					handleResponse.current({ type: 'success', code: message.studentCode })
				} else if (message.type === 'rejected') {
					setIsPending(false)
					closedOnPurpose = true
					ws.close()
					handleResponse.current({ type: 'fail' })
				}
			} catch (error) {
				console.error('failed to parse message from server. Message: ', String(event.data))
			}
		}

		ws.onclose = () => {
			if (!closedOnPurpose) {
				onError('Something went wrong while adding you to the mission. Please try again.')
			}
			if (isMounted.current) {
				setIsPending(false)
			}
		}
	}

	return { addNewStudent, isPending }
}
