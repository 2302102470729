/**
 * Given a hex string and an opacity percentage (number between 0 and 1), will convert to rgba value for that string.
 * Helpful for lightening constant colors.
 * @param {string} hex
 * @param {number} percent between 0 and 1
 */
export const rgba: (hex: string, percent: number) => string = (
	hex: string,
	percent: number
): string => {
	const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
	if (result) {
		const { r, g, b } = {
			r: parseInt(result[1], 16),
			g: parseInt(result[2], 16),
			b: parseInt(result[3], 16),
		}
		return `rgba(${r}, ${g}, ${b}, ${percent})`
	}
	return ''
}

// Make sure these match variables.less
export const UI_Z_INDEX: number = 5
export const OVERLAY_Z_INDEX: number = UI_Z_INDEX + 1
export const STATION_ICON_Z_INDEX: number = UI_Z_INDEX + 1
export const MODAL_Z_INDEX: number = OVERLAY_Z_INDEX + 1
export const DEFAULT_MEDIA_MODAL_Z_INDEX: number = MODAL_Z_INDEX + 3 // 10 is the default for media modals
export const LITERACY_EVENT_Z_INDEX: number = DEFAULT_MEDIA_MODAL_Z_INDEX + 1
export const CLASS_QUESTION_INDEX: number = MODAL_Z_INDEX + 1
export const SQUADRON_STATUS_BAR_PAUSED_Z_INDEX: number = 1000 // put in front of modals and overlays
export const CONTROLLER_Z_INDEX: number = SQUADRON_STATUS_BAR_PAUSED_Z_INDEX + 2
export const JOIN_AUDIO_CHAT_MODAL_Z_INDEX: number = SQUADRON_STATUS_BAR_PAUSED_Z_INDEX + 1
export const SQUADRON_EXPANDED_PLAYER_Z_INDEX: number = SQUADRON_STATUS_BAR_PAUSED_Z_INDEX + 1
export const COLLECTED_DATA_Z_INDEX: number = 0

export const ERROR_RED: string = '#cc324c'
export const DARK_PRIMARY: string = '#3C497C' // tailwind color: primary-700
export const PRIMARY_PURPLE: string = '#5d578d' // tailwind color: primary-600
export const SECONDARY_PURPLE: string = '#364984' // closest tailwind color: primary-700
export const LIGHT_PURPLE: string = '#817abc' // tailwind color: primary, primary-500

export const ACCENT_BLUE: string = '#95effd'
export const ACCENT_BLUE_FAINT: string = rgba(ACCENT_BLUE, 0.5)
export const ACCENT_GREEN: string = '#39fdc1'
export const LIGHT_GREEN: string = '#03B5AF'
export const LIGHT_ORANGE: string = '#FFB352'
export const INDIGO: string = '#5c6992'
export const PRIMARY_GREEN: string = '#05b2ae'
export const DARK_RED_FLASH: string = '#d22020'
export const BRIGHT_RED_FLASH: string = '#ff0000'
export const DULL_BLUE_PULSE: string = '#427776'
export const ALMOST_BLACK: string = '#00000d'
export const BOOST_PHRASE_ACTIVE: string = '#00b5af'
export const BOOST_PHRASE_INACTIVE: string = '#605591'
export const BOOST_GOLD: string = '#d7af00'
export const BORDER_GREY: string = '#707070'
export const GRAY_PRIMARY: string = '#364755'

export const APP_WHITE: string = '#ffffff'

export const DARKEST_SHADE: string = '#151540'
export const EMPTY_SPACE: string = '#14163D' // tailwind color: primary-800

export const ALMOST_BLACK_VERY_FAINT: string = rgba(ALMOST_BLACK, 0.5)
export const PRIMARY_PURPLE_FAINT: string = rgba(PRIMARY_PURPLE, 0.9)
export const PRIMARY_PURPLE_VERY_FAINT: string = rgba(PRIMARY_PURPLE, 0.5)

export const ACCENT_ORANGE: string = '#f7931e'

export const PROGRESS: string = '#94EFFD'
export const CONTRAST: string = PRIMARY_GREEN
export const HIGHLIGHT: string = ACCENT_GREEN
export const WARNING: string = '#FF7E7E'
export const RED: string = '#FF5E4B'
export const DARK_RED: string = '#EE0000'

export const BONUS_YELLOW: string = '#d2af00'
export const CORRECT_QUESTION_PURPLE: string = '#e205e2'

export const GRADIENT_LIGHT_PURPLE: string = '#9334ff'
export const GRADIENT_DARK_PURPLE: string = '#31174e'
export const GRADIENT_LIGHT_GREEN: string = '#0d3a58'
export const GRADIENT_DARK_BLUE: string = '#413581'

export const STATION_PADDING: string = '6vh'

export const REMOTE_STATUS_BAR_BLUE: string = '#5D588E'
export const REMOTE_STATUS_BAR_LIGHT_BLUE: string = '#7A73B5'
export const ACTIVE_ORANGE: string = '#F89216'
export const INACTIVE_MICROPHONE_BLACK: string = '#172954'
export const REMOTE_STATUS_GREEN: string = '#07b2ad'

export const TIMER_UPDATE_RATE = 1000

const spacingValue = 8
const spacingHalfValue = spacingValue / 2
const spacing2xValue = spacingValue * 2
const spacing3xValue = spacingValue * 3
const spacing4xValue = spacingValue * 4
const spacing5xValue = spacingValue * 5
const spacing6xValue = spacingValue * 6

export const theme = {
	spacingHalfValue,
	spacingValue,
	spacing2xValue,
	fontRegular: '500',
	fontSemiBold: '600',
	fontBold: '700',
	titleFont: '20px',
	fontxxxs: '12px',
	fontxxs: '14px',
	fontxs: '15px',
	fonts: '16px',
	fontm: '20px',
	fontl: '24px',
	fontxl: '32px',
	fontxxl: '2.25rem',
	base: 'transparent',
	darkestPrimary: EMPTY_SPACE, // tailwind color: primary-800
	darkerPrimary: DARK_PRIMARY, // tailwind color: primary-700
	darkPrimary: PRIMARY_PURPLE, // tailwind color: primary-600
	primary: LIGHT_PURPLE, // tailwind color: primary, primary-500
	primaryColor: LIGHT_PURPLE, // tailwind color: primary, primary-500
	lightestPrimaryColor: '#DDDDF6', // tailwind color: primary-200
	lighterPrimaryColor: '#BCBCE7', // tailwind color: primary-300
	lightPrimaryColor: '#7A77A5', // tailwind color: primary-400
	secondary: SECONDARY_PURPLE, // closest tailwind color: primary-700
	secondaryColor: LIGHT_GREEN,
	lightSecondaryColor: '#15AAA4',
	lighterSecondaryColor: '#c5eff6',
	lighterGreyColor: '#bcb9d9',
	greyColor: ALMOST_BLACK,
	darkGreyColor: DARKEST_SHADE,
	neutralLightest: '#E4E3E9',
	neutralVariant: '#80808A',
	neutralDark: '#4A4A4A',
	neutral: '#BAB9C9',
	rgba: rgba,
	errorColor: ERROR_RED,
	modalZIndex: 7,
	uiZIndex: 5,
	white: APP_WHITE,
	black: '#000000',
	success: '#1DC574',
	error: '#F35959',
	warning: WARNING,
	spacingHalf: `${spacingHalfValue}px`,
	spacing: `${spacingValue}px`,
	spacing2x: `${spacing2xValue}px`,
	spacing3x: `${spacing3xValue}px`,
	spacing4x: `${spacing4xValue}px`,
	spacing5x: `${spacing5xValue}px`,
	spacing6x: `${spacing6xValue}px`,
	fontFamily: 'proxima-nova,sans-serif',
	primaryButton: {
		hover: '#7289da',
		pressed: '#343e80',
		activeBorder: '#332c72',
		textOnFill: '#ffffff',
		color: '#4e5aa8',
	},
}

export const TAILWIND_SCREEN_SIZE_VALUES = {
	sm: 640,
	md: 768,
	lg: 1024,
	xl: 1280,
	'2xl': 1536,
}

export const TAILWIND_SCREEN_SIZES = {
	sm: `${TAILWIND_SCREEN_SIZE_VALUES.sm}px`,
	md: `${TAILWIND_SCREEN_SIZE_VALUES.md}px`,
	lg: `${TAILWIND_SCREEN_SIZE_VALUES.lg}px`,
	xl: `${TAILWIND_SCREEN_SIZE_VALUES.xl}px`,
	'2xl': `${TAILWIND_SCREEN_SIZE_VALUES['2xl']}px`,
}
