// @flow
import React, {
	useRef,
	useState,
	useEffect,
	useCallback,
	forwardRef,
	type AbstractComponent,
} from 'react'
import { get } from 'axios'
import classnames from 'classnames'

type InputProps = {
	label?: string,
	id?: string,
	size?: 'large',
	className?: string,
	inputClassName?: string,
}
/**
 * A styled input component with an optional label. The label will act as a placeholder when the input is empty, and will float to the top when the input is focused.
 */
export const Input: AbstractComponent<InputProps, HTMLInputElement> = forwardRef(function Input(
	{ label, size, className, inputClassName, ...props }: InputProps,
	ref
): React$Node {
	return (
		<div className={classnames('relative', className)}>
			<input
				{...props}
				ref={ref}
				className={classnames(
					'leading-7 w-full border-2 border-white rounded bg-transparent text-white focus:outline-none peer p-1',
					size === 'large' && 'text-lg',
					inputClassName
				)}
			/>
			{label && (
				<label
					htmlFor={props.id}
					className={classnames(
						'absolute transition-all',
						// styles when outside of input
						'-top-6 left-0 text-sm text-neutral-light',
						// styles when inside of input
						'peer-placeholder-shown:top-2 peer-placeholder-shown:left-2 peer-placeholder-shown:text-base peer-placeholder-shown:text-white',
						// same styles as outside of input but when focused
						'peer-focus:-top-6 peer-focus:left-0 peer-focus:text-sm peer-focus:text-neutral-light'
					)}>
					{label}
				</label>
			)}
		</div>
	)
})

export type Session = {
	url: string,
	missionCode: string,
	simulationName: string,
}

export type JrStudents = {
	type: 'junior',
	data: { [studentCode: string]: { id: string, code: string, name: string } },
}

export type AdvancedStudent = {
	id: string,
	name: string,
	code: string,
	station?: { id: string, name: string },
	isLead?: boolean,
}

export type AdvancedStudentsObject = {
	[studentId: string]: AdvancedStudent,
}

export type AdvancedStudents = {
	type: 'juniorPlus',
	data: {
		[teamId: string]: {
			name: string,
			id: string,
			students: AdvancedStudentsObject,
		},
	},
}

/**
 * A hook that queries the given server with the given mission server url to get the students for a mission.
 *
 * @param {string} serverUrl the url for the server to query
 * @param {{initialStudents?: Students, immediate?: {missionCode: string}, onError: Error => void}} options optional values to pass to the hook, if immediate is set
 * to false, the query will not be called, likewise if the value to immediate changes to truthy, than the query will be called again.
 * @returns {loading: boolean, error: ?Error, students: AdvancedStudents | JrStudents, fetch: (missionCode: string) => void} a students object
 */
export function useStudentsQuery<Students: AdvancedStudents | JrStudents>(
	serverUrl: ?string,
	{
		initialStudents,
		immediate,
		onError,
	}: {
		initialStudents?: ?Students,
		immediate?: { missionCode: string },
		onError?: Error => void,
	} = {}
): { loading: boolean, error: ?Error, students: ?Students, fetch: (string, string) => void } {
	const [loading, setLoading] = useState(false)
	const fetchRef = useRef(noop)
	const [error, setError] = useState(null)
	const [students, setStudents] = useState<?Students>(initialStudents)
	const fetch = useCallback(
		(serverUrl: string, missionCode?: string) => {
			if (loading) {
				return
			}
			if (serverUrl && missionCode) {
				setLoading(true)
				get(`${serverUrl}/api/missions/${missionCode.toLowerCase()}/students`)
					.then((result: { data: { students: Students } }) => {
						if (result.data.students) {
							setStudents(result.data.students)
						} else {
							throw new Error('Could not get students for mission code')
						}
					})
					.catch(error => {
						onError && onError(error)
						setError(error)
					})
					.finally(() => {
						setLoading(false)
					})
			}
		},
		[loading, onError]
	)
	useEffect(() => {
		fetchRef.current = fetch
	}, [fetch])
	const missionCode = immediate?.missionCode
	useEffect(() => {
		if (missionCode && serverUrl) {
			fetchRef.current(serverUrl, missionCode)
		}
	}, [missionCode, serverUrl])
	return { loading, error, students, fetch: fetchRef.current }
}

// A noop function used to temporarily take the place of the fetch function until it is set.
const noop: (string, ?string) => void = () => {
	console.error('this is a no op')
}
