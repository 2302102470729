import React from 'react'
import { useSelector } from 'react-redux'
import 'styled-components/macro'
import { getActiveStudentCount } from '../store/stores/general'

import HackerActions from './HackerActions'
import HackerData from './HackerData'

export default function HackerMode(): React$Node {
	const totalStudents = useSelector(getActiveStudentCount)
	return (
		<main css="background-color: rgb(40, 56, 108);">
			<div>Total Connected Students: {totalStudents}</div>
			<div className="row">
				<HackerActions />
				<HackerData />
			</div>
		</main>
	)
}
