// @flow
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { isRepairsActive } from '../store/selectors/jrState'
import { isRepairsActive as isJrPlusRepairsActive } from '../store/selectors/jrPlusState/decks'
import { sendMessage } from '../store/stores/webSocket'
import type { TractorBeamStatus, DefenseStatus } from '../types'
import { isCommunicationActive, isPowerActive, isScannerActive } from '../store/selectors/jrState'
import { isThrustersActive } from '../store/selectors/sharedSelectors'
import { isPowerActive as isPlusPowerActive } from '../store/selectors/jrPlusState/power'
import { isCommunicationActive as isPlusCommunicationActive } from '../store/selectors/jrPlusState/communication'
import { getMissionType, isPaused, JUNIOR_PLUS } from '../store/stores/general'
import { setIsDebugging, getIsDebugging } from '../store/stores/settings'
import './HackerActions.css'

export default function HackerActions(): React$Node {
	const dispatch = useDispatch()

	const isMissionPaused = useSelector(isPaused)
	const isDebugging = useSelector(getIsDebugging)
	const missionType = useSelector(getMissionType)
	const thrusterActiveStatus = useSelector(isThrustersActive)
	const communicationActiveStatus = useSelector(
		missionType === JUNIOR_PLUS ? isPlusCommunicationActive : isCommunicationActive
	)
	const scannerActiveStatus = useSelector(isScannerActive)
	const repairActiveStatus = useSelector(
		missionType === JUNIOR_PLUS ? isJrPlusRepairsActive : isRepairsActive
	)
	const powerActiveStatus = useSelector(
		missionType === JUNIOR_PLUS ? isPlusPowerActive : isPowerActive
	)

	const sendHack = (type: string, payload?: any) => {
		dispatch(sendMessage('HACKER-' + type, payload))
	}

	const [damageAmount, setDamageAmount] = useState(10)
	const [tractorBeamStatus, setTractorBeamStatus] = useState<TractorBeamStatus>('ASTEROID')
	const [defenseStatus, setDefenseStatus] = useState<DefenseStatus>('ENEMY_SHIP')

	return (
		<div className="HackerActions">
			<div className="action">
				<button onClick={() => sendHack('DAMAGE-SHIP', Number(damageAmount))}>
					Damage Ship -{damageAmount}
				</button>
				<input
					className="fill"
					type="number"
					value={damageAmount}
					onChange={e => {
						setDamageAmount(e.target.value)
					}}
				/>
			</div>

			<div className="action">
				<button onClick={() => sendHack('THRUSTERS-RESET', !thrusterActiveStatus)}>
					Turn thrusters {thrusterActiveStatus ? 'off' : 'on'}
				</button>
			</div>
			<div className="action">
				<button onClick={() => sendHack('REPAIR-RESET', !repairActiveStatus)}>
					{repairActiveStatus ? 'Deactivate Repairs' : 'Activate Repairs'}
				</button>
			</div>
			<div className="action">
				<button
					onClick={() => {
						const payload =
							missionType === JUNIOR_PLUS ? { max: 0.6, min: 0.4 } : { targetPower: 5 }
						sendHack('POWER-SET', { status: !powerActiveStatus, ...payload })
					}}>
					Turn power {powerActiveStatus ? 'off' : 'on'}
				</button>
			</div>
			<div className="action">
				<button onClick={() => sendHack('TRANSPORTER-RESET')}>Reset Transporter</button>
			</div>
			<div className="action">
				<button
					onClick={() => {
						sendHack(
							missionType === JUNIOR_PLUS ? 'PLUS-COMMUNICATION-SET' : 'COMMUNICATION-SET',
							!communicationActiveStatus
						)
					}}>
					Turn {communicationActiveStatus ? 'off' : 'on'}
					{missionType === JUNIOR_PLUS ? ' communications' : ' the phone'}
				</button>
			</div>
			<div className="action">
				<select
					value={tractorBeamStatus}
					onChange={e => {
						setTractorBeamStatus(e.target.value)
					}}>
					<option value={'OFFLINE'}>Offline</option>
					<option value={'ASTEROID'}>Asteroid</option>
					<option value={'ENEMY_SHIP'}>Enemy Ship</option>
					<option value={'TARGET'}>Target</option>
				</select>
				<button onClick={() => sendHack('TRACTOR-BEAM-SET', tractorBeamStatus)}>
					{tractorBeamStatus ? `Set Tractor Beam to ${tractorBeamStatus}` : 'Turn off Tractor Beam'}
				</button>
			</div>
			<div className="action">
				<button onClick={() => sendHack('SCANNER-RESET', !scannerActiveStatus)}>
					{scannerActiveStatus ? 'Turn off Scanner' : 'Turn on Scanner'}
				</button>
			</div>
			<div className="action">
				<button onClick={() => sendHack('CREATIVE-CANVAS')}>Initialize creative canvas</button>
			</div>
			<div className="action">
				<select
					value={defenseStatus}
					onChange={e => {
						setDefenseStatus(e.target.value)
					}}>
					<option value={'OFFLINE'}>Offline</option>
					<option value={'ASTEROID'}>Asteroid</option>
					<option value={'ENEMY_SHIP'}>Enemy Ship</option>
					<option value={'TARGET'}>Target</option>
				</select>
				<button onClick={() => sendHack('DEFENSE-SET', defenseStatus)}>
					{defenseStatus ? `Set Defense to ${defenseStatus}` : 'Turn off Defense'}
				</button>
				<div className="action">
					<button onClick={() => sendHack('RESET-ALL')}>RESET ALL</button>
				</div>
				<div className="action">
					<button onClick={() => sendHack('TOGGLE-PAUSE', !isMissionPaused)}>
						{isMissionPaused ? 'Play ' : 'Pause '}Mission
					</button>
				</div>
				<div className="action">
					<button onClick={() => sendHack('START-TRAINING')}>Start Training</button>
				</div>
				<div className="action">
					<button onClick={() => sendHack('START-MISSION')}>Start Mission</button>
				</div>
				<CheckBoxWrapper>
					<input
						type="checkbox"
						checked={isDebugging}
						onChange={() => dispatch(setIsDebugging(!isDebugging))}
						id="debug-toggle"
					/>
					<label htmlFor="debug-toggle">Debug</label>
				</CheckBoxWrapper>
			</div>
		</div>
	)
}

const CheckBoxWrapper = styled.div`
	color: white;
`
