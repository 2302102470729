import { createContext } from 'react'
import type { Context } from 'react'

export type ShipDimensionData = {
	svgWidth: number,
	svgHeight: number,
	screenWidth: number,
	screenHeight: number,
}

export const ShipDimensionContext: Context<ShipDimensionData> = createContext({
	svgWidth: 100,
	svgHeight: 100,
	screenWidth: 100,
	screenHeight: 100,
})
