import { keyframes, type KeyFrames } from 'styled-components'

function hexToRgb(hex): ?{ r: number, g: number, b: number } {
	var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
	return result
		? {
				r: parseInt(result[1], 16),
				g: parseInt(result[2], 16),
				b: parseInt(result[3], 16),
		  }
		: null
}

export function animateThrob(hexColor: string): KeyFrames {
	const { r, g, b } = hexToRgb(hexColor) || { r: 255, g: 255, b: 255 }
	return keyframes`
	0%   { fill: rgba(${r}, ${g}, ${b}, 0.2); }
	50%  { fill: rgba(${r}, ${g}, ${b}, 0.4);}
	100% { fill: rgba(${r}, ${g}, ${b}, 0.2); }
	`
}

export const DISPLAY_STATUS = { FAIL: 'FAIL', SUCCESS: 'SUCCESS' }
