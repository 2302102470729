import React, { type AbstractComponent } from 'react'
import shipImage from '../images/shipFront.svg'

type Props = {
	height?: string,
	width?: string,
	rotation?: number,
	className?: string,
}

export const LEFT_CROP_AMOUNT = 80

function VehicleImage({ className }: Props, ref): React$Node {
	return (
		<div style={{ overflow: 'hidden', marginLeft: `-${LEFT_CROP_AMOUNT}px ` }}>
			<img
				ref={ref}
				src={shipImage}
				alt="The Vehicle"
				className={className}
				style={{ clipPath: `inset(0 0 0 ${LEFT_CROP_AMOUNT}px)` }}
			/>
		</div>
	)
}

export default (React.forwardRef(VehicleImage): AbstractComponent<Props, HTMLImageElement>)
