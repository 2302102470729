import uuid from 'uuid/v4'
import { REDUX_WEBSOCKET_SEND } from '../../utility/websocketConstants'
import { addPointEvent } from '../stores/points'
import type { MiddleWare } from './general'
import type { PointEventLocation } from '../stores/points'
import { getStation } from '../stores/general'
import type { Action } from '../actionTypes'

/**
 * Redux middleware that checks each WEBSOCKET_SEND action and, if it has a point event location, handles the point
 * event functionality. This includes dispatching an "add point event" action to store the point event location locally,
 * and adding the point event ID onto the websocket message.
 */
const preSendMessage: MiddleWare = store => next => action => {
	if (action.type === REDUX_WEBSOCKET_SEND) {
		if (!store.getState().webSocket.isOpen) {
			return
		}
		const webSocketMessage = action.payload
		if (!webSocketMessage) {
			return
		}
		if (action.meta && action.meta.POINT_EVENT_LOCATION) {
			const pointEventLocation: PointEventLocation = (action.meta.POINT_EVENT_LOCATION: any)
			const pointEventId = uuid()
			const pointAction: Action = {
				// spread to help with flow
				...addPointEvent({
					id: pointEventId,
					location: pointEventLocation,
					station: getStation(store.getState()),
				}),
			}
			store.dispatch(pointAction)
			if (!webSocketMessage.meta) {
				webSocketMessage.meta = {}
			}
			webSocketMessage.meta.POINT_EVENT_ID = pointEventId
		}
	}
	return next(action)
}

export default preSendMessage
