import { getStudentId } from '../../stores/general'
import type { ReduxStore } from '../../rootReducer'
import type { PanelComponent as Component, PanelData } from '@mission.io/mission-toolkit'

export const AVAILABLE_CODES: string[] = 'ΩΨΘΣΔβδζξΦΞΓμλ'.split('')

export const COMPONENT_TYPES = {
	SLIDER: 'SLIDER',
	CODE: 'CODE',
	TOGGLE: 'TOGGLE',
	TEAM_TOGGLE: 'TEAM_TOGGLE',
	CONNECTION: 'CONNECTION',
}

export const PANEL_TYPES = {
	SLIDER: 'SLIDER',
	CODE: 'CODE',
	BUTTON: 'BUTTON',
	SWITCH: 'SWITCH',
	CONNECTION: 'CONNECTION',
	TEAM_SWITCH: 'TEAM_SWITCH',
}

// type PanelTypes = $Keys<typeof PANEL_TYPES>

export type PopulatedPanel = {
	id: string,
	type: string,
	components: Component[],
}

export function getCodeSocketMessage(
	componentId: string
): {
	componentId: string,
	type: 'CODE',
} {
	return {
		componentId,
		type: 'CODE',
	}
}

export function getToggleSocketMessage(
	componentId: string,
	newValue: boolean
): {
	componentId: string,
	type: 'TOGGLE',
	value: boolean,
} {
	return {
		componentId,
		value: newValue,
		type: 'TOGGLE',
	}
}

/**
 * getTurnTeamSwitchOnMessage - get a message to send to the server which turns on the team toggle component
 *
 * @param {string} componentId - the id of the component to turn on
 *
 * @return Object
 */
export function getTurnTeamSwitchOnMessage(
	componentId: string
): {
	componentId: string,
	type: 'TEAM_TOGGLE_ON',
} {
	return {
		componentId,
		type: 'TEAM_TOGGLE_ON',
	}
}

export function getSliderSocketMessage(
	componentId: string,
	newValue: number
): {
	componentId: string,
	type: 'SLIDER',
	value: number,
} {
	return {
		componentId,
		value: newValue,
		type: 'SLIDER',
	}
}

export function getConnectionSocketMessage(
	componentId: string,
	connection: [string, string]
): {
	componentId: string,
	type: 'CONNECTION',
	value: [string, string],
} {
	return {
		componentId,
		value: connection,
		type: COMPONENT_TYPES.CONNECTION,
	}
}

export function getInteractiveCodeSocketMessage(
	componentId: string,
	newValue: string
): {
	componentId: string,
	type: 'CODE',
	value: string,
} {
	return {
		componentId,
		value: newValue,
		type: 'CODE',
	}
}

function populatePanel(state: ReduxStore, panel: PanelData): PopulatedPanel {
	return {
		id: panel.id,
		type: panel.type,
		components: panel.components.map((componentId: string) => getComponent(state, componentId)),
	}
}

export function getStudentPanels(state: ReduxStore, studentId: string): PopulatedPanel[] {
	const store = state.jrPlusState.stationData.engineering
	const studentPanels = store.panels[studentId]
	if (!studentPanels) {
		return []
	}
	const populatedPanels = []
	studentPanels.forEach(panel => {
		populatedPanels.push(populatePanel(state, panel))
	})
	return populatedPanels
}

export function getCurrentStudentPanels(state: ReduxStore): PopulatedPanel[] {
	const studentId = getStudentId(state.general)
	if (!studentId) {
		return []
	}
	return getStudentPanels(state, studentId)
}

export function getComponent(state: ReduxStore, componentId: string): Component {
	return state.jrPlusState.stationData.engineering.components[componentId]
}

export function getInteractiveCode(state: ReduxStore, componentId: string): ?string {
	const store = state.jrPlusState.stationData.engineering
	const studentId = getStudentId(state.general)
	if (!store || !studentId) {
		return null
	}
	return (
		store.interactive[studentId] &&
		store.interactive[studentId][componentId] &&
		store.interactive[studentId][componentId].code
	)
}
