import React from 'react'
import { createRoot } from 'react-dom/client'
import * as Sentry from '@sentry/react'
import { enablePatches } from 'immer'
import LogRocket from 'logrocket'
import setupLogRocketReact from 'logrocket-react'

import config from './config'
import App from './App'
import { hackToHandleGoogleTranslateError } from './utility/googleTranslateHack'

enablePatches()

if (config.isProd) {
	hackToHandleGoogleTranslateError()
}

if (config.trackErrors) {
	if (config.logRocketUrl) {
		LogRocket.init(config.logRocketUrl)
		setupLogRocketReact(LogRocket)
	}

	Sentry.init({
		dsn: config.sentry.dsn,
		environment: config.environment,
		integrations: [
			Sentry.replayIntegration({
				// turn off masking by default. To enable masking on an element, add the property "data-sentry-mask" or the class "sentry-mask"
				maskAllText: false,
				maskAllInputs: false,
				blockAllMedia: false,
			}),
		],
		replaysSessionSampleRate: config.sentry.replaysSessionSampleRate,
		replaysOnErrorSampleRate: config.sentry.replaysOnErrorSampleRate,
		ignoreErrors: [
			// we are ignoring this because its chrome throwing an error for slow animations
			// and because everyone else on stack overflow agrees that its ignorable
			'ResizeObserver loop limit exceeded',
			// Errors that come from Google Translate. I could not figure out how to catch the errors, and we don't want them taking up our Sentry quota.
			/Cannot read properties of null \(reading '__closure_events_fn_/,
			/Cannot read property '__closure_events_fn_/,
			// These errors can be caused by tldraw when it tries to read from the clipboard when pasting (Note: these only happen when our program initiates a paste [such as by clicking a button] and not when the user does [ie. using the keyboard shortcut such as Command/Control + V])
			/NotAllowedError: Failed to execute 'read' on 'Clipboard': Read permission denied\./, // Google Chrome
			/DOMException: Clipboard read operation is not allowed\./, // Firefox
			/NotAllowedError: The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission\./, // Safari
		],
		// Don't send the message if the error stack trace has translate_http in it
		beforeSend(event, hint) {
			const error = hint.originalException
			if (error?.stack?.includes('translate_http')) {
				return null
			}
			return event
		},
	})
	if (config.logRocketUrl) {
		LogRocket.getSessionURL(sessionURL => {
			Sentry.getCurrentScope().setExtra('LogRocket sessionURL', sessionURL)
		})
	}
}

const container = document.getElementById('root')
// $FlowFixMe
createRoot(container).render(<App />)
