import React from 'react'
import { useSpring, animated } from '@react-spring/web'
import laserBeamImgSrc from '../../images/laser_beam.png'
import type { AbstractComponent } from 'react'
const PROJECTILE_DURATION = 2000

type Location = {
	top: number,
	left: number,
}

export const PROJECTILE_TYPE = {
	LASER: 'LASER',
	RADIO_WAVE: 'RADIO_WAVE',
}

type Props = {
	width: number,
	height: number,
	begin: Location,
	end: Location,
	onUpdate: () => void,
	onFinish: () => void,
	type: $Keys<typeof PROJECTILE_TYPE>,
}

export default (React.forwardRef(Projectile): AbstractComponent<
	Props,
	HTMLImageElement | HTMLDivElement
>)

/**
 * The projectile fired by the player. Supports rendering for two different types of projectiles: LASER | RADIO_WAVE.
 * @throws {Error} if the type of projectile is not recognized
 * @param {HTMLImageElement | HTMLDivElement} ref the ref provided by the parent in order to access the projectile location.
 * @param {number} props.width the width of the projectile
 * @param {number} props.height the height of the projectile
 * @param {Location} props.begin the starting location of the projectile
 * @param {Location} props.end the ending location of the projectile
 * @param {() => void} props.onUpdate a callback function that is called when the projectile's position is updated
 * @param {() => void} props.onFinish a callback function that is called when the projectile has reached its destination
 * @param {$Keys<typeof PROJECTILE_TYPE>} props.type the type of projectile: LASER | RADIO_WAVE
 */
function Projectile(
	{ width, height, begin, end, onUpdate, onFinish, type }: Props,
	ref
): React$Node {
	const location = useSpring({
		config: { duration: PROJECTILE_DURATION },
		from: begin,
		to: end,
		onChange: onUpdate,
		onRest: onFinish,
	})
	if (type === PROJECTILE_TYPE.LASER) {
		return (
			<animated.img
				ref={ref}
				className="z-[2] rotate-90 absolute"
				src={laserBeamImgSrc}
				style={{ width: height, height: width, ...location }}
			/>
		)
	}
	if (type === PROJECTILE_TYPE.RADIO_WAVE) {
		return (
			<animated.div
				ref={ref}
				className={`z-[2] absolute text-accent-green rounded before:content-['\\']  p-1 before:w-0 before:h-0
				before:border-[30px] 
				before:border-double 
				before:border-transparent 
				before:border-r-accent-green 
				before:rounded-full 
				before:animate-shooter-game-radio-wave-oscillate
				before:opacity-50 `}
				style={{ width, height, ...location }}
			/>
		)
	} else {
		throw new Error(`Unknown projectile type: ${type}`)
	}
}
