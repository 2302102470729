import React from 'react'
import styled from 'styled-components'

import CollectedData from '../../juniorPlusStations/Sensor/CollectedData'

import { rgba, APP_WHITE } from '../../constants/styles'

export default function SensorData(): React$Node {
	return (
		<SensorSection>
			<Wrapper>
				<CollectedData isCulminatingMoment={true} />
			</Wrapper>
		</SensorSection>
	)
}

const SensorSection: StyledType<> = styled.div`
	width: 50%;
	margin-left: 20px;
`

const Wrapper = styled.div`
	height: 100%;
	padding: 0px 0px 20px 0px;
	overflow-y: scroll;
	overflow-x: hidden;
	border-radius: 3px;
	color: ${APP_WHITE};
	&::-webkit-scrollbar {
		width: 10px;
	}

	&::-webkit-scrollbar-track {
		border-radius: 10px;
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 10px;
		background: ${rgba(APP_WHITE, 0.2)};
	}
`
