import React from 'react'
import radar from '../../../images/radar.png'

export default function Wand({
	disabled,
	start,
}: {
	disabled: boolean,
	start: number,
}): React$Node {
	const degree = start * 360
	if (disabled) {
		return null
	} else {
		return (
			<image
				xlinkHref={radar}
				x={-1}
				y={-1}
				width={2}
				height={2}
				style={{ transform: `rotate(+${90 + degree}deg)` }}
			/>
		)
	}
}
