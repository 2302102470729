import { SVG_DOM_HEIGHT } from '../BaseSVG'

// building block constants
export const SVG_HEIGHT = 50
export const LENGTHS = {
	svgHeight: SVG_HEIGHT,
	halfSvgHeight: SVG_HEIGHT / 2,
	svgDomHeight: SVG_DOM_HEIGHT,
	domToSVGRatio: SVG_HEIGHT / SVG_DOM_HEIGHT,
	svgToDomRatio: SVG_DOM_HEIGHT / SVG_HEIGHT,
	handleLength: 15,
	smallNodeMarkerRadius: 6,
	largeNodeMarkerRadius: 10,
	pathBorderRadius: 5,
	preTitleSpace: 10,
	optionContainerHeight: 75,
}

export const MAX_MULTIPLE_CHOICE_OPTIONS = 4

// ids used to animate components in the options frame
export const IDS: { [string]: string } = {
	// Add animation ids for multiple choice option
	...new Array(MAX_MULTIPLE_CHOICE_OPTIONS).fill(null).reduce((acc, _, i) => {
		if (i === 0) return acc // skip the first option, which is the path from main title
		const leafFromMainId = `animate-4-${i}-leaf-from-main-path`
		const optionContainerId = `animate-4-${i}-option-container`
		const markerId = `animate-4-${i}-marker`
		acc[leafFromMainId] = leafFromMainId
		acc[optionContainerId] = optionContainerId
		acc[markerId] = markerId
		return acc
	}, ({}: { [string]: string })),
	'animate-1-marker': 'animate-1-marker',
	'animate-2-options-path': 'animate-2-options-path',
	'animate-3-options-title': 'animate-3-options-title',
	'animate-4-path-out-from-title': 'animate-4-path-out-from-title',
	'animate-4-marker': 'animate-4-marker',
	'animate-4-option-container': 'animate-4-option-container',
	'animate-5-display-content': 'animate-5-display-content',
}
