// @flow
import React from 'react'
import { getDOMIdFromMapObjectId } from './Map/helpers/functions'

type LineDrawingContextType = {
	connectingIds: {
		startElementId: ?string,
		endElementId: ?string,
	},
	resetConnectingIds?: (
		| { drawLines: false }
		| {
				drawLines: true,
				mapObjectId: string,
				cardId: string,
		  }
	) => void,
}

export const LineDrawingContext: React$Context<LineDrawingContextType> = React.createContext({
	connectingIds: { startElementId: null, endElementId: null },
})

/**
 * Returns the context for line drawing: connecting ids, dom element ids that will connect,
 * a reset function that takes a mapObjectId and a card id.
 * @returns {LineDrawingContextType}
 */
export function useLineDrawingContext(): LineDrawingContextType {
	return React.useContext(LineDrawingContext)
}

/**
 * Provider control state for connecting lines between map objects and map object content.
 * @returns a react node that provides the line drawing context.
 */
export default function LineDrawingContextProvider({
	children,
}: {
	children: React$Node,
}): React$Node {
	const [startElementId, setStartElementId] = React.useState(null)
	const [endElementId, setEndElementId] = React.useState(null)

	const resetConnectingIds = React.useCallback(params => {
		if (params.drawLines === false) {
			setStartElementId(null)
			setEndElementId(null)
		} else {
			setStartElementId(params.cardId)
			setEndElementId(getDOMIdFromMapObjectId(params.mapObjectId))
		}
	}, [])

	return (
		<LineDrawingContext.Provider
			value={{
				connectingIds: {
					startElementId,
					endElementId,
				},
				resetConnectingIds,
			}}>
			{children}
		</LineDrawingContext.Provider>
	)
}

/**
 * Gets the dom id for the card that holds a contextual data
 * @param {CollectedDataForView} object
 * @returns {string}
 */
export const getCardId = (dataId: string): string => {
	return `card-${dataId}`
}
