import type { ReduxStore } from '../../rootReducer'

export type WaitStatus = {
	passed: boolean,
	extra: mixed,
}

export type WaitData = {
	id: string,
	status: WaitStatus[],
}

export function getWaitStatus(store: ReduxStore, waiterId: ?string): ?Array<WaitStatus> {
	if (!waiterId) {
		return null
	}
	let state = store.jrPlusState.waiters
	if (!state[waiterId]) {
		return null
	}
	return state[waiterId].status
}
