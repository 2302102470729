export const JR_PATCH = 'JR_PATCH'
export const JR_PLUS_PATCH = 'JR_PLUS_PATCH'
export const JR_FULL_STATE = 'JR_FULL_STATE'
export const JR_PLUS_FULL_STATE = 'JR_PLUS_FULL_STATE'
export const SET_MISSION_ANALYTICS = 'SET_MISSION_ANALYTICS'

export const SHOW_MODAL = 'SHOW_MODAL'
export const SET_VOLUME = 'SET_VOLUME'
export const RESET_MISSION = 'RESET_MISSION'

export const START_BOOST = 'START_BOOST'
export const SWITCH_STATION = 'SWITCH_STATION'

export const DISPLAY_ERROR = 'DISPLAY_ERROR'

export const STUDENT_DETAILS = 'STUDENT_DETAILS'
export const DID_USE_TOUCH = 'DID_USE_TOUCH'
export const LOGIN_REQUIRED = 'LOGIN_REQUIRED'

export const SET_PLAYER_VOLUME_BY_KEY = 'SET_PLAYER_VOLUME_BY_KEY'
export const SET_OVERALL_PLAYER_VOLUME = 'SET_OVERALL_PLAYER_VOLUME'

export const SET_IS_TEXT_TO_SPEECH_ENABLED = 'SET_IS_TEXT_TO_SPEECH_ENABLED'
export const SET_TEXT_TO_SPEECH_GENDER = 'SET_TEXT_TO_SPEECH_GENDER'
export const SET_TRANSLATION_LANGUAGE = 'SET_TRANSLATION_LANGUAGE'

export const SET_IS_DEBUGGING = 'SET_IS_DEBUGGING'

export const SET_MISSION_SERVER_URL = 'SET_MISSION_SERVER_URL'

// essential data
export const ESSENTIAL_DATA = 'ESSENTIAL_DATA'

// static data
export const STATIC_DATA = 'STATIC_DATA'
export const JUNIOR_PLUS_STATIC_DATA = 'JUNIOR_PLUS_STATIC_DATA'
export const JUNIOR_STATIC_DATA = 'JUNIOR_STATIC_DATA'

// optional data
export const UPDATE_OPTIONAL_DATA = 'UPDATE_OPTIONAL_DATA'

// request handled
export const FINISHED_REQUEST = 'FINISHED_REQUEST'

export const REMOVE_CURRENT_QUESTION = 'REMOVE_CURRENT_QUESTION'
export const SET_CURRENT_QUESTION = 'SET_CURRENT_QUESTION'
export const SET_QUESTION_STATUS = 'SET_QUESTION_STATUS'

// send to owner websockets on mission end
export const MISSION_ENDED = 'MISSION_ENDED'

export type Action = { type: string, payload?: any }
