import React from 'react'
import { animated, useSpring } from '@react-spring/web'
import type { BeamProps as Props } from './upgradeLookup'

const SVG_HEIGHT = 152
const DISTANCE_OF_WIRE_FROM_CENTER = 53.55
export default function Magnet(props: Props): React$Node {
	const { targetLength, onExtended, onRetracted, springConfig, ...otherProps } = props
	const { grow } = useSpring({
		from: { grow: 0 },
		to: {
			grow: targetLength ? targetLength - SVG_HEIGHT - DISTANCE_OF_WIRE_FROM_CENTER : 0,
		},
		config: springConfig,
		onRest: targetLength ? onExtended : onRetracted,
	})
	return (
		<svg {...otherProps} width="52" height="152" viewBox={`0 0 52 ${SVG_HEIGHT}`} fill="none">
			<animated.line
				x1={25.5}
				x2={25.5}
				y1={grow.to(i => 53.5 - i)}
				y2={99}
				stroke="#4F5163"
				strokeWidth="2"
			/>
			<animated.g id="magnet" transform={grow.to(i => `translate(0 ${-i})`)}>
				<path
					d="M0 0H52V7C52 9.76142 49.7614 12 47 12H5C2.23858 12 0 9.76142 0 7V0Z"
					fill="#787B85"
				/>
				<path d="M0 7H52C52 9.76142 49.7614 12 47 12H5C2.23858 12 0 9.76142 0 7Z" fill="#656874" />
				<path
					d="M22.2119 48.151H29.0839V55.384C29.0839 55.663 28.9731 55.9306 28.7758 56.1279C28.5785 56.3252 28.3109 56.436 28.0319 56.436H23.2639C22.9849 56.436 22.7173 56.3252 22.52 56.1279C22.3227 55.9306 22.2119 55.663 22.2119 55.384V48.151Z"
					fill="url(#paint0_radial_1297_2265)"
				/>
				<path
					d="M25.6479 35.723C33.6555 35.723 40.1469 29.2316 40.1469 21.224C40.1469 13.2164 33.6555 6.72498 25.6479 6.72498C17.6403 6.72498 11.1489 13.2164 11.1489 21.224C11.1489 29.2316 17.6403 35.723 25.6479 35.723Z"
					fill="#4A4A5B"
				/>
				<path
					d="M39.7479 16.1609H32.78C31.9721 15.0227 30.9034 14.0945 29.6633 13.454C28.4232 12.8135 27.0477 12.4792 25.652 12.4792C24.2562 12.4792 22.8807 12.8135 21.6406 13.454C20.4005 14.0945 19.3318 15.0227 18.5239 16.1609H11.548C11.1547 16.1609 10.7774 16.3172 10.4993 16.5953C10.2212 16.8734 10.0649 17.2506 10.0649 17.6439V24.8049C10.0649 24.9996 10.1033 25.1924 10.1779 25.3723C10.2524 25.5522 10.3617 25.7156 10.4994 25.8532C10.6371 25.9908 10.8006 26.1 10.9805 26.1744C11.1604 26.2488 11.3533 26.2871 11.548 26.2869H18.5159C19.3235 27.4255 20.3921 28.3541 21.6323 28.9949C22.8724 29.6357 24.248 29.9701 25.6439 29.9701C27.0398 29.9701 28.4155 29.6357 29.6556 28.9949C30.8958 28.3541 31.9644 27.4255 32.772 26.2869H39.7479C39.9426 26.2871 40.1354 26.2488 40.3154 26.1744C40.4953 26.1 40.6588 25.9908 40.7965 25.8532C40.9342 25.7156 41.0435 25.5522 41.118 25.3723C41.1926 25.1924 41.231 24.9996 41.231 24.8049V17.6439C41.231 17.2506 41.0747 16.8734 40.7966 16.5953C40.5185 16.3172 40.1412 16.1609 39.7479 16.1609ZM25.6479 27.0929C24.4875 27.0927 23.3531 26.7484 22.3882 26.1036C21.4234 25.4587 20.6714 24.5422 20.2274 23.47C19.7834 22.3978 19.6673 21.2181 19.8937 20.0799C20.1202 18.9417 20.679 17.8962 21.4996 17.0756C22.3202 16.255 23.3657 15.6962 24.5039 15.4697C25.6421 15.2433 26.8219 15.3594 27.8941 15.8034C28.9663 16.2474 29.8827 16.9994 30.5276 17.9642C31.1724 18.929 31.5168 20.0634 31.517 21.2239C31.517 22.7805 30.8986 24.2733 29.798 25.3739C28.6973 26.4746 27.2045 27.0929 25.6479 27.0929Z"
					fill="url(#paint1_radial_1297_2265)"
				/>
				<path
					opacity="0.25"
					d="M30.8653 12.2633C31.8955 12.8581 33.0913 12.7157 33.5362 11.9452C33.981 11.1746 33.5065 10.0678 32.4763 9.473C31.446 8.87819 30.2502 9.02063 29.8053 9.79116C29.3605 10.5617 29.835 11.6685 30.8653 12.2633Z"
					fill="white"
				/>
				<path
					d="M40.9808 30.011L39.9578 28.9879C39.8001 28.8307 39.5866 28.7423 39.3638 28.7419H35.5418C34.3829 30.2678 32.8868 31.505 31.1704 32.3568C29.454 33.2085 27.5639 33.6517 25.6478 33.6517C23.7317 33.6517 21.8416 33.2085 20.1252 32.3568C18.4089 31.505 16.9127 30.2678 15.7538 28.7419H11.9318C11.7091 28.7423 11.4956 28.8307 11.3378 28.9879L10.3148 30.011C10.1578 30.1688 10.0694 30.3823 10.0688 30.6049V39.731C10.0692 39.9537 10.1576 40.1672 10.3148 40.325L20.4288 50.4309C20.5067 50.5091 20.5994 50.571 20.7013 50.6133C20.8032 50.6555 20.9125 50.6771 21.0228 50.6769H30.2728C30.3832 50.6771 30.4924 50.6555 30.5944 50.6133C30.6963 50.571 30.7889 50.5091 30.8668 50.4309L40.9808 40.318C41.1381 40.1602 41.2265 39.9467 41.2268 39.7239V30.5999C41.225 30.379 41.1367 30.1676 40.9808 30.011Z"
					fill="url(#paint2_linear_1297_2265)"
				/>
				<path
					d="M30 1.5V5.5"
					stroke="white"
					strokeOpacity="0.5"
					strokeWidth="2"
					strokeLinecap="round"
				/>
			</animated.g>
			<path
				d="M44.648 105.044L40.348 100.777H10.948L6.648 105.044C6.608 110.793 6.84101 121.204 8.73901 129.007C10.6745 136.338 13.4559 143.419 17.027 150.107C17.1623 150.361 17.364 150.573 17.6107 150.721C17.8573 150.868 18.1395 150.946 18.427 150.946H32.867C33.1545 150.946 33.4368 150.868 33.6834 150.721C33.93 150.573 34.1318 150.361 34.267 150.107C37.8382 143.419 40.6195 136.338 42.555 129.007C44.455 121.204 44.688 110.793 44.648 105.044Z"
				fill="url(#paint3_linear_1297_2265)"
			/>
			<path
				d="M13.406 100.777H37.89L33.18 96.1229C32.9139 95.8596 32.5544 95.7122 32.18 95.7129H19.11C18.7356 95.7122 18.3761 95.8596 18.11 96.1229L13.406 100.777Z"
				fill="url(#paint4_radial_1297_2265)"
			/>
			<path
				opacity="0.18"
				d="M7.23608 116.984L13.8041 123.552C14.2699 128.426 15.109 133.256 16.3141 138.002C18.5001 146.517 20.8021 150.449 20.8021 150.449"
				stroke="white"
				strokeWidth="0.762"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M7.23608 115.68L14.782 123.226C15.2479 128.1 16.087 132.931 17.2921 137.676C18.3399 142.138 19.857 146.478 21.8181 150.621"
				stroke="#1B1B24"
				strokeWidth="0.762"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				opacity="0.2"
				d="M7.23608 114.54L15.1991 122.503C15.6649 127.376 16.504 132.207 17.709 136.952C18.8564 141.681 20.5309 146.266 22.701 150.621"
				stroke="#1B1B24"
				strokeWidth="0.762"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				opacity="0.2"
				d="M7.98702 103.715L10.097 101.621C10.6422 101.08 11.3791 100.777 12.1471 100.777H39.1471C39.915 100.777 40.6518 101.08 41.197 101.621L43.307 103.715C43.4164 103.823 43.4912 103.962 43.5217 104.113C43.5523 104.264 43.5373 104.42 43.4787 104.563C43.4201 104.705 43.3205 104.827 43.1925 104.912C43.0646 104.998 42.914 105.044 42.76 105.044H8.53401C8.38001 105.044 8.22948 104.998 8.10152 104.912C7.97356 104.827 7.87394 104.705 7.81532 104.563C7.75671 104.42 7.74173 104.264 7.77229 104.113C7.80286 103.962 7.87761 103.823 7.98702 103.715Z"
				fill="white"
			/>
			<path
				opacity="0.2"
				d="M28.195 112.401H23.101C21.8777 112.401 20.886 113.393 20.886 114.616V116.743C20.886 117.966 21.8777 118.958 23.101 118.958H28.195C29.4183 118.958 30.41 117.966 30.41 116.743V114.616C30.41 113.393 29.4183 112.401 28.195 112.401Z"
				fill="url(#paint5_linear_1297_2265)"
			/>
			<path
				d="M27.5749 113.378H23.7209C22.9389 113.378 22.3049 114.012 22.3049 114.794V116.565C22.3049 117.347 22.9389 117.981 23.7209 117.981H27.5749C28.357 117.981 28.9909 117.347 28.9909 116.565V114.794C28.9909 114.012 28.357 113.378 27.5749 113.378Z"
				fill="url(#paint6_radial_1297_2265)"
			/>
			<path
				opacity="0.2"
				d="M28.195 122.067H23.101C21.8777 122.067 20.886 123.059 20.886 124.282V126.409C20.886 127.632 21.8777 128.624 23.101 128.624H28.195C29.4183 128.624 30.41 127.632 30.41 126.409V124.282C30.41 123.059 29.4183 122.067 28.195 122.067Z"
				fill="url(#paint7_linear_1297_2265)"
			/>
			<path
				d="M27.5749 123.044H23.7209C22.9389 123.044 22.3049 123.678 22.3049 124.46V126.231C22.3049 127.013 22.9389 127.647 23.7209 127.647H27.5749C28.357 127.647 28.9909 127.013 28.9909 126.231V124.46C28.9909 123.678 28.357 123.044 27.5749 123.044Z"
				fill="url(#paint8_radial_1297_2265)"
			/>

			<defs>
				<radialGradient
					id="paint0_radial_1297_2265"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(77.0974 69.5512) scale(11.3623 11.3587)">
					<stop stopColor="#3B384C" />
					<stop offset="0.413" stopColor="#2F2D3F" />
					<stop offset="1" stopColor="#1A1A26" />
				</radialGradient>
				<radialGradient
					id="paint1_radial_1297_2265"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(-395.573 21.2247) scale(12.6235 12.6284)">
					<stop stopColor="#3B384C" />
					<stop offset="0.413" stopColor="#2F2D3F" />
					<stop offset="1" stopColor="#1A1A26" />
				</radialGradient>
				<linearGradient
					id="paint2_linear_1297_2265"
					x1="10.0688"
					y1="39.7094"
					x2="41.2268"
					y2="39.7094"
					gradientUnits="userSpaceOnUse">
					<stop stopColor="#5E5E78" />
					<stop offset="0.763" stopColor="#74748B" />
					<stop offset="1" stopColor="#7D7D93" />
				</linearGradient>
				<linearGradient
					id="paint3_linear_1297_2265"
					x1="25.648"
					y1="145.126"
					x2="25.648"
					y2="106.897"
					gradientUnits="userSpaceOnUse">
					<stop stopColor="#5E5E78" />
					<stop offset="0.763" stopColor="#74748B" />
					<stop offset="1" stopColor="#7D7D93" />
				</linearGradient>
				<radialGradient
					id="paint4_radial_1297_2265"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(-1024.89 9.12923) rotate(180) scale(8.93015 7.37917)">
					<stop stopColor="#3B384C" />
					<stop offset="0.413" stopColor="#2F2D3F" />
					<stop offset="1" stopColor="#1A1A26" />
				</radialGradient>
				<linearGradient
					id="paint5_linear_1297_2265"
					x1="25.648"
					y1="118.46"
					x2="25.648"
					y2="115.22"
					gradientUnits="userSpaceOnUse">
					<stop stopColor="#CCCCCC" />
					<stop offset="0.119" stopColor="#BFBFC0" />
					<stop offset="0.339" stopColor="#9D9DA0" />
					<stop offset="0.634" stopColor="#66666D" />
					<stop offset="0.991" stopColor="#1B1B27" />
					<stop offset="1" stopColor="#1A1A26" />
				</linearGradient>
				<radialGradient
					id="paint6_radial_1297_2265"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(38.1698 100.048) scale(4.59997 4.603)">
					<stop stopColor="#3B384C" />
					<stop offset="0.413" stopColor="#2F2D3F" />
					<stop offset="1" stopColor="#1A1A26" />
				</radialGradient>
				<linearGradient
					id="paint7_linear_1297_2265"
					x1="25.648"
					y1="128.126"
					x2="25.648"
					y2="124.886"
					gradientUnits="userSpaceOnUse">
					<stop stopColor="#CCCCCC" />
					<stop offset="0.119" stopColor="#BFBFC0" />
					<stop offset="0.339" stopColor="#9D9DA0" />
					<stop offset="0.634" stopColor="#66666D" />
					<stop offset="0.991" stopColor="#1B1B27" />
					<stop offset="1" stopColor="#1A1A26" />
				</linearGradient>
				<radialGradient
					id="paint8_radial_1297_2265"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(38.1698 109.714) scale(4.59997 4.603)">
					<stop stopColor="#3B384C" />
					<stop offset="0.413" stopColor="#2F2D3F" />
					<stop offset="1" stopColor="#1A1A26" />
				</radialGradient>
			</defs>
		</svg>
	)
}
