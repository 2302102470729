import React from 'react'
import styled from 'styled-components'
import type { Props } from '../helpers'
import { FilledPath, BackgroundCircle, FilledCircle, FilledEllipse } from '../helpers'

const Ellipse = styled(FilledEllipse)`
	stroke-miterlimit: 10;
	stroke-width: 2px;
`

export default function CommunicationIcon({
	iconOnly,
	transitionPattern = {},
	isOpen = true,
	...props
}: Props): React$Node {
	return (
		<svg
			{...props}
			id="communicationJrPlusPanel"
			data-name="Communications JrPlus Panel"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 70 70">
			<g
				style={{
					...transitionPattern,
					transform: `${isOpen ? 'scale(1)' : 'scale(0)'}`,
					transformOrigin: '50% 50%',
				}}>
				{!iconOnly && <BackgroundCircle cx="35" cy="35" r="35" />}
				<FilledPath
					d="M52.88,58S49,75.46,58.7,85.16,83,88.07,83,88.07Z"
					transform="translate(-37 -37)"
					iconOnly={iconOnly}
					fill="#49ff40"
				/>
				<Ellipse
					cx="68.5"
					cy="72.5"
					rx="3.54"
					ry="21.72"
					transform="translate(-68.2 32.67) rotate(-45)"
					iconOnly={iconOnly}
					stroke="#49ff40"
				/>
				<FilledPath
					d="M82.38,56.92c.12.35-15.14,12.34-15.14,12.34a3.85,3.85,0,0,0,4.36,4.36l12-15.26Z"
					transform="translate(-37 -37)"
					iconOnly={iconOnly}
					fill="#49ff40"
				/>
				<FilledCircle fill="#49ff40" cx="46.59" cy="20.27" r="2.18" iconOnly={iconOnly} />
			</g>
		</svg>
	)
}
