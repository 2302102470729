// @flow
import React, { useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import TractorBeam from './TractorBeam'
import Vehicle, { LEFT_CROP_AMOUNT as VEHICLE_CROP } from '../../components/VehicleImage'
import Target, { TARGET_STATE_TYPE } from './Target'
import { sendMessage } from '../../store/stores/webSocket'
import { getStudentId } from '../../store/stores/general'
import { STATUS_TO_IMAGE } from '../../utility/targets'
import { getTractorBeamGroupHits as getGroupHits } from '../../store/selectors/jrState'
import { useDimensions, useImageWidth } from '../../utility/hooks.jsx'
import { getCollision } from '../../juniorPlusStations/Defense/components/helpers'
import { doesCollide } from '../../juniorPlusStations/Communication/collision'

const RIGHT_OFFSET = 150
const TARGET_DRIFT = 150
const TARGET_DRIFT_SPEED = 3111

const BEAM_EXTENDED_HEIGHT = 150

const BEAM_STATE_TYPE = {
	retracting: 'retracting',
	expanding: 'expanding',
}

/**
 * The K-3 Tractor Beam Station. Students must use the tractor beam to capture the target.
 */
export default function TractorBeamGame(): React$Node {
	const dispatch = useDispatch()
	const { status, myHits, groupHits } = useSelector(state => {
		const studentId = getStudentId(state.general)
		const { status, studentHits } = state.jrState.stationData.tractorBeam
		const groupHits = getGroupHits(state)

		const myHits = studentId && studentHits.hasOwnProperty(studentId) ? studentHits[studentId] : 0

		return {
			status,
			myHits,
			groupHits,
		}
	})
	const targetRef = useRef<?HTMLImageElement>()
	const beamRef = useRef()
	const [vehicleRef, vehicleWidth] = useImageWidth()
	const [canvasRef, { width: canvasWidth, height: canvasHeight }] = useDimensions()
	const [beamState, setBeamState] = useState<?$Keys<typeof BEAM_STATE_TYPE>>(null)
	const [targetState, setTargetState] = useState<$Keys<typeof TARGET_STATE_TYPE> | null>(null)

	/**
	 * Handles any click event within the station. If the station is online and the beam is not already active, the beam will be activated.
	 */
	const handleCanvasClick = () => {
		if (status !== 'OFFLINE' && !beamState && targetState !== TARGET_STATE_TYPE.disappearing) {
			setBeamState(BEAM_STATE_TYPE.expanding)
		}
	}
	/**
	 * Checks for a collision between the beam and the target. If a collision is detected, the target state is updated to frozen.
	 * This function should be called every time the beam position is updated.
	 */
	const handleBeamPositionUpdate = () => {
		if (beamState == null) {
			return
		}

		if (!beamRef.current) {
			return
		}
		const beam = getCollision(beamRef.current)
		if (!targetRef.current) {
			return
		}
		const target = getCollision(targetRef.current)

		if (beamState !== BEAM_STATE_TYPE.retracting && doesCollide(beam, target)) {
			setTargetState(TARGET_STATE_TYPE.frozen)
		}
	}
	/**
	 * When the beam is retracted, the target will be updated to retracting if it is currently frozen.
	 * If the target is not frozen, a message is sent to the server indicating that the beam did not hit the target
	 */
	const handleBeamRetracting = () => {
		setBeamState(BEAM_STATE_TYPE.retracting)

		if (targetState === TARGET_STATE_TYPE.frozen) {
			setTargetState(TARGET_STATE_TYPE.retracting)
		} else {
			dispatch(sendMessage('TRACTOR_BEAM', { hit: false }))
		}
	}
	/**
	 * A function when the beam is finished retracting. If the target is retracting, the target will be updated to disappearing.
	 */
	const handleBeamRetracted = () => {
		if (targetState === TARGET_STATE_TYPE.retracting) {
			setTargetState(TARGET_STATE_TYPE.disappearing)
		}
		setBeamState(null)
	}
	/**
	 * When the target has officially finished its disappearing animation, the target state is set to null.
	 */
	const handleTargetDisappeared = () => {
		const vehicleRect = vehicleRef.current?.getBoundingClientRect()
		let pointLocation = { x: 0, y: 0 }
		if (vehicleRect) {
			pointLocation.x = (vehicleRect.left || 0) + vehicleRect.width + 10
			pointLocation.y = (vehicleRect.top || 0) + vehicleRect.height / 2
		}
		dispatch(sendMessage('TRACTOR_BEAM', { hit: true }, { location: pointLocation }))
		setTargetState(null)
	}
	/**
	 * A function to generate the target's retracting location. This function is used to determine where the target should move to when the beam is retracting.
	 */
	const generateTargetRetracting = React.useCallback(() => {
		const vehicleRect = vehicleRef.current?.getBoundingClientRect()
		const targetRect = targetRef.current?.getBoundingClientRect()
		if (!vehicleRect || !canvasWidth || !targetRect) {
			return {
				top: (canvasHeight || 0) / 2,
				right: canvasWidth || 0,
			}
		}

		const targetHeight = targetRect.height
		const vehicleCenterY = vehicleRect.top + vehicleRect.height / 2

		return {
			top: vehicleCenterY - targetHeight / 2,
			right: canvasWidth - vehicleRect.width + targetRect.width / 2,
		}
	}, [canvasWidth, canvasHeight, vehicleRef])

	return (
		<div className="TractorBeam relative w-full h-full">
			<div
				ref={canvasRef}
				className="w-full h-full flex items-center relative "
				onClick={handleCanvasClick}>
				<Vehicle ref={vehicleRef} className={`max-h-[80%]`} />

				{canvasWidth && Boolean(beamState) && (
					<TractorBeam
						beamHeight={BEAM_EXTENDED_HEIGHT}
						beamWidth={canvasWidth - (vehicleWidth - VEHICLE_CROP)}
						ref={beamRef}
						beam={Boolean(beamState)}
						retractingTarget={
							targetState === TARGET_STATE_TYPE.retracting ||
							targetState === TARGET_STATE_TYPE.frozen
						}
						onPositionUpdate={handleBeamPositionUpdate}
						onBeamRetracting={handleBeamRetracting}
						onBeamRetracted={handleBeamRetracted}
					/>
				)}

				{canvasHeight && status !== 'OFFLINE' && (
					<Target
						tractorBeamStatus={status}
						ref={targetRef}
						image={STATUS_TO_IMAGE[status] || STATUS_TO_IMAGE.ASTEROID}
						right={RIGHT_OFFSET}
						drift={TARGET_DRIFT}
						driftSpeed={TARGET_DRIFT_SPEED}
						containerHeight={canvasHeight}
						targetState={targetState}
						getRetractingLocation={generateTargetRetracting}
						onDisappeared={handleTargetDisappeared}
					/>
				)}
			</div>
			<div className="absolute top-10 right-12 text-primary-200 text-left text-lg">
				<div>Your Hits: {myHits}</div>
				<div>Team Hits: {groupHits}</div>
			</div>
		</div>
	)
}
