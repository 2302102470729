import { useContext } from 'react'
import { ShipDimensionContext, type ShipDimensionData } from './context'

/**
 * useTranslateToSvgCoordinates - scales the screen coordinates into the svg coordinates (does not account for rotation of the svg)
 *
 * @param  {number} screenX - the screen coordinate (horizontal) to translate into the map coordinate
 * @param  {number} screenY - the screen coordinate (vertical) to translate into the map coordinate
 * @returns {{
 *	 x: number, the screenX coordinate in the screenOverview svg x coordinate,
 *	 y: number, the screenY coordinate in the screenOverview svg y coordinate,
 *}}
 */
export function useTranslateToSvgCoordinates(
	screenX: number,
	screenY: number
): { x: number, y: number } {
	const shipDimensions = useContext(ShipDimensionContext)
	return {
		x: (screenX * shipDimensions.svgWidth) / shipDimensions.screenWidth,
		y: (screenY * shipDimensions.svgHeight) / shipDimensions.screenHeight,
	}
}

/**
 * Gets the relative DOM position values for the ship svg path. These position values are used
 * to build a div overlay over the ship to draw any alerts that should appear.
 * @returns {top:number, left: number, width: number, height: number}
 */
export function useShipScreenSize(): {
	top: number,
	left: number,
	width: number,
	height: number,
} {
	const context = useContext(ShipDimensionContext)
	const sizeFactor = getShipSizeFactor(context)
	const ship = {}
	ship.width = sizeFactor.factor * context.svgWidth
	ship.height = sizeFactor.factor * context.svgHeight
	if (sizeFactor.type === 'y') {
		// height is centered in the container
		const centerScreen = context.screenWidth / 2
		const centerSvg = ship.width / 2
		ship.top = 0
		ship.left = centerScreen - centerSvg
	} else {
		// width needs to be centered in the container
		const centerScreen = context.screenHeight / 2
		const centerSvg = ship.height / 2
		ship.left = 0
		ship.top = centerScreen - centerSvg
	}
	return ship
}

/**
 * Gets the factor by which svg coordinates should be multiplied in order to translate an [x,y] point from the svg
 * plane to the relative dom plane that the svg lives in.
 * @param {ShipDimensionData} context
 * @returns {type: 'x' | 'y', factor: number} the factor value (a number) and whether its based off of the x or y axis of the ship svg
 */
function getShipSizeFactor(context: ShipDimensionData): { type: 'y' | 'x', factor: number } {
	const { screenWidth, screenHeight, svgWidth, svgHeight } = context
	const heightScale = screenHeight / svgHeight
	const widthScale = screenWidth / svgWidth
	return heightScale < widthScale
		? { type: 'y', factor: heightScale }
		: { type: 'x', factor: widthScale }
}

/**
 * Translates an array of values from the svg plane to the screen.
 * @param {number[]} values
 * @returns {number[]} a mapping of the provided values.
 */
export function useScreenScaledValues(values: number[]): number[] {
	const { factor } = getShipSizeFactor(useContext(ShipDimensionContext))
	return values.map(value => value * factor)
}
