import React, { forwardRef, type AbstractComponent } from 'react'
import styled from 'styled-components/macro'
import shipPath from './assets/ShipSilhouette'

export type Cell = {
	id: string,
	x: number,
	y: number,
	height: number,
	width: number,
}

type Props = {
	children?: React$Node,
	onClickCell: (e: MouseEvent, id: Cell) => void,
}

export const CANVAS_HEIGHT = 1080
export const CANVAS_WIDTH = 1920

/**
 * The grid for the tractor beam station, each grid cell is a square and assign the very center
 * of the grid a ref so that other components can determine proper projectile angles from the
 * center of the grid. Children of the grid will need to have coordinates relative to the grid's view box, 1920x1080
 * @param {Props} props
 * @param {React$Ref<Element>} centerRef
 */
function Grid({ onClickCell, children, ...props }: Props, centerRef): React$Node {
	return (
		<svg {...props} viewBox={`0 0 ${CANVAS_WIDTH} ${CANVAS_HEIGHT}`} fill="none">
			<circle
				cx={CANVAS_WIDTH / 2}
				cy={CANVAS_HEIGHT / 2}
				r="1"
				fill="transparent"
				ref={centerRef}
			/>

			<Filler onClickCell={onClickCell} />

			<g fill="none">
				<path transform="translate(878 427)" opacity="0.5" d={shipPath} fill="#364984" />
			</g>
			<g id="tractorBeam">{children}</g>
		</svg>
	)
}

/**
 * Drawing this component takes up time which we can't afford on every animation
 * for the tractor beam station. Here we separate the grid into its own component and memoize it.
 * Builds the rows, columns and each individual cell on the grid (including corner styles and hover styles for each cell)
 */
const Filler = React.memo(function MemoizedFiller({
	onClickCell,
}: {
	onClickCell: (e: MouseEvent, Cell) => void,
}) {
	return (
		<g>
			<g id="grid-rows" opacity="0.2">
				{ROWS.map(row => (
					<path key={row.id} {...row} />
				))}
			</g>
			<g id="grid-columns" opacity="0.2">
				{COLUMNS.map(col => (
					<path key={col.id} {...col} />
				))}
			</g>
			<g id="grid-squares">
				{SQUARES.map(square => (
					<HoverBox key={square.id} onClick={e => onClickCell(e, square)}>
						<rect {...square} opacity="0.2" />
						<text
							x={square.x + 5}
							y={square.y - 5 + 60}
							fontFamily="Orbitron, sans-serif"
							fontSize="14"
							fill="transparent">
							{square.id}
						</text>
					</HoverBox>
				))}
			</g>
			<g id="grid-corners" opacity="0.2">
				{CORNERS.map(corner => (
					<path key={corner.id} {...corner} />
				))}
			</g>
		</g>
	)
})

export default (forwardRef(Grid): AbstractComponent<Props, Element>)

const HoverBox = styled.g`
	&:hover text {
		fill: white;
	}
	&:hover rect {
		fill: #00ffff;
	}
	transition: fill 200ms ease;
	cursor: pointer;
`

export const CELL_SPACE = 60
const TOTAL_ROWS = 19
const CENTER_ROW = (TOTAL_ROWS - 1) / 2

const TOTAL_COLUMNS = 33
const CENTER_COLUMN = (TOTAL_COLUMNS - 1) / 2

const CENTRAL_STROKE_WIDTH = 3
const CORNERS = []
const SQUARES = []

const ROWS = new Array(TOTAL_ROWS).fill({}).map((_, index) => {
	const properties = {}
	let d = `M0 ${index * CELL_SPACE}H${CANVAS_WIDTH}`
	if (index === CENTER_ROW) {
		properties.strokeWidth = CENTRAL_STROKE_WIDTH
	}

	properties.id = `row[${CENTER_ROW - index}]`
	properties.d = d
	properties.stroke = '#00FFFF'
	properties.strokeMiterlimit = '10'

	const y = index * CELL_SPACE
	const y1 = index === 0 ? 0 : y - 3.75
	const y2 = index === 0 ? 0 : y + 3.75
	for (let col = 0; col < TOTAL_COLUMNS; col += 1) {
		const x = col * CELL_SPACE
		const x1 = col === TOTAL_COLUMNS - 1 ? x : x + 3.75
		const x2 = col === 0 ? 0 : x - 3.75

		CORNERS.push({
			d: `M${x1} ${y1}H${x2}V${y2}H${x1}V${y1}Z`,
			id: `corner[${CENTER_ROW - index}][${col - CENTER_COLUMN}]`,
			fill: '#00FFFF',
		})

		const coordinateX = col - CENTER_COLUMN + 1
		const coordinateY = CENTER_ROW - index
		SQUARES.push({
			x,
			y,
			width: 60,
			height: 60,
			id: `[${coordinateX <= 0 ? coordinateX - 1 : coordinateX}, ${
				coordinateY <= 0 ? coordinateY - 1 : coordinateY
			}]`,
			fill: 'transparent',
		})
	}

	return properties
})

const COLUMNS = new Array(TOTAL_COLUMNS).fill({}).map((_, index) => {
	const properties = {}
	if (index === CENTER_COLUMN) {
		properties.strokeWidth = CENTRAL_STROKE_WIDTH
	}
	properties.id = `col[${index - CENTER_COLUMN}]`
	properties.d = `M${index * CELL_SPACE} 0V${CANVAS_HEIGHT}`
	properties.stroke = '#00FFFF'
	properties.strokeMiterlimit = '10'
	return properties
})
