import React, { Component } from 'react'
import { DragLayer } from 'react-dnd'
import type { Point } from '../types'

type Line<T> = [T, Point]

type LineDragLayerProps<T> = {
	start: ?T,
	renderLine: (Line<T>) => React$Node,
}

type ReactDndProps = {
	currentOffset: ?{
		x: number,
		y: number,
	},
}

type Props<T> = LineDragLayerProps<T> & ReactDndProps

/**
 * Display a line based on the current drag state. This line will go from the
 * passed in start, and the currentOffset (current drag location)
 */
class LineDragLayer<T> extends Component<Props<T>> {
	render() {
		return <svg>{this.getCurrentDragLine()}</svg>
	}

	getCurrentDragLine() {
		const { start, currentOffset, renderLine } = this.props

		if (!start || !currentOffset) {
			return null
		}

		return renderLine([start, [currentOffset.x, currentOffset.y]])
	}
}

function collect(monitor): ReactDndProps {
	return {
		currentOffset: monitor.getClientOffset(),
	}
}

// eslint-disable-next-line no-undef
export default (DragLayer(collect)(LineDragLayer): <T>(props: LineDragLayerProps<T>) => React$Node)
