// @flow
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { get } from 'axios'
import styled from 'styled-components/macro'
import { useStudentsQuery, Input } from './shared'
import config from '../../config'
import StudentSelect from './StudentSelect'
import { Loading } from '../basics'
import { setMissionServerUrl, getMissionServerUrl } from '../../store/stores/general'
import {
	connectToMission,
	getMissionCodeFromUrl,
	useMissionServerUrlFetcher,
	type FetchError,
} from '../AppSetup'
import {
	setWebSocketUrl,
	getMissionServerWebsocketAddress,
	getMissionServerHttpAddress,
} from '../../store/stores/webSocket'
import { isBoolean, isPlainObject, isString } from 'lodash'
import { getLoginError } from '../../store/selectors/sharedSelectors'
import { LandingPageWrapper } from '../LandingPageWrapper'
import { Button } from '../basics/Buttons.jsx'

/**
 * The login page.
 * @returns {React$Node}
 */
export default function Login(): React$Node {
	useEffect(() => {
		document.title = `${config.companyName}: Join Mission`
	}, [])

	return (
		<LandingPageWrapper>
			<LoginContent />
		</LandingPageWrapper>
	)
}

const LOGIN_ERROR_STORAGE_KEY = 'LOGIN_ERROR_STORAGE_KEY'
const LOGIN_CODE_STORAGE_KEY = 'LOGIN_CODE_STORAGE_KEY'

const urlMissionCode = getMissionCodeFromUrl()

/**
 * getFromSessionStorage - get the value associated with the key from sessionStorage or return null if the value does not exist
 *
 * @param  {string} key - the key of the value to get from session storage
 *
 * @returns ?string - the value associated with the key from session storage or null if an error occurred
 */
function getFromSessionStorage(key: string): ?string {
	try {
		return window.sessionStorage.getItem(key)
	} catch (error) {
		console.error(`Error reading session storage item "${key}"`, error)
	}
	return null
}

/**
 * Content for the login page to display, either a loading component, a login screen, or a student selector depending on the url path name
 * and the state of the current session.
 * @returns {React$Node}
 */
function LoginContent(): React$Node {
	// Fetches the mission server url so we can check if there is a current session.
	const sessionError = getFromSessionStorage(LOGIN_ERROR_STORAGE_KEY)
	const missionServerUrl = useSelector(getMissionServerUrl)
	const dispatch = useDispatch()
	const missionCodeInput = useRef()
	const previousMissionData = usePreviousMissionData()
	const [previousMissionServerData, setPreviousMissionServerData] = useState(null)
	const [error, _setError] = useState(sessionError)
	const [missionCode, setMissionCode] = useState(
		urlMissionCode || previousMissionData?.missionCode || ''
	)
	const [codeIsSubmitted, setCodeIsSubmitted] = useState(false)
	const loginError = useSelector(getLoginError)

	// Returns to the main login screen
	const goToMainLogin = useCallback(
		(errorMessage?: string) => {
			if (urlMissionCode) {
				if (errorMessage) {
					window.sessionStorage.setItem(LOGIN_ERROR_STORAGE_KEY, errorMessage)
					if (missionCode) window.sessionStorage.setItem(LOGIN_CODE_STORAGE_KEY, missionCode)
				}

				if (window.location.pathname !== '/') {
					window.location.href = '/'
				}
			} else if (codeIsSubmitted) {
				setCodeIsSubmitted(false)
			}
		},
		[codeIsSubmitted, missionCode]
	)

	// Sets an error that is displayed in the mission code input form
	const setError = useCallback(
		(error: ?(FetchError | string)) => {
			let errorMessage

			if (!error) {
				_setError(null)
				window.sessionStorage.removeItem(LOGIN_CODE_STORAGE_KEY)
				window.sessionStorage.removeItem(LOGIN_ERROR_STORAGE_KEY)
				return
			}
			if (typeof error === 'string') {
				errorMessage = error
			}
			// Check for axios error
			// $FlowFixMe this is what an axios error looks like
			else if (error.response?.data?.message) {
				errorMessage = error.response.data.message
			} else {
				errorMessage = 'Uh oh! Something went wrong'
			}
			_setError(errorMessage)
			goToMainLogin(errorMessage)
		},
		[goToMainLogin]
	)

	const { students, loading, fetch } = useStudentsQuery(missionServerUrl, { onError: setError })

	// Focus the cursor on the mission code input
	useEffect(() => {
		if (missionCodeInput.current) {
			missionCodeInput.current.focus()
		}
	}, [])

	// Get the current mission server url if there is one connected to the current session or mission code.
	useMissionServerUrlFetcher({
		onError: ({ error }) => {
			if (urlMissionCode) {
				setError(error)
			}
		},
		clearError: () => {
			setError(null)
		},
		missionCode: codeIsSubmitted || urlMissionCode ? missionCode : previousMissionData?.missionCode,
	})

	// Feature: Allow user to reconnect to mission, gets current session and adds button above login for student to reconnect.
	useEffect(() => {
		if (!previousMissionData?.missionServerUrl || !previousMissionData?.missionCode) {
			return
		}

		get(`${previousMissionData.missionServerUrl}/api/connect/${previousMissionData.missionCode}`, {
			withCredentials: true,
		})
			.then(res => {
				if (res.data !== null) {
					setPreviousMissionServerData(res.data)
				}
			})
			.catch(err => {
				console.error(err)
			})
	}, [previousMissionData])

	// Connects to a mission after user enters a mission code
	const onConnect: (e: SyntheticInputEvent<>) => Promise<void> = async (
		e: SyntheticInputEvent<>
	) => {
		let serverUrl = missionServerUrl
		e.preventDefault()
		// Scroll to top of page for tablet user experience. When tablet input is focused,
		// the keyboard takes up space, and the browser scrolls content up, messing with screen height.
		window.scrollTo(0, 0)
		if (!missionCode) {
			setError(`Please type in a mission code`)
			return
		}

		if (error) {
			setError(null)
		}

		// We are displaying an uppercase code to user: make sure values match what the user sees
		const upperCaseMissionCode = missionCode.toUpperCase()
		const lowerCaseMissionCode = missionCode.toLowerCase()
		if (config.useMatchmaker) {
			try {
				const matchmakerRes = await get(`${config.matchmakerUrl}/connect`, {
					params: { missionCode: lowerCaseMissionCode },
				})
				if (matchmakerRes.status === 200) {
					serverUrl = getMissionServerHttpAddress(matchmakerRes.data.missionServerUrl)
					dispatch(setMissionServerUrl(serverUrl))
					dispatch(
						setWebSocketUrl(
							getMissionServerWebsocketAddress(matchmakerRes.data.missionServerUrl),
							missionCode,
							serverUrl
						)
					)
				} else {
					_setError(`Could not find a mission with the code '${upperCaseMissionCode}'`)
					return
				}
			} catch (err) {
				setError(err)
				return
			}
		}

		fetch(serverUrl, missionCode)
		setCodeIsSubmitted(true)
	}
	// Updates the mission code value when the mission code input changes.
	const onUpdateMissionCode: (e: SyntheticInputEvent<>) => void = e => {
		const value = e.target.value
		if (!/^[a-zA-Z0-9]*$/.test(value)) {
			return
		}
		setCodeIsSubmitted(false)
		setMissionCode(value)
		setError(null)
	}

	if (loginError) {
		return (
			<Container>
				<div className="section flex flex-col gap-2">
					<ErrorMessage>This user is not allowed to join the mission as a teacher.</ErrorMessage>
					<Button variant="danger" as="a" href={loginError.redirectUrl}>
						Login As A Different User
					</Button>
					<Button variant="danger" as="a" href="/">
						Join A Different Mission
					</Button>
				</div>
			</Container>
		)
	}

	if (urlMissionCode || (codeIsSubmitted && !loading && !error)) {
		return (
			<StudentSelect
				missionCode={missionCode}
				goBack={() => {
					setMissionCode('')
					goToMainLogin()
				}}
				onError={setError}
				initialStudents={students}
			/>
		)
	}
	return (
		<Container className="h-full justify-center">
			<form className="section space-y-3" onSubmit={onConnect}>
				<h1>Join Mission</h1>
				{previousMissionServerData && previousMissionData && (
					<p className="text-lg leading-6">
						Looks like you are connected to the mission &quot;
						{previousMissionServerData.simulationName}
						&quot;. Would you like to{' '}
						<Button
							className="inline-block tracking-widest !p-0 text-sm underline-offset-2"
							$variant="link"
							type="button"
							onClick={() => {
								connectToMission(previousMissionData.missionCode, previousMissionData.studentId)
							}}>
							Reconnect
						</Button>
						?
					</p>
				)}
				{error && <div className="text-xl text-error mb-2">{error}.</div>}
				<Input
					className="!mt-6"
					inputClassName="uppercase"
					id="missionCode"
					type="text"
					placeholder=" "
					value={missionCode}
					onChange={onUpdateMissionCode}
					ref={missionCodeInput}
					label="Mission Code"
					size="large"
					autoComplete="off"
				/>
				{loading && <Loading className="size-52" />}
				<div className="flex justify-center">
					<Button type="submit" disabled={loading} $small>
						Join
					</Button>
				</div>
			</form>
		</Container>
	)
}

/**
 * Gets a css gradient with the given direction in degrees. Examples of directions:
 * 90, 180, 270
 * @param {number} direction
 */
const gradientByDirection = direction => {
	return `linear-gradient(${direction}deg, rgb(64, 53, 127) 0%, rgb(23, 66, 95) 100%, rgba(5, 13, 19, 0) 100%) 0% 0% no-repeat padding-box padding-box`
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	.section {
		background: ${gradientByDirection(180)};
		width: 90%;
		max-width: 400px;
		padding: 24px;
		border-radius: 4px;

		h1 {
			font-size: 1.8rem;
			text-align: center;
		}
	}
`

const ErrorMessage = styled.div`
	color: ${({ theme }) => theme.error};
`

type MissionJoinData = {
	missionCode: string,
	missionServerUrl: string,
	isTeacher?: boolean,
	studentId?: string,
}

/**
 * usePreviousMissionData - get the data for connecting to the last mission that the client ran (from local storage)
 *
 * @returns MissionJoinData
 */
function usePreviousMissionData(): ?MissionJoinData {
	return useMemo(() => {
		try {
			const stringifiedPreviousMissionData = localStorage.getItem(
				config.localStorageJoinPreviousMissionKey
			)

			if (!stringifiedPreviousMissionData) {
				return
			}

			const possiblePreviousMissionData = JSON.parse(stringifiedPreviousMissionData)

			if (
				!(
					isPlainObject(possiblePreviousMissionData) &&
					isString(possiblePreviousMissionData.missionCode) &&
					isString(possiblePreviousMissionData.missionServerUrl)
				)
			) {
				return
			}

			const missionCode: string = possiblePreviousMissionData.missionCode
			const missionServerUrl: string = possiblePreviousMissionData.missionServerUrl

			if (isString(possiblePreviousMissionData.studentId)) {
				return {
					missionCode,
					missionServerUrl,
					studentId: possiblePreviousMissionData.studentId,
				}
			} else if (
				isBoolean(possiblePreviousMissionData.isTeacher) &&
				possiblePreviousMissionData.isTeacher
			) {
				return {
					missionCode,
					missionServerUrl,
					isTeacher: possiblePreviousMissionData.isTeacher,
				}
			}
		} catch (error) {
			console.error(error)
		}
		return null
	}, [])
}
