import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { getStudentName } from '../../../../store/stores/general'
import { getStudentId } from '../../../../store/stores/general'
import { getTeamForStudent } from '../../../../store/selectors/jrPlusState/teams'

// Determine identifiers for the tldraw/yjs awareness. This makes it so the user's name and color will appear next to their cursor in the canvas.
export function useMyAwarenessIdentifiers(): ?{ name: string, color: string, id: string } {
	const myId = useSelector(state => getStudentId(state.general))
	const studentName = useSelector(getStudentName)
	const team = useSelector(state => (myId ? getTeamForStudent(state, myId) : null))
	const myName = studentName || 'Anonymous'
	const myIndex = team?.teamMemberIds.findIndex(memberId => memberId === myId) || 0
	const myColor = colors[myIndex]
	return useMemo(() => {
		if (!myId) {
			return null
		}
		return { name: myName, color: myColor, id: myId }
	}, [myName, myColor, myId])
}

const colors = [
	'red',
	'green',
	'blue',
	'gold',
	'orange',
	'purple',
	'pink',
	'brown',
	'black',
	'grey',
]
