import type { ReduxStore } from '../../store/rootReducer'
import {
	type QuestionPhaseStation,
	getStudentCorrectQuestionCountForQuestionPhase,
	getQuestionPhaseQuestionIdsForStudent,
	studentHasCompletedQuestionPhase,
} from '@mission.io/mission-toolkit'
import { QUESTION_PHASE } from '@mission.io/mission-toolkit/constants'
import type { StationEnum } from '../../types'
import { getStation } from '../../store/stores/general'
import { getQuestionPhase } from '../../store/selectors/jrPlusState/questionData'
import { getWeaponIndex } from '../../store/selectors/jrPlusState/defense'
import { getMachineIndex } from '../../store/selectors/jrPlusState/tractorBeam'
import { getEngineIndex } from '../../store/selectors/sharedSelectors'
import { getStudentId } from '../../store/stores/general'
import { STATION_UPGRADE_ICONS } from './upgrades'

/**
 * Determines if the given station is in the question phase
 * @param {ReduxStore} state
 * @param {StationEnum} station
 * @returns {boolean}
 */
export function isGivenStationInQuestionPhase(state: ReduxStore, station: ?StationEnum): boolean {
	const givenStationAsQuestionPhaseEnum = station
		? STATION_ENUM_TO_QUESTION_PHASE_STATION[station]
		: null

	if (!givenStationAsQuestionPhaseEnum) {
		return false
	}
	const questionPhase = getQuestionPhase(state)
	const questionPhaseStation =
		questionPhase.status === QUESTION_PHASE.STATUS.ACTIVE ? questionPhase.station : null

	return questionPhaseStation ? questionPhaseStation === givenStationAsQuestionPhaseEnum : false
}

/**
 * Determines if the current selected station by the student is in the question phase.
 * @param {ReduxStore} state
 * @returns {boolean} whether the current station is having a question phase
 */
export function isCurrentStationInQuestionPhase(state: ReduxStore): boolean {
	return isGivenStationInQuestionPhase(state, getStation(state))
}

/**
 * A map of Station Enum Keys (the string that defines the active station in redux)
 * to question phase station enums (the string that defines the active station in the full state question phase)
 */
const STATION_ENUM_TO_QUESTION_PHASE_STATION: { [StationEnum]: QuestionPhaseStation } = {
	decks: QUESTION_PHASE.STATIONS.REPAIRS,
	defensePlus: QUESTION_PHASE.STATIONS.DEFENSE,
	communicationPlus: QUESTION_PHASE.STATIONS.COMMUNICATION,
	tractorBeamPlus: QUESTION_PHASE.STATIONS.TRACTOR_BEAM,
	transporterPlus: QUESTION_PHASE.STATIONS.TRANSPORTER,
	thrustersPlus: QUESTION_PHASE.STATIONS.THRUSTERS,
	powerPlus: QUESTION_PHASE.STATIONS.POWER,
}

/**
 * Gets the current station selected in terms of the question phase station type rather
 * than the station key enum.
 * @param {ReduxStore} state
 * @returns {?QuestionPhaseStation} the station selected that could potentially be in the question phase
 */
export function getCurrentStation(state: ReduxStore): ?QuestionPhaseStation {
	const station = getStation(state)
	return station ? STATION_ENUM_TO_QUESTION_PHASE_STATION[station] : null
}

/**
 * Gets the id for the current question phase. Necessary for sending messages to the server that
 * have to do with updating the current question phase.
 * @param {ReduxStore} state
 * @returns {?string}
 */
export function getQuestionPhaseId(state: ReduxStore): ?string {
	const questionPhase = getQuestionPhase(state)
	return questionPhase.status === QUESTION_PHASE.STATUS.ACTIVE ? questionPhase._actionId : null
}

const MAX_UPGRADE = 3

/**
 * Returns a number for total questions answered correctly in the question phase by the connected student.
 * @param {ReduxStore} state
 * @returns {number}
 */
function getTotalCorrectQuestionsInQuestionPhase(state: ReduxStore): number {
	const studentId = getStudentId(state.general)
	const questionPhase = getQuestionPhase(state)
	if (!studentId || questionPhase.status !== QUESTION_PHASE.STATUS.ACTIVE) {
		return 0
	}
	const initialUpgrade = Math.max(MAX_UPGRADE - questionPhase.questionIds.length, 0)
	return Math.min(
		initialUpgrade +
			(getStudentCorrectQuestionCountForQuestionPhase(state.jrPlusState, studentId) || 0),
		MAX_UPGRADE
	)
}

/**
 * Determines if a student has initiated upgrades for the current question phase.
 * @param {ReduxStore} state
 * @returns {boolean}
 */
export function getStudentHasInitiatedUpgrades(state: ReduxStore): boolean {
	const studentId = getStudentId(state.general)
	if (!studentId) {
		return false
	}
	const questionPhase = getQuestionPhase(state)
	return (
		questionPhase.status === QUESTION_PHASE.STATUS.ACTIVE &&
		Boolean(getQuestionPhaseQuestionIdsForStudent(questionPhase, studentId))
	)
}

/**
 * Determines if the question phase is active
 * @param {ReduxStore} state
 * @returns {boolean}
 */
export function isQuestionPhaseActive(state: ReduxStore): boolean {
	return getQuestionPhase(state).status === QUESTION_PHASE.STATUS.ACTIVE
}

// When a station does not support selecting upgrades, we return an upgrade index of 0.
const getNonUpgradableUpgradeIndex = () => 0

// This is a map of each station that supports the question phase to selectors that fetch the index of upgrade earned for the station.
// Note that there are stations that do not support upgrades yet, and those will use our noop selector which returns an index of -1
export const STATION_ENUM_TO_UPGRADE_SELECTOR: {
	[QuestionPhaseStation]: (ReduxStore) => number,
} = {
	[QUESTION_PHASE.STATIONS.REPAIRS]: getNonUpgradableUpgradeIndex,
	[QUESTION_PHASE.STATIONS.DEFENSE]: getWeaponIndex,
	[QUESTION_PHASE.STATIONS.COMMUNICATION]: getNonUpgradableUpgradeIndex,
	[QUESTION_PHASE.STATIONS.TRACTOR_BEAM]: getMachineIndex,
	[QUESTION_PHASE.STATIONS.TRANSPORTER]: getNonUpgradableUpgradeIndex,
	[QUESTION_PHASE.STATIONS.THRUSTERS]: getEngineIndex,
	[QUESTION_PHASE.STATIONS.POWER]: getNonUpgradableUpgradeIndex,
}

/**
 * When questions are answered during the question phase the index for an upgrade is determined by the total questions that the student answers correctly during the phase.
 * This selector will return upgrade index earned for the current station in the question phase IF that is the station currently selected by the student.
 * @param {ReduxStore} state
 * @returns {number} the index of upgrade earned (i.e: 0: default upgrade, 1-3: upgrades after the default)
 */
const getStationUpgradeIndexDuringQuestionPhase = (state): number => {
	if (isCurrentStationInQuestionPhase(state)) {
		return getTotalCorrectQuestionsInQuestionPhase(state)
	}
	return -1
}

/**
 * A selector that will return the station upgrade index for the students current station whether the station is active or if the station is in the question phase.
 * @param {ReduxStore} state
 * @returns {number}
 */
export function getStationUpgradeIndexForCurrentStation(state: ReduxStore): number {
	const questionPhaseIsOver = !isQuestionPhaseActive(state)
	const currentStation = getCurrentStation(state)
	if (!currentStation || !STATION_ENUM_TO_UPGRADE_SELECTOR[currentStation]) {
		return -1
	}
	return questionPhaseIsOver
		? STATION_ENUM_TO_UPGRADE_SELECTOR[currentStation](state)
		: getStationUpgradeIndexDuringQuestionPhase(state)
}

export function isCurrentStationUpgradable(state: ReduxStore): boolean {
	const currentStation = getCurrentStation(state)
	if (!currentStation) return false
	// If we have station upgrade icons for the station, then it is upgradable.
	return Boolean(STATION_UPGRADE_ICONS[currentStation])
}

/**
 * Determines if student is done answering their questions for the question phase.
 * @param {ReduxStore} state
 * @returns {boolean}
 */
export function isStudentDoneWithQuestionPhase(state: ReduxStore): boolean {
	const studentId = getStudentId(state.general)
	if (!studentId) {
		return false
	}
	return Boolean(studentHasCompletedQuestionPhase(state.jrPlusState, studentId))
}
