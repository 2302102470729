import React, { type AbstractComponent } from 'react'
import { useSpring, animated } from '@react-spring/web'

import { TARGET_BEAM_DURATION } from './TractorBeam'

import Path from '../../components/ShooterGame/Path'
import { getDimensions } from '../../utility/targets'
import type { TractorBeamStatus } from '../../types'

const DISAPPEARING_DURATION = 2000

type Location = {
	top: number,
	right: number,
}

export const TARGET_STATE_TYPE = {
	frozen: 'frozen',
	retracting: 'retracting',
	disappearing: 'disappearing',
}

type Props = {
	image: string,
	tractorBeamStatus: TractorBeamStatus,
	targetState: $Keys<typeof TARGET_STATE_TYPE> | null,
	containerHeight: number,

	right?: number,
	drift: number,
	driftSpeed: number,

	onDisappeared: () => void,
	getRetractingLocation: () => Location,
}

/**
 * TargetObject - the animated image of the target
 *
 * @param {Object} props - the react props
 * @param {string} props.image - the url for the image
 * @param {Object} props.style - the style used to position the image
 *
 * @return {React$Node}
 */

const TargetObject = React.forwardRef(({ image, style }: { image: string, style: Object }, ref) => {
	return <animated.img ref={ref} className="absolute" style={style} src={image} alt="Target" />
})

/**
 * The target component is a visual representation of the target that the player is trying to capture with the tractor beam.
 * It receives a forwarded ref from its parent and applies it to the animated image element so that the parent can access the target's location.
 * @param {string} props.image - the url for the target image
 * @param {TractorBeamStatus} props.tractorBeamStatus - the status of the tractor beam station: OFFLINE | TARGET | ASTEROID | SHIP
 * @param {number} props.containerHeight - the height of the container that the target is in
 * @param {number} props.right - the right offset of the target
 * @param {number} props.driftSpeed - the speed at which the target drifts horizontally
 * @param {number} props.drift - the amount that the target drifts horizontally
 * @param {string} props.targetState - the state of the target: frozen | retracting | disappearing
 * @param {() => void} props.onDisappeared - a callback function that is called when the target has finished disappearing
 * @param {() => Location} props.getRetractingLocation - a function that generates the location the target should move to when the beam is retracting
 */
function Target(
	{
		image,
		tractorBeamStatus,
		containerHeight,
		right,
		driftSpeed,
		drift,
		targetState,
		onDisappeared,
		getRetractingLocation,
	}: Props,
	ref
) {
	const [previousLocation, setPreviousLocation] = React.useState(null)
	const [retractingLocation, setRetractingLocation] = React.useState(null)
	React.useEffect(() => {
		if (targetState === 'retracting') {
			setRetractingLocation(getRetractingLocation)
		} else if (!targetState) {
			setRetractingLocation(null)
		}
	}, [targetState, getRetractingLocation])

	const [width, height] = getDimensions(tractorBeamStatus)

	if (targetState === 'frozen') {
		return <TargetObject ref={ref} image={image} style={{ ...previousLocation, width, height }} />
	}
	if (targetState === 'disappearing' && retractingLocation) {
		return (
			<DisappearingTarget
				ref={ref}
				image={image}
				onDisappeared={onDisappeared}
				retractingLocation={retractingLocation}
				width={width}
				height={height}
			/>
		)
	} else if (targetState === 'retracting' && retractingLocation) {
		return (
			<RetractingTarget
				ref={ref}
				image={image}
				retractingLocation={retractingLocation}
				previousLocation={previousLocation || retractingLocation}
				width={width}
				height={height}
			/>
		)
	}
	return (
		<Path
			top={0}
			bottom={containerHeight - height}
			leftDrift={-drift}
			rightDrift={drift}
			verticalTransitionTime={4000}
			horizontalTransitionTime={driftSpeed || 1825}
			onFrame={location => {
				setPreviousLocation({ top: location.top, right: (right || 0) + location.rightOffset })
			}}>
			{location => (
				<TargetObject
					ref={ref}
					image={image}
					style={{
						height,
						width,
						top: location.top,
						right: (right || 0) + (location.rightOffset || 0),
						opacity: 1,
					}}
				/>
			)}
		</Path>
	)
}

export default (React.forwardRef(Target): AbstractComponent<Props, HTMLImageElement>)

/**
 * DisappearingTarget - animate the target disappearing
 *
 * @param {Object} props - the react props
 * @param {string} props.image - the url for the image
 * @param {() => void} props.onDisappeared - a callback for when the target has finished disappearing
 * @param {Location} props.retractingLocation - the location of the target
 * @param {number} props.width - the width of the target
 * @param {number} props.height - the height of the target
 *
 * @return {React$Node}
 */
const DisappearingTarget = React.forwardRef(
	(
		{
			image,
			onDisappeared,
			retractingLocation,
			height,
			width,
		}: {
			image: string,
			onDisappeared: () => void,
			retractingLocation: Location,
			height: number,
			width: number,
		},
		ref
	) => {
		const { opacity, scale } = useSpring({
			config: { duration: DISAPPEARING_DURATION },
			from: { opacity: 1, scale: 1 },
			to: { opacity: 0, scale: 0.8 },
			onRest: onDisappeared,
		})

		return (
			<TargetObject
				ref={ref}
				image={image}
				style={{
					...retractingLocation,
					width,
					height,
					opacity,
					transform: scale.to(scale => `scale(${scale})`),
				}}
			/>
		)
	}
)

/**
 * RetractingTarget - animate the target retracting to a target location
 *
 * @param {Object} props - the react props
 * @param {string} props.image - the url for the image
 * @param {() => void} props.previousLocation - the location moving from
 * @param {Location} props.retractingLocation - the location moving to
 * @param {number} props.width - the width of the target
 * @param {number} props.height - the height of the target
 *
 * @return {React$Node}
 */
const RetractingTarget = React.forwardRef(
	(
		{
			image,
			previousLocation,
			retractingLocation,
			height,
			width,
		}: {
			image: string,
			previousLocation: Location,
			retractingLocation: Location,
			height: number,
			width: number,
		},
		ref
	) => {
		const { top, right } = useSpring({
			config: { duration: TARGET_BEAM_DURATION },
			from: { ...previousLocation },
			to: { ...retractingLocation },
		})

		return <TargetObject ref={ref} image={image} style={{ width, height, top, right }} />
	}
)
