// $FlowFixMe
import { ReactComponent as TractorBeam3 } from './tractorBeam3.svg'
// $FlowFixMe
import { ReactComponent as TractorBeam2 } from './tractorBeam2.svg'
import TractorBeam1 from './tractorBeam1'
import TractorBeam0 from './tractorBeam0'

export type BeamProps = {
	targetLength?: number,
	onExtended?: () => void,
	onRetracted?: () => void,
	springConfig?: {},
}

type RopeBeamConfig = {
	type: 'ROPE',
	withLoad: { speedRatio: number },
	withoutLoad: { speedRatio: number },
}
export type LightBeamConfig = {
	type: 'LIGHT',
	withLoad: { speedRatio: number },
	withoutLoad: { speedRatio: number },
	maxHeight: number,
	minHeight: number,
}

export type BeamConfig = LightBeamConfig | RopeBeamConfig

type Upgrade = {
	id: string,
	name: string,
	tractorBeam: React$ComponentType<BeamProps>,
	pointOfRotation: { x: number, y: number },
	beamConfig: BeamConfig,
	dimensions: { width: number, height: number },
}

const upgrades: Upgrade[] = [
	{
		id: 'level0',
		name: 'Plunger',
		tractorBeam: TractorBeam0,
		pointOfRotation: { x: 25, y: 155 },
		beamConfig: { type: 'ROPE', withLoad: { speedRatio: 12 }, withoutLoad: { speedRatio: 10 } },
		dimensions: { width: 0, height: 0 },
	},
	{
		id: 'level1',
		name: 'Magnetic Retractor',
		tractorBeam: TractorBeam1,
		pointOfRotation: { x: 26, y: 152 },
		beamConfig: { type: 'ROPE', withLoad: { speedRatio: 10 }, withoutLoad: { speedRatio: 8 } },
		dimensions: { width: 0, height: 0 },
	},
	{
		id: 'level2',
		name: 'Pulsar Puller',
		tractorBeam: TractorBeam2,
		pointOfRotation: { x: 53, y: 90 },
		beamConfig: {
			type: 'LIGHT',
			withLoad: { speedRatio: 6 },
			withoutLoad: { speedRatio: 4 },
			maxHeight: 40,
			minHeight: 20,
		},
		dimensions: { width: 107, height: 103 },
	},
	{
		id: 'level3',
		name: 'Gravitron Plus',
		tractorBeam: TractorBeam3,
		pointOfRotation: { x: 72, y: 124 },
		beamConfig: {
			type: 'LIGHT',
			withLoad: { speedRatio: 4 },
			withoutLoad: { speedRatio: 2 },
			maxHeight: 80,
			minHeight: 40,
		},
		dimensions: { width: 144, height: 142 },
	},
]

export default upgrades
