import React, { useState } from 'react'
import styled from 'styled-components'
import { VolumeSlider } from './VolumeSlider'
import { useSelector, useDispatch } from 'react-redux'
import {
	getPlayerVolumes,
	getOverallPlayerVolume,
	setPlayerVolumeByKey,
	setOverallPlayerVolume,
} from '../../../store/stores/settings'
import { FaAngleUp, FaAngleDown } from 'react-icons/fa'

// Renders specific Player volumes (i.e. video, music, characters, ambiance, etc.)
function VolumeDetails() {
	const dispatch = useDispatch()
	const volumes = useSelector(getPlayerVolumes)
	const setVolume = (value: number, type: string) => {
		dispatch(setPlayerVolumeByKey(value, type))
	}
	const sliders = [
		{ label: 'Video', volume: volumes.VIDEO, volumeSetter: val => setVolume(val, 'VIDEO') },
		{ label: 'Music', volume: volumes.MUSIC, volumeSetter: val => setVolume(val, 'MUSIC') },
		{
			label: 'Characters',
			volume: volumes.VOCAL_TRACK,
			volumeSetter: val => setVolume(val, 'VOCAL_TRACK'),
		},
		{
			label: 'Effects',
			volume: volumes.EFFECTS,
			volumeSetter: val => setVolume(val, 'EFFECTS'),
		},
		{
			label: 'Ambiance',
			volume: volumes.AMBIANCE,
			volumeSetter: val => setVolume(val, 'AMBIANCE'),
		},
	]

	return (
		<VolumeRow>
			{sliders.map(slider => (
				<VolumeWrapper key={slider.label}>
					<SliderLabel>{slider.label}</SliderLabel>
					<VolumeSlider volume={slider.volume} handleChange={slider.volumeSetter} />
				</VolumeWrapper>
			))}
		</VolumeRow>
	)
}

// Renders Player volume (by default only shows the main volume)
export default function Volume(): React$Node {
	const [showDetails, setShowDetails] = useState(false)
	const volume = useSelector(getOverallPlayerVolume)
	const dispatch = useDispatch()

	return (
		<div>
			<DefaultVolumeWrapper>
				<VolumeSlider
					volume={volume}
					handleChange={(vol: number) => {
						dispatch(setOverallPlayerVolume(vol))
					}}
				/>

				{showDetails ? (
					<ArrowUp size={20} onClick={() => setShowDetails(false)} />
				) : (
					<ArrowDown size={20} onClick={() => setShowDetails(true)} />
				)}
			</DefaultVolumeWrapper>
			<AudioWrapper show={showDetails}>
				<VolumeDetails />
			</AudioWrapper>
		</div>
	)
}

const ArrowUp = styled(FaAngleUp)`
	color: white;
	cursor: pointer;
	margin-left: 6px;
`

const ArrowDown = styled(FaAngleDown)`
	color: white;
	cursor: pointer;
	margin-left: 6px;
`
const DefaultVolumeWrapper = styled.div`
	padding: 0 16px;
	display: flex;
	align-items: center;
	width: 100%;
`

const VolumeWrapper = styled.div`
	padding: 2px 4px 0;
	width: 100%;
`

const VolumeRow = styled.div`
	width: 100%;
	display: grid;
	grid-template-columns: 1fr 1fr;
	align-items: center;
	justify-items: center;
	padding: 0 4px;
`

const SliderLabel = styled.div`
	font-size: 0.8rem;
	width: fit-content;
	color: white;
	padding-top: 4px;
	margin: auto;
`

const AudioWrapper = styled.div`
	border-radius: 0 0 4px 4px;
	position: relative;
	margin-top: -2px;
	transform: scaleY(0);
	transform-origin: top;
	transition: transform 0.25s ease;
	${({ show }) => (show ? `transform: scaleY(1); ` : `height: 0;`)}
`
